import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { NbDialogService, NbToastrService } from "@nebular/theme";
@Component({
  selector: 'ngx-scheme-incubator-bank-details',
  templateUrl: './scheme-incubator-bank-details.component.html',
  styleUrls: ['./scheme-incubator-bank-details.component.scss']
})
export class SchemeIncubatorBankDetailsComponent implements OnInit {
  bankDetailsForm!: FormGroup;
  isTideDetails:boolean=false;
  inc_fund_id:any;
  incubator_id:any;
  submitted:any;
  incbankdetailsresponse:any;
  
  constructor(private router: Router, 
    private fb: FormBuilder,
    private grantMangementService: GrantManagementService,
    private toastrService: NbToastrService,
    private _route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.inc_fund_id = this._route.snapshot.paramMap.get("incid");
    this.incubator_id = this._route.snapshot.paramMap.get("incubator_id");
   //bank details
   this.bankDetailsForm = this.fb.group({
      accountHolderName: ['',[ Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      bankName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), Validators.pattern('[a-zA-Z ]*')]],
      bankAddress: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      accountNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]],
      accountType: ['', [Validators.required]],
      ifscCode: ['', [Validators.required, Validators.pattern('[A-Z|a-z]{4}[0][A-Z|a-z|0-9]{6}')]]//abcd0EFGH12 or KLMN0tuvw56
    });
    if(this.inc_fund_id && this.incubator_id) {
      this.getBankDetailsByIncubatorId(this.inc_fund_id);
    }
  }
  onBankDetailsSubmit() {
    console.log(this.bankDetailsForm.value);
    if (this.bankDetailsForm.invalid) {
      // show error message
      this.bankDetailsForm.markAllAsTouched();
      console.log("Please fill in all mandatory fields.",this.bankDetailsForm.value);
      return;
    } else{
      console.log("onBankDetailsSubmit",this.bankDetailsForm.value);
       //remap the fields as per api
       let bankDetails = this.bankDetailsForm.value;     
       const mapObj = {
         "accountholder_name": bankDetails.accountHolderName,
         "bank_name": bankDetails.bankName,
         "address_line1": bankDetails.bankAddress,
         "account_number": bankDetails.accountNumber,
         "account_type":bankDetails.accountType,
         "ifsc_code":bankDetails.ifscCode
       }
 
       // API call with mapObj to bulk update here
 
       this.grantMangementService.createBankAccountDetails(mapObj, this.inc_fund_id).subscribe(res=>{
         this.incbankdetailsresponse = res;
         this.submitted = true;
         this.toastrService.success("Updated Bankdetails Successfully!");
         window.location.reload();
       });
    } 
  }
  get _accountHolderName(){
    return this.bankDetailsForm.get('accountHolderName');
  }
  get _bankName(){
    return this.bankDetailsForm.get('bankName');
  }
  get _bankAddress(){
    return this.bankDetailsForm.get('bankAddress');
  }
  get _accountNumber(){
    return this.bankDetailsForm.get('accountNumber');
  }
  get _accountType(){
    return this.bankDetailsForm.get('accountType');
  }
  get _ifscCode(){
    return this.bankDetailsForm.get('ifscCode');
  }

  getBankDetailsByIncubatorId(inc_fund_id:any) {
    console.log("IN BANK DETAILS:::");
    this.grantMangementService.getIncubatorBankDetails(inc_fund_id).subscribe(response => {
      console.log("response::::",response);
      this.incbankdetailsresponse = response;
      this.patchValue();
    })
  }

  patchValue() {
    console.log("Bank Details::RES::", this.incbankdetailsresponse);
    this.bankDetailsForm.patchValue({
      accountHolderName: this.incbankdetailsresponse?.results[0].accountholder_name,
      bankName:this.incbankdetailsresponse?.results[0].bank_name,
      bankAddress:this.incbankdetailsresponse?.results[0].address_line1,
      accountNumber:this.incbankdetailsresponse?.results[0].account_number,
      accountType:this.incbankdetailsresponse?.results[0].account_type,
      ifscCode:this.incbankdetailsresponse?.results[0].ifsc_code
    });
  }
}
