import { Component, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MediaService } from '../../services/media.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import * as _ from "lodash";
import { ShowcaseDialogComponent } from "../../@core/miscellaneous/dialog/showcase-dialog/showcase-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from '@angular/common';

const headers = new HttpHeaders().set(
  "Authorization",
  `Bearer ${sessionStorage.getItem("admintoken")}`
);

@Component({
  selector: 'ngx-mediagallery',
  templateUrl: './mediagallery.component.html',
  styleUrls: ['./mediagallery.component.scss'],
  providers: [DatePipe]
})
export class MediagalleryComponent implements OnInit {
  @ViewChild("UploadFileInput", { static: false }) uploadFileInput: ElementRef;
  @ViewChild("UploadFileInput1", { static: false }) uploadFileInput1: ElementRef;
  baseUrl = environment.apiurl;
  fileInputLabel: string;
  editMode:boolean;
  formSubmitted: boolean;
  mediaForm: FormGroup; 
  categoryForm: FormGroup 
  pageSize: number = 25;
  p : number = 1;
  data: {
    images: any;
    media_name: string;    
    media_link: string;
  };
  id: string;
  createMode: boolean = true;

  inValidMessage: boolean;
  dataTable: any;
  readMore: boolean;
  uploadMode: boolean;
  media_id:number;
  currentData: any;
  updatedData: any;
  imageUrl: string | ArrayBuffer | null = null;
  filetypeerr: boolean= false;
  filesizeerr: boolean =false;
  filetype: boolean= false;
  filesizer: boolean =false;
  modaldata = { mediaName: "", link: "", image: "", status: "" };
  categoryList: any;
  selectedValue: any;
  event: any;
  event_id: any;
  editEvent: FormGroup;
  event_copy_images: any[];
  

  constructor(
    private fb: FormBuilder,
    private mediaService: MediaService,
    private http: HttpClient,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private router: Router,
    private datepipe:DatePipe

  ) { }

  ngOnInit(): void {
    this.mediaForm = this.fb.group({
      EventCategory: ['', Validators.required, ],
      EventName: ['', Validators.required, ],
      mediaImage: ['', Validators.required, ],
      status:['']
  })
  this.categoryForm = this.fb.group({
    CategoryName: ['', Validators.required, ],
    CategoryDesc: ['', Validators.required, ],
    CategoryImage: ['', Validators.required, ],    
    StartDate: ['', Validators.required, ],
    EndDate: ['', Validators.required, ],
    
    
})
  this.editEvent = this.fb.group({
    category_name : ['',Validators.required],
    start_date : ['',Validators.required],
    end_date : ['',Validators.required],
    description: ['',Validators.required]
  })
  this.getAllMedia();
  this.getallCategory()
  }

  onFileSelect(event:any) {
    // const file = event.target.files[0];
    // const reader = new FileReader();
    // reader.onload = (event:any) => {
    //   this.imageUrl = event.target.result
    //   console.log('file upload',this.imageUrl);
    // }
    // reader.readAsDataURL(file);
    // // console.log("file", file);
    // this.fileInputLabel = file.name;
    // this.MediaForm.get("uploadedImage").setValue(file);
    // this.uploadMode=true;

    let af = ['image/png','image/jpeg','image/jpg','image/svg','image/webp']
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event:any) => {
      this.imageUrl = event.target.result
      console.log('file upload',this.imageUrl);
    }
    reader.readAsDataURL(file);
    this.uploadMode=true;

    const filesize = file.size;
    const filesizemb = filesize/1024 ** 2;
    console.log('filesize in mb',filesizemb);
    console.log('file',file);
    if(!af.includes(file.type)){
      this.filetype = true
    }
    else
    {
      this.filetype= false
    }

  if(filesizemb > 2){
    this.filesizer = true
    }
  else
  {
    this.filesizer = false
  }
  this.fileInputLabel = file.name;
  // this.mediaForm.get("mediaImage").setValue(file);
  this.categoryForm.get("CategoryImage").setValue(file);
  }
  onnewFileSelect(event:any) {
   let af = ['image/png','image/jpeg','image/jpg','image/svg','image/webp']
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event:any) => {
      this.imageUrl = event.target.result
      console.log('file upload',this.imageUrl);
    }
    reader.readAsDataURL(file);
    this.uploadMode=true;

    const filesize = file.size;
    const filesizemb = filesize/1024 ** 2;
    console.log('filesize in mb',filesizemb);
    console.log('file',file);
    if(!af.includes(file.type)){
      this.filetypeerr = true
    }
    else
    {
      this.filetypeerr = false
    }

  if(filesizemb > 2){
    this.filesizeerr = true
    }
  else
  {
    this.filesizeerr = false
  }
  this.fileInputLabel = file.name;
  this.mediaForm.get("mediaImage").setValue(file);
  // this.categoryForm.get("CategoryImage").setValue(file);
  }
  fileSizeValidator(control: FormControl) {
    const fileSize = control?.value?.size / 1024 / 1024; // size in MB
    const maxSize = 2; // maximum file size in MB
    return fileSize > maxSize ? { fileSizeExceeded: true } : null;
  }
  fileTypeValidator(control: FormControl) {
    const allowedTypes = ['image/png', 'image/jpeg']; // allowed file types
    const fileType = control?.value?.type; // file type
    return allowedTypes.includes(fileType) ? null : { invalidFileType: true };
  }

  getallCategory(){
    this.mediaService.getallCategory().subscribe((res: any) => {
      this.categoryList = res.results;
      console.log("event data::", this.dataTable);
    });
  }

  getIndividualCategory(id){
    this.event_id = id;
    this.mediaService.getCategory(id).subscribe((res:any)=>{
      this.event = res.results[0];
      console.log('event__1234',this.event);
      this.event['start_date'] = this.datepipe.transform(this.event.start_date,'yyyy-MM-dd');
      this.event['end_date'] = this.datepipe.transform(this.event.end_date,'yyyy-MM-dd')
      this.editEvent.patchValue(this.event);
    })
  }

  removeimage(index){
    console.log('image index',index);
    this.event_copy_images = [];
    this.event_copy_images = [...this.event.images];
    this.event_copy_images.splice(index,1);
    console.log('event_copy_images',this.event_copy_images);
    document.getElementById(`image_${index}`).setAttribute('class','d-none');
  }

  createCategory(){
    
     if (this.categoryForm.valid) {
      
      const formData = new FormData();
      
      formData.append("file", this.categoryForm.get("CategoryImage").value);
      const headers = new HttpHeaders();
      this.http
        .post<any>(this.baseUrl + "files?intent=create-edition", formData, {
          headers: headers,
        })
        .subscribe((response) => {
          console.log("image response", response.results[0].url);
          if (response.statusCode === 200) {
            this.uploadFileInput1.nativeElement.value = "";
            this.fileInputLabel = undefined;
          }
          let data = {
            category_name : this.categoryForm.controls.CategoryName.value,  
            description: this.categoryForm.controls.CategoryDesc.value,
            images: [
              {
                "file_id": response.results[0].file_id,
                "path":response.results[0].path,
                "url": response.results[0].url
    
              }
            ],
            start_date: this.categoryForm.controls.StartDate.value,
            end_date: this.categoryForm.controls.EndDate.value,
            
                
           
          };
          this.mediaService.createCategory(data).subscribe(
            (res: any) => {
              let data = res.results;
              console.log("category data::", data);
              this.categoryForm.reset();
              this.fileInputLabel = 'Choose File';
              this.getallCategory();
              // this.imageUrl='';
              this.toastrService.success("Data Added Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Record already Exist!");
            }
          );
        });
    } else {
      this.categoryForm.markAllAsTouched();
    }
    
  }
  update_event(){
    console.log('edit_event',this.editEvent.value);

    let data = this.editEvent.value;
    data['images'] = this.event_copy_images;
    this.mediaService.updateCategory(this.event_id,data).subscribe((res:any)=>{
      console.log('response_update',res)
    })
  }
  getAllMedia() {
    this.mediaService.getallMedia().subscribe((res: any) => {
      this.dataTable = res.results;
      console.log("ts data::", this.dataTable);
    });
  }

  deleteEventData(id) {
    this.dialogService
      .open(ShowcaseDialogComponent, {
        context: {
          message: "Are you sure you want to delete this event?",
        },
      })
      .onClose.subscribe((confirmed) => {
        if (confirmed) {
          console.log("deletedata:", id);
          this.mediaService.deleteCategory(id).subscribe(
            (data) => {
              this.getallCategory();
              this.toastrService.success("Event Deleted Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Delete Media!");
            }
          );
        }
      });
  }
  publishEvent() {  
   
    this.formSubmitted = true;
    if (this.mediaForm.valid) {
      this.editMode = false;
      this.createMode = true;
      const formData = new FormData();
      formData.append("file", this.mediaForm.get("mediaImage").value);
      this.http
        .post<any>(this.baseUrl + "files?intent=create-edition", formData, {
          headers: headers,
        })
        .subscribe((response) => {
          console.log("image response", response.results[0].url);
          if (response.statusCode === 200) {
            this.uploadFileInput.nativeElement.value = "";
            this.fileInputLabel = undefined;
          }
          let data = {
            category_id: this.mediaForm.controls.EventCategory.value,  
            entity_name: this.mediaForm.controls.EventName.value,
            entity_images
: [
              {
                "file_id": response.results[0].file_id,
                "path":response.results[0].path,
                "url": response.results[0].url
    
              }
            ],
            "posted_on": "2024-01-10"  
            
                
           
          };
          this.mediaService.createEvent(data).subscribe(
            (res: any) => {
              this.data = res.results;
              console.log("publish data::", this.data);
              this.mediaForm.reset();
              this.fileInputLabel = 'Choose File';
              this.getAllMedia();
              // this.imageUrl='';
              this.toastrService.success("Data Added Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Record already Exist!");
            }
          );
        });
    } else {
      this.mediaForm.markAllAsTouched();
    }
  }

  editData(mediaData: any, el:any) {
    console.log(mediaData)
    // el.scrollIntoView({behaviour:'smooth'});
    this.id = mediaData.media_id
    this.updatedData = mediaData
    this.uploadMode=false;
    this.editMode = true; //change btn to update
    this.createMode = false;
    this.mediaForm.controls["mediaTitle"].setValue(mediaData.media_name);
    this.mediaForm.controls["status"].setValue(mediaData.media_status);
    this.media_id = mediaData.media_id;  
    this.mediaForm.controls["mediaLink"].setValue(mediaData.media_link); //static passing
    this.mediaForm.controls["mediaImage"].setValue(mediaData.images[0].url);


    const url = mediaData.images[0].url;
    const last_part=url.split('/');
    this.fileInputLabel = last_part[last_part.length-1];

   
  }

  updateForms() {
    if (this.mediaForm.valid) {
      if(this.uploadMode == false ){
        if (this.editMode &&  _.mediaForm == _.data) {
          this.editMode = true;         
          // this.currentData["news_status"] = this.status;
          this.data = {
            
            media_name: this.mediaForm.controls.mediaTitle.value,  
            media_link: this.mediaForm.controls.mediaLink.value,
            images: [
              {
                "file_id": this.updatedData.images[0].file_id,
                "path":this.updatedData.images[0].path,
                "url": this.updatedData.images[0].url
    
              }
            ]   
            
                
           
          };
         
          console.log("data....", this.data);
          this.mediaService.editMedia(this.data, this.id).subscribe(
            (res) => {
              this.mediaForm.reset();
              this.getAllMedia();
              this.editMode = false;
              this.createMode = true;
              this.fileInputLabel = 'Choose File';
              // this.imageUrl='';
              this.toastrService.success("Media Updated Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to update Media!");
            }
          ); /////put api function call ends here!
        }
      }
            
      else if(this.uploadMode ==true){
        if (this.mediaForm.valid) {
          this.createMode=false;
          this.currentData = this.mediaForm.value;
          // this.currentData["news_status"] = this.status;
          const formData = new FormData();
          formData.append("file", this.mediaForm.get("mediaImage").value);
          this.http.post
          <any>
          (this.baseUrl + "files?intent=create-edition", formData,{
          headers :headers, })
          .subscribe((response)=>{
          console.log("image response", response.results[0].url);
          if (response.statusCode === 200) {
          this.uploadFileInput.nativeElement.value = "";
          this.fileInputLabel = undefined;
          }
          
          if(this.imageUrl != null){
            this.data = {
              media_name: this.mediaForm.controls.mediaTitle.value,  
              media_link: this.mediaForm.controls.mediaLink.value,
              images: [
                {
                  "file_id": response.results[0].file_id,
                  "path":response.results[0].path,
                  "url": response.results[0].url
      
                }
              ]   
              
                  
             
            };
          }
         
          console.log("data....", this.data);
          this.mediaService.editMedia(this.data, this.id).subscribe(
          (res) => {
          this.mediaForm.reset();
          this.getAllMedia();
          this.createMode = true;
          this.fileInputLabel = 'Choose File';
          // this.imageUrl='';
          this.uploadMode=false;
          this.editMode=false;
          this.toastrService.success("Media Updated Successfully!");
          },
          (error) => {
          this.inValidMessage = true;
          this.toastrService.warning("Unable to update Media!");
          }
          );
          })
        
          /////put api function call ends here!
          }
      }
      
     
    }
  }

  preview(item: any) {
    // console.log("item", item);
    // this.modaldata.mediaName = item.media_name;
    // this.modaldata.link = item.media_link;    
    // this.modaldata.image = item.images[0].url;
    this.router.navigateByUrl(`/admin/gallery/${item}`);
  }

  deleteMediaData(id) {
    this.dialogService
      .open(ShowcaseDialogComponent, {
        context: {
          message: "Are you sure you want to delete this Media?",
        },
      })
      .onClose.subscribe((confirmed) => {
        if (confirmed) {
          console.log("deletedata:", id);
          this.mediaService.deleteMedia(id).subscribe(
            (data) => {
              this.getAllMedia();
              this.toastrService.success("Media Deleted Successfully!");
              this.fileInputLabel = 'Choose File';
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Delete Media!");
            }
          );
        }
      });
  }
  

   // truncate text
   truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) {       
      this.readMore = false;
      return text;
     
    } else {
      this.readMore = true;
      return text.substring(0, maxLength) + '...';
    }
  }
   // truncate text

  cancel() {
    this.mediaForm.reset();
  }
  selectItem(item: any) {
    this.selectedValue = item.id;
}
}

