import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GrantNotificationService } from '../../../services/grant-notification.service';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { NbToastrService } from '@nebular/theme';

@Component({
    selector: 'ngx-finance-notifications',
    templateUrl: './finance-notifications.component.html',
    styleUrls: ['./finance-notifications.component.scss']
})
export class FinanceNotificationsComponent implements OnInit {
    notificationForm: FormGroup;
    notifications:any[] = [];
    schemeId:Number = 1;
    startdate:any;
    enddate:any;
    entity:any;
    errorMessage: string = '';

    p:number=1;
    pageSize:number=10;
    linkTextToggle:any = 'text';
    editMode:boolean = false;
    scheme_id:any;
    selectedNotificationId:any = '';
    isnotifyFormSubmited:boolean=false;
    selectNotif: any;
    constructor(private fb: FormBuilder,
        private activatedRoute:ActivatedRoute,
        private grantNotificationService: GrantNotificationService,
        private toasterService:NbToastrService,
        private router:Router,private grantManagementService:GrantManagementService,private _route:ActivatedRoute) { 
            this._route.paramMap.subscribe((params:any)=>{
                this.scheme_id = params.params.id;
                this.grantManagementService.scheme_id = this.scheme_id
              })
        }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.schemeId = params['id'];
        })
        this.notificationForm = this.fb.group({
            subject: ['',[Validators.required, Validators.maxLength(50)]],
            text: ['',[Validators.maxLength(250)]],
            link: ['',[Validators.pattern('^(https?:\/\/)([a-z]+)\.([a-z0-9]+)\.([a-z]{2,6})+(\/|.)([0-9a-z!@#$&()\\-`.+,\/\"]+(\/|.))*')]],
            description: ['',[Validators.maxLength(500)]]
        });
        this.grantManagementService.getEntityType().subscribe((res: any) => {
            this.entity = res;
            console.log('entity------', this.entity);
            this.getNotifications();
        });
        this.grantManagementService.getStartDate().subscribe((res:any)=>{
            this.startdate = res;
            console.log('startdate------',this.startdate)
            console.log('here1');
            //this.getFunds();
            
        })
      
        this.grantManagementService.getEndDate().subscribe((res: any) => {
            this.enddate = res;
            console.log('end------', this.enddate);
            console.log('here2');
            this.getNotifications();
        });
        this._route.queryParams.subscribe((params) => {
            this.p = +params['page'] || 1;
            this.loadData(this.p);
          
          })
    }

    editNotification(notification:any) {
        this.notificationForm.patchValue(
            { subject: notification.subject, text: notification.text, link: notification.link , description:notification.description});
        this.editMode = true;
        this.selectedNotificationId = notification.id;
    }

    getNotifications() {
        this.grantManagementService.getNotifications(this.entity,this.startdate, this.enddate).subscribe((res:any) => {
            this.notifications = res;
        })
    }

    deleteNotification(id:any) {
        this.grantManagementService.deleteNotification(id,this.entity).subscribe(() => {
            this.getNotifications();
            this.toasterService.danger('Record deleted successfully')
        })
    }

    submitNotification() {
        
        this.isnotifyFormSubmited=true;
        const text = this.notificationForm.get('text')?.value;
        const link = this.notificationForm.get('link')?.value;
        if (!text && !link) {
            
            this.toasterService.danger("Please provide either text or link.");

            return;
          } else {
            // this.errorMessage = '';
          }
        console.log(this.notificationForm);

        if(this.notificationForm.valid){

        let payload = {
            ...this.notificationForm.value,
            scheme_id: this.schemeId
        }
        if(this.editMode && this.selectedNotificationId) {
            this.grantManagementService.updateNotification(this.selectedNotificationId, payload,this.entity).subscribe(() => {
                this.getNotifications();
                this.toasterService.success('Updated submitted successfully')

                this.selectedNotificationId = '';
                this.editMode = false;
            })
        } else {
            this.grantManagementService.createNotification(payload,this.entity).subscribe(() => {
                this.getNotifications();
                this.toasterService.success('Form submitted successfully')

            },
        (error:any)=>{
            this.toasterService.danger('something went wrong')

        })
        }
        this.notificationForm.reset();
        this.isnotifyFormSubmited=false;
        // this.toasterService.success('Form submitted successfully')
    }
    else{
        console.log('ffsdfsdgfdgfd');
        this.toasterService.danger('Something went wrong')


    }
    }
    ngOnDestroy() {
        this.editMode = false;
        this.selectedNotificationId = '';
    }
    get _text() {
        return this.notificationForm?.get("text");
      }
      get _subject(){
        return this.notificationForm?.get("subject");
      }
      get _link() {
        return this.notificationForm?.get("link");
      }
      get _description(){
        return this.notificationForm?.get("description")
      }
      
// setValidator(selection: any){

//  this.linkTextToggle = selection;
//     if (selection === 'link') {
//         console.log('link is working')
//       this.notificationForm.get('text').setValidators([]);
//       this.notificationForm.get('link').setValidators([Validators.required, Validators.pattern('^(https?:\/\/)([a-z]+)\.([a-z0-9]+)\.([a-z]{2,6})+(\/|.)([0-9a-z!@#$&()\\-`.+,\/\"]+(\/|.))*')]);
//     } else if (selection === 'text') {
//         console.log('text is working')
//       this.notificationForm.get('link').setValidators([]);
//       this.notificationForm.get('text').setValidators(Validators.required);
//     }
//     this.notificationForm.get('link').updateValueAndValidity();
//     this.notificationForm.get('text').updateValueAndValidity();
//   }

  viewNotification(data: any){
    this.selectNotif = [];
    if(data != null){
        this.selectNotif = data;
        console.log('136', this.selectNotif)
    }
  }
  //pagenation
  onPageChange(page:number):void{
    this.p =page,
    this.router.navigate([],{
      relativeTo:this._route,
      queryParams:{page:page},
      queryParamsHandling:'merge'
    })
    this.loadData(page)
  }
  private loadData(page:number):void{
    console.log(page)
  }

  cancelEdit(){
    this.editMode =false;
    this.notificationForm.reset();
  
  }
}
