<!-- 
<div class="container py-3">
  <ngx-gia-utilized-table></ngx-gia-utilized-table>
</div> -->
<div class="container bg-white p-4">
  <h2 class="text-center eir-grant">State wise Agency​</h2>
  <div class="d-flex justify-content-end p-3">
    <button type="button" class="btn btn-primary text-end" (click)="downloadCSV()">Download</button>
  </div>
  <div class="table-responsive">
    <table class="table text-center table-bordered">
      <thead>
        <tr>
          <th>S.N</th>
          <th>State/ UT Name​​​</th>
          <th>Agency Name​​​​</th>
          <th *ngIf="scheme_id !=2">Type of the Agency</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor= "let item of Incubatorfunds?.data; let i=index">
          <td>{{i+1}}</td>
          <td>{{item.state}}</td>
          <td>{{item.name}}</td>
          <td *ngIf="scheme_id !=2">{{item.group ? item.group:'-'}}</td>
        </tr>
     
          <tr>
            <th>Total</th>
            <th>{{statedata}}</th>
            <th>{{newincubator}}</th>
            <th *ngIf="scheme_id !=2"></th>
          </tr>
        
        

        <!-- Add more rows as needed -->
      </tbody>
    </table>
  </div>
</div>
