<div class="login-form row custom-card col-md-10 offset-md-1">
    <div class="col-12 col-md-6 col-sm-12 transform-center m-auto">
        <div class="text-center">
            <img src="{{'assets/images/MeitYStartupHub-Logo-FINAL_5 1.png'}}" height="137px">
        </div>
        <hr class="hr-text">
        <div class="desc-text">
            Meity startup India Portal is a one of its kind online platform for all stakeholders of the startup
            ecosystem in India.
        </div>
        <hr class="hr-text">
        <div class="text-center">
            
        </div>
    </div>
    <div class="col-12 col-md-6 col-sm-12">
        <div class="text-center pt-30">
           
        </div>
        <form class="ng-pristine ng-invalid" [formGroup]="echangepasswordform">
            <div class="row">
                <div class="form-control-group col-6">
                    <label for="input-email" class="font-weight-bold">First Name :</label>
                    <input nbinput="" fullwidth="" formControlName = "firstname" id="input-email" placeholder="First Name"
                        fieldsize="large" autofocus="" [ngClass] = "{'is-invalid':submitted && f.firstname.errors}"
                        class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                    <div *ngIf="submitted && f.firstname.errors" class="text-danger">
                        <div *ngIf="f.firstname.errors['required']">First Name is required</div>
                    </div>
                </div>
    
                <div class="form-control-group col-6">
                    <label for="input-email" class="font-weight-bold">Last Name :</label>
                    <input nbinput="" fullwidth="" formControlName = "lastname" id="input-email" placeholder="Last Name"
                        fieldsize="large" autofocus="" [ngClass] = "{'is-invalid':submitted && f.lastname.errors}"
                        class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                    <div *ngIf="submitted && f.lastname.errors" class="text-danger">
                        <div *ngIf="f.lastname.errors['required']">last Name is required</div>
                    </div>
                </div>

                <div class="form-control-group col-6">
                    <label for="input-email" class="font-weight-bold">Email :</label>
                    <input nbinput="" fullwidth="" readonly formControlName = "email" id="input-email" placeholder="Email"
                        fieldsize="large" autofocus="" [ngClass] = "{'is-invalid':submitted && f.email.errors}"
                        class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition"> 
                    <div *ngIf="submitted && f.email.errors" class="text-danger">
                        <div *ngIf="f.email.errors['required']">Email Name is required</div>
                        <div *ngIf="f.email.errors['pattern']">Email is invalid</div>
                    </div>    
                    </div>
                <div class="form-control-group col-6">
                    <label for="input-email" class="font-weight-bold">Password :</label>
                    <input nbinput="" fullwidth="" formControlName = "password" type="password" id="password" placeholder="Password"
                        fieldsize="large" autofocus="" [ngClass] = "{'is-invalid':submitted && f.password.errors}"
                        class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                    <div *ngIf="submitted && f.password.errors" class="text-danger">
                        <div *ngIf="f.password.errors['required']">Password is required</div>
                        <div *ngIf="f.password.errors['minlength']">Password is invalid</div>
                    </div>
                </div>
                <div class="form-control-group col-12">
                    <label for="input-email" class="font-weight-bold">Confirm Password :</label>
                    <input nbinput="" fullwidth="" formControlName = "cpassword" type="password" id="confirmpassword" placeholder="Confirm Password"
                        fieldsize="large" autofocus="" [ngClass] = "{'is-invalid':submitted && f.cpassword.errors}"
                        class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                        <div *ngIf="submitted && f.cpassword.errors" class="text-danger">
                            <div *ngIf="f.cpassword.errors['required']">Confirm Password is required</div>
                        
                        </div>
                        <p *ngIf="nomatch" class="text-danger">
                            Password and Confirm Password didn't match.
                        </p>
                </div>

                <!-- <div class="form-control-group col-6">
                    <label for="input-email" class="font-weight-bold">Upload Photo :</label>
               
                  
                    <input nbinput="" fullwidth="" name="email" type="file" id="input-email"
                        fieldsize="large" autofocus="" (change)="onFileSelect($event)" #UploadFileInput name="uploadedImage"
                        class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                        <p class="fs-11 font-weight-bold text-danger">
                            (File Size Should Not Exceed 2MB. Allowed Extensions: PNG , JPG , SVG)
                          </p>
                </div> -->
            </div>
         
        
    
            <button type="button"  fullwidth="" status="primary" size="large" (click)="submit()"
                class="appearance-filled full-width size-large shape-rectangle status-primary nb-transition login-button">
                Submit </button>
        </form>
       
    </div>
</div>

