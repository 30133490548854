import { Component, OnInit } from '@angular/core';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx'; // Import xlsx library

@Component({
  selector: 'ngx-statewise-startuplist',
  templateUrl: './statewise-startuplist.component.html',
  styleUrls: ['./statewise-startuplist.component.scss']
})
export class StatewiseStartuplistComponent implements OnInit {
  startdate:any;
  enddate:any;
  funds:any;
  total:any;
  statetotal:any
  entity: any;
  entityType:any;
  scheme_id:any;
  incubatortotal: any;
  constructor(private grantManagementService:GrantManagementService,private _route:ActivatedRoute) {
    this._route.paramMap.subscribe((params:any)=>{
      this.scheme_id = params.params.id;
      this.grantManagementService.scheme_id = this.scheme_id
    })
   }

  ngOnInit(): void {
    this.grantManagementService.getStartDate().subscribe((res:any)=>{
      this.startdate = res;
      console.log('startdate------',this.startdate)
      this.statewiseData();
  })

  this.grantManagementService.getEndDate().subscribe((res: any) => {
      this.enddate = res;
      console.log('end------', this.enddate);
      // this.statewiseData(); // Call getFunds() after end date is available
  });
  this.grantManagementService.getEntityType().subscribe((res: any) => {
    this.entity = res;
    console.log('entity------', this.entity);
    this.statewiseData(); 
});
    // this.statewiseData()
  }

  statewiseData(){

    this.grantManagementService.stateWiseStartups(this.startdate, this.enddate,this.entity).subscribe((res:any) => {
      console.log("res ==========> ",res);
      this.funds = res
      console.log(this.funds,'dd')
      this.total=res?.startup_total[0].total_value;
      this.statetotal =res?.state_total[0].total_value
      this.incubatortotal = res?.incubator_total[0].total_value
      });
  }
  
  // downloadCSV(): void {
  //   const csvData = this.convertToCSV();
  //   const blob = new Blob([csvData], { type: 'text/csv' });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'table_data.csv';
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   window.URL.revokeObjectURL(url);
  // }
  
  // convertToCSV(): string {
  //   let csv = '';
    
  //   if (this.scheme_id == 2) {
  //     csv += 'S.N,State/ UT Name,Start-UP Name,Respective Agency Name\n';
  //     this.funds.data.forEach((item, index) => {
  //       csv += `${index + 1},${item.state},${item.startup_name},${item.incubator_name}\n`;
  //     });
  //   } else {
  //     // Add table headers with "Agency Type"
  //     csv += 'S.N,State/ UT Name,Start-UP Name,Respective Agency Name,Agency Type\n';
  //     // Add table rows with "Agency Type"
  //     this.funds.data.forEach((item, index) => {
  //       csv += `${index + 1},${item.state},${item.startup_name},${item.incubator_name},${item.group}\n`;
  //     });
  //   }
    
  //   // Add total row
  //   csv += `Total,Total-1:${this.statetotal},Total-2:${this.total},,\n`;
  //   return csv;
  // }
  

downloadCSV(): void {
    const excelData = this.convertToExcelData();

    // Convert data to XLSX format
    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Create a Blob and trigger download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'table_data.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

convertToExcelData(): any[][] {
    const excelData = [];

    // Determine headers based on scheme_id
    let headers;
    if (this.scheme_id == 2) {
        headers = ['S.N', 'State/ UT Name', 'Start-UP Name', 'Respective Agency Name'];
    } else {
        headers = ['S.N', 'State/ UT Name', 'Start-UP Name', 'Respective Agency Name', 'Agency Type'];
    }
    excelData.push(headers);

    // Add data rows
    this.funds.data.forEach((item, index) => {
        let rowData;
        if (this.scheme_id == 2) {
            rowData = [
                index + 1,
                item.state || '-',
                item.startup_name || '-',
                item.incubator_name || '-'
            ];
        } else {
            rowData = [
                index + 1,
                item.state || '-',
                item.startup_name || '-',
                item.incubator_name || '-',
                item.group || '-'
            ];
        }
        excelData.push(rowData);
    });

    // Add total row
    excelData.push(['Total', `Total-1: ${this.statetotal}`, `Total-2: ${this.total}`, '', '']);

    return excelData;
}

}
