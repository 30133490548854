<div class="container py-3">
  <!-- <ngx-gia-utilized-table></ngx-gia-utilized-table> -->
</div>
<div class="container bg-white p-4">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h2 class="text-center eir-grant mb-0">EiR Grants</h2>
    <button type="button" class="btn btn-primary" (click)="downloadCSV()">Download</button>
  </div>
  <!-- Rest of your content here -->
  <!-- <div *ngIf="loading" class="text-center">
    <p>Loading...</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div> -->

  <table  class="table table-responsive text-center">
    <thead>
      <tr>
        <th scope="col">Sr. No.</th>
        
        <th scope="col">Agency Name</th>
        <th scope="col">Startup Name</th>
        <th scope="col">State</th>
        <th scope="col">Grant Type</th>
        <th scope="col">Group</th>
     

        <th scope="col">Quarter</th>

      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let fund of funds|paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index">
        <tr>
          <td>{{ i + 1 + (p - 1) * pageSize }}</td>
          <td>{{fund.agency_name}}</td>
          <td>{{ fund?.startup_name }} </td>
         <td>{{fund.startup_state}}</td>
          <td>{{ fund?.grant_type }}</td>
          <td>{{fund?.group}}</td>
    <td>
      <table class="table">
              <thead>
                <tr>
                  <th>Quarter</th>
                  <th scope="col">Sanction No.</th>
                  <th scope="col">Sanction Date</th>
                  <th scope="col">Amount Approved</th>
                  <th scope="col">Amount Utilized</th>
                      <th scope="col">Amount Unutilized</th>
                  <th scope="col">Sanction Doc</th>
                  <th scope="col">Utilization Doc</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let quarter of fund.termaccounts; let j=index">
                  <td>{{quarter.quarter}}</td>
                  <td>{{ quarter.sanction_no}}</td>
                  <td>{{ quarter.amount_sanction_date.substring(0, 10) }}</td>
                  <td style="color: blue;text-decoration:underline"><a
                      (click)="viewTermFund(fund?.startupfund_id, quarter.incubatorfundterm_id,fund?.startup_name)"
                      data-bs-toggle="modal" data-bs-target="#exampleModal">{{ quarter.amount_received }}</a></td>

                  <td style="color: blue;text-decoration:underline"><a
                      (click)="viewutilizedFund(fund?.startupfund_id, quarter.incubatorfundterm_id,fund?.startup_name)"
                      data-bs-toggle="modal" data-bs-target="#newexampleModal">{{quarter.amount_utilised}}</a>
                    </td>
                 <td>{{quarter.amount_refunded}}</td>
                      <td>
                    <ng-container *ngIf="quarter.sanction_documents != null; else noDocument">
                      <a [href]="quarter.sanction_documents?.url" target="_blank">View Document here</a>
                    </ng-container>
                    <ng-template #noDocument>
                      <p>No document</p>
                    </ng-template>
                  </td>
                  <td>
                    <ng-container *ngIf="quarter.utilisation_documents != null; else noUtilisationDocument">
                      <a [href]="quarter.utilisation_documents?.url" target="_blank">View Utilisation Document here</a>
                    </ng-container>
                    <ng-template #noUtilisationDocument>
                      <p>Utilisation document is not uploaded</p>
                    </ng-template>
                  </td>
                  
                 

                  <td>{{ quarter.status }}</td>
                  <td class="d-flex justify-content-center">
                    <ng-container *ngIf="quarter.status != 'REJECTED'">
                    <i class="fa fa-close arrow px-2"
                      (click)="rejectTermFund(quarter.incubatorfundterm_id,fund?.startupfund_id)"></i>
                    </ng-container>
                    <ng-container *ngIf="quarter.status != 'APPROVED'">
                    <i class="fa fa-check arrow px-2"
                      (click)="approveTermFund(quarter.incubatorfundterm_id,fund?.startupfund_id)"></i>
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </ng-container>
      <div class="modal fade" id="newexampleModal" tabindex="-1" aria-labelledby="newexampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title fs-5" id="exampleModalLabel"><b>{{newstartupp}}</b></p>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
          </div>
          <div class="modal-body scrollbar-data">
            <table class="table table-borderless">
    
              <thead>
                <tr>
                  <th scope="col">Head</th>
                  <th scope="col">Approved Amount</th>
    
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of newone">
                  <td>{{item.head}}</td>
                  <td>{{item.amount_utilized}}</td>
                  <td></td>
    
                </tr>
    
              </tbody>
            </table>
            <!-- <p>{{item.head}}:{{item.approved_amount}}</p> -->
    
          </div>
    
        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title fs-5" id="exampleModalLabel"><b>{{startupp}}</b></p>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
          </div>
          <div class="modal-body scrollbar-data">
            <table class="table table-borderless">
    
              <thead>
                <tr>
                  <th scope="col">Head</th>
                  <th scope="col">Approved Amount</th>
    
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of viewdata">
                  <td>{{item.head}}</td>
                  <td>{{item.approved_amount}}</td>
                  <td></td>
    
                </tr>
    
              </tbody>
            </table>
    
          </div>
    
        </div>
      </div>
    </div>
 </tbody>
  </table>
  <pagination-controls *ngIf="funds?.length > 0"  (pageChange)="onPageChange($event)" directionLinks="true"  class="float-right"></pagination-controls>



</div>