import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

@Injectable({
  providedIn: 'root'
})
export class AcceleratorService {
  baseUrl = environment.apiurl 

  constructor( private http: HttpClient) { }

  getacceleratorsList(data) {
    // let data={
    //   search_entity: "STARTUP"
    // }
    const url = this.baseUrl + "search";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  getacceleratorByID(id) {
    const url = this.baseUrl + `accelerators/${id}`;
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  actionaccelerator(currentData, id) {
    let url = this.baseUrl + "accelerators/" + id;

    let result: any = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));

      console.log("data:results:", currentData);
    return result;
  }
  editaccelerator(currentData, id) {
    let url = this.baseUrl + "accelerators/" +id;
    // console.log("update::", currentData);
      let result = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  filteraccelerator(data) {
    const url = this.baseUrl + "search";
    let result: any = this.http
      .post(url, data,{ headers: headers })
      .pipe(catchError(this.handleError));
    return result;
 
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
