import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ngx-gia-utilized-table',
  templateUrl: './gia-utilized-table.component.html',
  styleUrls: ['./gia-utilized-table.component.scss']
})
export class GiaUtilizedTableComponent implements OnInit {
schemeId:number;
schemeName:string;
schemes:string;
  constructor(private _route:ActivatedRoute,private router: Router) {
    const schemeid = this._route.paramMap.subscribe((response:any)=>{
      console.log('params id', response.params)
      this.schemeId = response.params.id
      
    });
   }


  ngOnInit(): void {
    this.loadScript('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js');
    if(this.schemeId == 1){
      this.schemeName = 'TIDE 2.0'
    }
    else if(this.schemeId ==2){
      this.schemeName = 'SAMRIDH'

    }
    else if(this.schemeId ==3){
      this.schemeName = 'Other Meity Scheme'

    }
    else if(this.schemeId ==4){
      this.schemeName = 'GENESIS'

    }
    else if(this.schemeId ==5){
      this.schemeName = 'Non Meity Schemes'

    }
    else if(this.schemeId ==6){
      this.schemeName = 'NGIS'

    }
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  // navigateToStateWiseFunds() {
  //   this.router.navigate(['/admin/grant-management', this.schemeId, 'state-fund'])
  // }

  // navigateToStateWiseIncubators() {
  //   this.router.navigate(['/admin/grant-management', this.schemeId, 'statewise-agency'])

  // }

  // navigateToStateWiseStartupList() {
  //   this.router.navigate(['/admin/grant-management', this.schemeId, 'statewise-startup'])

  // }

  // navigateToQuarterWiseFundRelease() {
  //   this.router.navigate(['/admin/grant-management', this.schemeId, 'quarter-fund'])
    

  // }
}
