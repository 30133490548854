import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrantManagementComponent } from './grant-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { SchemeIncubatorProjectDetailsComponent } from './scheme-incubator-project-details/scheme-incubator-project-details.component';
import { GrantManagementRoutingModule } from './grant-management-routing.module';
import { SchemeIncubatorProjectFinancialsComponent } from './scheme-incubator-project-financials/scheme-incubator-project-financials.component';
import { SchemeIncubatorFundsUtilizationComponent } from './scheme-incubator-funds-utilization/scheme-incubator-funds-utilization.component';
import { SchemeIncubatorBankDetailsComponent } from './scheme-incubator-bank-details/scheme-incubator-bank-details.component';
import { SchemeIncubatorUCDocumentsComponent } from './scheme-incubator-uc-documents/scheme-incubator-uc-documents.component';
import { SchemeIncubatorGrantListComponent } from './scheme-incubator-grant-list/scheme-incubator-grant-list.component';
import { SchemeIncubatorGenerateReportComponent } from './scheme-incubator-generate-report/scheme-incubator-generate-report.component';
import { NewfinancialdashboardComponent } from './newfinancialdashboard/newfinancialdashboard.component';
import { NewstartuptableComponent } from './newstartuptable/newstartuptable.component';
import { ListofAgenciesComponent } from './listof-agencies/listof-agencies.component';
import { FinanceNotificationsComponent } from './finance-notifications/finance-notifications.component';
import { FinanceUploadsComponent } from './finance-uploads/finance-uploads.component';
import { TeamtideTableComponent } from './teamtide-table/teamtide-table.component';
import { GiaReleasedTableComponent } from './gia-released-table/gia-released-table.component';
import { GiaUtilizedTableComponent } from './gia-utilized-table/gia-utilized-table.component';
import { ReleseEirGrantsComponent } from './relese-eir-grants/relese-eir-grants.component';
import { ReleaseScaleupInvestmentComponent } from './release-scaleup-investment/release-scaleup-investment.component';
import { EirHeadwiseUtilizationComponent } from './eir-headwise-utilization/eir-headwise-utilization.component';
import { ScaleupHeadwiseUtilizationComponent } from './scaleup-headwise-utilization/scaleup-headwise-utilization.component';
import { StatewiseFundreleasedComponent } from './statewise-fundreleased/statewise-fundreleased.component';
import { StatewiseAgenciesComponent } from './statewise-agencies/statewise-agencies.component';
import { StatewiseStartuplistComponent } from './statewise-startuplist/statewise-startuplist.component';
import { QuarterwiseFundReleaseComponent } from './quarterwise-fund-release/quarterwise-fund-release.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { RouterModule } from '@angular/router';
import { FinancialyeardropdownComponent } from './financialyeardropdown/financialyeardropdown.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SamridhFundReleaseComponent } from './samridh-fund-release/samridh-fund-release.component';
import { SamridhDocumentDataComponent } from './samridh-document-data/samridh-document-data.component';
import { SamridhReleaseOrderComponent } from './samridh-release-order/samridh-release-order.component';
//import { FinancialyeardropdownComponent } from './financialyeardropdown/financialyeardropdown.component';


@NgModule({
  declarations: [
    GrantManagementComponent,
    SchemeIncubatorProjectDetailsComponent,
    SchemeIncubatorProjectFinancialsComponent,
    SchemeIncubatorFundsUtilizationComponent,
    SchemeIncubatorBankDetailsComponent,
    SchemeIncubatorUCDocumentsComponent,
    SchemeIncubatorGrantListComponent,
    SchemeIncubatorGenerateReportComponent,
    NewfinancialdashboardComponent,
    NewstartuptableComponent,
    ListofAgenciesComponent,
    FinanceNotificationsComponent,
    FinanceUploadsComponent,
    TeamtideTableComponent,
    GiaReleasedTableComponent,
    GiaUtilizedTableComponent,
    ReleseEirGrantsComponent,
    ReleaseScaleupInvestmentComponent,
    EirHeadwiseUtilizationComponent,
    ScaleupHeadwiseUtilizationComponent,
    StatewiseFundreleasedComponent,
    StatewiseAgenciesComponent,
    StatewiseStartuplistComponent,
    QuarterwiseFundReleaseComponent,
    FinancialyeardropdownComponent,
    SamridhFundReleaseComponent,
    SamridhDocumentDataComponent,
    SamridhReleaseOrderComponent
    // FinancialyeardropdownComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CKEditorModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    RouterModule,
    NgxPaginationModule
  ]
})
export class GrantManagementModule { }
