import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AxiosServicesService } from "../../services/axios/axios-services.service";
import { NbToastrService } from '@nebular/theme';
@Component({
  selector: "ngx-forgot",
  templateUrl: "./forgot.component.html",
  styleUrls: ["./forgot.component.scss"],
})
export class ForgotComponent implements OnInit {
  forgotForm: FormGroup;
  loginResponse: any;
  mobileNumForm: FormGroup;
  inValidMessage: boolean = false;
  submitted = false;
  show: boolean = false;
  showeye:boolean = true;
  // loginservice: any;
  constructor(
    private router: Router,
    private http: HttpClient,
    // private loginservice:LoginService
    private formBuilder: FormBuilder,
    private axiosService: AxiosServicesService,
    private toastrService: NbToastrService
  ) {}

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: [
        null,
        [Validators.required, ,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      ],
    });
  }

  passwordLogin() {
    if (this.forgotForm.valid) {
      this.router.navigate(["/"]);
    }
  }
  get f(){
    return this.forgotForm.controls;
  }
  
  forgotpasswordcode(){
    this.submitted = true;
    if(!this.forgotForm.valid){

    }
    else {
      let data = {
        "email":this.forgotForm.controls.email.value
      }
      this.axiosService.forgotpassword_code(data).subscribe((res)=>{
        console.log('res',res);
        sessionStorage.setItem('email_forgotpassword',this.forgotForm.controls.email.value)
        this.router.navigate(['/auth/resetpassword'])
      },(err)=>{
        this.toastrService.danger('User not found in our database')
      })
    }

  }

  backtologin(){
    this.router.navigate(['/auth']);
  }
}
