import { Inject, Injectable } from "@angular/core";
import { Observable, from, throwError } from "rxjs";
import axios, { AxiosRequestConfig } from "axios";
import { catchError, map } from "rxjs/operators";
import { HttpClientService } from "./base-http-client-axios.service";
import { HttpErrorResponse, HttpHeaders , HttpClient} from "@angular/common/http";
import { environment } from '../../../environments/environment';

const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Authorization', `Bearer ${sessionStorage.getItem('admintoken')}`);

@Injectable({
  providedIn: "root",
})
export class AxiosServicesService {
  //baseUrl = 'https://reqres.in/';
  // baseUrl = "http://43.205.193.210:8593";
  // baseUrl = "http://43.205.193.210:8090/";
  // baseUrl = "http://43.205.193.210:8090/";

  // baseUrl_auth = "http://43.205.193.210:8080/api/";
  constructor(@Inject("HttpClientService") private http: HttpClientService, private httpClient:HttpClient) {}

  validateLogin(path: string, params: any): Observable<object> {
    let url = environment.apiurl + 'admin/login';
    let result: any = this.http
      .post(url, params)
      .pipe(catchError(this.handleError));
    return result;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  forgotpassword_code(data:any){
    let url = environment.apiurl + 'user/forgotpassword/code';
    let result = this.http.post(url,data).pipe(catchError(this.handleError));
    return result;
  }

  resetpasswordwithcode(data:any){
    let url = environment.apiurl + 'user/forgotpassword';
    let result = this.http.post(url,data).pipe(catchError(this.handleError));
    return result;
  }

  changepassword(data:any){
    let url = environment.apiurl + `user/changepassword`;
    let result = this.httpClient.put(url,data,{'headers':headers}).pipe(catchError(this.handleError));
    return result;
  }

  getevaluatorstatus(token:any){
    const url = environment.apiurl + `evaluator?token=${token}`;
    let result = this.httpClient.get(url,{'headers':headers}).pipe(catchError(this.handleError));
    return result;
  }
}
