import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, AbstractControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ChallengeService } from '../../../services/challenge.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ShowcaseDialogComponent } from '../../../@core/miscellaneous/dialog/showcase-dialog/showcase-dialog.component';

declare var $:any;

@Component({
  selector: 'ngx-rounds',
  templateUrl: './rounds.component.html',
  styleUrls: ['./rounds.component.scss']
})
export class RoundsComponent implements OnInit {
  @ViewChild('stage', { static: true }) stage: ElementRef;
  public count=2;
  addForm: FormGroup;
  createRoundForm: FormGroup;
  submitted = false;
  editionrounds: any;
  form: any;
  stageid: any;
  externalevaluators: any;
  evaluators:any=[];
  challengename:any
  edition_name:any
  criteriaList: any;
  showtable: boolean;
  criteriaid: any;
  criteriaupdateform: FormGroup;
  submit_criteria = false;
  isValidFormSubmitted: boolean = false;
  showsave: boolean = false;
  

  constructor(
    private fb: FormBuilder,private challengeservice:ChallengeService, private router:Router , private toastrService: NbToastrService,private dialogService: NbDialogService,

  ) { }

  ngOnInit(): void {
    
    // this.getCriteriaList(this.stageid);
    this.challengename = sessionStorage.getItem('challengename');
    this.edition_name = sessionStorage.getItem('edition_name');

    this.listExternalEvaluators();
    this.form = this.fb.group({
      address: this.fb.array([])
    })
    this.removeInput(1)
    this.getRoundsByEdition();
    this.createRoundForm=this.fb.group({
      addRoundName:['',Validators.required]
    });

     //dynamic form
     this.addForm= this.fb.group(
      {
        criteria : this.fb.array([])
    //   roundsName: [
    //   ""
    //   // Validators.required,
    //   // Validators.minLength(3),
    //   // Validators.maxLength(50),
    //   ],
    //   weightage: ["" 
    //   //Validators.required
    // ],
    //   roundsDescription: [""
    //   // [Validators.required]
    // ],
      });
      this.criteriaupdateform = this.fb.group({
        name: ['',Validators.required],
        description: ['',[Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
        weightage:['',[Validators.required,Validators.min(0),Validators.max(100),Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
      })
  }
  get g(){
    return this.criteriaupdateform.controls;
  }
  getCriteriaList(stageid:any){
    this.stageid = stageid;
    sessionStorage.setItem('domid',`adderr_${stageid}`)
    this.challengeservice.getCriteriaForRound(stageid).subscribe((res:any)=>{
      console.log('res_criteria',res);
      
        this.criteriaList = res.results[0];
        console.log('criteria',this.criteria);
        if(this.criteriaList.length!=0){
        this.showtable = true;
      }
     else{
       this.showtable = false;
     }
    })
  }

  getIndividualData(c:any){
    this.criteriaid = c.id;
    this.criteriaupdateform.controls.name.setValue(c.name);
    this.criteriaupdateform.controls.description.setValue(c.description);
    this.criteriaupdateform.controls.weightage.setValue(c.weightage);


  }

  deleteCriteria(id:any) {
    console.log('delete');
    this.dialogService
      .open(ShowcaseDialogComponent, {
        context: {
          message: "Are you sure you want to delete this criteria?",
        },
      })
      .onClose.subscribe((confirmed) => {
        if (confirmed) {
          console.log('delete data')
          this.challengeservice.deletecriteria(id).subscribe((res)=>{
            console.log('res_delete_image',res)
            this.getCriteriaList(this.stageid);
          })
        }
      });
  }

  updatecriteria(){

    this.submit_criteria = true;
    if(!this.criteriaupdateform.valid){
      return;
    }
    else{
      let getcriteria = [];
      if(this.criteriaList.length!=0){
        for(var i = 0 ; i < this.criteriaList.length ; i++){
          getcriteria.push({
            id:this.criteriaList[i].id,
            name:this.criteriaList[i].name,
            description: this.criteriaList[i].description,
            weightage: this.criteriaList[i].weightage
          })
        }
      }

      console.log('getcriteria_update',getcriteria)

      for(var i = 0 ; i < getcriteria.length ; i++){
        if(getcriteria[i].id == this.criteriaid){
          getcriteria[i].weightage = +this.criteriaupdateform.controls.weightage.value;
        }
      }

    var sum = getcriteria.reduce((acc,obj)=>{
      return acc + (+obj.weightage);
    },0)
    console.log('weightage_sum_update',sum);
      console.log('form is valid');
    if(sum <= 100){
      this.challengeservice.updatecriteria(this.criteriaid,this.criteriaupdateform.value).subscribe((res)=>{
        console.log('res_update_criteria',res);
        this.criteriaupdateform.reset();
        this.submit_criteria = false;
        $('#exampleModal').modal('hide');
        this.getCriteriaList(this.stageid);
      })
    }
    else{
      this.toastrService.danger('Total weightage should not exceed 100');
    }
    }
  }
   //dynamic forms starts
    inputs = [{ field1: '', field2: '', field3: '', field4: '' }, { field1: '', field2: '', field3: '', field4: '' }];
    addInput(index: number) {
      this.inputs.splice(index + 1, 0, { field1: '', field2: '', field3: '', field4: '' });
      this.count=this.count+1;
    }
    removeInput(index: number) {
      this.inputs.splice(index, 1);
      this.count=this.count-1;
    }
    //dynamic form ends

    submitForm(){
      let data = {

      }
      this.challengeservice.createRound(data,sessionStorage.getItem('editionid')).subscribe((res)=>{
        console.log('res',res)
        this.toastrService.success("Stage added successfully");
        this.getRoundsByEdition();

      })
    }

    getRoundsByEdition(){
      this.challengeservice.getEditionRounds(sessionStorage.getItem('editionid')).subscribe((res:any)=>{
        console.log(res);
        this.editionrounds = res.results[0];
        console.log('edition rounds',this.editionrounds);
        for(var i = 0 ; i < this.editionrounds.length; i++){

        }
      })
    }

    criteria(id:any){
      this.stageid = id;
      this.router.navigate([`/admin/rounds/criteria/${this.stageid}`]).then(()=>{
        location.reload();
      })
    }

    // addCriteria(){
    //   console.log('add form value',this.form.value);
    //   for(var i = 0 ; i < this.form.value.address.length ; i++){
    //     let data = this.form.value.address[i];
    //     this.challengeservice.createCriteria(data,this.stageid).subscribe((res)=>{
    //       console.log('res_criteria',res);
    //     })
    //   }
    // }
    
    addCriteria(){
      let getcriteria = [];
      if(this.criteriaList.length!=0){
        for(var i = 0 ; i < this.criteriaList.length ; i++){
          getcriteria.push({
            name:this.criteriaList[i].name,
            description: this.criteriaList[i].description,
            weightage: this.criteriaList[i].weightage
          })
        }
      }
      this.isValidFormSubmitted = true;
      //this.isValidFormSubmitted = false;
      if(this.form.invalid){
        return;
      }
      // this.isValidFormSubmitted = true;
      let final_arr = getcriteria.concat(this.form.value.address)
      console.log('c',this.form.value.address);
      var sum = final_arr.reduce((acc,obj)=>{
        return acc + (+obj.weightage);
      },0)
      console.log('weightage_sum',sum);
      if(sum <= 100) {
        for(var i = 0 ; i < this.form.value.address.length ; i++){
          let data = this.form.value.address[i];
          this.challengeservice.createCriteria(data,this.stageid).subscribe((res)=>{
            console.log('res_criteria',res);
            this.getCriteriaList(this.stageid);
            this.form.reset();
            this.isValidFormSubmitted = false;
            $('#evalutatorslist').modal('hide');
            document.getElementById(sessionStorage.getItem('domid')).className+=' d-none'
            //this.getRoundsByEdition();
            
            for(var i = 0 ; i < this.form.get('address').length ; i++){
              this.deleteAddressGroup(i);
            }
          })
        }
      }
  
      else{
        this.toastrService.danger('Total weightage should not exceed 100');
      }
  
    }
    addNewAddressGroup1(){
      // console.log('stage_123',id);
      this.isValidFormSubmitted = false;
      console.log('stageid_init',this.stageid);
      // if(id == this.stageid){
        const add = this.form.get('address') as FormArray;
        add.push(this.fb.group({
          name: ['',Validators.required],
          description: ['',[Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
          weightage:['',[Validators.required,Validators.min(0),Validators.max(100),Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
        }))
        this.showsave = true;
      // }

    }
    addNewCriteria(){
      const add = this.addForm.get('criteria') as FormArray;
      add.push(this.fb.group({
        roundsName: [],
        weightage: [],
        roundsDescription: []
      }))
    }

  close(){
    this.form.reset();
    this.isValidFormSubmitted = false;
  }
  addNewAddressGroup(id:any) {
    console.log('stageid1234',id);
    const add = this.form.get('address') as FormArray;
    add.push(this.fb.group({
      name: [],
      description: [],
      weightage:[]
    }))
  }

  // deleteAddressGroup(index: number) {
  //   const add = this.form.get('address') as FormArray;
  //   add.removeAt(index)
  // }

  deleteAddressGroup(index: number) {
    const add = this.form.get('address') as FormArray;
    add.removeAt(index)
    console.log('length',add.length);
    if(add.length==0){
      this.showsave = false;
    }
    else{
      this.showsave = true;
    }
  }
  
  listExternalEvaluators(){
    this.challengeservice.listevaluators('external').subscribe((res:any)=>{
      this.externalevaluators = res.results[0];
      console.log('res_list',this.externalevaluators);
    })
  }

  addtoarray(event:any,value:any){
    
    if(event.target.checked==true){
      this.evaluators.push(+value);
    }
    else{
      for(var i = 0 ; i < this.evaluators.length ; i++){
        if(this.evaluators[i]==+value){
          this.evaluators.splice(i,1);
        }
      }
    }
    console.log('evaluators',this.evaluators);
  } 

  add(id:any){
    let data = {
      "evaluator_ids":this.evaluators
    }
    console.log('data',data);
    this.challengeservice.addEvaluatorstoRound(id,data).subscribe((res)=>{
      console.log('res_evaluators',res);
    })
  }

  challenges(){
    this.router.navigate(['/admin/addchallenge']).then(()=>{
      sessionStorage.setItem('edition_changed','false');
      location.reload();
    })
  }
}

