<!-- <nb-card> -->
  <!-- <nb-card-header>{{ config.message }}</nb-card-header> -->
  <!-- <nb-card-header >Are you sure you want to delete??</nb-card-header>
  <nb-card-body>
    <button nbButton class="confirm" status="primary" (click)="ref.close(true)">YES</button>
    <button nbButton status="cancel" (click)="ref.close(false)">NO</button>
  </nb-card-body>
</nb-card> -->

<!-- <div class="modal" id="confirmationDialog" tabindex="-1" role="dialog" aria-labelledby="confirmationDialogLabel" aria-hidden="true"> -->
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="confirmationDialogLabel">Confirmation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="ref.close(false)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

       {{message ? message : 'Are you sure you want to Delete?'}}
      </div>
      <div class="modal-footer d-flex justify-content-center align-items-center">
        <button type="button" class="btn btn-primary custombtn" (click)="ref.close(true)" >Yes</button>
        <button type="button" class="btn btn-secondary custombtnprev" data-dismiss="modal" (click)="ref.close(false)">No</button>
      </div>
    </div>
  </div>
<!-- </div> -->
