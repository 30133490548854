<!-- <div class="col-12 col-sm-12" *ngIf="projectmanager || superadmin">
    <nb-card>
      <nb-card-header>Incubator Details</nb-card-header>
      <nb-card-body>
        <div class="container-lg">
          <div class="table-responsive">
              <div class="table-wrapper">
                  <table class="table table-bordered">
                      <thead>
                          <tr>
                              <th>Incubator</th>
                              <th>Location</th>
                              <th class="col-sm-3">Stages</th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr >
                              <td>Centre for Innovation Incubation and Entrepreneurship (CIIE)</td>
                              <td>IIM Ahmedabad</td>
                              <td >
                                <a class="style" ><i class="fa fa-circle solid"></i></a>
                                <a class="style" ><i class="fa fa-circle solid"></i></a>
                                <a class="style" ><i class="fa fa-circle thin"></i></a>
                                <a class="style" ><i class="fa fa-circle thin"></i></a>
                              </td>
                          </tr>
                          <tr >
                            <td>KLE Centre for Technology Innovation and Entrepreneurship</td>
                            <td>KLE Technological University, Dharwad</td>
                            <td >
                              <a class="style" ><i class="fa fa-circle solid"></i></a>
                              <a class="style" ><i class="fa fa-circle solid"></i></a>
                              <a class="style" ><i class="fa fa-circle thin"></i></a>
                              <a class="style" ><i class="fa fa-circle thin"></i></a>
                            </td>
                        </tr>
                      
                        <tr >
                          <td>optum</td>
                          <td>New Delhi</td>
                          <td >
                            <a class="style" ><i class="fa fa-circle solid"></i></a>
                            <a class="style" ><i class="fa fa-circle solid"></i></a>
                            <a class="style" ><i class="fa fa-circle thin"></i></a>
                            <a class="style" ><i class="fa fa-circle thin"></i></a>
                            </td>
                      </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>  
    </nb-card-body>
  </nb-card> 
  </div> -->
<!-- <div class="container-grid col-12 col-sm-12" *ngIf="financemanager || superadmin"> -->
    <div class="d-flex mb-3">
        <div class="space-between center mr-1">
          <label class="mx-1">State</label>
          <ng-multiselect-dropdown
            [(ngModel)]="selectstate"
            [placeholder]="''"
            [settings]="dropdownSettings_s"
            [data]="dropdownstates"
            [(ngModel)]="selectedStates"
            [ngModelOptions]="{ standalone: true }"
          >
          </ng-multiselect-dropdown>
        </div>
        <div class="space-between center mr-1 space-between center">
          <label class="mx-1">City</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="selectcity"
            placeholder="Enter City"
          />
        </div>
        <div class="space-between center mr-1">
          <label class="mx-1">Domain</label>
          <ng-multiselect-dropdown
            [(ngModel)]="selectdomain"
            [placeholder]="''"
            [settings]="dropdownSettings"
            [data]="dropdownList"
            [(ngModel)]="selectedItems"
            [ngModelOptions]="{ standalone: true }"
            (onSelect)="onItemSelectDomain($event)"
            (onDeSelect)="onItemDeSelectDomain($event)"
          >
          </ng-multiselect-dropdown>
        </div>
      
        <!-- <div class="space-between center mr-1">
          <label class="mx-1">Sector</label>
          <ng-multiselect-dropdown
            [(ngModel)]="selectsector"
            [placeholder]="''"
            [settings]="dropdownSettings"
            [data]="dropdownList1"
            [(ngModel)]="selectedItems1"
            [ngModelOptions]="{ standalone: true }"
          >
          </ng-multiselect-dropdown>
        </div> -->
      
        <!-- <div class="form-group space-between center mr-1">
            <label for="exampleFormControlSelect1">Scheme</label>
            <select class="form-control" id="exampleFormControlScheme" >
              <option value="">Select</option>
              <option *ngFor="let option of options" [value]="option.id" [(ngModel)]="selectscheme">
                {{ option.name }}
              </option>
            </select>
          </div> -->
      
        <div class="space-between center mr-1">
          <label class="mx-1">Schemes</label>
          <ng-multiselect-dropdown
            [(ngModel)]="selectscheme"
            [placeholder]="''"
            [settings]="dropdownSettings_schemes"
            [data]="dropdownscheme"
            [(ngModel)]="selectedScheme"
            [ngModelOptions]="{ standalone: true }"
          >
          </ng-multiselect-dropdown>
        </div>
        <div class="space-between center mr-1">
          <label class="mx-1">Status</label>
          <!-- <select class="form-control" [(ngModel)]="selectstatus">
            <option value="">Select</option>
            <option value="APPROVED">APPROVED</option>
            <option value="REJECTED">REJECTED</option>
            <option value="PENDING">PENDING</option>
         </select> -->
          <ng-multiselect-dropdown
            [(ngModel)]="selectstatus"
            [placeholder]="''"
            [settings]="dropdownSettings"
            [data]="dropdownstatus"
            [(ngModel)]="selectedStatus"
            [ngModelOptions]="{ standalone: true }"
          >
          </ng-multiselect-dropdown>
        </div>
        <div class="space-between center bt-row">
          <!-- <label class="mx-3">.</label><br /> -->
          <button
            class="btn btn-primary mr-1"
            (click)="filterItem()"
            *ngIf="filterMode"
            id="filter"
          >
            Filter
          </button>
          <button
            class="btn btn-secondary mr-1"
            (click)="resetFilters()"
          >
            Reset
          </button>
          <button class="btn btn-success" (click)="downloadIncubatorList()"><i class="fas fa-download"></i> Download</button>
      
          <!-- <button class="btn btn-primary " [matMenuTriggerFor]="menu"> Results </button> -->
              
      <!-- <mat-menu #menu="matMenu">
        <button mat-menu-item>APPROVED <span >({{approvedCount}})</span></button>
        <button mat-menu-item>REJECTED <span >({{rejectedCount}})</span></button>
        <button mat-menu-item>PENDING <span >({{pendingCount}})</span></button>
      </mat-menu> -->
      
        </div>
      
      </div>
      
         <!-- filter count row -->
         <div class="filter-container">
           
          <div class="filter-row">
            <p (click)="countFilter('All')" [class.active]="activeFilter == null">View All 
              <!-- <span>{{approvedCount + rejectedCount + pendingCount}}</span></p> -->
            <p (click)="countFilter('APPROVED')" [class.active]="activeFilter == 'APPROVED'">APPROVED <span>{{approvedCount}}</span>
            </p>
            <p (click)="countFilter('REJECTED')" [class.active]="activeFilter == 'REJECTED'">REJECTED <span>{{rejectedCount}}</span></p>
            <p (click)="countFilter('PENDING')" [class.active]="activeFilter == 'PENDING'">PENDING <span>{{pendingCount}}</span></p>
          </div>
      
       
      </div>
      <!-- filter count row -->
      
      <div class="container-grid col-12 col-sm-12">
        <div class="p-2">
          <strong class="d-flex justify-content-center" style="font-size: 27px"
            >Investor Details</strong
          >
        </div>
        <div class="container-lg bg-white">
          <!-- <div class="table-responsive"> -->
          <div class="table-wrapper">
            <div class="loader-container" *ngIf="loading">
              <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <table class="table table-striped" *ngIf="!loading">
              <thead>
                <tr class="info">
                  <th scope="col" class="text-center">Sl No</th>
                  <th>Name</th>
                  <th>Center</th>
                  <th>State</th>
                  <th>City</th>
                  <th>Domain</th>
                  <!-- <th>Sector</th> -->
                  <!-- <th>Schemes</th> -->
                  <th>Status</th>
                  <th >view</th>
                </tr>
              </thead>
              <tbody *ngIf="data?.length > 0; else noRecords">
                <tr *ngFor="let d of data | paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index">
                <!-- <tr  *ngFor=" let d of data | paginate: { itemsPerPage: 10, currentPage: p}    " > -->
                  <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th>
                  <td>{{ d.name | titlecase }}</td>
                  <td>{{ d.affiliate_name | slice : 70 }}</td>
                  <td>{{ d.state | titlecase }}</td>
                  <td>{{ d.city | titlecase }}</td>
                  <td>{{ d.domain  }}</td>
                  <!-- <td>{{ d.sector }}</td> -->
                  <!-- <td>{{ d.scheme_names }}</td> -->
                  <td >{{ d.status }}</td>
      
                  <td class="d-flex justify-content-center">
                    <!-- <div class="dropdown mr-3">
                        <a class="dropbtn fa fa-check" style="color: blue"></a>
                        <div class="dropdown-content">
                          <a *ngIf="d.status === 'APPROVED'" (click)="changestatus(d.id, 'REJECTED')">Reject</a>
                          <a *ngIf="d.status === 'REJECTED'" (click)="changestatus(d.id, 'APPROVED')">Approve</a>
                        </div>
                        <div class="dropdown-content" *ngIf="d.status === 'PENDING'">
                          <a (click)="changestatus(d.id, 'REJECTED')">Reject</a>
                          <a (click)="changestatus(d.id, 'APPROVED')">Approve</a> -->
                    <!-- <a *ngIf="d.status === 'REJECTED'" disabled>Rejected</a> -->
                    <!-- </div>
                      </div> -->
                    <div class="d-flex">
                      <div *ngIf="d.status === 'PENDING'" class="d-flex">
                        <a
                          class="dropbtn fa fa-check mr-2"
                          style="color: blue"
                          (click)="changestatus(d.id, 'APPROVED')"
                        ></a>
                        <a
                          class="dropbtn fa fa-close mr-2"
                          style="color: red"
                          (click)="changestatus(d.id, 'REJECTED')"
                        ></a>
                      </div>
                      <div *ngIf="d.status === 'APPROVED'">
                        <a
                          class="dropbtn fa fa-close mr-2 ml-2"
                          style="color: red"
                          (click)="changestatus(d.id, 'REJECTED')"
                        ></a>
                      </div>
                      <div *ngIf="d.status === 'REJECTED'">
                        <a
                          class="dropbtn fa fa-check mr-2 ml-2"
                          style="color: blue"
                          (click)="changestatus(d.id, 'APPROVED')"
                        ></a>
                      </div>
                      <div>
                        <!-- <a
                          class="fa fa-edit ml-2 mr-2"
                          (click)="editModal(d)"
                          data-toggle="modal"
                          data-target="#editModal"
                          style="color: green"
                        ></a> -->
                        <!-- <a class="fa fa-edit ml-2 mr-2" (click)="updateScheme(d.id)" style="color: green" ></a> -->
      
                        <a class="fa fa-eye ml-2" (click)="preview(d.id)"></a>
                      </div>
                    </div>
                    <!-- <a class="fa fa-check mr-3" data-target="#approvalmodel" data-toggle="modal"  style = "color:green"></a> -->
                  </td>
                </tr>
              </tbody>
              <ng-template #noRecords>
                <tbody>
                  <tr>
                    <td colspan="9" class="no-records-found">No Data found.</td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
          </div>
      
          <!-- <pagination-controls (pageChange)="onPageChanged($event)"  directionLinks="true" autoHide="false"></pagination-controls> -->
          <pagination-controls *ngIf="data?.length > 0;" (pageChange)="onPageChange($event)" directionLinks="true" class="float-right"></pagination-controls>
      
          <!-- <pagination-controls  (click)="paginate($event)" class="float-right"></pagination-controls> -->
      
          <!-- <div class="d-flex justify-content-end ">
            <button class="btn btn-primary" (click)="handleNext(currentPage -1)" [disabled]="currentPage === 1">Previous</button>
            <a class="btn " *ngFor="let p of paginationarr" [disabled]="currentPage === p" (click)="paginate(p)" >{{p+1}}</a> 
            <button class="btn btn-primary" (click)="handlePrev(currentPage +1)">Next</button>
          </div> -->
          <!-- </div> -->
        </div>
      </div>
      
      <!-- Button trigger modal -->
      <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#approveModel">
              Launch demo modal
            </button> -->
      <!-- Modal -->
      <!-- <div
          class="modal fade"
          id="approveModel"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">...</div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
                <button type="button" class="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div> -->
      
      <!-- Update Modal -->
      <div
        class="modal custom-modal"
        id="editModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header modal-title-center">
              <strong class="modal-title-center">Update Incubator Details</strong>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="container col-sm-12 mt-5" #section>
                <form [formGroup]="modalUpdateForm">
                  <div class="form-group">
                    <strong for="">Incubator Name:</strong>
                    <input
                      class="form-control"
                      type="text"
                      placeholder=""
                      formControlName="modalIncubatorName"
                      readonly
                    />
                  </div>
                  <div class="row">
                    <div class="custom-control custom-checkbox col-md-5 my-3 ml-4">
                      <!-- <div *ngFor="let option of options" [value]="option.value" >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          formControlName="schemes"
                          [value]="option.value" 
                        />
                        <label class="custom-control-label" for="customCheck1">{{option.name }}</label>
                      </div> -->
                      <label *ngFor="let option of options">
                        <input type="checkbox" (change)="schemeval($event)" [value]="option.id" formControlName="schemes">
                        {{option.name}}
                      </label>
                    </div>
                   <!-- <div class="custom-control custom-checkbox col-md-5 my-3 ml-4">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                        formControlName="schemes"
                        [value]="Samridh"                  />
                      <label class="custom-control-label" for="customCheck1">Samridh</label>
                    </div>
                    <div class="custom-control custom-checkbox col-md-5 my-3 ml-4">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck2"
                        formControlName="schemes"
                        value="TIDE 2.0"                 />
                      <label class="custom-control-label" for="customCheck2">TIDE 2.0</label>
                    </div> -->
                    <div class="form-group col-md-5">
                      <label for="exampleFormControlSelect1">Group</label>
                      <select class="form-control" formControlName="group">
                        <option *ngFor="let option of types" [value]="option.value">
                          {{ option.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <!-- <button type="button" (click)="updateSchemes()" class="btn btn-primary">
                  Update
                </button> -->
                <button
                  class="btn btn-primary"
                  (click)="updateData()"
                  data-dismiss="modal"
                >
                  Save
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
