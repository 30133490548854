<div class="btn btn-primary ml-5 ">
  <span class="fa fa-download " (click)="downloadPdf()" style = "color:white" > Click to Download</span>  

</div>
<div class=" float-right mr-5 ">
<button class="btn btn-primary" style = "color:white" routerLink="/admin/"> Go to Dashboard</button>  
</div>

<div class="container-grid mt-2 ml-5 mr-5"  id="pdfSample">  

<div class="image-container mt-3 ml-5 mr-5 ">
    <img class="img" src="assets/images/startupbanner.png" width="100%" height="100%">
    <div class="d-flex justify-content-center template">
       <strong textContent="sample" style="font-size: 25px; color: white;">Funding Summary</strong>
    </div>
  </div>
  <div class="body-content d-flex flex-wrap">
    <div class="row custom-row">
      <div class="leftcol">Funding source</div>
      <div class="rightcol">{{incfund_summary?.incubatorfund[0].funding_source}}</div>
    </div>
    <div class="row custom-row">
      <div class="leftcol">Startups Finalized</div>
      <div class="rightcol">{{incfund_summary?.incubatorfund[0].startups_finalized_count}}</div>
    </div>
    <div class="row custom-row">
      <div class="leftcol">Tenure Years</div>
      <div class="rightcol">{{incfund_summary?.incubatorfund[0].tenureyears_count}}</div>
    </div>
  </div>
  <div class="body-content d-flex flex-wrap pb-2">
    <div class="row custom-row justify-content-center">  
      <h3 class="font-weight-bold">Project Financials</h3>
    </div>
    <table class="table table-bordered table-width">
      <thead>
        <tr>
          <th>Startup Name</th>
          <th>Grant Type</th>
          <th>Grant Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let incterm of incfund_summary?.term_accounts">
          <td style="font-size: 18px;color: blue;">{{incterm.startup_name}}</td>
          <td style="font-size: 18px;color: blue;">{{incterm.grant_type}}</td>
          <td style="font-size: 18px;color: blue;">{{incterm.grant_amount}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="body-content d-flex flex-wrap pb-2">
    <div class="row custom-row justify-content-center">  
      <h3 class="font-weight-bold">Bank Account Details</h3>
    </div>
    <div class="row custom-row">
      <div class="leftcol">Account Number</div>
      <div class="rightcol">{{incfund_summary?.incubatorfund[0].incubatorfundbankdetail?.account_number}}</div>
    </div>
    <div class="row custom-row">
      <div class="leftcol">Account Holder Name</div>
      <div class="rightcol">{{incfund_summary?.incubatorfund[0].incubatorfundbankdetail?.accountholder_name}}</div>
    </div>
    <div class="row custom-row">
      <div class="leftcol">Address</div>
      <div class="rightcol">{{incfund_summary?.incubatorfund[0].incubatorfundbankdetail?.address_line1}}</div>
    </div>
    <div class="row custom-row">
      <div class="leftcol">Bank Name</div>
      <div class="rightcol">{{incfund_summary?.incubatorfund[0].incubatorfundbankdetail?.bank_name}}</div>
    </div>
    <div class="row custom-row">
      <div class="leftcol">Account Type</div>
      <div class="rightcol">{{incfund_summary?.incubatorfund[0].incubatorfundbankdetail?.account_type}}</div>
    </div>
    <div class="row custom-row">
      <div class="leftcol">IFSC Code</div>
      <div class="rightcol">{{incfund_summary?.incubatorfund[0].incubatorfundbankdetail?.ifsc_code}}</div>
    </div>
  </div>
</div> 

