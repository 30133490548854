

<div class="container-grid col-sm-12" #section>
      <h2 class="">News</h2>
      <form
        [formGroup]="newsForm"
        class="form-control-group col-12"
       enctype="multipart/form-data"
      >
      <div class="grid-container">
        <div class="grid-item">
          <label for="">Title</label>
          <input
            type="text"
            formControlName="newsTitle"
            class="form-control"
          />
          <div *ngIf="newsForm.get('newsTitle').invalid && newsForm.get('newsTitle').touched">
            <div *ngIf="newsForm.get('newsTitle').errors.required" class="text-danger">Title is required.</div>
          </div>
        </div>
        <div class="grid-item">
          <label for="">Link</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">@</span>
            </div>
            <input type="text" class="form-control" formControlName="newsLink"
            placeholder="" aria-label="" aria-describedby="basic-addon1">
          </div>
          <div *ngIf="newsForm.get('newsLink').invalid && newsForm.get('newsLink').touched">
              <div *ngIf="newsForm.get('newsLink').errors.required" class="text-danger">URL is required.</div>
              <div *ngIf="newsForm.get('newsLink').errors.pattern" class="text-danger">Valid URL is required.</div>
          </div>   
        </div>
        <div class="grid-item"><label>Select File</label>
          <div class="custom-file">
            <!-- <input type="file" class="custom-file-input" id="customFile" name="" formControlName="uploadedImage"
              (change)="onFileSelect($event)" > -->
              <input type="file" class="custom-file-input" id="customFile" (change)="onFileSelect($event)">
            <label class="custom-file-label" for="customFile">{{fileInputLabel || 'Choose File'}}</label>
            <span style="color:black">(File Size Should Not Exceed 2MB. Allowed Extensions: PNG , JPG , SVG)</span>
            <div *ngIf="newsForm.get('uploadedImage').invalid && newsForm.get('uploadedImage').touched" style="color:red">
              <div *ngIf="newsForm.get('uploadedImage').errors.required" class="text-danger">File is required.</div>
              <p class="text-danger" *ngIf="filetypeerr"> 
                File format is incorrect
              </p>
              <p class="text-danger" *ngIf="filesizeerr"> 
                File size should not exceed 2 MB
              </p>
              <!-- <div *ngIf="newsForm.get('uploadedImage').errors?.fileSizeExceeded">File size exceeded 2 MB</div>
             <div *ngIf="newsForm.get('uploadedImage').errors?.invalidFileType">Invalid file type</div> -->
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div class="my-div">
          <div class=""  *ngIf="imageUrl">
            <img [src]="imageUrl" alt="Image preview" height="auto" width="200" alt="">
          </div>
        </div>
      </div>
      </div>

      <div class="form-control-group col-sm-12 mt-2">
        <label>Desription</label>
           <ckeditor [editor]="Editor" formControlName="newsDescription" [config]="editorConfig"></ckeditor>
           <div *ngIf="newsForm.get('newsDescription').invalid && newsForm.get('newsDescription').touched">
            <div *ngIf="newsForm.get('newsDescription').errors.required" class="text-danger">Desription is required.</div>
          </div>
       </div>
  
  </form>
      <div class="d-flex w-100 justify-content-center">
        <button class="btn custombtn mr-3 mb-3 mt-3" (click)="publishNews()" *ngIf="createMode">Create</button>
        <button class="btn custombtn mr-3 mb-3 mt-3" (click)="updateForms()" *ngIf="editMode">Update</button>
        <button class="btn custombtn mb-3 mt-3" (click)="cancel()">Cancel</button>
      </div>
      
    <!-- </div> -->
    <!-- <div class="container-grid"> -->
      <div class="col-md-6 my-2 float-right">
        <div class="custom_row">
          <input class="form-control" type="text" [(ngModel)]="searchText" placeholder="Search by Title...">
          <div class="download-bt">
            <button class="btn btn-success" (click)="downloadlabList()"><i class="fas fa-download"></i> Download</button>
          </div>
        </div>
       
      </div>
      <div class="table-wrapper table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" class="text-center">Sl No</th>
            <th>Updated Time</th>
            <th scope="col">Title</th>
            <th scope="col">Link</th>
            <th scope="col">Image</th>
            <th scope="col">Description</th>
            <th scope="col">Status</th>
            <th scope="col" width="200px">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="filteredData?.length > 0; else noRecords">
          <tr  *ngFor="let d of filteredData| paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index" >
            <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th>
            <td>{{d.updated_at |  date}}</td>
            <td class="m_col">{{ truncateText(d.news_subject, 150) }} <span data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" *ngIf="readMore"  class="readMore">Read more</span></td>
            <td class="m_col"><a href="{{d.news_link}}" target="_blank">{{d.news_link}}</a></td>
            <td class="m_col"><a href="{{d.news_image}}" target="_blank">{{d.news_image}}</a></td>
            <td class="m_col"><span [innerHtml]="truncateText(d.news_description, 150)"> </span>  <span data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" *ngIf="readMore"  class="readMore">Read more</span> </td>
            <td>{{d.news_status}} </td>

            <td >
               <!-- <div class="dropdown mr-3">
                <a class="dropbtn fa fa-check" style = "color:blue"></a>
                <div class="dropdown-content" >
                  <a *ngIf="d.news_status === 'active'" (click)="changestatus(d,'inactive')">inactive</a>
                  <a *ngIf="d.news_status === 'inactive'" (click)="changestatus(d,'active')">active</a>
                </div>
              </div> -->
              <div class="d-flex align-items-center ">
                <div *ngIf="d.news_status === 'active'">
                  <a class="fa fa-close mr-3 arrow" style="color:red"  (click)="changestatus(d,'inactive')"></a>
                </div>
                <div *ngIf="d.news_status === 'inactive'">
                    <a class="fa fa-check mr-3 arrow" style="color:blue"  (click)="changestatus(d,'active')"></a>
                </div>
                <a class="fa fa-edit mr-3 arrow" style = "color:blue" (click)="editData(d, section)" ></a>
                <a class="fa fa-trash mr-3 arrow" style = "color:red" (click)="deleteNewsData(d.news_id)" ></a>
                <a class="fa fa-eye mr-3 arrow" style = "color:green" data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" ></a>
                </div>

            </td>
          </tr>
        
        </tbody>
        <ng-template #noRecords>
          <tbody>
            <tr>
              <td colspan="6" class="no-records-found">No Data found.</td>
            </tr>
          </tbody>
        </ng-template>
      </table>
      </div>

      <pagination-controls *ngIf="filteredData?.length > 0;" (pageChange)="p = $event" class="float-right"></pagination-controls>
    </div>

<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
Launch demo modal
</button> -->

<!-- Modal -->
<div class="modal custom-modal " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content ">
    <div class="modal-header ">
      <h3 class="modal-title " id="exampleModalLabel">Preview</h3>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="container-fluid ">
        <label for="basic-url">News Title</label>
        <div class="input-group">
          <div class="input-group-prepend">
          </div>
          <input type="text" class="form-control mb-2" placeholder="" aria-label="Username" value="{{modaldata.newsTitle}}" aria-describedby="basic-addon1" readonly>
        </div>
        <label for="basic-url">News Image</label>
        <div class="input-group">
        <div class="input-group-prepend">
        </div>
        <div class="d-flex justify-content-center align-items-center mb-2">
        <img [src]="modaldata.image" alt="image" width="735" height="300">
      </div>
      </div>

        <label for="basic-url">News Description  </label>
    
     <div class="container">
      <!-- Content here -->
      <!-- <textarea class="form-control" rows="8" cols="50" aria-label="With textarea" value="{{modaldata.description}}" id="fileInput" readonly ></textarea> -->
      <div class="text_contianer form-control" [innerHtml]="modaldata.description" style="word-break: break-all; overflow-y: auto;">
             
      </div>
    </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
    </div>
  </div>
  
</div>
</div>