import { Component, OnInit } from "@angular/core";
import jspdf from "jspdf";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import html2canvas from "html2canvas";
import { GrantManagementService } from "../../../services/grant-management/grant-management.service";

@Component({
  selector: "ngx-scheme-incubator-generate-report",
  templateUrl: "./scheme-incubator-generate-report.component.html",
  styleUrls: ["./scheme-incubator-generate-report.component.scss"],
})
export class SchemeIncubatorGenerateReportComponent implements OnInit {
 
  inc_fund_id:any;
  incubator_id:any;
  incfund_summary:any;

  constructor(
    private http: HttpClient,
    private grantManagementService: GrantManagementService,
    private _route: ActivatedRoute
  ) {
    this.inc_fund_id = this._route.snapshot.paramMap.get("incid");
    this.incubator_id = this._route.snapshot.paramMap.get("incubator_id");
  }

  ngOnInit(): void {
    this.generatePDF();
  }

  generatePDF() {
    this.grantManagementService.getIncFundSummary(this.inc_fund_id).subscribe((data: any) => {
      // let data= res.results[0];
     console.log("DATA::::", data);
     this.incfund_summary=data?.results[0];
    });
  }
  downloadPdf() {
    var data1 = document.getElementById("pdfSample") as HTMLElement;
    html2canvas(data1).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 208;
      // var imgWidth = 101.6;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save(`reports_${new Date().getTime()}.pdf`); // Generated PDF
    });
  }
}
