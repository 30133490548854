import { Component, OnInit } from '@angular/core';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import * as XLSX from 'xlsx'; // Import xlsx library

@Component({
  selector: 'ngx-samridh-document-data',
  templateUrl: './samridh-document-data.component.html',
  styleUrls: ['./samridh-document-data.component.scss']
})
export class SamridhDocumentDataComponent implements OnInit {
  document:any;
  docData:any;
  errorMessage ='';
  approveMsg='';
  rejectMsg='';
  constructor(private Grandmanagement:GrantManagementService) { }

  ngOnInit(): void {
    this.getdocumentData()
  }

  getdocumentData(){
    this.Grandmanagement.documentData().subscribe((res:any)=>{
      console.log('res',res.results);
      this.document = res.results;
    })
  }
  newdata(item:any){
    this.docData = item
  }

docStatus(type:any, status:any,item:any){
  const id =item.id;
 console.log('dd',type,status)
 if(type ==='uc'){
  const payload ={
    "uc_status" : status,
}

this.Grandmanagement.approveUtilizeSan(id,payload).subscribe((res:any)=>{
console.log(res);
if(status === 'APPROVED'){
  
    this.approveMsg = 'Utilization certificate document has been approved succesfully'
    setTimeout(() => {
      this.approveMsg = '';
    }, 2000);
this.getdocumentData()

}
else if(status ==='REJECTED'){
  
    this.rejectMsg = 'Utilization certificate document has been rejected succesfully'

    setTimeout(() => {
      this.rejectMsg = '';
    }, 2000);
}
this.getdocumentData()

}),
(error)=>{
  console.log(error);
  this.errorMessage = 'Error approving Utilization certificate  document'
  setTimeout(() => {
    this.errorMessage = '';
  }, 2000);
}
 }
 else if(type === 'se'){
  const payload ={
    "se_status" : status,
}
this.Grandmanagement.approveUtilizeSan(id,payload).subscribe((res:any)=>{
  console.log(res);

  if(status === 'APPROVED'){
    this.approveMsg = 'Utilization certificate document has been approved succesfully'
    setTimeout(() => {
      this.approveMsg = '';
    }, 2000);
    this.getdocumentData();
  }
  else if(status ==='REJECTED'){
    this.rejectMsg = 'Statement of expenditure certificate document has been rejected succesfully'
    setTimeout(() => {
      this.rejectMsg = '';
    }, 2000);
    this.getdocumentData();
  }
}),
(error)=>{
  console.log(error);
  this.errorMessage = 'Error approving Statement of expenditure certificate  document'
  setTimeout(() => {
    this.errorMessage = '';
  }, 2000);
}
 }

 }
  //table download
  // downloadTableData(): void {
  //   const csvData = this.convertToCSV(this.document);
  //   const blob = new Blob([csvData], { type: 'text/csv' });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.setAttribute('hidden', '');
  //   a.setAttribute('href', url);
  //   a.setAttribute('download', 'documentData.csv');
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }

  // convertToCSV(data: any[]): string {
  //   const header = ['Accelerator Name', 'Utilization Status', 'Utilization Certificate', 'Statement Status', 'Statement of Expenditure'];
  //   const rows = data.map(item => [
  //     item.accelerator.name,
  //     item.uc_status,
  //     item.statement_of_expenditure.url,
  //     item.se_status,
  //     item.utilisation_certificate.url
  //   ]);

  //   let csvContent = header.join(',') + '\n';
  //   rows.forEach(row => {
  //     csvContent += row.join(',') + '\n';
  //   });

  //   return csvContent;
  // }

  downloadTableData(): void {
    const wb = XLSX.utils.book_new();

    // Convert data to worksheet
    const ws = XLSX.utils.aoa_to_sheet(this.convertToExcelData(this.document));
    XLSX.utils.book_append_sheet(wb, ws, 'Document Data');

    // Generate Excel file and trigger download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'documentData.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

convertToExcelData(data: any[]): any[][] {
    const header = ['Accelerator Name', 'Utilization Status', 'Utilization Certificate', 'Statement Status', 'Statement of Expenditure'];
    const rows = data.map(item => [
        item.accelerator.name,
        item.uc_status,
        item.utilisation_certificate.url,
        item.se_status,
        item.statement_of_expenditure.url
    ]);

    return [header, ...rows];
}


}
