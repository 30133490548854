 
 <div class="container-fluid">
   <div class="row bg-white p-3 shadow">
     <div class="col-12 my-3">
       <div class="d-flex justify-content-end align-items-center">
        <div class="dropdown" *ngIf = "showchallenge">
          
          <button class="btn bg-transparent border border-primary dropdown-toggle text-primary" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Actions
          </button>
          <div class="dropdown-menu border border-primary customposition" aria-labelledby="dropdownMenuButton">
            <button class="dropdown-item border-bottom border-primary" type="button" *ngIf = "editionclicked" routerLink="/admin/rounds">Round & Criteria</button>
            <button class="dropdown-item border-bottom border-primary" type="button" *ngIf="editionclicked" (click)="goto('preview')">{{applications&&applications.length==0?'Create Form':'Form Preview'}}</button>
            <button class="dropdown-item border-bottom border-primary" type="button" data-toggle="modal" data-target="#evalutatorslist" style="cursor:pointer">Manage Evaluators</button>
            <!-- <button class="dropdown-item border-bottom border-primary" data-toggle="modal" data-target="#exampleModalCenter_ext">Add External Edition </button> -->
            <button class="dropdown-item border-bottom border-primary" *ngIf = "editionclicked" type="button" >Remind Evaluators</button>
            <!-- <button class="dropdown-item border-bottom border-primary" type="button" *ngIf = "editionclicked" (click)="export_csv()">Export</button> -->
            <!-- <button class="dropdown-item border-bottom border-primary" type="button" *ngIf="showform" (click)="goto('preview')">Form Preview</button> -->
          </div>
        </div>
       </div>
     </div>
     <div class="col-6" *ngIf = "showchallenge">
      <div class="d-flex justify-content-center align-items-center columnflex bg-purple border-5 p-3">
        <p class="text-center text-white font-weight-bold">
          Challenges
        </p>
        <select name="" id="" class="form-control" (change)="show($event)">
          <option value="" [selected]="editioncreated" class="text-center">Select a challenge</option>
          <option value="{{data.id}}" [selected]="data.name == challengename_session" *ngFor="let data of tableData_challenge" class="text-center">{{data.name}}</option>
        </select>
        <div class="my-3">
          <button class="btn bg-transparent text-white border-white font-weight-bold" data-toggle="modal" data-target="#challenge">Add Challenge <i class="fa fa-plus text-white font-weight-bold"></i></button>
        </div>
      </div>
     </div>
     <div [ngClass]="!evaluator?'col-6':'col-12'">
      <div class="d-flex justify-content-center align-items-center columnflex bg-orange border-5 p-3">
        <p class="text-center text-white font-weight-bold">
          Editions
        </p>
        <ng-container *ngIf="!evaluator">
          <select name="" id="" class="form-control" (change)="geteditionid($event)">
            <option value="" class="text-center">Select an edition</option>
            <option value="{{data.id}}" [selected]="data.name == edition_name" *ngFor="let data of tableData_edition" class="text-center">{{data.name}}</option>
          </select>
        </ng-container>
        <ng-container *ngIf="evaluator">
          <ng-container *ngIf="tableData_edition.length!=0 ; else no_assigned_application">
            <select name="" id="" class="form-control" (change)="geteditionid($event)">
              <option value="" class="text-center">Select an edition</option>
              <option value="{{data.id}}" [selected]="data.name == edition_name" *ngFor="let data of tableData_edition" class="text-center">{{data.name}}</option>
            </select>
          </ng-container>

          <ng-template #no_assigned_application>
            <p class="text-center mb-0 font-weight-bold text-white">
              No application has been assigned yet
            </p>
          </ng-template>
        </ng-container>
        <div class="my-3" *ngIf="!evaluator">
          <button class="btn bg-transparent text-white border-white font-weight-bold mr-2" data-toggle="modal" data-target="#exampleModalCenter">Add Edition <i class="fa fa-plus text-white font-weight-bold"></i></button>
        
        </div>
      </div>
     </div>
   </div>

   <div class="row bg-white my-3 p-3 shadow" *ngIf = "editionclicked"> 
    <div class="col-12">
      <div class="d-flex w-100 justify-content-between align-items-center my-3">
        <div class="d-flex justify-content-start align-items-center overflow_auto">
          <button class="mr-3 bg-transparent border-5 border border-dark px-3 py-2 screening activestage" id="screening" *ngIf = "editionclicked && !evaluator" (click)="getapplicationsinscreeing()">
            Screening
          </button>
          <button class="mr-3 bg-transparent border-5 border border-dark px-3 py-2" [class.activestage]="selectedindex==i" (click)="criteria(e.id,i)" *ngFor = "let e of editionrounds;let i = index">
            Stage {{e.order}}
          </button>

          <button class="mr-3 bg-transparent border-5 border border-dark px-3 py-2 screening" id="selected" *ngIf = "editionclicked && !evaluator" (click)="getapplications_selected()">
            Selected
          </button>
        </div>
    
        <div class="d-flex justify-content-center align-items-center">
          <!-- <div class="dropdown mr-3"> -->
            <button class="btn bg-transparent border border-primary text-primary mr-3" [disabled]="disabled" *ngIf="showassignevaluators && !evaluator" type="button" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false" data-toggle="modal" data-target="#assignevaluators" aria-haspopup="true" aria-expanded="false">
              Assign Evaluators
            </button>

            
          <!-- </div> -->

          <div class="dropdown" *ngIf="!evaluator && !disabled_m">
            <button class="btn bg-transparent border border-primary text-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Manage Status
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="keeponhold()">Hold</a>
              <a class="dropdown-item" *ngFor = "let e of editionrounds;let i = index" (click)="movetoround(e.id)">Stage {{e.order}}</a>
              <a class="dropdown-item" (click)="reject()">Reject</a>
              <a class="dropdown-item" (click)="select()">Select</a>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive" *ngIf="submittedapplications&&submittedapplications.length!=0;else nodata">
        <div class="w-100 d-flex justify-content-end align-items-center mb-3">
          <button class="btn btn-primary custombtn" type="button" *ngIf = "editionclicked && !evaluator" (click)="export_xlsx()">Export</button>
        </div>
        <table class="table border border-dark">
          <thead>
              <tr>
                  <th class="border border-dark" *ngIf="!evaluator">#</th>
                  <th class="border border-dark">Company</th>
                  <th class="border border-dark">Location</th>
                  <th class="border border-dark">Industry</th>
                  <th class="border border-dark">Date Added</th>
                  <!-- <th class="border border-dark">Documents</th> -->
                  <th class="border border-dark">Score</th>
                  <th class="border border-dark">Status</th>
                  <th class="border border-dark">View</th>
              </tr>
          </thead>
          <tbody *ngIf="evaluator">

              <tr *ngFor = "let s of submittedapplications">
              
                  <td class="border border-dark" *ngIf="!evaluator"><input type="checkbox" (change)="addtolist($event,s)"></td>
                  <td class="border border-dark">{{s.company_profile.companyname}}</td>
                  <td class="border border-dark">{{s.company_profile.cityc}}</td>
                   <td class="border border-dark">{{s.company_profile.industryc}}</td>
                   <td class="border border-dark">{{s.created_at}}</td>
                   <!-- <td class="border border-dark">
                     <ul class="list-unstyled d-flex">
                       <li *ngFor = "let d of s.documents">
                          {{d.path}} ,
                       </li>
                     </ul>
                   </td> -->
                   <td class="border border-dark">
                     {{s.my_score=="-1"?"-" : s.my_score}}
                   </td>
                 
                   <td class="border border-dark">{{s.progress_state}}</td>    
                   <td class="border border-dark"><a class="fa fa-eye" (click)="getStatus(s.progress_state)" routerLink="/admin/application/{{s.id}}"></a></td>             
                </tr>

                 
          </tbody>

          <tbody *ngIf="!evaluator">

            <tr *ngFor = "let s of submittedapplications">
           
                <td class="border border-dark"><input type="checkbox" (change)="addtolist($event,s)"></td>
                <td class="border border-dark">{{s.company_profile.companyname}}</td>
                <td class="border border-dark">{{s.company_profile.cityc}}</td>
                 <td class="border border-dark">{{s.company_profile.industryc}}</td>
                 <td class="border border-dark">{{s.created_at}}</td>
                 <!-- <td class="border border-dark">
                   <ul class="list-unstyled d-flex">
                     <li *ngFor = "let d of s.documents">
                        {{d.path}} ,
                     </li>
                   </ul>
                 </td> -->
                 <td class="border border-dark">
                   {{s.score_average=="-1"?"-" : s.score_average}}
                 </td>
               
                 <td class="border border-dark">{{s.progress_state}}</td>    
                 <td class="border border-dark"><a class="fa fa-eye" (click)="gotoindividual(s.id , s.score_average, s.progress_state)"></a></td>             
              </tr>

               
        </tbody>
      </table> 
      <ng-template #nodata>
        <p class="no-records-found" *ngIf = "editionclicked && !evaluator">No Data found.</p>
      
      </ng-template>
      </div>
      
    </div>
   </div>
 </div>

 



<div class="modal fade bd-example-modal-lg" id="evalutatorslist" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content p-4">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="w-100 align-items-center p-3 border-bottom border-dark mb-2">
              <h2 class="text-center mb-0">
                Manage Evaluators
              </h2>
            </div>
   
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 justify-content-center align-items-center">
            <div class="w-100 align-items-center mb-3">
              <p class="text-center mb-0 font-weight-bold">
                Internal Evaluators
              </p>
            </div>
            <div>
              <ul class="list-unstyled">
                <li class="d-flex justify-content-start align-items-center">
                  <div class="round_internal mr-3">

                  </div>
                  <div>
                    <p class="mb-0">
                      Sandeep Reddy Pallavolu
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-6 justify-content-center align-items-center">
            <div class="w-100 align-items-center mb-3">
              <p class="text-center mb-0 font-weight-bold">
                External Evaluators
              </p>
            </div>
            <div>
              <ul class="list-unstyled">
                <li class="d-flex justify-content-start align-items-center mb-2" *ngFor="let e of externalevaluators">
                  <div class="round_internal mr-3">

                  </div>
                  <div>
                    <p class="mb-0">
                      {{e.first_name}} {{e.last_name}}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
            <div class="col-12">
              <div class="w-100">
                <a class="float-right text-primary font-weight-bold" data-toggle="modal" data-dismiss="modal" href="#externalevaluators">+ Invite External Evaluators</a>
              </div>
            </div>
        </div>

        <div class="row">
          <div class="w-100 d-flex justify-content-center align-items-center">
            <button class="btn btn-primary custombtn" data-dismiss="modal">
              dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade bd-example-modal-lg" id="externalevaluators" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content p-4 h-500-scroll">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="w-100">
              <h3 class="text-center">
                Invite external evaluators
              </h3>
              <p class="text-center">
                Members will be notified through email about the invitation
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Company Email</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor = "let e of externalevaluators">
                  <td>{{e.first_name}}</td>
                  <td>{{e.last_name}}</td>
                  <td>{{e.email}}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
        
            <div [formGroup]="addForm">

     
            
                <div *ngFor="let addressGroup of form.get('address').controls;let i = index" [formGroup]="addressGroup" class="form-row align-items-center mb-3">
            
               
            
                 
                    <div class="col-3">
                      <span class="text-danger ml-1 font-weight-bold float-right showlabel">*</span>
                      <input formControlName="first_name" placeholder="First Name" class="form-control">
                      <p *ngIf="form.get('address').controls[i].get('first_name').errors?.required && isValidFormSubmitted != null && !isValidFormSubmitted"
                        class="text-danger pos_abs mb-0">
                      First name is required
                    </p>
                    </div>
                    
                    <div class="col-3">
                      <span class="text-danger ml-1 font-weight-bold float-right showlabel">*</span>
                      <input formControlName="last_name" placeholder="Last Name" class="form-control">
                      <p *ngIf="form.get('address').controls[i].get('last_name').errors?.required && isValidFormSubmitted != null && !isValidFormSubmitted"
                        class="text-danger pos_abs mb-0">
                      Last name is required
                      </p>
                    </div>
                    
                     <div class="col-3">
                      <span class="text-danger ml-1 font-weight-bold float-right showlabel">*</span>
                      <input formControlName="email" placeholder="Email" class="form-control">
                      <p *ngIf="form.get('address').controls[i].get('email').errors?.required && isValidFormSubmitted != null && !isValidFormSubmitted"
                        class="text-danger pos_abs mb-0">
                      Email is required
                      </p>
                      <p *ngIf="form.get('address').controls[i].get('email').errors?.pattern && isValidFormSubmitted != null && !isValidFormSubmitted"
                        class="text-danger pos_abs mb-0">
                      Please enter a valid email
                      </p>
                    </div>
            
                    <div class="col-3">
                        <span class="text-danger ml-1 font-weight-bold float-right showlabel visibilityhidden">*</span>
                        <button (click)="deleteAddressGroup(i)" class="btn btn-danger w-100">Remove</button>
                   
                    </div>
              
                   
                    
                </div>
               
                <div class="w-100 setminheight mt-3">
                  <a class="float-right text-primary font-weight-bold"  (click)="addNewAddressGroup1()">+Add more people</a>
                </div>
                <div class="d-flex justify-content-center align-items-center clearall">
                  <button class="btn btn-primary mr-3 custombtn" (click)="addEvaluator()">Save</button>
                  <button class="btn btn-danger custombtnprev" data-dismiss="modal" (click)="cancel()">Cancel</button>
                </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="modal fade bd-example-modal-lg" id="assignevaluators" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content p-4">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="w-100 align-items-center p-3 border-bottom border-primary mb-2">
              <h2 class="text-center mb-0">
                Assign Evaluators
              </h2>
            </div>
   
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 justify-content-center align-items-center">
            <div class="w-100 align-items-center mb-3">
              <p class="text-center mb-0 font-weight-bold">
                Select Evaluators
              </p>
            </div>

            <div>
              <div>
                <input type="text" name="" id="" class="form-control" (keyup)="searchselect($event)">
              </div>
              <ng-container *ngIf="!search;else alldata">
                <div class="d-flex justify-content-between align-items-center bg-custom shadow p-3 my-3" *ngFor="let s of selectevaluators">
                  <div>
                    <h3 class="mb-0">
                      {{s.first_name}} {{s.last_name}}
                    </h3>
                    <p>
                      External Evaluator
                    </p>
                  </div>
                  <div>
                    <a class="text-primary" (click)="assignevaluator(s)">+ Assign</a>
                  </div>
                </div>
              </ng-container>
              <ng-template #alldata>
                <div class="d-flex justify-content-between align-items-center bg-custom shadow p-3 my-3" *ngFor="let s of selectevaluators_search">
                  <div>
                    <h3 class="mb-0">
                      {{s.first_name}} {{s.last_name}}
                    </h3>
                    <p>
                      External Evaluator
                    </p>
                  </div>
                  <div>
                    <a class="text-primary" (click)="assignevaluator(s)">+ Assign</a>
                  </div>
                </div>
              </ng-template>
            </div>
       
          </div>

          <div class="col-6 justify-content-center align-items-center">
            <div class="w-100 align-items-center mb-3">
              <p class="text-center mb-0 font-weight-bold">
                Selected Evaluators
              </p>
            </div>
            <div>
              <div>
                <input type="text" name="" id="" class="form-control" (keyup)="searchselected($event)">
              </div>
              <ng-container *ngIf = "!search_selected;else alldata_search">
                <p></p>
                <div class="d-flex justify-content-between align-items-center bg-custom shadow p-3 my-3" *ngFor="let s of selectedevaluators">
                  <div>
                    <h3 class="mb-0">
                      {{s.first_name}} {{s.last_name}}
                    </h3>
                    <p>
                      External Evaluator
                    </p>
                  </div>
                  <div>
                    <a class="text-danger" (click)="removeevaluator(s)">- Remove</a>
                  </div>
                </div>
              </ng-container>
              <ng-template #alldata_search>
                <div class="d-flex justify-content-between align-items-center bg-custom shadow p-3 my-3" *ngFor="let s of selectedevaluators_search">
                  <div>
                    <h3 class="mb-0">
                      {{s.first_name}} {{s.last_name}}
                    </h3>
                    <p>
                      External Evaluator
                    </p>
                  </div>
                  <div>
                    <a class="text-danger" (click)="removeevaluator(s)">- Remove</a>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="w-100 d-flex justify-content-center align-items-center">
            <button class="btn btn-primary custombtn mr-3" (click)="add()">
              Save
            </button>
            <button class="btn btn-primary custombtnprev" data-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>~
      </div>
    </div>
  </div>
</div>
 


<div class="modal fade" id="exampleModalCenter" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-center align-items-center pos_rel">
        <h3 class="modal-title text-center mb-0 pos_abs" id="exampleModalCenterLabel">Edition Form</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="container-fluid">
            <form [formGroup]="editionForm" >
           
                 <div class="form-group col-md-12 ">
                  <label for="">Challenge Name</label>
                 <input type="text" name="" id="" readonly class="form-control" value="{{challengename_session}}">
                 </div>
              <div class="form-group col-md-12 ">
                <label for="">Edition Name <span style="color: red;">*</span></label>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  status="basic"
                  name="name"
                  id="input-name"
                  placeholder="Edition Name"
                  autofocus=""
                  formControlName="editionName"
                  class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                 
                />
                <div *ngIf="submitted_edition && g.editionName.errors" class="text-danger">
                  <div  class="custom_font">Name is required</div>
                  <!-- <div *ngIf="g.editionName.errors['pattern']" class="custom_font">Please enter a valid name</div> -->
                </div>
              </div>
             <div class="form-group col-md-12 ">
              <label for="">Edition Description <span style="color: red;">*</span></label>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  status="basic"
                  name="description"
                  id="input-name"
                  placeholder="Description"
                  autofocus=""
                  formControlName="editiondesc"
                  class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                  
                 />
                 <div *ngIf="submitted_edition && g.editiondesc.errors" class="text-danger">
                  <div class="custom_font">Description is required</div>
                  <!-- <div *ngIf="g.editiondesc.errors['pattern']" class="custom_font">Please enter a valid description</div> -->
                </div>
              </div>
                <div class="form-group col-md-12">
                  <label for="">Start Date <span style="color: red;">*</span></label>
                  <input type="date" formControlName="startRange"
                    class="form-control date" style="margin-left: 0;"
                     [min]="mindate" (change)= "getstartdate($event)"
                  />
                  <div *ngIf="submitted_edition && g.startRange.errors" class="text-danger">
                    <div class="custom_font">Start date is required</div>
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <label for="">End Date <span style="color: red;">*</span></label>
                  <input type="date" formControlName="endRange" style="margin-left: 0;"
                    class="form-control date" id="end_date" [min]="mindate_end" readonly
                  />
                  <div *ngIf="submitted_edition && g.endRange.errors" class="text-danger">
                    <div class="custom_font">End date is required</div>
                    
                  </div>
                </div>

                <div class="form-group col-md-12">
                  <label class="mr-2 mb-0">Select File <span style="color: red;">*</span></label>
                  <div>
                    <p class="fs-11 font-weight-bold">
                    (File Size Should Not Exceed 2MB. Allowed Extensions: PNG , JPG , SVG)
                  </p>
                  </div>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="customFile" name="uploadedImage"
                      (change)="onFileSelect($event)" #UploadFileInput >
                      <div *ngIf="submitted_edition && g.uploadedImage.errors" class="text-danger">
                        <div class="custom_font">Document is required</div>
                        </div>
                        <p class="text-danger" *ngIf="filetypeerr"> 
                          File format is incorrect
                        </p>
                        <p class="text-danger" *ngIf="filesizeerr"> 
                          File size should not exceed 2 MB
                        </p>

                    <label class="custom-file-label" for="customFile">{{fileInputLabel || 'Choose File'}}</label>
                  </div>
                </div>
           </form>
        </div>
      </div>
      <div class="modal-footer w-100 justify-content-center align-items-center d-flex">
       
          <button nbButton (click)="createEdition()" [disabled]="filesizeerr || filetypeerr" status="primary"
              size="small"
              class="btn custombtn" >{{editMode? 'Update':'Save'}}</button>
              <button nbButton status="primary"
              size="small"
              class="btn custombtnprev" data-dismiss="modal">Cancel</button>     
      
       
  
      </div>
    </div>
  </div>
</div>    


<div class="modal fade" id="exampleModalCenter_ext" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-center align-items-center pos_rel">
        <h3 class="modal-title text-center mb-0 pos_abs" id="exampleModalCenterLabel">Edition Form</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="container-fluid">
            <form [formGroup]="editionForm_ext" >
              <!-- <div class="form-group col-md-12 ">
                <input
                  type="text"
                  nbInput
                  fullWidth
                  status="basic"
                  name="name"
                  id="input-name"
                  placeholder="Challenge Name"
                  autofocus=""
                  formControlName="challengeName_ext"
                  class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                  [ngClass]="{ 'is-invalid': submitted_edition && g.challengeName_ext.errors }"
                />
              </div> -->
              <div class="form-group col-md-12 ">
                <input
                  type="text"
                  nbInput
                  fullWidth
                  status="basic"
                  name="name"
                  id="input-name"
                  placeholder="Enter Name"
                  autofocus=""
                  formControlName="editionName_ext"
                  class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                  [ngClass]="{ 'is-invalid': submitted_edition && g.editionName_ext.errors }"
                />
                <div *ngIf="submitted_edition && g.editionName_ext.errors" class="text-danger">
                  <div *ngIf="g.editionName_ext.errors['required']" class="custom_font">Name is required</div>
                  <div *ngIf="g.editionName_ext.errors['pattern']" class="custom_font">Please enter a valid name</div>
                </div>
              </div>
             <div class="form-group col-md-12 ">
                <input
                  type="text"
                  nbInput
                  fullWidth
                  status="basic"
                  name="description"
                  id="input-name"
                  placeholder="Enter Description"
                  autofocus=""
                  formControlName="editiondesc_ext"
                  class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                  [ngClass]="{ 'is-invalid': submitted_edition && g.editiondesc_ext.errors }"
                 />
                 <div *ngIf="submitted_edition && g.editiondesc_ext.errors" class="text-danger">
                  <div *ngIf="g.editiondesc_ext.errors['required']" class="custom_font">Description is required</div>
                  <div *ngIf="g.editiondesc_ext.errors['pattern']" class="custom_font">Please enter a valid description</div>
                </div>
              </div>

              <div class="form-group col-md-12 ">
                <input
                  type="url"
                  nbInput
                  fullWidth
                  status="basic"
                  name="url"
                  id="input-name"
                  placeholder="Enter Application URL"
                  autofocus=""
                  formControlName="application_url"
                  class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                  [ngClass]="{ 'is-invalid': submitted_edition && g.application_url.errors }"
                 />
                 <div *ngIf="submitted_edition && g.application_url.errors" class="text-danger">
                  <div *ngIf="g.application_url.errors['required']" class="custom_font">URL is required</div>
                  <div *ngIf="g.application_url.errors['pattern']" class="custom_font">Please enter a valid URL</div>
                </div>
              </div>
                <div class="form-group col-md-12">
                  <label for="">Start Date</label>
                  <input type="date" formControlName="startRange_ext"
                    class="form-control date"
                    [ngClass]="{ 'is-invalid': submitted_edition && g.startRange_ext.errors }" [min]="mindate_ext" (change)= "getstartdate_ext($event)"
                  />
                  <div *ngIf="submitted_edition && g.startRange_ext.errors" class="text-danger">
                    <div *ngIf="g.startRange_ext.errors['required']" class="custom_font">Start date is required</div>
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <label for="">End Date</label>
                  <input type="date" formControlName="endRange_ext"
                    class="form-control date" id="end_date_ext" [ngClass]="{ 'is-invalid': submitted_edition && g.endRange_ext.errors }" [min]="mindate_end_ext" readonly
                  />
                  <div *ngIf="submitted_edition && g.endRange_ext.errors" class="text-danger">
                    <div *ngIf="g.endRange_ext.errors['required']" class="custom_font">End date is required</div>
                  </div>
                </div>

                <div class="form-group col-md-12">
                  <label class="mr-2 mb-0">Select File</label>
                  <div>
                    <p class="fs-11 font-weight-bold text-danger">
                    (File Size Should Not Exceed 2MB. Allowed Extensions: PNG , JPG , SVG)
                  </p>
                  </div>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="customFile" name="uploadedImage_ext" [ngClass]="{ 'is-invalid': submitted_edition && g.uploadedImage_ext.errors }"
                      (change)="onFileSelect_ext($event)" #UploadFileInput_ext >
                      <div *ngIf="submitted_edition && g.uploadedImage_ext.errors" class="text-danger">
                        <div *ngIf="g.uploadedImage_ext.errors['required']" class="custom_font">Document is required</div>
                        </div>
                        <p class="text-danger" *ngIf="filetypeerr_ext"> 
                          File format is incorrect
                        </p>
                        <p class="text-danger" *ngIf="filesizeerr_ext"> 
                          File size should not exceed 2 MB
                        </p>

                    <label class="custom-file-label" for="customFile">{{fileInputLabel_ext || 'Choose File'}}</label>
                  </div>
                </div>
           </form>
        </div>
      </div>
      <div class="modal-footer w-100 justify-content-center align-items-center d-flex">
       
          <button nbButton (click)="createExternalEdition()" [disabled]="filesizeerr_ext || filetypeerr_ext" status="primary"
              size="small"
              class="btn custombtn" >{{editMode? 'Update':'Save'}}</button>
              <button nbButton status="primary"
              size="small"
              class="btn custombtnprev" data-dismiss="modal">Cancel</button>     
      
       
  
      </div>
    </div>
  </div>
</div>   

<div class="modal fade" id="challenge" tabindex="-1" data-backdrop="static" role="dialog" aria-labelledby="challengeLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-center align-items-center pos_rel">
        <h3 class="modal-title text-center mb-0 pos_abs" id="challengeLabel">Challenge Form</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cleardata()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
        [formGroup]="challengeForm"
         class="step-container"
      >
        <div class="row">
          <div class="form-control-group col-sm-12">
            <input
              type="text"
              nbInput
              fullWidth
              status="basic"
              name="challengeName"
              id="input-name"
              placeholder="Challenge Name"
              autofocus=""
              formControlName="challengeName"
              class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
              [ngClass]="{ 'is-invalid': submitted && f.challengeName.errors }"
             />
             
             <div *ngIf="submitted && f.challengeName.errors" class="text-danger">
              <div *ngIf="f.challengeName.errors['required']" class="custom_font">Name is required</div>
              <div *ngIf="f.challengeName.errors['pattern']" class="custom_font">Please enter a valid name</div>
            </div>
          </div>

          </div>
          <div class="row">

           <div class="form-control-group col-sm-12">
             <input
               type="text"
               nbInput
               fullWidth
               status="basic"
               name="description"
               id="input-name"
               placeholder="Description"
               autofocus=""
               formControlName="description"
               class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
               [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
             />
             <div *ngIf="submitted && f.description.errors" class="text-danger">
              <div *ngIf="f.description.errors['required']" class="custom_font">Description is required</div>
              <div *ngIf="f.description.errors['pattern']" class="custom_font">Please enter valid description</div>
            </div>
           </div>
           </div>
        
          </form>
      </div>
      <div class="modal-footer d-flex justify-content-center align-items-center w-100">
        <button type="button" class="btn custombtnprev" data-dismiss="modal" (click)="cleardata()">Cancel</button>
        <button type="button" (click)="createChallenge()" class="btn custombtn">Save</button>
      </div>
    </div>
  </div>
</div>
  
