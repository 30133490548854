import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnyAaaaRecord, AnyRecord } from 'dns';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AxiosServicesService } from './axios/axios-services.service';
const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Authorization', `Bearer ${sessionStorage.getItem('admintoken')}`);
@Injectable({
  providedIn: 'root'
})
export class MentorService {

  //baseUrl = "http://43.205.193.210:8090";
  //baseUrl = "http://43.205.193.210:8090";
  baseUrl = environment.apiurl;

  constructor(
    private http: HttpClient,
    private axiosServices: AxiosServicesService
  ) { }

  saveRegistrationForm(data:any) {
    let url = this.baseUrl + "mentors/register/registrationForm";
    return this.http.post(url,data, { headers: headers } ).pipe(catchError(this.handleError));
  }

  getRegistrationForm(formid):Observable<any>{
    let url = `${this.baseUrl}mentors/register/registrationForm?formId=${formid}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  getAllRegistrationForms():Observable<any>{
    let url = `${this.baseUrl}mentors/register/registrationForm/all`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  changeStatus(data:any):Observable<any>{
    let url = `${this.baseUrl}mentors/register/registrationForm/status`;
    return this.http.put(url,data).pipe(catchError(this.handleError));
  }

  getScoreSchema():Observable<any>{
    let url = `${this.baseUrl}mentors/gurukulscore/schema`
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  getIndividualMentor(mentorid:string):Observable<any>{
    let url = `${this.baseUrl}mentors/${mentorid}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
