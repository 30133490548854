<!-- Guest House form-->
<div class="d-flex justify-content-center bg-white mb-3">
  <h2 class="h2 mr-5">{{ facility_type }} Booking Details</h2>
</div>
<div class="container-grid" id="lab_form">
  <form [formGroup]="bookingFilterForm">

 
    <div class="d-flex mb-2 pt-3 pr-3"> 
      <div class="col-sm-3">
        <label class="mx-3">User Type</label>    
        <select  id="selectedUserType"  class="form-select usertypeselect" (change)="onUserSelectionChange($event)" formControlName="selectedUserType"> <!--(change)="onUserSelectionChange($event)"-->
          <option value="ALL">Select User Type</option>
          <!-- <option value="STARTUP">STARTUP</option> -->
          <option value="INCUBATOR" selected>INCUBATOR</option>
           <option value="COE">COE</option>
           <option value="ACCELERATOR">ACCELERATOR</option>
           <option value="ALL">SUPERADMIN</option>
       </select>     
      
    </div>
    <div class="col-sm-3">
      <label class="mx-3">User</label>    
  
      <ng-multiselect-dropdown 
      [placeholder]="''"
      [settings]="dropdownSettings"
      [data]="dropdownuser"
      [(ngModel)]="selectedUser"
      [ngModelOptions]="{standalone: true}"
      
    >
</ng-multiselect-dropdown>

  </div> 
  
  <div class="col-sm-3">
    <label for="" class="mx-3">From Date</label>
    <input type="date" formControlName="start_date" id="start_date"
      class="form-control date" />   
    
  </div>
  <div class="col-sm-3">
    <label for="" class="mx-3">To Date</label>
    <input type="date" formControlName="end_date" id="end_date"
      class="form-control date" style="margin: 0;"/>
  </div>
    
        
         
       
        
         
        </div>
      
    <div class="d-flex mb-3 pr-3">

     
      <div class="col-sm-3">
        <label for="" class="mx-3">States</label>             
            <select class="form-control custominputborder" (change)="getCities($event)" formControlName="state">
              <option value="0" selected>Select State</option>
              <option value="{{s}}" *ngFor = "let s of states">{{s}}</option>
            </select>
            </div>
            <div class="col-sm-3">
              <label for="" class="mx-3">Cities</label>           
          
            <select class="form-control custominputborder" (change)="getCity($event)" formControlName="city">
              <option value="0" selected>Select A City</option>
              <option value="{{c}}" *ngFor="let c of selectedcities">{{c}}</option>
            </select>
              </div>
              <div class="col-sm-3">
                <label class="mx-3">Status</label>    
              <ng-multiselect-dropdown 
                          [placeholder]="''"
                          [settings]="dropdownSettingst"
                          [data]="dropdownstatus"
                          [(ngModel)]="selectedStatus"
                          [ngModelOptions]="{standalone: true}"
                          
                        >
                </ng-multiselect-dropdown>
              </div>
      <div class="col-sm-3">
        <label class="mx-3" style="visibility: hidden;">.</label><br>
        <button class="btn btn-primary mr-1" id="filter" (click)="filterItem()"> Filter </button> 
         <!-- <button class="btn btn-primary mr-1" id="filter"> Filter </button> -->
        <button class="btn btn-secondary mr-1" (click)="resetFilters()">Reset</button>
        <button class="btn btn-success mr-1 mt-2" (click)="downloadTableData()"><i class="fas fa-download"></i> Download</button>
      </div>
    </div>
      
  
      </form>
    
      <div class="filter-container">
     
        <div class="filter-row">
          <p (click)="countFilter('ALL')" [class.active]="activeFilter == null">View All <span> {{approvedCount + rejectedCount + pendingCount}}</span></p>
          <p (click)="countFilter('CONFIRMED')" [class.active]="activeFilter == 'CONFIRMED'">CONFIRMED  <span> {{approvedCount}}</span></p>
          <p (click)="countFilter('DECLINED')" [class.active]="activeFilter == 'DECLINED'">DECLINED  <span> {{rejectedCount}}</span></p>
          <p (click)="countFilter('PENDING')" [class.active]="activeFilter == 'PENDING'">PENDING  <span> {{pendingCount}}</span></p>
        </div>
    
     
    </div>
  <div class="" #section>
    <table class="bordered-table table" id="tbl_exporttable_to_xls">
      <thead class="thead-dark">
        <tr>
          <th scope="col" class="text-center">Sl No</th>
          <th>Booked By</th>
          <th>{{ facility_type }} Name</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th *ngIf="facility_type != 'Venue Booking'">Description</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list | paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index">
          <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th>
          <!-- <td>{{item.sections.list.travellers_details.name}}</td> -->
          <td>{{ item.sections.list[0].travellers_details.name }}</td>
          <td>{{ item.resource_name }}</td>
          <td>{{ item.start_date | date:'dd/MM/yyyy'}}</td>
          <td>{{ item.end_date | date:'dd/MM/yyyy' }}</td>
          <td *ngIf="facility_type != 'Venue Booking'"><span *ngIf="item.sections.list[0].description != 'null'">{{item.sections.list[0].description}}</span></td>
          <td>{{ item.status }}</td>
          <td>
            <span class="d-flex align-items-center">
              <!-- <div *ngIf="item.status === 'PENDING'" class="d-flex">
                <a
                  class="dropbtn fa fa-check mr-2"
                  style="color: blue"
                  (click)="changestatus(item.id, 'CONFIRMED')"
                ></a>
                <a
                  class="dropbtn fa fa-close mr-2"
                  style="color: red"
                  (click)="changestatus(item.id, 'DECLINED')"
                ></a>
              </div>
              <div *ngIf="item.status === 'CONFIRMED'">
                <a
                  class="dropbtn fa fa-close mr-2 ml-4"
                  style="color: red"
                  (click)="changestatus(item.id, 'DECLINED')"
                ></a>
              </div>
              <div *ngIf="item.status === 'DECLINED'">
                <a
                  class="dropbtn fa fa-check mr-4"
                  style="color: blue"
                  (click)="changestatus(item.id, 'CONFIRMED')"
                ></a>
              </div> -->
              <a
              class="fa fa-eye ml-2 cursor-pointer"
              style="color: green"
              data-toggle="modal"
              alt="View" title="View" 
              data-target="#exampleModal"
              (click)="preview(item)"
            ></a>
            </span>
          </td>
        </tr>
       
      
      </tbody>
    </table>
    <p class="text-center" *ngIf="data && data.length < 1">No data found</p>
    <div class="pagination_right">
      <pagination-controls *ngIf="paginationEnabled && data?.length > 0;" (pageChange)="p = $event" class="float-right"></pagination-controls>
    </div>
  </div>
  <!-- Guest House form-->

  <!-- Modal -->
<div
class="modal custom-modal"
id="exampleModal"
tabindex="-1"
role="dialog"
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div class="modal-dialog modal-dialog-scrollable" role="document">
  <div class="modal-content d-flex">
    <div class="modal-header">
      <h3 class="modal-title" id="exampleModalLabel">View Resource Booked Details</h3>
      <!-- <p>Resource type {{ facility_type }}</p> -->
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12"><label class="font-weight-bold">Resource Name:</label> {{resourceordermodaldata.resource_name | titlecase }}</div>                
      </div> 
      
      <div class="row">
        <div class="col-md-12"><label class="font-weight-bold">Booking Start Date:</label> {{resourceordermodaldata.start_date | date }}</div>                
      </div>

      <div class="row">
        <div class="col-md-12"><label class="font-weight-bold">Booking End Date:</label> {{resourceordermodaldata.end_date | date }}</div>                
      </div>

      <div class="row" *ngIf="facility_type == 'Co-Working Space'">
        <div class="col-md-12"><label class="font-weight-bold">Number of hours</label> {{resourceordermodaldata.sections.list[0].number_hour }}</div>                
      </div>

      <div class="row" *ngIf="facility_type != 'Venue'">
        <div class="col-md-12"><label class="font-weight-bold">Description:</label> {{resourceordermodaldata.sections.list[0].travellers_details.description }}</div>            
      </div>

      <div class="row" *ngIf="facility_type == 'Venue'">
        <div class="col-md-12"><label class="font-weight-bold">Reason For Travel:</label> {{resourceordermodaldata.sections.list[0].travel_reason}}</div>                
      </div>

      <div class="row" *ngIf="facility_type == 'Venue'">
        <div class="col-md-12"><label class="font-weight-bold">Travelling From:</label> {{resourceordermodaldata.sections.list[0].travelling_from}}</div>                
      </div>
      

      <div class="row" *ngIf="facility_type == 'Venue'">
        <div class="col-md-12"><label class="font-weight-bold">No. Of Days Of Occupancy:</label> {{resourceordermodaldata.sections.list[0].no_of_occupancy_days }}</div>                
      </div>

      <div class="row" *ngIf="facility_type == 'Venue'">
        <div class="col-md-12"><label class="font-weight-bold">No. Of People Accompanying:</label> {{resourceordermodaldata.sections.list[0].no_of_people_accompanying}}</div>                
      </div>

      <div class="row py-1">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2">Contact Details</legend>
          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Name:</label> {{resourceordermodaldata.sections.list[0].travellers_details.name}}</div>
          </div>

          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Contact Number:</label> {{resourceordermodaldata.sections.list[0].travellers_details.number}} </div>                    
          </div>
          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Organization Name:</label> {{resourceordermodaldata.sections.list[0].travellers_details.organization_name}} </div>                    
          </div>

          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Designation:</label> {{resourceordermodaldata.sections.list[0].travellers_details.designation}}</div>                    
          </div>

          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Type:</label> {{resourceordermodaldata.sections.list[0].travellers_details.type}} </div>                    
          </div>
         </fieldset>                  
      </div> 

      <div class="row py-1">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2">Agency Details</legend>
          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Agency Name:</label> {{resourceordermodaldata?.agencyname}}</div>
          </div>

          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Agency Type:</label> {{resourceordermodaldata?.agenytype}} </div>                    
          </div>
          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Agency City:</label> {{resourceordermodaldata?.agencycity}} </div>                    
          </div>

          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Agency State:</label> {{resourceordermodaldata?.agencystate}}</div>                    
          </div>

         </fieldset>                  
      </div> 

      <div class="row py-1">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2">Incharge Details</legend>
          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Name:</label> {{resourceordermodaldata.incharge_details.name}}</div>
          </div>

          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Contact Number:</label> {{resourceordermodaldata.incharge_details.phone}} </div>                    
          </div>
          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Email:</label> {{resourceordermodaldata.incharge_details.email}} </div>                    
          </div>

          <div class="row">
            <div class="col-md-12"><label class="font-weight-bold">Designation:</label> {{resourceordermodaldata.incharge_details.designation}}</div>                    
          </div>

         </fieldset>                  
      </div> 
      
    </div>
  </div>
</div>
</div>
</div>
