import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";

const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);
@Injectable({
  providedIn: 'root'
})
export class MediaService {
  baseUrl = environment.apiurl 

  constructor( private http: HttpClient,) { }

  createMedia(data: any) {
    const url = this.baseUrl + "media/add";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getallMedia() {
    const url = this.baseUrl + "media/getall";
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  editMedia(currentData, id) {
    let url = this.baseUrl + "media/update/" + id;
    console.log("data::", currentData);   
    let result = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  deleteMedia(id: number) {
    let url = this.baseUrl + "media/delete/" + id;
    let result: any = this.http
      .delete(url, { headers: headers })
      .pipe(catchError(this.handleError));

    return result;
  }


  deleteCategory(id:any) {
    let url = this.baseUrl + "eventgallery/category/" + id;
    let result: any = this.http
      .delete(url, { headers: headers })
      .pipe(catchError(this.handleError));

    return result;
  }
  // media gallery
  createCategory(data: any) {
    const url = this.baseUrl + "eventgallery/category";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  getallCategory() {
    const url = this.baseUrl + "eventgallery/category";
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  createEvent(data: any) {
    const url = this.baseUrl + "entitygallery";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  // gallery
  getGallery(id) {
    const url = this.baseUrl + "entitygallery/category/" + id;
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getCategory(id:any){
    const url = this.baseUrl + "eventgallery/category/" + id;
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  updateCategory(id:any,data: any) {
    const url = this.baseUrl + "eventgallery/category/" + id;
    let result: any = this.http
      .put(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  updateEntity(id:any,data:any){
    const url = this.baseUrl + "eventgallery/" + id;
    let result: any = this.http
      .put(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

