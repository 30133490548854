import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AxiosServicesService } from "./axios/axios-services.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);
  
@Injectable({
    providedIn: "root",
})
export class GrantNotificationService {
    entity_type:string='INCUBATOR'
    baseUrl = environment.apiurl;
    constructor(private http: HttpClient) {}

    // getNotifications(schemeId:any) {
    //     const url = this.baseUrl + `grantnotifications/${schemeId}?entity_type=${this.entity_type}`;
    //     return this.http.get(url, { headers: headers }).pipe(catchError((err) => throwError(err)));
    // }

    // createNotification(payload:any) {
    //     const url = this.baseUrl + `grantnotifications/create?entity_type=${this.entity_type}`;
    //     return this.http.post(url, payload, { headers: headers }).pipe(catchError((err) => throwError(err)));
    // }

    // updateNotification(id:any, payload:any) {
    //     const url = this.baseUrl + `grantnotifications/${id}?entity_type=${this.entity_type}`;
    //     return this.http.put(url, payload, { headers: headers }).pipe(catchError((err) => throwError(err)));
    // }

    // deleteNotification(id:any) {
    //     const url = this.baseUrl + `grantnotifications/${id}?entity_type=${this.entity_type}`;
    //     return this.http.delete(url, { headers: headers }).pipe(catchError((err) => throwError(err)));
    // }

}