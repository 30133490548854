<div class="d-flex mb-3">
  <div class="col-sm-2">
    <label class="mx-3">Facility</label>
    <ng-multiselect-dropdown
      [(ngModel)]="selectfacility"
      [placeholder]="''"
      [settings]="dropdownSettings_t"
      [data]="dropdownFacilities"
      [ngModelOptions]="{ standalone: true }"
    >
    </ng-multiselect-dropdown>
  </div>

  <div class="col-sm-2">
    <label class="mx-3">Entity Type</label>
    <ng-multiselect-dropdown
      [(ngModel)]="selectentity"
      [placeholder]="''"
      [settings]="dropdownSettings"
      [data]="dropdownEntities"
      [ngModelOptions]="{ standalone: true }"
    >
    </ng-multiselect-dropdown>
  </div>

  <div class="col-sm-2">
    <label class="mx-3">Status</label>
    <ng-multiselect-dropdown
      [placeholder]="''"
      [settings]="dropdownSettings"
      [data]="dropdownstatus"
      [(ngModel)]="selectstatus"
      [ngModelOptions]="{ standalone: true }"
    >
    </ng-multiselect-dropdown>
  </div>
  <div class="col-sm-2">
    <label class="mx-3">-</label><br />
    <button class="btn btn-primary mr-1" (click)="filterItem()" id="filter">
      Filter
    </button>
    <button class="btn btn-secondary" (click)="resetFilters()">Reset</button>
  </div>
</div>

<div class="container-grid col-12 col-sm-12">
  <div class="text-center pt-3">
    <h2>Facility Details</h2>
  </div>
  <div class="container-lg">
    <!-- <div class="table-responsive" > -->
    <div class="table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Facility</th>
            <th>Entity Type</th>
            <th>Entity Name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="data?.length > 0; else noRecords">
          <tr
            *ngFor="
              let d of data | paginate : { itemsPerPage: 10, currentPage: p };let i = index">
          <th scope="row" class="text-center">{{i+1 + (p - 1) * 10}}</th>
            <td>{{ d.resource_type }}</td>
            <td>{{ getEntityType(d.creator) }}</td>
            <td>{{ d.name }}</td>
            <td>{{ d.status }}</td>
            <td>
              <div class="d-flex align-items-center">
                <div *ngIf="d.status === 'PENDING'" class="d-flex">
                  <a
                    class="dropbtn fa fa-check mr-2"
                    style="color: blue"
                    (click)="
                      changestatus(d.id, 'APPROVED', d.resource_type, d.creator)
                    "
                  ></a>
                  <a
                    class="dropbtn fa fa-close mr-2"
                    style="color: red"
                    (click)="
                      changestatus(d.id, 'DECLINED', d.resource_type, d.creator)
                    "
                  ></a>
                </div>
                <div *ngIf="d.status === 'APPROVED'">
                  <a
                    class="dropbtn fa fa-close mr-2 ml-4"
                    style="color: red"
                    (click)="
                      changestatus(d.id, 'DECLINED', d.resource_type, d.creator)
                    "
                  ></a>
                </div>
                <div *ngIf="d.status === 'DECLINED'">
                  <a
                    class="dropbtn fa fa-check mr-4"
                    style="color: blue"
                    (click)="
                      changestatus(d.id, 'APPROVED', d.resource_type, d.creator)
                    "
                  ></a>
                </div>
                <a
                  class="fa fa-eye ml-2"
                  style="color: green"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  (click)="preview(d)"
                ></a>
              </div>
            </td>
          </tr>
        </tbody>
        <ng-template #noRecords>
          <tbody>
            <tr>
              <td colspan="8" class="no-records-found">No Data found.</td>
            </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
    <pagination-controls
    *ngIf="data?.length > 0" (pageChange)="p = $event"
      class="float-right" 
    ></pagination-controls>
    <!-- </div> -->
  </div>
</div>

<!-- Modal -->
<div
  class="modal custom-modal"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content d-flex">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Preview</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div *ngIf="modaldata.name">
            <label for="basic-url">Resource Title</label>

            <input
              type="text"
              class="form-control mb-2"
              placeholder=""
              aria-label="Username"
              value="{{ modaldata.name }}"
              aria-describedby="basic-addon1"
              readonly
            />
          </div>
          <div *ngIf="modaldata.img1">
            <label for="basic-url">Resource Images</label>
            <div
              class="d-flex justify-content-between align-items-center mb-2 col-sm-12"
            >
              <div class="col-sm-6 d-flex justify-content-between">
                <img
                  [src]="modaldata.img1"
                  alt="image"
                  width="150"
                  height="150"
                />
                <img
                  [src]="modaldata.img2"
                  alt="image"
                  width="150"
                  height="150"
                />
              </div>
              <div class="col-sm-6 d-flex justify-content-between">
                <img
                  [src]="modaldata.img3"
                  alt="image"
                  width="150"
                  height="150"
                />
                <img
                  [src]="modaldata.img4"
                  alt="image"
                  width="150"
                  height="150"
                />
              </div>
            </div>
          </div>

          <div *ngIf="modaldata.info">
            <label for="basic-url">Lab Information </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.info }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.websiteurl">
            <label for="basic-url">Website URL </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.websiteurl }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.benefits">
            <label for="basic-url">Lab Benefits </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.benefits }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.eligibility">
            <label for="basic-url">Lab Eligibility</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.eligibility }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.availableRoomsNo">
            <label for="basic-url">Total No. of Rooms Available </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.availableRoomsNo }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.foodType">
            <label for="basic-url">Food Type </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.foodType }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.shareType">
            <label for="basic-url">Sharing Type </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.shareType }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.description">
            <label for="basic-url">Any Other Information</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.description }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.delegatesName">
            <label for="basic-url">Delegate's Name</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.delegatesName }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.delegatesCharge">
            <label for="basic-url"> Delegate's Charge </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.delegatesCharge }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.startupName">
            <label for="basic-url">StartUp Name </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.startupName }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.startupCharge">
            <label for="basic-url">StartUp Charge </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.startupCharge }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.icDelegates">
            <label for="basic-url">IC Delegates/Officials Name</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.icDelegates }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.icDelegatesCharges">
            <label for="basic-url">IC Delegates/Officials Charge </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.icDelegatesCharges }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.otherName">
            <label for="basic-url">Other </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.otherName }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.otherCharges">
            <label for="basic-url">Other Charge </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.otherCharges }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.selectedType">
            <label for="basic-url">Type</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.selectedType }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.otherFacility">
            <label for="basic-url">Any other Facility</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.otherFacility }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.noOfPeople">
            <label for="basic-url">No. of People Accomodation </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.noOfPeople }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.anycharges">
            <label for="basic-url">Any Charges</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.anycharges }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.inchargeInstruction">
            <label for="basic-url">Instructions(If any) </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.inchargeInstruction }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.details">
            <label for="basic-url">Equipment Details</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.details }}"
              id="fileInput"
              readonly
            />
          </div>

          <div>
            <h2>Incharge Details:</h2>
          </div>

          <div *ngIf="modaldata.incharge_details.name">
            <label for="basic-url">Name </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.name }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.incharge_details.phone">
            <label for="basic-url">Contact Number</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.phone }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.incharge_details.designation">
            <label for="basic-url">Designation </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.designation }}"
              id="fileInput"
              readonly
            />
          </div>
          <div *ngIf="modaldata.incharge_details.email">
            <label for="basic-url">Email Address</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.email }}"
              id="fileInput"
              readonly
            />
          </div>

          <div>
            <h2>Location:</h2>
          </div>
          <div *ngIf="modaldata.address_line1">
            <label for="basic-url">Address </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.address_line1 }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.city">
            <label for="basic-url">City</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.city }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.state">
            <label for="basic-url">State </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.state }}"
              id="fileInput"
              readonly
            />
          </div>

          <div *ngIf="modaldata.zip_code">
            <label for="basic-url">ZipCode</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.zip_code }}"
              id="fileInput"
              readonly
            />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
