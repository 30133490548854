import { P } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GrantManagementService } from '../../services/grant-management/grant-management.service';

@Component({
  selector: 'ngx-grant-management',
  templateUrl: './grant-management.component.html',
  styleUrls: ['./grant-management.component.scss']
})
export class GrantManagementComponent implements OnInit {
  projectDetailsForm:FormGroup;
  projectDetails:boolean = true;
  projectFinancials:boolean;
  fundsUtilization:boolean;
  bankDetails:boolean;
  documents:boolean;
  summary:boolean;
  id:any;
  selectedItem:any;
  schemeid:any;
  fundRes:any;
  inc_fund_id:any;
  incubator_id:any;
  fund_created:boolean=false;
  incTermHeadsResponse:any;
  incBankDetails:any;
  incFundUtilDetails:any;
  project_inancials:boolean=false;
  incSummaryData:any;
  incubatorName:any;
  incProjDetails:any;
  step1finished:boolean=false;
  step2finished:boolean=false;
  step3finished:boolean=false;
  step4finished:boolean=false;
  allDataFinished:boolean=false;

  constructor(
    private fb:FormBuilder,
    private router:Router,
    private _route: ActivatedRoute,
    private grantMangementService: GrantManagementService
  ) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get("id");
    this.selectedItem='project-details';
    this.schemeid = this.id;
    this.inc_fund_id = this._route.snapshot.paramMap.get("incid");
    this.incubator_id = this._route.snapshot.paramMap.get("incubator_id");
    if(this.inc_fund_id && this.incubator_id) {
      this.fund_created = true;
      this.step1finished = true;
      this.grantMangementService.getFundUtilizationDetails(this.inc_fund_id).subscribe(res=>{
        console.log('TERM HEAD RESPONSE:::',res);
        this.incTermHeadsResponse = res;
        if(this.incTermHeadsResponse?.results[0].sf_list?.length>0) {
          //this.patchUpdatedNestedForm();
          this.project_inancials=true;
          this.step2finished = true;
        }
      });
      this.grantMangementService.getIncubatorBankDetails(this.inc_fund_id).subscribe((response):any=>{
        this.incBankDetails=response;
        if(this.incBankDetails && this.incBankDetails?.results.length>0 && this.incBankDetails?.results[0].bank_name) {
          this.step4finished = true;
        }
      })
      this.grantMangementService.getFundUtilizationDetails(this.inc_fund_id).subscribe((response):any=>{
        this.incFundUtilDetails=response;
        if(this.incFundUtilDetails && this.incFundUtilDetails?.results.length>0 && this.incFundUtilDetails?.results[0]?.sf_list[0]?.headfunds[0]?.approved_amount) {
          this.step3finished = true;
        }
      })
      this.grantMangementService.getIncFundSummary(this.inc_fund_id).subscribe((data: any) => {
        // let data= res.results[0];
        this.incSummaryData = data;
        if(this.incSummaryData && 
          this.incSummaryData?.results.length>0 && 
          this.incSummaryData?.results[0].incubatorfund[0].startups_finalized_count &&
          this.incSummaryData?.results[0].incubatorfund[0].incubatorfundbankdetail &&
          this.incSummaryData?.results[0].term_accounts?.length>0 &&
          this.incSummaryData?.results[0].termhead_accounts?.sf_list.length>0
          )
        this.allDataFinished = true;
      });
      //get session incubator data
      this.grantMangementService.getIncubatorProjectDetails(this.inc_fund_id).subscribe(response => {
        this.incProjDetails = response;
        if(this.incProjDetails && this.incProjDetails?.results.length>0) {
          this.incubatorName = this.incProjDetails?.results[0].incubator_name;
        }
      });
    }
  }

  getSchemFundRes(res:any) {
    console.log("Response::::", res);
    this.fundRes = res;
    sessionStorage.setItem("fundres",JSON.stringify(this.fundRes));
  }
  backtomainmenu() {
    let url = `/admin/grant-management/${this.schemeid}`;
    this.router.navigate([url]);
  }
  navigateMenu(value:string) {
    this.selectedItem=value;
    if(value == 'project-details') {
      this.projectDetails = true;
      this.projectFinancials = false;
      this.fundsUtilization = false;
      this.bankDetails = false;
      this.documents = false;
      this.summary = false;
    }

    else if(value == 'project-financials') {
      this.projectDetails = false;
      this.projectFinancials = true;
      this.fundsUtilization = false;
      this.bankDetails = false;
      this.documents = false;
      this.summary = false;
    }

    if(value == 'fund-utilization') {
      this.projectDetails = false;
      this.projectFinancials = false;
      this.fundsUtilization = true;
      this.bankDetails = false;
      this.documents = false;
      this.summary = false;
    }

    if(value == 'bank-details') {
      this.projectDetails = false;
      this.projectFinancials = false;
      this.fundsUtilization = false;
      this.bankDetails = true;
      this.documents = false;
      this.summary = false;
    }

    if(value == 'documents') {
      this.projectDetails = false;
      this.projectFinancials = false;
      this.fundsUtilization = false;
      this.bankDetails = false;
      this.documents = true;
      this.summary = false;
    }

    if(value == 'summary') {
      this.projectDetails = false;
      this.projectFinancials = false;
      this.fundsUtilization = false;
      this.bankDetails = false;
      this.documents = false;
      this.summary = true;
    }
  }
}
