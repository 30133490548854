import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from "@angular/router";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //return next.handle(request);

    const token = sessionStorage.getItem('admintoken');
    console.log('token1234',token);
    if(token){
      request = request.clone({
        setHeaders: {Authorization: `Bearer ${token}`}
      })
      
        return next.handle(request)
            .pipe(
                map(res => {                   
                    return res
                }),
                catchError((err: HttpErrorResponse) => {                                 
                    if(err.status == 401 && err.error.code == "auth_token_expired"){
                      sessionStorage.clear(); 
                      sessionStorage.setItem("expiryflag","success");         
                     // console.log('Unauthorized');
                      this.router.navigate(['/auth']);
                    }
                    console.log("err++++++++++",err);
                    return throwError(err);
                })
            )
         }
         else {
          return next.handle(request);
        }
    }
   
  
}
