<div class="container">
    <div class="row">        
        <div class="container pt-4">  
          <div class="d-flex flex-wrap flex-direction-column justify-content-around">
            <h2 class="font-weight-bold text-center">Incubator Grant Funding Details</h2> 
            <h4><a class="btn btn-primary text-white text-decoration-none" (click)="assignfund()">Assign Fund</a></h4>     
          </div>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="text-center" scope="col">Scheme Name</th>
                <th class="text-center" scope="col">Incubator Name</th>
                <th class="text-center" scope="col">Fund Created Date</th>
                <th class="text-center" scope="col">View/Update</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sdetails of incubatorGrantData?.results">
                <td class="text-center">{{sdetails.scheme_name}}</td>
                <td class="text-center">{{sdetails.incubator_name}}</td>
                <td class="text-center">{{sdetails.created_at | date: 'dd/MM/yyyy'}}</td>
                <td class="text-center"><a (click)="navigate(sdetails.scheme_id,sdetails.id,sdetails.incubator_id)"><i class="fa fa-eye"></i></a></td>
              </tr>
              <tr *ngIf="incubatorGrantData && incubatorGrantData?.results?.length == 0">
                  <td colspan="5" style="text-align: center;">No incubators assigned with funds.</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</div>
