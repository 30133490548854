import { Component, OnInit } from '@angular/core';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import * as XLSX from 'xlsx';  // Import XLSX for Excel export
import { saveAs } from 'file-saver';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-quarterwise-fund-release',
  templateUrl: './quarterwise-fund-release.component.html',
  styleUrls: ['./quarterwise-fund-release.component.scss']
})
export class QuarterwiseFundReleaseComponent implements OnInit {
  Quarterfunds : any;
  startdate: any;
  enddate: any;
  agencyname:any[]=[]
  Q1Data:any;
  Q2Data:any;
  scheme_id:any;
  Q3Data:any;Q4Data:any;
  QuarterData:any[]=[]
  entity: any;
  constructor(private grantManagementService:GrantManagementService,private _route :ActivatedRoute) {
    this._route.paramMap.subscribe((params:any)=>{
      this.scheme_id = params.params.id;
      this.grantManagementService.scheme_id = this.scheme_id
    })
   }

  ngOnInit(): void {
    this.grantManagementService.getStartDate().subscribe((res:any)=>{
      this.startdate = res;
      console.log('startdate------',this.startdate)
      //  this.quarterWiseFund()
      
  })

  this.grantManagementService.getEndDate().subscribe((res: any) => {
      this.enddate = res;
      console.log('end------', this.enddate);
      this.quarterWiseFund()
      
       // Call getFunds() after end date is available
  });
  this.grantManagementService.getEntityType().subscribe((res: any) => {
    this.entity = res;
    console.log('entity------', this.entity);
    this.quarterWiseFund()
});
  // this.quarterWiseFund()

  }
  quarterWiseFund(){
    // if(!this.startdate || !this.enddate){
    //   console.log('Start date or end date is not available yet.');
    //   return;
    // }
    this.grantManagementService.QuarterwiseFund(this.startdate,this.enddate,this.entity).subscribe((res:any) => {
      console.log("res ==========> ",res);
      this.Quarterfunds = res;
      this.agencyname=res?.agency_name
      this.Q1Data = this.Quarterfunds?.filter(item => item.quarter === 'Q1');
      this.Q2Data =this.Quarterfunds?.filter(item => item.quarter === 'Q2');
      this.Q3Data =this.Quarterfunds?.filter(item => item.quarter === 'Q3');
      this.Q4Data =this.Quarterfunds?.filter(item => item.quarter === 'Q4');
      this.QuarterData = [];
      this.QuarterData.push(this.Q1Data);
      this.QuarterData.push(this.Q2Data);
      this.QuarterData.push(this.Q3Data);
      this.QuarterData.push(this.Q4Data);
      console.log('sss',this.QuarterData)
      console.log('aa',this.Quarterfunds)
  })
  }
  // downloadCSV() {
  //   const wb = XLSX.utils.book_new();
  //   const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

  //   quarters.forEach((quarter, index) => {
  //     const data = this.QuarterData[index].map(item => ({
  //       'Agency Name': item.agency_name,
  //       'Value': item.total_value
  //     }));

  //     const ws = XLSX.utils.json_to_sheet(data);
  //     XLSX.utils.book_append_sheet(wb, ws, `Quarter ${quarter}`);
  //   });

  //   const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //   saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'QuarterlyData.xlsx');
  // }

downloadCSV() {
    const wb = XLSX.utils.book_new();
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

    quarters.forEach((quarter, index) => {
        const data = this.QuarterData[index].map(item => ({
            'Agency Name': item.agency_name,
            'Value': item.total_value
        }));

        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, `Quarter ${quarter}`);
    });

    // Generate Excel file and trigger download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'QuarterlyData.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

}

  
  


