<div class="login-form row custom-card col-md-10 offset-md-1">
    <div class="col-12 col-md-6 col-sm-12 transform-center">
        <div class="text-center">
            <img src="{{'assets/images/MeitYStartupHub-Logo-FINAL_5 1.png'}}" height="137px">
        </div>
        <hr class="hr-text">
        <div class="desc-text">
            Meity startup India Portal is a one of its kind online platform for all stakeholders of the startup
            ecosystem in India.
        </div>
        <hr class="hr-text">
        <div class="text-center">
            <!-- <img class="govt-login" src="{{'assets/images/gov-login-img.png'}}"> -->
        </div>
    </div>
    <div class="col-12 col-md-6 col-sm-12">
        <div class="text-center pt-30">
            <!-- <img class="welcome-meity" src="{{'assets/images/login-logo-welcom.png'}}" width="50%"> -->
        </div>
        
        <!-- Login with password -->        
        <form class="ng-pristine ng-invalid" [formGroup]="loginForm">
            <div class="form-control-group">
                <label for="input-email" class="font-weight-bold">User Email :</label>
                <input nbinput="" fullwidth="" name="email" id="input-email" placeholder="Email"
                    fieldsize="large" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" autofocus="" formControlName="email"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                    <div *ngIf="submitted && f.email.errors" class="text-danger">
                        <div *ngIf="f.email.errors['required']">Email is required</div>
                        <div *ngIf="f.email.errors['pattern']">Email is invalid</div>
                      </div>
                <!-- <p class="caption status-danger ng-star-inserted" *ngIf="!loginForm.get('email')?.valid &&
                loginForm.get('email')?.dirty"> {{
                    loginForm.get('email')?.hasError('minlength')
                    ? 'Minimum 6 characters required'
                    : loginForm.get('email')?.getError('invalid')
                    }}
                </p> -->

                <!-- status-danger -->
            </div>
            <div class="form-control-group password-container">
                <span class="label-with-link">
                    <label for="input-password" class="font-weight-bold">Password:</label>
                    <a routerlink="/request-password" class="font-weight-bold text-decoration-none"
                        href="auth/reset-password">Forgot Password?
                    </a>
                </span>
                <input formControlName="password" id="password" nbinput="" fullwidth="" name="password" [type]="show?'text':'password'"
                    id="input-password" placeholder="Password" fieldsize="large" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                    class="input-full-width size-large status-basic shape-rectangle ng-untouched ng-pristine ng-invalid nb-transition"
                    >
                    <i class="fa fa-eye-slash" id="eye" (click)="showpassword()" *ngIf="showeye;else hideeye"></i>
                    <ng-template #hideeye>
                        <i class="fa fa-eye" aria-hidden="true" (click)="hidepassword()"></i>
                    </ng-template>
                    <div *ngIf="submitted && f.password.errors" class="text-danger">
                        <div *ngIf="f.password.errors['required']">Password is required</div>
                        <div *ngIf="f.password.errors['minlength']">Password is invalid</div>
                    </div>
                

                <!-- <span class="caption status-danger ng-star-inserted" *ngIf="!loginForm.get('password')?.valid &&
                    (loginForm.get('password')?.touched || loginForm.get('password')?.dirty)">
                    {{
                    loginForm.get('password')?.hasError('minlength')
                    ? 'Minimum 6 characters required'
                    : loginForm.get('password')?.getError('invalid')
                    }}
                </span> -->
                <!-- <div class="caption status-danger ng-star-inserted"
                    *ngIf="inValidMessage && loginForm.get('email')?.valid && loginForm.get('password')?.valid">!Invalid
                    Credentials</div> -->
                <!---->
            </div>
            <button (click)="passwordLogin()" fullwidth="" status="primary" size="large"
                class="appearance-filled full-width size-large shape-rectangle status-primary nb-transition login-button">
                Log In </button>
        </form>  
       
    </div>
</div>