<div class="container py-3">
  <!-- <ngx-gia-utilized-table></ngx-gia-utilized-table> -->
</div>
<section id="investor_section">
    <div class="container">
      <div class="whitebg my-3 p-4 ">
        <h2 class="text-center">Team</h2>
        <form [formGroup]="teamtideForm">
            
          <div class="row  p-3 my-4">
           
            <div class="col-12 col-md-6">
              <label class="form-label label-required">Name</label>
                <input type="text" class="form-control" placeholder="Name" formControlName="name">
                <div
                *ngIf="
                  (istideFormSubmited && _name.invalid) ||
                  (_name?.touched && _name?.invalid)
                "
                class="text-danger"
              >
                <div *ngIf="teamtideForm?.get('name').errors?.required">
                  <p class="text-danger">Name is mandatory</p>
                </div>
              </div>
                
             </div>
             <div class="col-12 col-md-6">
              <label class="form-label label-required">Designation</label>

                <input type="text" class="form-control" placeholder="designation" formControlName="designation">
                
                <div
                *ngIf="
                  (istideFormSubmited && _designation.invalid) ||
                  (_designation?.touched && _designation?.invalid)
                "
                class="text-danger"
              >
                <div *ngIf="teamtideForm?.get('designation').errors?.required">
                  <p class="text-danger">Designation is mandatory</p>
                </div>
              </div>
             </div>
            </div>
            <div class="row  p-3 my-4">
           
                <div class="col-12 col-md-6">
                  <label class="form-label label-required">Email</label>

                    <input type="text" class="form-control" placeholder="Email" formControlName="email">
                    <div
                *ngIf="
                  (istideFormSubmited && _email.invalid) ||
                  (_email?.touched && _email?.invalid)
                "
                class="text-danger"
              >
                <div *ngIf="teamtideForm?.get('email').errors?.required">
                  <p class="text-danger">Email is mandatory</p>
                </div>
                <div *ngIf="_email?.errors?.pattern">
                  <p class="text-danger">Invalid email formate</p>
                </div>
              </div>
                 </div>
                 <div class="col-12 col-md-6">
                  <label class="form-label label-required">Contact Number</label>

                    <input type="text" class="form-control" placeholder="contact number" formControlName="cnumber">
                    
                    <div
                    *ngIf="
                      (istideFormSubmited && _cnumber.invalid) ||
                      (_cnumber?.touched && _cnumber?.invalid)
                    "
                    class="text-danger"
                  >
                    <div *ngIf="teamtideForm?.get('cnumber').errors?.required">
                      <p class="text-danger">Contact number is mandatory</p>
                    </div>
                    <!-- <div *ngIf="_cnumber.errors?.pattern">
                      <p class="text-danger">Invalid Contact Number</p>
                    </div>
                    <div *ngIf="_cnumber.errors?.maxlength">
                      <p class="text-danger">Invalid Contact Number</p>
                    </div>
                    <div *ngIf="_cnumber.errors?.minlength">
                      <p class="text-danger">Invalid Contact Number</p>
                    </div> -->
                    <div *ngIf="_cnumber.errors?.pattern || _cnumber.errors?.maxlength || _cnumber.errors?.minlength">
                      <p class="text-danger">Invalid Contact Number</p>
                    </div>
                  </div>

                 </div>
                </div>
             <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-primary text-center" (click)="onSubmit()">{{ editmode ? 'Update' : 'Register' }}</button>
                <button type="canel" class="mx-2 btn btn-secondary cancelEditBtn " *ngIf="editmode" (click)="cancelEdit()">Cancel</button>

              </div>
                
                </form>
                <!-- //table -->
                <div class="p-4 my-4">
                  <h2 class="text-center p-3">Team scheme List</h2>
                  <div class="table-container">
                <table class="table table-striped text-center">
                                      
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact Number</th>

                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of newtideData|paginate: { itemsPerPage: pageSize, currentPage: p }; let i=index">
                      <th scope="row">{{ i + 1 +(p-1)*pageSize }}</th>
                      <td>{{item.name}}</td>
                      <td>{{item.designation}}</td>
                      <td>{{item.email}}</td>
                      <td>{{item.contact}}</td>
                      <td class="d-flex justify-content-center"><i class="fa fa-edit arrow px-2" (click)="getbyidtideData(item.id)"></i>
                    
                     <i class="fa fa-trash arrow px-2" (click)="teamDelete(item.id)"></i>
                  </td>
                  <!-- <i class="fa fa-eye arrow px-2"></i> -->
                    </tr>
                    </tbody>
                    </table>
                    <pagination-controls *ngIf="newtideData?.length>0" (pageChange)="onPageChange($event)" directionLinks="true"  class="float-right"></pagination-controls>
                    </div>
                  </div>
                </div>

                
               </div>

             

</section>