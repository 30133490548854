import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { TeamsService } from '../../../services/teams.service';
// import * as bootstrap from 'bootstrap';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
  @ViewChild('editMemberModal') editMemberModal!: ElementRef;
  @ViewChild('galleryFileInput') galleryFileInput: ElementRef;

  teamsdata: any = [];
  selectedMember: any = {};

  fileError: any;
  fileUploaded: any;
  file: File | null = null;
  memberId: any;
  selectedItem: any;
  selectedStatus: any;

  constructor(private teamService: TeamsService, private toastrService: NbToastrService) { }

  ngOnInit(): void {
    this.fetchTeamData();
  }

  fetchTeamData(): void {
    this.teamService.mshteam().subscribe(
      (response) => {
        if (response.results) {
          console.log(response.results);
          this.teamsdata = response.results;
        }
      },
      (error) => {
        console.error('Error fetching team data', error);
      }
    );
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.file = input.files[0];
      console.log('File selected:', this.file);
    }
    
  }

  // uploadFile(): void {
  //   if (!this.file) {
  //     console.error('No file to upload');
  //     return;
  //   }
    
  //   if (!this.file.name.match(/\.(xls|xlsx)$/)) {
  //     this.fileError = true;
  //     return;
  //   }
    
  //   this.fileError = false;
  //   this.fileUploaded = true;
    
  //   const formData = new FormData();
  //   formData.append('file', this.file);
    
  //   this.teamService.uploadFile(formData).subscribe({
  //     next: (res) => {
  //       console.log('Upload successful:', res);
  //       this.toastrService.success('File uploaded successfully!');
  //     },
  //     error: (err) => {
  //       console.error('Upload failed:', err);
  //       this.toastrService.warning('File upload failed!');
  //     }
  //   });
  // }
  uploadFile(): void {
    if (!this.file) {
      console.error('No file to upload');
      return;
    }
  
    // Validate file extension (only allow .xls or .xlsx)
    if (!this.file.name.match(/\.(xls|xlsx)$/)) {
      this.fileError = true;
      console.error('Invalid file type');
      return;
    }
  
    this.fileError = false;
    this.fileUploaded = true;
  
    // Prepare the FormData to send with the file
    const formData = new FormData();
    formData.append('file', this.file);

    this.teamService.uploadFile(formData).subscribe((res)=>{
      this.toastrService.success('File uploaded successfully!');
      this.fetchTeamData(); // Refresh team data after upload
      this.galleryFileInput.nativeElement.value = '';
    },(err)=>{
      this.toastrService.warning('File upload failed!');
    })
  }
  

  // changestatus(memberId: number, newStatus: string) {
  //   this.teamService.changeStatus(memberId, newStatus).subscribe(
  //     (response) => {
  //       // Handle the successful response
  //       const member = this.teamsdata.find((m) => m.id === memberId);
  //       if (member) {
  //         member.status = newStatus;
  //       }
  //       this.toastrService.success('Status updated successfully!');
  //     },
  //     (error) => {
  //       // Handle error response
  //       console.error('Error changing status:', error);
  //       this.toastrService.warning('Failed to update status.');
  //     }
  //   );
  // }
  changestatus(member: any, status: string): void {
    this.memberId = member.id;
    console.log('Item:', this.memberId);
    console.log('status -----');
    console.log(status);
    const data = { status }; // Prepare data object with status
  
    this.teamService.changeStatus(this.memberId, data).subscribe({
      next: (res: any) => {
        console.log('Response:', res);
        if (status === 'active') {
          this.toastrService.success('TEAMS is activated successfully!');
        } else if (status === 'inactive') {
          this.toastrService.danger('TEAMS rejected successfully!');
        }
        this.fetchTeamData();
      },
      error: (err) => {
        console.error('Error:', err);
        this.toastrService.warning('Failed to change status. Please try again.');
      }
    });
  
  

  }
  openUpdateStatusModal(member: any, status: string) {
    console.log('member------');
    console.log(member);
    this.changestatus(member,status);

    this.selectedItem = member;
    this.selectedStatus = status;
    const statusText = status === 'active' ? 'activate' : 'reject';
    document.getElementById('statusText').innerText = statusText;
  }
  
  // Method to confirm and update status
  confirmStatusUpdate(member: any, status: string) {
    this.changestatus(member,status);

    
    if (this.selectedItem && this.selectedStatus) {
      this.changestatus(this.selectedItem, this.selectedStatus);
    }
  }




  editData(member: any, section: string) {
    this.selectedMember = { ...member }; // Clone the member data to edit
    // const modal = new bootstrap.Modal(document.getElementById('editMemberModal'));
    // modal.show();

  }

  saveChanges() {
    let data = {
      name : this.selectedMember.name,
      designation: this.selectedMember.designation,
      email_id: this.selectedMember.email_id,
      linkedin_url : this.selectedMember.linkedin_url,
    }
    this.teamService.updateMember(this.selectedMember.id,data).subscribe((res)=>{
      this.fetchTeamData();
    },(err)=>{
      console.log(err)
    })

  }

  // Optional method to close modal
  onCancel() {
    const modalElement = document.getElementById('editMemberModal');
    // if (modalElement) {
    //   const modal = bootstrap.Modal.getInstance(modalElement);
    //   if (modal) {
    //     modal.hide();
    //   }
    // }
  }
}

