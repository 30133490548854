<div class="container">
  <div class="d-flex justify-content-left">
    <a (click)="backtomainmenu()" class="btn btn-primary text-white">Back</a>
  </div>
  <div class="row">
    <div class="w-100">
      <h2 class="text-center">Government of India</h2>
    </div>
    <div class="w-100">
      <h2 class="text-center">Ministry of Electronics and Information Technology (MeitY)</h2>
    </div>
    <div class="w-100">
      <h2 class="text-center">(Innovation and IPR Division)</h2>
    </div>
    <div class="w-100">
      <h2 class="text-center"><span *ngIf="incubatorName">{{incubatorName}} - </span>TIDE 2.0</h2>
    </div>
  </div>

  <ul class="d-flex list-unstyled justify-content-center flex-wrap">
    <li class="active mr-2 "><a class="btn btn-primary text-white" [ngClass]="{'active': selectedItem == 'project-details', 'finished': step1finished === true }" data-toggle="pill" (click)="navigateMenu('project-details')">Project Details</a></li>
    <li class="mr-2"><a class="btn btn-primary text-white" [ngClass]="{'active': selectedItem == 'project-financials', 'disabled': fund_created === false, 'finished': step2finished === true}"   data-toggle="pill" (click)="navigateMenu('project-financials')">Project Financials</a></li>
    <li class="mr-2"><a class="btn btn-primary text-white" [ngClass]="{'active': selectedItem == 'fund-utilization', 'disabled': fund_created === false, 'inactive': project_inancials === false, 'finished': step3finished === true }"   data-toggle="pill" (click)="navigateMenu('fund-utilization')">Funds Utilization</a></li>
    <li class="mr-2"><a class="btn btn-primary text-white" [ngClass]="{'active': selectedItem == 'bank-details', 'disabled': fund_created === false,  'finished': step4finished === true}"  data-toggle="pill" (click)="navigateMenu('bank-details')">Bank Details</a></li>
    <li class="mr-2"><a class="btn btn-primary text-white" [ngClass]="{'active': selectedItem == 'documents', 'disabled': fund_created === false}"  data-toggle="pill" (click)="navigateMenu('documents')">View UC Documents</a></li>
    <li class=""><a class="btn btn-primary text-white" [ngClass]="{'active': selectedItem == 'summary', 'disabled': allDataFinished === false}"  data-toggle="pill" (click)="navigateMenu('summary')">Summary</a></li>
  </ul>
  
  <div class="tab-content">
    <div id="project-details" *ngIf = "projectDetails">
      <ngx-scheme-incubator-project-details [schemeid]="schemeid"  (schemeFundRes)="getSchemFundRes($event)" ></ngx-scheme-incubator-project-details>
    </div>
    <div id="project-financials" *ngIf = "projectFinancials">
      <ngx-scheme-incubator-project-financials [fundRes] = "fundRes"></ngx-scheme-incubator-project-financials>
    </div>
    <div id="funds-utilization" *ngIf = "fundsUtilization">
      <ngx-scheme-incubator-funds-utilization></ngx-scheme-incubator-funds-utilization>
    </div>
    <div id="bank-details" *ngIf = "bankDetails">
      <ngx-scheme-incubator-bank-details></ngx-scheme-incubator-bank-details>
    </div>
    <div id="documents" *ngIf = "documents">
      <ngx-scheme-incubator-uc-documents></ngx-scheme-incubator-uc-documents>
    </div>
    <div id="summary" *ngIf = "summary">
      <ngx-scheme-incubator-generate-report></ngx-scheme-incubator-generate-report>
    </div>
  </div>
</div>
