import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { IncubatorService } from '../../../../services/incubator.service';

@Component({
  selector: 'ngx-dialog-name-prompt',
  templateUrl: 'dialog-name-prompt.component.html',
  styleUrls: ['dialog-name-prompt.component.scss'],
})
export class DialogNamePromptComponent {
  message:any;
  config:any;
  title:any;
  dataReceived = new Subject<string>();

  constructor(public ref: NbDialogRef<DialogNamePromptComponent>,private incubator:IncubatorService){
    // let myData = localStorage.getItem('myData');
    // this.message = myData;
    // console.log("local", this.message); // Output: 'Hello World'

    
  }

  // ngOnInit(): void {
  //   console.log('dialog');
  //   this.incubator.getsubjval().subscribe((res)=>{
  //     console.log('subject___value___',res)
  //     this.message = res;
  //     console.log('message__subj',this.message)
  //   })
  // }


}
