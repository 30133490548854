import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { IncubatorService } from '../../../services/incubator/incubator.service';


@Component({
  selector: 'ngx-scheme-incubator-grant-list',
  templateUrl: './scheme-incubator-grant-list.component.html',
  styleUrls: ['./scheme-incubator-grant-list.component.scss']
})
export class SchemeIncubatorGrantListComponent implements OnInit {
  id: any;
  selectedItem: any;
  schemeid: any;
  incubatorGrantData: any;
  
  constructor(
    private fb:FormBuilder,
    private router:Router,
    private _route: ActivatedRoute,
    private incubatorService: IncubatorService,
    private grantMangementService: GrantManagementService,
    private toastrService: NbToastrService) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get("id");
    this.selectedItem='project-details';
    this.schemeid = this.id;
    this.getGrantIncubatorData();
  }

  async getGrantIncubatorData() {
    try {
      this.incubatorService.getGrantIncubatorsBySchemeId(this.schemeid).subscribe((res)=>{
      this.incubatorGrantData = res;
      })
    } catch(error) {
      console.log("error:::",error);
    }

  }

  assignfund(){
    this.router.navigate(['/admin/grant-management/1/main']);
  }

  navigate(schemeid:any, id:any , incubator_id:any){
    this.router.navigate([`/admin/grant-management/${schemeid}/main/${id}/${incubator_id}`])
  }
}
