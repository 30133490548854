import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AxiosServicesService } from "./axios/axios-services.service";
import { environment } from "../../environments/environment";

const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

@Injectable({
  providedIn: "root",
})
export class FaqsService {
  //baseUrl = "http://10.0.53.134:8090";
  // baseUrl = "http://10.0.53.134:8090";
  baseUrl = environment.apiurl 
  constructor(
    private http: HttpClient,
    private axiosServices: AxiosServicesService
  ) {}

  getallcategory() {
    const url = this.baseUrl + "faqscategory/getall";
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  getallFaqsList() {
    const url = this.baseUrl + "faqs/getall";
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  activeFaqList(){
    const url = this.baseUrl + "faqs";
    let result : any = this.http
    .get(url, {headers: headers})
    .pipe(catchError(this.handleError));
    return result;
  }
  editfaqsQuestion(currentData, faqs_id) {
    let url = this.baseUrl + "faqs/update";
    let param = {
      faqs_category_id: 1,
      faq_question: currentData.question,
      faq_answer: currentData.answer,
      // user_id: 1,
      user_id: sessionStorage.getItem('user_id'),

      faqs_id: faqs_id,
    };
    let result = this.http
      .put(url, param, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  actionFaqs(currentData) {
    let url = this.baseUrl + "faqs/update";
    let result: any = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));

      console.log("data:results:", currentData);
    return result;
  }
  dragUpdate(faqs_id){
    const url = this.baseUrl + "faqs/faqs_id";
    let result:any = this.http
    .put(url, faqs_id,{headers: headers})
    .pipe(catchError(this.handleError));

    console.log("data:results:", faqs_id);
  return result;
  }
  createFaqs(data: any) {
    const url = this.baseUrl + "faqs/add";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  deleteFaqsRow(id: number) {
    console.log('headers', {headers:headers})
    let url = this.baseUrl + "faqs/delete/" + id;
    let result: any = this.http
      .put(url,{},{headers:headers}).pipe(catchError(this.handleError));

    return result;
  }
  changeOrder(data: any){
    const url = this.baseUrl + "faqs/order";
    let result:any = this.http
    .put(url, data,{headers: headers})
    .pipe(catchError(this.handleError));

    console.log("data:results:", data);
  return result;
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
