<div class="container-fluid">
    <div class="row">        
        <div class="container-fluid center_form">        
          <div class="tab-content px-4 pt-2">
            <div class="tab-pane fade show active shadow-lg py-3 mb-5 bg-white border-radius rounded" id="project-details" role="tabpanel">
              <!-- Add your content for the Project Details tab here -->
              <form id="projectDetail" [formGroup]="projectDetail" (ngSubmit)="onProjectDetailsSubmit()">
                <div class="form-group">
                  <label class="form-label font-weight-bold">Type of Centre<span class="text-danger">*</span></label>
                  <select class="form-control" formControlName="center_type" (change)='onCenterTypeChange($event)' required>
                    <option value="">Please select type of center</option>
                    <option value="G1">G1</option>
                    <option value="G2">G2</option>
                    <option value="G3">G3</option>
                  </select>
                  <div *ngIf="(center_type.touched || submitted) && center_type.errors?.required" class="text-danger">
                      <p class="text-danger">This Field is mandatory</p>
                  </div>
                </div>
                <div class="form-group font-weight-bold">
                  <label class="form-label">Name of Centre<span class="text-danger">*</span></label>
                  <select class="form-control" formControlName="nameOfCentre" required>
                    <option value="">Please select the center name</option>
                    <option *ngFor="let inc of incubatorData?.results" value="{{inc.id}}">{{inc.name}}</option>
                  </select>
                  <div *ngIf="(nameOfCentre.touched || submitted) && nameOfCentre.errors?.required" class="text-danger">
                      <p class="text-danger">This Field is mandatory</p>
                  </div>
                </div>
                <!--<div class="form-group font-weight-bold">
                  <label class="form-label">Location of Centre</label>
                  <input type="text" class="form-control" formControlName="locationOfCentre" value="" readonly />
                  <div *ngIf="(locationOfCentre.touched || submitted) && locationOfCentre.errors?.required" class="text-danger">
                      <p class="text-danger">This Field is mandatory</p>
                  </div>
                </div>-->
                <div class="form-group font-weight-bold">
                  <label class="form-label">Startups Finalized<span class="text-danger">*</span></label>
                  <input type="number" placeholder="Startups Finalized" class="form-control" formControlName="startupsFinalized" onkeypress="if (this.value.length > 1) return false;" />
                  <div *ngIf="(startupsFinalized.touched || submitted) && startupsFinalized.errors?.required" class="text-danger">
                      <p class="text-danger">This Field is mandatory</p>
                  </div>
                  <div *ngIf="startupCount==0">
                      <p class="text-danger">There is no startups assigned with the incubator.</p>
                  </div>
                  <div *ngIf="startupCount>0">
                    <p class="text-danger">Assigned startups are less than the provided startups finalized</p>
                  </div>
                  <div *ngIf="startupsFinalized?.errors?.pattern">
                    <p class="text-danger">Please enter the valid number start from 1</p>
                  </div>
                </div>
                <div class="form-group font-weight-bold">
                  <label class="form-label">Tenure<span class="text-danger">*</span></label>
                  <select class="form-control" formControlName="tenure" required >
                    <option value="">Please select tenure</option>
                    <option value="1">1 Year</option>
                    <option value="2">2 Years</option>
                    <option value="3">3 Years</option>
                    <option value="4">4 Years</option>
                    <option value="5">5 Years</option>
                  </select>
                  <div *ngIf="(tenure.touched || submitted) && tenure.errors?.required" class="text-danger">
                      <p class="text-danger">This Field is mandatory</p>
                  </div>
                </div>
                <div class="form-group font-weight-bold">
                  <label class="form-label">Funding Source<span class="text-danger">*</span></label>
                  <input type="text" placeholder="Funding Source" class="form-control" formControlName="fundSource" required />
                  <div *ngIf="(fundSource.touched || submitted) && fundSource.errors?.required" class="text-danger">
                      <p class="text-danger">This Field is mandatory</p>
                  </div>
                </div>
                <div class="pt-2">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
</div>
