import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { AxiosServicesService } from "../../../services/axios/axios-services.service";
import { RoleService } from "../../../services/role.service";
import * as _ from "lodash";
import { ShowcaseDialogComponent } from "../../../@core/miscellaneous/dialog/showcase-dialog/showcase-dialog.component";
import { NotificationService } from "../../../@core/miscellaneous/common/notification.service";
import { PermissionService } from "../../../services/permission.service";


@Component({
  selector: "ngx-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.scss"],
})
export class RolesComponent implements OnInit {
  rolesForm: FormGroup;
  inValidMessage: boolean = false;
  data: any;
  tableData: any[] = [];
  rolesDetails: any;
  currentData: any[] = [];
  name: any[] = [];
  editMode: boolean = false;
  res: any;
  index: any;
  names: any;

  permissions:any;
  permission_list = [];
  assignpermission = [];
  permissions_record: any;
  permissionsid: any;
  roleid: any;
  permissionorder: any;
  submitted = false;
  disabled=true;

  constructor(
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private axiosService: AxiosServicesService,
    private roleService: RoleService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private notification: NotificationService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.rolesForm = this.formBuilder.group({
      name: ['',Validators.required],
      roleCode: ['', Validators.required],
      description: ['', Validators.required],
      id: [null],
    });
    this.getAllRoles();
    this.getAllPermissions();
    // this.getPermissionByRoleId(3);
  }

  get f(){
    return this.rolesForm.controls;
  }
  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  submitForm() {
    this.submitted = true;
    if(!this.rolesForm.valid){

    }
    else {
        if (this.editMode && _.rolesForm == _.data) {
          //put api function call below
          this.editMode = true;
          let data = {
            "id":this.permissionsid,
            "permission": {
              "permission_id":this.permissionsid,
              "permissions": this.permission_list
            },
            "role_id": this.roleid,
            "permission_order": this.permissionorder
        }
    
        console.log('payload data',data);
          this.currentData = this.rolesForm.value;
          this.roleService
            .updateRow(this.currentData, +this.data.id)
            .subscribe((res) => {
              this.rolesForm.reset();
              this.submitted = false;
              this.getAllRoles();
            }); /////put api function call ends here!
            console.log('edit_data',data);
            
          this.roleService.editPermissionforRole(data).subscribe((res)=>{
            console.log('res',res);
            
          })
          this.editMode = false;
          this.toastrService.success("Data Updated Successfully!");
          this.permission_list = [];
          this.getAllPermissions();
        } else {
          this.editMode = false;
          console.log('roles form',this.rolesForm);
          this.roleService
            .addRoles(this.rolesForm)
            .pipe()
            .subscribe(
              (data) => {
                console.log('...data',data.results[0].id);
                for(var i = 0 ; i < this.permission_list.length; i++){
                  this.assignpermission.push(
                    {
                      "permission": {
                          "permission_id": this.permission_list[i].permissionid,
                          "permission_module": this.permission_list[i].name,
                          "permissions": {
                              "create": this.permission_list[i].create,
                              "view": this.permission_list[i].view,
                              "edit": this.permission_list[i].edit,
                              "delete": this.permission_list[i].delete,
                          }
                      },
                      "role_id": data.results[0].id,
                      "permission_order": this.randomIntFromInterval(1,100)
                  }
                  ) 
                }
  
                console.log('assignpermission',this.assignpermission);
  
                this.roleService.addPermissiontoRole(this.assignpermission).subscribe((res)=>{
                  console.log('...assignrole',this.assignpermission);
                  this.data = data;
                  this.getAllRoles();
                  this.rolesForm.reset();
                  this.submitted = false;
                  this.toastrService.success("Data Added Successfully!");
                  location.reload();
                  
                })
                // this.data = data;
                // this.getAllRoles();
                // this.rolesForm.reset();
                // this.toastrService.success("Data Added Successfully!");
              },
              (error) => {
                this.inValidMessage = true;
                console.log('error__roles',error.error.message);
                
                if(error.status === 409){
                  this.toastrService.danger("Role already exists.");
                }
                else{
                  this.toastrService.danger(error.error.message);
                }
              }
            );
        }
      
    }

  }

  getAllRoles() {
    this.roleService
      .getAllRoles()
      .pipe()
      .subscribe(
        (data) => {
          this.rolesDetails = data;
          this.tableData = this.rolesDetails.results;
          console.log("role data =>", data)
        },
        (error) => {
          this.inValidMessage = true;
        }
      );
  }

  getAllPermissions(){
    this.permissionService.getAllPermission().pipe().subscribe((data:any)=>{
      console.log('permissions1234',data);
      for(var i = 0 ; i < data.results.length ; i++){
        this.permission_list.push({permissionid:data.results[i].permission_id,name:data.results[i].module,create:data.results[i].conditions.create,view:data.results[i].conditions.view,edit:data.results[i].conditions.edit,delete:data.results[i].conditions.delete})
      }

      console.log('permission_list',this.permission_list);
    })
  }

  removeData(id) {
    this.dialogService.open(ShowcaseDialogComponent, {
      context: {
        message: 'Are you sure you want to delete this item?'
      }
    }).onClose.subscribe(confirmed => {
      if (confirmed) {
        this.roleService.deleteRow(id)
        .subscribe(data => {
          console.log('deleted',data);
          this.toastrService.danger("Data Deleted Successfully!");
          this.getAllRoles();
        },(err)=>{
          console.log('err_delete',err);
        });
      }
      
    });
  }

  editData(data) {
    console.log('editdata',data);
    this.data = data;
    this.rolesForm.controls["name"].setValue(this.data.name);
    this.rolesForm.controls["roleCode"].setValue(this.data.role_code);
    this.rolesForm.controls["description"].setValue(this.data.description);
    this.rolesForm.controls["id"].setValue(this.data.id);
    this.editMode = true; //change btn to update

    console.log('role_id',this.data.id);
    this.roleService.getPermissionByRoleId(this.data.id).subscribe((res:any)=>{
     // console.log('permissions by role id', res.results.permission.permissions);
      console.log('res_permissions_.......',res);
      if(res.results!=false){
        console.log('permissionrecord__if__',this.permissions_record);
        if('permissions' in res.results.permission){
          this.permissions_record = res.results.permission.permissions;
          console.log('permissionrecord__if',this.permissions_record);
        }
        else {
          this.permissions_record = res.results.permission;
          console.log('permissionrecord__else',this.permissions_record)
        }
        this.permission_list = [];
        console.log('permission_record__',this.permissions_record.length);
        if(this.permissions_record != undefined){
          if(this.permissions_record.length != 0){
            for(var i = 0 ; i < this.permissions_record.length ; i++){
              this.permission_list.push(
                {
                  permissionid:this.permissions_record[i].permission!=undefined?this.permissions_record[i].permission.permission_id:this.permissions_record[i].permissionid,
                  name:this.permissions_record[i].permission!=undefined?this.permissions_record[i].permission.permission_module:this.permissions_record[i].name,
                  create:this.permissions_record[i].permission!=undefined?this.permissions_record[i].permission.permissions.create:this.permissions_record[i].create,
                  view:this.permissions_record[i].permission!=undefined?this.permissions_record[i].permission.permissions.view:this.permissions_record[i].view,
                  edit:this.permissions_record[i].permission!=undefined?this.permissions_record[i].permission.permissions.edit:this.permissions_record[i].edit,
                  delete:this.permissions_record[i].permission!=undefined?this.permissions_record[i].permission.permissions.delete:this.permissions_record[i].delete
                }
              )
            }
            console.log('permission_list',this.permission_list);
          }
        } 
      }

      else{
        console.log('present here');
        this.permission_list = [];
        console.log('permissionlist____',this.permission_list);
        this.getAllPermissions();
      }
   
      // if('permissions' in res.results.permission){
      //   this.permissions_record = res.results.permission.permissions;
      // }
      // else {
      //   this.permissions_record = res.results.permission;
      // }
      this.permissionsid = res.results.id;
      this.roleid = res.results.role_id;
      this.permissionorder = res.results.permission_order; 

      // this.permission_list = [];
      // console.log('permission_record__',this.permissions_record);
      // if(this.permissions_record != undefined){
      //   if(this.permissions_record.length != 0){
      //     for(var i = 0 ; i < this.permissions_record.length ; i++){
      //       this.permission_list.push(
      //         {
      //           permissionid:this.permissions_record[i].permission.permission_id,
      //           name:this.permissions_record[i].permission.permission_module,
      //           create:this.permissions_record[i].permission.permissions.create,
      //           view:this.permissions_record[i].permission.permissions.view,
      //           edit:this.permissions_record[i].permission.permissions.edit,
      //           delete:this.permissions_record[i].permission.permissions.delete
      //         }
      //       )
      //     }
      //     console.log('permission_list',this.permission_list);
      //   }
      // } 


      
    })
  }

  cancelData() {
    this.rolesForm.reset();
    this.editMode = false;
    this.submitted = false;
    this.permission_list = [];
    this.getAllPermissions();
    //this.toastrService.warning("Cancelled Successfully!")
  }

  getPermissionByRoleId(roleid:any)
  
  {
      this.roleService.getPermissionByRoleId(roleid).pipe().subscribe((res:any)=>{
        
        if(res.results.permission != undefined){
          this.permissions = Object.keys(res.results.permission.permissions[0]);
          console.log('permissions',this.permissions);
        }
        else{
          this.permissions = ['no permissions assigned yet'];
          console.log('permissions not assigned', this.permissions);
        }
      },(err)=>{
        console.log('err',err);
      })
  }

  close(){
    this.permissions = [];
  }
  
  permissioncheck(value:any,id:any,event:any){
    console.log('permission list',this.permission_list);
    console.log('...value_permissions',value,id,event.target.checked);
    
    
    // Find and replace the name of the object with id = 2
    this.permission_list = this.permission_list.map(obj => {
      if (obj.permissionid === id ) {
        if(value == 'create'){
          console.log('event target',event.target.checked);
          return { ...obj, create: event.target.checked };
        }
        else if(value == 'edit'){
          return { ...obj, edit: event.target.checked };
        }
        else if(value == 'delete'){
          return { ...obj, delete: event.target.checked };
        }
        else if(value == 'view'){
          return { ...obj, view: event.target.checked };
        }
         // Use spread syntax to create a new object with the updated name
      } else {
        return obj; // Return the original object for all other cases
      }
    });
     
    console.log('...permission changes',this.permission_list);
  }


  permissioncheck_edit(value:any,id:any,event:any){
    console.log('permission_edit_1234',this.permission_list);
    // if(this.permissions_record!=undefined){
    //   for(var i = 0 ; i < this.permissions_record.length; i++){
    //     if(this.permissions_record[i].permission.permission_id == id){
    //       if(value == 'create'){
    //         this.permissions_record[i].permission.permissions.create = event.target.checked
    //       } 
    //       else if(value == 'view'){
    //         this.permissions_record[i].permission.permissions.view = event.target.checked
    //       }
    //       else if(value == 'edit'){
    //         this.permissions_record[i].permission.permissions.edit = event.target.checked
    //       }
    //       else if(value == 'delete'){
    //         this.permissions_record[i].permission.permissions.delete = event.target.checked
    //       }
        
    //     }
    //   }
  
    //   console.log('permissions_record----123',this.permissions_record);
    // }

    if(this.permission_list!=undefined){
      for(var i = 0 ; i < this.permission_list.length; i++){
        if(this.permission_list[i].permissionid == id){
          if(value == 'create'){
            this.permission_list[i].create = event.target.checked
          } 
          else if(value == 'view'){
            this.permission_list[i].view = event.target.checked
          }
          else if(value == 'edit'){
            this.permission_list[i].edit = event.target.checked
          }
          else if(value == 'delete'){
            this.permission_list[i].delete = event.target.checked
          }
        
        }
      }
  
      console.log('permissions_record----123',this.permissions_record);
    }

    // console.log('permission list',this.permission_list);
    // console.log('...value_permissions',value,id,event.target.checked);
    
    // console.log('permissions record',this.permissions_record);
    // // Find and replace the name of the object with id = 2
    // this.permissions_record = this.permissions_record.map(obj => {
    //   console.log('id',obj.permission.permission_id);
    //   if (obj.permission.permission_id === id ) {
    //     if(value == 'create'){
    //       console.log('event target',event.target.checked);
    //       return { ...obj,create: event.target.checked };
    //     }
    //     else if(value == 'edit'){
    //       return { ...obj, edit: event.target.checked };
    //     }
    //     else if(value == 'delete'){
    //       return { ...obj, delete: event.target.checked };
    //     }
    //     else if(value == 'view'){
    //       return { ...obj, view: event.target.checked };
    //     }
    //      // Use spread syntax to create a new object with the updated name
    //   } else {
    //     return obj; // Return the original object for all other cases
    //   }
    // });
     
    // console.log('...permission changes',this.permissions_record);
  }
  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  get_permissionid(id:any,event:any){
    console.log('permissions_list_______',this.permission_list);
    console.log('event',event.target.checked);
    console.log('permissionid',id);
    if(event.target.checked == true){
      for(var i=0 ; i<this.permission_list.length ; i++){
        
        if(this.permission_list[i].permissionid==id){
          this.permission_list[i].create = true;
          this.permission_list[i].view = true;
          this.permission_list[i].edit = true;
          this.permission_list[i].delete = true;
        }
      
      }

      console.log('checktrue______',this.permission_list);
    }

    else{
        for(var i=0 ; i<this.permission_list.length ; i++){
          if(this.permission_list[i].permissionid==id){
          this.permission_list[i].create = false;
          this.permission_list[i].view = false;
          this.permission_list[i].edit = false;
          this.permission_list[i].delete = false;
        }
      }
 

      console.log('checkfalse______',this.permission_list);
    }
  
  }
}
