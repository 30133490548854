import { Component, OnInit } from '@angular/core';
import { CommondialogComponent } from '../../@core/miscellaneous/commondialog/commondialog.component';
import { MentorService } from '../../services/mentor.service';
import { MatDialog } from "@angular/material/dialog";
import { NbDialogService, NbToastrService } from "@nebular/theme";


@Component({
  selector: 'ngx-mentorformsall',
  templateUrl: './mentorformsall.component.html',
  styleUrls: ['./mentorformsall.component.scss']
})
export class MentorformsallComponent implements OnInit {
  forms: any;
  mat_data: string;
  inValidMessage: boolean;

  constructor(private MentorService:MentorService,private dialog: MatDialog,private toastrService: NbToastrService) { }

  ngOnInit(): void {
    this.getAllForms();
  }

  getAllForms(){
    this.MentorService.getAllRegistrationForms().subscribe((res)=>{
      this.forms = res
    })
  }

  changestatus(id: string, value: string) {
    // localStorage.setItem('myData', 'Startup');
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = '0'; 
      htmlElement.style.position = 'sticky';
    }
    this.mat_data = value;
    if(this.mat_data=='REJECTED'){
      this.mat_data= 'Reject'
    }else if(this.mat_data=="APPROVED"){
      this.mat_data= 'Approve'
    }
    const dialogRef=this.dialog.open(CommondialogComponent,{
          data: { status: this.mat_data, name: 'Form' }
        })
        dialogRef.afterClosed().subscribe(result => {
          if(result==true){
            var data = {
              id: id,
              status: value,
            };
            this.MentorService.changeStatus(data).subscribe(
              (res: any) => {
                console.log("change_status", res);
                // this.getAllStartUpData();
                // this.listAllStartups();
                localStorage.removeItem('myData');
                this.toastrService.success("Successfully updated Status!");
                location.reload();
                const updatedStartupIndex = this.forms.findIndex(item => item.id === id);
                  if (updatedStartupIndex !== -1) {
                    this.forms[updatedStartupIndex].status = value;
                  }
              },
              (error) => {
                this.inValidMessage = true;
                this.toastrService.warning("Unable to Update Status!");
              }
            );
          }
        })
  }

}
