
<div class="row">
    <div class="col-sm-4">
        <label>State</label>
        <ng-multiselect-dropdown [(ngModel)]="selectstate" [placeholder]="''" [settings]="dropdownSettings_s"
            [data]="dropdownstates" [(ngModel)]="selectedStates" [ngModelOptions]="{standalone: true}">
        </ng-multiselect-dropdown>
    </div>
    <div class="col-sm-4">
        <label>City</label>
        <input type="text" class="form-control" [(ngModel)]="selectcity" placeholder="Enter City">
    </div>
    <div class="col-sm-4">
        <label>Domain</label>
        <ng-multiselect-dropdown [(ngModel)]="selectdomain" [placeholder]="''" [settings]="dropdownSettings"
            [data]="dropdownList" [(ngModel)]="selectedItems" [ngModelOptions]="{standalone: true}"
            (onSelect)="onItemSelectDomain($event)" (onDeSelect)="onItemDeSelectDomain($event)">
        </ng-multiselect-dropdown>
    </div>
</div>

<div class="row startups-status-filter-reset">
    <div class="col-sm-4">
        <label>Status</label>
        <ng-multiselect-dropdown [(ngModel)]="selectstatus" [placeholder]="''" [settings]="dropdownSettings"
            [data]="dropdownstatus" [(ngModel)]="selectedStatus" [ngModelOptions]="{standalone: true}">
        </ng-multiselect-dropdown>
    </div>
    <div class="col-sm-8">
        <label>.</label>
        <div>
            <button class="btn btn-primary mr-1" (click)="filterItem()" *ngIf="filterMode" id="filter"> Filter </button>
            <button class="btn btn-secondary mr-1" (click)="resetFilters()">Reset</button>
            <button class="btn btn-success mr-1" (click)="downloadStartupList()">
                <i class="fas fa-download"></i>
                Download
            </button>
        </div>

    </div>
</div>

<div class="row startups-schemes-download">
    <div class="col-sm-6 ">
        <label>Schemes</label>
        <ng-multiselect-dropdown placeholder="select scheme" [settings]="dropdownSettings_scheme"
            [data]="dropdownschemes" [(ngModel)]="selectedScheme" (onSelect)="schemeChanged($event)"
            [ngModelOptions]="{standalone: true}"></ng-multiselect-dropdown>
    </div>
    <div class="col-sm-6">
        <label>.</label>
        <div>
            <button class="btn btn-success" (click)="downloadStartupList()">
                <i class="fas fa-download"></i>
                Download
            </button>
        </div>
    </div>
</div>

<br>
<!-- filter count row -->
<div class="filter-container">
    <div class="filter-row justify-content-end">
        <p (click)="countFilter('All')" [class.active]="activeFilter == null">
            View All <span>{{ (+approved_count) + (+rejected_count) + (+pending_count) }}</span>
        </p>
        <p (click)="countFilter('APPROVED',approved_count)" [class.active]="activeFilter == 'APPROVED'" [class.disabled]="!dsApproved">
            APPROVED <span>{{ approved_count }} </span>
        </p>
        <p (click)="countFilter('REJECTED',rejected_count)" [class.active]="activeFilter == 'REJECTED'" [class.disabled]="!dsRejected">
            REJECTED <span>{{ rejected_count }}</span>
        </p>
        <p (click)="countFilter('PENDING',pending_count)" [class.active]="activeFilter == 'PENDING'" [class.disabled]="!dsPending">
            PENDING <span>{{ pending_count }}</span>
        </p>
    </div>
</div>

<!-- filter count row -->


<div class="container-grid col-12 col-sm-12">
    
    <div class="p-2">
        <strong class="d-flex justify-content-center" style="font-size: 27px;">Startup Detail</strong>
    </div>

    <div class="container-lg">

        <div class="table-wrapper table-responsive">
            <div class="loader-container" *ngIf="loading">
                <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <table class="table table-striped" *ngIf="!loading">
                <thead>
                    <tr>
                        <th scope="col" class="text-center">Sl No</th>
                        <th>Updated Time</th>
                        <th>Startup Name</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Domain</th>
                        <!-- <th>Stages</th> -->
                        <th>Status</th>
                        <th *ngIf="activeFilter !== 'PENDING'" style="text-align: center;text-transform: capitalize">{{ activeFilter || 'Approved/Rejected' }} By</th>
                        <th>Last Logged In</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody *ngIf="data?.length > 0; else noRecords">
                    <tr *ngFor="let d of data let i = index">
                        <th scope="row" class="text-center">{{getSerialNo(i+1)}}</th>
                        <td>{{d.updated_at | date}}</td>
                        <td>{{d.name | titlecase}}</td>
                        <td>{{d.state | titlecase}}</td> 
                        <td>{{d.city | titlecase}}</td>
                        <td>{{d.domain}}</td>
                        <!-- <td>{{d.startup_stage | titlecase}}</td> -->
                        <td>{{d.status}}</td>
                        <td *ngIf="activeFilter !== 'PENDING'">{{d.approved_by}}</td>
                        <td class="text-center">{{ d.login_info != null ? (d.login_info.last_login_time | date) : '-' }}</td>
                        <td class="d-flex justify-content-center">
                            <div class="d-flex">
                                <div *ngIf="d.status === 'PENDING'" class="d-flex">
                                    <a class="dropbtn fa fa-check mr-2" style="color:blue"
                                        (click)="changestatus(d.id, 'APPROVED')"></a>
                                    <a class="dropbtn fa fa-close mr-2 " style="color:red"
                                        (click)="changestatus(d.id, 'REJECTED')"></a>
                                </div>
                                <div *ngIf="d.status === 'APPROVED'">
                                    <a class="dropbtn fa fa-close mr-2 ml-4" style="color:red"
                                        (click)="changestatus(d.id, 'REJECTED')"></a>
                                </div>
                                <div *ngIf="d.status === 'REJECTED'">
                                    <a class="dropbtn fa fa-check mr-4" style="color:blue"
                                        (click)="changestatus(d.id, 'APPROVED')"></a>
                                </div>
                                <div>
                                    <a class="fa fa-eye arrow" (click)="preview(d.id)" style="color:green"></a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <ng-template #noRecords>
                    <tbody>
                        <tr> <td colspan="8" class="no-records-found">No Data found.</td> </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>

        <div class="w-100 mt-3">
            <ul class="d-flex list-unstyled float-right">
                <!-- Pagination links will be dynamically generated here -->
                <li class="mr-2 p-3"><a (click)="getPrevSet()"><<</a></li>
                <li class="mr-2 p-3" [ngClass]="{'bg-primary text-white':selectedindex==i-1}" *ngFor = "let i of initial_pages;let i1 = index"><a class="paginationlink text-decoration-underline" (click)="paginate_startup(i)"> {{i}} </a></li>
                <li class="mr-2 p-3"><a (click)="getNextSet()">>></a></li>
                <!-- <li class="mr-2" *ngFor = "let r of remaining_pages"><a class="paginationlink" (click)="paginate_startup(i-1)"> {{r}} </a></li> -->
            </ul>
        </div>
        

    </div>
</div>