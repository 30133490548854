<div class="container bg-white">
  <div class="d-flex justify-content-end p-3">
      <button type="button" class="btn btn-primary" (click)="downloadTableData()">Download</button>
</div>
    <table class="table text-center">
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col" class="col-3">Name of the Accelerator</th>
            <th scope="col">Startup Name</th>

            <th scope="col" class="col-3">Total Fund Released(in Lakhs)</th>
            <th scope="col" class="col-3">Admin Fee released(Cumulative)(in Lakhs)</th>
            <th>Startup Fund Released(Cumulative)(in Lakhs)</th>
            <th>View Details</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of fundreleaseData | paginate: { itemsPerPage: pageSize, currentPage: p } ; let i= index">
            <th scope="row">{{ i + 1 +(p-1)*pageSize }}</th>
            <td>{{item.acceleratorfund.accelerator.name}}</td>
            <td>{{item.startup.name}}</td>

            <td>{{totalFund(item.acceleratorfund.admin_fee,item.grant_amount)+'L'}}</td>
            <td>{{item.acceleratorfund.admin_fee + 'L'}}</td>
            <td>{{item.grant_amount + 'L'}}</td>
            <td><i class="fa fa-info-circle" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="newdata(item)"></i></td>
          </tr>
          
        </tbody>
      </table>
      <pagination-controls *ngIf="fundreleaseData?.length>0" (pageChange)="onPageChange($event)" directionLinks="true"  class="float-right"></pagination-controls>

</div>
<!-- samridh modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <h4 class="modal-title" id="exampleModalLabel"><b></b></h4> -->
        </div>
        <div class="modal-body scroll-bar" >
          <div>
            <p><b>Startup Name</b> {{newdataaa?.startup.name}}</p>
            <p><b>Name of the Accelerator</b> : {{newdataaa?.acceleratorfund.accelerator.name}}</p>
            <p><b>Total Fund Released:</b> {{ totalFund(newdataaa?.acceleratorfund?.admin_fee, newdataaa?.grant_amount)+'L' }}</p>
            <p><b>Admin Fee released(Cumulative)</b>    :{{newdataaa?.acceleratorfund.admin_fee + 'L'}} </p>
            <p><b>Startup Fund Released(Cumulative)</b>  : {{newdataaa?.grant_amount + 'L'}}</p>    

            
            </div>
         
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>