import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import axios from 'axios';
import { isArray } from 'rxjs/internal-compatibility';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';

@Component({
  selector: 'ngx-scheme-incubator-funds-utilization',
  templateUrl: './scheme-incubator-funds-utilization.component.html',
  styleUrls: ['./scheme-incubator-funds-utilization.component.scss']
})
export class SchemeIncubatorFundsUtilizationComponent implements OnInit {
  
  mc_for_Eir:any;
  mc_for_Grant:any;
  _hackathon:any;
  challenge_grant:any;
  deepEngamementProgram:any;
  _workshop:any;
  inc_fund_id: any;
  incubator_id: any;
  @Input() fundRes: any;

  startupForm:any;
  startupFormList:any;
  incubatorfund_id:any;
  fundUtilizationDetails:any;
  FundUtilizationForm:any;
  termsList:any;
  incTermHeadsResponse:any;
  termGroupList:any;
  startupFundUtilisedDetail:any;
  tenure: number;
  incfundutilresponse: any;
  submitted:any;

  constructor(
    private fb:FormBuilder,
    private grantMangementService: GrantManagementService,
    private toastrService: NbToastrService,
    private _route: ActivatedRoute, 
  ) { }

  ngOnInit(): void {
    console.log("IN FINANCIAL UTILIZATION ::::", this.fundRes);
    this.inc_fund_id = this._route.snapshot.paramMap.get("incid");
    this.incubator_id = this._route.snapshot.paramMap.get("incubator_id");
    if(this.inc_fund_id && this.incubator_id) {
      this.grantMangementService.getFundUtilizationDetails(this.inc_fund_id).subscribe(res=>{
        console.log('TERM HEAD RESPONSE:::',res);
        this.incTermHeadsResponse = res;
        if(this.incTermHeadsResponse?.results.length>0) {
          //this.patchUpdatedNestedForm();
          this.patchNestedForm()
        }
      });
      this.addFundUtil()
      //this.getTermsDetails(0)
    }
  }
  addFundUtil() {
    this.startupFundUtilisedDetail = this.fb.group({
      startupFundutilised: this.fb.array([
        this.fb.group({
          eir_approved_mount:['', [Validators.required]],
          eirmanagement_approvedAmount:['', [Validators.required]],
          grant_approved_mount:['', [Validators.required]],
          grantmanagement_approvedAmount:['', [Validators.required]],
          hackathon_approvedAmount:['', [Validators.required]],
          challenge_approvedAmount:['', [Validators.required]],
          dep_approvedAmount:['', [Validators.required]],
          workshop_approvedAmount:['', [Validators.required]],
          amountutilisedinQuarters: this.fb.array([
            this.fb.group({
              eir_utilized:[''],
              eirmanagement_utilized:[''],
              grant_utilized:[''],
              grantmanagement_utilized:[''],
              hackathon_utilized:[''],
              challengegrant_utilized:[''],
              dep_utilized:[''],
              workshop_utilized:[''],
            })
          ])
        })
      ])
    });
  }

  
  async patchNestedForm() {
    await this.getListOfStartups();

    const fundArr:any = this.incTermHeadsResponse.results[0]?.sf_list;
    // this.projectFinancial_details;
    //console.log("length", fundArr.length);
    for (let i = 0; i < fundArr?.length; i++) {
      const financialDetail = fundArr[i];

      let eir_appramount = financialDetail.headfunds[0]?.approved_amount;
      let eirm_appramount = financialDetail.headfunds[1]?.approved_amount;
      let grant_appramount = financialDetail.headfunds[2]?.approved_amount;
      let grantm_appramount = financialDetail.headfunds[3]?.approved_amount;
      let hack_appramount = financialDetail.headfunds[4]?.approved_amount;
      let challenge_appramount = financialDetail.headfunds[5]?.approved_amount;
      let dep_appramount = financialDetail.headfunds[6]?.approved_amount;
      let work_appramount = financialDetail.headfunds[7]?.approved_amount;

      //======================\
      const group = this.fb.group({
        Eir_approvedAmount: [
          financialDetail.headfunds[0]?.approved_amount,
          [Validators.required],
        ],

        mcEir_approvedAmount: [
          financialDetail.headfunds[1]?.approved_amount,
          [Validators.required],
        ],

        Grant_approvedAmount: [
          financialDetail.headfunds[2]?.approved_amount,
          [Validators.required],
        ],

        mcGrant_approvedAmount: [
          financialDetail.headfunds[3]?.approved_amount,
          [Validators.required],
        ],
        hackathon_approvedAmount: [
          financialDetail.headfunds[4]?.approved_amount,
          [Validators.required],
        ],
        challengeGrant_approvedAmount: [
          financialDetail.headfunds[5]?.approved_amount,
          [Validators.required],
        ],
        dep_approvedAmount: [
          financialDetail.headfunds[6]?.approved_amount,
          [Validators.required],
        ],
        workshop_approvedAmount: [
          financialDetail.headfunds[7]?.approved_amount,
          [Validators.required],
        ],
        total_approvedAmount: [0],
        Eir_UtilizedTotal: [this.calculateTotal(financialDetail, 0)],
        Eir_unUtilized: [this.calculateunUtilizedTotal(financialDetail, 0)],

        mcEir_UtilizedTotal: [this.calculateTotal(financialDetail, 1)],
        mcEir_unUtilized: [this.calculateunUtilizedTotal(financialDetail, 1)],

        Grant_UtilizedTotal: [this.calculateTotal(financialDetail, 2)],
        Grant_unUtilized: [this.calculateunUtilizedTotal(financialDetail, 2)],

        mcGrant_UtilizedTotal: [this.calculateTotal(financialDetail, 3)],
        mcGrant_unUtilized: [this.calculateunUtilizedTotal(financialDetail, 3)],
        hackathon_UtilizedTotal: [this.calculateTotal(financialDetail, 4)],
        hackathon_unUtilized: [this.calculateunUtilizedTotal(financialDetail, 4)],
        challengeGrant_UtilizedTotal: [this.calculateTotal(financialDetail, 5)],
        challengeGrant_unUtilized: [this.calculateunUtilizedTotal(financialDetail, 5)],
        dep_UtilizedTotal: [this.calculateTotal(financialDetail, 6)],
        dep_unUtilized: [this.calculateunUtilizedTotal(financialDetail, 6)],
        workshop_UtilizedTotal: [this.calculateTotal(financialDetail, 7)],
        workshop_unUtilized: [this.calculateunUtilizedTotal(financialDetail, 7)],
        total_UtilizedTotal: [0],
        total_unUtilized: [0],
        startupfund_id:[financialDetail.startupfund_id],
        amountutilisedinQuarters: this.fb.array([]),
      });

      group.valueChanges.subscribe(res => {
        let total_approved = Number(group.get('Eir_approvedAmount').value) + Number(group.get('mcEir_approvedAmount').value) + Number(group.get('Grant_approvedAmount').value) + Number(group.get('mcGrant_approvedAmount').value) + Number(group.get('hackathon_approvedAmount').value) + Number(group.get('challengeGrant_approvedAmount').value) + Number(group.get('dep_approvedAmount').value) + Number(group.get('workshop_approvedAmount').value);
        group.get('total_approvedAmount').patchValue(total_approved, { emitEvent: false });

        let total_UtilizedTotal = Number(group.get('Eir_UtilizedTotal').value) + Number(group.get('mcEir_UtilizedTotal').value) + Number(group.get('Grant_UtilizedTotal').value) + Number(group.get('mcGrant_UtilizedTotal').value) + Number(group.get('hackathon_UtilizedTotal').value) + Number(group.get('challengeGrant_UtilizedTotal').value) + Number(group.get('dep_UtilizedTotal').value) + Number(group.get('workshop_UtilizedTotal').value);;
        group.get('total_UtilizedTotal').patchValue(total_UtilizedTotal, { emitEvent: false });

        let total_unUtilized = Number(group.get('Eir_unUtilized').value) + Number(group.get('mcEir_unUtilized').value) + Number(group.get('Grant_unUtilized').value) + Number(group.get('mcGrant_unUtilized').value) + Number(group.get('hackathon_unUtilized').value) + Number(group.get('challengeGrant_unUtilized').value) + Number(group.get('dep_unUtilized').value) + Number(group.get('workshop_unUtilized').value);;
        group.get('total_unUtilized').patchValue(total_unUtilized, { emitEvent: false });
      });

      for (let j = 0; j < financialDetail.terms.length; j++) {
        // const termDetail = termsDetails[j];

        const termGroup = this.fb.group({
          Eir_Utilizedq1: [financialDetail.terms[j].heads[0].amount_utilized],
          mcEir_Utilizedq1: [financialDetail.terms[j].heads[1].amount_utilized],
          Grant_Utilizedq1: [financialDetail.terms[j].heads[2].amount_utilized],
          mcGrant_Utilizedq1: [financialDetail.terms[j].heads[3].amount_utilized],
          hackathon_Utilizedq1: [financialDetail.terms[j].heads[4].amount_utilized],
          challengeGrant_Utilizedq1: [financialDetail.terms[j].heads[5].amount_utilized],
          dep_Utilizedq1: [financialDetail.terms[j].heads[6].amount_utilized],
          workshop_Utilizedq1: [financialDetail.terms[j].heads[7].amount_utilized],
          total_Utilizedq1: [0],
          fundterm_id:[financialDetail.terms[j].incubatorfundterm_id],
        });

        //===================\
       
        let total_Utilizedq1 = Number(termGroup.get('Eir_Utilizedq1').value) + Number(termGroup.get('mcEir_Utilizedq1').value) + Number(termGroup.get('Grant_Utilizedq1').value) + Number(termGroup.get('mcGrant_Utilizedq1').value) + Number(termGroup.get('hackathon_Utilizedq1').value) + Number(termGroup.get('challengeGrant_Utilizedq1').value) + Number(termGroup.get('dep_Utilizedq1').value) + Number(termGroup.get('workshop_Utilizedq1').value);
        termGroup.get('total_Utilizedq1').patchValue(total_Utilizedq1, { emitEvent: false });
        // Add the term detail group to the current financial detail group
        (group.get("amountutilisedinQuarters") as FormArray).push(termGroup);
      }

      // Add the financial detail group to the form array
      (this.startupFundUtilisedDetail.get("startup_Fund_utilised") as FormArray ).push(group);
    }
  }
 
  async getListOfStartups() {
    return new Promise((resolve, reject) => {
      this.startupFundUtilisedDetail = this.fb.group({
        startup_Fund_utilised: this.fb.array([]),
      });
      resolve(this.startupFundUtilisedDetail);
    });
  }

  
  postFundUtisedDetail(){
    console.log("=====",this.startupFundUtilisedDetail.value)
    if (this.startupFundUtilisedDetail.invalid) {
      // show error message
        this.startupFundUtilisedDetail.markAllAsTouched();
        console.log("Please fill in all mandatory fields.",this.startupFundUtilisedDetail.value);
        return;
    } else {
      //remap the fields as per api
      let fundUtilArr = this.startupFundUtilisedDetail.value?.startup_Fund_utilised;
      ;
      let modifiedArr = [];
      if(fundUtilArr && isArray(fundUtilArr) && fundUtilArr.length>0) {
        let innerObj:any = {};
        for(let i=0; i<fundUtilArr.length; i++) {
          let innerObj:any = {};
          let heads = [];
          let eir_head:any = {};
          let eirm_head:any = {};
          let grant_head:any = {};
          let grantm_head:any = {};
          let hack_head:any = {};
          let chalenge_head:any = {};
          let dep_head:any = {};
          let work_head:any = {};

          eir_head.head = "EIR";
          eir_head.approved_amount = fundUtilArr[i].Eir_approvedAmount
          heads.push(eir_head);

          eirm_head.head = "Support/Management Cost for EiR";
          eirm_head.approved_amount = fundUtilArr[i].mcEir_approvedAmount
          heads.push(eirm_head);

          grant_head.head = "Grant";
          grant_head.approved_amount = fundUtilArr[i].Grant_approvedAmount
          heads.push(grant_head);

          grantm_head.head = "Support/Management Cost for Grant";
          grantm_head.approved_amount = fundUtilArr[i].mcGrant_approvedAmount
          heads.push(grantm_head);

          hack_head.head = "Hackathon";
          hack_head.approved_amount = fundUtilArr[i].hackathon_approvedAmount
          heads.push(hack_head);

          chalenge_head.head = "Challenge Grant";
          chalenge_head.approved_amount = fundUtilArr[i].challengeGrant_approvedAmount
          heads.push(chalenge_head);

          dep_head.head = "Deep Engagement Program";
          dep_head.approved_amount = fundUtilArr[i].dep_approvedAmount
          heads.push(dep_head);

          work_head.head = "Workshop";
          work_head.approved_amount = fundUtilArr[i].workshop_approvedAmount
          heads.push(work_head);

          innerObj.startupfund_id = fundUtilArr[i].startupfund_id;
          innerObj.heads = heads

          modifiedArr.push(innerObj)
        }
      }
      const mapObj = {
        "sf_list":modifiedArr
      }

      // API call with mapObj to bulk update here
      console.log("MAP::::",mapObj)
      this.grantMangementService.createIncubatorFundUtilization(mapObj).subscribe(res=>{
        this.incfundutilresponse = res;
        this.submitted = true;
        this.toastrService.success("Updated Scheme Fund Successfully!");
        window.location.reload();
      });
    }
  }
  get startupFundUtilised() {
    return this.startupFundUtilisedDetail.get('startup_Fund_utilised') as FormArray;
  }

  //termdetails

  getTermsDetails(index: number) {
    return this.startupFundUtilised.at(index).get('amountutilisedinQuarters') as FormArray;
  }

  //utilized total

  calculateTotal(financialDetail: any, headIndex: number): number {
    let total = 0;
    for (let j = 0; j < financialDetail.terms.length; j++) {
      const amountUtilized = financialDetail.terms[j].heads[headIndex].amount_utilized;
      if (amountUtilized) {
        total += parseInt(amountUtilized, 10);
      }
    }
    return total;
  }

  //unutilized amount
  calculateunUtilizedTotal(financialDetail: any, headIndex: number): number {
    let approved_amount = financialDetail.headfunds[headIndex].approved_amount
    let total = 0;
    for (let j = 0; j < financialDetail.terms.length; j++) {
      const amountUtilized = financialDetail.terms[j].heads[headIndex].amount_utilized;
      if (amountUtilized) {
        total += parseInt(amountUtilized, 10);
      }
    }
    return approved_amount-total || 0;
  }

}
