import { HttpClient, HttpErrorResponse , HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from "../../../environments/environment";

const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Authorization', `Bearer ${sessionStorage.getItem('admintoken')}`);

@Injectable({
  providedIn: "root",
})
export class IncubatorService {
  baseUrl = environment.apiurl;

  constructor(
    private http: HttpClient
  ) {}
 
  getIncubatorsBySchemeId(scheme_id: any){
    try {
      let url = this.baseUrl + `incubators/list?scheme_id=${scheme_id}`;
      let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result:::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  getIncubatorsByGroupType(data: any){
    try {
      let url = this.baseUrl + `schemes/${data.scheme}/incubators/list?group=${data.id}`;
      let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result:::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  getGrantIncubatorsBySchemeId(scheme_id: any){
    try {
      let url = this.baseUrl + `grant/incubators/listByScheme?scheme_id=${scheme_id}`;
      let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  getStartupsByIncubatorId(incubator_id: any){
    try {
      let url = this.baseUrl + `startups/list?incubator_id=${incubator_id}`;
      console.log("URL:::",url);
      let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result:::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  getListByIncubatorFund(incubator_id: any){
    try {
      let url = this.baseUrl + `accounts/terms/listByIncubatorFund?incubatorfund_id=${incubator_id}`;
      console.log("URL:::",url);
      let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result:::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  getIncubatorProjectDetails(incubatorfund_id: any){
    try {
      let url = this.baseUrl + `grant/incubators?incubatorfund_id=${incubatorfund_id}`;
      console.log("URL:::",url);
      let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result:::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
