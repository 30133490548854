import { Component, Input, OnInit } from "@angular/core";
import * as XLSX from "xlsx";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ExportToCsv } from "export-to-csv";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as _ from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { DialogNamePromptComponent } from "../../@core/miscellaneous/dialog/dialog-name-prompt/dialog-name-prompt.component";
import { FacilitiesService } from "../../services/facilities.service";

const styles = {
  header: {
    fontSize: 18,
    bold: true,
  },
  subheader: {
    fontSize: 14,
    bold: true,
  },
  body: {
    fontSize: 12,
  },
};

@Component({
  selector: "ngx-labs-facilties-status",
  templateUrl: "./labs-facilties-status.component.html",
  styleUrls: ["./labs-facilties-status.component.scss"],
})
export class LabFacilitiesStatusComponent implements OnInit {
  data: any;
  items = [{ title: "PDF" }, { title: "EXCEL" }];
  list: any = [];
  action: { status: string };
  inValidMessage: boolean;
  allentities: any;
  myArray: any[] = [];
  itemName: string;
  itemCategory: string;
  selectfacility: any = "";
  selectentity: any = "";
  selectstatus: any = [];
  dropdownFacilities: any = [
    "LAB",
    "GUESTHOUSE",
    "COWORKING_SPACE",
    "EQUIPMENT",
  ];
  dropdownEntities = ["INCUBATOR", "PROJECT MANAGER"];
 // selectedStatus: any[];
  dropdownstatus: any[] = ["APPROVED", "PENDING", "DECLINED"];
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings_t: IDropdownSettings = {};
  originalData: any[];
  public domain = [];
  public sector = [];
  filteredlist = [];
  filterMode: boolean = true;
  resetMode: boolean = false;
  p: number = 1;
  filterValue: any = {};
  apidata: any;
  facility: any;
  entity: any;
  status: any;
  modaldata = {
    name: "",
    info: "",
    websiteurl: "",
    benefits: "",
    eligibility: "",
    img1: "",
    img2: "",
    img3: "",
    img4: "",
    incharge_details: {
      name: "",
      phone: "",
      designation: "",
      email: "",
    },
    address_line1: "",
    city: "",
    state: "",
    zip_code: "",

    availableRoomsNo: "",
    foodType: "",
    shareType: "",
    description: "",

    delegatesName: "",
    delegatesCharge: "",
    startupName: "",
    startupCharge: "",
    icDelegates: "",
    icDelegatesCharges: "",
    otherCharges: "",
    otherName: "",

    selectedType: "",
    otherFacility: "",
    noOfPeople: "",
    anycharges: "",
    inchargeInstruction: "",

    details: "",
  };

  constructor(
    private facilitiesService: FacilitiesService,
    private toastrService: NbToastrService,
    private router: Router,
    private fb: FormBuilder,
    private _route: ActivatedRoute,
    private dialogService: NbDialogService,
    private route: ActivatedRoute
  ) {
    // this.filterValue = this._route.snapshot.queryParamMap.get("filter");
    // console.log("filtervalue", this.filterValue);

    this.route.queryParams.subscribe((params) => {
      this.facility = params["facility"];
      this.entity = params["entity"];
      this.status = params["status"];
    });
  }

  ngOnInit(): void {
    this.listAllFacility(this.facility, this.entity, this.status);

    // this.dropdownList = [this.facility];
     //this.selectedStatus = [this.status];
    // this.filteredlist = [];
    this.selectentity=[this.entity];
    this.selectfacility=[this.facility];
    this.selectstatus=[this.status];
    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };

    this.dropdownSettings_t = {
      singleSelection: true,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
  }

  listAllFacility(facility: string, entity: string, status: string) {
    if (!_.isEmpty(entity)) {
      this.myArray.push({
        name: "creator",
        value: entity === "PROJECT MANAGER" ? "SYSTEM" : "USER",
      });
    }

    if (!_.isEmpty(status)) {
      this.myArray.push({
        name: "status",
        value: status,
      });
    }

    if (!_.isEmpty(facility)) {
      this.apidata = {
        filters: this.myArray,
        search_entity: facility,
        page: 0,
        page_size: 50,
      };
      console.log("initial payload resource", this.apidata);
    } else {
      this.apidata = {
        filters: this.myArray,
        page: 0,
        page_size: 10,
      };
      console.log("initial payload no resource", this.apidata);
    }

    this.facilitiesService
      .filterFacility(this.apidata)
      .subscribe((res: any) => {
        console.log("initial response", res);
        this.data = res.results;
      });
  }

  changestatus(
    id: string,
    status: string,
    resource_type: string,
    entity: string
  ) {
    this.dialogService
      .open(DialogNamePromptComponent, {})
      .onClose.subscribe((confirmed) => {
        if (confirmed) {
          var data = {
            status: status,
          };
          this.facilitiesService
            .actionFacility(data, id, this.getResourceName(resource_type))
            .subscribe(
              (res: any) => {
                console.log("change_status", res);
                this.listAllFacility(
                  this.getResourceName(resource_type),
                  entity,
                  status
                );
                this.toastrService.success("Successfully updated Status!");
              },
              (error) => {
                this.inValidMessage = true;
                this.toastrService.warning("Unable to Update Status!");
              }
            );
        }
      });
  }

  filterItem() {
    this.myArray = [];
    this.filterMode = false;
    this.resetMode = true;

    console.log("selectentity", this.selectentity);
    console.log("selectstatus", this.selectstatus);
    console.log("selectfacility", this.selectfacility);

    let value = _.map(this.selectentity, (i) => {
      return i === "PROJECT MANAGER" ? "SYSTEM" : "USER";
    });
    console.log("value", value);

    if (this.selectentity.length != 0) {
      this.myArray.push({
        name: "creator",
        value: value,
      });
    }

    if (this.selectstatus.length != 0) {
      this.myArray.push({
        name: "status",
        value: this.selectstatus,
      });
      console.log("selectcity1223:", this.myArray);
    }

    if (this.selectfacility.length != 0) {
      this.apidata = {
        filters: this.myArray,
        search_entity: this.selectfacility[0],
        page: 0,
        page_size: 10,
      };
      console.log("payload if resource present", this.apidata);
    } else {
      this.apidata = {
        filters: this.myArray,
        page: 0,
        page_size: 10,
      };
      console.log("payload if resource not present", this.apidata);
    }
    this.facilitiesService
      .filterFacility(this.apidata)
      .subscribe((res: any) => {
        console.log("filter res:", res);
        this.data = res.results;
      });
  }

  resetFilters() {
    // this.resetMode = false;
    // this.filterMode = true;
    console.log("selectentity", this.selectentity);
    console.log("selectstatus", this.selectstatus);
    console.log("selectfacility", this.selectfacility);
    // let data = {
    //   filters: [],
    //   page: 0,
    //   page_size: 10,
    // };
    // this.facilitiesService.filterFacility(data).subscribe((res: any) => {
    //   console.log("reset data:", res);
    //   this.data = res.results;
    // });
    // clear the filter criteria object
    this.data = {};
    this.selectfacility = [];
    this.selectentity = [];
    this.selectstatus = '';
    // reset the filtered data to the original data
    this.data = this.originalData;
    this.listAllFacility(this.selectfacility,this.selectentity,this.selectstatus);
  }
  preview(item) {
    console.log("item", item);
    this.modaldata.name = item.name;
    this.modaldata.info = item.information;
    this.modaldata.websiteurl = item.website_url;
    this.modaldata.benefits = item.benefits;
    this.modaldata.eligibility = item.eligibility;
    this.modaldata.img1 = item.documents[0]?.url;
    this.modaldata.img2 = item.documents[1]?.url;
    this.modaldata.img3 = item.documents[2]?.url;
    this.modaldata.img4 = item.documents[3]?.url;
    this.modaldata.incharge_details.name = item.incharge_details.name;
    this.modaldata.incharge_details.designation =
      item.incharge_details.designation;
    this.modaldata.incharge_details.phone = item.incharge_details.phone;
    this.modaldata.incharge_details.email = item.incharge_details.email;
    this.modaldata.address_line1 = item.address_line1;
    this.modaldata.city = item.city;
    this.modaldata.state = item.state;
    this.modaldata.zip_code = item.zip_code;

    this.modaldata.availableRoomsNo = item.no_of_rooms;
    this.modaldata.foodType = item.food_type;
    this.modaldata.shareType = item.sharing_type;
    this.modaldata.description = item.description;

    this.modaldata.delegatesName =
      item.sections.list[0]?.target_user_list[0]?.name;
    this.modaldata.delegatesCharge =
      item.sections.list[0]?.target_user_list[0]?.charge;
    this.modaldata.startupName =
      item.sections.list[0]?.target_user_list[1]?.name;
    this.modaldata.startupCharge =
      item.sections.list[0]?.target_user_list[1]?.charge;
    this.modaldata.icDelegates =
      item.sections.list[0]?.target_user_list[2]?.name;
    this.modaldata.icDelegatesCharges =
      item.sections.list[0]?.target_user_list[2]?.charge;
    this.modaldata.otherCharges =
      item.sections.list[0]?.target_user_list[3]?.name;
    this.modaldata.otherName =
      item.sections.list[0]?.target_user_list[3]?.charge;

    this.modaldata.selectedType = item.selectedType;
    this.modaldata.otherFacility = item.otherFacility;
    this.modaldata.noOfPeople = item.noOfPeople;
    this.modaldata.inchargeInstruction = item.inchargeInstruction;

    this.modaldata.details = item.details;
  }

  getFacilityName(facility: string): string {
    if (facility === "LAB") {
      return "lab";
    } else if (facility === "GUESTHOUSE") {
      return "guesthouse";
    } else if (facility === "COWORKING_SPACE") {
      return "coworking";
    } else if (facility === "EQUIPMENT") {
      return "equipment";
    }
  }

  getEntityType(entity: string): string {
    if (entity === "USER") {
      return "INCUBATOR";
    } else if (entity === "SYSTEM") {
      return "PROJECT MANAGER";
    }
  }

  getResourceName(facility: string): string {
    if (facility === "labresource") {
      return "lab";
    } else if (facility === "guesthouseresource") {
      return "guesthouse";
    } else if (facility === "coworkingresource") {
      return "coworking";
    } else if (facility === "equipmentresource") {
      return "equipment";
    }
  }
}
