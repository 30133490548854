<!-- Co-working form-->
<div class="mb-3 ml-1 mr-1">
  <div>
    <h2 class="h2 bg-white mx-auto text-center">Co-Working Space Details</h2>
  </div>
  <div class="d-flex justify-content-end">
    <a
      class="btn btn-primary float-right mb-2 text-white mr-4 ml-5"
      (click)="gotosection(section)"
      >Co working Listing</a
    >
    <a
      class="btn btn-primary float-right mb-2 text-white"
      (click)="gotoBooking()"
      >Bookings Listing</a
    >
  </div>
  <div class="container-grid" id="lab_form">
    <form [formGroup]="coWorkingForm" #section1>
      <div class="row">
        <div class="col-md-6 mr-3 mt-3 ml-3">
          <div class="form-group">
            <label for="labFacility" class="form-label"
              >Name of the Space </label
            ><span class="text-danger">*</span>
            <input
              type="text"
              class="form-control"
              formControlName="spaceName"
              placeholder="Please enter Name of the Space"
            />
            <div
              *ngIf="
                coWorkingForm.get('spaceName').invalid &&
                coWorkingForm.get('spaceName').touched
              "
            >
              <div
                *ngIf="coWorkingForm.get('spaceName').errors.required"
                class="text-danger"
              >
              Name of the Space is required.
              </div>

              <div
                *ngIf="coWorkingForm.get('spaceName').errors.pattern"
                class="text-danger"
              >
                Valid Name of the Space is required.
              </div>

            </div>
          </div>

          <div class="form-group">
            <label for="labFacility" class="form-label">Type</label>
            <span class="text-danger">*</span>
            <select class="custom-select" formControlName="selectedType">
              <option value="">Please select Type</option>
              <option *ngFor="let type of coWorkingType" [ngValue]="type">
                {{ type }}
              </option>
            </select>

            <div
              *ngIf="
                coWorkingForm.get('selectedType').invalid &&
                coWorkingForm.get('selectedType').touched
              "
            >
              <div
                *ngIf="coWorkingForm.get('selectedType').errors.required"
                class="text-danger"
              >
                Type is required.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="labFacility" class="form-label"
              >Any Other Facility</label
            >
            <textarea
              class="form-control text_area_control"
              id="textArea"
              formControlName="otherFacility"
              placeholder="Please enter Any Other Facility"
              rows="8"
            ></textarea>
            <div
              *ngIf="
                coWorkingForm.get('otherFacility').invalid &&
                coWorkingForm.get('otherFacility').touched
              "
            >
              <div
                *ngIf="coWorkingForm.get('otherFacility').errors.required"
                class="text-danger"
              >
              Any Other Facility is required.
              </div>

              <div
                *ngIf="coWorkingForm.get('otherFacility').errors.pattern"
                class="text-danger"
              >
                Valid Any Other Facility is required.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="labFacility" class="form-label"
              >No. of People Accomodation </label
            ><span class="text-danger">*</span>
            <input
              type="number"
              class="form-control"
              formControlName="noOfPeople"
              placeholder="Please enter No. of People Accomodation"
            />
            <div
              *ngIf="
                coWorkingForm.get('noOfPeople').invalid &&
                coWorkingForm.get('noOfPeople').touched
              "
            >
              <div
                *ngIf="coWorkingForm.get('noOfPeople').errors.required"
                class="text-danger"
              >
                Accomodation is required.
              </div>

              <div
                *ngIf="coWorkingForm.get('noOfPeople').errors.pattern"
                class="text-danger"
              >
                Accomodation is Numeric.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="labFacility" class="form-label">Any Charges</label>
            <span class="text-danger">*</span>
            <select class="custom-select" formControlName="anycharges">
              <option selected value="">
                Please select Any Charges
              </option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>

            <div
              *ngIf="
                coWorkingForm.get('anycharges').invalid &&
                coWorkingForm.get('anycharges').touched
              "
            >
              <div
                *ngIf="coWorkingForm.get('anycharges').errors.required"
                class="text-danger"
              >
                Charges is required.
              </div>
            </div>
          </div>
        </div>
        <!-- end of col-lg-6-->
        <div class="col-sm-5 mt-3">
          <div>
            <label>Coworking Space Image 1</label
            ><span class="text-danger">*</span>
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="customFile"
                (change)="onFileSelect($event, 0)"
                accept=".jpg,.png,.jpeg"
                multiple
              />
              <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[0]" for="customFile"><a href="{{previewimageurl[0]}}" target="_blank">View Image</a></button>
              <label class="custom-file-label" for="customFile">{{
                images[0] || "Choose File"
              }}</label>
              <div
                *ngIf="
                  coWorkingForm.get('_img1').invalid &&
                  coWorkingForm.get('_img1').touched
                "
              >
                <div
                  *ngIf="coWorkingForm.get('_img1').errors.required"
                  class="text-danger"
                >
                  File is required.
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <label>Coworking Space Image 2</label
            ><!--<span class="text-danger">*</span>-->
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="customFile"
                (change)="onFileSelect($event, 1)"
                accept=".jpg,.png,.jpeg"
                multiple
              />
              <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[1]" for="customFile"><a href="{{previewimageurl[1]}}" target="_blank">View Image</a></button>
              <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[1]" (click)="clearImage(1)">Remove Image</button>
              <label class="custom-file-label" for="customFile">{{
                images[1] || "Choose File"
              }}</label>
              <div
                *ngIf="
                  coWorkingForm.get('_img2').invalid &&
                  coWorkingForm.get('_img2').touched
                "
              >
                <div
                  *ngIf="coWorkingForm.get('_img2').errors.required"
                  class="text-danger"
                >
                  File is required.
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <label>Coworking Space Image 3</label
            ><!--<span class="text-danger">*</span>-->
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="customFile"
                (change)="onFileSelect($event, 2)"
                accept=".jpg,.png,.jpeg"
                multiple
              />
              <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[2]" for="customFile"><a href="{{previewimageurl[2]}}" target="_blank">View Image</a></button>
            <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[2]" (click)="clearImage(2)">Remove Image</button>
              <label class="custom-file-label" for="customFile">{{
                images[2] || "Choose File"
              }}</label>
              <div
                *ngIf="
                  coWorkingForm.get('_img3').invalid &&
                  coWorkingForm.get('_img3').touched
                "
              >
                <div
                  *ngIf="coWorkingForm.get('_img3').errors.required"
                  class="text-danger"
                >
                  File is required.
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <label>Coworking Space Image 4</label
            ><!--<span class="text-danger">*</span>-->
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="customFile"
                (change)="onFileSelect($event, 3)"
                accept=".jpg,.png,.jpeg"
                multiple
              />
              <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[3]" for="customFile"><a href="{{previewimageurl[3]}}" target="_blank">View Image</a></button>
            <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[3]" (click)="clearImage(3)">Remove Image</button>
              <label class="custom-file-label" for="customFile">{{
                images[3] || "Choose File"
              }}</label>
              <div
                *ngIf="
                  coWorkingForm.get('_img4').invalid &&
                  coWorkingForm.get('_img4').touched
                "
              >
                <div
                  *ngIf="coWorkingForm.get('_img4').errors.required"
                  class="text-danger"
                >
                  File is required.
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <p class="text-danger">
              <span style="color: #444444">Note:</span> Please upload
              PNG/JPEG/JPG. Maximum size of the file is 2MB.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="form-group col-md-6">
            <fieldset class="border p-2">
              <legend class="float-none w-auto p-2">Incharge Details</legend>

              <div class="form-group">
                <label for="labFacility" class="form-label">Name </label
                ><span class="text-danger">*</span>
                <input
                  type="text"
                  class="form-control"
                  formControlName="inchargeName"
                  placeholder="Please enter Name"
                />
                <div
                  *ngIf="
                    coWorkingForm.get('inchargeName').invalid &&
                    coWorkingForm.get('inchargeName').touched
                  "
                >
                  <div
                    *ngIf="coWorkingForm.get('inchargeName').errors.required"
                    class="text-danger"
                  >
                    Name is required.
                  </div>

              <div
                *ngIf="coWorkingForm.get('inchargeName').errors.pattern"
                class="text-danger"
              >
                Valid Name is required.
              </div>


                </div>
              </div>

              <div class="form-group">
                <label for="labFacility" class="form-label"
                  >Contact Number </label
                ><span class="text-danger">*</span>
                <input
                  type="number"
                  class="form-control"
                  formControlName="inchargeContact"
                  placeholder="Please enter Contact Number"
                />
                <div
                  *ngIf="
                    coWorkingForm.get('inchargeContact').invalid &&
                    coWorkingForm.get('inchargeContact').touched
                  "
                >
                  <div
                    *ngIf="coWorkingForm.get('inchargeContact').errors.required"
                    class="text-danger"
                  >
                    Contact is required.
                  </div>
                  <div
                    *ngIf="coWorkingForm.get('inchargeContact').errors?.pattern"
                    class="text-danger"
                  >
                    Contact number is invalid
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="labFacility" class="form-label">Designation </label
                ><span class="text-danger">*</span>
                <input
                  type="text"
                  class="form-control"
                  formControlName="inchargeDesignation"
                  placeholder="Please enter Designation"
                />
                <div
                  *ngIf="
                    coWorkingForm.get('inchargeDesignation').invalid &&
                    coWorkingForm.get('inchargeDesignation').touched
                  "
                >
                  <div
                    *ngIf="
                      coWorkingForm.get('inchargeDesignation').errors.required
                    "
                    class="text-danger"
                  >
                    Designation is required.
                  </div>

              <div
                *ngIf="coWorkingForm.get('inchargeDesignation').errors.pattern"
                class="text-danger"
              >
                Valid Designation is required.
              </div>


                </div>
              </div>

              <div class="form-group">
                <label for="labFacility" class="form-label"
                  >Email Address </label
                ><span class="text-danger">*</span>
                <input
                  type="email"
                  class="form-control"
                  formControlName="inchargeEmail"
                  placeholder="Please enter Email Address"
                />
                <div
                  *ngIf="
                    coWorkingForm.get('inchargeEmail').invalid &&
                    coWorkingForm.get('inchargeEmail').touched
                  "
                >
                  <div
                    *ngIf="coWorkingForm.get('inchargeEmail').errors.required"
                    class="text-danger"
                  >
                    Email is required.
                  </div>
                  <div
                    *ngIf="coWorkingForm.get('inchargeEmail').errors.email"
                    class="text-danger"
                  >
                    Valid Email is required.
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="form-group col-md-6">
            <fieldset class="border p-2">
              <legend class="float-none w-auto p-2">Location</legend>

              <div class="form-group">
                <label for="labFacility" class="form-label">
                  Address</label>
                <span class="text-danger">*</span>
                <input
                  type="text"
                  class="form-control"
                  formControlName="addressLine1"
                  placeholder="Please enter Address"
                />
                <div
                  *ngIf="
                    coWorkingForm.get('addressLine1').invalid &&
                    coWorkingForm.get('addressLine1').touched
                  "
                >
                  <div
                    *ngIf="coWorkingForm.get('addressLine1').errors.required"
                    class="text-danger"
                  >
                    Address is required.
                  </div>

                <div
                  *ngIf="coWorkingForm.get('addressLine1').errors.pattern"
                  class="text-danger"
                >
                  Valid Address is required.
                </div>


                </div>
              </div>
              <div class="form-group">
                <label for="labFacility" class="form-label">City </label
                ><span class="text-danger">*</span>
                <input
                  type="text"
                  class="form-control"
                  formControlName="cityName"
                  placeholder="Please enter City"
                />
                <div
                  *ngIf="
                    coWorkingForm.get('cityName').invalid &&
                    coWorkingForm.get('cityName').touched
                  "
                >
                  <div
                    *ngIf="coWorkingForm.get('cityName').errors.required"
                    class="text-danger"
                  >
                    City is required.
                  </div>

               <div
                  *ngIf="coWorkingForm.get('cityName').errors.pattern"
                  class="text-danger"
                >
                  Valid City is required.
                </div>



                </div>
              </div>
              <div class="form-group">
                <label for="labFacility" class="form-label">State </label
                ><span class="text-danger">*</span>
                <select class="custom-select" formControlName="stateName">
                  <option selected value="">Please select State</option>
                  <option *ngFor="let states of arrstates" [ngValue]="states">
                    {{ states }}
                  </option>
                </select>

                <div
                  *ngIf="
                    coWorkingForm.get('stateName').invalid &&
                    coWorkingForm.get('stateName').touched
                  "
                >
                  <div
                    *ngIf="coWorkingForm.get('stateName').errors.required"
                    class="text-danger"
                  >
                    State is required.
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="labFacility" class="form-label">Zip Code </label
                ><span class="text-danger">*</span>
                <input
                  type="number"
                  class="form-control"
                  formControlName="zipCode"
                  placeholder="Please enter Zip Code"
                />
                <div
                  *ngIf="
                    coWorkingForm.get('zipCode').invalid &&
                    coWorkingForm.get('zipCode').touched
                  "
                >
                  <div
                    *ngIf="coWorkingForm.get('zipCode').errors.required"
                    class="text-danger"
                  >
                    Zip Code is required.
                  </div>
                  <div
                  *ngIf="coWorkingForm.get('zipCode').errors.pattern"
                  class="text-danger"
                >
                  Valid Zip Code is required.
                </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="form-group ml-3 mr-3 mt-2">
        <label for="labFacility" class="form-label">Instructions(If any)</label>
        <!-- <textarea
            class="form-control text_area_control"
            id="textArea"
            rows="8"
            formControlName="inchargeInstruction"
          ></textarea> -->
        <ckeditor
          [editor]="Editor"
          [config]="editorConfig"
          [config]="{ placeholder: 'Please enter Instructions(If any)' }"
          formControlName="inchargeInstruction"
          placeholder="Please enter Instructions(If any)"
          #myEditor
        ></ckeditor>
        <div
          *ngIf="
            coWorkingForm.get('inchargeInstruction').invalid &&
            coWorkingForm.get('inchargeInstruction').touched
          "
        >
          <div
            *ngIf="coWorkingForm.get('inchargeInstruction').errors.required"
            class="text-danger"
          >
            Instructions is required.
          </div>

            <div
                  *ngIf="coWorkingForm.get('inchargeInstruction').errors.pattern"
                  class="text-danger"
                >
                  Valid Instructions is required.
                </div>
        </div>
      </div>
      <div class="d-flex w-100 justify-content-center">
        <button
          class="btn custombtn mr-3 mb-3 mt-3"
          (click)="submitCoForm()"
          *ngIf="createMode"
        >
          Create
        </button>
        <button
          class="btn custombtn mr-3 mb-3 mt-3"
          (click)="updateCoworking()"
          *ngIf="editMode"
        >
          Update
        </button>
        <!-- <button class="btn custombtn mr-3 mb-3 mt-3" (click)="updateForms()" *ngIf="editMode">Update</button> -->
        <button class="btn custombtn mb-3 mt-3" (click)="cancel()">
          Cancel
        </button>
      </div>

      <!-- <div class="d-flex justify-content-center">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Save
          </button>
        </div> -->
    </form>
    <form [formGroup]="coWorkingFilterForm">

  <div class="d-flex mb-3"> 
    <div class="col-sm-4">
      <label class="mx-3">User Type</label>    
      <select  id="selectedUserType" (change)="onUserSelectionChange($event)" class="form-select usertypeselect" formControlName="selectedUserType"> <!--(change)="onUserSelectionChange($event)"-->
        <option value="ALL">Select User Type</option>
        <option value="INCUBATOR" selected >INCUBATOR</option>
         <option value="COE" >COE</option>
         <option value="ACCELERATOR">ACCELERATOR</option>
         <option value="ALL">SUPERADMIN</option>
     </select>     
     
  
  </div>
  <div class="col-sm-4">
    <label class="mx-3">User</label>    
  <ng-multiselect-dropdown 
              [placeholder]="''"
              [settings]="dropdownSettings"
              [data]="dropdownuser"
              [(ngModel)]="selectedUser"
              [ngModelOptions]="{standalone: true}"
              
            >
    </ng-multiselect-dropdown>
   

</div>
<div class="col-sm-4">
  <label class="mx-3">Status</label>    
<ng-multiselect-dropdown 
            [placeholder]="''"
            [settings]="dropdownSettingst"
            [data]="dropdownstatus"
            [(ngModel)]="selectedStatus"
            [ngModelOptions]="{standalone: true}"
            
          >
  </ng-multiselect-dropdown>
</div>
    </div>

    <div class="d-flex">
      <div class="col-sm-8">
        <div class="input_row">
          <div class="input_box">
            <label for="" class="mx-3">From Date</label>
            <input type="date" formControlName="start_date" id="start_date"
              class="form-control date"
             
            />
          </div>
         
        <div class="input_box">
            <label for="">To Date</label>
            <input type="date" formControlName="end_date" id="end_date"
              class="form-control date"/>
          </div>
        </div>
       
        
      </div>  
      <!-- <div class="d-flex mb-3 justify-content-end"> -->
        <div class="col-sm-4">
          <label class="mx-3">.</label><br>
            <button class="btn btn-primary mr-1" (click)="filterItem()" *ngIf="filterMode" id="filter"> Filter </button> 
             <!-- <button class="btn btn-primary mr-1" id="filter"> Filter </button> -->
            <button class="btn btn-secondary mr-1" (click)="resetFilters()" >Reset</button>
            <button class="btn btn-success mr-1" (click)="downloadlabList()"><i class="fas fa-download"></i> Download</button>       
        </div>    
      <!-- </div> -->
    </div>
    
  </form>

     <!-- filter count row -->
 <div class="filter-container">
     
  <div class="filter-row justify-content-center mt-3">
    <p (click)="countFilter('ALL')" [class.active]="activeFilter == null">ALL  <span> {{approvedCount + rejectedCount + pendingCount}}</span></p>
    <p (click)="countFilter('APPROVED')" [class.active]="activeFilter == 'APPROVED'">APPROVED  <span> {{approvedCount}}</span></p>
    <p (click)="countFilter('DECLINED')" [class.active]="activeFilter == 'DECLINED'">DECLINED  <span> {{rejectedCount}}</span></p>
    <p (click)="countFilter('PENDING')" [class.active]="activeFilter == 'PENDING'">PENDING  <span> {{pendingCount}}</span></p>
  </div>


</div>
<!-- filter count row -->



  <div class="" #section>
    <table class="bordered-table table">
      <thead class="thead-dark">
        <tr>
          <th scope="col" class="text-center">Sl No</th>
          <th>Updated Time</th>
          <th>Coworking Space Name Title</th>
          <th>No. of People</th>
          <th>Incharge Designation</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of list | paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index">
          <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th>
          <td>{{data.updated_at |  date}}</td>
          <td>{{ data.name }}</td>
          <td>{{ data.no_of_people }}</td>
          <td>{{ data.incharge_details.designation }}</td>
          <td>{{ data.status }}</td>
          <td class="d-flex">
            <div *ngIf="data.status === 'PENDING'" class="d-flex">
              <a
                class="dropbtn fa fa-check mr-2"
                style="color: blue"
                (click)="
                  changestatus(data.id, 'APPROVED', 'coworkingresource', data.creator)
                "
              ></a>
              <a
                class="dropbtn fa fa-close mr-2"
                style="color: red"
                (click)="
                  changestatus(data.id, 'DECLINED', 'coworkingresource', data.creator)
                "
              ></a>
            </div>
            <div *ngIf="data.status === 'APPROVED'">
              <a
                class="dropbtn fa fa-close mr-2 ml-4"
                style="color: red"
                (click)="
                  changestatus(data.id, 'DECLINED', 'coworkingresource', data.creator)
                "
              ></a>
            </div>
            <div *ngIf="data.status === 'DECLINED'">
              <a
                class="dropbtn fa fa-check mr-4"
                style="color: blue"
                (click)="
                  changestatus(data.id, 'APPROVED', 'coworkingresource', data.creator)
                "
              ></a>
            </div>

            <span
              class="fa fa-edit cursor-pointer"
              style="color: blue"
              (click)="editData(data, section1)"
            ></span>
            <!-- <span
              class="fa fa-trash ml-2 cursor-pointer"
              style="color: red"
              (click)="deleteData(data.id)"
            ></span> -->
            <a
              class="fa fa-eye ml-2 cursor-pointer"
              style="color: green"
              data-toggle="modal"
              data-target="#exampleModal"
              (click)="preview(data)"
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="text-center" *ngIf="list.length < 1">No data found</p>
    <pagination-controls *ngIf="paginationEnabled && list?.length > 0;" (pageChange)="p = $event" class="float-right"></pagination-controls>
  </div>

  <!-- Co-Working form-->
</div>

<!-- Modal -->
<div
  class="modal custom-modal"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content d-flex">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Preview</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <label for="basic-url">Name of the Space</label>
          <div class="input-group">
            <div class="input-group-prepend"></div>
            <input
              type="text"
              class="form-control mb-2"
              placeholder=""
              aria-label="Username"
              value="{{ modaldata.name }}"
              aria-describedby="basic-addon1"
              readonly
            />
          </div>
          <label for="basic-url">Coworking Images</label>
          <div class="d-flex justify-content-between align-items-center mb-2">
            
            <div  class="col" *ngFor = "let img of modaldata.img1">
              <img [src]="img" alt="image" class="rounded" width="150" height="150"  />
            </div>
            <!-- <div  class="col">
              <img *ngIf="modaldata.img2" [src]="modaldata.img2" alt="image" width="150" height="150" />
            </div>
            <div  class="col">
              <img *ngIf="modaldata.img3" [src]="modaldata.img3" alt="image" width="150" height="150" />
            </div>
            <div  class="col">
              <img *ngIf="modaldata.img4" [src]="modaldata.img4" alt="image" width="150" height="150" />
            </div> -->

          </div>

          <div class="mb-2">
            <label for="basic-url">Type</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.selectedType }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Any other Facility</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.otherFacility }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">No. of People Accomodation </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.noOfPeople }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Any Charges</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.anycharges }}"
              id="fileInput"
              readonly
            />
            <div>
              <h2>Incharge Details:</h2>
            </div>

            <label for="basic-url">Name </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.name }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Contact Number</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.phone }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Designation </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.designation }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Email Address</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.email }}"
              id="fileInput"
              readonly
            />

            <div>
              <h2>Location:</h2>
            </div>
            <label for="basic-url">Address</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.address_line1 }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">City</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.city }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">State </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.state }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">ZipCode</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.zip_code }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Instructions(If any) </label>
            <!-- <input
              class="form-control mb-2"
              value="{{ modaldata.inchargeInstruction }}"
              id="fileInput"
              readonly
            /> -->
            <textarea class="form-control" [innerHTML]="modaldata.inchargeInstruction" readonly></textarea>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
            >
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
