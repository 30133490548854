import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GrantUploadsService } from "../../../services/grant-uploads.service";
import { ActivatedRoute, Router } from "@angular/router";
import { GrantManagementService } from "../../../services/grant-management/grant-management.service";
import { title } from "process";
import { NbToastrService } from "@nebular/theme";

@Component({
  selector: "ngx-finance-uploads",
  templateUrl: "./finance-uploads.component.html",
  styleUrls: ["./finance-uploads.component.scss"],
})
export class FinanceUploadsComponent implements OnInit {
  isuploadFormSubmited: boolean = false;
   startdate:any;
   enddate:any;
  uploadForm: FormGroup;
  schemeId: any = 1;
  editMode: boolean = false;
  selectedUploadId: any = "";
  uploads: any[] = [];
  filetyperrr: boolean =false;
  filesizerrr:boolean=false;
  fileInputLabel: any;
  newUpload = false;
  editData: any;
  uploadedFile: any;
  p:number=1;
pageSize:number=10;
  entity: any;
  scheme_id:any;
  fileIsUploaded: boolean;
  uploadData: any;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private grantUploadService: GrantUploadsService,
    private router:Router,private grantManagementService:GrantManagementService,
    private toasterService:NbToastrService, private _route:ActivatedRoute
  ) {
    this._route.paramMap.subscribe((params:any)=>{
      this.scheme_id = params.params.id;
      this.grantManagementService.scheme_id = this.scheme_id
    })
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.schemeId = params["id"];
    });
    this.uploadForm = this.fb.group({
      title: ["", [Validators.required, Validators.maxLength(20)]],
      file: ["", [Validators.required]],
      description: ["", [Validators.maxLength(500)]],
    });
    this.grantManagementService.getEntityType().subscribe((res: any) => {
      this.entity = res;
      console.log('entity------', this.entity);
    
  });
  this.grantManagementService.getStartDate().subscribe((res:any)=>{
    this.startdate = res;
    console.log('startdate------',this.startdate)
    console.log('here1');
    //this.getFunds();
    
})

this.grantManagementService.getEndDate().subscribe((res: any) => {
    this.enddate = res;
    console.log('end------', this.enddate);
    console.log('here2');
    this.getGrantUploads();
});
this._route.queryParams.subscribe((params) => {
  this.p = +params['page'] || 1;
  this.loadData(this.p);

})
  }

  // onFileSelect(event) {
  //   this.newUpload = true;
  //   let allowedTypes= ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
  //   const file = event.target.files[0];
  //   this.uploadedFile = event.target.files[0];
  //   const filesize = file.size;
  //   const filesizemb = filesize / 1024 ** 2;
  //   if (filesizemb > 2) {
  //     this.filesizerrr = true;
  //   }
  //   else{
  //     this.filesizerrr = false;
  //   }
  //   if(!allowedTypes.includes(file.type)){
  //     this.filetyperrr = true;
  //   }
  //   else{
  //     this.filetyperrr=false
  //   }
  //   // const formData = new FormData();
  //   this.fileInputLabel = file ? file.name : 'Choose File';

  //   // formData.append("file", file);
  //   console.log('onfileselect', this.filesizerrr, this.filetyperrr);
  //   console.log(this.uploadedFile);
    
  // }

  // async uploadDoc(){
  //   console.log('running uploadDoc')
  //   console.log(this.uploadedFile);
  //   if(this.newUpload){
  //     console.log(this.newUpload)
  //     const formData = new FormData();
  //     formData.append("file", this.uploadedFile);
  //     await this.grantManagementService.uploadFile(formData).subscribe((response: any) => {
  //       console.log(response.results[0])
  //       this.uploadForm.get("file").setValue(response.results[0]);
  //       this.fileIsUploaded = true;
  //     })
  //   }
  //   else{
  //     console.log(this.newUpload)
  //     this.uploadForm.get("file").setValue(this.editData?.file.url)

  //     this.fileIsUploaded = true;
  //   }

  //   await console.log('Finished uploading Doc');

  //   // this.delayedFunction();
  // }

  // onFileSelect(event:any){
  //     let allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  //     const file = event.target.files[0];
  //     const fileSize = file.size;
  //     const fileSizeMB = fileSize / (1024 ** 2);
  //     console.log('filesize in mb', fileSizeMB);
  //     console.log('file', file);
  
  //     if (!allowedTypes.includes(file.type)) {
  //         this.filetyperrr = true; // File type not allowed
  //     } else {
  //         this.filetyperrr = false; // File type allowed
  //     }
  
  //     if (fileSizeMB > 2) {
  //         this.filesizerrr = true; // File size exceeds 2MB
  //     } else {
  //         this.filesizerrr = false; // File size within limit
  //     }
  
  //     this.uploadForm.get("file").setValue(file);
  // }


  get _title() {
    return this.uploadForm.get("title");
  }
  get _file() {
    return this.uploadForm.get("file");
  }
  get _description() {
    return this.uploadForm.get("description");
  }
  getGrantUploads() {
    this.grantManagementService.getUploads(this.entity,this.startdate,this.enddate).subscribe((res: any) => {
      this.uploads = res;
    });
  }

  editUpload(upload: any) {
    this.editData = upload;
    console.log(this.editData)
    this.uploadForm.patchValue({
      title: upload.title,
      description: upload.description,
    });
    this.fileInputLabel = upload.file.path ? upload.file.path.split('/')[1] : upload.file.split('/')[4];
    this.editMode = true;
    this.selectedUploadId = upload.id;
  }

  deleteUpload(id: any) {
    this.grantManagementService.deleteUpload(id,this.entity).subscribe(() => {
      this.getGrantUploads();
      this.toasterService.danger('Record deleted Successfully')

    });
  }

  // async submitUpload() {
  //   // this.isuploadFormSubmited = true;
  //   console.log(this.uploadForm);

  //    await this.uploadDoc();
  //   // console.log('111', uploadedImage)

  //   // if ((!this.filetyperrr && !this.filesizerrr) && this.uploadForm.valid) {
  //     if(this.fileIsUploaded = true){
  //       await this.delayedFunction();
  //     }
  //     // .then(() => {
        
      // })
    
    // } else {
    //   console.log(this.filetyperrr, this.filesizerrr, this.uploadForm)
    //   console.log("ffsdfsdgfdgfd");
    //   this.uploadForm.markAllAsTouched();
    // }


    // await console.log(this.uploadForm);

  // }

//   delayedFunction(){
    

//     this.isuploadFormSubmited = true;

//       console.log(this.filetyperrr, this.filesizerrr, this.uploadForm)
// console.log('Uploading')
//       let payload = {
//         title: this.uploadForm.get("title").value,
//         description: this.uploadForm.get("description").value,
//         file: this.uploadForm.get("file").value,
//         scheme_id: this.schemeId,
//       };
//       console.log(payload)
//       if (this.editMode && this.selectedUploadId) {
//         this.grantManagementService
//           .updateUpload(this.selectedUploadId, payload,this.entity)
//           .subscribe(() => {
//             console.log('Successfully updated')
//             this.getGrantUploads();
//             this.uploadForm.reset();
//             this.selectedUploadId = "";
//             this.fileInputLabel = ""
            
//             this.editMode = false;
//           });
//       } else {
//         this.grantManagementService.createUpload(payload,this.entity).subscribe(() => {
//           this.getGrantUploads();
//         });
//       }
      
//       this.uploadForm.reset();
//       this.fileInputLabel = "";
//       this.isuploadFormSubmited=false;
    
//   }

  // New functions!!! -------------------------------

  onFileSelect2(event) {
    this.newUpload = true;
    const file = event.target.files[0];
    this.uploadedFile = event.target.files[0];
    let allowedTypes= ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
    const filesize = file.size;
    const filesizemb = filesize / 1024 ** 2;
    if (filesizemb > 2) {
      this.filesizerrr = true;
    }
    else{
      this.filesizerrr = false;
    }
    if(!allowedTypes.includes(file.type)){
      this.filetyperrr = true;
    }
    else{
      this.filetyperrr=false
    }
    this.fileInputLabel = file ? file.name : '';

    console.log('onfileselect', this.filesizerrr, this.filetyperrr);
    console.log(this.uploadedFile);    
  }

  async uploadDoc2(){
    console.log('running uploadDoc2')
    console.log(this.uploadedFile);

    if(this.newUpload){
      console.log('newUpload is ', this.newUpload)
      const formData = new FormData();
      formData.append("file", this.uploadedFile);

      try{
        const response: any =
        await this.grantManagementService.uploadFile(formData).toPromise();
          console.log(response.results[0])
          this.uploadForm.get("file").setValue(response.results[0]);
          await this.delayedFunction2();
      }
      catch(error){
        console.log(error)
      }      
    }
    else{
      console.log('newUpload is ', this.newUpload); 
      this.fileIsUploaded = false;
      await this.uploadForm.get("file").setValue(this.editData?.file.url? this.editData?.file.url : this.editData?.file);
    }

    await console.log('Finished uploading Doc');
  }

  delayedFunction2(){
    this.isuploadFormSubmited = true;
    console.log(this.filetyperrr, this.filesizerrr, this.uploadForm);
    console.log('The form is being uploaded!');

    let payload = {
      title: this.uploadForm.get("title").value,
      description: this.uploadForm.get("description").value,
      file: this.uploadForm.get("file").value,
      scheme_id: this.schemeId
    }
    console.log('The payloadform upload is ', payload);

    if(this.editMode){
      this.grantManagementService.updateUpload(this.selectedUploadId, payload, this.entity).subscribe((response: any) => {
        console.log('Upload has been updated succesfully!');
        this.formClear();
        this.toasterService.success('Updated  Successfully')

      }, 
    (error: any) =>{
      this.toasterService.danger('Something went wrong')

    });
    }
    else{
      this.grantManagementService.createUpload(payload, this.entity).subscribe((response: any) => {
        console.log('Upload created succesfully', response);
        this.formClear();
        this.toasterService.success('Form Submitted Successfully')
      },
      (error: any) =>{
        this.toasterService.danger('Something went wrong')
  
      });
    }
  }

  async submitUpload2(){
    console.log('Submitted form', this.uploadForm);

    await this.uploadDoc2();

    if(this.fileIsUploaded == false){
      console.log('file not uploaded',this.fileIsUploaded)
      this.delayedFunction2();
    }
  }

  formClear(){
    console.log('clearing form!');
    this.newUpload = false;
    this.uploadedFile = '';
    this.filesizerrr = false;
    this.filetyperrr = false;
    this.fileInputLabel = '';
    this.fileIsUploaded = null;
    this.editMode = false;
    this.uploadForm.get("file").setValue('');
    this.isuploadFormSubmited = false;
    this.selectedUploadId = ''
    this.uploadForm.reset();
    this.getGrantUploads();
  }

  viewUpload(data: any){
    console.log('data', data)
    if(data != null){
      this.uploadData = data;
    }

  }
  //pagination
onPageChange(page:number):void{
  this.p =page,
  this.router.navigate([],{
    relativeTo:this._route,
    queryParams:{page:page},
    queryParamsHandling:'merge'
  })
  this.loadData(page)
}
private loadData(page:number):void{
  console.log(page)
}

cancelEdit(){
  this.editMode =false;
    this.uploadForm.reset();
}
}
