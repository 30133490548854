import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { MentorService } from '../../../services/mentor.service';
import { Location } from '@angular/common';

@Component({
  selector: 'ngx-individualmentor',
  templateUrl: './individualmentor.component.html',
  styleUrls: ['./individualmentor.component.scss']
})
export class IndividualmentorComponent implements OnInit {

  mentorid:string;
  mentordata:any={};
  Object = Object;

  constructor(private _route:ActivatedRoute, private mentorService:MentorService,private router: Router,private location: Location) { }

  ngOnInit(): void {
    this._route.params.subscribe((params)=>{
      this.mentorid = params['id'];
    })

    this.getMentor();
  }

  getMentor(){
    this.mentorService.getIndividualMentor(this.mentorid).subscribe((res)=>{
      var data = {...res.results};
      delete data['form_schema'];
      var mentor = {data,...res.results.form_schema.step2,...res.results.form_schema.step3,...res.results.form_schema.step4};
      this.mentordata = {...mentor.data,...res.results.form_schema.step2,...res.results.form_schema.step3,...res.results.form_schema.step4}
    })
  }

  removeKeys(obj, keysToRemove) {
    keysToRemove.forEach(key => delete obj[key]);
}

formatUrl(url:any)
{
  const invalid = ['',null,undefined]
  if(!invalid.includes(url)){
    var httpString = "http://";
    var httpsString = "https://";
    if (url.substr(0, httpString.length).toLowerCase() !== httpString && url.substr(0, httpsString.length).toLowerCase() !== httpsString)
                url = httpString + url;
      return url;
  }else{
    return null
  }
    // return url;
}

isEmptyValue(value: any): boolean {
  return !value || (Array.isArray(value) && value.length === 0);
}

handleRoute(){
 this.location.back();
 localStorage.setItem('viewback','true')
}

mentorlink(url){
  var httpString = "http://";
    var httpsString = "https://";
    if (url.substr(0, httpString.length).toLowerCase() !== httpString && url.substr(0, httpsString.length).toLowerCase() !== httpsString)
                url = httpString + url;
                console.log("url",url)

    return url;
}

}
