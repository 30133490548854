<div class="container bg-white p-4">
    <h2 class="text-center eir-grant">Utilized Under Scale-up Investment​</h2>
    <table class="table table-striped   text-center">
        <thead>
            <tr>
                <th scope="col">S.N</th>
                <th scope="col">Start-up Name​</th>
                <th scope="col">Recommended Funding​​</th>
                <th scope="col">Support Cost​</th>
                <th scope="col">Total Utilized​</th>

           
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Data 1</td>
                <td>This list is downloadable</td>
                <td>Data 3</td>
                <td>Data 3</td>
                <td>Data 3</td>
                
            </tr>
            <tr>
                <td>Data 1</td>
                <td>Data 2</td>
                <td>Data 3</td>
                <td>Data 3</td>
               
                
                <td>Data 3</td>
            </tr>
            <!-- Add more rows as needed -->
        </tbody>
    </table>
</div>
