import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { IncubatorService } from '../../services/incubator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { StartupService } from '../../services/startup.service';

export let SELECT_ITEMS = [
  { value: 'G1', label: 'G1' },
  { value: 'G2', label: 'G2' },
  { value: 'G3', label: 'G3' },
];

@Component({
  selector: 'ngx-individualincubator',
  templateUrl: './individualincubator.component.html',
  styleUrls: ['./individualincubator.component.scss']
})
export class IndividualincubatorComponent implements OnInit {
  types = SELECT_ITEMS;
  selectscheme:any=[];
  dropdownSettings_schemes: IDropdownSettings={};
  selectedScheme:any[];
  dropdownscheme:any[]=['TIDE 2.0', 'SAMRIDH'];
  originalData:any[]=[]
  id: string;
  data: any;
  table: any;
  incubatorForm: FormGroup;
  options: any[] = [];
  sid: any;
  inValidMessage: boolean;
  totalItems: number;
  incId: any;
  dataList: any;
  // startupForm: FormGroup;
  // startupList: any;
  // startId: any;
  // incubId: any;
  showGroupS:boolean=false;
  showGroupT:boolean=false;
  selectedIncubator =[];
  dropdownIncubator = [];
  dropdownSettings: IDropdownSettings={};
  selectIncubator:any;


  constructor(
    private http: HttpClient,
    private incubatorService: IncubatorService,
    private _route: ActivatedRoute,
    private router:Router,
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private startupService: StartupService,


  ) {
    //  this.id = this._route.snapshot.paramMap.get("id");
    //  console.log(this.id); 
    }

  ngOnInit(): void {
    this.incubatorForm = this.fb.group({
      incubatorName: ['',[Validators.required]],
      schemes:['',[Validators.required]],
      group:['',[Validators.required]],
      schemesT:[''],
    
    })

    this.dropdownIncubator=[
      // { item_id: 1, item_text: 'Agriculture' },
      // { item_id: 2, item_text: 'IT' },
      // { item_id: 3, item_text: 'Soft' },
    ];

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };

    this.getAllSchemes();
    this.listAllIncubators();
    this.selectedIncubator = [];

  }

  getAllSchemes(){
    this.incubatorService.getschemeList()
      .subscribe((res:any)=>{
        this.options= res.results;
        console.log("SchemeLIST:", this.options);
      })
  }
   listAllIncubators(){
    let data={
      search_entity: "INCUBATOR",
      "page": 0, "page_size": 5000
    }
    this.incubatorService.getIncubatorsList(data)
      .subscribe((res:any)=>{
        this.originalData =res.results;
        console.log('original data 1234',this.originalData);
        for(var i = 0 ; i < this.originalData.length ; i++){
          this.dropdownIncubator.push({
            item_id:this.originalData[i].id , item_text:this.originalData[i].name
          })
        }
        this.dropdownIncubator = [...this.dropdownIncubator];
        console.log('dropdown1234',this.dropdownIncubator);
        this.totalItems = +res.total_search_results_count;
      },(err)=>{
        console.log('err',err);
      })
  }
  schemeval(event:any){
    console.log('event____scheme',event.target.value)
     this.sid= event.target.value;
  }
  incubatobyId(event:any){
    console.log('event____Incubator',event)
    this.incId= event;
    console.log('inc_id',this.incId);
  }
  submitDetail(){
    this.incubatorForm.markAllAsTouched();  // calling mark as touch every time.
    if (this.incubatorForm.invalid) {
      // Display error messages or perform other actions
      
      return;
    }
    console.log("form submited successfully")
  //  return
    console.log("sID:", this.sid);
    console.log("incu form::",this.incubatorForm);
       let data=  {
          "schemes": [
              {
                  "id": this.sid,
                  "group": this.incubatorForm.controls.group.value
              }
          ]
      }
    
    this.incubatorService.updateScheme(data, this.incId)
      .subscribe((response:any)=>{
        this.dataList= response;
        this.listAllIncubators();
        console.log("incubtordetail__:", response);
        this.incubatorForm.reset();
        this.toastrService.success("Schemes Updated Successfully!");
      },
        (error) => {
        this.inValidMessage = true;
        this.toastrService.warning("Unable to Update Schemes!");
      })
  }


    showGroupTide(){
    this.showGroupT =! this.showGroupT;
  }
  showGroupScheme(){
    this.showGroupS =! this.showGroupS;
  }
  cancel(){
    this.incubatorForm.reset();
  }
  
  gotoincubator(){
    this.router.navigate(['/admin/incubator']);
  }
 
}
