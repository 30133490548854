
<div class="container ">
  <!-- <ngx-gia-utilized-table></ngx-gia-utilized-table> -->
</div>
<section id="investor_section">
    <div class="container">
        <div class="whitebg my-3 p-4 ">
            <h2 class="text-center">Notifications</h2>
            <form [formGroup]="notificationForm">
                <div class="row text-center py-3">
                    <!-- <div class="col-12 col-md-12">
                        <a href="">Regarding submission of UCs for FY: 2023-24 Dated: </a>
                    </div> -->
                </div>
                <div class="row  p-3 my-4 d-flex justify-content-center">
                  <!-- <div class="col-12 d-flex justify-content-center">
                    <mat-button-toggle-group [value]="linkTextToggle"  class="newone" name="fontStyle" aria-label="Font Style">
                      <mat-button-toggle class="text" value="text" (click)="setValidator('text')">Text</mat-button-toggle>
                      <mat-button-toggle class="link" value="link" (click)="setValidator('link')" >Link</mat-button-toggle>
                    </mat-button-toggle-group>
                  </div> -->
                    <div class="col-12 col-md-5">
                      <label for="">Subject <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" placeholder="Subject" formControlName="subject">
                    
                        <div
                        *ngIf="
                          (isnotifyFormSubmited && _subject.invalid) ||
                          (_subject?.touched && _subject?.invalid)
                        "
                        class="text-danger"
                      >
                        <div *ngIf="notificationForm.get('subject').errors?.required">
                          <p class="text-danger">Subject is mandatory</p>
                        </div>
                        <div *ngIf="notificationForm.get('subject').errors?.maxlength">
                          <p class="text-danger">Subject should not exceed 50 characters</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <!-- <br> -->
                      <!-- *ngIf="linkTextToggle=='text'" -->
                      
                        <label for="">Text </label>
                        <input type="text" class="form-control" placeholder="Text" formControlName="text">
                        <div
                        *ngIf="
                          (isnotifyFormSubmited && _text.invalid) ||
                          (_text?.touched && _text?.invalid)
                        "
                        class="text-danger"
                      >
                          <div *ngIf="notificationForm.get('text').errors?.maxlength">
                          <p class="text-danger">text should not exceed 250 characters </p>
                        </div>
                      </div>
                      
                      </div>
                      </div>
                      
                      <div class="row p-3 my-4 d-flex justify-content-center">
                        <div class="col-12 col-md-5">
                                                  <!-- *ngIf="linkTextToggle=='link'"  -->
                        <label for="">Link</label>
                        <input type="text" class="form-control" placeholder="Link" formControlName="link">
                        <div
                        *ngIf="
                          (isnotifyFormSubmited && _link.invalid) ||
                          (_link?.touched && _link?.invalid)
                        "
                        class="text-danger"
                      >
                        <!-- <div *ngIf="notificationForm.get('link').errors?.required">
                          <p class="text-danger">Link is mandatory</p>
                        </div> -->
                        <div *ngIf="_link.errors.pattern">
                            <p class="text-danger">Invalid link </p>
                          </div>
                      </div>
                      </div>
                    

                    <!-- Description -->
                    <div class="col-12 col-md-5">
                      <label for="">Description</label>
                      <textarea class="form-control" placeholder="description" formControlName="description"></textarea>
                      <div
                      *ngIf="
                        (isnotifyFormSubmited && _description.invalid) ||
                        (_description?.touched && _description?.invalid)
                      "
                      class="text-danger"
                    >
                      <!-- <div *ngIf="notificationForm.get('description').errors?.required">
                        <p class="text-danger">Description is mandatory</p>
                      </div> -->
                      <div *ngIf="notificationForm.get('description').errors?.maxlength">
                        <p class="text-danger">Description should not exceed 500 characters</p>
                        
                      </div>
                    </div>
                    
                    </div>
                    
                </div>
               
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary text-center" (click)="submitNotification()">{{ editMode ? 'Update' : 'Submit' }}</button>                   
                    <button type="canel" class="mx-2 btn btn-secondary cancelEditBtn " *ngIf="editMode" (click)="cancelEdit()">Cancel</button>

                  </div>
            </form>

            <div class="table-container p-4 my-4">
                <table class="table table-striped text-center">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Subject</th>
                            <th scope="col">Text</th>
                            <th scope="col">Link</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let notification of notifications|paginate: { itemsPerPage: pageSize, currentPage: p }; let index=index">
                            <tr>
                                <th scope="row">{{ index+1 +(p-1)*pageSize }}</th>
                                <td class="textwrap">{{ notification?.subject }}</td>
                                <td class="textwrap">{{ notification?.text }}</td>
                                <td><a [href]="notification?.link" target="_blank">{{notification?.link}}</a></td>
                                <td class="d-flex justify-content-center">
                                    <i class="fa fa-eye arrow px-2" data-bs-toggle="modal" data-bs-target="#viewNotifModal" (click)="viewNotification(notification)"></i>
                                    <i class="fa fa-edit arrow px-2" (click)="editNotification(notification)"></i>
                                    <i class="fa fa-trash arrow px-2" (click)="deleteNotification(notification.id)"></i>
                                </td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>
                <pagination-controls *ngIf="notifications.length>0" (pageChange)="onPageChange($event)" directionLinks="true"  class="float-right"></pagination-controls>

            </div>
        </div>
    </div>

    <!-- View Notification Modal -->
<div class="modal fade" id="viewNotifModal" tabindex="-1" aria-labelledby="viewNotifModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="viewNotifModalLabel">Notification Details:</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times arrow px-2"></i>
        </button>
      </div>
      <div class="modal-body">
        <b>Subject:</b>
        <p class="text-break">{{ this.selectNotif?.subject }}</p>
        <b *ngIf="this.selectNotif?.text">Notification Text:</b>
        <p class="text-break" *ngIf="this.selectNotif?.text">{{ this.selectNotif?.text }}</p>
        <b *ngIf="this.selectNotif?.link">Notification Link:</b>
        <br *ngIf="this.selectNotif?.link">
        <a *ngIf="this.selectNotif?.link" [href]="this.selectNotif?.link" target="_blank" class="text-underline text-primary">{{ this.selectNotif?.link }}</a>
        <br *ngIf="this.selectNotif?.link"><br *ngIf="this.selectNotif?.link">
        <b>Description:</b>
        <p class="text-break">{{ this.selectNotif?.description }}</p>
        <b>Created at:</b>
        <p>{{ this.selectNotif?.created_at.substring(0, 10) }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
</section>