import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from "@nebular/auth";
import { RegisterComponent } from "./auth/register/register.component";
import { LoginComponent } from "./auth/login/login.component";
import { AuthGuardService } from "./services/auth-guard.service";
import { ForgotComponent } from "./auth/forgot/forgot.component";
import { ResetpasswordComponent } from "./auth/resetpassword/resetpassword.component";
import { EvaluatorloginComponent } from "./auth/evaluatorlogin/evaluatorlogin.component";
import { EvaluatorchangepasswordComponent } from "./auth/evaluatorchangepassword/evaluatorchangepassword.component";

export const routes: Routes = [
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuardService],
  },
  {
    path: "auth",
    component: NbAuthComponent,
    children: [
      {
        path: "",
        component: LoginComponent,
        // canActivate: [AuthGuardService],
      },
      {
        path: "login",
        component: LoginComponent,
        // canActivate: [AuthGuardService],
      },
      {
        path: "register",
        component: RegisterComponent,
        // canActivate: [AuthGuardService],
      },
      {
        path: "request-password",
        component: ForgotComponent,
      },
      {
        path: "reset-password",
        component: ForgotComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "resetpassword",
        component: ResetpasswordComponent
      },
      {
        path: "evaluator",
        component: EvaluatorchangepasswordComponent
      },
      {
        path: "logout",
        component: NbLogoutComponent,
      },
    ],
  },
  { path: "", redirectTo: "auth", pathMatch: "full" },
  { path: "**", redirectTo: "admin" },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
