import { HttpClient, HttpErrorResponse , HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AxiosServicesService } from "./axios/axios-services.service";
import { HttpClientService } from "./axios/base-http-client-axios.service";

const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Authorization', `Bearer ${sessionStorage.getItem('admintoken')}`);

@Injectable({
  providedIn: "root",
})
export class PermissionService {
  //baseUrl = "http://43.205.193.210:8593";
  baseUrl = environment.apiurl;

  constructor(
    private http: HttpClient,
    private axiosServices: AxiosServicesService
  ) {}

  getAllPermission() {
    let url = this.baseUrl + "permission/getall";
    let result: any = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
    return result;
  }

  addPermission(permissionData: any) {
    let url = this.baseUrl + "permission/add";
    let Param = {
      module: permissionData.value.name,
      conditions: permissionData.value.menus,
      description: permissionData.value.description,
    };
    let result: any = this.http
      .post(url, Param,{'headers':headers})
      .pipe(catchError(this.handleError));
    return result;
  }

  editPermission(currentData, permission_id) {
    let url = this.baseUrl + "permission/update";
    console.log("data::", currentData);
    let param = {
      permission_id: permission_id,
      description: currentData.description,
      module: currentData.module,
    };
    let result: any = this.http
      .put(url, param)
      .pipe(catchError(this.handleError));
    return result;
  }

  deleteRow(id: number) {
    let url = this.baseUrl + "permission/delete/" + id;
    let param = {
      deleted_by_id: 1,
    };
    let result: any = this.http
      .delete(url, { body: param })
      .pipe(catchError(this.handleError));

    return result;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
