import { HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup , Validators} from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
import { AxiosServicesService } from '../../services/axios/axios-services.service';
import { HttpClient } from '@angular/common/http';
import { ConfirmPasswordValidator } from '../customvalidator';
import { ConfirmedValidator } from '../../confirmed.validator';

declare var $:any;


const headers= new HttpHeaders()
.set('Authorization', `Bearer ${sessionStorage.getItem('admintoken')}`);

@Component({
  selector: 'ngx-evaluatorchangepassword',
  templateUrl: './evaluatorchangepassword.component.html',
  styleUrls: ['./evaluatorchangepassword.component.scss']
})
export class EvaluatorchangepasswordComponent implements OnInit {

  echangepasswordform:FormGroup;
  token:any;
  @ViewChild("UploadFileInput", { static: false }) uploadFileInput: ElementRef;
  fileInputLabel: any;
  code: any;
  nomatch = false;
  
  constructor(private fb:FormBuilder, private router:Router , private _route:ActivatedRoute, private auth:AxiosServicesService, private http:HttpClient) { 
    this._route.queryParams.subscribe((params)=>{
      this.token = params['token'];
      console.log('tokenfromurl',this.token);
    })
  }

  submitted = false;

  ngOnInit(): void {
    this.getevaluatorstatus();
    this.echangepasswordform = this.fb.group({
      firstname : ['',Validators.required],
      lastname: ['',Validators.required],
      email: ['',[Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['',[Validators.required,Validators.minLength(6)]],
      cpassword: ['',Validators.required],
      uploadedImage: ['']
    },
    {
      Validator: ConfirmPasswordValidator("password","confirmpassword")
    }
    )
  }

  onFileSelect(event) {
    console.log('file is here');
    const file = event.target.files[0];
    console.log('file',file);
    this.fileInputLabel = file.name;
    this.echangepasswordform.get("uploadedImage").setValue(file);
  }

  getevaluatorstatus(){
    this.auth.getevaluatorstatus(this.token).subscribe((res:any)=>{
      console.log('evaluatorstatus',res);
      this.code = res.code;
      if(res.status == 'PENDING'){
        this.echangepasswordform.controls.firstname.setValue(res.first_name);
        this.echangepasswordform.controls.lastname.setValue(res.last_name);
        this.echangepasswordform.controls.email.setValue(res.email);
      }

      else{
        this.router.navigate(['/']);
      }
      
    })
  }

  get f(){
    return this.echangepasswordform.controls;
  }
  

  submit(){
    this.submitted = true;
    if(!this.echangepasswordform.valid){
      console.log('invalid');
    }
    else{
      if($('#password').val()!=$('#confirmpassword').val()){
        this.nomatch = true
      }
      else{
        this.nomatch = false
        const formdata = new FormData();
        //formdata.append('file',this.echangepasswordform.get('uploadedImage').value);
        // this.http.post<any>("http://10.0.53.134:8090/api/files?intent=user-profile-image",formdata,{'headers':headers}).subscribe((response)=>{
  
    
        // })
        let data = {
          "email":this.echangepasswordform.controls.email.value,
          "code":this.code,
          "password":this.echangepasswordform.controls.password.value,
          "intent": "EVALUATOR-RESET"
        }
        this.auth.resetpasswordwithcode(data).subscribe((res)=>{
          console.log('res_pass',res);
          this.echangepasswordform.reset;
          this.submitted = false;
          this.router.navigate(['/']);
        })
      }

    }
  }

}
