<div class="container newselect ">
    <!-- <div class="row newdropd0wn"> -->
  <div class="col-12 col-md-3">
    <select class="form-select form-control entitypes" (change)="entityData($event)"  [(ngModel)]="selectedEntityType">
      <option *ngFor="let item of entity_types" [value]="item" >{{item}}</option>
      </select>
  </div>
<div class="col-12 col-md-3 newdropd0wn">
    <select class="form-select form-control yeardata" (change)="newdataadd($event)">
      <option *ngFor="let item of newfinancialYear" [value]="item.id" [selected]="item.range[0]==defaultval">{{item.range[0]}}</option>
      </select>
  </div>
   <div class="">
    <button type="button" class="btn btn-primary" (click)="postfinancialYear()">Add Financial Year</button>
  </div>
  </div>
<!-- </div> -->
  <!-- <div class="container whitebg">
    <nav class="navbar navbar-expand-lg navbar-light justify-content-between">
           <div class="container-fluid">
               <div class="collapse navbar-collapse" id="navbarNavDropdown">
                   <ul class="navbar-nav">
                       <li class="nav-item dropdown ">
                           <a class="nav-link" href="#" (click)="setCurrentTab('home')" [routerLink]="['/admin/grant-management',this.scheme_id,'home' ]">
                               Home
                           </a>
                       </li>
                       <li class="nav-item">
                           <a class="nav-link" href="#" (click)="setCurrentTab('startuptable')" [routerLink]="['/admin/grant-management',this.scheme_id,'startuptable']">
                               Startups
                           </a>
                       </li>
                       <li class="nav-item">
                           <a class="nav-link" href="#" (click)="setCurrentTab('team')" [routerLink]="['/admin/grant-management',this.scheme_id,'team']">
                               Team {{schemeName}}
                           
                           </a>
                       </li>
                       <li class="nav-item">
                           <a class="nav-link" href="#"  (click)="setCurrentTab('released')" [routerLink]="['/admin/grant-management',this.scheme_id,'released']">
                               GIA Released
                           </a>
                       </li>
                       <li class="nav-item">
                           <a class="nav-link" href="#" (click)="setCurrentTab('Eir-grant')"  [routerLink]="['/admin/grant-management',this.scheme_id,'Eir-grant']">
                               EIR Grant Release
                           </a>
                       </li>
                       <li class="nav-item">
                           <a class="nav-link" href="#"  (click)="setCurrentTab('release-scaleup')" [routerLink]="['/admin/grant-management',this.scheme_id,'release-scaleup']">
                               Scale-Up Release
                           </a>
                       </li>
                       <li class="nav-item">
                           <a class="nav-link" href="#"  (click)="setCurrentTab('notifications')" [routerLink]="['/admin/grant-management',this.scheme_id,'notifications']">
                               Notifications
                           </a>
                       </li>
                       <li class="nav-item">
                           <a class="nav-link" href="#"  (click)="setCurrentTab('uploads')" [routerLink]="['/admin/grant-management',this.scheme_id,'uploads']">
                               Uploads
                           </a>
                       </li>
                       <li class="nav-item dropdown">
                           <a class="nav-link dropdown-toggle" id="navbarDropdown7" role="button" aria-expanded="false">
                               Financial data
                           </a>
                           <ul class="dropdown-menu" aria-labelledby="navbarDropdown7">
                               <li><a class="dropdown-item" (click)="setCurrentTab('state-fund')"  [routerLink]="['/admin/grant-management', this.scheme_id, 'state-fund']" >State wise Funds</a></li>
                               <li><a class="dropdown-item" (click)="setCurrentTab('statewise-agency')"  [routerLink]="['/admin/grant-management', this.scheme_id, 'statewise-agency']">State wise Agency</a></li>
                               <li><a class="dropdown-item" (click)="setCurrentTab('statewise-startup')" [routerLink]="['/admin/grant-management', this.scheme_id, 'statewise-startup']">State wise Startup-list</a></li>
                               <li><a class="dropdown-item" (click)="setCurrentTab('quarter-fund')" [routerLink]="['/admin/grant-management', this.scheme_id, 'quarter-fund']">Quarter wise Fund release</a></li>
                           </ul>
                       </li>
                   </ul>
               </div>
           </div>
       </nav>

</div> -->
<div class="container whitebg">
    <nav class="navbar navbar-expand-lg ">
        <div class="container-fluid">
            <button class="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link " href="#" routerLinkActive="active" (click)="setCurrentTab('home')" [routerLink]="['/admin/grant-management', this.scheme_id, 'home']">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" routerLinkActive="active" (click)="setCurrentTab('startuptable')" [routerLink]="['/admin/grant-management', this.scheme_id, 'startuptable']">Startups</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" routerLinkActive="active" (click)="setCurrentTab('team')" [routerLink]="['/admin/grant-management', this.scheme_id, 'team']">Team {{schemeName}}</a>
                    </li>
                    <ng-container *ngIf="scheme_id!=2">
                    <li class="nav-item">
                        <a class="nav-link" href="#" routerLinkActive="active" (click)="setCurrentTab('released')" [routerLink]="['/admin/grant-management', this.scheme_id, 'released']">GIA Released</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" routerLinkActive="active" (click)="setCurrentTab('Eir-grant')" [routerLink]="['/admin/grant-management', this.scheme_id, 'Eir-grant']">EIR Grant Release</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" routerLinkActive="active" (click)="setCurrentTab('release-scaleup')" [routerLink]="['/admin/grant-management', this.scheme_id, 'release-scaleup']">Scale-Up Release</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" routerLinkActive="active" (click)="setCurrentTab('notifications')" [routerLink]="['/admin/grant-management', this.scheme_id, 'notifications']">Notifications</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" routerLinkActive="active" (click)="setCurrentTab('uploads')" [routerLink]="['/admin/grant-management', this.scheme_id, 'uploads']">Uploads</a>
                    </li>
                    </ng-container>
                    <ng-container *ngIf="scheme_id==2">
                        <li class="nav-item">
                            <a class="nav-link" href="#" routerLinkActive="active" (click)="setCurrentTab('fund-release')" [routerLink]="['/admin/grant-management', scheme_id, 'fund-release']">Fund Release</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" routerLinkActive="active" (click)="setCurrentTab('document-uploads')" [routerLink]="['/admin/grant-management', scheme_id, 'document-uploads']">Documents</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" routerLinkActive="active" (click)="setCurrentTab('release-order')" [routerLink]="['/admin/grant-management', scheme_id, 'release-order']">Release-order</a>
                        </li>

                    </ng-container>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown7" role="button" data-bs-toggle="dropdown" aria-expanded="false">Financial data</a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown7">
                            <li><a class="dropdown-item" href="#"  (click)="setCurrentTab('state-fund')" [routerLink]="['/admin/grant-management', this.scheme_id, 'state-fund']">State wise Funds</a></li>
                            <li><a class="dropdown-item" href="#"  (click)="setCurrentTab('statewise-agency')" [routerLink]="['/admin/grant-management', this.scheme_id, 'statewise-agency']">State wise Agency</a></li>
                            <li><a class="dropdown-item" href="#"  (click)="setCurrentTab('statewise-startup')" [routerLink]="['/admin/grant-management', this.scheme_id, 'statewise-startup']">State wise Startup-list</a></li>
                            <li *ngIf="scheme_id!=2"><a class="dropdown-item" href="#"  (click)="setCurrentTab('quarter-fund')" [routerLink]="['/admin/grant-management', this.scheme_id, 'quarter-fund']">Quarter wise Fund release</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>

<div class="mt-4" *ngIf="currentTab === 'home'">
    <ngx-newfinancialdashboard></ngx-newfinancialdashboard>
</div>
<div class="mt-4" *ngIf="currentTab === 'startuptable'">
    <ngx-newstartuptable></ngx-newstartuptable>
</div>
<div class="mt-4" *ngIf="currentTab === 'team'">
    <ngx-teamtide-table></ngx-teamtide-table>
</div>
<div class="mt-4"  *ngIf="currentTab === 'released'">
    <ngx-gia-released-table></ngx-gia-released-table>
</div>
<div class="mt-4"  *ngIf="currentTab === 'Eir-grant'">
    <ngx-relese-eir-grants></ngx-relese-eir-grants>
</div>
<div class="mt-4"  *ngIf="currentTab === 'release-scaleup'">
    <ngx-release-scaleup-investment></ngx-release-scaleup-investment>
</div>
<div class="mt-4"  *ngIf="currentTab === 'notifications'">
    <ngx-finance-notifications></ngx-finance-notifications>
</div>
<div class="mt-4"  *ngIf="currentTab === 'uploads'">
    <ngx-finance-uploads></ngx-finance-uploads>
</div>
<div class="mt-4"  *ngIf="currentTab === 'state-fund'">
    <ngx-statewise-fundreleased></ngx-statewise-fundreleased>
</div>
<div class="mt-4"  *ngIf="currentTab === 'statewise-agency'">
    <ngx-statewise-agencies></ngx-statewise-agencies>
</div>
<div class="mt-4"  *ngIf="currentTab === 'statewise-startup'">
    <ngx-statewise-startuplist></ngx-statewise-startuplist>
</div>
<div class="mt-4"  *ngIf="currentTab === 'quarter-fund'">
    <ngx-quarterwise-fund-release></ngx-quarterwise-fund-release>
</div>
<div class="mt-4"  *ngIf="currentTab === 'fund-release'">
    <ngx-samridh-fund-release></ngx-samridh-fund-release>
</div>
<div class="mt-4"  *ngIf="currentTab === 'document-uploads'">
    <ngx-samridh-document-data></ngx-samridh-document-data>
</div>
<div class="mt-4"  *ngIf="currentTab === 'release-order'">
    <ngx-samridh-release-order></ngx-samridh-release-order>
</div>