<div class="container-grid col-sm-12">
    <nb-card class="col-sm-12">
        <nb-card-header class="float-right">Media</nb-card-header>
    </nb-card>
   
        <form
          [formGroup]="mediaForm"
          class="form-control-group col-12"
         enctype="multipart/form-data"
        >
        <div class="row">
        <div class="col-sm-6">
            <label for="" class="label-required">Media Name</label>
            <input
              type="text"
              formControlName="mediaTitle"
              class="form-control"
              placeholder="Enter Name"
            />
            <div *ngIf="mediaForm.get('mediaTitle').invalid && mediaForm.get('mediaTitle').touched">
              <div *ngIf="mediaForm.get('mediaTitle').errors.required" class="text-danger">Name is required.</div>
            </div>
          </div>
          <div class="col-sm-6">
            <label for="" class="label-required">Link</label>
            <div class="mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">@</span>
                </div>
                <input type="text" class="form-control" formControlName="mediaLink"
                placeholder="" aria-label="" aria-describedby="basic-addon1"  placeholder="Enter Link">
               
              </div>
              <div *ngIf="mediaForm.get('mediaLink').invalid && mediaForm.get('mediaLink').touched">
                <div *ngIf="mediaForm.get('mediaLink').errors.required" class="text-danger">Link is required.</div>
                  <div *ngIf="mediaForm.get('mediaLink').errors.url" class="text-danger">The URL must start with 'https://' or 'http://'</div>
              </div>
            </div>
            
          </div>
          </div>
          <div class="row">
          <div class="col-sm-6">
            <label class="label-required">Select File</label>
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="customFile" name="uploadedImage"
                (change)="onFileSelect($event)" formControlName="mediaImage" #UploadFileInput >
              <label class="custom-file-label" for="customFile">{{fileInputLabel || 'Choose File'}}</label>
            </div>
            <span style="color: black;">(File Size Should Not Exceed 2MB. Allowed Extensions: PNG , JPG , SVG)</span>
            <div *ngIf="mediaForm.get('mediaImage').invalid && mediaForm.get('mediaImage').touched">
              <div *ngIf="mediaForm.get('mediaImage').errors.required" class="text-danger">Image is required.</div>
            </div>
            <p class="text-danger" *ngIf="filetypeerr"> 
              File format is incorrect
            </p>
            <p class="text-danger" *ngIf="filesizeerr"> 
              File size should not exceed 2 MB
            </p>
          </div>
          </div>
        </form>
        <div class="button-wrapper">
          <button class="btn btn-primary mr-2 custombtn" (click)="cancel()">Cancel</button>
          <button class="btn btn-primary custombtn"  (click)="publishMedia()" *ngIf="createMode">Create</button>
          <button class="btn btn-primary custombtn" (click)="updateForms()" *ngIf="editMode">Update</button>
        </div>


        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" class="text-center">Sl No</th>
              <th>Updated Time</th>
              <th scope="col">Name</th>
              <th scope="col">Link</th>
              <th scope="col">Image</th>            
              <th scope="col">Status</th>
              <th scope="col" width="200px">Actions</th>
            </tr>
          </thead>
          <tbody *ngIf="filteredDataTable?.length > 0; else noRecords">
            <tr  *ngFor="let d of filteredDataTable| paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index" >
              <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th>
              <td>{{d.updated_at |  date}}</td>
              <td class="m_col" style="word-break: break-all;">{{ truncateText(d.media_name, 150) }} <span data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" *ngIf="readMore"  class="readMore">Read more</span></td>
              <td class="m_col" style="word-break: break-all;"><a href="{{d.media_link}}" target="_blank">{{d.media_link}}</a></td>
              <td class="m_col" style="word-break: break-all;"><a href="{{d.images[0].url}}" target="_blank">{{d.images[0].url}}</a></td>              
              <td>{{d.media_status}} </td>
  
              <td >
                 <!-- <div class="dropdown mr-3">
                  <a class="dropbtn fa fa-check" style = "color:blue"></a>
                  <div class="dropdown-content" >
                    <a *ngIf="d.news_status === 'active'" (click)="changestatus(d,'inactive')">inactive</a>
                    <a *ngIf="d.news_status === 'inactive'" (click)="changestatus(d,'active')">active</a>
                  </div>
                </div> -->
                <div class="d-flex align-items-center ">                 
                  <a class="fa fa-edit mr-3 arrow" style = "color:blue" (click)="editData(d, section)" ></a>
                  <a class="fa fa-trash mr-3 arrow" style = "color:red" (click)="deleteMediaData(d.media_id)" ></a>
                  <a class="fa fa-eye mr-3 arrow" style = "color:green" data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" ></a>
                  </div>
  
              </td>
            </tr>
          
          </tbody>
          <ng-template #noRecords>
            <tbody>
              <tr>
                <td colspan="6" class="no-records-found">No Data found.</td>
              </tr>
            </tbody>
          </ng-template>
        </table>
        <pagination-controls *ngIf="filteredDataTable?.length > 0;" (pageChange)="p = $event" class="float-right"></pagination-controls>
    </div>

    <!-- Modal -->
<div class="modal custom-modal " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content ">
      <div class="modal-header ">
        <h3 class="modal-title " id="exampleModalLabel">Preview</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="container-fluid ">
          <label for="basic-url">Media Name</label>
          <div class="input-group">
            <div class="input-group-prepend">
            </div>
            <input type="text" class="form-control mb-2" placeholder="" aria-label="Username" value="{{modaldata.mediaName}}" aria-describedby="basic-addon1" readonly>
          </div>

          <label for="basic-url">Media Link</label>
          <div class="input-group">
            <div class="input-group-prepend">
            </div>
            <input type="text" class="form-control mb-2" placeholder="" aria-label="Username" value="{{modaldata.link}}" aria-describedby="basic-addon1" readonly>
          </div>
          <label for="basic-url">Media Image</label>
          <div class="input-group">
          <div class="input-group-prepend">
          </div>
          <div class="d-flex justify-content-center align-items-center mb-2">
          <img [src]="modaldata.image" alt="image" style="width: 100%;">
        </div>
        </div>
  
         
  
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
    
  </div>
  </div>
   