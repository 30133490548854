import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AxiosServicesService } from "./axios/axios-services.service";
import { environment } from "../../environments/environment";
const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

@Injectable({
  providedIn: 'root'
})
export class StateService {
  baseUrl = environment.apiurl;

  constructor( private http: HttpClient,) { }

  // get state
  getallstates() {
    const url = this.baseUrl + "states";
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  // get state
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
