
<div class="container">
  <ul class="d-flex list-unstyled">
    <li class="active mr-2 "><a class="btn  text-white" data-toggle="pill" [ngClass]="{'btn-primary': this.lab, 'btn-secondary': !this.lab}" (click)="navigateMenu('lab')">Labs</a></li>
    <li class=" mr-2"><a class="btn text-white"   data-toggle="pill" [ngClass]="{'btn-primary': this.guest, 'btn-secondary': !this.guest}" (click)="navigateMenu('guest')">Venue Space</a></li>
    <li class=" mr-2"><a class="btn text-white"   data-toggle="pill" [ngClass]="{'btn-primary': this.cowork, 'btn-secondary': !this.cowork}" (click)="navigateMenu('cowork')">Co-Working Space</a></li>
    <li class=" "><a class="btn text-white"  data-toggle="pill" [ngClass]="{'btn-primary': this.equipment, 'btn-secondary': !this.equipment}" (click)="navigateMenu('equipment')">Equipment Details</a></li>
  </ul>
  
  <div class="tab-content">
    <div id="labs" *ngIf = "lab">
      <ngx-labs [newid]="newid" [entity_type]="entity_type"></ngx-labs>
     
    </div>
    <div id="guest" *ngIf = "guest">
     
      <ngx-guest-house [newid]="newid" [entity_type]="entity_type"></ngx-guest-house>
    </div>
    <div id="coworking" *ngIf = "cowork">
      
      <ngx-coworking-space [newid]="newid" [entity_type]="entity_type"></ngx-coworking-space>
    </div>
    <div id="equipment" *ngIf = "equipment">
     
      <ngx-equipment-details [newid]="newid" [entity_type]="entity_type"></ngx-equipment-details>
    </div>
  </div>
</div>
