import { HttpClient, HttpErrorResponse , HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AxiosServicesService } from "./axios/axios-services.service";
import { HttpClientService } from "./axios/base-http-client-axios.service";
import { environment } from "../../environments/environment";

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjM1IiwiZW1haWwiOiJ0ZXN0c21hbmFnZXJAZ21haWwuY29tIiwiaWF0IjoxNjc3OTMzNjA1LCJleHAiOjE2ODY1NzM2MDV9.s9hWkJVV0sWuCpqtaCOiorL22AQlhJaP4rWaqJHD32k'

const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Authorization', `Bearer ${sessionStorage.getItem('admintoken')}`);

@Injectable({
  providedIn: "root",
})
export class RoleService {
  //baseUrl = "http://43.205.193.210:8593";
  baseUrl = environment.apiurl;
  name: any;
  roleCode: any;
  description: any;
  rolesForm: any;
  value: any;

  constructor(
    private http: HttpClient,
    private axiosServices: AxiosServicesService
  ) {
    
  }

  getAllRoles() {
    let url = this.baseUrl + "role/getall";
    let result: any = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
    return result;
  }

  addRoles(data: any) {
    console.log("data :: ", data);
    const url = this.baseUrl + "role/add";
    let Param = {
      name: data.value.name,
      role_code: data.value.roleCode,
      role_description: data.value.description,
    };
    let result: any = this.http
      .post(url, Param,{'headers':headers})
      .pipe(catchError(this.handleError));
    return result;
  }

  deleteRow(id: number) {
    let url = this.baseUrl + "role/delete/" + id;
    let param = {
      deleted_by_id: 1,
    };
    let result: any = this.http
      .put(url, { body: param },{'headers':headers})
      .pipe(catchError(this.handleError));

    return result;
  }

  updateRow(currentData, role_id) {
    let url = this.baseUrl + "role/update";
    console.log("data::", currentData);
    let param = {
      id: role_id,
      name: currentData.name,
      role_code: currentData.roleCode,
      description: currentData.description,
    };
    let result = this.http.put(url, param , {'headers':headers}).pipe(catchError(this.handleError));
    return result;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  getPermissionByRoleId(role_id){
    let url = this.baseUrl + `rolePermission/${role_id}`;
    let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));

    return result;
  }

  addPermissiontoRole(data:any){
    let url = this.baseUrl + `rolePermission/add`;
    let result: any = this.http
      .post(url,data,{'headers':headers})
      .pipe(catchError(this.handleError));
    return result;
  }

  createAdminUser(data){
    const url = this.baseUrl + 'admin/user';
    let result = this.http.post(url,data,{'headers':headers}).pipe(catchError(this.handleError));
    return result ;
  }

  addRoleToUser(data){
    const url = this.baseUrl + "userRole/add";
    let result = this.http.post(url,data,{'headers':headers}).pipe(catchError(this.handleError));
    return result;
  }

  getAllUsers(){
    const url = this.baseUrl + "admin/users";
    let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
    return result;
  }

  getIndividualUserData(){
    const url = this.baseUrl + "admin/user";
    let result = this.http.get(url);
    return result;
  }
  editUserProfile(data){
    const url=this.baseUrl+ "/user/profile";
    let result = this.http.put(url,data,{'headers':headers});
    return result;

  }
  editPermissionforRole(data){
    const url = this.baseUrl + "rolePermission/update";
    let result = this.http.put(url,data,{'headers':headers});
    return result;
  }


  encodeBase64(value: any): any {
    return btoa(value);
  }

  decodeBase64(value: any): any {
    return atob(value);
  }
  
}
