<p>add-roles works!</p>


    <!-- <div class="container-lg">
        <div class="table-responsive">
            <div class="table-wrapper">
                <div class="table-title">
                    <div class="row">
                        <div class="col-sm-8"><h2>Role <b>Details</b></h2></div>
                         <div class="col-sm-2">
                            <button type="button" class="btn btn-info add-new"><i class="fa fa-plus"></i> Add New</button>
                        </div> 
                    </div>
                </div>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Role Code</th>
                            <th>Role Descriptions</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let entry of tableData ">
                            <td>{{entry.name}}</td>
                            <td>{{entry.roleCode}}</td>
                            <td>{{entry.description}}</td>
                            <td>{{entry.roleStatus}}</td>
    
                            <td>
                                 <a class="add" ><i class="material-icons">&#xE03B;</i></a> 
                                <a class="edit" ><i class="material-icons">&#xE254;</i></a>
                                <a class="delete" ><i class="material-icons">&#xE872;</i></a>
                            </td>
                        </tr>
                       
                    </tbody>
                </table>
            </div>
        </div>
    </div>   -->