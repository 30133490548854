import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { AxiosServicesService } from '../../services/axios/axios-services.service';
import { RoleService } from '../../services/role.service';
import * as _ from "lodash";

@Component({
  selector: 'ngx-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.scss']
})
export class AdminUserComponent implements OnInit {
  usersForm: FormGroup;
  editMode:boolean=false;
  createMode: boolean = true;
  tableData: any[] = [];
  data:any;
  roles:any;
  password:string;
  disabled = true;
  role_id:number;
  allusers:any;
  selectrole = "select role"
  submitted = false;

  allowedImplementingAgencies = [ 'STPI', 'NASSCOM','CDAC','IPHW','C-MET','CSIR','EMCD','AMTZ','Cyber Security'];
  allowedProgramDivisions = [ 'Startup, Innovation & IPR','ET','SIPD','IPHW','EMCD','Cyber Security' ];

  dropdownSettings:any = {
    singleSelection: false,
  };

  roleList:any = [
    {value:'Project Manager',title:"Project Manager"},
    {value:'Social Manager',title:"Social Manager"},
    {value:'Finance Manager',title:"Finance Manager"},
  ];
  currentData: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private axiosService:AxiosServicesService,
    private toastrService: NbToastrService,
    private roleService: RoleService
  
    ) {}

    ngOnInit() {
      this.getAllUsers();
      // this.password = '123456'
      this.roleService.getAllRoles().subscribe((res)=>{
        console.log('res',res);
        this.roles = res.results;
        console.log('roles',this.roles);
      })
      this.usersForm = this.formBuilder.group({
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        email:  ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        password: ['', [Validators.required, passwordValidator]],
        implementing_agency: [[]],
        program_division: [[]],
        role: ['', Validators.required],
        is_edited: [false]
      });
      function passwordValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const value: string = control.value || '';
        const hasLowerCase = /[a-z]/.test(value);
        const hasUpperCase = /[A-Z]/.test(value);
        const hasDigit = /\d/.test(value);
        const hasSpecialChar = /[#@$!%*?&]/.test(value);
      
        const isValid =
          hasLowerCase && hasUpperCase && hasDigit && hasSpecialChar && value.length >= 8;
      
        return isValid ? null : { invalidPassword: true };
      }
    }

    get f(){
      return this.usersForm.controls;
    }
    getAllUsers(){
      this.roleService.getAllUsers().subscribe((response:any)=>{
        console.log('response',response);
      let responseData = this.roleService.decodeBase64(response.data);
      console.log("responseData+++++++",responseData)
      let res = JSON.parse(responseData);

        this.allusers = res.results
      })
    }

    ConfirmedValidator(controlName: string, matchingControlName: string) {
      return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (
          matchingControl.errors &&
          !matchingControl.errors.confirmedValidator
        ) {
          return;
        }
        if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
        } else {
          matchingControl.setErrors(null);
        }
      };
    }
    submitForm(){
      this.submitted = true;
      if(!this.usersForm.valid){

      }
      else {
        this.tableData.push(this.usersForm.value);
        console.log("tableData::", this.tableData);
        //this.usersForm.reset();
        let data = {
          
            "email": this.usersForm.value.email,
            "password": this.usersForm.value.password,
            "mobile": this.usersForm.value.mobile,
            "first_name": this.usersForm.value.firstname,
            "last_name": this.usersForm.value.lastname,
            "role_id": this.usersForm.value.role,
            "implementing_agency":this.usersForm.value.implementing_agency,
            "program_division": this.usersForm.value.program_division,
            "is_edited": this.usersForm.value.is_edited
        }

        this.roleService.createAdminUser(data).subscribe((res)=>{
          console.log(res)
          this.toastrService.success("Data Added Successfully!");
          this.getAllUsers();
          this.usersForm.reset();
          this.submitted = false;
        })

        console.log('data',data);
      }

    }
    editData(data){
      this.data = data;
      console.log("editdataAdmin::", this.data);

      this.usersForm.controls["firstname"].setValue(data.first_name);
      this.usersForm.controls["lastname"].setValue(data.last_name);
      this.usersForm.controls["mobile"].setValue(data.mobile);
      this.usersForm.controls["role"].setValue(data.role_id);
      this.usersForm.controls["password"].setValue(data.password);
      this.usersForm.controls["email"].setValue(data.email);
      if(data.role_id == 13 || data.role_id == 95) {
        this.usersForm.controls["implementing_agency"].setValue(data.implementing_agency);
        this.usersForm.controls["program_division"].setValue(data.program_division);
      }
      this.usersForm.controls["is_edited"].setValue(true);
    }

    deleteUser(data) {
        // need to delete user @ users, userroles, userverifications
        console.log('user delete not supported');
    }

    updateForms() {
      if (this.usersForm.valid) {
        if (this.editMode && _.usersForm == _.data) {
          this.currentData=this.usersForm.value;
        }
      }
    }
    cancelData(){
      this.usersForm.reset();
      this.submitted = false;
    }

    addRoletoUser(role_id:any,role_name:any){
      console.log('role1234',role_id,role_name);
      this.selectrole = role_name;
      this.role_id = role_id
      console.log('role_id',role_id);
      this.disabled = false;  
    }
  }
