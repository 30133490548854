import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewstartuptableComponent } from '../newstartuptable/newstartuptable.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GrantNotificationService } from '../../../services/grant-notification.service';
import { GrantUploadsService } from '../../../services/grant-uploads.service';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
@Component({
  selector: 'ngx-newfinancialdashboard',
  templateUrl: './newfinancialdashboard.component.html',
  styleUrls: ['./newfinancialdashboard.component.scss']
})

export class NewfinancialdashboardComponent implements OnInit {
  chooseYear:any;
  startdate:any;
  enddate:any;
  scheme_id:any;
  baseUrl = environment.apiurl;
  newfinancialYear:any[]=[];
  schemeId:Number = 1;
  notifications:any;
  uploads:any;
  entity:any;
  selectNotif: any;

  constructor(private _route:ActivatedRoute,private http:HttpClient,
    private grantNotificationService: GrantNotificationService,
    private grantUploadService: GrantUploadsService,private grantManagementService:GrantManagementService) { 
      this._route.paramMap.subscribe((params:any)=>{
        this.scheme_id = params.params.id;
        this.grantManagementService.scheme_id = this.scheme_id
      })
    console.log('scheme_id',this.scheme_id);
  }

  ngOnInit(): void {
    // this.getfinancialYear();
    this.grantManagementService.getEntityType().subscribe((res: any) => {
      this.entity = res;
      console.log('entity------', this.entity);
      this.getNotifications();
      this. getGrantUploads()
  });
  this.grantManagementService.getStartDate().subscribe((res:any)=>{
    this.startdate = res;
    console.log('startdate------',this.startdate)
    console.log('here1');
    //this.getFunds();
    
})

this.grantManagementService.getEndDate().subscribe((res: any) => {
    this.enddate = res;
    console.log('end------', this.enddate);
    console.log('here2');
    this.getNotifications();
    this.getGrantUploads();
});

    // this.getNotifications();
    // this. getGrantUploads()
  }
  

  // getfinancialYear(){
  //   this.http.get(this.baseUrl + "finance/list").subscribe(
  //     (data:any) => {
  //       this.newfinancialYear = data.results;
  //       console.log('tidedata',data.results)
  //     },
  //     error => {
  //       console.error('Error fetching investors: ', error);
  //     }
  //   );
  // }
    // postfinancialYear(){
    //   const currentDate = new Date();
    //   const startDateString = this.newfinancialYear[0]?.start_date.substring(0, 10);
    //   var startDate = new Date(startDateString);
    //   console.log('da', startDate)
      
    //   startDate.setFullYear(startDate.getFullYear() + 1);

    //   console.log('sd' ,startDate)
      
    //   // startDate.setFullYear(startDate.getFullYear() + 1);
    //   // const newStartDateString = startDate.toISOString().substring(0, 10);

    //   const  endDateString = this.newfinancialYear[0]?.end_date.substring(0, 10);
    //   var endDate = new Date(endDateString);
    //   endDate.setFullYear(startDate.getFullYear() + 2);
    //   console.log(endDate)
      

    //   // endDate.setFullYear(endDate.getFullYear() + 1);
    //   // const newendDateString = endDate.toISOString().substring(0, 10);
    //   if (currentDate.setFullYear(startDate.getFullYear() + 1) >= startDate.setFullYear(startDate.getFullYear()) && currentDate.setFullYear(startDate.getFullYear() + 2) <= endDate.setFullYear(startDate.getFullYear())) {
    //     const range = startDateString.substring(0, 4) + "-" + endDateString.substring(0, 4);
    //     const payload = {
    //       start_date: startDate,
    //       end_date: endDate,
    //       scheme_ids: [this.scheme_id],
    //       range: [range],
    //     };
    //     console.log('Success: ', startDate);
    //     this.http.post(this.baseUrl + "finance", payload).subscribe(
    //       (response: any) => {
    //         console.log('Success: ', response);
    //       this. getfinancialYear()
    //         // Handle success response
    //       },
    //       error => {
    //         console.error('Error: ', error);
    //         // Handle error response
    //       }
    //     );
    
    //   }
    //   else{
        
    //     const currentYear = new Date().getFullYear();
    //     console.log('currentYear------');
    //     console.log(currentYear);
    //     const nextYear = currentYear + 1;
    //     const startDateString = currentYear + "-01-01";
    //     const endDateString = nextYear + "-01-01";
    
    //     const range = currentYear + "-" + nextYear;
    //     const payload = {
    //         start_date: startDateString,
    //         end_date: endDateString,
    //         scheme_ids: [this.scheme_id],
    //         range: [range],
    //     };
    //     console.log('payload-----');
    //     console.log(payload);
    //     this.http.post(this.baseUrl + "finance", payload).subscribe(
    //       (response: any) => {
    //         console.log('Success: ', response);
    //       this. getfinancialYear()
    //         // Handle success response
    //       },
    //       error => {
    //         console.error('Error: ', error);
    //         // Handle error response
    //       }
    //     );
    //   }
    

    //   // const  range = startDateString.substring(0,4)+"-"+endDateString.substring(0,4)
      
    
    
    // }
// postfinancialYear(){
//   const startDateString = this.newfinancialYear[0].start_date.substring(0, 10);
//     const startDate = new Date(startDateString);
//     startDate.setFullYear(startDate.getFullYear() + 1);
//     const newStartDateString = startDate.toISOString().substring(0, 10);
 
//     const  endDateString = this.newfinancialYear[0].end_date.substring(0, 10);
//     const endDate = new Date(endDateString);
//     endDate.setFullYear(endDate.getFullYear() + 1);
//     const newendDateString = endDate.toISOString().substring(0, 10);
 
//     const  range = newStartDateString.substring(0,4)+"-"+newendDateString.substring(0,4)
//     const payload = {
//             start_date: startDate,
//             end_date: endDate,
//             scheme_ids: [this.scheme_id],
//             range: [range],
//           };

// this.http.post(this.baseUrl + "finance", payload).subscribe(
//           (response: any) => {
//             console.log('Success: ', response);
//           this. getfinancialYear()
//             // Handle success response
//           },
//           error => {
//             console.error('Error: ', error);
//             // Handle error response
//           }
//         );
      
// }
    getNotifications() {
      this.grantManagementService.getNotifications(this.entity,this.startdate, this.enddate).subscribe((res:any) => {
          this.notifications = res;
      })
  }
  getGrantUploads() {
    this.grantManagementService.getUploads(this.entity,this.startdate, this.enddate).subscribe((res:any) => {
        this.uploads = res;
    })
}

storeData(data: any){
  console.log(data)
  this.selectNotif = [];
  if(data != null){
    this.selectNotif = data;
    console.log('79', this.selectNotif?.description)
  }
}

}
  