<div class="container">
  <div class="container">
    <div class="container pt-4">
      <div class="" id="project-financials">
        <form [formGroup]="projectFinancial" (ngSubmit)="onSubmit()">
          <!--<div class="form-group  font-weight-bold">
            <label class="form-label" for="fundingSource">Funding Source</label>
            <input
              type="text"
              class="form-control"
              id="fundingSource"
              formControlName="fundingSource"
              readonly
            />

            <div
              *ngIf="
                projectFinancial.controls.fundingSource.touched &&
                projectFinancial.controls.fundingSource.invalid
              "
              class="text-danger funding_source_errormsg"
            >

              <div
                *ngIf="projectFinancial.controls.fundingSource.errors?.required"
              >
                This Field is mandatory
              </div>
              <div
                *ngIf="
                  projectFinancial.controls.fundingSource.errors?.maxlength
                "
              >
                Funding Source must be at most 60 characters long
              </div>
            </div>
          </div>-->
          <div formArrayName="financialDetails">
            <div
              *ngFor="
                let financialDetail of projectFinancial?.get('financialDetails')
                  ?.controls;
                let i = index
              "
            >
              <div [formGroupName]="i" class="shadow-lg p-3 mb-5 bg-white border-radius">
                <div class="row">
                  <div class="col-md-12">
                   <div class="row">
                    <div class="form-group col-md-4  font-weight-bold">
                      <label for="startupName" class="form-label label-required">Name of the Startup:<span class="text-danger">*</span></label>
                          <select class="form-control" formControlName="nameOfStartup">
                            <option value="">Select</option>
                            <option *ngFor="let sdata of startupData?.results" value="{{sdata.id}}">{{sdata.name}}</option>
                          </select>
                      <div
                        *ngIf="
                          projectFinancial.controls.financialDetails.controls[
                            i
                          ].get('nameOfStartup').touched &&
                          projectFinancial.controls.financialDetails.controls[
                            i
                          ].get('nameOfStartup').invalid
                        "
                        class="text-danger"
                      >
                        <div
                          *ngIf="
                            projectFinancial.controls.financialDetails.controls[
                              i
                            ].get('nameOfStartup')?.errors?.required
                          "
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4  font-weight-bold">
                      <label>Please Select EIR/Grant/Scale<span class="text-danger">*</span></label>
                      <select class="form-control" formControlName="eir_Grant">
                        <option value="">Select</option>
                        <option value="EIR">EIR</option>
                        <option value="GRANT">GRANT</option>
                        <option value="SCALE">SCALE</option>
                      </select>
                      <div
                        *ngIf="
                          projectFinancial.controls.financialDetails.controls[
                            i
                          ].get('eir_Grant').touched &&
                          projectFinancial.controls.financialDetails.controls[
                            i
                          ].get('eir_Grant').invalid
                        "
                        class="text-danger"
                      >
                        <div
                          *ngIf="
                            projectFinancial.controls.financialDetails.controls[
                              i
                            ].get('eir_Grant')?.errors?.required
                          "
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4  font-weight-bold">
                      <label>Approved Amount<span class="text-danger">*</span></label>
                      <input class="form-control" placeholder="Approved Amount" type="number" min="1" formControlName="approved_amount" />
                      <div
                        *ngIf="
                          projectFinancial.controls.financialDetails.controls[
                            i
                          ].get('approved_amount').touched &&
                          projectFinancial.controls.financialDetails.controls[
                            i
                          ].get('approved_amount').invalid
                        "
                        class="text-danger"
                      >
                        <div
                          *ngIf="
                            projectFinancial.controls.financialDetails.controls[
                              i
                            ].get('approved_amount')?.errors?.required
                          "
                        >
                          This field is required
                        </div>
                        <div
                        *ngIf="
                          projectFinancial.controls.financialDetails.controls[
                            i
                          ].get('approved_amount')?.errors?.pattern
                        "
                      >
                        Amount should be numeric
                      </div>
                      </div>
                    </div>
                   </div>
                  </div>
                </div>
                <div formArrayName="termsDetails">
                  <div
                    *ngFor="
                      let term of financialDetail.get('termsDetails')?.controls;
                      let j = index
                    "
                  >
                    <div [formGroupName]="j">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="form-group col-md-4  font-weight-bold">
                              <label>Q{{j+1}} Amount<span class="text-danger">*</span></label>
                              <input type="hidden" formControlName="incubatorfundterm_id" />
                              <input class="form-control" placeholder="Q{{j+1}} Amount" type="number" min="1" formControlName="quarterAmount" />
                              <div
                                *ngIf="
                                  projectFinancial.controls.financialDetails.controls[
                                    i
                                  ]
                                    .get('termsDetails')
                                    .controls[j].get('quarterAmount').touched &&
                                  projectFinancial.controls.financialDetails.controls[
                                    i
                                  ]
                                    .get('termsDetails')
                                    .controls[j].get('quarterAmount').invalid
                                "
                                class="text-danger"
                              >
                                <div
                                  *ngIf="
                                    projectFinancial.controls.financialDetails.controls[
                                      i
                                    ]
                                      .get('termsDetails')
                                      .controls[j].get('quarterAmount').errors
                                      ?.required
                                  "
                                >
                                  This field is required
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-md-4  font-weight-bold">
                              <label>Date of Sanction<span class="text-danger">*</span></label>
                              <input class="form-control"type="date" formControlName="dateOfSanction" />
                              <!-- {{projectFinancial.controls.financialDetails.controls[i].get('termsDetails').controls[j].get('dateOfSanction').touched}} -->
                              <div
                                *ngIf="
                                  projectFinancial.controls.financialDetails.controls[
                                    i
                                  ]
                                    .get('termsDetails')
                                    .controls[j].get('dateOfSanction').touched &&
                                  projectFinancial.controls.financialDetails.controls[
                                    i
                                  ]
                                    .get('termsDetails')
                                    .controls[j].get('dateOfSanction').invalid
                                "
                                class="text-danger"
                              >
                                <div
                                  *ngIf="
                                    projectFinancial.controls.financialDetails.controls[
                                      i
                                    ]
                                      .get('termsDetails')
                                      .controls[j].get('dateOfSanction').errors
                                      ?.required
                                  "
                                >
                                  This field is required
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-md-4">
                              <label>Date of Receive<span class="filled-incubator-txt"> (This field is filled by incubator)</span></label>
                              <input class="form-control" type="text" formControlName="dateOfReceive" readonly />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <button type="button" (click)="removeTermsDetail(i, j)">
                        Remove Term Detail
                      </button> -->
                    </div>
                  </div>
                  <!-- <button type="button" (click)="addTermsDetail(i)">
                    Add Term Detail
                  </button> -->
                </div>
                <div class="row">
                  <div class="form-group col-md-6 font-weight-bold">
                    <label>Utilized Amount</label>
                    <input class="form-control"type="text" formControlName="utilAmount" readonly />
                  </div>
                  <div class="form-group col-md-6 font-weight-bold">
                    <label>Un utilized Amount</label>
                    <input class="form-control"type="text" formControlName="unutilAmount" readonly />
                  </div>
                </div>
                <!-- <button type="button" class="btn btn-primary square-btn my-2" (click)="removeFinancialDetail(i)">
                  -
                </button> -->
              </div>
            </div>
            <!--<button type="button" class="btn btn-primary square-btn my-2" (click)="addFinancialDetail()">
               Add Financial Detail +
            </button>-->
          </div>
          <button type="submit" class="btn btn-primary">submit</button>
        </form>
      </div>
    </div>
  </div>
</div>
