import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'ngx-gia-released-table',
  templateUrl: './gia-released-table.component.html',
  styleUrls: ['./gia-released-table.component.scss']
})
export class GiaReleasedTableComponent implements OnInit {
  showEir:boolean=true;
  showscaleup:boolean=true;
  entity:any;
  baseUrl = environment.apiurl;
  newtideData:any;
  fundtype="";
  granttype="EIR";
  startdate: any;
  scaledata:any
  enddate:any;
  scheme_id:any;
  newscaleData: any;
  constructor(private http:HttpClient,private grantManagementService:GrantManagementService,private _route:ActivatedRoute) { 
    this._route.paramMap.subscribe((params:any)=>{
      this.scheme_id = params.params.id;
      this.grantManagementService.scheme_id = this.scheme_id
    })
  }

  ngOnInit(): void {
    this.grantManagementService.getStartDate().subscribe((res:any)=>{
      this.startdate = res;
      console.log('startdate------',this.startdate)
      // this.getData('amount_received')
      // this.giautilizedrefnewdata('amount_received')
  })

  this.grantManagementService.getEndDate().subscribe((res: any) => {
      this.enddate = res;
      console.log('end------', this.enddate);
      this.getData('amount_received')
      this.giautilizedrefnewdata('amount_received')

  });
  this.grantManagementService.getEntityType().subscribe((res: any) => {
    this.entity = res;
    console.log('entity------', this.entity);
    this.getData('amount_received')
    this.giautilizedrefnewdata('amount_received')
 
});
    
  }

  giautilizedrefnewdata(funds:any){
    this.grantManagementService.eirUtilized(funds, this.startdate, this.enddate,this.entity).subscribe(
      (data: any) => {
        this.newtideData =data;
        
        console.log('111',this.newtideData)
      },
      (error: any) => {
        // Handle errors
        console.error('Error fetching scaleup utilized data:', error);
      }
    );
}
// giautilizednewdata(fund){
//   if(this.showEir){
//    this.granttype ="EIR" 
//   }
//   else{
//     this.granttype = ""
//   }
//   this.fundtype= fund;

//   this.http.get(`${this.baseUrl}grant/incubatorfunds/received/utilised/refunded?startdate=2023-06-30&enddate=2024-04-25&grant_type=${this.granttype}&fundtype=${this.fundtype}`).subscribe(
//     (data:any) => {

//       this.newtideData = data;
//       console.log('tidedata',this.newtideData)
//     },
//     error => {
//       console.error('Error fetching investors: ', error);
//     }
//   );
// }
// giautilizedrefnewdata(fund){
//   if(this.showEir){
//    this.granttype ="EIR" 
//   }
//   else{
//     this.granttype = ""
//   }
//   this.fundtype= fund;

//   this.http.get(`${this.baseUrl}grant/incubatorfunds/received/utilised/refunded?startdate=2019-01-01&enddate=2024-12-31&grant_type=${this.granttype}&fundtype=${this.fundtype}`).subscribe(
//     (data:any) => {

//       this.newtideData = data;
//       console.log('tidedata',this.newtideData)
//     },
//     error => {
//       console.error('Error fetching investors: ', error);
//     }
//   );
// }


// scaleuputilizeddata(fund,start_date:any,end_date:any){
//   if(this.showscaleup){
//    this.granttype ="SCALEUP" 
//   }
//   else{
//     this.granttype = ""
//   }
//   this.fundtype= fund;

//   this.http.get(`${this.baseUrl}grant/incubatorfunds/received/utilised/refunded?startdate=${start_date}&enddate=${end_date}&grant_type=${this.granttype}&fundtype=${this.fundtype}`).subscribe(
//     (data:any) => {

//       this.newscaleData = data;
//       console.log('tidedata',data)
//     },
//     error => {
//       console.error('Error fetching investors: ', error);
//     }
//   );
// }
getData(funds:any){
this.grantManagementService.scaleuputilizeddata(funds, this.startdate,this.enddate,this.entity).subscribe(
  (data: any) => {
    this.scaledata=data;
    // Handle the response data
    console.log('Scaleup Utilized Data:', data);
  
  },
  (error: any) => {
    // Handle errors
    console.error('Error fetching scaleup utilized data:', error);
  }
);
}

downloadExcel() {

  this.grantManagementService.getParallelData(this.startdate, this.enddate, this.entity).subscribe(
    (results) => {
      // Results is an array containing the results of all HTTP requests
      // Handle the results here, for example, you can generate Excel files
      this.generateExcelFiles(results);
    },
    (error) => {
      console.error('Error:', error);
      // Handle error as needed
    }
  );
}

generateExcelFiles(results: any[]) {
  const workbook = XLSX.utils.book_new();
  
  // Create separate worksheets for each type of data
  const sheet1 = XLSX.utils.json_to_sheet(results[0]); // Assuming results[0] is for amount received
  const sheet2 = XLSX.utils.json_to_sheet(results[1]); // Assuming results[1] is for amount utilised
  const sheet3 = XLSX.utils.json_to_sheet(results[2]); // Assuming results[2] is for amount refunded

  // Add worksheets to the workbook
  XLSX.utils.book_append_sheet(workbook, sheet1, 'Amount Received');
  XLSX.utils.book_append_sheet(workbook, sheet2, 'Amount Utilised');
  XLSX.utils.book_append_sheet(workbook, sheet3, 'Amount Refunded');

  // Generate and trigger download
  XLSX.writeFile(workbook, 'data.xlsx');
}

downloadscaleupExcel() {

  this.grantManagementService.getsectorParallelData(this.startdate, this.enddate, this.entity).subscribe(
    (results) => {
      // Results is an array containing the results of all HTTP requests
      // Handle the results here, for example, you can generate Excel files
      this.generateExcelFiles(results);
    },
    (error) => {
      console.error('Error:', error);
      // Handle error as needed
    }
  );
}

scaleupgenerateExcelFiles(results: any[]) {
  const workbook = XLSX.utils.book_new();
  
  // Create separate worksheets for each type of data
  const sheet1 = XLSX.utils.json_to_sheet(results[0]); // Assuming results[0] is for amount received
  const sheet2 = XLSX.utils.json_to_sheet(results[1]); // Assuming results[1] is for amount utilised
  const sheet3 = XLSX.utils.json_to_sheet(results[2]); // Assuming results[2] is for amount refunded

  // Add worksheets to the workbook
  XLSX.utils.book_append_sheet(workbook, sheet1, 'Amount Received');
  XLSX.utils.book_append_sheet(workbook, sheet2, 'Amount Utilised');
  XLSX.utils.book_append_sheet(workbook, sheet3, 'Amount Refunded');

  // Generate and trigger download
  XLSX.writeFile(workbook, 'data.xlsx');
}
}



