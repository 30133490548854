import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FaqsService } from "../../services/faqs.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { toJSON, toDOM } from "dom-to-json";
import * as _ from "lodash";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ShowcaseDialogComponent } from "../../@core/miscellaneous/dialog/showcase-dialog/showcase-dialog.component";
import { CommondialogComponent } from "../../@core/miscellaneous/commondialog/commondialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ExcelService } from '../../services/excel.service';
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: "ngx-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.scss"],
})
export class FaqsComponent implements OnInit {
  public Editor = ClassicEditor;
  filterStatus: '';

  public editorConfig = {
    placeholder: 'Enter Answer',
    toolbar: {
      items: [
        "heading",
        "|",
        "alignment", // <--- ADDED
        "bold",
        "italic",
        "link",
        "bulletedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    link: {
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
        openInNewTab: {
          mode: "manual",
          label: "Open in a new tab",
          defaultValue: true, // This option will be selected by default.
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
  };
  faqsForm: FormGroup;
  categoryList = "General";
  dropLists = [
    "list1",
    "list2",
    "list3",
    "list4",
   
  ];
  data: any;
  dataaa: any;
  list: any[] = [];
  list2: any[] = [];
  id_byList:any;
  editMode: boolean;
  createMode: boolean = true;
  currentData: any;
  faqsEditdata: any;
  inValidMessage: boolean;
  formSubmitted = false;
  value: any;
  index: any;
  modaldata = { categories: "", question: "", answer: "", user_Id:"" };
  user_Id:any=[];
  searchText = '';
  p: number = 1;
  pageSize: number = 25;
  id = "section";
  // dialog: any;
  mat_data: any;
  filter_list: any;

  constructor(
    private faqsService: FaqsService,
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private route:ActivatedRoute

  ) {
    this.route.queryParams.subscribe(params => { 
        this.filterStatus = params['status'];
    })
  }

  ngOnInit(): void {
    this.faqsForm = this.fb.group({
      categories: [""],
      question: ["", Validators.required],
      answer: ["", Validators.required],
    });
    this.getCategory();
    this.getAllFaqsQuestion();
    this.getactivefaq()
    // this.parseToHTML();
  }
 
  getCategory() {
    this.faqsService.getallcategory().subscribe((res: any) => {
      // this.categoryList=res.results;
      // console.log(this.categoryList)
    });
  }
  getactivefaq(){
    this.faqsService.activeFaqList().subscribe((res: any) => {
      this.list2 = res.results;    
      console.log("list", this.list2);
      // filter data with active faq
      // this.filter_list = this.list.filter(item => item.faq_status === "active");
      // console.log("Filtered list", this.filter_list);
      // resolve(res);
    });

  }
  getAllFaqsQuestion() {
    // return new Promise((resolve,reject)=>{
    this.faqsService.getallFaqsList().subscribe((res: any) => {
      this.list = res.results;    
      console.log("list", this.list);
      // filter data with active faq
      // this.filter_list = this.list.filter(item => item.faq_status === "active");
      // console.log("Filtered list", this.filter_list);
      // resolve(res);
    });
    // })
  }
  get filteredData() {
    let filteredDataByStatus;
    if(this.filterStatus) {
        filteredDataByStatus = this.list?.filter(d => d.faq_status == this.filterStatus.toLowerCase())
    } else { filteredDataByStatus = this.list }
    return filteredDataByStatus?.filter(d => {
      // Check if name property exists and is not undefined
      if (d && d.faq_question || d && d.faq_answer || d && d.faq_status ) {
          return  d.faq_question.toLowerCase().includes(this.searchText.toLowerCase()) 
          || d.faq_answer.toLowerCase().includes(this.searchText.toLowerCase())
          || d.faq_status.toLowerCase().includes(this.searchText.toLowerCase());
      }
      // If name property is undefined, return false to exclude from filtered data
      return false;
    });
  }
  

  submitFaqsQuestion() {
    this.formSubmitted = true;
    if (this.faqsForm.valid) {
      this.editMode = false;
      this.createMode = true;
      this.data = {
        faqs_category_id: 1,
        faq_question: this.faqsForm.controls.question.value,
        faq_answer: this.faqsForm.controls.answer.value,
        user_id: sessionStorage.getItem('user_id')
      };
      this.faqsService.createFaqs(this.data).subscribe(
        (res: any) => {
          this.data = res.results;
          this.faqsForm.reset();
          this.getAllFaqsQuestion();
          this.toastrService.success("Data Added Successfully!");
        },
        (error) => {
          this.inValidMessage = true;
          this.toastrService.warning("Record already Exist!");
        }
      );
    } else {
      this.faqsForm.markAllAsTouched();
    }
  }

  editData(data, el:any) {
    el.scrollIntoView({behaviour:'smooth'});

    this.data = data;
    console.log("editdata", this.data);
    this.faqsForm.controls["categories"].setValue(data.faqs_categoryname);
    this.faqsForm.controls["question"].setValue(data.faq_question);
    this.faqsForm.controls["answer"].setValue(data.faq_answer);
    this.editMode = true; //change btn to update
    this.createMode = false;
  }

  updateForms() {
    if (this.faqsForm.valid) {
      if (this.editMode && _.faqsForm == _.data) {
        //put api function call below
        this.editMode = true;
        this.currentData = this.faqsForm.value;
        this.faqsService
          .editfaqsQuestion(this.currentData, this.data.faqs_id)
          .subscribe(
            (res) => {
              this.faqsForm.reset();
              this.getAllFaqsQuestion();
              this.editMode = false;
              this.createMode = true;
              this.toastrService.success("Data Updated Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Update records!");
            }
          ); /////put api function call ends here!
      }
    }
  }

  deleteFaqs(id) {
    this.dialogService
      .open(ShowcaseDialogComponent, {
        context: {
          message: "Are you sure you want to delete this item?",
        },
      })
      .onClose.subscribe((confirmed) => {
        if (confirmed) {
          this.faqsService.deleteFaqsRow(id).subscribe(
            (data) => {
              this.getAllFaqsQuestion();
              this.toastrService.success("Data Deleted Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.danger("Unable to Delete records!");
            }
          );
        }
      });
      var htmlElement = document.documentElement;
        if (htmlElement) {
          htmlElement.style.top = '0'; 
          htmlElement.style.position = 'sticky';
        } 
  }
 
  changestatus(data:any, status:any){
    // localStorage.setItem('myData', 'Faqs');
    console.log('data', data);
    this.mat_data = status;

    const dialogRef=this.dialog.open(CommondialogComponent,{
          data: { status: this.mat_data, name: 'Faqs' }
        })
    dialogRef.afterClosed().subscribe(result => {
     var result=result;
      if(result==true){
        var value = {
          faqs_id: data.faqs_id,
          faqs_category_id: data.faqs_category_id,
          faq_question: data.faq_question,
          faq_answer: data.faq_answer,
          user_id: data.user_id,
          faq_status: status
        }
        this.faqsService.actionFaqs(value).subscribe((res:any)=>{
            console.log('change_status',res);
            this.getAllFaqsQuestion();
            localStorage.removeItem('myData');
            this.toastrService.success("Successfully updated Status!");
          },
          (error) => {
          this.toastrService.warning("Unable to Update Status!");
        })
        
      }
      });
  }

  cancel() {
    this.faqsForm.reset();
    this.editMode = false;
    this.createMode = true;
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.list2, event.previousIndex, event.currentIndex);
    // this.resetIndexes();    
    console.log(this.list2);
    const data = this.list2.map((item, index) => ({
      "faqId": item.faqs_id,
      "orderId": index + 1 // Add 1 to match your desired order
    }));
    console.log("modified data is ==> ", data)
    // this.faqsService.dragUpdate(this.id).subscribe((res:any)=>{
    //   this.id = this.id

    // })
    this.faqsService.changeOrder(data).subscribe((res: any) => {      
      console.log('FAQ order updated successfully:', res);
      this.toastrService.success("Successfully updated FAQ!");
    },
    (error) => {
      this.inValidMessage = true;
      this.toastrService.warning("Unable to Update FAQ!");
    }
    );
    
    

  }
  // resetIndexes() {
  //   for (let i = 0; i < this.list.length; i++) {
  //     this.list[i].order = i;
  //     this.id_byList = this.list[i].faqs_id = i+1;
  //     console.log("get id form drag and drop " + this.id_byList)
      
    
  //   }
  // }
  preview(data: any) {
    console.log("dataPreview:", data);
    this.modaldata.question = data.faq_question;
    this.modaldata.answer = data.faq_answer;
    // this.modaldata.categories = data.faqs_categoryname;
  }


  exportData(dataArray: any[]) {
    const data = dataArray.map((d) => ({
      Question: d.faq_question ? d.faq_question : '-',
      Answer: d.faq_answer ? d.faq_answer.replace(/<\/?[^>]+(>|$)/g, "") : '-',
      Status: d.faq_status ? d.faq_status :'-',
      // 'Updated Time':new Date(d?.updated_at),
    }));
    this.exportAsXLSX(data)
    // this.generateExcelFile(data, "myData.xlsx");
    console.log("Export:", data);
  }

  exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'Faqs');
  }

  downloadFaqList() {
    var exportTableData
    if(this.filterStatus) {
        exportTableData = this.list?.filter(d => d.faq_status == this.filterStatus.toLowerCase())
    } else { exportTableData = this.list }
    this.exportData(exportTableData);
  }
  //   async parseToHTML(){
  //   await this.getAllFaqsQuestion();
  //   console.log('parse html',this.list);
  //   for(var i=0 ; i<this.list.length ; i++){
  //     let innerHTML = `<tr>
  //       <td>${this.list[i].faq_question}</td>
  //       <td>${this.list[i].faq_answer}</td>
  //       <td><button class="btn btn-success edit mr-2" id="edit_${i}">Edit</button>
  //           <button class="btn btn-danger delete" id="delete_${i}">Delete</button>
  //           <button class="btn btn-warning preview" id="preview_${i}">Preview</button></td>

  //     <tr>`
  //     document.getElementById('tbody').innerHTML+= innerHTML;
  //   }

  //   document.querySelectorAll(".edit").forEach((element,i)=>{
  //     document.getElementById(`edit_${i}`).onclick = () =>{
  //       this.editData(this.list[i])
  //     }
  //   })

  //   document.querySelectorAll(".delete").forEach((element,i)=>{
  //     document.getElementById(`delete_${i}`).onclick = () =>{
  //       console.log('delete',`delete_${i}`)
  //       this.deleteFaqs(this.list[i].id)
  //     }
  //   })
  // }
}
