import { Component, OnInit } from '@angular/core';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ActivatedRoute, Router } from '@angular/router';
import { relative } from 'path';
import * as XLSX from 'xlsx';

@Component({
  selector: 'ngx-release-scaleup-investment',
  templateUrl: './release-scaleup-investment.component.html',
  styleUrls: ['./release-scaleup-investment.component.scss']
})
export class ReleaseScaleupInvestmentComponent implements OnInit {
  startdate: any;
  enddate: any;
  entity:any; 
   loading: boolean = false; 

  funds:any;
  newstartupp:any;
  p:number=1;
  pageSize:number=5;
  startupp: any;
  viewdata: any;
  scheme_id:any;
  newone:any[]=[]
  constructor(private grantManagementService:GrantManagementService,
    private router:Router,private toasterService:NbToastrService,private _route:ActivatedRoute) {
    this._route.paramMap.subscribe((params:any)=>{
      this.scheme_id = params.params.id;
      this.grantManagementService.scheme_id = this.scheme_id
    })
   }

  ngOnInit(): void {
    this.grantManagementService.getStartDate().subscribe((res:any)=>{
      this.startdate = res;
      console.log('startdate------',this.startdate)
      // this.getFunds();
  })

  this.grantManagementService.getEndDate().subscribe((res: any) => {
      this.enddate = res;
      console.log('end------', this.enddate);
      this.getFunds(); // Call getFunds() after end date is available
  });
  this.grantManagementService.getEntityType().subscribe((res: any) => {
    this.entity = res;
    console.log('entity------', this.entity);
this.getFunds()
});
this._route.queryParamMap.subscribe((params)=>{
  this.p= +params['page']||1;
  this.loadData(this.p)
})
  // this.getFunds()
  // this.loadScript('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js');
  }

  getFunds(){
    // this.loading = true;

    if (!this.startdate || !this.enddate) {
      console.log('Start date or end date is not available yet.');
      return;
  }
    this.grantManagementService.listByIncubatorFund(this.startdate, this.enddate,this.entity).subscribe((res:any) => {
      console.log("res ==========> ",res);
      this.loading = false;

      this.funds = res.results.filter((item)=>{
        return item.grant_type == "SCALEUP";
      
      });
  
      console.log('sss',this.funds)
  })
  }
  viewTermFund(fundid:any, fund:any, name: any,) {  
    this.startupp = name
    this.grantManagementService.viewTermFund(fundid,fund,this.entity).subscribe((res:any) => {
      console.log("res ========> ", res);  
      this.viewdata = res.results[0]
      console.log(this.viewdata)
      
    })
  }

  // approveTermFund(fundtermid:any,fund:any) { 
  //   this.grantManagementService.approveTermFund(fundtermid,fund,this.entity).subscribe((res:any) => {
  //       console.log("res ========> ", res);
  //       this.toasterService.success("You have approved this request");
  //       this.getFunds()  
  //     })
  // }
  approveTermFund(fundtermid: any, startupfund_id: any) {
    const fund = this.funds.find(f => f.startupfund_id === startupfund_id);
    if (this.isFundDataComplete(fund, fundtermid)) {
        this.grantManagementService.approveTermFund(fundtermid, startupfund_id, this.entity).subscribe((res: any) => {
            console.log("res ========> ", res);
            this.toasterService.success("You have approved this request");
            this.getFunds();
        });
    } else {
        this.toasterService.danger("Approval failed: Required data is missing.");
    }
}
isFundDataComplete(fund: any, fundtermid: any): boolean {
  console.log('fund:', fund);

  if (!fund || !fund.termaccounts) {
      return false;
  }

  const termaccount = fund.termaccounts.find((term: any) => term.incubatorfundterm_id === fundtermid);
  if (!termaccount) {
      console.log('No termaccount found for the given term ID');
      return false;
  }

  console.log('termaccount:', termaccount);
  console.log('amount_received:', termaccount.amount_received);
  console.log('amount_utilised:', termaccount.amount_utilised);
  console.log('sanction_documents:', termaccount.sanction_documents);
  console.log('utilisation_documents:', termaccount.utilisation_documents);
  console.log('approved_heads:', termaccount.approved_heads);
  console.log('approved_heads length:', termaccount.approved_heads?.length);
  const approveddata = fund.approved_heads.filter((item:any)=>item.incubatorfundterm_id==fundtermid);
  const utilizeddata = fund.utilised_heads.filter((item:any)=>item.incubatorfundterm_id==fundtermid);

  const allFieldsComplete = termaccount &&
      termaccount.amount_received != null &&
      termaccount.amount_utilised != null &&
      termaccount.sanction_documents != null &&
      termaccount.utilisation_documents != null &&
      fund.grant_amount != null &&
// fund.approved_heads && fund.approved_heads.length > 0 && fund.approved_heads.every((head: any) => head.approved_amount != null);
//       fund.utilised_heads && fund.utilised_heads.length > 0 && fund.utilised_heads.every((head: any) => head.amount_utilized!= null);
approveddata && approveddata.length > 0 && approveddata.every((head: any) => head.approved_amount != null) &&
utilizeddata && utilizeddata.length > 0 && utilizeddata.every((head: any) => head.amount_utilized!= null);

  console.log('allFieldsComplete:', allFieldsComplete);
  return allFieldsComplete;
}

  rejectTermFund(fundtermid:any,fund) {
    this.grantManagementService.rejectTermFund(fundtermid,fund,this.entity).subscribe((res:any) => {
        console.log("res ========> ", res);  
        this.toasterService.danger("You have rejected this request");
        this.getFunds() 
      })
  }
  // public loadScript(url: string) {
  //   const body = <HTMLDivElement> document.body;
  //   const script = document.createElement('script');
  //   script.innerHTML = '';
  //   script.src = url;
  //   script.async = false;
  //   script.defer = true;
  //   body.appendChild(script);
  // }


viewutilizedFund(fundid:any, fund:any, name: any){
  this.newstartupp = name
  this.grantManagementService.viewUtilizationFund(fundid,fund,this.entity).subscribe((res:any) => {
    console.log("res ========> ", res);  
    this.newone = res.results[0]
    console.log(this.newone)
  })
}
// downloadCSV() {
//   const csvData = this.prepareCSVData(this.funds);
//   const blob = new Blob([csvData], { type: 'text/csv' });
//   const url = window.URL.createObjectURL(blob);
//   const anchor = document.createElement('a');
//   anchor.href = url;
//   anchor.download = 'table_data.csv';
//   anchor.click();
//   window.URL.revokeObjectURL(url);
// }

// // Function to prepare CSV data
//  prepareCSVData(data) {
//   let csv = 'Agency Name,Startup Name,Grant Type,Group,Term Account Data,\n';
//   data.forEach((fund) => {
//     const termAccountData = this.getTermAccountData(fund.termaccounts, fund.approved_heads,fund.utilised_heads);
//     console.log('fund',fund)
//     csv += `"${fund.agency_name}","${fund.startup_name}","${fund.grant_type}","${fund.group}","${termAccountData}"\n`;
//   });
//   return csv;
// }

// // Function to concatenate term account data
//  getTermAccountData(termaccounts, approved_heads,utilised_heads,) {
//   // console.log('xxxxxxxxxx', utilised_heads)
//   let termAccountData = '';
//   termaccounts.forEach((term, index) => {
//     const incfundtermId = term.incubatorfundterm_id;
//     const approvedheads = approved_heads.length > 0 ? approved_heads.filter((item:any)=> item.incubatorfundterm_id == incfundtermId ) : approved_heads; 
//     const utilizedheads = utilised_heads.length > 0 ? utilised_heads.filter((item:any)=> item.incubatorfundterm_id == incfundtermId ) : utilised_heads; 
// // console.log('incfundtermId, approvedheads, utilizedheads', incfundtermId, approvedheads, utilizedheads)
//     const approvedHeadsData = approvedheads.map
//     (head => `${head.head}: ${head.approved_amount}`).join(', ');

//     const utilised_headsData = utilizedheads.map(head=>`${head.head}:${head.amount_utilized}`).join(', ')

//     termAccountData += `Quarter ${index + 1}: 
//       Sanction No. - ${term.sanction_no},
//       Sanction Date - ${term.amount_sanction_date.substring(0, 10)},
//       Amount Approved - ${term.amount_received},
//       Amount Utilized - ${term.amount_utilised}, 
//       Amount Refunded - ${term.amount_refunded}, 
//       Approved Heads - ${approvedHeadsData},
//       Utilized Heads -${utilised_headsData}\n`;
//   });
//   return termAccountData;
// }
downloadCSV() {
  const newEntryYear = sessionStorage.getItem('range')
  const excelData = this.prepareExcelData(this.funds);
  const worksheet = XLSX.utils.json_to_sheet(excelData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, newEntryYear);

  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  

  // Convert the ArrayBuffer to a Blob and trigger the download
  const blob = new Blob([wbout], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = 'table_data.xlsx';
  anchor.click();
  window.URL.revokeObjectURL(url);
}

// Function to prepare Excel data
prepareExcelData(data) {
  return data.map((fund) => {
    const termAccountData = this.getTermAccountData(fund.termaccounts, fund.approved_heads, fund.utilised_heads);
    return {
      'Agency Name': fund.agency_name,
      'Startup Name': fund.startup_name,
      'Grant Type': fund.grant_type,
      'Group': fund.group,
      'Term Account Data': termAccountData
    };
  });
}

// Function to concatenate term account data
getTermAccountData(termaccounts, approved_heads, utilised_heads) {
  let termAccountData = '';
  termaccounts.forEach((term, index) => {
    const incfundtermId = term.incubatorfundterm_id;
    const approvedheads = approved_heads.length > 0 ? approved_heads.filter((item) => item.incubatorfundterm_id == incfundtermId) : approved_heads;
    const utilizedheads = utilised_heads.length > 0 ? utilised_heads.filter((item) => item.incubatorfundterm_id == incfundtermId) : utilised_heads;

    const approvedHeadsData = approvedheads.map(head => `${head.head}: ${head.approved_amount}`).join(', ');
    const utilised_headsData = utilizedheads.map(head => `${head.head}:${head.amount_utilized}`).join(', ');

    termAccountData += `Quarter ${index + 1}: 
      Sanction No. - ${term.sanction_no},
      Sanction Date - ${term.amount_sanction_date.substring(0, 10)},
      Amount Approved - ${term.amount_received},
      Amount Utilized - ${term.amount_utilised}, 
      Amount Refunded - ${term.amount_refunded}, 
      Approved Heads - ${approvedHeadsData},
      Utilized Heads - ${utilised_headsData}\n`;
  });
  return termAccountData;
}

//pagination
onPageChange(page:number):void{
  this.p =page;
  this.router.navigate([],{
    relativeTo:this._route,
    queryParams:{page:page},
    queryParamsHandling:'merge'

  })
  this.loadData(page)
}
private loadData(page:number):void{
  console.log('Loading data for page', page)
}
}
