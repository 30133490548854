<!-- <div class="container py-3">
  <ngx-gia-utilized-table></ngx-gia-utilized-table>
</div> -->
<div class="container bg-white p-4 ">
    <h2 class="text-center eir-grant">Financial Years​</h2>
    <div class="table-responsive">
    <!-- <table class="table  text-center table-bordered">
        <thead>
            <colgroup span="8"></colgroup>
            <tr>
                <th rowspan="2" >S.N</th>
                <th rowspan="2 ">State/UT​​</th>
                <th  colspan="8" scope="colgroup">Financial Year​​​</th>
                

           
            </tr>
            <tr>
                
                <th>2019-20​</th>
                <th>2020-21​</th>
                <th>2021-22​</th>
                <th>2022-23​​</th>

                <th>2023-24​​</th>

                <th>2024-25​</th>

                <th>2025-26​​</th>
                <th>TOTAL​</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Assam-this is downloadble</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
          </tr>
          <tr>
            <tr>
                <td>1</td>
                <td>Andhra-this is downloadble</td>
           <td>fsdfds</td>
           <td>fsdfds</td>
           <td>fsdfds</td>
           <td>fsdfds</td>
           <td>fsdfds</td>
           <td>fsdfds</td>
           <td>fsdfds</td>
           <td>fsdfds</td>
              </tr>
              <tr>
            
        <td colspan="2">Total</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
       <td>fsdfds</td>
       
       <td>fsdfds</td>
          </tr>
        </tbody>
    </table> -->
    <div class="d-flex justify-content-end p-3">
      <button type="button" class="btn btn-primary text-end" (click)="downloadCSV()">Download</button>
    </div>
    <div class="table-container">
    <table class="table">
        <tr>
          <th>State</th>
          <th *ngFor="let year of years">{{ year }}</th>
        </tr>
        <ng-container *ngFor="let state of states">
          <tr>
            <td>{{ state }}</td>
            <td *ngFor="let year of years">
              {{ getData(year, state) || '-' }}
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
</div>
</div>
