import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { NbThemeService } from "@nebular/theme";
import { takeWhile } from "rxjs/operators";
import { RoleService } from "../../services/role.service";
import { DashboardService } from "../../services/dashboard.service";
import { Router } from "@angular/router";
import { SchemeService } from "../../services/scheme/scheme.service";
import { IncubatorService } from "../../services/incubator.service";
import { GrantManagementService } from "../../services/grant-management/grant-management.service";
declare var Highcharts:any;

interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}

@Component({
  selector: "ngx-dashboard",
  styleUrls: ["./dashboard.component.scss"],
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnDestroy, OnInit , AfterViewInit {
  private alive = true;
  super: boolean;
  financemanager: boolean;
  projectmanager: boolean;
  socialmanager: boolean;
  currentYear:any;
  heads:any = [];
  sum:any = [];
  // solarValue: number;
  // lightCard: CardSettings = {
  //   title: 'Light',
  //   iconClass: 'nb-lightbulb',
  //   type: 'primary',
  // };
  // rollerShadesCard: CardSettings = {
  //   title: 'Roller Shades',
  //   iconClass: 'nb-roller-shades',
  //   type: 'success',
  // };
  // wirelessAudioCard: CardSettings = {
  //   title: 'Wireless Audio',
  //   iconClass: 'nb-audio',
  //   type: 'info',
  // };
  // coffeeMakerCard: CardSettings = {
  //   title: 'Coffee Maker',
  //   iconClass: 'nb-coffee-maker',
  //   type: 'warning',
  // };

  // statusCards: string;

  // commonStatusCardsSet: CardSettings[] = [
  //   this.lightCard,
  //   this.rollerShadesCard,
  //   this.wirelessAudioCard,
  //   this.coffeeMakerCard,
  // ];

  // statusCardsByThemes: {
  //   default: CardSettings[];
  //   cosmic: CardSettings[];
  //   corporate: CardSettings[];
  //   dark: CardSettings[];
  //   'material-dark': CardSettings[];
  //   'material-light': CardSettings[];
  // } = {
  //   default: this.commonStatusCardsSet,
  //   cosmic: this.commonStatusCardsSet,
  //   corporate: [
  //     {
  //       ...this.lightCard,
  //       type: 'warning',
  //     },
  //     {
  //       ...this.rollerShadesCard,
  //       type: 'primary',
  //     },
  //     {
  //       ...this.wirelessAudioCard,
  //       type: 'danger',
  //     },
  //     {
  //       ...this.coffeeMakerCard,
  //       type: 'info',
  //     },
  //   ],
  //   dark: this.commonStatusCardsSet,
  //   'material-dark': this.commonStatusCardsSet,
  //   'material-light': this.commonStatusCardsSet,
  // };
  data: any;
  news: any;
  press: any;
  faqs:any;
  media:any;
  userevents:any;
  flagshipevents:any;
  challenges:any; 
  schemeResponse = [];
  options: any[] = [];
  scheme: any;
  challegeView: boolean = false;
  approvedtotal:number = 0;
  releasedtotal:number = 0;
  pendingtotal:number = 0;

  constructor(
    private themeService: NbThemeService,
    private roleService: RoleService,
    private dashboardService: DashboardService,
    private router: Router,
    private schemeService: SchemeService,
    private incubatorService: IncubatorService,
    private  grantManagementService:GrantManagementService
  ) {
    // this.themeService.getJsTheme()
    //   .pipe(takeWhile(() => this.alive))
    //   .subscribe(theme => {
    //     this.statusCards = this.statusCardsByThemes[theme.name];
    // });
  }

  ngAfterViewInit(){
    // this.getAllHeads().then(()=>{
    //   this.bar();
    // })
  }

  ngOnInit(): void {
    this.roleService.getIndividualUserData().subscribe((response: any) => {
      console.log("login response", response);
      let responseData = this.roleService.decodeBase64(response.data);
      let res = JSON.parse(responseData)
      console.log("res", res.results[0].role_id);
      sessionStorage.setItem("role_id", res.results[0].role_id);
      sessionStorage.setItem("role_name", res.results[0].name);
      if (sessionStorage.getItem("role_name") != null) {
        if (sessionStorage.getItem("role_name") == "Finance Manager") {
          this.financemanager = true;
          this.super = false;
          this.projectmanager = false;
          this.socialmanager = false;
        } else if (sessionStorage.getItem("role_name") == "Super Admin") {
          this.super = true;
          this.financemanager = false;
          this.projectmanager = false;
          this.socialmanager = false;
        } else if (sessionStorage.getItem("role_name") == "Project Manager") {
          this.super = false;
          this.financemanager = false;
          this.projectmanager = true;
          this.socialmanager = false;
        } else if (sessionStorage.getItem("role_name") == "Social Manager") {
          this.socialmanager = true;
          this.financemanager = false;
          this.super = false;
          this.projectmanager = false;
        } else {
          // this.financemanager = false;
          // this.super = false;
        }
      }
      this.schemeService.getAllSchemes().subscribe((response: any) => {
        console.log("schemes response::::", response);
        this.schemeResponse = response.results;
        this.calculateAllSums(this.schemeResponse);
      });
    });
    this.getDashboardCounts();
    this.getpressnewsCounts();
    this.getAllSchemes();

    localStorage.removeItem('viewback');
    localStorage.removeItem('filterArray');
    localStorage.removeItem('countdata')
  }

  getDashboardCounts() {
    this.dashboardService.getdashboardData().subscribe((res: any) => {
      console.log('count_res',res);
      this.data = res;
      console.log(this.data);
      console.log(this.data.challenge_counts);
      // this.scheme = this.data.scheme_counts.startups_granted[0]
      console.log(
        "schme dasssss",
        this.data.scheme_counts.startups_granted[0]?.count
      );
    });
  }

  getpressnewsCounts() {
    this.dashboardService.getPressNewsData().subscribe((res: any) => {
      this.news = res.news_counts;
      this.press = res.pressrelease_counts;
      this.faqs = res.faq_counts;
      this.media = res.media_counts;
      this.userevents = res.userevents_count,
      this.flagshipevents = res.flagshipevents_count,
      this.challenges = res.challenges_count
      console.log("dashPRess::", this.news, this.press);
    });
  }

  getAllSchemes() {
    this.incubatorService.getschemeList().subscribe((res: any) => {
      this.options = res.results;
      console.log("SchemeLISTDaash:", this.options);
    });
  }

  async loadpageonce() {
    return new Promise<void>((resolve, reject) => {
      if (!sessionStorage.getItem("foo")) {
        sessionStorage.setItem("foo", "no reload");
        location.reload();
      } else {
        sessionStorage.removeItem("foo");
      }
      resolve();
    });
  }
  gotostartup(value) {
    console.log("value +++", value);
    // const filterBy = 'PENDING'; // the value to filter by
    const filterBy = value; // the value to filter by
    this.router.navigate(["admin/startups"], {
      queryParams: { filter: filterBy },
    });
  }

  gotomentor(value) {
    // const filterBy = 'PENDING'; // the value to filter by
    const filterBy = value; // the value to filter by
    this.router.navigate(["admin/mentors"], {
      queryParams: { filter: filterBy },
    });
  }
  gotoincubator(value) {
    console.log("value", value);
    // const filterBy = 'PENDING'; // the value to filter by
    const filterBy = value; // the value to filter by
    this.router.navigate(["admin/incubator"], {
      queryParams: { filter: filterBy },
    });
  }
  gotoAccelerator(value) {
    console.log("value", value);
    // const filterBy = 'PENDING'; // the value to filter by
    const filterBy = value; // the value to filter by
    this.router.navigate(["admin/accelerator"], {
      queryParams: { filter: filterBy },
    });
  }
  gotoCoe(value) {
    console.log("value", value);
    // const filterBy = 'PENDING'; // the value to filter by
    const filterBy = value; // the value to filter by
    this.router.navigate(["admin/coe"], {
      queryParams: { filter: filterBy },
    });
  }
  gotochallenge() {
    this.router.navigate(["admin/addchallenge"]);
  }
  showChallenge() {
    // this.challegeView=true;
    this.challegeView = !this.challegeView;
  }
  gotoLabsandFacilities(facility: string, entity: string, status: string) {
    const facility1 = facility;
    const entity1 = entity;
    const status1 = status;
    this.router.navigate(["admin/facilities/status"], {
      queryParams: { facility: facility1, entity: entity1, status: status1 },
    });
  }
  ngOnDestroy() {
    //this.schemeResponse.unsubscribe();
  }

  calculateAllSums(schemeResponse:any) {
    for (const data of schemeResponse) {
      this.approvedtotal += Number(data.stats.approved);
      this.releasedtotal += Number(data.stats.received);
      this.pendingtotal += Number(data.stats.utilized);
    }
  }

  bar(){
    document.getElementById('bar').classList.remove('d-none');
    Highcharts.chart('bar', {
      chart: {
        type: 'column',
        width:1200
      },
      title: {
        text: 'Heads'
      },
      xAxis: {
        categories: [...this.heads]
      },
      yAxis: {
        title: {
          text: 'Number'
        }
      },
      plotOptions: {
        column: {
            dataLabels: {
                enabled: true,
                format: '{point.y:.0f}' // Display y-value with no decimal places
            }
        }
    },
      series: [{
        name: 'Heads',
        data: [...this.sum],
        point : {
          events: {
            click:(event:any)=>{
              console.log('e_heads',event);
              this.router.navigate(['/admin/analytics'])
            }
          }
        }
      }]
    });
    document.getElementById('area').className+=' d-none'
  }

 async getAllHeads(){
   return new Promise((resolve,reject)=>{
    let data = {

        "query_entity": "INCUBATOR"
      
      }
  this.dashboardService.getAllHeads(data).subscribe((res:any)=>{
      console.log('res_heads_dash',res);
      let heads = res.heads;
      for(var i = 0 ; i < heads.length ; i++){
        this.heads.push(heads[i].head_name);
        this.sum.push(heads[i].sum);
      }

      console.log('heads',this.heads);
      console.log('sum',this.sum);
      resolve(this.heads);
  })
   })

  }

  newfinancialdata(id:any){
    this.router.navigate([`/admin/grant-management/${id}/home`])
  //   .then(()=>{
  //     this.currentYear = (new Date()).getFullYear();
  //     sessionStorage.setItem('start_date',`${this.currentYear}-04-01`)
  //     sessionStorage.setItem('end_date',`${this.currentYear+1}-03-31`)
  //     sessionStorage.setItem('range',`${this.currentYear}-${this.currentYear+1}`)
  //     setTimeout(()=>{
  //       this.grantManagementService.setStartDate(sessionStorage.getItem('start_date'));
  //       this.grantManagementService.setEndDate(sessionStorage.getItem('end_date'));
  //     },1000);
  //  } )
    }
}
