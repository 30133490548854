import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { ChallengeService } from '../../../services/challenge.service';
import { EditionService } from '../../../services/edition.service';
import { find, get, pull } from 'lodash';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatePipe } from '@angular/common';
// import { ExportToCsv } from 'export-to-csv';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';
import {ExcelService} from '../../../services/excel.service';

const headers= new HttpHeaders()
.set('Authorization', `Bearer ${sessionStorage.getItem('admintoken')}`);

declare var $:any;


@Component({
  selector: 'ngx-add-challenge',
  templateUrl: './add-challenge.component.html',
  styleUrls: ['./add-challenge.component.scss']
})
export class AddChallengeComponent implements OnInit , AfterViewInit {
  @ViewChild("UploadFileInput", { static: false }) uploadFileInput: ElementRef;
  @ViewChild("UploadFileInput_ext", { static: false }) uploadFileInput_ext: ElementRef;
  tableData_challenge:any;
  tableData_edition:any;
  challengeForm!: FormGroup;
  editionForm!: FormGroup;
  editionForm_ext!: FormGroup;
  editMode:boolean=false;
  selectedItemNgModel;
  showEdition:boolean=false;
  firstForm!: FormGroup;
  step = 1;
  visible:boolean = false;
  showAppForm:boolean=false;
  showform:boolean=false;
  selectedItemFormControl: FormGroup;
  details: any;
  inValidMessage: boolean;
  challengename:string;
  challengeid:string;
  submitted = false;
  submitted_edition = false;
  fileInputLabel: string;
  showapplication!:boolean;
  mindate:any;
  mindate_ext:any;
  isValidFormSubmitted = null;
  evaluator:boolean;
  filetypeerr = false;
  filesizeerr = false;
  filetypeerr_ext = false;
  filesizeerr_ext = false;
  selectedindex = 0;
  newedition = false;
  // roleList:any = [
  //   {value:'Project Manager',title:"Project Manager"},
  //   {value:'Social Manager',title:"Social Manager"},
  //   {value:'Finance Manager',title:"Finance Manager"},
  // ];
  options: any[];
  selectedValue: string;
  data: any;
  currentStep = 1;
  csvdata:any;
  form: any;
  addForm: any;
  externalevaluators:any;
  submitform = false;
  editionrounds: any;
  stageid: any;
  evaluators:any=[];
  showall = false;
  disabled = true;
  startdate: any;
  startdate_ext: any;
  mindate_end: string;
  mindate_end_ext: string;
  submittedapplications: any;
  editionselected = false;
  selectevaluators:any;
  selectedevaluators = [];
  appllications_list = [];
  disabled_m = true;
  showassignevaluators = false;
  showchallenge:boolean;
  editionclicked: boolean = false;
  applications: any;
  search: boolean = false; 
  selectevaluators_search: any[];
  search_selected: boolean = false;   
  selectedevaluators_search: any[];
  editioncreated = false;
  challengename_session: string;
  edition_name: string;
  fileInputLabel_ext: any;
  submittedapplications_export: any;
  

  constructor( 
    private fb: FormBuilder,
    private http: HttpClient,
    private challengeService: ChallengeService,
    private editionService: EditionService,
    private toastrService: NbToastrService,
    private router: Router,
    private datePipe: DatePipe,
    private excelService: ExcelService
    ) { 
  
      // this.csvdata = [
      //   {
      //     company:'test',
      //     location:'test',
      //     industry: 'test',
      //     date: '2023-03-20',
      //     documents: 'test',
      //     paymentstatus: 'paid',
      //     actions: 'approved'
      //   },
      //   {
      //     company:'test1',
      //     location:'test1',
      //     industry: 'test1',
      //     date: '2023-03-20',
      //     documents: 'test1',
      //     paymentstatus: 'not paid',
      //     actions: 'rejected'
      //   },
      //   {
      //     company:'test2',
      //     location:'test2',
      //     industry: 'test2',
      //     date: '2023-03-20',
      //     documents: 'test2',
      //     paymentstatus: 'in process',
      //     actions: 'approved'
      //   }
      // ]
    }
  
  ngOnInit(): void {
    this.editionclicked = false;
    // if(sessionStorage.getItem('selected_tab')!=null){
      sessionStorage.setItem('edition_changed','false');
      if(sessionStorage.getItem('challengename')!=null){
        this.challengename_session = sessionStorage.getItem('challengename');
        this.challengeService.getFormbyEditionid(sessionStorage.getItem('editionid')).subscribe((res)=>{
          console.log('res1245',res.results);
          this.applications = res.results;
          this.editionclicked = true;
        })
        this.editionService.getEditionsByChallenge(sessionStorage.getItem('challengeid')).subscribe((res:any)=>{
          console.log('editiondatafromsession',res);
          this.tableData_edition = res.results;
          this.showAppForm=true;
    
        })
      }
  
      if(sessionStorage.getItem('edition_name')!=null){
        this.edition_name = sessionStorage.getItem('edition_name');
        this.challengeService.getFormbyEditionid(sessionStorage.getItem('editionid')).subscribe((res)=>{
          console.log('res1245',res.results);
          this.applications = res.results;
          this.editionclicked = true;
        })
      }
    // }
    console.log('edition_changed',sessionStorage.getItem('edition_changed'));
    if(sessionStorage.getItem('edition_changed')!=null){
      if(sessionStorage.getItem('edition_changed')=='false'){
        this.challengeService.getFormbyEditionid(sessionStorage.getItem('editionid')).subscribe((res)=>{
          console.log('res1245',res.results);
          this.applications = res.results;
        })
        //this.editionclicked = true;
        this.showform = true;
        if(sessionStorage.getItem('role_name')=='EXTERNAL_EVALUATOR'){
          console.log('present here');
          this.challengeService.getEvaluatorRoundsByEditionId(sessionStorage.getItem('editionid')).subscribe((res:any)=>{
            console.log('res_eva_rounds',res.results);
            this.editionrounds = res.results;
            console.log('res_eva______1234',this.editionrounds);
            document.getElementById('screening').classList.remove('activestage');
          })
        }
        else{
          console.log('it is present here');
          
          this.challengeService.getEditionRounds(sessionStorage.getItem('editionid')).subscribe((res:any)=>{
            console.log('editionrounds1234',res);
            // document.getElementById('screening').classList.remove('activestage');
            this.editionrounds = res.results[0];
            this.csvdata = res.results[0];
            console.log('edition rounds',this.editionrounds);
            this.showall = true;
            document.getElementById('screening').classList.remove('activestage');
            if(sessionStorage.getItem('selected_tab')=='SCREENING' || sessionStorage.getItem('selected_tab')=='ON_HOLD' || sessionStorage.getItem('selected_tab')=='REJECTED'){
              this.showassignevaluators = false;
              if(document.getElementById('screening')!=null){
                document.getElementById('screening').className+=' activestage';
              }
            }
  
            else if(sessionStorage.getItem('selected_tab')=='SELECTED'){
              if(document.getElementById('selected')!=null){
                document.getElementById('selected').className+=' activestage';
                this.showassignevaluators = false;
              }
            }
         
          },(err)=>{
            this.showall = false;
          })
        }

        if(sessionStorage.getItem('selected_tab')!=null){
          this.editionclicked = true; 
          
          if(sessionStorage.getItem('selected_tab')=='SELECTED'){
            let states = ['SELECTED'];
            this.selectedindex = -1;
            this.getApplications(sessionStorage.getItem('editionid'),states);
            this.showassignevaluators = false;
          }
    
          else if(sessionStorage.getItem('selected_tab')=='SCREENING' || sessionStorage.getItem('selected_tab')=='ON_HOLD' || sessionStorage.getItem('selected_tab')=='REJECTED'){
         
            this.selectedindex = -1;
            let states = ['SCREENING', 'ON_HOLD', 'REJECTED']
            this.getApplications(sessionStorage.getItem('editionid'),states);
            this.showassignevaluators = false;
          }
    
          else if(sessionStorage.getItem('selected_tab')=='IN_ROUNDS'){
            if(sessionStorage.getItem('stageindex')){
              this.selectedindex = +sessionStorage.getItem('stageindex');
            }
            //this.disabled_m = false;
            this.showapplication = true;
            this.getApplicationsByRoundId(sessionStorage.getItem('stageid'));
            this.showassignevaluators = true;
          }
      
        }
      }
      else{
        console.log('new edition selected');
      }
    }
  
    console.log('test')
    this.mindate = this.minstartdate();
    this.mindate_ext = this.minstartdate_ext();
    console.log('mindate',this.mindate);
    this.listExternalEvaluators();
    this.listExternalEvaluators1();
    this.form = this.fb.group({
      address: this.fb.array([],Validators.required)
    })

    this.addForm= this.fb.group(
      {
        criteria : this.fb.array([])
    //   roundsName: [
    //   ""
    //   // Validators.required,
    //   // Validators.minLength(3),
    //   // Validators.maxLength(50),
    //   ],
    //   weightage: ["" 
    //   //Validators.required
    // ],
    //   roundsDescription: [""
    //   // [Validators.required]
    // ],
      });
  
    this.challengeForm = this.fb.group({
      challengeName: ['', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      // domain:[''],
      // sector:[''],
      description: ['', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)] ],
    });

    this.editionForm = this.fb.group({
      editionName: ['',[Validators.required]],
      editiondesc: ['',[Validators.required] ],
      startRange:['',Validators.required],
      endRange:['',Validators.required],
      uploadedImage: ['',Validators.required]
    });

    this.editionForm_ext = this.fb.group({
      editionName_ext: ['',[Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      editiondesc_ext: ['',[Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)] ],
      startRange_ext:['',Validators.required],
      endRange_ext:['',Validators.required],
      application_url:['',Validators.required],
      uploadedImage_ext: ['',Validators.required]
    });

    this.allChallenges();
    if(sessionStorage.getItem('role_name')=="EXTERNAL_EVALUATOR"){
      this.getevaluatoreditions();
      this.evaluator = true
    }
    else {
      this.showEdition = false;
      this.showchallenge = true;
      this.evaluator = false;
    }
    //this.getEditions();
  }

  createExternalEdition(){


    if(!this.editionForm_ext.valid){

    }
    else{
      console.log('edition_valid');
      const formData = new FormData();
      formData.append("file",this.editionForm_ext.get("uploadedImage_ext").value);
      console.log('formdata_image',formData);
      this.challengeService.uploadFile(formData,'create-edition').subscribe((response:any)=>{
        console.log('image response',response.results[0].url);
        // if (response.statusCode === 200) {   
          this.uploadFileInput.nativeElement.value = "";
          this.fileInputLabel = undefined;
        // }
        let data = {
          "name": this.editionForm_ext.controls.editionName_ext.value,
          "description": this.editionForm_ext.controls.editiondesc_ext.value,
          "start_date": this.formatDate(this.editionForm_ext.controls.startRange_ext.value),
          "end_date": this.formatDate(this.editionForm_ext.controls.endRange_ext.value),
          "application_url":this.editionForm_ext.controls.application_url.value,
          "profile_image": [
            {
              "file_id":response.results[0].file_id,
              "url":response.results[0].url,
              "file_path":response.results[0].path
            }
          ]
          
        }
      console.log('data edition', data);
        this.editionService
     
        .createEditions_ext(data)
        .pipe()
        .subscribe(
          (data) => {
            console.log('data',this.data)
            this.toastrService.success("Data Added Successfully!");
            $('#exampleModalCenter_ext').modal('hide');
          },
          (error) => {
            this.inValidMessage = true;
            this.toastrService.warning("An edition with same name already exists");
          }
        );
      })
  
        

    } 
  }

  show_test(event:any){
    console.log('event_challenge',event.target.value)
  }
  searchselect(event:any){
    if(event.target.value.length!=0){
      this.search = true;
      console.log('event value',event.target.value)
      console.log('evaluators',this.selectevaluators);
      this.selectevaluators_search = this.selectevaluators.filter((item:any)=>{
        console.log('first name',item);
        return item.first_name.toLowerCase().includes(event.target.value.toLowerCase());
      })
      console.log('select evaluators',this.selectevaluators_search);
    }

    else{
      this.search = false;
    }

  }

  searchselected(event:any){
    if(event.target.value.length!=0){
      this.search_selected = true;
      console.log('event value',event.target.value)
      console.log('evaluators',this.selectedevaluators);
      this.selectedevaluators_search = this.selectedevaluators.filter((item:any)=>{
        console.log('first name',item);
        return item.first_name.includes(event.target.value);
      })
      console.log('select evaluators',this.selectedevaluators_search);
    }

    else{
      this.search_selected = false;
    }

  }
  addtolist(event:any,data:any){
    if(event.target.checked == true){
      console.log('here1234');
      this.appllications_list.push(data)
      this.disabled_m = false;
    }
    else{
      for(var i = 0 ; i < this.appllications_list.length ; i ++){
        if(data.id == this.appllications_list[i].id){
          this.appllications_list.splice(i,1);  
        }
      }
      console.log('applications_length',this.appllications_list);
      if(this.appllications_list.length == 0 ){
        console.log('here');
        this.disabled_m = true;
      }
    }

    console.log('applicationlist',this.appllications_list);
  }
  ngAfterViewInit(){
    // if(!this.evaluator){
    //   document.querySelector('#create_edition button').setAttribute('disabled','true');
    // }
    
    // document.querySelector('#addEdition').setAttribute('class','d-none');
    $('.dropdown-menu').click(function(e) {
      e.stopPropagation();
      if ($(e.target).is('[data-toggle=modal]')) {
          $($(e.target).data('target')).modal()
      }
  });
  }
  get f(){
    return this.challengeForm.controls;
  }
  

  get g(){
    return this.editionForm.controls;
  }

   allChallenges(){
    this.challengeService
      .getAllChallenges()
      .pipe()
      .subscribe(
        (data) => {
          this.tableData_challenge = data.results;
          console.log("chanllenge All::", this.tableData_challenge);
        },
        (error) => {
          this.inValidMessage = true;
        }
      );
   }
   getEditions(){
    this.editionService
    .getAllEdition()
    .pipe()
    .subscribe(
      (data) => {
        this.tableData_edition = data.results;
        console.log("Editions All::", this.tableData_edition);
      },
      (error) => {
        this.inValidMessage = true;
      }
    );
   }

   criteria(id:any,i:any){
    sessionStorage.setItem('selected_tab','IN_ROUNDS');
    this.appllications_list = [];
    if(!this.evaluator){
      document.getElementById('screening').classList.remove('activestage');
      document.getElementById('selected').classList.remove('activestage');
    }
 
    this.stageid = id;
    this.selectedindex = i;
    sessionStorage.setItem('stageindex',i);
    sessionStorage.setItem('stageid',id);
    this.disabled_m = true;
    this.showapplication = true;
    this.getApplicationsByRoundId(id);
    // if(sessionStorage.getItem('role_name')=='EXTERNAL_EVALUATOR'){
    //   this.evaluator = true;
    //   this.challengeService.getEvaluatorApplicationsByRoundId(id).subscribe((res:any)=>{
    //     console.log('res_eva_apps_1234',res);
    //     this.submittedapplications = res.results;
    //     console.log('apps_eva',this.submittedapplications);
    //     this.showapplication = true;
    //   })
    // }
    // else{
    //   this.evaluator = false;
    //   this.disabled = false;
    //   this.showassignevaluators = true;
    //   this.challengeService.listapplicationsbyroundid(sessionStorage.getItem('editionid'),id).subscribe((res:any)=>{
    //     console.log('res_applications_by_round',res);
    //     this.submittedapplications = res.results
    //   })
    // }
    
    // this.router.navigate([`/admin/rounds/criteria/${this.stageid}`]).then(()=>{
    //   location.reload();
    // })
  }
   
  getApplicationsByRoundId(id:any){
    if(sessionStorage.getItem('role_name')=='EXTERNAL_EVALUATOR'){
      this.evaluator = true;
      this.challengeService.getEvaluatorApplicationsByRoundId(id).subscribe((res:any)=>{
        console.log('res_eva_apps_1234',res);
        this.submittedapplications = res.results;
        console.log('apps_eva',this.submittedapplications);
        this.showapplication = true;
        document.getElementById('screening').classList.remove('activestage');
      })
    }
    else{
      this.evaluator = false;
      this.disabled = false;
      this.showassignevaluators = true;
      this.challengeService.listapplicationsbyroundid(sessionStorage.getItem('editionid'),id).subscribe((res:any)=>{
        console.log('res_applications_by_round',res);
        //this.disabled_m = true;
        this.submittedapplications = res.results
        document.getElementById('screening').classList.remove('activestage');
      })
    }
  }
   createChallenge(){
 
     this.submitted = true;
     if(!this.challengeForm.valid){

     }
     else {
      let data = {
        "name": this.challengeForm.controls.challengeName.value,
        "domain": [
            'test'
        ],
        "sector": [
            'test'
        ],
        "description": this.challengeForm.controls.description.value
    }
      console.log('data',data);
      this.challengeService
      .createChallenge(data)
      .pipe()
      .subscribe(
        (data) => {
          this.data = data;
          this.challengeForm.reset();
          this.submitted = false;
          this.toastrService.success("Data Added Successfully!");
          $('#challenge').modal('hide');
          this.allChallenges();
        },
        (error) => {
          this.inValidMessage = true;
          console.error('error',error);
          this.toastrService.warning(error.error.detail);
        }
      );
     }

   }

   createEdition1(){
    sessionStorage.setItem('start_range',this.editionForm.controls.startRange.value)
    sessionStorage.setItem('end_range',this.editionForm.controls.endRange.value)
    let data = {
      "name": this.editionForm.controls.editionName.value,
      "description": this.editionForm.controls.editiondesc.value,
      "start_date": this.formatDate(this.editionForm.controls.startRange.value),
      "end_date": this.formatDate(this.editionForm.controls.endRange.value),
      
    }
  console.log('data edition', data);
    this.editionService
 
    .createEditions(this.challengeid,data)
    .pipe()
    .subscribe(
      (data) => {
        this.data = data;
        this.editionForm.reset();
        this.submitted_edition = false;
        this.toastrService.success("Data Added Successfully!");
      },
      (error) => {
        this.inValidMessage = true;
        this.toastrService.warning("Unable to Add records!");
      }
    );
   }
   createEdition(){
    sessionStorage.setItem('start_range',this.editionForm.controls.startRange.value)
    sessionStorage.setItem('end_range',this.editionForm.controls.endRange.value)
    this.submitted_edition = true;

    if(!this.editionForm.valid){

    }
    else{
      console.log('edition_valid');
      const formData = new FormData();
      formData.append("file",this.editionForm.get("uploadedImage").value);
      console.log('formdata_image',formData);
      this.challengeService.uploadFile(formData,'create-edition').subscribe((response:any)=>{
        console.log('image response',response.results[0].url);
        // if (response.statusCode === 200) {   
          this.uploadFileInput.nativeElement.value = "";
          this.fileInputLabel = undefined;
        // }
        let data = {
          "name": this.editionForm.controls.editionName.value,
          "description": this.editionForm.controls.editiondesc.value,
          "start_date": this.formatDate(this.editionForm.controls.startRange.value),
          "end_date": this.formatDate(this.editionForm.controls.endRange.value),
          "profile_image": [
            {
              "file_id":response.results[0].file_id,
              "url":response.results[0].url,
              "file_path":response.results[0].path
            }
          ]
          
        }
      console.log('data edition', data);
        this.editionService
     
        .createEditions(sessionStorage.getItem('challengeid'),data)
        .pipe()
        .subscribe(
          (data) => {
            this.data = data;
            this.editionForm.reset();
            this.submitted_edition = false;
            this.newedition = true;
            this.editionclicked = false;
            sessionStorage.removeItem('challengename');
            sessionStorage.removeItem('edition_name');
            this.toastrService.success("Data Added Successfully!");
            $('#exampleModalCenter').modal('hide');
            this.showEdition = false;
            this.getEditions();
            this.editioncreated = true;
            this.editionclicked = false;
            sessionStorage.removeItem('edition_changed')
            this.challengename_session = '';
            // location.reload();
          },
          (error) => {
            this.inValidMessage = true;
            this.toastrService.warning("An edition with same name already exists");
          }
        );
      })
      // this.http.post<any>(`${environment.apiurl}/files?intent=create-edition`,formData,{'headers':headers}).subscribe(
      //   (response) => {
      //     console.log('image response',response.results[0].url);
          
      //       this.uploadFileInput.nativeElement.value = "";
      //       this.fileInputLabel = undefined;
         
      //     let data = {
      //       "name": this.editionForm.controls.editionName.value,
      //       "description": this.editionForm.controls.editiondesc.value,
      //       "start_date": this.formatDate(this.editionForm.controls.startRange.value),
      //       "end_date": this.formatDate(this.editionForm.controls.endRange.value),
      //       "profile_image": [
      //         {
      //           "file_id":response.results[0].file_id,
      //           "url":response.results[0].url,
      //           "file_path":response.results[0].path
      //         }
      //       ]
            
      //     }
      //   console.log('data edition', data);
      //     this.editionService
       
      //     .createEditions(this.challengeid,data)
      //     .pipe()
      //     .subscribe(
      //       (data) => {
      //         this.data = data;
      //         this.editionForm.reset();
      //         this.submitted_edition = false;
      //         this.toastrService.success("Data Added Successfully!");
      //         $('#exampleModalCenter').modal('hide');
      //         this.showEdition = false;
      //       },
      //       (error) => {
      //         this.inValidMessage = true;
      //         this.toastrService.warning("Unable to Add records!");
      //       }
      //     );
      //   });
        

    } 

   }

   
   getStatus(status:any){
    sessionStorage.setItem('selected_tab',status);
    sessionStorage.setItem('edition_changed','false');
    // this.editionclicked = true;
   }

   formatDate(date){
    console.log('date_formatted',date);
    let myDate=new Date(date);
    let formattedDate=this.datePipe.transform(myDate,'yyyy-MM-dd hh:mm:ss');
    return formattedDate;
   }

   //checkAllCheckBox(ev) { // Angular 9
   checkAllCheckBox(ev: any) { // Angular 13
		// this.products.forEach(x => x.checked = ev.target.checked)
	}

	isAllCheckBoxChecked() {
		// return this.products.every(p => p.checked);
	}
  
  cancelData(){
    this.editionForm.reset();
  }
 
  show(event:any){
    this.challengeid = event.target.value;
    // console.log('editionvalue',document.getElementById('editionvalue'));
    sessionStorage.setItem('challengeid',this.challengeid);
    let challengedata = this.tableData_challenge.find((item)=>{
      return item.id == event.target.value;
    })
    console.log('challengedata',challengedata);
    sessionStorage.setItem('challengename',challengedata.name);
    this.challengename_session = sessionStorage.getItem('challengename');
    
    // this.showChallenge = false;
    this.challengename = challengedata.name;
    this.showEdition = true;
    // document.querySelector('#create_edition button').removeAttribute('disabled');
    // document.querySelector('#create_edition button').innerHTML = "Select  Edition";
    this.editionService.getEditionsByChallenge(this.challengeid).subscribe((res:any)=>{
      this.tableData_edition = res.results;
      this.showAppForm=true;

    })
  }

  movetoround(id:any){
    console.log('application list',this.appllications_list);
    for(var i = 0 ; i < this.appllications_list.length ; i++){
      let data = {
        application_id : this.appllications_list[i].id,
        to_progress_state : "IN_ROUNDS",
        to_round_id : id
      }

      this.challengeService.moveapplicationtoaround(data).subscribe((res:any)=>{
        console.log('res_moved_to_round',res);
        location.reload();
        
      })
    }
  } 

  geteditionid(event:any){
    
    this.selectedindex = -1;
    let id = event.target.value;
    let editiondata = this.tableData_edition.find((item)=>{
      return item.id == id;
    })
    sessionStorage.setItem('edition_changed','true');
    console.log('editiondata',editiondata);
    sessionStorage.setItem('editionid',id);
    sessionStorage.setItem('edition_name',editiondata.name);
    sessionStorage.setItem('edition_desc',editiondata.description);
    sessionStorage.setItem('edition_start_date',editiondata.start_date);
    sessionStorage.setItem('edition_end_date',editiondata.end_date);
    sessionStorage.setItem('selected_tab','SCREENING');
    this.edition_name = sessionStorage.getItem('edition_name');
    console.log('edition_name_1234',this.edition_name);
    //document.querySelector('#create_edition button').innerHTML = name;
    this.challengeService.getFormbyEditionid(id).subscribe((res)=>{
      console.log('res1245',res.results);
      this.applications = res.results;
    })
    this.editionclicked = true;
    if(sessionStorage.getItem('role_name')=='EXTERNAL_EVALUATOR'){
      this.challengeService.getEvaluatorRoundsByEditionId(id).subscribe((res:any)=>{
        console.log('res_eva_rounds',res.results);
        this.editionrounds = res.results;
        console.log('res_eva______1234',this.editionrounds);
      })
    }
    else{
      this.formatdate_start(editiondata.start_date);
      this.formatdate_end(editiondata.end_date);

      this.editionselected = true;

      this.showform = true;
      this.challengeService.getEditionRounds(id).subscribe((res:any)=>{
        console.log(res);
        this.editionrounds = res.results[0];
        this.csvdata = res.results[0];
        console.log('edition rounds',this.editionrounds);
        this.showall = true;
        document.getElementById('screening').className+=' activestage';
      },(err)=>{
        this.showall = false;
      })
      let states = ['SCREENING', 'ON_HOLD', 'REJECTED']
      this.challengeService.getallapplications(id,states).subscribe((res:any)=>{
        console.log('res_app',res.results);
        this.submittedapplications = res.results; 
        if(res.results.length!=0){
          this.showapplication = true
          // this.submittedapplications = res.results;
          console.log('submittedapps',this.submittedapplications);
      }
        else{
          this.showapplication = false;
          
      }
    })
    }
    
    //sessionStorage.setItem('start_date',start_date);
    //sessionStorage.setItem('end_date',end_date);
    
    //this.router.navigate(['/admin/forms'])
  }


  getEditionRounds(start_date:any,end_date:any,id:any){
    alert('here');
    if(sessionStorage.getItem('role_name')=='EXTERNAL_EVALUATOR'){
      this.challengeService.getEvaluatorRoundsByEditionId(id).subscribe((res:any)=>{
        console.log('res_eva_rounds',res.results);
        this.editionrounds = res.results;
        console.log('res_eva______1234',this.editionrounds);
      })
    }
    else{
      this.formatdate_start(start_date);
      this.formatdate_end(end_date);

      this.editionselected = true;

      this.showform = true;
      this.challengeService.getEditionRounds(id).subscribe((res:any)=>{
        console.log('editionrounds',res);
        this.editionrounds = res.results[0];
        this.csvdata = res.results[0];
        console.log('edition rounds',this.editionrounds);
        this.showall = true;
      },(err)=>{
        this.showall = false;
      })
      let states = ['SCREENING', 'ON_HOLD', 'REJECTED']
      this.challengeService.getallapplications(id,states).subscribe((res:any)=>{
        console.log('res_app',res.results);
        this.submittedapplications = res.results; 
        if(res.results.length!=0){
          this.showapplication = true
          // this.submittedapplications = res.results;
          console.log('submittedapps',this.submittedapplications);
      }
        else{
          this.showapplication = false;
          
      }
    })
    }
  }

  getapplicationsinscreeing(){
    sessionStorage.setItem('selected_tab','SCREENING');
    document.getElementById('selected').classList.remove('activestage');
    this.showassignevaluators = false;
    this.selectedindex = -1;
    let states = ['SCREENING', 'ON_HOLD', 'REJECTED']
    this.getApplications(sessionStorage.getItem('editionid'),states);
    // this.challengeService.getallapplications(sessionStorage.getItem('editionid'),states).subscribe((res:any)=>{
    //   console.log('res_app',res.results);
    //   if(res.results.length!=0){
    //     this.showapplication = true
    //     this.submittedapplications = res.results;
    //   }
    //   else{
    //     this.showapplication = false;
    //     this.submittedapplications = res.results;
    //   }
    // })
  }

  getapplications_selected(){
    sessionStorage.setItem('selected_tab','SELECTED');
    document.getElementById('screening').classList.remove('activestage');
    this.showassignevaluators = false;
    this.selectedindex = -1;
    let states = ['SELECTED'];
    this.getApplications(sessionStorage.getItem('editionid'),states);
    // this.challengeService.getallapplications(sessionStorage.getItem('editionid'),states).subscribe((res:any)=>{
    //   console.log('res_app',res.results);
    //   if(res.results.length!=0){
    //     this.showapplication = true
    //     this.submittedapplications = res.results;
    //   }
    //   else{
    //     this.showapplication = false;
    //     this.submittedapplications = res.results;
    //   }
    // })
  }

  getApplications(editionid:any , states:any){
    this.challengeService.getallapplications(editionid,states).subscribe((res:any)=>{
      console.log('res_app',res.results);
      if(res.results.length!=0){
        this.showapplication = true
        this.submittedapplications = res.results;
      }
      else{
        this.showapplication = false;
        this.submittedapplications = res.results;
      }
    })
  }

  formatdate_start(dateinput:any){
    console.log('date from min',dateinput);
    var date = new Date(dateinput);
    
    // '2023-03-26T18:10'
    var dateStr =
      date.getFullYear() + "-" +("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2)
    console.log(dateStr);
    sessionStorage.setItem('start_range',dateStr);
    
  }

  minstartdate(){
    // var date = new Date();
    // date.setDate(date.getDate()+1);
    // '2023-03-26T18:10'
    // var dateStr =
    //   date.getFullYear() + "-" +("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2)+ "T" +
    //   ("00" + date.getHours()).slice(-2) + ":" +
    //   ("00" + date.getMinutes()).slice(-2)
    // console.log(dateStr);
    // var dateStr =
    //   date.getFullYear() + "-" +("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2)
    // console.log(dateStr);
    const today = new Date(); // Get the current date
    today.setHours(0, 0, 0, 0); // Set hours, minutes, and seconds to zero
    const dateStr =
      today.getFullYear() + "-" + ("00" + (today.getMonth() + 1)).slice(-2) + "-" + ("00" + today.getDate()).slice(-2);
    return dateStr;
   
    
  } 

  minstartdate_ext(){
    // var date = new Date();
    // date.setDate(date.getDate()+1);
    // '2023-03-26T18:10'
    // var dateStr =
    //   date.getFullYear() + "-" +("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2)+ "T" +
    //   ("00" + date.getHours()).slice(-2) + ":" +
    //   ("00" + date.getMinutes()).slice(-2)
    // console.log(dateStr);
    // var dateStr =
    //   date.getFullYear() + "-" +("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2)
    // console.log(dateStr);
    const today = new Date(); // Get the current date
    today.setHours(0, 0, 0, 0); // Set hours, minutes, and seconds to zero
    const dateStr =
      today.getFullYear() + "-" + ("00" + (today.getMonth() + 1)).slice(-2) + "-" + ("00" + today.getDate()).slice(-2);
    return dateStr;
   
    
  } 
  
  
  getstartdate(event:any){
    console.log('start date',event.target.value);
    this.startdate = event.target.value;
    document.getElementById('end_date').removeAttribute('readonly');
    this.mindate_end = this.minenddate();
  }


  getstartdate_ext(event:any){
    console.log('start date ext',event.target.value);
    this.startdate_ext = event.target.value;
    document.getElementById('end_date_ext').removeAttribute('readonly');
    this.mindate_end_ext = this.minenddate_ext();
    console.log('min date end ext',this.mindate_end_ext);
  }


  minenddate(){
    var date = new Date(this.startdate);
    date.setDate(date.getDate()+1);
    // '2023-03-26T18:10'
    // var dateStr =
    //   date.getFullYear() + "-" +("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2)+ "T" +
    //   ("00" + date.getHours()).slice(-2) + ":" +
    //   ("00" + date.getMinutes()).slice(-2)
    // console.log(dateStr);
    var dateStr =
    date.getFullYear() + "-" +("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2)
    console.log(dateStr);
    
    return dateStr
  }

  minenddate_ext(){
    var date = new Date(this.startdate_ext);
    date.setDate(date.getDate()+1);
    // '2023-03-26T18:10'
    // var dateStr =
    //   date.getFullYear() + "-" +("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2)+ "T" +
    //   ("00" + date.getHours()).slice(-2) + ":" +
    //   ("00" + date.getMinutes()).slice(-2)
    // console.log(dateStr);
    var dateStr =
    date.getFullYear() + "-" +("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2)
    console.log('end date ext',dateStr);
    
    return dateStr
  }
  formatdate_end(dateinput:any){
    var date = new Date(dateinput);
    var dateStr =
      
    date.getFullYear() + "-" +("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2)
  console.log(dateStr);
  sessionStorage.setItem('end_range',dateStr);
  }

  goto(value:any){

    if(value == 'edit'){
      this.router.navigate(['/admin/forms']).then(()=>{
        sessionStorage.setItem('formpreview','false');
        location.reload();
      })
    }
    else if(value == 'preview'){
      this.router.navigate(['/admin/forms']).then(()=>{
        location.reload()
      });
      sessionStorage.setItem('formpreview','true');
    }
  }

  // export_csv(){
  //   let csv_data = [];
  //   console.log('submitted applications csv',this.submittedapplications);
  //   const options = { 
  //     fieldSeparator: ',',
  //     quoteStrings: '"',
  //     decimalSeparator: '.',
  //     showLabels: true, 
  //     showTitle: true,
  //     title: 'Applications',
  //     useTextFile: false,
  //     useBom: true,
  //     useKeysAsHeaders: true,
  //     filename:'applications',
  //     headers: ['Company','Location','Industry','Date','Status','Score'] 
  //   };
   
  // const csvExporter = new ExportToCsv(options);
  // console.log('sumittedapplications',this.submittedapplications);
  // for(var i = 0 ; i < this.submittedapplications.length ; i++){

  //   csv_data.push({
  //     company: this.submittedapplications[i].company_profile.companyname,
  //     location: this.submittedapplications[i].company_profile.cityc,
  //     industry: this.submittedapplications[i].company_profile.industryc,
  //     date: this.submittedapplications[i].created_at,
  //     status: this.submittedapplications[i].progress_state,
  //     score: this.submittedapplications[i].score_average != -1 ? this.submittedapplications[i].score_average : 'Not evaluated'
  //   })
  // }
  // console.log('csvdata',csv_data);
  // csvExporter.generateCsv(csv_data);
  
  // }

  exportData(dataArray: any[]) {
    console.log(dataArray)
    const data = dataArray.map((d) => {
      const res = {
        Company: d.company_profile.companyname,
        Location: d.company_profile.cityc,
        Industry: d.company_profile.industryc,
        Date: d.created_at,
        Current_Status: d.progress_state,
        Current_Stage: d.progress_state == 'IN_ROUNDS' || d.progress_state == 'REJECTED'? `Stage ${d.current_round_order}`: d.progress_state
      };
      d.round_scores.forEach((element, index) => {
        console.log('element1234',element);
        if(element == -1){
          console.log('NA');
          res[`SCORE_STAGE_${index+1}`] = 'NA';
        }
        else{
          res[`SCORE_STAGE_${index+1}`] = element;
        }
        
      });
      return res;
    });
  
    this.exportAsXLSX(data)
    // this.generateExcelFile(data, "myData.xlsx");
    console.log("Export:", data);
  }

  exportAsXLSX(data) {
    console.log('data123445',data);
    this.excelService.exportAsExcelFile(data, 'challenges');
  }

  export_xlsx() {
      this.challengeService.exportSubmittedApplications(sessionStorage.getItem('editionid')).subscribe((res:any)=>{
        console.log('export_excel',res)
        this.submittedapplications_export = res.results
        console.log('export_applications',this.submittedapplications_export)
        this.exportData(this.submittedapplications_export);
      })
      // return;
      
  }



  showexternalevaluators(){
    $('#externalevaluators').modal('show');
  }

  hidemodal(){
    $('#evaluatorslist').on('shown.bs.modal', function (e) {
      $("#externalevaluators").modal('hide');
  })
  }

  addNewAddressGroup1(){
    const add = this.form.get('address') as FormArray;
    add.push(this.fb.group({
      first_name: ['',Validators.required],
      last_name: ['',Validators.required],
      email:['',[Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      profile_type:['R_EVALUATOR']
    }))
  }

  addEvaluator(){
    // this.submitform = true;
    this.isValidFormSubmitted = false;
    if(this.form.invalid){
      return;
    }
    this.isValidFormSubmitted = true;
    let externalevaluators = {};
    externalevaluators['evaluators'] = [] 
    console.log('add form value',this.form.value);
    for(var i = 0 ; i < this.form.value.address.length ; i++){
      let data = this.form.value.address[i];
      externalevaluators['evaluators'].push(data);
    }
    console.log('external evaluators',externalevaluators);
    this.challengeService.addExternalEvaluators(externalevaluators).subscribe((res)=>{
      console.log('res_evaluators',res);
      this.toastrService.success('Evaluator Added Successfully');
      this.form.reset();
      this.listExternalEvaluators();
      location.reload();
    })
  }

  deleteAddressGroup(index: number) {
    const add = this.form.get('address') as FormArray;
    add.removeAt(index)
  }

  listExternalEvaluators(){
    this.challengeService.listevaluators('external').subscribe((res:any)=>{
      this.externalevaluators = res.results[0];
      //this.selectevaluators = res.results[0];
      console.log('res_list',this.externalevaluators);
    })
  }

  listExternalEvaluators1(){
    this.challengeService.listevaluators('external').subscribe((res:any)=>{
      //this.externalevaluators = res.results[0];
      this.selectevaluators = res.results[0];
      //console.log('res_list',this.selectevaluators);
    })
  }

  onFileSelect(event) {
    let af = ['image/png','image/jpeg','image/jpg','image/svg','image/webp']
    const file = event.target.files[0];
    const filesize = file.size;
    const filesizemb = filesize/1024 ** 2;
    console.log('filesize in mb',filesizemb);
    console.log('file',file);
    if(!af.includes(file.type)){
      this.filetypeerr = true
    }
    else
    {
      this.filetypeerr = false
    }

  if(filesizemb > 2){
    this.filesizeerr = true
    }
  else
  {
    this.filesizeerr = false
  }
this.fileInputLabel = file.name;
this.editionForm.get("uploadedImage").setValue(file);
    // console.log('file is here');
    // const file = event.target.files[0];
    // console.log('file',file);
    // this.fileInputLabel = file.name;
    // this.editionForm.get("uploadedImage").setValue(file);
  }

  onFileSelect_ext(event) {
    let af = ['image/png','image/jpeg','image/jpg','image/svg','image/webp']
    const file_ext = event.target.files[0];
    const filesize_ext = file_ext.size;
    const filesizemb_ext = filesize_ext/1024 ** 2;
    console.log('filesize in mb',filesizemb_ext);
    console.log('file',file_ext);
    if(!af.includes(file_ext.type)){
      this.filetypeerr_ext = true
    }
    else
    {
      this.filetypeerr_ext = false
    }

  if(filesizemb_ext > 2){
    this.filesizeerr_ext = true
    }
  else
  {
    this.filesizeerr_ext = false
  }
this.fileInputLabel_ext = file_ext.name;
this.editionForm_ext.get("uploadedImage_ext").setValue(file_ext);
    // console.log('file is here');
    // const file = event.target.files[0];
    // console.log('file',file);
    // this.fileInputLabel = file.name;
    // this.editionForm.get("uploadedImage").setValue(file);
  }

  addtoarray(event:any,value:any){
    
    if(event.target.checked==true){
      this.evaluators.push(+value);
    }
    else{
      for(var i = 0 ; i < this.evaluators.length ; i++){
        if(this.evaluators[i]==+value){
          this.evaluators.splice(i,1);
        }
      }
    }
    console.log('evaluators',this.evaluators);
  } 

  add(){
    this.evaluators = [];
    console.log('selected evaluators 1234',this.selectedevaluators);
    for(var i = 0 ; i < this.selectedevaluators.length ; i++){
      this.evaluators.push(this.selectedevaluators[i].user_id);
    }
    console.log('eval_1234',this.evaluators);
    var evaluators = _.uniq(this.evaluators);
    let data = {
      "evaluator_ids":this.evaluators
    }
    console.log('data',data);

    this.challengeService.addEvaluatorstoRound(sessionStorage.getItem('stageid'),data).subscribe((res)=>{
      console.log('res_evaluators',res);
      $('#assignevaluators').modal('hide');
      sessionStorage.setItem('selected_tab','IN_ROUNDS')
      sessionStorage.setItem('edition_changed','false');
      // this.editionclicked = true;
      location.reload();
    })
  }

  assignevaluator(s:any){
    console.log('s_data',s);
    for(var i = 0 ; i < this.selectevaluators.length ; i++){
      if(s.user_id == this.selectevaluators[i].user_id){
        this.selectedevaluators.push(this.selectevaluators[i]);
        this.selectevaluators.splice(i,1);
      }
    }
    console.log('selectevaluator modified',this.selectevaluators);
    console.log('selectedevaluator modified',this.selectedevaluators);
    console.log('external evaluators', this.externalevaluators);
  }

  removeevaluator(s:any){
    console.log('s_data',s);
    for(var i = 0 ; i < this.selectedevaluators.length ; i++){
      if(s.user_id == this.selectedevaluators[i].user_id){
        this.selectevaluators.push(this.selectedevaluators[i]);
        this.selectedevaluators.splice(i,1);
      }
    }
    console.log('selectedevaluator modified',this.selectedevaluators);
    console.log('selectevaluator modified',this.selectevaluators);
    console.log('external evaluators', this.externalevaluators);
  }

  keeponhold(){
    for(var i = 0 ; i < this.appllications_list.length ; i ++) {
      let data = {
        application_id : this.appllications_list[i].id,
        to_progress_state: "ON_HOLD"
      }

      this.challengeService.moveapplicationtoaround(data).subscribe((res)=>{
        console.log('keep on hold',res);
        location.reload();
      })
    }
  }

  reject(){
    for(var i = 0 ; i < this.appllications_list.length ; i ++) {
      let data = {
        application_id : this.appllications_list[i].id,
        to_progress_state: "REJECTED"
      }

      this.challengeService.moveapplicationtoaround(data).subscribe((res)=>{
        console.log('rejected',res);
        location.reload();
      })
    }
  }

  select(){
    for(var i = 0 ; i < this.appllications_list.length ; i ++) {
      let data = {
        application_id : this.appllications_list[i].id,
        to_progress_state: "SELECTED"
      }

      this.challengeService.moveapplicationtoaround(data).subscribe((res)=>{
        console.log('rejected',res);
        location.reload();
      })
    }
  }

  getevaluatoreditions(){
    this.showEdition = true;
    this.showchallenge = false;
    this.challengeService.getevaluatoreditions().subscribe((res:any)=>{
      console.log('res_evaluators',res.results[0]);
      this.tableData_edition = res.results[0]
      console.log('edition table data', this.tableData_edition);
    })
  }

  gotoindividual(id:any,score:any,status:any){
    sessionStorage.setItem('selected_tab',status);
    sessionStorage.setItem('score',score);
    sessionStorage.setItem('edition_changed','false');
    // this.editionclicked = true;
    this.router.navigate([`/admin/application/${id}`]);
  }

  cancel(){
    this.form.get('address').reset();
    // this.addForm.criteria.reset();
  }

  cleardata(){
    this.challengeForm.reset();
    this.submitted = false;
  }
}
