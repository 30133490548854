import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { GrantNotificationService } from '../../../services/grant-notification.service';
import { GrantUploadsService } from '../../../services/grant-uploads.service';
import { environment } from '../../../../environments/environment';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { range } from 'rxjs';
@Component({
  selector: 'ngx-financialyeardropdown',
  templateUrl: './financialyeardropdown.component.html',
  styleUrls: ['./financialyeardropdown.component.scss']
})
export class FinancialyeardropdownComponent implements OnInit {
  baseUrl = environment.apiurl;
  scheme_id:any;
  currentTab: string ;

  schemeId:number;
schemeName:string;
schemes:string;
  funds:any;
  defaultval:any;
  selectedEntityType:any;
financialYearAdded = false;
  selectYear:any;
  newfinancialYear:any;
  currentYear:any = null;
  selectedYearId: any
  entity_types: any[] = ['INCUBATOR', 'COE', 'ACCELERATOR'];
  constructor( //private _aRoute: ActivatedRouteSnapshot, 
  private _route: ActivatedRoute,
    private toastrService: NbToastrService,private http:HttpClient,private router:Router ,private grantManagementService:
    GrantManagementService ) {
    this._route.paramMap.subscribe((params:any)=>{
       this.scheme_id = params.params.id;
       
       })
    }

  ngOnInit(): void {
  this.reloadingData()
    if(sessionStorage.getItem('range') == null){
      const currYear = new Date().getFullYear();
      const newRangee = (currYear + '-' + (currYear + 1)).toString();
      sessionStorage.setItem('range', newRangee);
       sessionStorage.setItem("start_date", (currYear + '-04-01').toString())
     sessionStorage.setItem("end_date", ((currYear+1) + '-03-31').toString());
   }
    this.defaultval = sessionStorage.getItem('range');
    this.getfinancialYear();
    this.initializeEntityType() 
    this.currentYear = (new Date()).getFullYear();

    if (!sessionStorage.getItem('entity_types')) {
      sessionStorage.setItem('entity_types', 'INCUBATOR');
    }
    this.selectedEntityType = sessionStorage.getItem('entity_types');
    this.loadScript('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js');
    if(this.scheme_id == 1){
      this.schemeName = 'TIDE 2.0'
    }
    else if(this.scheme_id ==2){
      this.schemeName = 'SAMRIDH'
      }
    else if(this.scheme_id ==3){
      this.schemeName = 'Other Meity Scheme'
      }
    else if(this.scheme_id ==4){
      this.schemeName = 'GENESIS'
     }
    else if(this.scheme_id ==5){
      this.schemeName = 'Non Meity Schemes'
      }
    else if(this.scheme_id ==6){
      this.schemeName = 'NGIS'
    }
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  getfinancialYear(){
    this.http.get(this.baseUrl + `finance/list?scheme_id=${this.scheme_id}`).subscribe(
      (data:any) => {
        this.newfinancialYear = data.results;
       this.grantManagementService.setStartDate(sessionStorage.getItem("range").substring(0, 4)+'-04-01');
        this.grantManagementService.setEndDate(sessionStorage.getItem("range").substring(5, 9)+'-03-31');
},
      error => {
        console.error('Error fetching investors: ', error);
      }
    );
  }
  postfinancialYear() {
    if (this.newfinancialYear.length === 0) {
      // If no data is returned, add the current financial year
      const currentYear = new Date().getFullYear();
      const nextYear = currentYear + 1;
      const payload = {
        start_date: `${currentYear}-04-01`,
        end_date: `${nextYear}-03-31`,
        scheme_ids: [this.scheme_id],
        range: [`${currentYear}-${nextYear}`], // Corrected the range format
      };      
      this.http.post(this.baseUrl + "finance", payload).subscribe(
        (response: any) => {
          location.reload();
          this.toastrService.success('Year updated successfully!')
        },
        error => {
          console.error('Error adding current financial year: ', error);
        }
      );
    } else {
      // Financial year already exists, extend it by one year
      const currentYear = new Date().getFullYear();
      const nextYear = currentYear + 1;
      const maxYear = currentYear + 2;
      // console.log(currentYear, maxYear);
  
      const startDateString = this.newfinancialYear[0].start_date.substring(0, 10);
      // console.log('startdate----',startDateString);
      const startDate = new Date(startDateString);
      startDate.setFullYear(startDate.getFullYear() + 1);
      const newStartDateString = startDate.toISOString().substring(0, 10);
  
      const endDateString = this.newfinancialYear[0].end_date.substring(0, 10);
      const endDate = new Date(endDateString);
      endDate.setFullYear(endDate.getFullYear() + 1);
      const newEndDateString = endDate.toISOString().substring(0, 10);
  
      const newRange = `${startDate.getFullYear()}-${endDate.getFullYear()}`; // Calculate new range
      // console.log(newRange);
      if (parseInt(newRange.substring(0, 4)) < maxYear) {
        const payload = {
          start_date: `${currentYear+1}-04-01 00:00:00+00`, // Corrected the format
          end_date: `${currentYear+2}-03-31 00:00:00+00`, // Corrected the format
          scheme_ids: [this.scheme_id],
          range: [`${currentYear + 1}-${currentYear + 2}`],
        };

  // this.http.post(this.baseUrl + "finance", payload).subscribe(
  //   (response: any) => {
        
  //     this.toastrService.success('Year updated successfully!')
  //     this.getfinancialYear()
  //   },
  //   error => {
  //     console.error('Error: ', error);
  //   }
  // );

console.log('currentYear', (currentYear + 1), this.newfinancialYear.filter((item: any) => item.range[0].substring(0, 4) == (currentYear + 1)))
const yearExists = this.newfinancialYear.filter((item: any) => item.range[0].substring(0, 4) == (currentYear + 1));
if (yearExists.length == 0) {
  console.log('Year doesnt exists')
  // return;
  this.http.post(this.baseUrl + "finance", payload).subscribe(
    (response: any) => {
      this.toastrService.success('Year updated successfully!');
      this.financialYearAdded = true; // Set the flag to true after the first success
      this.getfinancialYear(); // Refresh the financial year data
    },
    error => {
      console.error('Error: ', error);
    }
  );
} else {
  this.toastrService.danger('Year already added!');
}
}
      
    }
  }
  
 
 
  newdataadd(event:any){
 var newitem = event.target.value;
//  console.log('new',newitem)
const selectedYear: any[] = this.newfinancialYear.filter((item:any)=> item.id==newitem)
sessionStorage.setItem('range',selectedYear[0].range);
sessionStorage.setItem("start_date",selectedYear[0].start_date.substring(0,10))
  sessionStorage.setItem("end_date",selectedYear[0].end_date?.substring(0,10));
  sessionStorage.setItem('selectedYearId', newitem);
  setTimeout(()=>{
    this.grantManagementService.setStartDate(sessionStorage.getItem('start_date'));
    this.grantManagementService.setEndDate(sessionStorage.getItem('end_date'));
  },1000);
  this.selectedYearId = newitem;


  }
  entityData(event: any): void {
    var newdata= event.target.value;
    sessionStorage.setItem("entity_types",newdata)
    this.grantManagementService.setEntityType(newdata); // Pass the selected entity type to the service
// console.log('newdata',newdata);
this.selectedEntityType = newdata;
 
  }

  //for entitytype default
  initializeEntityType() {
    const storedEntityType = sessionStorage.getItem('entity_types') || 'INCUBATOR';
    this.selectedEntityType = storedEntityType;
    this.grantManagementService.setEntityType(storedEntityType);
  }

  setDefaultFinancialYear() {
    if (!this.selectedYearId) {
      const currentFinancialYear = this.newfinancialYear.find(
        item => item.range[0].substring(0, 4) == this.currentYear.toString()
      );

      if (currentFinancialYear) {
        this.selectedYearId = currentFinancialYear.id;
        console.log('selectedYear----');
        console.log(this.selectedYearId);
        //this.newdataadd({ target: { value: currentFinancialYear.id } });
      }
    }
  }
  //routing
 setCurrentTab(tab: string) {
    this.currentTab = tab;
  }
  
  //routing on reloading
  reloadingData(){
  const routePath = this.router.url.split('/')[4]
  if(routePath.includes('?')){
    var newroutePath = routePath.split('?')[0]
  }
  else{
    var newroutePath = routePath;
  }
  if(newroutePath == 'home'){
    this.currentTab = 'home'
  }
  else if(newroutePath == 'startuptable'){
    this.currentTab = 'startuptable'
  }
  else if(newroutePath == 'team'){
    this.currentTab = 'team'
  }
  else if(newroutePath == 'released'){
    this.currentTab = 'released'
  }
  else if(newroutePath == 'Eir-grant'){
    this.currentTab = 'Eir-grant'
  }
  else if(newroutePath == 'release-scaleup'){
    this.currentTab = 'release-scaleup'
  }
  else if(newroutePath == 'notifications'){
    this.currentTab = 'notifications'
  }
  else if(newroutePath == 'uploads'){
    this.currentTab = 'uploads'
  }
  else if(newroutePath == 'state-fund'){
    this.currentTab = 'state-fund'
  }
  else if(newroutePath == 'statewise-agency'){
    this.currentTab = 'statewise-agency'
  }
  else if(newroutePath == 'statewise-startup'){
    this.currentTab = 'statewise-startup'
  }
  else if(newroutePath == 'quarter-fund'){
    this.currentTab = 'quarter-fund'
  }
  else if(newroutePath == 'fund-release'){
    this.currentTab = 'fund-release'
  }
  else if(newroutePath == 'document-uploads'){
    this.currentTab = 'document-uploads'
  }
  else if(newroutePath == 'release-order'){
    this.currentTab = 'release-order'
  }

  else{
    this.currentTab = 'home'
    // console.log('going to else...')
  }
      }
     

    }