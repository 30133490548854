<!-- 
<div class="container py-3">
  <ngx-gia-utilized-table></ngx-gia-utilized-table>
</div> -->

<div class="container whitebg">
  <div class="p-4">
    <h2 class="text-center">GIA Released</h2>
    <div class="d-flex justify-content-center py-3">
      <button
        type="button"
        class="btn btn-primary mr-2"
        (click)="showEir = true"
      >
        EiR Grants
      </button>
      <button type="button" class="btn btn-primary" (click)="showEir = false">
        
        Scale-Up Investment
      </button>
    </div>
    <div class="p-3 my-2" *ngIf="showEir">
      <p class="p-2"><b>EIR Grants Table:</b></p>
      <div class="d-flex justify-content-end p-3">
        <button type="button" class="btn btn-primary text-end" (click)="downloadExcel()">Download Excel</button>
      </div>
      <div class="p-2 justify-content-center d-flex">
        <button
          type="button"
          class="btn btn-primary mr-2"
          (click)="giautilizedrefnewdata('amount_received')"
        >
          GIA Released
        </button>
        <button
          type="button"
          class="btn btn-primary mr-2"
          (click)="giautilizedrefnewdata('amount_utilised')"
        >
          GIA Utilized
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="giautilizedrefnewdata('amount_refunded')"
        >
          GIA Unutilized
        </button>
        
      </div>

      <table class="table table-striped table-bordered text-center">
        <thead>
          <tr>
            <th>Financial Year</th>
            <th>Total</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of newtideData">
            <td>{{ item.year_range }}</td>
            <ng-container *ngIf="item.amount_received">
              <td>{{ item.amount_received }}</td>
            </ng-container>
            <ng-container *ngIf="!item.amount_received && item.amount_utilised">
              <td>{{ item.amount_utilised }}</td>
            </ng-container>
            <ng-container *ngIf="!item.amount_received && !item.amount_utilised && item.amount_refunded">
              <td>{{ item.amount_refunded }}</td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="p-3 my-2" *ngIf="!showEir">
      <p class="p-2"><b>Scale-Up Investment:</b></p>
      <div class="d-flex justify-content-end p-3">
        <button type="button" class="btn btn-primary text-end" (click)="downloadscaleupExcel()">Download Excel</button>
      </div>
      <div class="p-2 justify-content-center d-flex">
        <button type="button" class="btn btn-primary mr-2" (click)="getData('amount_received')">GIA Released</button>
        <button type="button" class="btn btn-primary mr-2" (click)="getData('amount_utilised')">GIA Utilized</button>
        <button type="button" class="btn btn-primary" (click)="getData('amount_refunded')">GIA Unutilized</button>
      </div>
      <table class="table table-striped table-bordered text-center">
        <thead>
          <tr>
            <th>Financial Year</th>
            <th>Total</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of scaledata">
            <td>{{ item.year_range }}</td>
            <ng-container *ngIf="item.amount_received">
              <td>{{ item.amount_received }}</td>
            </ng-container>
            <ng-container *ngIf="!item.amount_received && item.amount_utilised">
              <td>{{ item.amount_utilised }}</td>
            </ng-container>
            <ng-container *ngIf="!item.amount_received && !item.amount_utilised && item.amount_refunded">
              <td>{{ item.amount_refunded }}</td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
