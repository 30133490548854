<div class="login-form row custom-card col-md-10 offset-md-1">
    <div class="col-12 col-md-6 col-sm-12 transform-center">
        <div class="text-center">
            <img src="{{'assets/images/MeitYStartupHub-Logo-FINAL_5 1.png'}}" height="137px">
        </div>
        <hr class="hr-text">
        <div class="desc-text">
            Meity startup India Portal is a one of its kind online platform for all stakeholders of the startup
            ecosystem in India.
        </div>
        <hr class="hr-text">
        <div class="text-center">
            <!-- <img class="govt-login" src="{{'assets/images/gov-login-img.png'}}"> -->
        </div>
    </div>
    <div class="col-12 col-md-6 col-sm-12">
        <div class="text-center pt-30">
            <!-- <img class="welcome-meity" src="{{'assets/images/login-logo-welcom.png'}}" width="50%"> -->
        </div>
        <hr class="hr-text" data-content="OR">
        <form class="ng-pristine ng-invalid" [formGroup]="forgotForm">
            <div class="form-control-group">
                <label for="input-email" class="font-weight-bold">Email :</label>
                <input nbinput="" fullwidth="" name="email" id="input-email" placeholder="Email"
                    fieldsize="large" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" autofocus="" formControlName="email"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                    <div *ngIf="submitted && f.email.errors" class="text-danger">
                        <div *ngIf="f.email.errors['required']">Email is required</div>
                        <div *ngIf="f.email.errors['pattern']">Email is invalid</div>
                      </div>
                
            </div>

            <div class="form-control-group">
                <label for="input-email" class="font-weight-bold">Code :</label>
                <input nbinput="" fullwidth="" name="email" id="input-email" placeholder="Code"
                    fieldsize="large"  autofocus="" formControlName="code" [ngClass]="{ 'is-invalid': submitted && f.code.errors }"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                    <div *ngIf="submitted && f.code.errors" class="text-danger">
                        <div *ngIf="f.code.errors['required']">Code is required</div>
                        <div *ngIf="f.code.errors['minlength']">Code is invalid</div>
                    </div>
                
            </div>

            <div class="form-control-group password-container">
                <label for="input-email" class="font-weight-bold">Password :</label>
                <input nbinput="" fullwidth="" name="password1" id="input-email" placeholder="Password"
                    fieldsize="large" autofocus="" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                    <i class="fa fa-eye-slash" id="eye" (click)="showpassword()" [type]="show?'text':'password'" *ngIf="showeye;else hideeye"></i>
                    <ng-template #hideeye>
                        <i class="fa fa-eye" aria-hidden="true" (click)="hidepassword()"></i>
                    </ng-template>
                    <div *ngIf="submitted && f.password.errors" class="text-danger">
                        <div *ngIf="f.password.errors['required']">Password is required</div>
                        <div *ngIf="f.password.errors['minlength']">Password is invalid</div>
                    </div>
          
            </div>
            <button type="button" (click)="resetpassword()" fullwidth="" status="primary" size="large"
                class="appearance-filled full-width size-large shape-rectangle status-primary nb-transition login-button">
                Submit </button>
        </form>
       
    </div>
</div>
