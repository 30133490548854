import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { IncubatorService } from '../../../services/incubator/incubator.service';

@Component({
  selector: 'ngx-scheme-incubator-project-details',
  templateUrl: './scheme-incubator-project-details.component.html',
  styleUrls: ['./scheme-incubator-project-details.component.scss']
})
export class SchemeIncubatorProjectDetailsComponent implements OnInit {
  projectDetail!: FormGroup;
  submitted:boolean=false;
  @Input() schemeid = '';
  @Input() incubatorid = '';
  incubatorData: any;
  schemeFundResponse: any;
  inc_fund_id: any;
  incubator_id:any;
  projectDetailsResponse: any;
  startupCount:any;
  @Output() schemeFundRes = new EventEmitter();
  
  constructor(
    private router: Router, 
    private fb: FormBuilder, 
    private incubatorService: IncubatorService,
    private grantMangementService: GrantManagementService,
    private toastrService: NbToastrService,
    private _route: ActivatedRoute) { }

  ngOnInit(): void {
     //project details
     this.projectDetail = this.fb.group({
      center_type: ['', Validators.required],
      nameOfCentre: ['', Validators.required],
      //locationOfCentre: ['', Validators.required],
      startupsFinalized: ['', [Validators.required, Validators.pattern('[1-9]*')]],
      fundSource: ['', Validators.required],
      tenure:['', Validators.required]
    });
    this.inc_fund_id = this._route.snapshot.paramMap.get("incid");
    this.incubator_id = this._route.snapshot.paramMap.get("incubator_id");
    
    if(this.inc_fund_id && this.incubator_id) {
      this.getIncProjectDetailsByIncId(this.inc_fund_id);
    }
  }

  // validations here
  get projectDetailControl() {
    return this.projectDetail.controls;
  }

  get center_type() {
    return this.projectDetail.get('center_type');
  }

  get nameOfCentre(){
    return this.projectDetail.get('nameOfCentre');
  }

  // get locationOfCentre(){
  //   return this.projectDetail.get('locationOfCentre');
  // }

  get startupsFinalized(){
    return this.projectDetail.get('startupsFinalized');
  }

  get fundSource() {
    return this.projectDetail.get('fundSource');
  }

  get tenure(){
    return this.projectDetail.get('tenure');
  }

  async onCenterTypeChange(event) {
    const value = event.target.value;
    const scheme_id = this.schemeid;
    const data = {"id":value, "scheme": scheme_id};
    try {
      await this.getTypeData(data);
    } catch (error) {
      console.log("Error in scheme incubator proj detials:::",error);
    }
  }

  onProjectDetailsSubmit() {
    if (this.projectDetail.invalid) {
      this.projectDetail.markAllAsTouched();
      console.log("Please fill in all mandatory fields.",this.projectDetail.value);
      return;
    } else {
      let incubatorId = this.projectDetail.get('nameOfCentre')?.value;
      let startupFinalized = this.projectDetail.get('startupsFinalized').value;
      this.incubatorService.getStartupsByIncubatorId(incubatorId).subscribe((response:any) => {
        this.startupCount = response?.results;
        console.log("STARTUPCOUNT_LENGTH:::",this.startupCount.length);
        console.log("FINALIZED COUNT:::",startupFinalized);
        if(startupFinalized == 0) {
          this.submitted = false;
          this.projectDetail.get('startupsFinalized').setErrors({'isStartupCountValidationFailed': true})
          return;
        } else if(this.startupCount && ((this.startupCount.length < startupFinalized) || (this.startupCount.length == 0))) {
          this.startupCount = this.startupCount.length;
          this.submitted = false;
          this.projectDetail.get('startupsFinalized').setErrors({'isStartupCountValidationFailed': true})
          return;
        } else {
          console.log("onProjectDetailsSubmit",this.projectDetail.value);
          //this.submitted = true;
          const formDetails = {
            "scheme_id": this.schemeid,
            "incubator_id": incubatorId,
            "startups_finalized_count":startupFinalized,
            "tenureyears_count":this.projectDetail.get('tenure').value,
            "funding_source":this.projectDetail.get('fundSource').value,
            "heads":["EIR","Support/Management Cost for EiR", "Grant","Support/Management Cost for Grant","Hackathon", "Challenge Grant", "Deep Engagement Program", "Workshop"]
          }
          console.log("Form Details:::",formDetails);
          try {
            if(!this.projectDetailsResponse) {
              alert("Once details are submitted it won't be updated");
            }
            this.grantMangementService.createSchemeFundId(formDetails).subscribe(response => {
              console.log("THIS IS THE RESPONSE::::",response);
              this.schemeFundResponse = response
              console.log("Created SchemeFund Response::::", this.schemeFundResponse)
              if(this.schemeFundResponse && this.schemeFundResponse.results?.length>0) {
                this.schemeFundRes.emit(this.schemeFundResponse);
                this.toastrService.success("Created Scheme Fund Successfully!");
                this.router.navigate([`/admin/grant-management/${this.schemeFundResponse?.results[0].scheme_id}/main/${this.schemeFundResponse?.results[0].id}/${this.schemeFundResponse?.results[0].incubator_id}`]);
              } else {
                this.toastrService.warning("something went wrong!");
              }
            },error => {
              this.toastrService.danger("Incubator is already assigned. Please use another incubator for fund assign");
            });
          } catch(err) {
            this.toastrService.warning("something went wrong!");
          }
        }
      })
    }
  }

  async getTypeData(data:any) {
    try {
      this.incubatorService.getIncubatorsByGroupType(data).subscribe((res)=>{
        this.incubatorData = res;
      })
    } catch(error) {
      console.log("error:::",error);
    }

  }

  getIncProjectDetailsByIncId(inc_fund_id: any) {
    this.incubatorService.getIncubatorProjectDetails(inc_fund_id).subscribe(response => {
      this.projectDetailsResponse = response;
      sessionStorage.setItem("fundres",JSON.stringify(this.projectDetailsResponse));
      this.getPatchIncubatorData(this.projectDetailsResponse);
      this.patchValue();
      this.projectDetail.patchValue({nameOfCentre:this.projectDetailsResponse?.results[0].incubator_id})
    })
  }

  getPatchIncubatorData(res:any) {
    const value = this.projectDetailsResponse?.results[0].incubator_group;
    const scheme_id = this.projectDetailsResponse?.results[0].scheme_id;
    const data = {"id":value, "scheme": scheme_id};
    try {
      this.incubatorService.getIncubatorsByGroupType(data).subscribe((res:any)=>{
        this.incubatorData = res;
      })
    } catch (error) {
      console.log("Error in scheme incubator proj detials:::",error);
    }
  }

  patchValue() {
    console.log("PROJ DETAILS::RES::", this.projectDetailsResponse);
    this.projectDetail.patchValue({
      center_type: this.projectDetailsResponse?.results[0].incubator_group,
      startupsFinalized:this.projectDetailsResponse?.results[0].startups_finalized_count,
      tenure:this.projectDetailsResponse?.results[0].tenureyears_count,
      fundSource:this.projectDetailsResponse?.results[0].funding_source
    });
  }
}
