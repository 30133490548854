import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs-compat";
import { RoleService } from '../services/role.service';
import { HttpHeaders } from "@angular/common/http";



var headers1= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Authorization', `Bearer hello`);
@Injectable({
  providedIn: "root",
})
export class AuthGuardService {  
  baseUrl: string;  
   constructor(private router: Router,private roleService: RoleService,) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    let url = state.url;
    let loginStatus = sessionStorage.getItem("login-status");  
    console. log("loginStatus++++++++",loginStatus) 
  
   //handle 401 token expiry   
  //  if(loginStatus == "success"){   

  //   console.log("Auth Guard service headers++++++++++++++++++",headers1)
  //   console.log("sessionStorage.getItem('admintoken')++++++++",sessionStorage.getItem('admintoken'))
  //   this.roleService.getIndividualUserData()
  //   .subscribe({     
  //     error: (err)=>{
  //       console.log("testing authorization guard")
  //       console.log("error testing auth",err.error.code)
  //       if(err.status == 401 && err.error.code == "auth_token_expired"){
  //         sessionStorage.clear(); 
  //         sessionStorage.setItem("expiryflag","success");         
  //        // console.log('Unauthorized');
  //         this.router.navigate(['/auth']);
  //       }
  //     }
  //   });
  // }
  
    if (
      (url == "" ||
        url == "/" ||
        url == "/auth" ||
        url == "/auth/login" ||
        url == "/auth/register" ||
        url == "/auth/reset-password") &&
      loginStatus == "success"
    ) {
      this.router.navigate(["challenge"]);
    } else if (
      (url == "" ||
        url == "/" ||
        url == "/auth" ||
        url == "/auth/login" ||
        url == "/auth/register" ||
        url == "/auth/reset-password") &&
      loginStatus != "success"
    ) {
      return true;
    } else if (loginStatus == "success") {
      return true;
    } else {      
      this.router.navigate(['/auth']);
      return false;
    }
  }
}
