
<div class="container-fluid bg-white mb-3">
  <div class="row bg-purple border-5 p-3 cursor-pointer" (click)="challenges()">
    <div class="col-3 d-flex justify-content-center align-items-center pos_rel">
      <i class="fa fa-arrow-left mr-3 text-white font-weight-bold pos_abs leftarr" aria-hidden="true"></i>
      <div>
        
        <p class="text-white font-weight-bold">
          Challenge Name :
        </p>
        <p class="text-white font-weight-bold mb-0">
          {{challengename}}
        </p>
      </div>
    </div>
    <div class="col-3">
      <div>
        <p class="text-white font-weight-bold">
          Edition Name :
        </p>
        <p class="text-white font-weight-bold mb-0">
          {{edition_name}}
        </p>
      </div>
    </div>
    <div class="col-3">
      <div>
        <p class="text-white font-weight-bold">
          Description :
        </p>
        <p class="text-white font-weight-bold mb-0">
          {{edition_desc}}
        </p>
      </div>
    </div>
    <div class="col-3">
      <div>
        <p class="text-white font-weight-bold">
          Duration :
        </p>
        <p class="text-white font-weight-bold mb-0">
          {{start_date}} to {{end_date}}
        </p>
      </div>
      <!-- <div>
        <p class="text-white font-weight-bold">
          Start Date : {{start_date}}
        </p>
        <p class="text-white font-weight-bold mb-0">
          End Date : {{end_date}}
        </p>
      </div> -->
    </div>
  </div>
</div>
 <!-- <div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12 bg-white mb-3 d-flex justify-content-center align-items-center p-3">
      
        <ul class="list-unstyled m-auto">
          <li class="mr-3 mb-3 font-weight-bold">
            Challengename : {{challengename}}
          </li>
          <li class="mr-3 mb-3 font-weight-bold">
            Editionname: {{edition_name}}
          </li>
          <li class="mr-3 mb-3 font-weight-bold">
            Description: {{edition_desc}}
          </li>
          <li class="mr-3 mb-3 font-weight-bold">
            Start Date: {{start_date}}
          </li>
          <li class="mr-3 mb-3 font-weight-bold">
            End Date: {{end_date}}
          </li>
        </ul>
      
    </div>
  </div>
</div> -->
 <div class="container-fluid" *ngIf = "noform">
    <div class="row">
      
      <div class="col-12 col-sm-12 bg-white shadow p-5">
      
          <nb-stepper #stepper orientation="horizontal" class="p-3">
            <nb-step [stepControl]="applicationForm" label="Application">
             <div class="w-100">
               <h2 class="text-center">
                 Application Details
               </h2>
             </div>
             <div class="mt-4">
              <form
              [formGroup]="applicationForm" class="step-container col-12" id="step1">
              <div class="form-row row align-items-center justify-content-between mb-2">
                <div class="col-4 my-2">
                  <label for="" class="font-weight-bold">Start Date</label>
                  <span class="text-danger ml-1 font-weight-bold">*</span>
                      <input type="date" formControlName="startRange" readonly
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.startRange.errors }"
                      />
                      <div *ngIf="submitted && f.startRange.errors" class="text-danger">
                        <div *ngIf="f.startRange.errors['required']" class="custom_font">Start date is required</div>
                      </div>

                </div>
                <div class="col-4 my-2">
                  <label for="" class="font-weight-bold">End Date</label>
                  <span class="text-danger ml-1 font-weight-bold">*</span>
                      <input type="date" formControlName="endRange" readonly
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.endRange.errors }"
                      /> 
                      <div *ngIf="submitted && f.endRange.errors" class="text-danger">
                        <div *ngIf="f.startRange.errors['required']" class="custom_font">End date is required</div>
                        </div>
                        
                </div>

                <div class="col-4 my-2">
                  <label for="" class="font-weight-bold">Geographical Focus</label>
                  <span class="text-danger ml-1 font-weight-bold">*</span>
                  <select formControlName="geographical" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.geographical.errors }"> 
                      <option value="">Select A Region</option>
                      <option value="GLOBAL">Global</option>
                      <option value="REGIONAL">Regional</option>
                      <!-- <nb-option *ngFor="let role of roleList" [value]="role.value">{{role.title}}</nb-option> -->
                  </select>
                  <div *ngIf="submitted && f.geographical.errors" class="text-danger">
                    <div *ngIf="f.geographical.errors['required']" class="custom_font">Geography required</div>
                    </div>
                </div>
              </div>

              <div class="form-row row align-items-center justify-content-between my-2">
                <div class="col-12">
                  <label for="" class="font-weight-bold">Time Zone</label>
                  <span class="text-danger ml-1 font-weight-bold">*</span>
                  <!-- <input type="datetime" placeholder="Enter Timezone" formControlName="timeZone" [ngClass]="{ 'is-invalid': submitted && f.timeZone.errors }"
                    class="form-control"
                  /> -->
                  <!-- <ng-multiselect-dropdown
                  [placeholder]="'Timezone'"
                  [settings]="dropdownSettings_t"
                  [data]="dropdownList_t"
                  [(ngModel)]="selectedItems_t"
                  [ngModelOptions]="{standalone: true}"
                  (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)"
                  formControlName="timeZone" [ngClass]="{ 'is-invalid': submitted && f.timeZone.errors }"
                >
                </ng-multiselect-dropdown> -->

                <select name="" id="" formControlName="timeZone" (change)="getTimeZone($event)" [ngClass]="{ 'is-invalid': submitted && f.timeZone.errors }" class="form-control">
                  <option value="">Select Timezone</option>
                  <option value="IST">IST</option>
                  <option value="GMT">GMT</option>
                </select>
                  
                  <div *ngIf="submitted && f.timeZone.errors" class="text-danger">
                    <div *ngIf="f.timeZone.errors['required']" class="custom_font">Timezone is required</div>
                    </div>
                </div> 

                <div class="col-12 mb-2 mt-3">
                  <label class="font-weight-bold">Industry</label>
                  <span class="text-danger ml-1 font-weight-bold">*</span>
                  <ng-multiselect-dropdown
                  [placeholder]="'Industry'"
                  [settings]="dropdownSettings"
                  [data]="dropdownList"
                  [(ngModel)]="selectedItems"
                  [ngModelOptions]="{standalone: true}"
                  (onSelect)="onItemSelectIndustry($event)"
                  (onDeSelect)="onItemDeSelectIndustry($event)"
                  (onSelectAll)="onSelectAll($event)"
                  [ngClass]="{ 'is-invalid': submitted && f.tag.errors }"
                  formControlName = "tag"
                >
                </ng-multiselect-dropdown>
                <div *ngIf="submitted && f.tag.errors" class="text-danger">
                  <div *ngIf="f.tag.errors['required']" class="custom_font">Industry is required</div>
                  </div>
                  <!-- <div class="editor-tag d-flex" (click)="focusTagInput('sectors')">
                    <div class="d-flex justify-content-between alignitemscenter">
                      <div class="d-flex justify-content-between alignitemscenter custominputpadding" *ngFor="let tag of tags_sectors">
                        <p class="tag-item mb-0">{{tag}}</p>
                        <p (click)="removeTag(tag,'sectors')">x</p>
                      </div>
                    </div>
                         <input
                         placeholder="Type Business sector" id="sector"
                         #tagInput_sectors type="text"
                         class="input-tag form-control border-0 input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                         (keyup)="onKeyUp($event,'sectors')"
                         formControlName="tag" [ngClass]="{ 'is-invalid': submitted && f.tag.errors }">
                        
                         <div *ngIf="submitted && f.tag.errors" class="text-danger">
                          <div *ngIf="f.tag.errors['required']" class="custom_font">Industry is required</div>
                        </div>
  

                       </div> -->
                </div>

                <div class="col-12 my-2">
              
                  <label class="font-weight-bold">Sector</label>
                  <span class="text-danger ml-1 font-weight-bold">*</span>
                  <ng-multiselect-dropdown
                  [placeholder]="'Sector'"
                  [settings]="dropdownSettings"
                  [data]="dropdownList1"
                  [(ngModel)]="selectedItems1"
                  [ngModelOptions]="{standalone: true}"
                  (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)"
                  [ngClass]="{ 'is-invalid': submitted && f.businessSubSector.errors }"
                  formControlName = "businessSubSector"
                  
                >
                </ng-multiselect-dropdown>
                <div *ngIf="submitted && f.businessSubSector.errors" class="text-danger">
                  <div *ngIf="f.businessSubSector.errors['required']" class="custom_font">Sector is required</div>
                  </div>
                    <!-- <div class="editor-tag d-flex" (click)="focusTagInput('subsectors')">
                      <div class="d-flex justify-content-between alignitemscenter">
                        <div class="d-flex justify-content-between alignitemscenter custominputpadding" *ngFor="let tag of tags_subsectors">
                          <p class="tag-item mb-0">{{tag}}</p>
                          <p (click)="removeTag(tag,'subsectors')">x</p>
                        </div>
                      </div>
                      <input
                        placeholder="Type Business Sub-sector" id="subsector"
                        #tagInput_subsectors
                        type="text"
                        class="input-tag form-control input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                        (keyup)="onKeyUp($event,'subsectors')"
                        formControlName="businessSubSector" [ngClass]="{ 'is-invalid': submitted && f.businessSubSector.errors }"
                      />
                      <div *ngIf="submitted && f.businessSubSector.errors" class="text-danger">
                        <div *ngIf="f.businessSubSector.errors['required']" class="custom_font">Sector is required</div>
                      </div>
                    </div> -->
                </div>

                <div class="col-12 my-2">
                  <label class="font-weight-bold">Application Description</label>
                  <span class="text-danger ml-1 font-weight-bold">*</span>
                  <div>
                    <ckeditor name="" id="" [editor]="Editor" (keyup)="add_application_detail($event)"  [config]="editorConfig" formControlName="applicationDetail" [ngClass]="{ 'is-invalid': submitted && f.applicationDetail.errors }"></ckeditor>
                  </div>
                  <div *ngIf="submitted && f.applicationDetail.errors" class="text-danger">
                    <div *ngIf="f.applicationDetail.errors['required']" class="custom_font">Description is required</div>
                    
                  </div>
                  <p *ngIf="invalidapplicationdetail" class="text-danger">Please enter a valid description</p>
                </div>

                <div class="col-12 my-2">
                  <label class="font-weight-bold">Terms & Conditions/Eligiblity</label>
                  <span class="text-danger ml-1 font-weight-bold">*</span>
                  <!-- <div id="termsandconditions"></div> -->
                  <ckeditor name="" [editor]="Editor" [config]="editorConfig_tc" (keyup)="add_tc($event)" id="" formControlName="eligibility" [ngClass]="{ 'is-invalid': submitted && f.eligibility.errors }"></ckeditor>
                  <div *ngIf="submitted && f.eligibility.errors" class="text-danger">
                    <div *ngIf="f.eligibility.errors['required']" class="custom_font">Eligiblity is required</div>
                  </div>
                  <p *ngIf="invalid_tc" class="text-danger">Please enter a valid description</p>
                    <!-- <angular-editor formControlName="eligibility" [config]="editorConfig"></angular-editor> -->
                </div>

                <div class="col-12 my-2">
                  <label class="font-weight-bold">Keywords ?</label>
                  <span class="text-danger ml-1 font-weight-bold">*</span>
                  <!-- <ng-multiselect-dropdown
                  [placeholder]="'Sector'"
                  [settings]="dropdownSettings"
                  [data]="dropdownList2"
                  [(ngModel)]="selectedItems2"
                  [ngModelOptions]="{standalone: true}"
                  (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)"
                  [ngClass]="{ 'is-invalid': submitted && f.keywordsTag.errors }"
                  formControlName = "keywordsTag"
                >
                </ng-multiselect-dropdown> -->
                <ul id="list" class="keywords list-unstyled d-flex"></ul>
                <input type="text" id="txt" placeholder="Enter Keywords" class="form-control" [ngClass]="{ 'is-invalid': submitted && nokeyword }"
                formControlName = "keywordsTag">
                <p class="text-danger font-weight-bold">
                  Please press enter after entering each keyword
                </p>
                <!-- <div *ngIf="submitted && f.keywordsTag.errors" class="text-danger">
                  <div *ngIf="f.keywordsTag.errors['required']" class="custom_font">Keyword is required</div>
                </div> -->
                <div *ngIf="nokeyword" class="text-danger">
                  <p class="text-danger">Keyword is required</p>
                </div>
                  <!-- <div class="editor-tag d-flex" (click)="focusTagInput('keywords')">
                    <div class="d-flex justify-content-between alignitemscenter">
                      <div class="d-flex justify-content-between alignitemscenter custominputpadding" *ngFor="let tag of tags_keywords">
                        <p class="tag-item mb-0">{{tag}}</p>
                        <p (click)="removeTag(tag,'keywords')">x</p>
                      </div>
                    </div>
                    <input
                      placeholder="Type Keywords" 
                      #tagInput_keywords
                      type="text"
                      class="input-tag form-control input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      (keyup)="onKeyUp($event,'keywords')" id="keyword"
                      formControlName="keywordsTag" [ngClass]="{ 'is-invalid': submitted && f.keywordsTag.errors }"
                    />
                    <div *ngIf="submitted && f.keywordsTag.errors" class="text-danger">
                      <div *ngIf="f.keywordsTag.errors['required']" class="custom_font">Keywords is required</div>
                    </div>
                  </div> -->
                </div>

                <div class="col-12 my-2">
                  <div class="d-flex justify-content-start align-items-center">
                  <label class="font-weight-bold mr-1" for="">Upload Additional Document</label>
                  <div>
                    <p class="fs-11 font-weight-bold text-danger mb-0">
                    (File Size Should Not Exceed 2MB. Allowed Extensions: PDF , DOC , DOCX , XLSX , CSV , EXCEL)
                  </p>
                  </div>
                </div>
                  <div class="d-flex justify-content-between alignitemscenter">
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" name="uploadedImage" (change)="onFileSelect($event)" #UploadFileInput [ngClass]="{ 'is-invalid': submitted && f.uploadedImage.errors }"
                      id="inputGroupFile03">
                      <div *ngIf="submitted && f.uploadedImage.errors" class="text-danger">
                        <div *ngIf="f.uploadedImage.errors['required']" class="custom_font">Document is required</div>
                        </div>
                        <p class="text-danger" *ngIf="filetypeerr"> 
                          File format is incorrect
                        </p>
                        <p class="text-danger" *ngIf="filesizeerr"> 
                          File size should not exceed 2 MB
                        </p>
                      <label class="custom-file-label" for="customFile">{{fileInputLabel || 'Choose File'}}</label>
                    </div>
                  </div>
                
                </div>

                <div class="col-12 my-2">
                  <div class="d-flex justify-content-between alignitemscenter">
                    <p class="font-weight-bold">
                      Do you wish to access/export details of draft applications
                    </p>
                    <!-- <nb-toggle formControlName="dataExport">  </nb-toggle> -->
                    <label class="switch_export">
                      <input type="checkbox" (change)="getaccesstoexport($event)">
                      <span class="slider_export round_export"></span>
                    </label>
                  </div>
                </div>

                <div class="col-12 my-2">
                  <div class="d-flex justify-content-between alignitemscenter">
                    <p class="font-weight-bold">
                      Receive email and in-app notification for application submitted
                    </p>
                    <label class="switch_export">
                      <input type="checkbox" (change)="getaccesstoemailnotification($event)">
                      <span class="slider_export round_export"></span>
                    </label>
                    <!-- <nb-toggle formControlName="emailExport">  </nb-toggle> -->
                  </div>
                </div>
              </div>
          <!-- <div class="row">
              <div class="form-control-group col-sm-4">
                      <label for="">Start Date</label>
                      <input type="datetime-local" formControlName="startRange"
                        class="form-control date"
                      />
              </div>
              <div class="form-control-group col-sm-4">
                  <label for="">End Date</label>
                  <input type="datetime-local" formControlName="endRange"
                    class="form-control date"
                  />
               </div>
              <div class="form-control-group col-sm-4">
                  
              </div>
          </div> -->
    
          <!-- <div class="row">
              <div class="form-control-group col-sm-12">
                  <label for="">Time Zone</label>
                  <input type="datetime" placeholder="IST TimeZone" formControlName="timeZone"
                    class="form-control"
                  />
              </div>
              
            </div> -->

            <!-- <div class="row">
                <div class="form-control-group col-sm-12">
           
                </div>
              </div> -->
              <!-- <div class="row">
                  <div class="form-control-group col-sm-12">
                      
                  </div>
                </div> -->
              <!-- <div class="row">
                <div class="form-control-group col-sm-12">
                  <label>Application Desription</label>
                  <div id="applicationdesc"></div>
                </div>
                </div> -->
                <!-- <div class="row">
                <div class="form-control-group col-sm-12">
                  
                </div>
              </div>  -->
              <!-- <div class="row">
                  <div class="form-control-group col-sm-12">
              
                  </div>
                </div>  -->
              <!-- <div class="row">
                  <label>Upload Additional Documents</label>
              <div class="input-group col-sm-12">
                <div class="input-group-prepend">
                    <button class="btn btn-outline-secondary" type="button">Button</button>
                  </div>
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" formControlName="chooseFile"
                    id="inputGroupFile03">
                    <label class="custom-file-label" for="inputGroupFile03">Choose file</label>
                  </div>
                </div>
              </div>      -->

              <!-- <div class="row">
                  <div class="form-control-group col-sm-11">
                      <label for="">Do you wish to access/export details of draft applications</label><br>
                      
                      <span for="">This includes data of applicants who have signed up and accessed the form but have not submitted their application</span>
                    </div>
                      <div class="col-sm-1">
                        <nb-toggle formControlName="dataExport">  </nb-toggle>
                      </div>


              </div> -->
              <!-- <div class="row">
                  <div class="form-control-group col-sm-11">
                      <label for="">Receive email and in-app notification for application submitted</label>
                      
                  </div>
                    <div class="col-sm-1">
                      <nb-toggle formControlName="emailExport"> </nb-toggle>
                    </div>
              </div> -->
              <br>
              <div class="d-flex justify-content-center alignitemscenter">
              <button nbButton nbStepperNext type="button" #button_next id="next_step_1" [disabled]="filesizeerr || filetypeerr || invalidapplicationdetail || invalid_tc" (click)="applicationJson()" class="btn custombtn">Next</button>
            </div>
            </form>
             </div>
           
              
            </nb-step>

            <nb-step [stepControl]="companyDetailsForm" label="Company Details">
              <div id="step2"> 
                <div class="w-100">
                  <h1 class="text-center">
                    Company Details
                  </h1>
                  <p class="text-center hide font-weight-bold fs-18">
                    Select fields of the profile you wish to mark as mandatory in the application form.
                  </p>
                </div>
                <!-- <div class="text-center">
                  <label>Select fields of the profile you wish to mark as mandatory in the application form.</label>
                </div> -->
            
                <div class="mt-4">
                <form  [formGroup]="companyDetailsForm" class="step-container" id="step2form">
                  <div class="form-row row align-items-center justify-content-between mb-2">
                    <div class="col-12 my-2" id="companylogo">
                      <div class="d-flex justify-content-between alignitemscenter">
                      
                        <div class="d-flex justify-content-start align-items-center">
                        <p class="font-weight-bold mr-1 mb-1">
                          Company Logo
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyprofilepic">*</span>
                        </p>
                        <div>
                          <p class="fs-11 font-weight-bold text-danger">
                          (File Size Should Not Exceed 2MB. Allowed Extensions: PNG , JPG , SVG)
                        </p>
                        </div>
                      </div>
                        <label class="switch">
                          <input type="checkbox" (click)="allowlogo($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <label class="font-weight-bold" for="">Company Logo</label> -->
                      <div class="">
                        <div class="custom-file">
                          <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyprofilepic">*</span> -->
                          <input type="file" required name="companylogo" readonly id="requiredcompanyprofile" class="form-control requiredcompanyprofile" (change)="selectFile($event)">
                          <!-- <label class="custom-file-label" for="inputGroupFile03">Choose file</label> -->
                        </div>
                      
                      </div>
                      <div id="showcompanylogo" class="d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                      <div id="showcompanylogo_size" class="d-none">
                        <p class="text-danger">
                          File size should not exceed 2MB
                        </p>
                      </div>
                      <div id="showcompanylogo_ext" class="d-none">
                        <p class="text-danger">
                          File should be of type JPG or PNG or SVG or WEBP
                        </p>
                      </div>
                    </div>
  
                    <div class="col-6 my-2">
                      <div class="d-flex justify-content-between alignitemscenter">
                        <p class="font-weight-bold mb-1">
                          Company Name <span class="text-danger ml-1 font-weight-bold float-right showlabel">*</span>
                        </p>
                        
                        <!-- <label class="switch_disabled">
                          <input type="checkbox" disabled (click)="allowname($event)"formControlName="profileLogo">
                          <span class="slider_disabled round_disabled"></span>
                        </label> -->
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="companyname"
                        id="name"
                        placeholder="Company Name"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                      />
                      <div id="companyname" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
  
                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter">
                        <p class="font-weight-bold mb-1">
                          Registered Company Name <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyreglabel">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowregistered($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyreglabel">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="registeredcompany"
                        id="registered"
                        placeholder="Registered Company Name"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        formControlName="registered"
                        readonly
                      />
  
                      <div id="registeredcompany" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
  
                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Year of Establishment
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="yearestd">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowyear($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="yearestd">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="yearc"
                        id="year"
                        placeholder="Year of Establishment"
                        fieldsize="small"
                        readonly
                        autofocus=""
                        type="text"
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        pattern = "^\d{4}$"
                        
                      />
  
                      <div id="yearc" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
  
                      <div id="yearc_invalid" class="show_invalid d-none">
                        <p class="text-danger">
                          Please Enter Valid Year
                        </p>
                      </div>
                    </div>
  
                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Website
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyweb">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox"  (click)="allowwebsite($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div> 
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyweb">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="websitec"
                        id="website"
                        placeholder="Website"
                        fieldsize="small"
                        autofocus=""
                        type="url"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        [pattern]="urlpattern"
                        
                      />
                      <div id="websitec" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
  
                      <div id="websitec_invalid" class="show_invalid d-none">
                        <p class="text-danger mb-0">
                          Please Enter Valid URL
                        </p>
                      </div>
                    </div>
  
                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Country Name
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companycountry">*</span>
                        </p>
                        <!-- <label class="switch_disabled">
                          <input type="checkbox" disabled (click)="allowcountry($event)" formControlName="profileLogo">
                          <span class="slider_disabled round_disabled"></span>
                        </label> -->
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companycountry">*</span> -->
                      <select nbinput=""
                      fullwidth=""
                      name="countryc"
                      id="country"
                      placeholder="Country"
                      fieldsize="small"
                      autofocus=""
                      type="text"
                      readonly
                      class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
  >
                        <option value="">Select Country</option>
                        <option value="India">India</option>
                      </select>
                      <!-- <input
                                              
                      /> -->
                      <div id="countryc" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
  
                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          State Name
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companystate">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowstate($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companystate">*</span> -->
                      <select nbinput=""
                      fullwidth=""
                      name="statec"
                      id="state"
                      placeholder="State"
                      fieldsize="small"
                      autofocus=""
                      type="text"
                      readonly
                      class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2">
                        <option value="">Select A State</option>
                        <option value="{{s}}" *ngFor = "let s of states">{{s}}</option>
                      </select>
                      <!-- <input
                        
                        
                      /> -->
                      <div id="statec" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
  
                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          City Name
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companycity">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowcity($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companycity">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="cityc"
                        id="city"
                        placeholder="City"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly 
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        
                      />
                      <div id="cityc" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                         </p>
                      </div>
                    </div>
  
                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Company Stage
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companystage">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowstage($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companystage">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="stagec"
                        id="stage"
                        placeholder="Stage"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        
                      />
                      <div id="stagec" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
  
                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Industry Name
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyindustry">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowindustry($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyindustry">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="industryc"
                        id="industry"
                        placeholder="Industry"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                      />
                      <!-- <select nbinput=""
                      fullwidth=""
                      name="industryc"
                      id="industry"
                      placeholder="Industry"
                      fieldsize="small"
                      autofocus=""
                      type="text"
                      readonly
                      (change)="getSectors($event)"
                      class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2">
                        <option value="">Select Industry</option>
                        <option value="{{a.id}}" *ngFor = "let a of alldomains">{{a.name}}</option>
                      </select> -->
                      <div id="industryc" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
  
                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Sector Name
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companysector">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowsector($event)"formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companysector">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="sectorc"
                        id="sector"
                        placeholder="Sector"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        
                      />
                      <!-- <select nbinput=""
                      fullwidth=""
                      name="sectorc"
                      id="sector"
                      placeholder="Sector"
                      fieldsize="small"
                      autofocus=""
                      type="text"
                      readonly
                      class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2">
                        <option value="">Select Sector</option>
                        <option value="{{s.id}}" *ngFor = "let s of sectors_dropdown">{{s.name}}</option>
                      </select> -->
                      <div id="sectorc" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>


                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Twitter URL
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companytwitter">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowtwitter_company($event)"formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companytwitter">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="twitter_company"
                        id="twitter_company"
                        placeholder="Twitter URL"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        [pattern]="urlpattern"
                        
                      />
                      <div id="sectorc" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>

                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Instagram URL
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyinsta">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowinsta($event)"formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyinsta">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="insta"
                        id="insta"
                        placeholder="Instagram URL"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        [pattern]="urlpattern"
                        
                      />
                      <div id="sectorc" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>

                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Facebook URL
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyfacebook">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowfacebook($event)"formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyfacebook">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="facebook"
                        id="facebook"
                        placeholder="Facebook URL"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        [pattern]="urlpattern"
                        
                      />
                      <div id="sectorc" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>

                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Koo URL
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companykoo">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowkooapp($event)"formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companykoo">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="kooapp"
                        id="kooapp"
                        placeholder="Koo URL"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        [pattern]="urlpattern"
                        
                      />
                      <div id="sectorc" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>

                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          LinkedIn URL
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companylinkedin">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowlinkedin($event)"formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companylinkedin">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="linkedin_company"
                        id="linkedin_company"
                        placeholder="LinkedIn URL"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        [pattern]="urlpattern"
                        
                      />
                      <div id="sectorc" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>

                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Youtube URL
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyyt">*</span>
                        </p>

                        <label class="switch">
                          <input type="checkbox" (click)="allowyoutube($event)"formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companyyt">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="youtube"
                        id="youtube"
                        placeholder="Youtube URL"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        [pattern]="urlpattern"
                        
                      />
                      <div id="sectorc" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
  
                    <div class="col-12 my-2" >
                      <label for="trade_name" class="font-weight-bold mt-2">Description <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companydescription">*</span></label>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="companydescription">*</span> -->
                      <textarea
                      nbInput
                      fullWidth
                      placeholder="Description"
                      name="descriptionDetail"
                      class="textarea"
                      type="text"
                      id="description"
                      readonly
                      class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
        
                    ></textarea> 
                    <div id="descriptionDetail" class="show d-none">
                      <p class="text-danger mb-0">
                        This field is mandatory
                      </p>
                    </div>
                    </div>
  
    
  
                    <div class="col-12 my-4" id="profilepic_management">
                      <div class="w-100"> 
                        <h2 class="text-center font-weight-bold">Management Team</h2>
                        <div class="d-flex justify-content-between alignitemscenter">
                          <div class="d-flex justify-content-start align-items-center">
                            <p class="font-weight-bold mr-1 mb-1">
                              Profile Picture
                              <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="managementprofile">*</span>
                            </p>
                            <div>
                              <p class="fs-11 font-weight-bold text-danger">
                              (File Size Should Not Exceed 2MB. Allowed Extensions: PNG , JPG , SVG)
                            </p>
                            </div>
                          </div>
                          <label class="switch">
                            <input type="checkbox" (click)=allowprofilepic($event) formControlName="profileLogo">
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <!-- <label class="font-weight-bold" for="">Company Logo</label> -->
                        <div class="">
                          <div class="custom-file">
                            <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="managementprofile">*</span> -->
                            <input type="file" readonly class="custom-file-input m_profilepic form-control" id="m_profilepic" (change)="selectFile($event)">
                            <!-- <label class="custom-file-label" for="inputGroupFile03">Choose file</label> -->
                          </div>
                        </div>

                        <div id="showmanangementpic" class="d-none">
                          <p class="text-danger mb-0">
                          This field is mandatory
                          </p>
                        </div>

                        <div id="showmanagementpic_size" class="d-none">
                            <p class="text-danger">
                            File size should not exceed 2MB
                            </p>
                        </div>
                          <div id="showmanagementpic_ext" class="d-none">
                            <p class="text-danger">
                              File should be of type JPG or PNG or SVG or WEBP
                            </p>
                          </div>
                      </div>
                    </div>
  
                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter">
                        <p class="font-weight-bold mb-1">
                          Name
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="name_management">*</span>
                        </p>
                        <!-- <label class="switch_disabled">
                          <input type="checkbox" disabled (click)="allowmanagementname($event)" formControlName="profileLogo">
                          <span class="slider_disabled round_disabled"></span>
                        </label> -->
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="name_management">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="namem"
                        id="managementname"
                        placeholder="Name"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        
                      />
                      <div id="namem" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
                    <div class="col-6 my-2">
                      <div class="d-flex justify-content-between alignitemscenter">
                        <p class="font-weight-bold mb-1">
                          Designation
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="title_management">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowtitle($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="title_management">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="titlem"
                        id="title"
                        placeholder="Designation"
                        fieldsize="small"
                        type="text"
                        autofocus=""
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        
                      />
                      <div id="titlem" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
                    <div class="col-6 my-2">
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Tell Us More About This Team Member
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="moreabt">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="moreaboutthismember($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="moreabt">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="more"
                        id="moreaboutthismember"
                        placeholder="More About The Team Member"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        
                      />
                      <div id="more" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
              
                    </div>
                    <div class="col-6 my-2">
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Gender
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="gender_member">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)=allowgender($event) formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="gender_member">*</span> -->
                      <select nbinput=""
                      fullwidth=""
                      name="genderm"
                      id="gender"
                      placeholder="Gender"
                      fieldsize="small"
                      autofocus=""
                      readonly
                      class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2">
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                      </select>
                      <!-- <input
                        nbinput=""
                        fullwidth=""
                        name="genderm"
                        id="gender"
                        placeholder="Gender"
                        fieldsize="small"
                        type="text"
                        autofocus=""
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        
                      /> -->
                      <div id="genderm" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Nationality
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="nat_mem">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allownationality($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="nat_mem">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="nationalitym"
                        id="nationality"
                        placeholder="Nationality"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        
                      />
                      <div id="nationalitym" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
                    <div class="col-6 my-2" >
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Email
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="email_management">*</span>
                        </p>
                        <!-- <label class="switch_disabled">
                          <input type="checkbox" disabled (click)="allowemail($event)" formControlName="profileLogo">
                          <span class="slider_disabled round_disabled"></span>
                        </label> -->
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="email_management">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="emailm"
                        id="email"
                        placeholder="Email"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        pattern = "[a-z0-9]+@[a-z]+\.[a-z]{2,3}"
                        
                      />
                      <div id="emailm" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                      <div id="emailm_invalid" class="show_invalid d-none">
                        <p class="text-danger mb-0">
                          Please Enter Valid Email
                        </p>
                      </div>
                    </div>
                    <!-- <div class="col-12 my-2">
              
                    </div> -->
  
                    <div class="col-6 my-2">
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          LinkedIn url
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="m_linkedin">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowlinkedin($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="m_linkedin">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="linkedinm"
                        id="linkedin"
                        placeholder="Linkedin URL"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        [pattern]="urlpattern"
                        
                      />
                      <div id="linkedinm" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
  
                    <div class="col-6 my-2">
                      <div class="d-flex justify-content-between alignitemscenter mt-2">
                        <p class="font-weight-bold mb-1">
                          Twitter url
                          <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="m_twitter">*</span>
                        </p>
                        <label class="switch">
                          <input type="checkbox" (click)="allowtwitter($event)" formControlName="profileLogo">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="m_twitter">*</span> -->
                      <input
                        nbinput=""
                        fullwidth=""
                        name="twitterm"
                        id="twitter"
                        placeholder="Twitter URL"
                        fieldsize="small"
                        autofocus=""
                        type="text"
                        readonly
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition form-control required step2"
                        [pattern]="urlpattern"
                        
                      />
                      <div id="twitterm" class="show d-none">
                        <p class="text-danger mb-0">
                          This field is mandatory
                        </p>
                      </div>
                    </div>
                    <div class="container">
                      <div class="row">
                        <div class="col-12 d-flex justify-content-between my-2">
                     
                          <!-- <label for="" >Challenge List: </label> -->
                          <div class="d-flex justify-content-between align-items-center columnflex">
                            <div class="d-flex justify-content-between align-items-center customselectdiv">
                            
                            <select name="entitytype"  id="entitytype" class="btn btn-primary mr-3 required font-weight-bold step2 mt-2"> 
                              <option value="">Entity Type </option>
                              <option value="Start Up">Start Up</option>
                              <option value="MNC">MNC</option>
                              <!-- <option *ngFor="let data of tableData_challenge" value="{{data.name}}">{{data.name}}</option> -->
                             </select>
                             <span class="text-danger ml-1 font-weight-bold float-right showlabel customselectentity" id="mem_ent">*</span>
                             <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="mem_ent">*</span> -->
                             <label class="switch">
                              <input type="checkbox" (click)="allowentity($event)" formControlName="profileLogo">
                              <span class="slider round"></span>
                            </label>
                            <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel customselectentity" id="mem_ent">*</span> -->
                          </div>
    
                            <div id="et" class="show d-none">
                              <p class="text-danger mb-0">
                                This field is mandatory
                              </p>
                            </div>
                             
                               <!-- <label for="" >Challenge List: </label> -->
                               
    
                          </div>
    
                          <div>
    
                          </div>
                          <div class="d-flex justify-content-between align-items-center columnflex">
                          <div class="d-flex justify-content-between align-items-center customselectdiv">
                            <select name="teamsize"  id="teamsize" class="btn btn-primary required mr-3 font-weight-bold step2 mt-2"> 
                              <option value="">Team size </option>
                              <option value="1-10">1-10</option>
                              <option value="10-100">10-100</option>
                              <option value="100-500">100-500</option>
                              <option value="500-1000">500-1000</option>
                              <option value="1000-5000">1000-5000</option>
                              <option value="5000-10000">5000-10000</option>
                              <option value="10000-50000">10000-50000</option>
                              <option value="50000-100000">50000-100000</option>
                              <option value="above 1 Lakh">Above 1 Lakh</option>
                              <!-- <option *ngFor="let data of tableData_challenge" value="{{data.name}}">{{data.name}}</option> -->
                             </select>
                             <span class="text-danger ml-1 font-weight-bold float-right showlabel customselect_ts" id="mem_ts">*</span>
                             <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel" id="mem_ts">*</span> -->
                             <label class="switch">
                              <input type="checkbox" (click)="allowteamsize($event)" formControlName="profileLogo">
                              <span class="slider round"></span>
                            </label>
                            <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel customselect_ts" id="mem_ts">*</span> -->
                            </div>
                            <div id="ts" class="show d-none">
                              <p class="text-danger mb-0">
                                This field is mandatory
                              </p>
                            </div>
                          </div>
                            
                          <div class="d-flex justify-content-between align-items-center columnflex">
                          <div class="d-flex justify-content-between align-items-center customselectdiv">
                            <select name="principalcurrency"  id="principalcurrency"  class="btn btn-primary required mr-3 font-weight-bold step2 mt-2 required"> 
                              <option value="">Principal Currency</option>
                              <option value="INR">INR</option>
                              <option value="USD">USD</option>
                              <option value="GBP">GBP</option>
                              <option value="EUR">EUR</option>
                              <!-- <option *ngFor="let data of tableData_challenge" value="{{data.name}}">{{data.name}}</option> -->
                             </select>
                             <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel">*</span> -->
                             <span class="text-danger ml-1 font-weight-bold float-right showlabel customselect_pc">*</span>
                             <!-- <label class="switch_disabled">
                              <input type="checkbox_disabled" disabled formControlName="profileLogo">
                              <span class="slider_disabled round_disabled"></span>
                            </label> -->
                            <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel customselect_pc">*</span> -->
                          </div>
                            <div id="pc" class="show d-none">
                              <p class="text-danger mb-0">
                                This field is mandatory
                              </p>
                            </div>
                          </div>
                      
                              
                      </div>
                      </div>
                    </div>
                
                  </div>
                  <!-- <div class="row">
                    <div class="form-control-group col-sm-6">
                      <label for="trade_name" class="float-right">Company Logo</label>
                    </div>
                    <div class="form-control-group col-sm-1">
                      <input type="checkbox" formControlName="profileLogo"
                      name="name" id="name" class="float-right" >
                    </div>
                  </div>
                
                    <div class="card center">
                        <input type="file" 
                        (change)="selectFile($event)" />
                        <span style="color:var(--bz-theme-green);font-size:10px">or Drag &amp; Drop here</span>
                      </div> -->
                   
  
                  <!-- <div class="row">
                    <div class="form-control-group col-sm-6">
                      <label for="trade_name">Company Name</label>
                      <input type="checkbox" name="name" id="name" class="float-right"                      
                       formControlName="companyName"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="brief"
                        id="input-code"
                        placeholder="Company Name"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                    <div class="form-control-group col-sm-6">
                      <label for="trade_name">Registered Company Name</label>
                      <input type="checkbox" name="name" id="name" class="float-right"                     
                        formControlName="registered"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="Contact details"
                        id="input-code"
                        placeholder="Registered Company Name "
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                  </div> -->
  <!--            
                  <div class="row">
                    <div class="form-control-group col-sm-6">
                      <label for="trade_name">Year of Establishment</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="established"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="brief"
                        id="input-code"
                        placeholder="Year of Establishment"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                    <div class="form-control-group col-sm-6">
                      <label for="trade_name">Website</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="website"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="Contact details"
                        id="input-code"
                        placeholder="Website"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                  </div>
        
                  <div class="row">
                    <div class="form-control-group col-sm-6">
                      <label for="trade_name">Country Name</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="countryName"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="brief"
                        id="input-code"
                        placeholder="Country"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                    <div class="form-control-group col-sm-6">
                      <label for="trade_name">State Name</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="stateName"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="Contact details"
                        id="input-code"
                        placeholder="State"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                  </div>
        
                  <div class="row">
                    <div class="form-control-group col-sm-6">
                      <label for="trade_name">City Name</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="cityName"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="brief"
                        id="input-code"
                        placeholder="City"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                    <div class="form-control-group col-sm-6">
                      <label for="trade_name">Company stage</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="companyStage"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="Contact details"
                        id="input-code"
                        placeholder="Company stage"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                  </div>
        
                  <div class="row">
                    <div class="form-control-group col-sm-6">
                      <label for="trade_name">Industry Name</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="industry"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="brief"
                        id="input-code"
                        placeholder="Industry"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                    <div class="form-control-group col-sm-6">
                      <label for="trade_name">Sector Name</label>
                      <input type="checkbox" name="name" id="name" class="float-right"
                      formControlName="sectorName"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="Contact details"
                        id="input-code"
                        placeholder="Sector"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                  </div> -->
                  <!-- <div class="row">
                    <div class="form-control-group col-sm-12">
                      <label for="trade_name">Description </label>
                      <textarea
                      nbInput
                      fullWidth
                      placeholder="Description"
                      formControlName="descriptionDetail"
                      class="textarea"
                      class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
        
                    ></textarea> 
                    </div>
                  </div> -->
                 
        
                 <!-- <div class="row col-12 px-0">
                  <label>Connect your social accounts</label>
                </div>
        
                <div class="row">
                  <div class="col-sm-2">
                    <button class="btn btn-primary"> <span class="icon fab fa-twitter"> Connect</span></button>       
                  </div>
                  <div class="col-sm-2">
                    <button class="btn btn-primary"> <span class="icon fab fa-facebook"> Connect</span></button>       
                  </div>
                  <div class="col-sm-2">
                    <button class="btn btn-primary"> <span class="icon fab fa-linkedin-in"> Connect</span></button>       
                  </div>
                  <div class="col-sm-2">
                    <button class="btn btn-primary"> <span class="icon fab fa-connect"> Connect</span></button>       
                  </div>
                  <div class="col-sm-2">
                    <button class="btn btn-primary"> <span class="icon fab fa-instagram"> Connect</span></button>       
                  </div>
                </div> -->
        
                <br>
                <!-- <h3>Management Team</h3><br> -->
             
              <!-- <div class="col-12 row px-0">
                <div class="col-md-12 row input-box form-group justify-content-center">
                  <div class="row profile-picture fileinput-button text-center justify-content-center ng-isolate-scope">
                    <div class="row col-md-8 p-1 mandatory-row">
                      <label for="trade_name">Profile Picture</label>
                      <input type="checkbox" name="company_logo" id="company_logo" class="ng-valid ng-dirty ng-valid-parse ng-empty ng-touched" 
                      formControlName="profilePicture"
                      >
                    </div>
                    <div class="camara-img col-md-8" style="border:solid 1px grey;padding:30px">
                      <img src="../../../../assets/images/camera.png">
                      <div class="upload-button">
                        <button type="button" class="btn ng-pristine ng-untouched ng-valid ng-isolate-scope ng-empty" upload-file-types="profile" >
                          <div class="upload-img"></div>Upload
                        </button>
                      </div>
                      <span style="color:var(--bz-theme-green);font-size:10px">or Drag &amp; Drop here</span>
                    </div>
                  </div>
                </div>
              </div>
                  <div class="row">
                    <div class="form-control-group col-sm-6">
                      <label for="">Name</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="managementName"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="brief"
                        id="input-code"
                        placeholder="Name"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
        
                    
                    <div class="form-control-group col-sm-6">
                      <label for="">Title</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="managementTitle"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="Contact"
                        id="input-code"
                        placeholder="Title "
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-control-group col-sm-6">
                      <label for="">Year of Establishment</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="managementEstabl"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="brief"
                        id="input-code"
                        placeholder="Year of Establishment"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                    <div class="form-control-group col-sm-6">
                      <label for="">Gender</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="gender"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="details"
                        id="input-code"
                        placeholder="Gender"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                  </div>
        
                  <div class="row">
                    <div class="form-control-group col-sm-6">
                      <label for="">Nationality</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="nationality"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="brief"
                        id="input-code"
                        placeholder="Nationality"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                    <div class="form-control-group col-sm-6">
                      <label for="">Email</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="managementEmail"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="details"
                        id="input-code"
                        placeholder="Email"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                  </div>
        
                  <div class="row">
                    <div class="form-control-group col-sm-6">
                      <label for="">Contact Number</label>
                      <input type="checkbox" name="name" id="name" class="float-right" 
                      formControlName="contactNumber"
                      >
                      <input
                        nbinput=""
                        fullwidth=""
                        name="brief"
                        id="input-code"
                        placeholder="Contact Number"
                        fieldsize="small"
                        autofocus=""
                        class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                      />
                    </div>
                   
                  </div>
                
                  <div class="row">
                    <div class="form-control-group col-sm-12">
                      <label for="">Tell us more about this team member</label>
                      <textarea
                      nbInput
                      fullWidth
                      placeholder="Tell us more about this team member"
                      formControlName="managementDescription"
                      class="textarea"
                      class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                    ></textarea> 
                    </div>
                  </div>
                  <div class="row">
                  <div class="input-group mb-3 col-sm-6">
                      <label for="">Title</label>
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <span class="icon fab fa-twitter"></span>
                       
                      </div>
                    </div>
                    <input type="text" style="border:0" name="twitter" class="form-control ng-pristine ng-untouched ng-valid ng-empty" disabled="disabled" placeholder="Enter Twitter URL">
                  </div>
                  <div class="input-group mb-3 col-sm-6">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <span class="icon fab fa-linkedin-in"></span>
                      </div>
                    </div>
                    <input type="text" style="border:0" name="twitter" class="form-control ng-pristine ng-untouched ng-valid ng-empty" disabled="disabled" placeholder="Enter LinkedIn URL">
                  </div>
                  </div> -->
                  <!-- <div class="row">
                  <div class="form-control-group col-sm-4 ">
                    
                    <select name=""  id="" class="btn btn-primary " > 
                     <option value="">Entity Type </option>
                     
                    </select>
                    </div>
                    <div class="form-control-group col-sm-4 ">
                      
                      <select name=""  id="" class="btn btn-primary " > 
                       <option value="">Team size </option>
                       
                      </select>
                      </div>
                      <div class="form-control-group col-sm-4 ">
                        <select name=""  id="" class="btn btn-primary " > 
                         <option value="">Principal Currency</option>
                         
                        </select>
                        </div>
                      </div> -->
        <!-- <br>   -->
                <div class="d-flex w-100 justify-content-center">
                  <button nbButton nbStepperPrevious class="btn custombtnprev mr-3" id="prev_step_2" type="button">Prev</button>
                  <button nbButton nbStepperNext (click)="companyJson()" id="next_step_2" type = "button" class="btn custombtn">Next</button>
                </div>
               
                </form>
                </div>
              </div>
          
            </nb-step> 
      
      
            
             <nb-step [stepControl]="applicationSectionForm" label="Application Section" >
              
              <div id="step3">
                <div class="w-100">
                  <h2 class="text-center">
                    Application Section
                  </h2>
                </div>
                  <div class="mt-4">
  
                  <form [formGroup]="applicationSectionForm"  class="step-container" id="step3form">
                    <div class="form-row row align-items-center justify-content-between mb-2">
                      <div class="w-100 hide">
                        <p class="text-center font-weight-bold">
                          Create an Application form by selecting sections of the profile that you wish to mark as mandatory in the application form. Do not forget to publish the form to start receiving applications
                        </p>
                        <p>
  
                        </p>
                      </div>
  
                        <div class="container">
                          <div class="row d-flex justify-content-center">
                            <div class="col-6 my-2 hide">
                              <div class="align-items-center justify-content-center mb-3">Select Sections that you wish to mark as mandatory</div>
                              <!-- <h3>Left Fields</h3> -->
                                        <div *ngFor="let field of leftFields" class="hide">
                                          <div class="bg-transparent border-5 border border-primary cursor-pointer tabDesign shape-rectangle nb-transition d-flex justify-content-between p-3 alignitemscenter mb-3 text-primary font-weight-bold" (click)="moveToRight(field)">
                                            <!-- <button class="bg-transparent border-0 text-primary custombtn" -->
                                             {{ field.name }} 
                                             
                                            <!-- </button> -->
                                            <i  class="icon fa fa-plus text-primary mandatoryplus" style = "color:white float-right"></i>
                                          </div>
                                             
                                        </div>
                            </div>
                            <div class="col-6 my-2 px-4">
                              <div class="align-items-center justify-content-center mt-0 mb-3 hide">Mandatory Sections</div>
                              
                                   <!-- <h3>Right Fields</h3> -->
                                     <div *ngFor="let field of rightFields;let i = index">
                                      <div class="bg-orange border-5 cursor-pointer tabDesign shape-rectangle text-white font-weight-bold nb-transition justify-content-between p-3 alignitemscenter mb-3" (click)="moveToLeft(field)">
                                        <!-- <button class="bg-transparent border-0 text-primary custombtn"
                                        >  -->
                                        {{ field.name }} 
                                        <span class="text-danger ml-1 font-weight-bold showlabel">*</span>
                                        
                                      <!-- </button> -->
                                      <i  class="icon fa fa-minus text-white mandatoryminus font-weight-bold" style = "color:red ;float:right"></i>
                                      </div>
                                     
                                        <!-- <input type="text" name="{{field.name}}" id="step3val_{{i}}" readonly class="form-control my-3 step3val"> -->
                                        <!-- <span class="text-danger ml-1 font-weight-bold float-right showlabel">*</span> -->
                                        <textarea type="text" name="{{field.name}}" id="step3val_{{i}}" placeholder="enter  {{field.name | lowercase}}" readonly class="form-control my-3 step3val"></textarea>
                                        <!-- <ckeditor [editor]="Editor" formControlName="newsDescription" [config]="editorConfig"></ckeditor> -->
                                        <div id="companyname" class="show_step_3 d-none" id="show_step_3_{{i}}">
                                          <p class="text-danger">
                                            This field is mandatory
                                          </p>
                                        </div>
                                     
                                 
                                      
                                     </div>
                              
                            </div>
  
                            <!-- <div class="col-12 my-2 hide">
                              <div class="d-flex justify-content-start alignitemscenter">
                                <div>
                                <p class="font-weight-bold mr-2">
                                  Allow Applicants to Add Optional Sections
                                </p>
                                <p>
                                  If switch is off and no section is marked mandatory, applicants will not have this step in the apply process.
                                </p>
                              </div>
                                <label class="switch_export">
                                  <input type="checkbox">
                                  <span class="slider_export round_export"></span>
                                </label>
                              </div>
                            </div> -->
                          </div>
                        </div>
                    </div>
                  <!-- <div class="main-content"> -->
                      <!-- <div class="text1 text-center">Create an Application form by selecting sections of the profile that you wish to mark as mandatory in the application form. Do not forget to publish the form to start receiving applications</div> -->
  
                  <!-- <nb-card>
                      <div class="content row col-12 justify-content-between px-0">
                    <div class="content1 col-6">
                      <div class="text2 row align-items-center justify-content-center">Select Sections that you wish to mark as mandatory</div>
                    
                                <div *ngFor="let field of leftFields" class="col-5" >
                                  <div class="bg-primary tabDesign shape-rectangle nb-transition d-flex justify-content-between p-2">
                                    <button class="bg-transparent border-0 text-white custombtn"
                                     > {{ field.name }} 
                                     
                                    </button>
                                    <i (click)="moveToRight(field)" class="icon fa fa-plus text-white" style = "color:white float-right"></i>
                                  </div>
                                     
                                </div>
  
                    </div>
                     <div class="content1 col-6">
                      <div class="text2 row align-items-center justify-content-center">Sections marked 
                          <strong> &nbsp;mandatory &nbsp;</strong>for the application </div>
                          <div class="container">
                            
                               <div *ngFor="let field of rightFields" class="col-5">
                                <button class="btn btn-primary shape-rectangle nb-transition tabDesign"
                                > {{ field.name }} 
                                <i (click)="moveToLeft(field)" class="icon fa fa-minus col-1" style = "color:red float-right"></i>
                              </button>
                               </div>
                          </div>
                      </div> 
                  </div>
  
                  </nb-card> -->
                    <!-- <div class="row">
                      <div class="form-control-group col-sm-11" style="line-height:1.5">
                        <strong>Allow applicants to add optional sections </strong>
                        <br> If switch is off and no section is marked mandatory, applicants will not have this step in the apply process.
                      </div>
                      <div class="form-control-group col-sm-1">
                        <nb-toggle > </nb-toggle>
                      </div>
                    </div> -->
                  
               <!-- </div> -->
                
                <div class="d-flex w-100 justify-content-center alignitemscenter">
                <button nbButton nbStepperPrevious class="btn custombtnprev mr-3" type="button" id="prev_step_3">Prev</button>
                <button nbButton nbStepperNext (click)="sectionJson()" type="button" id="next_step_3" class="btn custombtn">Next</button>
              </div>
                       </form> 
                  </div>
              </div>
         
           </nb-step> 
      
           <nb-step [stepControl]="additionalQuestionForm" label="Additional Question" >
            <div id="step4">
              <div class="w-100">
                <h2 class="text-center">
                  Additional Questions
                </h2>
               </div>
                <div class="mt-4 w-100">
                  <div class="d-flex justify-content-between mt-3 alignitemscenter w-100 mb-3">
                    <a class="font-weight-bold text-white bg-purple p-2 border-5" (click)="addEditor()" id="addquestion">
                      <i class="icon fa fa-plus text-white font-weight-bold mr-2"></i>
                      Add Question
                    </a>
    
                    <!-- <button id="removequestion" (click)="removeEditor(i)" class="btn btn-danger custombtnborder d-flex justify-content-center alignitemscenter">
                      <i class="icon fa fa-plus text-white font-weight-bold"> Remove Question</i>
                    </button> -->
                  </div>
              
             <form [formGroup]="editorsForm" id="step4form">
           
              <div class="form-row row align-items-center justify-content-between mb-2">
                <!-- <div class="col-12"> -->
                  <!-- <div class="row"> -->
                    <div class="col-12">
                      <div formArrayName="editors">
                        <div *ngFor="let editor of editors.controls ; let i=index">
                          <div class="form" id="form{{i}}">
                          <div class="row w-100 border border-5 border-secondary p-3 mb-4 removeinui">
                            <div class="col-10">
                              <input type="text" formControlName="{{i}}" class="form-control hideonpreview" placeholder="Please Enter Question" value="" (keyup)="getlabel($event,i)">
                              <label for="" id="label{{i}}"></label>
                              <div id="answer{{i}}">
                                
                              </div>
                              <a id="removequestion" (click)="removeEditor(i)" class="ml-auto text-white bg-orange font-weight-bold float-right mb-3 removequestion p-2 border-5">
                                <i class="icon fa fa-minus text-white font-weight-bold mr-2"></i>
                                Remove Question
                              </a>
                            </div>
                            <div class="col-2">
                              <div formArrayName="menus">
                                <div *ngFor="let menu of menus.controls | unique;">
                                  <select (change)="addInput_m(i,$event)" class="btn bg-transparent border-info selectfield" disabled="true"> 
                                      <option value="">Select Answer Type</option>
                                      <option value="textarea">Long Answer</option>
                                      <option value="text">Short Answer</option>
                                      <option value="radio">Radio</option>
                                      <option value="checkbox">Checkbox</option>
                                      <option value="file">File Upload</option>
                                      <option value="Number">Enter Number</option>
                                      <option value="date">Select Date</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          
                       
                          </div>
                          <!-- <input type="text" formControlName="{{i}}" class="form-control hideonpreview" value="" (keyup)="getlabel($event,i)">
                          <label for="" id="label{{i}}"></label>
                          <div formArrayName="menus">
                            <div *ngFor="let menu of menus.controls | unique; let i=index">
                              {{i}}
                              <select (change)="addInput_m(i,$event)" class="btn bg-transparent border-info">
                                  <option value="">Select Answer Type</option>
                                  <option value="textarea">Long Answer</option>
                                  <option value="text">Short Answer</option>
                                  <option value="radio">Radio</option>
                                  <option value="checkbox">Checkbox</option>
                                  <option value="file">File Upload</option>
                                  <option value="select">Drop Down</option>
                                  <option value="Number">Enter Number</option>
                                  <option value="date">Select Date</option>
                              </select>
                            </div>
                          </div> -->
                      
                          <!-- <angular-editor formControlName="{{i}}" class="textarea"> </angular-editor> -->
                          <!-- <input type="checkbox" name="company_logo" class="mandatory" id="mandatory_0">
                          <label for="trade_name"> Mark Question as Mandatory </label><br> -->
                          <!-- <i (click)="removeEditor(i)" class="icon fa fa-minus col-1" style = "color:red"> Question</i> -->
                        </div>
                        </div>
                    </div>
                    <!-- </div> -->
                    <!-- <div class="col-2">
                      <div formArrayName="menus">
                        <div *ngFor="let menu of menus.controls; let i=index">
                          <select (change)="addInput_m(i,$event)" class="btn bg-transparent border-info">
                              <option value="">Select Answer Type</option>
                              <option value="textarea">Long Answer</option>
                              <option value="text">Short Answer</option>
                              <option value="radio">Radio</option>
                              <option value="checkbox">Checkbox</option>
                              <option value="file">File Upload</option>
                              <option value="select">Drop Down</option>
                              <option value="Number">Enter Number</option>
                              <option value="date">Select Date</option>
                          </select>
                        </div>
                      </div>
                    </div> -->
    <!-- 
                    <ul>
                      <li *ngFor = "let t of testdata | unique">
                        {{t}}
                      </li>
                    </ul> -->
                  </div>
             
                
              
    
                <div id="addhere" class="d-flex justify-content-center alignitemscenter">
                      
                </div>
                   
                       
                <!-- </div> -->
              </div>
                <!-- <div class="editorArea">
                   <div class="row" >
                    <div formArrayName="editors" class="col-sm-10">
                      <div *ngFor="let editor of editors.controls; let i=index">
                        <div id="form{{i}}">
                        <input type="text" formControlName="{{i}}" class="form-control hideonpreview" value="" (keyup)="getlabel($event)">
                        <i (click)="removeEditor(i)" class="icon fa fa-minus col-1" style = "color:red"> Question</i>
                      </div>
                      </div>
                  </div>
                  
                  <div formArrayName="menus" class="form-control-group col-sm-2">
                    <div *ngFor="let menu of menus.controls; let i=index">
    
                    <label for="">Select Answer Type</label><br>
                      <select (change)="addInput(i,$event)" class="appearance-filled size-small shape-rectangle status-primary nb-transition space">
                          <option value="">Select</option>
                          <option value="textarea">Long Answer</option>
                          <option value="text">Sort Answer</option>
                          <option value="radio">Multiple Select</option>
                          <option value="checkbox">Multiple Choice</option>
                          <option value="file">File Upload</option>
                          <option value="select">Drop Down</option>
                          <option value="Number">Enter Number</option>
                          <option value="date">Select Date</option>
                      </select>
                    </div>
                  </div>
               </div>  
            </div> -->
            <!-- <div id="addhere">
                      
            </div>
                   <i (click)="addEditor()" id="addquestion" class="icon fa fa-plus col-1" style = "color:blue"> Question</i> -->
                <div class="d-flex justify-content-center w-100">
                  <button nbButton nbStepperPrevious class="btn custombtnprev mr-3" id="prev_step_4" type="button">Prev</button>
                 <button nbButton nbStepperNext (click)="createApplicationForm()" disabled="true" id="next_step_4" type="button" class="btn custombtn">Next</button>
                </div>
                  
            </form>
              
                </div>
            </div>
       
            <!-- <button (click)="additionalJson()">Convert to json</button> -->
            <!-- <button (click)="converttohtml()">Convert to html</button> -->
       </nb-step> 
      
       <nb-step [stepControl]="reviewPublishForm" label="Review" class="step4width">
              
        <!-- <nb-card-header >Review Publish</nb-card-header> -->
        <div class="grid-container col-sm-12">
        <form [formGroup]="reviewPublishForm"  class="step-container">
      <!-- <div class="row">
        <div class="form-control-group col-sm-12">
          <input
            nbinput=""
            fullwidth=""
            name="brief"
            id="input-code"
            placeholder="Contact"
            fieldsize="small"
            autofocus=""
            class="input-full-width size-small status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
          />
        </div>
        
      </div> <br> -->
      <div class="w-100 d-flex justify-content-center align-items-center">
      <!-- <button nbButton nbStepperPrevious class="btn btn-primary custombtnprev mr-3" type="button" id="prev_step_5">prev</button> -->
      <button nbButton nbStepperNext type="button" [disabled]= "disable_publish" id="next_step_5" class="btn btn-primary custombtn" (click)="publish()">confirm</button>
    </div>
      </form> 
      </div>
      </nb-step> 
      
            <!-- <nb-step [stepControl]="reviewPublishForm" [hidden]="true" label="Review Publish">
      
              <div class="step-container">
                <h3>Wizard completed!</h3>
              </div>
            </nb-step>  -->
           </nb-stepper>
        
      </div>
    </div>
  </div>
  

<div class="container-fluid bg-white p-3" *ngIf = "!noform">
  <div class="row my-3">
  <div class="col-12 d-flex justify-content-center align-items-center p-3">
    <h2 class="mb-0">
      Challenge Form Preview
    </h2>
  </div>
  </div>
  <div class="row">

    <div class="col-12">
      <!-- <div id="placehere">
        
      </div> -->

      <div id="placehere2">
        
      </div>

      <div id="placehere3">
        
      </div>

      <div id="placehere4">
        
      </div>
    </div>
  </div>
</div>
<div id="editor">

</div>

<!-- <input type="checkbox" name="" (click)="addmandatory()" id="" class="test1234"> test -->
<!-- <input type="text" name="" id="" class="form-control my-2" placeholder="enter question">
<div class="w-100">
<select class="form-control float-right selectfield">
  <option value="">Select</option>
  <option value="textarea">Long Answer</option>
  <option value="text">Sort Answer</option>
  <option value="radio">Multiple Select</option>
  <option value="checkbox">Multiple Choice</option>
  <option value="file">File Upload</option>
  <option value="select">Drop Down</option>
  <option value="Number">Enter Number</option>
  <option value="date">Select Date</option>
</select>
</div>
<textarea name="" id="" cols="30" rows="10" class="form-control addck" placeholder="enter answer"></textarea> -->
<!-- <input type="text" name="" id="" class=form-control> -->

<!-- <input type="text" name="" class="form-control" id="">
<input type="checkbox" value="mandatory">This Field is Mandatory; -->
