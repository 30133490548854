import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

const headers = new HttpHeaders()
    .set("content-type", "application/json")
    .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

@Injectable({
    providedIn: "root",
})
export class GrantUploadsService {
    entity_type:string='INCUBATOR'
    baseUrl = environment.apiurl;
    constructor(private http: HttpClient) { }

    // getUploads(schemeId: any) {
    //     const url = this.baseUrl + `grantuploads/${schemeId}?entity_type=${this.entity_type}`;
    //     return this.http.get(url, { headers: headers }).pipe(catchError((err) => throwError(err)));
    // }

    // uploadFile(data: any) {
    //     const url = this.baseUrl + `files?intent=grant-uploads`;
    //     let uploadHeaders = headers.delete("content-type");
    //     uploadHeaders.append("content-type", 'multipart/form-data');
    //     // console.log(headers)
    //     return this.http.post(url, data, { headers: uploadHeaders }).pipe(catchError((err) => throwError(err)));
    // }

    // createUpload(payload: any) {
    //     const url = this.baseUrl + `grantuploads/create?entity_type=${this.entity_type}`;
    //     return this.http.post(url, payload, { headers: headers }).pipe(catchError((err) => throwError(err)));
    // }

    // updateUpload(id: any, payload: any) {
    //     const url = this.baseUrl + `grantuploads/${id}?entity_type=${this.entity_type}`;
    //     return this.http.put(url, payload, { headers: headers }).pipe(catchError((err) => throwError(err)));
    // }

    // deleteUpload(id: any) {
    //     const url = this.baseUrl + `grantuploads/${id}?entity_type=${this.entity_type}`;
    //     return this.http.delete(url, { headers: headers }).pipe(catchError((err) => throwError(err)));
    // }

}