<!-- <div class="container">
  <div class="whitebg">
    <table class="table table-striped table-responsive">
      <thead>
        <tr>
          <th scope="col">Incubator Name</th>
          <th scope="col">State​</th>
          <th scope="col">Incubator Type​</th>
          <th scope="col">Start-Up Name</th>
          <th scope="col">Field/ Domain​</th>
          <th scope="col">CP-1​</th>
          <th scope="col">CP-2</th>
          <th scope="col" class="col-2">Start up Detailed Document​</th>
          <th scope="col">Actions​</th>

        </tr>
      </thead>
      <tbody>
        <tr>
          <td>fgfdg</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>@mdo</td>
          <td>@mdo</td>
          <td>@mdo</td>
          <td>@mdo</td>
           <td>
            <i class="dropbtn fa fa-check " style="color:green"></i>
            <i class="dropbtn fa fa-close px-1 " style="color:red"></i>
            <i class="fa fa-eye arrow " style="color:blue"></i>
        </td>
        </tr>
        <tr>
          <td>fgfdg</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>@mdo</td>
          <td>@mdo</td>
          <td>@mdo</td>
          <td>@mdo</td>
          <td>
            <i class="dropbtn fa fa-check " style="color:green"></i>
            <i class="dropbtn fa fa-close px-1" style="color:red"></i>
            <i class="fa fa-eye arrow " style="color:blue">
  
            </i></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
 -->
<div class="container py-3">
  <div class="row justify-content-end">
    <div class="col-12 col-md-6">
      <div *ngIf="approveMsg" class="alert alert-success alert-dismissible fade show" role="alert">
        {{approveMsg}}
         </div>
         <div *ngIf="rejectMsg" class="alert alert-danger alert-dismissible fade show" role="alert">
          {{rejectMsg}}
           </div>
    </div>
  </div>
 
</div>
<div class="container">
  <div class=" whitebg table-wrapper ">
    <div class="d-flex justify-content-between align-items-center mb-3 p-3">
      <h2 class="text-center eir-grant mb-0">Startup Table</h2>
      <button type="button" class="btn btn-primary text-end" (click)="downloadCSV()">Download</button>
    </div>


    <table class="table table-responsive text-center">
      <thead>
        <tr>
          <th>S.No</th>
          <th scope="col">Agency Name</th>
          <th scope="col">State​</th>
          <!-- <th scope="col">Agency Type​</th> -->
          <th scope="col">Start-Up Name</th>
          <th scope="col">Field/ Domain​</th>
          <th scope="col">Contact Person-1​</th>
          <th scope="col">Contact Person-2</th>
          <th scope="col">Contact Person-3</th>
          <th scope="col" class="col-2" *ngIf="scheme_id!= 2">Start up Detailed Document​</th>
          <!-- <th scope="col">Actions​</th> -->
          <th *ngIf="scheme_id==2">Status</th>
          <th *ngIf="scheme_id ==2 ">Actions</th>

        </tr>
      </thead>
      <tbody class="newscroll">
        <tr *ngFor="let item of newincubatorData | paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index">
          <td class="text-center">{{i+1 + (p - 1) * pageSize}}</td>
          <td>{{item.agency_name
            }}</td>
          <td>{{item.contact_info?.state}}</td>
          <!-- <td>{{item.group? item.group:"-"}}</td> -->
          <td>{{item.name}}</td>
          <td>{{item.domain}}</td>
          <td>
        <tr>
          <td>{{ item.team_info.teamInfo[0] ? item.team_info.teamInfo[0].team_meb_name : "-" }}</td>
        </tr>
        <tr>
          <td>{{ item.team_info.teamInfo[0] ? item.team_info.teamInfo[0].team_meb_designation : "-" }}</td>
        </tr>
        <tr>
          <td>{{ item.team_info.teamInfo[0] ? item.team_info.teamInfo[0].team_meb_email : "-" }}</td>
        </tr>
        <tr>
          <td>{{ item.team_info.teamInfo[0] ? item.team_info.teamInfo[0].team_meb_mob : "-" }}</td>
        </tr>
        </td>
        <td>
          <tr>
            <td>{{ item.team_info.teamInfo[1] ? item.team_info.teamInfo[1].team_meb_name : "-" }}</td>
          </tr>
          <tr>
            <td>{{ item.team_info.teamInfo[1] ? item.team_info.teamInfo[1].team_meb_designation : "-" }}</td>
          </tr>
          <tr>
            <td>{{ item.team_info.teamInfo[1] ? item.team_info.teamInfo[1].team_meb_email : "-" }}</td>
          </tr>
          <tr>
            <td>{{ item.team_info.teamInfo[1] ? item.team_info.teamInfo[1].team_meb_mob : "-" }}</td>
          </tr>
        </td>

        <td>
          <tr>
            <td>{{ item.team_info.teamInfo[2] ? item.team_info.teamInfo[2].team_meb_name : "-" }}</td>
          </tr>
          <tr>
            <td>{{ item.team_info.teamInfo[2] ? item.team_info.teamInfo[2].team_meb_designation : "-" }}</td>
          </tr>
          <tr>
            <td>{{ item.team_info.teamInfo[2] ? item.team_info.teamInfo[2].team_meb_email : "-" }}</td>
          </tr>
          <tr>
            <td>{{ item.team_info.teamInfo[2] ? item.team_info.teamInfo[2].team_meb_mob : "-" }}</td>
          </tr>
        </td>
        <td *ngIf="scheme_id!= 2">
          <tr>
            <td>
              <ng-container *ngFor="let document of item.documents ">
                <a href="{{ document.url }}" target="_blank">{{ document.file_name }}</a><br>
              </ng-container>
            </td>
          </tr>
        </td>
        <td *ngIf="scheme_id ==2 ">{{item.grant_status}}</td>
        <td *ngIf="scheme_id ==2 " class="d-flex">
          <i class="fa fa-eye arrow px-1" data-bs-toggle="modal" data-bs-target="#exampleModal"
            (click)="newData(item)"></i>
          <ng-container *ngIf="item.grant_status!== 'APPROVED'">
            <i class="dropbtn fa fa-check px-1" style="color:green"
              (click)="approveTermFund(item)"></i>
          </ng-container>
          <ng-container *ngIf="item.grant_status!=='REJECTED'">
            <i class="dropbtn fa fa-close " (click)="rejectTermFund(item)"  style="color:red"></i>
          </ng-container>
        </td>

      </tbody>
    </table>
    <pagination-controls *ngIf="newincubatorData?.length > 0" (pageChange)="onPageChange($event)" directionLinks="true"
      class="float-right"></pagination-controls>

  </div>
</div>

<!-- Samridh Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel"><b>Startup Documents</b></h4>
        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button> -->
      </div>
      <div class="modal-body scroll-bar">
        <div>
          <p><b>Accelerator Name</b> :{{modalData?.agency_name}} </p>
          <p><b>Startup Name</b> : {{modalData?.name}}</p>
          <p><b>Location</b> :{{modalData?.state}} </p>
          <p><b>Sector</b> : {{modalData?.sector}}</p>
          <p><b>Current Valuation</b> :{{modalData?.grant_data?.current_valuation  + 'Cr'}} </p>
          <p><b>Valuation Method</b> :{{modalData?.grant_data?.valuation_method}} </p>

          <p><b>Total matching Funding Raised</b> :{{modalData?.grant_data?.matching_funding_raised + 'L'}} </p>
          <p><b>Investement Round(Pre Series A, Series A,B,C)</b>:{{modalData?.grant_data?.investment_round}} </p>
          <p><b>Key Investors</b>:{{modalData?.grant_data?.key_investors}}</p>
          <p><b>Matching fund required from MeitY</b> :{{modalData?.grant_data?.matching_fund_meity + 'L'}} </p>
          <p><b>Investment Instrument Used</b> :{{modalData?.grant_data?.investment_instrument}} </p>
          <p><b>Amount of Equity/Shares held on behalf of MSH</b> :{{modalData?.grant_data?.equity_held_msh}} </p>
          <p><b>Amount of Equity Held</b>:{{modalData?.grant_data?.equity_held + '%'}}</p>
          <p><b>Terms of Investment (conversation rate.Divident rate
              etc.):{{modalData?.grant_data?.investment_terms}}</b></p>
          <p><b>Exit Terms and Modes</b>: {{modalData?.grant_data?.exit_terms}}</p>
          <p><b>Certificate of Incorporation</b>:
            <a *ngIf="ciDocument" [href]="ciDocument.url" target="_blank" class="text-underline text-primary">
              View Document
            </a>
          <p><b>DPIIT Certificate</b>:
            <a *ngIf="dpiitDocument" [href]="dpiitDocument.url" target="_blank" class="text-underline text-primary">
              View Document
            </a>
          <p><b>Acceleration Agreement</b>:

            <a *ngIf="aaDocument" [href]="aaDocument.url" target="_blank" class="text-underline text-primary">
              View Document
            </a>
          <p><b>Accelerator Investment Agreement</b>:
            <a *ngIf="aiaDocument" [href]="aiaDocument.url" target="_blank" class="text-underline text-primary">
              View Document
            </a>
          <p><b>Investor Agreement</b>:
            <a *ngIf="iaDocument" [href]="iaDocument.url" target="_blank" class="text-underline text-primary">
              View Document
            </a>
          <p><b>Pitchdeck</b>:
            <a *ngIf="pdDocument" [href]="pdDocument.url" target="_blank" class="text-underline text-primary">
              View Document
            </a>
          <p><b>Captable</b>:
            <a *ngIf="ctDocument" [href]="ctDocument.url" target="_blank" class="text-underline text-primary">
              View Document
            </a>

          <p><b>Financial Projection</b>:

            <a *ngIf="fpDocument" [href]="fpDocument.url" target="_blank" class="text-underline text-primary">
              View Document
            </a>
          <p><b>Due Dillegence Report</b>:
            <a *ngIf="ddrDocument" [href]="ddrDocument.url" target="_blank" class="text-underline text-primary">
              View Document
            </a>
          <p><b>Valuation Report</b>:
            <a *ngIf="vrDocument" [href]="vrDocument.url" target="_blank" class="text-underline text-primary">
              View Document
            </a>

        </div>
        <!-- <table class="table table-striped table-responsive">
          <thead>
            <tr>
              <th scope="col">S.NO</th>
              <th scope="col">Accelerator Name</th>
              <th scope="col">Startup Name</th>
              <th scope="col">Location</th>
              <th scope="col">Sector</th>
              <th scope="col">Current Valuation</th>
              <th scope="col">Total matching Funding Raised</th>
              <th scope="col">Investedment Round</th>
              <th scope="col">Matching fund required from MeitY</th>
              <th scope="col">Investment Instrument Used</th>
              <th scope="col">Amount of Equity/Shares held on behalf of MSH</th>
              <th>Amount of Equity Held</th>
              <th>Terms of Investment</th>
              <th>Exit Terms and Modes</th>

            </tr>
          </thead>
          <tbody>
            <tr> 
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>@mdo</td>
              <td>@mdo</td>
              <td>@mdo</td>
              <td>@mdo</td>
              <td>@mdo</td>
              <td>@mdo</td>
              <td>@mdo</td>
              <td>@mdo</td>
              <td>@mdo</td>
              <td>@mdo</td>

              

            </tr>
            </tbody>
        </table> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>