<!-- <div class="container py-3">
  <ngx-gia-utilized-table></ngx-gia-utilized-table>
</div> -->
<div class="container bg-white p-4">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h2 class="text-center eir-grant">State wise Startup List​</h2>
    <button type="button" class="btn btn-primary" (click)="downloadCSV()">Download</button>
  </div>
    <div class="table-responsive">
      <table class="table text-center table-bordered">
        <thead>
          <tr>
            <th>S.N</th>
            <th>State/ UT Name​​​</th>
            <th>Start-UP Name​​​​​</th>
            <th>Respective Agency Name​​</th>
            <th *ngIf="scheme_id !=2">Agency Type​</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor= "let item of funds?.data; let i = index">
            <td>{{i+1}}</td>
            <td>{{item.state}}</td>
            <td>{{item.startup_name}}</td>
            <td>{{item.incubator_name}}</td>
            <td *ngIf="scheme_id !=2">{{item.group}}</td>

          </tr>
          
          
          <tr>
            <th>Total</th>
            <th>{{statetotal}}</th>
            <th>{{total}}</th>
            <th>{{incubatortotal}}</th>
            <th *ngIf="scheme_id !=2"></th>
          </tr>
          <!-- Add more rows as needed -->
        </tbody>
      </table>
    </div>
  </div>
