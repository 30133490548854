

  <div class="container-fluid">
    <div class="row">
      <div class="col-12 bg-white border-5 p-3 shadow">
        <div class="d-flex bg-purple border-5 justify-content-between align-items-center py-3 px-5 cursor-pointer" (click)="challenges()">
          <div class="d-flex">
            <p class="text-white font-weight-bold mb-0">
              <i class="fa fa-arrow-left mr-3 text-white" aria-hidden="true"></i>
              Challenge: {{challengename}}
            </p>
          </div>
          <div class="">
            <p class="text-white font-weight-bold mb-0">
              Edition: {{edition_name}}
            </p>
          </div>
        </div>
      </div>



      <!-- <div class="col-12 border-5 p-3">
        <div class="bg-orange d-flex justify-content-center align-items-center py-3 px-5 mb-2 cursor-pointer" (click)="criteria(e.id);" *ngFor = "let e of editionrounds; let i = index">
          <p class="text-white font-weight-bold mb-0">
            Stage {{i+1}}
          </p>
        </div>
      </div> -->
     
      <div class="col-12 my-3">
        <div id="accordion">
          <div *ngFor="let e of editionrounds; let i = index" class="mb-3">
            <div class="card-header bg-transparent border border-primary d-flex justify-content-center align-items-center pos_rel cursor-pointer" (click)="getCriteriaList(e.id)" #stage [attr.id]="'heading-' + i" data-toggle="collapse" [attr.data-target]="'#collapse-' + i" aria-expanded="true" [attr.aria-controls]="'collapse-' + i">
             
                <button class="btn btn-link text-dark">
                  Stage {{i+1}}
                  <i class="fa fa-exclamation-circle text-danger" aria-hidden="true" id="adderr_{{e.id}}" *ngIf="e.criteria_count == 0"></i>
                </button>

                <i class="fa fa-angle-down pos_abs left_98" aria-hidden="true"></i>
            </div>
    
            <div [attr.id]="'collapse-' + i" class="collapse" [attr.aria-labelledby]="'heading-' + i" data-parent="#accordion">
              <div class="card-body">
                <table class="table bg-white shadow" *ngIf="showtable;else nodata_c">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Points</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor = "let c of criteriaList;let i = index" class="text-center">
                      <td>{{c.name}}</td>
                      <td>{{c.description}}</td>
                      <td>{{c.weightage}}</td>
                      <td class="d-flex justify-content-between w-25 m-auto">
                        <i class="fa fa-edit text-primary mr-3 cursor-pointer" (click)="getIndividualData(c)" data-toggle="modal" data-target="#exampleModal"></i> 
                        <i class="fa fa-trash text-danger cursor-pointer" (click)="deleteCriteria(c.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                 

                
                </table>
             
                <ng-template #nodata_c>
                  <div class="bg-white shadow d-flex justify-content-center align-items-center p-3">
                    <p class="no-records-found font-weight-bold mb-0">No Data found.</p>
                  </div>
                
                </ng-template>
                <div class="d-flex justify-content-end align-items-center my-3">
                  <button class="btn btn-primary custombtn" type="button" data-toggle="modal" data-target="#evalutatorslist" style="cursor:pointer">Add Criteria</button>
              </div>
           
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 my-3">
        <div class="d-flex justify-content-center align-items-center">
          <a class="text-decoration-none text-primary font-weight-bold" (click)="submitForm()"><i class="fa fa-plus text-primary mr-2 font-weight-bold"></i>Add New Stage</a>
        </div>
      </div>
      <!-- <div class="col-12">
        <div>
          <router-outlet></router-outlet>
        </div>
      </div> -->
    </div>

    
  </div>

  <div class="modal fade" id="exampleModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Update Criteria</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="criteriaupdateform">
            <div class="form-group">
              <label for="formGroupExampleInput">Name</label>
              <input type="text" class="form-control" formControlName = "name" id="formGroupExampleInput" placeholder="Enter Name" [ngClass]="{ 'is-invalid': submit_criteria && g.name.errors }">
              <div *ngIf="submit_criteria && g.name.errors" class="text-danger">
                <div *ngIf="g.name.errors['required']" class="custom_font">Name is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="formGroupExampleInput2">Description</label>
              <input type="text" class="form-control" formControlName = "description" id="formGroupExampleInput2" placeholder="Enter Description" [ngClass]="{ 'is-invalid': submit_criteria && g.description.errors }">
              <div *ngIf="submit_criteria && g.description.errors" class="text-danger">
                <div *ngIf="g.description.errors['required']" class="custom_font">Description is required</div>
                <div *ngIf="g.description.errors['pattern']" class="custom_font">Please enter a valid description</div>
              </div>
              
            </div>
            <div class="form-group">
              <label for="formGroupExampleInput2">Points</label>
              <input type="text" class="form-control" formControlName = "weightage" id="formGroupExampleInput2" placeholder="Enter Weightage" [ngClass]="{ 'is-invalid': submit_criteria && g.weightage.errors }">
              <div *ngIf="submit_criteria && g.weightage.errors" class="text-danger">
                <div *ngIf="g.weightage.errors['required']" class="custom_font">Points is required</div>
                <div *ngIf="g.weightage.errors['min']" class="custom_font">Value should not be less than 0</div>
                <div *ngIf="g.weightage.errors['max']" class="custom_font">Value should not be more than 100</div>
                <div *ngIf="g.weightage.errors['pattern']" class="custom_font">Please enter number</div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer d-flex w-100 justify-content-center align-items-center">
          <button type="button" class="btn btn-primary custombtn mr-3" (click)="updatecriteria()">Save</button>
          <button type="button" class="btn btn-secondary custombtnprev" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container-fluid">
 
  <div class="row">
    <div class="col-4">
      <div class="bg-white shadow p-3 mb-4 cursor-pointer" (click)="challenges()">
        <ul class="list-unstyled">
          <li>
            <h2 class="font-weight-bold">{{challengename}}</h2>
          </li>
          <li>
            <h2 class="font-weight-bold">{{edition_name}}</h2>
          </li>
        </ul>
      </div>
      <div class="w-100 d-flex justify-content-center mb-4">
        <button class="btn btn-primary custombtn"(click)="submitForm()">Add New Stage</button>
      </div>

      <div>
        <ul class="list-unstyled">
          <li *ngFor = "let e of editionrounds; let i = index" class="bg-white p-3 w-100 mb-3 d-flex justify-content-center align-items-center shadow">
            <a class="text-center w-100" (click)="criteria(e.id);" style="cursor:pointer;">Stage {{i+1}}</a> 
     
          </li>
        </ul>
      </div>
    </div>
    <div class="col-8">
      <router-outlet></router-outlet>
    </div>
  </div>
</div> -->


<div class="modal fade bd-example-modal-lg" id="evalutatorslist" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content p-4">
      <div class="modal-header d-flex justify-content-center align-items-center pos_rel">
        <h3 class="modal-title text-center mb-0 pos_abs" id="exampleModalCenterLabel">Add Criteria</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div [formGroup]="addForm">


        <div *ngFor="let addressGroup of form.get('address').controls;let i = index" [formGroup]="addressGroup" class="my-3">
    
          <div class="form-row">
    
         
            <div class="col-3">
              <input formControlName="name" class="form-control" placeholder="Name">
              <p *ngIf="form.get('address').controls[i].get('name').errors?.required && isValidFormSubmitted != null && !isValidFormSubmitted"
              class="text-danger">
            Name is required
          </p>
            </div>
            
            <div class="col-3">
              <input formControlName="description" class="form-control" placeholder="Description">
              <p *ngIf="form.get('address').controls[i].get('description').errors?.required && isValidFormSubmitted != null && !isValidFormSubmitted"
              class="text-danger">
            Description is required
          </p>
          <p *ngIf="form.get('address').controls[i].get('description').errors?.pattern && isValidFormSubmitted != null && !isValidFormSubmitted"
          class="text-danger">
          Please enter a valid description.
      </p>
            </div>
            
             <div class="col-3">
              <input formControlName="weightage" class="form-control" placeholder="Points">
              <p *ngIf="form.get('address').controls[i].get('weightage').errors?.required && isValidFormSubmitted != null && !isValidFormSubmitted"
              class="text-danger">
            Points is required
          </p>
          <p *ngIf="form.get('address').controls[i].get('weightage').errors?.min && isValidFormSubmitted != null && !isValidFormSubmitted"
          class="text-danger">
        Please enter a number above 0
      </p>
      <p *ngIf="form.get('address').controls[i].get('weightage').errors?.max && isValidFormSubmitted != null && !isValidFormSubmitted"
      class="text-danger">
    Please enter a number below 100
    </p>
    <p *ngIf="form.get('address').controls[i].get('weightage').errors?.pattern && isValidFormSubmitted != null && !isValidFormSubmitted"
    class="text-danger">
    Please enter number
    </p>
            </div>
    
            <div class="col-3">
              <div class="w-100 d-flex justify-content-center align-items-center mt-1"> 
                <button (click)="deleteAddressGroup(i)" class="btn btn-danger d-flex justify-content-center align-items-center font-weight-bold w-50"><i class="fa fa-times font-weight-bold text-white"></i></button>
              </div>
            </div>
          </div>
           
            
        </div>
        
        <div class="w-100 d-flex justify-content-center align-items-center my-3">
          <a class="text-primary font-weight-bold" (click)="addNewAddressGroup1()"><i class="fa fa-plus text-primary font-weight-bold mr-2"></i>Add More</a>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-3" *ngIf = "showsave">
          <button class="btn btn-primary custombtn mr-3" (click)="addCriteria()">Save</button>
          <button class="btn btn-primary custombtn" data-dismiss="modal" (click)="close()">Close</button>
        </div>
    </div>
    </div>
  </div>
</div>

   


