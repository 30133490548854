<div class="container py-3">
  <!-- <ngx-gia-utilized-table></ngx-gia-utilized-table> -->
</div>
<section id="investor_section">
    <div class="container">
        <div class="whitebg my-3 p-4 ">
            <h2 class="text-center">UPLOADS</h2>
            
            <form [formGroup]="uploadForm">
                <div class="row text-center py-3">
                    <!-- <div class="col-12 col-md-12">
                        <a href="">Subject </a>
                    </div> -->
                </div>
                <div class="row  p-3 my-4">
                    <div class="col-12 col-md-4">
                        <label class="form-label label-required">Title</label>
                        <input type="text" class="form-control" placeholder="title" formControlName="title">
                    
                        <div
                        *ngIf="
                          (isuploadFormSubmited && _title.invalid) ||
                          (_title?.touched && _title.invalid)
                        "
                        class="text-danger py-1"
                      >
                        <div *ngIf="uploadForm.get('title')?.errors?.required">
                          <p class="text-danger">Title is mandatory</p>
                        </div>
                        <div *ngIf="uploadForm.get('title')?.errors?.maxlength">
                            <p class="text-danger">Title should not exceed 20 characters</p>
                          </div>
                      </div>
                    
                    </div>
                    
                    <div class="col-12 col-md-4">
                        <label class="form-label label-required">Upload Doc</label>
                        <div class="custom-file">
                          <input type="file" class="custom-file-input" (change)="onFileSelect2($event)" id="file" name="file"/>
                          <label class="custom-file-label" for="file">{{
                            fileInputLabel || "Choose File"
                          }}</label>
                        </div>
                        <p class="text-danger" *ngIf="filetyperrr">
                          File format is incorrect
                        </p>
                        <p class="text-danger" *ngIf="filesizerrr">
                          File size should not exceed 2 MB
                        </p>
                   <div
                            *ngIf="
                              (isuploadFormSubmited && _file.invalid)
                              
                            "
                            class="text-danger py-1"
                          >
                            <div *ngIf="_file.errors.required || (_file?.touched && _file.invalid) || _file.invalid">
                              <p class="text-danger">file is mandatory</p>
                            </div>
                            
                           
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="form-label">Description</label>
                        <input type="text" class="form-control" placeholder="description" formControlName="description">
                        
                        <div
                        *ngIf="
                          (isuploadFormSubmited && _description.invalid) ||
                          (_description?.touched && _description.invalid)
                        "
                        class="text-danger py-1"
                      >
                        <!-- <div *ngIf="uploadForm.get('description')?.errors?.required">
                          <p class="text-danger">description is mandatory</p>
                        </div> -->
                       
                        <div *ngIf="uploadForm.get('description')?.errors?.maxlength">
                          <p class="text-danger">Reached Max length(500)</p>
                        </div>
                      </div>
                    </div>
                   
                 
                </div>
               
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary text-center" (click)="submitUpload2()">{{ editMode ? 'Update' : 'Submit' }}</button>
                    <button type="canel" class="mx-2 btn btn-secondary cancelEditBtn " *ngIf="editMode" (click)="cancelEdit()">Cancel</button>

                  </div>

            </form>

            <div class="table-container p-4 my-4">
                <table class="table table-striped text-center">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">File</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let upload of uploads|paginate: { itemsPerPage: pageSize, currentPage: p }; let index=index">
                            <tr>
                                <th scope="row">{{ index+1+(p-1)*pageSize }}</th>
                                <td>{{ upload?.title }}</td>
                                <td><a [href]="upload?.file?.url ? upload?.file?.url : upload?.file" target="_blank">Click me</a></td>
                                <td>
                                    <i class="fa fa-eye arrow px-2" data-bs-toggle="modal" data-bs-target="#viewUploadModal" (click)="viewUpload(upload)"></i>
                                    <i class="fa fa-edit arrow px-2" (click)="editUpload(upload)"></i>
                                    <i class="fa fa-trash arrow px-2" (click)="deleteUpload(upload.id)"></i>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <pagination-controls *ngIf="uploads.length>0" (pageChange)="onPageChange($event)" directionLinks="true"  class="float-right"></pagination-controls>

            </div>
        </div>


    </div>

    <!-- Modal -->
<div class="modal fade" id="viewUploadModal" tabindex="-1" aria-labelledby="viewUploadModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="viewUploadModalLabel">View Upload</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times arrow px-2"></i>
        </button>
      </div>
      <div class="modal-body">
        <b>Title: </b>
        <p>{{this.uploadData?.title}}</p>
        <br>
        <b>Upload Doc: </b>
        <br>
        <a [href]="this.uploadData?.file?.url ? this.uploadData?.file?.url : this.uploadData?.file" target="_blank">View uploaded document</a>
        <br>
        <br>
        <b>Description: </b>
        <p class="text-break">{{ this.uploadData?.description }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>




</section>