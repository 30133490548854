<div class="container-grid col-sm-12">
    <!-- <nb-card class="col-sm-12">
        <nb-card-header class="float-right">Media</nb-card-header>
    </nb-card> -->
    <h2>Media Gallery</h2>
   
        <form
          [formGroup]="mediaForm"
          class="form-control-group col-12"
         enctype="multipart/form-data p-4"
        >
        <div class="row">
          <div class="col-sm-6">
            <label for="" class="label-required">Event</label>
           
            <select name="" id="" class="form-control"  formControlName="EventCategory">
                <option value="" selected [disabled]="!selectedValue">Select event</option>
                <option [value]="item.id" *ngFor="let item of categoryList" (click)="selectItem(item)">{{item.category_name}}</option>

            </select>
            <div *ngIf="mediaForm.get('EventCategory').invalid && mediaForm.get('EventCategory').touched">
              <div *ngIf="mediaForm.get('EventCategory').errors.required" class="text-danger">Event is required.</div>
            </div>
            <div class="addEvent">
                <button class="btn btn_event" data-toggle="modal" data-target="#eventModal">
                    Add Event  <i class="fa fa-plus text-white font-weight-bold"></i>
                </button>
               

            </div>
          </div>
            <div class="col-sm-6">
                <label for="" class="label-required"> Category </label>
                <div class="input-group mb-3">
                 
                  <input
                  type="text"
                  formControlName="EventName"
                  class="form-control"
                  placeholder="Category Name"
                />
                 
                </div>
                <div *ngIf="mediaForm.get('EventName').invalid && mediaForm.get('EventName').touched">
                    <div *ngIf="mediaForm.get('EventName').errors.required" class="text-danger"> Category is required.</div>
                  </div>
              </div>
       
         
          </div>
          <div class="row py-2">
          <div class="col-sm-6">
            <label class="" class="label-required">Select File</label>
            <div class="custom-file">
              <input type="file" class="custom-file-input" formControlName="mediaImage" id="customFile" name="uploadedImage"
                (change)="onnewFileSelect($event)" #UploadFileInput >
              <label class="custom-file-label" for="customFile">{{fileInputLabel || 'Choose File'}}</label>
            </div>
            <span style="color: black;">(File Size Should Not Exceed 2MB. Allowed Extensions: PNG , JPG , SVG)</span>
            <div *ngIf="mediaForm.get('mediaImage').invalid && mediaForm.get('mediaImage').touched">
              <div *ngIf="mediaForm.get('mediaImage').errors.required" class="text-danger">Image is required.</div>
            </div>
            <div  *ngIf="filetypeerr">
              <p  class="text-danger"> File format is incorrect</p>
            </div>
            <div   *ngIf="filetypeerr">
              <p class="text-danger">File size should not exceed 2 MB</p>
            </div>
          </div>
          </div>
        </form>
        <div class="button-wrapper">
          <button class="btn btn-primary mr-2 custombtn" (click)="cancel()">Cancel</button>
          <button class="btn btn-primary custombtn"  (click)="publishEvent()" *ngIf="createMode">Create</button>
          <button class="btn btn-primary custombtn" (click)="updateForms()" *ngIf="editMode">Update</button>
        </div>


        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" class="text-center">Sl No</th>
              <th>Posted on</th>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col">Image</th> 
              <th scope="col">Start Date</th> 
              <th scope="col">End Date</th>            
              <th scope="col">Status</th>
              <th scope="col" width="200px">Actions</th>
            </tr>
          </thead>
          <tbody *ngIf="categoryList?.length > 0; else noRecords">
            <tr  *ngFor="let d of categoryList| paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index" >
              <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th>
              <td>{{d.created_at |  date}}</td>
              <td class="m_col" style="word-break: break-all;">{{ truncateText(d.category_name, 150) }} <span data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" *ngIf="readMore"  class="readMore">Read more</span></td> 
              <td class="m_col" style="word-break: break-all;">{{ truncateText(d.description, 150) }} <span data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" *ngIf="readMore"  class="readMore">Read more</span></td>              
              <td class="m_col" style="word-break: break-all;"><a href="{{d.images[0].url}}" target="_blank">{{d.images[0].url}}</a></td>              
              <td>{{d.start_date |  date}}</td>
              <td>{{d.end_date |  date}}</td>
              <td>{{d.status}} </td>
  
              <td >
                 <!-- <div class="dropdown mr-3">
                  <a class="dropbtn fa fa-check" style = "color:blue"></a>
                  <div class="dropdown-content" >
                    <a *ngIf="d.news_status === 'active'" (click)="changestatus(d,'inactive')">inactive</a>
                    <a *ngIf="d.news_status === 'inactive'" (click)="changestatus(d,'active')">active</a>
                  </div>
                </div> -->
                <div class="d-flex align-items-center ">                 
                  <!-- <a class="fa fa-edit mr-3 arrow" style = "color:blue" (click)="editData(d, section)" ></a> -->
                  <!-- <a class="fa fa-trash mr-3 arrow" style = "color:red" (click)="deleteMediaData(d.media_id)" ></a> -->
                  <a class="fa fa-edit mr-3 arrow" style = "color:blue" data-toggle="modal" data-target="#exampleModal" (click)="preview(d.id)" ></a>
                  <!-- <a class="fa fa-edit mr-3 arrow" style = "color:blue" data-toggle="modal" data-target="#exampleModal" (click)=getIndividualCategory(d.id)></a> -->
                  <a class="fa fa-trash mr-3 arrow" style = "color:red" data-toggle="modal" (click)="deleteEventData(d.id)"></a>
                  </div>
  
              </td>
            </tr>
          
          </tbody>
          <ng-template #noRecords>
            <tbody>
              <tr>
                <td colspan="6" class="no-records-found">No Data found.</td>
              </tr>
            </tbody>
          </ng-template>
        </table>
        <pagination-controls *ngIf="categoryList?.length > 0;" (pageChange)="p = $event" class="float-right"></pagination-controls>
    </div>

    <!-- Modal -->
<div class="modal custom-modal " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content ">
      <form [formGroup]="editEvent">
      <div class="modal-header ">
        <h3 class="modal-title " id="exampleModalLabel">Preview</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="container-fluid ">
          <label for="basic-url">Category Name</label>
          <div class="input-group">
            <div class="input-group-prepend">
            </div>
            <input type="text" formControlName="category_name" class="form-control mb-2" placeholder="" aria-label="Username" value="{{event?.category_name}}" aria-describedby="basic-addon1">
          </div>

          <label for="basic-url">Start Date</label>
          <div class="input-group">
            <input type="date" formControlName = "start_date" class="form-control mb-2" aria-label="Username"  aria-describedby="basic-addon1">
          </div>

          <label for="basic-url">End Date</label>
          <div class="input-group">
            <input type="date" formControlName = "end_date" class="form-control mb-2" aria-label="Username" aria-describedby="basic-addon1">
          </div>

          <label for="basic-url">Description</label>
          <div class="input-group">
            <div class="input-group-prepend">
            </div>
            <textarea type="text" formControlName = "description" class="form-control mb-2" placeholder="" aria-label="Username" aria-describedby="basic-addon1"></textarea>
          </div>
          <label for="basic-url">Images</label>
          <div class="input-group">
          <div class="input-group-prepend">
          </div>
          <div class="d-flex justify-content-center align-items-center mb-2">
          <div *ngFor = "let i of event?.images;let j = index" id="image_{{j}}">
            <img [src]="i.url" alt="image" style="width: 100%;">
            <i class="fa fa-times-circle float-right" (click)="removeimage(j)" aria-hidden="true"></i>
          </div>
          <!-- <div>
            <button>Add Images</button>
          </div> -->
          
        </div>
        </div>

  
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="update_event()">Update</button>
      </div>
    </div>
  </form>
  </div>
  </div>
  </div>

  <!-- event modal -->
  <div class="modal custom-modal " id="eventModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header ">
          <h3 class="modal-title " id="exampleModalLabel">Add Event</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ">
            
          <div class="container-fluid ">
            <form [formGroup]="categoryForm">
            <div>
                <label for="basic-url">Event Name</label>
                <div class="input-group">                 
                  <input type="text" class="form-control mb-2" placeholder=""  formControlName="CategoryName" >
                </div>
            </div>

            <div>
                <label for="basic-url">Event Description</label>
                <div class="input-group">
                    <textarea name="" id="" cols="30" rows="5" formControlName="CategoryDesc" class="form-control mb-2"></textarea>            
                 
                </div>
            </div>
            <div>
                <label for="basic-url">Event Image</label>
                <div class="input-group">
                    <input type="file" class="form-control mb-2" placeholder=""  (change)="onFileSelect($event)" #UploadFileInput1>           
                 
                </div>
            </div>
            <div>
                <label for="basic-url">Start Date</label>
                <div class="input-group">
                    <input type="date" class="form-control mb-2" placeholder=""  formControlName="StartDate">           
                 
                </div>
            </div>
            <div>
                <label for="basic-url">End Date</label>
                <div class="input-group">
                    <input type="date" class="form-control mb-2" placeholder=""  formControlName="EndDate">           
                 
                </div>
            </div>
          
  
           
    
           
        </form>

        
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" (click)="createCategory()">Create</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
      
    </div>
    </div>
    </div>
  
   