import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { NotificationService } from "../../@core/miscellaneous/common/notification.service";
import { DialogNamePromptComponent } from "../../@core/miscellaneous/dialog/dialog-name-prompt/dialog-name-prompt.component";
import { ShowcaseDialogComponent } from "../../@core/miscellaneous/dialog/showcase-dialog/showcase-dialog.component";
import { AxiosServicesService } from "../../services/axios/axios-services.service";
import { PermissionService } from "../../services/permission.service";
@Component({
  selector: "ngx-permission",
  templateUrl: "./permission.component.html",
  styleUrls: ["./permission.component.scss"],
})
export class PermissionComponent implements OnInit {
  checked = false;
  permissionsForm: FormGroup;
  inValidMessage: boolean = false;
  tableData: any[] = [];
  editMode: boolean = false;
  menus = [{ read: false, write: false, view: false, delete: false }];
  data: any;
  details: any;
  currentData: any;

  
  
  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private axiosService: AxiosServicesService,
    private permissionService: PermissionService,
    private toastrService: NbToastrService,
    private notification: NotificationService,
    private dialogService: NbDialogService,

  ) {}

  ngOnInit(): void {
    this.permissionsForm = this.fb.group({
      name: [
        null,
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      description: [null, Validators.compose([Validators.required])],
      menus: this.fb.group({
        create: [false, Validators.required],
        view: [false],
        edit: [false],
        delete: [false],
      }),
    });
    this.getAllPermission();
  }
  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  submitForm() {
    if (this.permissionsForm.valid) {
      if (this.editMode) {
        //put api function call below
        this.currentData = this.permissionsForm.value;
        this.permissionService
          .editPermission(this.currentData, this.data.permission_id)
          .subscribe((res) => {
            console.log("res::", res);
            this.getAllPermission();
            this.permissionsForm.reset();
          }); /////put api function call ends here!
        this.editMode = false;
        this.toastrService.success("Data Updated Successfully!");
      } else {
        this.editMode = false;
        const { value } = this.permissionsForm;
        console.log("current form value: ", value);
        this.permissionService
          .addPermission(this.permissionsForm)
          .pipe()
          .subscribe(
            (data) => {
              this.data = data;
              this.permissionsForm.reset();
              this.getAllPermission();
              this.toastrService.success("Data Added Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Add records!");
            }
          );
      }
    }
  }
  getAllPermission() {
    this.permissionService
      .getAllPermission()
      .pipe()
      .subscribe(
        (data) => {
          this.details = data;
          this.tableData = this.details.results;
        },
        (error) => {
          this.inValidMessage = true;
        }
      );
  }
  // removeData(data, id) {
  //   sessionStorage.setItem("permission_id", id);
  //   this.dialogService.open(DialogNamePromptComponent);

    // this.permissionService.deleteRow(data.permission_id).subscribe((data) => {
    //   this.getAllPermission();
    // });
    // this.toastrService.danger("Data Deleted Successfully!");
 // }

  deleteItem(data){
    this.dialogService.open(ShowcaseDialogComponent, {
      context: {
        message: 'Are you sure you want to delete this item?'
      }
    }).onClose.subscribe(confirmed => {
      if (confirmed) {
        // Proceed with delete action
        this.permissionService.deleteRow(data.permission_id).subscribe((data) => {
      this.getAllPermission();
    });
    this.toastrService.danger("Data Deleted Successfully!");
      }
    });
  }
  editPermissionData(data, index) {
    this.editMode = true; //change btn to update
    this.data = data;
    this.permissionsForm.controls["name"].setValue(this.data.module);
    this.permissionsForm.controls["description"].setValue(
      this.data.description
    );
    this.permissionsForm.controls["menus"].setValue({
      edit: this.data.conditions.edit,
      create: this.data.conditions.create,
      delete: this.data.conditions.delete,
      view: this.data.conditions.view,
    });
  }

  cancelData() {
    this.permissionsForm.reset();
    this.editMode = false;
    this.toastrService.warning("Cancelled Successfully!");
  }
   
  
  
}
