import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ActivatedRoute } from "@angular/router";
import { ResourceBookingService } from "../../../services/resource-booking.service";
import { DialogNamePromptComponent } from "../../../@core/miscellaneous/dialog/dialog-name-prompt/dialog-name-prompt.component";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { LabService } from "../../../services/lab.service";
import { states,cities, domains } from "../../../../assets/js/states";
import { DatePipe } from '@angular/common';
// declare var XLSX:any;
import * as XLSX from 'xlsx';

@Component({
  selector: "ngx-resource-booking",
  templateUrl: "./resource-booking.component.html",
  styleUrls: ["./resource-booking.component.scss"],
})
export class ResourceBookingComponent implements OnInit {

  // pagination
  paginationEnabled: boolean = true;
  labFilterForm: FormGroup;
  dropdownSettings: IDropdownSettings = {};
  dropdownSettingst: IDropdownSettings = {};
  dropdownstatus: any[] = ["CONFIRMED", "PENDING", "DECLINED"]
  dropdownuser: any[] = [];
  selectedStatus: any = [];
  p: number = 1;
  filterEnable: boolean = false; 
  myArray: any[] = [];
  list: any = [];
  bookingFilterForm: FormGroup;
  totalItems: number;
  filterValue: any;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  pendingCount: number = 0;
  resourceName: any;
  dropdownstates: string[];
  states:string[];
  selectedcities:string[];
  cities:any;
  dataset:any=[
    {
      "name":"state",
      "value": ""
    },
    {
      "name":"city",
      "value":""
    },
    {
      "name":"domain",
      "value":""
    }
  ];
  // pagination
  public Editor = ClassicEditor;
  
  selectedUser: any = [];
  filterValue1 = null;
  viewback: any;

  selectedText: any;

  public editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "alignment", // <--- ADDED
        "bold",
        "italic",
        "link",
        "bulletedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    link: {
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
        openInNewTab: {
          mode: "manual",
          label: "Open in a new tab",
          defaultValue: true, // This option will be selected by default.
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
  };
  msg = "";
  id = "section";
  numericPattern = "^[0-9]*$";
  data: any;
  userData: any;
  inValidMessage: boolean;
  byId: any;
  dataByID: any;
  urls = [];
  fileInputLabel: string;
  imageUrl: string | ArrayBuffer | null = null;
  facility: any;
  pageSize: number = 25;
  activeFilter: string | null = null; 
  facilities: any = {
    lab: "Lab",
    guesthouse: "Venue",
    COWORKING_SPACE: "Co-Working Space",
    equipment: "Equipment",
  };
  facility_type: any;

  resourceordermodaldata = {
    resource_name: "",
    start_date: "",
    end_date: "",
	sections:{
      list:[{
        travel_reason:"",
        travelling_from:"",
        travellers_details:{
        name:"",
        type:"",
        number:"",
        organization_name: "",
        designation:"",
        description:"",
        },
        no_of_occupancy_days:"",
        no_of_people_accompanying:"",
        number_hour: ""
        }]
	  },
    agencycity: "",
    agencyname : "",
    agencystate : "",
    agenytype: "",
    incharge_details : {
      "name": "",
      "email": "",
      "phone": 1234567890,
      "designation": ""
    } 
  }
  

  constructor(
    private fb: FormBuilder,
    private bookingService: ResourceBookingService,
    private toastrService: NbToastrService,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private labService: LabService,
    private datePipe: DatePipe
  ) {
    this.route.queryParams.subscribe((params) => {
      this.facility = params["facility"];      
      console.log("facility", this.facility);
    });

  }

  ngOnInit(): void {
    this.states = states;
    this.cities = cities;
    this.dropdownstates = this.states;
    if (this.facility) {
      this.resourceName = this.facility
      this.resourceName = this.facility.toUpperCase();
      console.log("facility in uppercase", this.resourceName);
    }
    this.bookingFilterForm = this.fb.group({
      selectedUserType: ['ALL'],
      start_date: [''], 
      end_date: [''],  
      state: ['0'],
      city: ['0']

      
    });


    this.facility_type = this.facilities[this.facility];
    console.log("facility_type", this.facility_type);

    // this.activeFilter = null;
    this.filterItem();
    this.activeFilter = null;
    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.selectedUser = [];
    this.selectedStatus = []   
  }

  getBookingList(value: any) {
    this.bookingService.getAllBookingData(this.facility).subscribe(
      (res: any) => {
        this.data = res.results;
        this.list = res.results;
        console.log("Booking dataTable::", res.results);
        this.updateStatusCounts(this.data);
      },
      (error) => {
        this.inValidMessage = true;
        this.toastrService.warning("Unable to get Bookings");
      }
    );
  }

  approveBooking(status: any) {
    let approve_data = {
      booking_id: this.data.id,
      status: status,
    };
    this.bookingService.approveBooking(approve_data).subscribe((res: any) => {
      this.dataByID = res.results;
      console.log("byID", this.dataByID);
    });
  }

  gotosection(el: any) {
    el.scrollIntoView({ behaviour: "smooth" });
  }

  changestatus(id: string, value: string) {
    this.dialogService
      .open(DialogNamePromptComponent, {})
      .onClose.subscribe((confirmed) => {
        if (confirmed) {
          var data = {
            booking_id: id,
            status: value,
          };
          this.bookingService.actionBooking(data).subscribe(
            (res: any) => {
              console.log("change_status", res);
              this.getBookingList(this.facility);
              this.toastrService.success("Successfully updated Status!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Update Status!");
            }
          );
        }
      });
  }

  onUserSelectionChange(event:any){ 
    const selectedValue = event.target as HTMLSelectElement;
    this.selectedText = selectedValue.options[selectedValue.selectedIndex].text
    console.log('selectedText', this.selectedText)
    let userType = "INCUBATOR";
    if(event.target.value){
      userType = event.target.value;
    }
    let data = {    
      search_entity: userType,
      };
    this.bookingService.filterUser(data).subscribe((res: any) => {
      console.log("pendinggggg.........:", res);
      this.userData = res.results;
      console.log("data is ==> ", this.userData)
      let data = [];
        for (var i = 0; i < this.userData.length; i++) {
          data.push({
            item_id: this.userData[i].user_id,
            item_text: this.userData[i].name,
          });
        }
        this.dropdownuser = [...data];
        // console.log('data_domain',data);
      console.log("this is user data", this.dropdownuser)
      
    });
  }

 

  preview(item: any){  
    console.log("modal data ==>> ", item)  
    this.resourceordermodaldata.resource_name = item.resource_name;
    this.resourceordermodaldata.start_date = item.start_date;
    this.resourceordermodaldata.end_date = item.end_date;
    this.resourceordermodaldata.sections.list[0].travel_reason = item.sections.list[0].travel_reason;
    this.resourceordermodaldata.sections.list[0].travelling_from = item.sections.list[0].travelling_from;

    this.resourceordermodaldata.sections.list[0].travellers_details.name = item.sections.list[0].travellers_details.name;
    this.resourceordermodaldata.sections.list[0].travellers_details.type = item.sections.list[0].travellers_details.type;
    this.resourceordermodaldata.sections.list[0].travellers_details.number = item.sections.list[0].travellers_details.number;
    this.resourceordermodaldata.sections.list[0].travellers_details.organization_name = item.sections.list[0].travellers_details.organization_name;
    this.resourceordermodaldata.sections.list[0].travellers_details.designation = item.sections.list[0].travellers_details.designation;
    this.resourceordermodaldata.sections.list[0].travellers_details.description = item.sections.list[0].description; //  item.sections.list[0].travellers_details.description
    this.resourceordermodaldata.sections.list[0].no_of_people_accompanying = item.sections.list[0].no_of_people_accompanying;
    this.resourceordermodaldata.sections.list[0].no_of_occupancy_days = item.sections.list[0].no_of_occupancy_days;
    this.resourceordermodaldata.sections.list[0].number_hour = item.sections.list[0].number_hour;
    this.resourceordermodaldata.agencycity = item.agencycity;
    this.resourceordermodaldata.agencyname = item.agencyname;
    this.resourceordermodaldata.agencystate = item.agencystate;
    this.resourceordermodaldata.agenytype = item.agenytype;
    this.resourceordermodaldata.incharge_details.name = item.incharge_details.name;
    this.resourceordermodaldata.incharge_details.email = item.incharge_details.email;
    this.resourceordermodaldata.incharge_details.phone = item.incharge_details.phone;
    this.resourceordermodaldata.incharge_details.designation = item.incharge_details.designation;
    console.log("number of hours ==>> ", this.resourceordermodaldata.sections.list[0].number_hour)
  }

  // download(type, fn?, dl?,list?){
  //   var elt = document.getElementById('tbl_exporttable_to_xls');
  //   var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
  //   const currentDate = new Date();
  //   const formattedDate = currentDate.toISOString().slice(0, 10); // Format: YYYY-MM-DD
  //   let bookingname = {lab:'Lab',
  //     guesthouse:'Venue',
  //     COWORKING_SPACE:'Coworking space',
  //     equipment:'Equipment'}
  //   const facilityname = (bookingname[`${this.facility}`]);
  //   return dl ?
  //     XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
  //     XLSX.writeFile(wb, fn || (`${facilityname}_booking_reportlist_${formattedDate}.` + (type || 'xlsx')));
  // }
//   downloadTableData() {
//     const isVenue = this.facility === 'guesthouse';

//     const header = ['Booked By', `${this.facility_type} Name`, 'Booking Start Date', 'Booking End Date', 'Description', 'Status', 'Contact Number', 'Organization Name', 'Type', 'Designation', ...(isVenue ? ['Additional Traveller'] : [])];
//     const data = this.list.map((item, index) => ({
//       'Booked By': item.sections.list[0].travellers_details.name,
//       [`${this.facility_type} Name`]: item.resource_name,
//       'Booking Start Date': new Date(item?.start_date),
//       'Booking End Date': new Date(item?.end_date),
//       'Description': item.sections.list[0].description || '', // Handle null or undefined case
//       'Status': item.status,
//       'Contact Number': item.sections.list[0].travellers_details.number,
//       'Organization Name': item.sections.list[0].travellers_details.organization_name,
//       'Designation': item.sections.list[0].travellers_details.designation,
//       'Type': item.sections.list[0].travellers_details.type
//     }));
// if(isVenue){
//   const additionalTraveller = item.sections.list[1]?.travellers_details?.name || '';
//   return { ...baseData, 'Additional Traveller': additionalTraveller };

// }
//     // Generate the Excel file with dynamic sheet name
//     const workbook: XLSX.WorkBook = XLSX.utils.book_new();
//     const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header });
//     let bookingname = {lab:'Lab',
//           guesthouse:'Venue',
//           COWORKING_SPACE:'Coworking space',
//           equipment:'Equipment'}
//     // Set sheet name based on facility_type
//     const sheetName = (bookingname[`${this.facility}`]); // Use facility_type directly as sheet name

//     XLSX.utils.book_append_sheet(workbook, worksheet, sheetName); // Set sheet name dynamically
//     const currentDate = new Date();
//     const day = String(currentDate.getDate()).padStart(2, '0');
//     const month = String(currentDate.getMonth() + 1).padStart(2, '0');
//     const year = currentDate.getFullYear();
//     const formattedDate = `${day}-${month}-${year}`;
//     // Export the Excel file
//     XLSX.writeFile(workbook, `${sheetName}_booking_reportlist_${formattedDate}.xlsx`);
//   }
downloadTableData() {
  const isVenue = this.facility === 'guesthouse';
  const isCoWorking = this.facility === 'COWORKING_SPACE';

  const header = [
    'Sl No',
    'Booked By', 
    `${this.facility_type} Name`, 
    'Booking Start Date', 
    'Booking End Date', 
    'Status', 
    'Contact Number', 
    'Organization Name', 
    'Type', 
    'Designation',
    ...(isVenue ? ['Travelling From', 'Reason For Travel', 'No. Of Days Of Occupancy', 'No. Of People Accompanying'] : []),
    ...(isCoWorking ? ['Number of hours'] : []), // Add extra column for 'CoWorking'
    ...(!isVenue ? ['Description'] : []), // Add 'Description' for non-Venue
    'Agency City',
      'Agency Name' ,
      'Agency State' ,
      'Ageny Type',
      "Incharge Name",
      "Incharge Email",
      "Incharge Phone",
      "Incharge Designation"
  ];

  const data = this.list.map((item, index) => {
    const baseData = {
      'Sl No':index + 1,
      'Booked By': item.sections.list[0].travellers_details.name,
      [`${this.facility_type} Name`]: item.resource_name,
      'Booking Start Date': new Date(item?.start_date),
      'Booking End Date': new Date(item?.end_date),
      'Status': item.status,
      'Contact Number': item.sections.list[0].travellers_details.number,
      'Organization Name': item.sections.list[0].travellers_details.organization_name,
      'Designation': item.sections.list[0].travellers_details.designation,
      'Type': item.sections.list[0].travellers_details.type,
      'Agency City': item.agencycity,
      'Agency Name' : item.agencyname,
      'Agency State' : item.agencystate,
      'Ageny Type': item.agenytype,
      "Incharge Name": item.incharge_details.name,
      "Incharge Email": item.incharge_details.email,
      "Incharge Phone": item.incharge_details.phone,
      "Incharge Designation": item.incharge_details.designation
    };

    if (!isVenue) {
      baseData['Description'] = item.sections.list[0].description || ''; // Handle null or undefined case
    }

    if (isVenue) {
      const additionalData = {
        'Travelling From': item.sections.list[0]?.travelling_from || '',
        'Reason For Travel': item.sections.list[0]?.travel_reason || '',
        'No. Of Days Of Occupancy': item.sections.list[0]?.no_of_occupancy_days || '',
        'No. Of People Accompanying': item.sections.list[0]?.no_of_people_accompanying || ''
      };
      return { ...baseData, ...additionalData };
    }

    if (isCoWorking) {
      const coworkingData = {
        'Number of hours': item.sections.list[0]?.number_hour || ''
      };
      return { ...baseData, ...coworkingData };
    }

    return baseData;
  });

  // Generate the Excel file with dynamic sheet name
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header });
  const bookingname = {
    lab: 'Lab',
    guesthouse: 'Venue',
    COWORKING_SPACE: 'Coworking space',
    equipment: 'Equipment'
  };

  // Set sheet name based on facility_type
  const sheetName = bookingname[this.facility];

  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName); // Set sheet name dynamically
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  // Export the Excel file
  XLSX.writeFile(workbook, `${sheetName}_booking_reportlist_${formattedDate}.xlsx`);
}


// count function
getCountsByStatus(startups) {
  const statusCounts = {};
  startups.forEach((startup) => {
    const status = startup.status;
    if (status) {
      if (statusCounts.hasOwnProperty(status)) {
        statusCounts[status]++;
      } else {
        statusCounts[status] = 1;
      }
    }
  });   

  return statusCounts;
}
updateStatusCounts(data) {
  const statusCounts = this.getCountsByStatus(data);
  console.log("Status Counts:", statusCounts);
  // Separate the counts
  this.approvedCount = statusCounts["CONFIRMED"] || 0;
  this.rejectedCount = statusCounts["DECLINED"] || 0;
  this.pendingCount = statusCounts["PENDING"] || 0;
  

}
// count function

// get state and city
getCities(event:any){
 
  // this.states = event.target.value;
  // //this.dataset.push({name:"state",value:[event.target.value]});
  // this.dataset[0].value = event.target.value
  // this.selectedcities = [];
  // let cities = this.cities[`${event.target.value}`];
  // for(var i = 0 ; i < cities.length ; i++){
  //   this.selectedcities.push(cities[i]);
  // }
  // console.log('selectedcities',this.selectedcities);  
  this.dataset[0].value = event.target.value;
  this.selectedcities = this.cities[event.target.value] || [];
}

getCity(event:any){
 
  //this.dataset.push({name:"city",value:[event.target.value]});
  // console.log("sadasdas => ", this.dataset[1].value)
  // // this.dataset[1].value = event.target.value
  // this.dataset[1].value.push(event.target.value);
  this.dataset[1].value = event.target.value;

}
// get state and city

// count filter
// countFilter(data)
//   {
//     this.activeFilter = data;
//     this.filterEnable = true
//     // this.filterMode = false;
//     // this.resetMode = true;  
//     this.myArray = [];
//     var userList = [];
//     var statusList =[];  
//     var selectedUserType = this.bookingFilterForm.get('selectedUserType').value || 'ALL';
//     var startDate = this.bookingFilterForm.get('start_date').value;
//     var endDate = this.bookingFilterForm.get('end_date').value;
//     var state = this.bookingFilterForm.get('state').value;
//     var city = this.bookingFilterForm.get('city').value;
     
        
//     for (var i = 0; i < this.selectedUser.length; i++) {
//       userList.push(this.selectedUser[i].item_id);
//     }

//     for (var i = 0; i < this.selectedStatus.length; i++) {
//       statusList.push(this.selectedStatus[i]);
//     }    
   
//     if (this.selectedUser.length != 0) {
//       this.myArray.push({
//         name: "userids",
//         value: userList,
//       });
//     }

//     if (this.selectedStatus.length != 0) {
//       this.myArray.push({
//         name: "status",
//         value: statusList,
//       });
//     }  
//       // Add the date filter here

//       if (startDate && endDate) {
//         const formattedStartDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');;
//         const formattedEndDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');
//         this.myArray.push({
//           name: "start_date",
//           value: [formattedStartDate],
//         });
//         this.myArray.push({
//           name: "end_date",
//           value: [formattedEndDate],
//         });
      
//       }
      
//       if(state && state != '0')
//       {
//         this.myArray.push({
//           name: "state",
//           value: state,
//         });
//       }
//       if(city && city != '0')
//       {
//         this.myArray.push({
//           name: "city",
//           value: city,
//         });
//       }

//     this.activeFilter = data;
//     this.filterValue1 = data; 

//     localStorage.removeItem('status')
//     if(this.viewback == 'true')
//    {
//      const getArray = localStorage.getItem('filterArray');
//     const parsedArray = JSON.parse(getArray);
//     this.myArray = parsedArray;
//     //  this.myArray = this.myArray.filter(item => item.name !== 'status');
//    }

//     if(data == 'All')
//      {
//        // this.myArray = this.myArray.filter(item => item.name !== 'status');
//        this.myArray.push({
//          name: "status",
//          value: ["CONFIRMED","PENDING", "DECLINED"]
//        });
//        this.activeFilter = null;
//        this.selectedStatus = ["PENDING", "DECLINED", "CONFIRMED"];
//        const jsonString = JSON.stringify(this.myArray);
//        localStorage.setItem('filterArray', jsonString); 
      
//      }
//      else {
    
//        this.myArray.push({
//          name: "status",
//          value: [data],
//        });
//        this.selectedStatus = [data];
//        const jsonString = JSON.stringify(this.myArray);
//        localStorage.setItem('filterArray', jsonString); 
//      }  
    
//         // Add the date filter here
//         if (startDate && endDate) {
//           const formattedStartDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');;
//           const formattedEndDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');
//           this.myArray.push({
//             name: "start_date",
//             value: [formattedStartDate],
//           });
//           this.myArray.push({
//             name: "end_date",
//             value: [formattedEndDate],
//           });
        
//         }
        
//         // function formatDate(dateString: string): string {
//         //   const dateObject = new Date(dateString);
//         //   const day = dateObject.getDate().toString().padStart(2, '0');
//         //   const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
//         //   const year = dateObject.getFullYear();
        
//         //   return `${day}-${month}-${year}`;
//         // }
//       if(state && state != '0')
//       {
//         this.myArray.push({
//           name: "state",
//           value: state,
//         });
//       }
//       if(city && city != '0')
//       {
//         this.myArray.push({
//           name: "city",
//           value: city,
//         });
//       }
 
  
//     console.log("+++++++++myArray",this.myArray);            
//     let data1 = {
//      filters: this.myArray, 
//      "search_entity":this.resourceName, 
//      "search_usertype_entity":selectedUserType,    
//       "page": 0, "page_size": 5000
//     };
   
//     if(this.filterValue == null){      
//       this.bookingService.SearchAllBookingData(data1).subscribe((res: any) => {
//         // console.log("res myarray___0603:", res);
//         this.data = res.results; 
//         // this.list = res.results;
//         console.log("TEST+++++++++++",res.results)   
//         // this.updateStatusCounts(this.data);   
//         this.p = 1
//       });   
     
//     }

//     else{   
//     let data = {    
//       "page": 0, "page_size": 2984
//   }
 
//     console.log("data____filterdata", data);
//     this.bookingService.SearchAllBookingData(data).subscribe((res: any) => {
//       console.log("pendinggggg.........:", res);
//       this.data = res.results; 
//       // this.list = res.results;
//       // this.updateStatusCounts(this.data);     
//       this.p = 1 
//     });
//   }
//   }

  countFilter(data){
    this.p = 1;
    console.log('data', data);
    if(data === "ALL"){
      console.log('this list', this.data)
      const filterData = ["CONFIRMED", "PENDING", "DECLINED"]
      const newFilter = this.data.filter((item: any) => item.status == 'APPROVED' || 'REJECTED' || 'PENDING');
      console.log('new filter', newFilter);
      this.selectedStatus = filterData
      this.activeFilter = null;
      this.list = newFilter;
    }
    else{
      console.log('this list', this.data)
      const newFilter = this.data.filter((item: any) => item.status == data);
      console.log('new filter', newFilter);
      this.selectedStatus = [data]
      this.activeFilter = data;
      this.list = newFilter;
    }
  }

// count filter
  // filter
  filterItem() {
    this.filterEnable = true
    // this.filterMode = false;
    // this.resetMode = true;  
    this.myArray = [];
    var userList = [];
    var statusList =[];  
    var selectedUserType = this.bookingFilterForm.get('selectedUserType').value || null;
    var startDate = this.bookingFilterForm.get('start_date').value;
    var endDate = this.bookingFilterForm.get('end_date').value;
    var state = this.bookingFilterForm.get('state').value;
    var city = this.bookingFilterForm.get('city').value;
     
        
    for (var i = 0; i < this.selectedUser.length; i++) {
      userList.push(this.selectedUser[i].item_id);
    }

    for (var i = 0; i < this.selectedStatus.length; i++) {
      statusList.push(this.selectedStatus[i]);
    }    
   
    if (this.selectedUser.length != 0) {
      this.myArray.push({
        name: "userids",
        value: userList,
      });
    }

    // if (this.selectedStatus.length != 0) {
    //   this.myArray.push({
    //     name: "status",
    //     value: statusList,
    //   });
    // }  
      // Add the date filter here

      if (startDate && endDate) {
        const formattedStartDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');;
        const formattedEndDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');
        this.myArray.push({
          name: "start_date",
          value: [formattedStartDate],
        });
        this.myArray.push({
          name: "end_date",
          value: [formattedEndDate],
        });
      
      }
      
      // function formatDate(dateString: string): string {
      //   const dateObject = new Date(dateString);
      //   const day = dateObject.getDate().toString().padStart(2, '0');
      //   const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
      //   const year = dateObject.getFullYear();
      
      //   return `${day}-${month}-${year}`;
      // }
      if(state && state != '0')
      {
        this.myArray.push({
          name: "state",
          value: state,
        });
      }
      if(city && city != '0')
      {
        this.myArray.push({
          name: "city",
          value: city,
        });
      }      


      this.activeFilter = this.selectedStatus.length == 3 ? null : (this.selectedStatus.length > 0 && this.selectedStatus.length < 3 ? this.selectedStatus : null)
      this.filterValue1 = this.selectedStatus
 
  
    console.log("+++++++++myArray",this.myArray);            
    let data1 = {
     filters: this.myArray, 
     "search_entity":this.resourceName, 
     "search_usertype_entity":selectedUserType,    
      "page": 0, "page_size": 5000
    };
   
    if(this.filterValue == null){      
      this.bookingService.SearchAllBookingData(data1).subscribe((res: any) => {
        // console.log("res myarray___0603:", res);
        this.data = res.results; 
        if(this.selectedText === 'SUPERADMIN'){
          let toFilter = this.data.filter(item => item.username == null);
          console.log(toFilter)
          this.list=[];
          if(this.selectedStatus.length > 0){
            console.log('this selectedStatus', this.selectedStatus);
            this.selectedStatus.forEach(status => {
              const filterStatus = toFilter.filter(item => item.status == status);
              console.log(filterStatus);
              console.log(this.list)
              this.list.push(...filterStatus);            
            })
            console.log('955', this.list)
          }
          else{
            this.list = toFilter;
          }
          this.updateStatusCounts(toFilter); 
        }
        else{
          this.list = [];
          if(this.selectedStatus.length > 0){
            console.log('this selectedStatus', this.selectedStatus);
            this.selectedStatus.forEach(status => {
              const filterStatus = this.data.filter(item => item.status == status);
              console.log(filterStatus);
              console.log(this.list)
              this.list.push(...filterStatus);            
            })
            console.log('955', this.list)
          }
          else{
            this.list = this.data;
          }
          this.updateStatusCounts(this.data); 
        }
        
        // this.list = res.results;
        console.log("TEST+++++++++++",res.results)   
          
        this.p = 1
        
      });   
     
    }

    else{   
    let data = {    
      "page": 0, "page_size": 2984
  }
 
    console.log("data____filterdata", data);
    this.bookingService.SearchAllBookingData(data).subscribe((res: any) => {
      console.log("pendinggggg.........:", res);
      this.data = res.results; 
      // this.list = res.results;
      this.updateStatusCounts(this.data);     
      this.p = 1;
      
    });
  }
  }

  resetFilters() {
    // this.resetMode = false;
    // this.filterMode = true;
    this.activeFilter = null;
    this.filterEnable = false;
    // clear the filter criteria object
    this.data = {};
  
      // this.selectsector = "";
       this.selectedUser = [];
      //  this.selectedUserType ={};
       this.selectedStatus = [];
       this.bookingFilterForm.get('selectedUserType').reset();
       this.bookingFilterForm.get('selectedUserType').setValue('ALL');
       this.bookingFilterForm.get('start_date').reset();
       this.bookingFilterForm.get('end_date').reset();
       this.bookingFilterForm.get('state').setValue('0');
       this.bookingFilterForm.get('city').setValue('0');
    // reset the filtered data to the original data
    // this.list = this.originalData;
    // this.getBookingList(this.facility);
    this.filterItem()
    // this.updateStatusCounts(this.data);
    this.p = 1;
    

  }
  // filter
}
