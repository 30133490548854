<div class="container">
    <div class="row">        
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="container pt-4">        
            <div class="shadow-lg py-4 mb-5 bg-white border-radius rounded" id="bank-details">
              <!-- Add your content for the Bank Details tab here -->
              <form id="bankDetailsId" [formGroup]="bankDetailsForm" (ngSubmit)="onBankDetailsSubmit()" class="row g-3">
                <div class="form-group col-md-6">
                  <label for="accountHolderName" class="form-label font-weight-bold">Name of Account Holder<span class="text-danger">*</span></label>
                  <input type="text" placeholder="Name of Account Holder" class="form-control" formControlName="accountHolderName" id="accountHolderName">
                  <div *ngIf="_accountHolderName?.touched && _accountHolderName?.invalid" class="text-danger">
                    <div *ngIf="_accountHolderName?.errors?.required">
                      <p class="text-danger">This Field is mandatory</p>
                    </div>
                    <div *ngIf="_accountHolderName?.errors?.pattern">
                      <p class="text-danger">Please provide valid account holder name</p>
                    </div>
                  </div> 
                </div>
                <div class="form-group col-md-6">
                  <label for="bankName" class="form-label font-weight-bold">Name of the Bank<span class="text-danger">*</span></label>
                  <input type="text" placeholder="Name of the Bank" class="form-control" formControlName="bankName" id="bankName">
                  <div *ngIf="_bankName?.touched && _bankName?.invalid" class="text-danger">
                    <div *ngIf="_bankName?.errors?.required">
                      <p class="text-danger">This Field is mandatory</p>
                    </div>
                    <div *ngIf="_bankName?.errors?.pattern">
                      <p class="text-danger">Please provide the valid bank name</p>
                    </div>
                  </div> 
                </div>
                <div class="form-group col-md-6">
                  <label for="bankAddress" class="form-label font-weight-bold">Address<span class="text-danger">*</span></label>
                  <textarea class="form-control" placeholder="Address" formControlName="bankAddress" id="bankAddress" rows="3"></textarea>
                  <div *ngIf="_bankAddress?.touched && _bankAddress?.invalid" class="text-danger">
                    <div *ngIf="_bankAddress?.errors?.required">
                      <p class="text-danger">This Field is mandatory</p>
                    </div>
                    <div *ngIf="_bankAddress?.errors?.pattern">
                      <p class="text-danger">Please provide valid bank address</p>
                    </div>
                  </div> 
                </div>
                <div class="form-group col-md-6">
                    <label for="accountNumber" class="form-label font-weight-bold">Account No<span class="text-danger">*</span></label>
                    <input type="text" placeholder="Account No" class="form-control" formControlName="accountNumber" id="accountNumber">
                    <div *ngIf="_accountNumber?.touched && _accountNumber?.invalid" class="text-danger">
                      <div *ngIf="_accountNumber?.errors?.required">
                        <p class="text-danger">This Field is mandatory</p>
                      </div>
                      <div *ngIf="_accountNumber?.errors?.minlength">
                        <p class="text-danger">Account no must be minimum of 10 characters</p>
                      </div>
                      <div *ngIf="_accountNumber?.errors?.maxlength">
                        <p class="text-danger">Account no should not exceed 20 characters</p>
                      </div>
                    </div> 
                  </div>
                  <div class="form-group col-md-6">
                    <label for="accountType" class="form-label font-weight-bold">Account Type<span class="text-danger">*</span></label>
                    <div>
                      <select class="form-control" formControlName="accountType" id="accountType">
                        <option value="" disabled selected>Select Account Type</option>
                        <option value="SAVINGS">Savings</option>
                        <option value="CURRENT">Current</option>
                        <option value="FIXED">Fixed Deposit</option>
                      </select>
                    </div>
                    <div *ngIf="_accountType?.touched && _accountType?.invalid" class="text-danger">
                      <div *ngIf="_accountType?.errors?.required">
                        <p class="text-danger">This Field is mandatory</p>
                      </div>
                    </div> 
                  </div>
                  <div class="form-group col-md-6">
                    <label for="ifscCode" class="form-label font-weight-bold">IFSC Code<span class="text-danger">*</span></label>
                    <input type="text" placeholder="IFSC Code" class="form-control" formControlName="ifscCode" id="ifscCode">
                    <div *ngIf="_ifscCode?.touched && _ifscCode?.invalid" class="text-danger">
                      <div *ngIf="_ifscCode?.errors?.required">
                        <p class="text-danger">This Field is mandatory</p>
                      </div>
                      <div *ngIf="_ifscCode?.errors?.pattern">
                        <p class="text-danger">Please provide valid ifsc code</p>
                      </div>
                    </div>  
                  </div>
                  <div class="col-12 mt-2">
                    <button type="submit" class="btn btn-primary">Submit</button>
                  </div>
              </form>
            </div>
        </div>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>
