import { F } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { isArray } from 'rxjs/internal/util/isArray';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { IncubatorService } from '../../../services/incubator/incubator.service';
import { log } from 'console';

@Component({
  selector: 'ngx-scheme-incubator-uc-documents',
  templateUrl: './scheme-incubator-uc-documents.component.html',
  styleUrls: ['./scheme-incubator-uc-documents.component.scss']
})
export class SchemeIncubatorUCDocumentsComponent implements OnInit {
  certificateDetail:FormGroup;
  inc_fund_id: any;
  incubator_id:any;
  fundUCDocuments:any;
  form: any;
  ucCount:number=0;

  updateDocumentDetails:any;
  yearToShow: any;
  chooseYear: any[] = [];
  createdDate!: number;
  tenure!: number;
  
  constructor(
    private router: Router, 
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private incubatorService: IncubatorService,
    private _route: ActivatedRoute,
    private grantMangementService: GrantManagementService,) { }

  ngOnInit(): void {;
     this.certificateDetail=this.fb.group({
      noOfUcs: ['', Validators.required],
      year: [this.yearToShow],
      // durationDocuments: this.fb.array([this.createDurationDocument()]),
      fileDocuments: this.fb.array([this.createFileDocument()])
    });
    
    this.inc_fund_id = this._route.snapshot.paramMap.get("incid");
    this.incubator_id = this._route.snapshot.paramMap.get("incubator_id");
    
    this.getSchemes();

    if(this.inc_fund_id && this.incubator_id) {
      this.getIncFundDocumentsByIncId(this.inc_fund_id, this.yearToShow);
    }
  }

  async viewYear(){
    const dropdown = document.getElementById("yearDropdown") as HTMLSelectElement;
    const selectedValue = dropdown.value;
  
  // Call a function in your TypeScript file and pass the selected value
  console.log(selectedValue);
  this.yearToShow = selectedValue;


  this.ucCount=0;
  this.getIncFundDocumentsByIncId(this.inc_fund_id, this.yearToShow);
  
  }

  get durationDocuments(): FormArray {
    return this.certificateDetail.get('durationDocuments') as FormArray;
  }

  get fileDocuments(): FormArray {
    return this.certificateDetail.get('fileDocuments') as FormArray;
  }

  createDurationDocument() {
    return this.fb.group({
      duration: [''],
      term_id:['']
    });
  }

  createFileDocument() {
    return this.fb.group({
      file: [''],
      term_id:['']
    });
  }

  patchData() {
    const data:any = this.updateDocumentDetails;
    console.log("PATCH DATALL:::", data);
    this.certificateDetail.setControl('durationDocuments', this.fb.array([]));
    this.certificateDetail.setControl('fileDocuments', this.fb.array([]));
    data.forEach(doc => {
      const durationDocument = this.createDurationDocument();
      const fileDocument = this.createFileDocument();
      durationDocument.patchValue(doc);
      fileDocument.patchValue(doc);
      this.durationDocuments.push(durationDocument);
      this.fileDocuments.push(fileDocument);
      if(fileDocument?.get('file')?.value?.file_url) {
        this.ucCount += 1;
      }
      this.certificateDetail.get('noOfUcs').patchValue(this.ucCount);
      let dataS = this.fileDocuments.controls[0];
      console.log("FILE DOCUMENTS:::",dataS.get('file').value);
    });
  }

  onCertificatesSubmit() {
    if (this.certificateDetail.invalid) {
      // show error message
      this.certificateDetail.markAllAsTouched();
      console.log("Please fill in all mandatory fields.",this.certificateDetail.value);
      return;
    } else {
      console.log(this.certificateDetail.value); 
    }
  }

  getIncFundDocumentsByIncId(inc_fund_id:any, year: any) {
    this.grantMangementService.getIncFundUCDocuments(inc_fund_id).subscribe((response:any)  => {

      let yearIndex = -1;
      for (let i = 0; i < this.chooseYear.length; i++) {
        console.log('Running for loop')
        if (this.chooseYear[i].id == this.yearToShow) {
            // console.log('Running if loop')
            yearIndex = i;
            console.log('yearIndex', yearIndex)
            break; // Exit loop if found
        }
      }

      // Slicing the data accordingly
      let start = yearIndex * 4;
      let end = (yearIndex + 1) * 4;
      let years = response.results.slice(start, end);
      console.log("Sliced array:", years);

      this.updateDocumentDetails =  years;

      // this.updateDocumentDetails = yearArray.filter((item: any) => item.hasOwnProperty("file") )?.length;
      console.log("::::++==",this.updateDocumentDetails)

      this.fundUCDocuments = response;
      console.log(response.results);
      this.fundUCDocuments=response
      this.patchData()
    })
  }

  get documents(): FormArray {
    return this.certificateDetail.get('durationDocuments') as FormArray;
  }

  get _noOfUcs(){
    return this.certificateDetail.get('noOfUcs');
  }
  // downloadFile(fileDoc:any, fName:any) {

  //   const fileUrl = fileDoc.get('file').value.file_url;
  //   const fileName = `Q${fName+1}`;

  //   // Create an anchor element and set its href and download attributes
  //   const a = document.createElement('a');
  //   a.setAttribute('href', fileUrl);
  //   a.setAttribute('download', fileName);
  //   a.setAttribute('target', '_blank')

  //   // Programmatically click the anchor element to trigger the download
  //   a.click();
  // }




  getQuarterNumber(quarter: any): string {
    // You can implement your logic to determine the quarter number based on the object's index
    return `${this.updateDocumentDetails.indexOf(quarter) + 1}`;
  }

  downloadDocument(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }
  downloadStatement(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }
  acceptDocument(termId: string): void {
    const confirmed = confirm("Are you sure you want to Accept Document?");
    if (confirmed) {
    const documentStatus = {
      term_id: termId,
      status: 'ACCEPTED',
      comment: ''
    };

    this.grantMangementService.updateStatusOnDoc(documentStatus, this.inc_fund_id).subscribe(res => {
      // this.incfundutilresponse = res;
      // this.submitted = true;
      this.toastrService.success('Accepted Document Successfully!');
      this.getIncFundDocumentsByIncId(this.inc_fund_id, this.yearToShow);
    });
  }else{
    console.log("acceptance denied")
  }
  }

  // rejectDocument(termId: string): void {
  //   const confirmed = confirm("Are you sure you want to Reject Document?");
  //   if (confirmed) {
  //   const documentStatus = {
  //     term_id: termId,
  //     status: 'REJECTED',
  //     comment: 'incorrect information'
  //   };

  //   this.grantMangementService.updateStatusOnDoc(documentStatus, this.inc_fund_id).subscribe(res => {
  //     // this.incfundutilresponse = res;
  //     // this.submitted = true;
  //     this.toastrService.success('Document Rejected Successfully!');
  //   });
  // }else{
  //   console.log("canceled")
  // }
  // }
  showRejectCommentPrompt(termId: string): void {
    const comment = prompt("Please enter the reason for rejecting the document:");
    if (comment !== null) {
      this.rejectDocument(termId, comment);
    } else {
      console.log("Rejection comment canceled");
    }
  }
  
  rejectDocument(termId: string, comment: string): void {
    const confirmed = confirm("Are you sure you want to reject the document?");
    if (confirmed) {
      const documentStatus = {
        term_id: termId,
        status: 'REJECTED',
        comment: comment
      };
  
      this.grantMangementService.updateStatusOnDoc(documentStatus, this.inc_fund_id).subscribe(res => {
        // this.incfundutilresponse = res;
        // this.submitted = true;
        this.toastrService.success('Document Rejected Successfully!');
        this.getIncFundDocumentsByIncId(this.inc_fund_id, this.yearToShow);
      });
    } else {
      console.log("Document rejection canceled");
    }
  }
  


  //disable accept button if already accepted document
  isLastCommentAccepted(commentList): boolean {
    // Assuming 'comments' is an array of comment objects in your component
    const comments = commentList;
  
    if (comments.length > 0) {
      const lastComment = comments[comments.length - 1];
      if(lastComment.status === 'ACCEPTED'){
        return true;
      }
    }
  
    return false;
  }
// change accept text to accepted
  getAcceptButtonText(commentList): string {
    if (this.isLastCommentAccepted(commentList)) {
      return 'Accepted';
    }
  
    return 'Accept';
  }


  isLastCommentRejected(commentList): boolean {
    // Assuming 'comments' is an array of comment objects in your component
    const comments = commentList;
  
    if (comments.length > 0) {
      const lastComment = comments[comments.length - 1];
      if(lastComment.status === 'REJECTED'){
        return true;
      }
    }
    return false;
  }
// 
  getRejectButtonText(commentList): string {
    if (this.isLastCommentRejected(commentList)) {
      return 'Rejected';
    }
  
    return 'Reject';
  }


  getSchemes(){
    this.incubatorService.getIncubatorProjectDetails(this.inc_fund_id).subscribe((response: any) => {
   
    console.log("response",response.results[0]);
    this.tenure=response.results[0].tenureyears_count
    this.createdDate = 2024;
    console.log("tenure",this.tenure, this.createdDate);
   
    this.yearsToChoose();
  })   
  }


  yearsToChoose(){
    const startYear: number = this.createdDate ;
    const endYear: number = Number(this.createdDate) + Number(this.tenure);
 
    console.log('Year range', startYear, '-', endYear)
 
    for (let oneYear = startYear; oneYear < endYear; oneYear++) {
// console.log(oneYear)
      // years.push(year);
      const yearAdd = oneYear + "-" + (oneYear+1);
      // console.log(yearAdd)
      this.chooseYear.push({ id: oneYear, name: yearAdd });
      // this.chooseYear.push(oneYear)
      // console.log(this.chooseYear)
    }

    this.yearToShow = this.chooseYear[0].id;
    
    console.log(this.chooseYear)
    // this.yearSelected = this.chooseYear[0].id;
    // console.log(this.yearSelected)
    
  }
  
  
  
  
}
