<div class="col-12 col-sm-12">
    <nb-card>
      <nb-card-header>Permission Form</nb-card-header>
      <nb-card-body class="container">
        <form class="ng-pristine ng-invalid" [formGroup]="permissionsForm" (ngSubmit)="submitForm()">
            <div class="form-control-group ">
                <!-- <label for="input-email" class="label">Name:</label> -->
                    <input type="text" nbInput fullWidth status="basic" name="name" id="input-name" placeholder="Permission Name"
                     autofocus="" formControlName="name"
                        class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition space">

            </div>  
            
            <div class="form-control-group ">
                <!-- <label for="input-email" class="label">Mobile Number:</label> -->
                <input nbinput="" fullwidth="" name="description" id="description" placeholder="Permission Description"
                    fieldsize="large" [ngClass]="{'status-danger':!permissionsForm.get('description')?.valid &&
                permissionsForm.get('description')?.dirty}" autofocus="" formControlName="description"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition space">
            </div>
        
            <div class="form-control-group ">
                    <label for="name" class="label">Permission Actions:</label>
            </div>
            <div class="form-control-group ">
                <nb-card-body formGroupName="menus" class="example-items-rows" [ngClass]="{'status-danger':!permissionsForm.get('description')?.valid &&
                permissionsForm.get('description')?.dirty}" autofocus="" *ngFor="let cat of menus"
                class="size-medium status-basic shape-rectangle nb-transition">
                   <nb-checkbox formControlName="create"  status="basic">Create</nb-checkbox>
                   <nb-checkbox formControlName="view"  status="primary">View</nb-checkbox>
                   <nb-checkbox formControlName="edit"  status="success">Edit</nb-checkbox>
                   <nb-checkbox formControlName="delete"  status="danger">Delete</nb-checkbox>
                 </nb-card-body>
            </div>

             <div class="row">
             <div class="col-3">
                 <button nbButton [disabled]="permissionsForm.invalid" fullwidth="" status="primary"
                size="medium" class="appearance-filled size-small shape-rectangle status-primary nb-transition">
                {{editMode? 'Update' : 'Add Data'}}</button>
            </div>
            <div class="col-3">

                <button nbButton (click)="cancelData()" fullwidth="" status="primary"
                size="medium"
                class="appearance-filled size-small shape-rectangle status-primary nb-transition">Cancel</button>
            </div>
            </div>
        </form>
        </nb-card-body>
    </nb-card>
    
    <nb-card>
        <nb-card-header>Permission Details</nb-card-header>
        <nb-card-body>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Permission Name</th>
                        <th>Descriptions</th>
                        <th>Create</th>
                        <th>Edit</th>
                        <th>View</th>
                        <th>Delete</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of tableData">
                        <td>{{data.module}}</td>
                        <td>{{data.description}}</td>
                        <td ><nb-checkbox [(ngModel)]="data.conditions.create"  status="primary" disabled></nb-checkbox></td>
                        <td ><nb-checkbox [(ngModel)]="data.conditions.view"  status="primary" disabled></nb-checkbox></td>
                        <td ><nb-checkbox [(ngModel)]="data.conditions.edit"  status="primary" disabled></nb-checkbox></td>
                        <td ><nb-checkbox [(ngModel)]="data.conditions.delete"  status="primary" disabled></nb-checkbox></td>
                        <td>
                            <span class="fa fa-edit" (click)="editPermissionData(data, i)" style = "color:blue" ></span>
                            <span class="fa fa-trash" (click)="deleteItem(data)" style = "color:red" ></span></td>    

                    </tr>
                   
                </tbody>
            </table>

      </nb-card-body>
    </nb-card>
</div>
