import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isArray } from 'rxjs/internal-compatibility';
import { isatty } from 'tty';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { IncubatorService } from '../../../services/incubator/incubator.service';
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { DatePipe } from '@angular/common';
@Component({
  selector: 'ngx-scheme-incubator-project-financials',
  templateUrl: './scheme-incubator-project-financials.component.html',
  styleUrls: ['./scheme-incubator-project-financials.component.scss']
})
export class SchemeIncubatorProjectFinancialsComponent implements OnInit {
  projectFinancial:any;
  fullResponse:any;
  @Input() fundRes:any;
  duration: any;
  incid: any;
  incubator_id:any;
  startupData: any;
  project: FormGroup;
  terms: FormGroup;
  incubatorFundRes: any;
  incubatorfund_id:any;
  submitted:any;
  incfundresponse:any;
  inc_fund_id:any;
 
  constructor(
    private router: Router,
    private _route: ActivatedRoute, 
    private fb: FormBuilder,
    private incubatorService: IncubatorService,
    private grantMangementService: GrantManagementService,
    private toastrService: NbToastrService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.inc_fund_id = this._route.snapshot.paramMap.get("incid");
    this.incubator_id = this._route.snapshot.paramMap.get("incubator_id");
    
    if(this.inc_fund_id && this.incubator_id) {
      this.grantMangementService.getIncubatorSchemeFund(this.inc_fund_id).subscribe(res=>{
        console.log('RESPONSE:::',res);
        this.incfundresponse = res;
        if(this.incfundresponse?.results.length>0) {
          this.patchUpdatedNestedForm();
        } else {
          const fundres:any = sessionStorage.getItem('fundres');
          //this.getIncProjectDetailsByIncId(this.inc_fund_id);
          this.fundRes = JSON.parse(fundres);
          this.patchInitialNestedForm();
        }
      });
    }

    this.projectFinancial = this.fb.group({
      //fundingSource:['',[Validators.required,Validators.maxLength(60)]],
      financialDetails: this.fb.array([
        this.fb.group({
          nameOfStartup:['', [Validators.required]],
          eir_Grant:['', [Validators.required]],
          approved_amount:['', [Validators.required,Validators.pattern("^[0-9]*$")]],
          utilAmount:[''],
          unutilAmount:[''],
          termsDetails: this.fb.array([
            this.fb.group({
              dateOfSanction:['', [Validators.required]],
              dateOfReceive:[''],
              incubatorfundterm_id:[],
              quarterAmount:['',[Validators.required, Validators.pattern("^[0-9]*$")]],
            })
          ])
        })
      ])
    });
    this.getStartupsByIncId(this.incubator_id)
  }



  //============
  get financialDetails() {
    return this.projectFinancial.get('financialDetails') as FormArray;
  }

  addFinancialDetail() {
    const financialDetail = this.fb.group({
      nameOfStartup:['', [Validators.required]],
      eir_Grant:['', [Validators.required]],
      approved_amount:['', [Validators.required,Validators.pattern("^[0-9]*$")]],
      termsDetails: this.fb.array([
        this.fb.group({
          dateOfSanction:['', [Validators.required]],
          dateOfReceive:[''],
          incubatorfundterm_id:[],
          quarterAmount:['',[Validators.required, Validators.pattern("^[0-9]*$")]],
        })
      ])
    });
    this.financialDetails.push(financialDetail);
  }

  removeFinancialDetail(index: number) {
    this.financialDetails.removeAt(index);
  }

  getTermsDetails(index: number) {
    return this.financialDetails.at(index).get('termsDetails') as FormArray;
  }

  addTermsDetail(index: number) {
    const termDetail = this.fb.group({
      dateOfSanction:['', [Validators.required]],
      dateOfReceive:[''],
      quarterAmount:['',[Validators.required, Validators.pattern("^[0-9]*$")]],
    });
    this.getTermsDetails(index).push(termDetail);
  }

  removeTermsDetail(financialDetailIndex: number, termDetailIndex: number) {
      this.getTermsDetails(financialDetailIndex).removeAt(termDetailIndex);
  }
  onSubmit(): void {
      if (this.projectFinancial.invalid) {
        // show error message
        this.projectFinancial.markAllAsTouched();
        console.log("Please fill in all mandatory fields.",this.projectFinancial.value);
        return;
      }else{
        console.log("onProjectFinancialSubmit",this.projectFinancial.value);

        //remap the fields as per api
        let projectFinancialsArr = this.projectFinancial.value.financialDetails;
        let modifiedArr = [];
        if(projectFinancialsArr && isArray(projectFinancialsArr) && projectFinancialsArr.length>0) {
          let innerObj:any = {};
          for(let i=0; i<projectFinancialsArr.length; i++) {
            let innerObj:any = {};
            innerObj.grant_amount = projectFinancialsArr[i].approved_amount;
            innerObj.grant_type = projectFinancialsArr[i].eir_Grant;
            innerObj.startup_id = projectFinancialsArr[i].nameOfStartup;
            //innerObj.incubator_id = this.incubator_id;
            innerObj.incubatorfund_id = this.inc_fund_id;
            innerObj.term_accounts = this.modifiedTermKeys(projectFinancialsArr[i].termsDetails, projectFinancialsArr[i].approved_amount);
            modifiedArr.push(innerObj);
          }
        }
        const mapObj = {
          //"funding_source": this.projectFinancial.value.fundingSource,
          "sf_list":modifiedArr
        }

        // API call with mapObj to bulk update here

        this.grantMangementService.createIncubatorSchemeFund(mapObj).subscribe(res=>{
          this.incfundresponse = res;
          this.submitted = true;
          this.toastrService.success("Updated Scheme Fund Successfully!");
          window.location.reload();
        });
      }
  }

  modifiedTermKeys(termArr, approvedAmount) {
    let newTermArr:any = [];
    if(termArr && isArray(termArr) && termArr.length>0) {
      let totalC = 0;
      for(let j=0; j<termArr.length;j++) {
        let newTermInnerObj:any = {};
        newTermInnerObj.incubatorfundterm_id = termArr[j].incubatorfundterm_id;
        newTermInnerObj.amount_sanction_date = termArr[j].dateOfSanction;
        newTermInnerObj.amount_received_date = termArr[j].dateOfReceive
        newTermInnerObj.amount_received      = termArr[j].quarterAmount;
        newTermArr.push(newTermInnerObj)
        totalC += Number(newTermInnerObj.amount_received);
      }

      if(totalC>approvedAmount) {
        this.toastrService.danger("Quarter amounts should be less than approved amounts");
        return;
      }
    }
    return newTermArr;
  }

//patch
  patchInitialNestedForm() {
    const fDetails = [];
    const fundArr:any = this.fundRes.results;
    /*this.projectFinancial.patchValue({
      fundingSource:fundArr[0].funding_source
    });*/
    console.log("FDETAILS:BEFORE:::", fundArr.length)
    if(fundArr && isArray(fundArr) && fundArr.length>0) {
        for(let i=0; i<fundArr.length;i++) {
          let startupsCount = fundArr[i].startups_finalized_count;
          if(startupsCount>0) {
            for(let j=0; j<startupsCount; j++) {
              const detailObj = {
                "nameOfStartup": "",
                "eir_Grant": "",
                "approved_amount": "",
                "termsDetails": fundArr[i].terms,
              }
              fDetails.push(detailObj);
            }
          }
        }
    }
    
    console.log("FDETAILS AFTER::::", fDetails)
    for (let i = 0; i < fDetails.length; i++) {
      const financialDetail = fDetails[i];

      const group = this.fb.group({
        nameOfStartup: [financialDetail.nameOfStartup, [Validators.required]],
        eir_Grant: [financialDetail.eir_Grant, [Validators.required]],
        approved_amount: [financialDetail.approved_amount, [Validators.required, Validators.pattern("^[0-9]*$")]],
        termsDetails: this.fb.array([])
      });

      // Loop through the terms details and add a new group for each item
      const termsDetails = financialDetail.termsDetails;
      for (let j = 0; j < termsDetails.length; j++) {
        const termDetail = termsDetails[j];

        const termGroup = this.fb.group({
          quarterAmount: [termDetail.quarterAmount, [Validators.required, Validators.pattern("^[0-9]*$")]],
          dateOfSanction: [termDetail.dateOfSanction, [Validators.required]],
          dateOfReceive: [termDetail.dateOfReceive],
          incubatorfundterm_id:[termDetail.id]
        });

        // Add the term detail group to the current financial detail group
        (group.get('termsDetails') as FormArray).push(termGroup);
      }

      // Add the financial detail group to the form array
      (this.projectFinancial.get('financialDetails') as FormArray).push(group);
    }
    this.financialDetails.removeAt(0);
  }


  patchUpdatedNestedForm() {
    const fDetails = [];
    const fundArr:any = this.incfundresponse.results;
    console.log("FUND ARAAY:::", fundArr);
    //this.projectFinancial.fundingSource = [fundArr?.funding_source,[Validators.required,Validators.maxLength(60)]];
    if(fundArr && isArray(fundArr) && fundArr.length>0) {
        for(let i=0; i<fundArr.length;i++) {
          const detailObj = {
            "nameOfStartup": fundArr[i].startup_id,
            "eir_Grant": fundArr[i].grant_type,
            "approved_amount": fundArr[i].grant_amount,
            "termsDetails": fundArr[i].termaccounts,
            "utilAmount":this.calculateTotal(fundArr[i].termaccounts),
            "unutilAmount":this.calculateunUtilizedTotal(fundArr[i].termaccounts, fundArr[i].grant_amount)
          }
          fDetails.push(detailObj);
        }
    }

    for (let i = 0; i < fDetails.length; i++) {
      const financialDetail = fDetails[i];
      const group = this.fb.group({
        nameOfStartup: [financialDetail.nameOfStartup, [Validators.required]],
        eir_Grant: [financialDetail.eir_Grant, [Validators.required]],
        approved_amount: [financialDetail.approved_amount, [Validators.required, Validators.pattern("^[0-9]*$")]],
        termsDetails: this.fb.array([]),
        utilAmount: financialDetail.utilAmount,
        unutilAmount: financialDetail.unutilAmount
      });
      // Loop through the terms details and add a new group for each item
      const termsDetails = financialDetail.termsDetails;
      for (let j = 0; j < termsDetails.length; j++) {
        const termDetail = termsDetails[j];
        const dateData = this.convertDate(termDetail.amount_sanction_date);
        let dateReceive = termDetail.amount_received_date;
        if(termDetail.amount_received_date && termDetail.amount_received_date !==null) {
          dateReceive = this.datePipe.transform(dateReceive, 'dd/MM/yyyy');
        }
        //console.log("termDetail::::",termDetail);
        const termGroup = this.fb.group({
          quarterAmount: [termDetail.amount_received, [Validators.required, Validators.pattern("^[0-9]*$")]],
          dateOfSanction: [dateData , [Validators.required]],
          dateOfReceive: [dateReceive],
          incubatorfundterm_id:[termDetail.incubatorfundterm_id]
        });
        // Add the term detail group to the current financial detail group
        (group.get('termsDetails') as FormArray).push(termGroup);
      }

      // Add the financial detail group to the form array
      (this.projectFinancial.get('financialDetails') as FormArray).push(group);
    }
    this.financialDetails.removeAt(0);
  }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [d.getFullYear(), pad(d.getMonth()+1), pad(d.getDate())].join('-')
  }
  

  getStartupsByIncId(id: any) {
    this.incubatorService.getStartupsByIncubatorId(id).subscribe(response => {
      this.startupData = response;
      console.log("RES:::,",this.startupData);
    })
  }

  getIncProjectDetailsByIncId(inc_fund_id: any) {
    this.incubatorService.getIncubatorProjectDetails(inc_fund_id).subscribe(response => {
      this.fundRes = response;
      this.patchInitialNestedForm();
    })
  }
 //utilized total

  calculateTotal(termDetails: any): number {
    let total = 0;
    for (let j = 0; j < termDetails.length; j++) {
      const amountUtilized = termDetails[j].amount_received;
      if (amountUtilized) {
        total += parseInt(amountUtilized, 10);
      }
    }
    return total;
  }

  //unutilized amount
  calculateunUtilizedTotal(termDetails: any, approvedAmount: number): number {
    let total = 0;
    for (let j = 0; j < termDetails.length; j++) {
      const amountUtilized = termDetails[j].amount_received;
      if (amountUtilized) {
        total += parseInt(amountUtilized, 10);
      }
    }
    return approvedAmount-total || 0;
  }
}
