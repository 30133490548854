import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AxiosServicesService } from '../../services/axios/axios-services.service';
import { RoleService } from '../../services/role.service';
// import { NbDialogService, NbToastrService } from "@nebular/theme";
import { resolve } from 'dns';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loginResponse:any;
    mobileNumForm: FormGroup;x
    inValidMessage:boolean = false;
    submitted = false;
    show: boolean = false;
    showeye:boolean = true;
    errorMsg!: string;
    errorVisible: boolean = false;
    // loginservice: any;
    constructor(
      private router: Router,
      private http: HttpClient,
      // private loginservice:LoginService
      private formBuilder: FormBuilder,
      private axiosService:AxiosServicesService,
      private roleService : RoleService,
      // private toastrService: NbToastrService,
      private errorService: ErrorService
      ) {}
  
    ngOnInit() {     
      this.mobileNumForm = this.formBuilder.group({
        mobile:[null, Validators.compose([Validators.required, Validators.minLength(6)])]
      });
      this.loginForm = this.formBuilder.group({
        email: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        password: [null, [Validators.required, Validators.minLength(6)]]
      });

      let expiryFlag = sessionStorage.getItem('expiryflag');      
      if(expiryFlag =="success"){
        // this.toastrService.danger(this.errorMsg);
        this.errorService.handleError("E0003");
        sessionStorage.removeItem('expiryflag');
      }
    }
    get f(){
      return this.loginForm.controls
    }
      passwordLogin(){
        this.submitted = true;
        if(!this.loginForm.valid){

        }
        else{
          let loginDetail = this.loginForm.value;
          let url =  'api/admin/login';
          this.axiosService.validateLogin(url,loginDetail).subscribe(
            async(data:any) => {
            if(data && data.hasOwnProperty('token')){
              // sessionStorage.setItem('login-status','success');
              // sessionStorage.setItem('admintoken',data.token)
              await this.setSessionToken();
              await this.setAdminToken(data.token);
              this.inValidMessage = false;
              console.log('session is set');
              
              this.router.navigate(['/dashboard']).then(()=>{
                console.log('dasboard is loaded');
                location.reload();
              });
              this.errorVisible = false;
            }
            else{
              this.inValidMessage = true;
            }
          },
          (error) => {
            
            console.log('error+++++',error.response.status);
            if(error.response.status == 429){           
              // this.toastrService.danger(' You are already exceeded the limit. Please wait for one hour.')
              this.errorService.handleError("E0001");
            }
            else if(error.response.status == 401){
              this.errorService.handleError("E0002");
            }
            else{
             
            if (!this.errorVisible) {
              if(error.response.data.debug.error ==='Unauthorized: User is not authorized for admin portal')
                this.errorService.handleError("E0010");
              else
                this.errorService.handleError("E0002");

              this.errorVisible = true;
              setTimeout(() => {
                this.errorVisible = false;
              }, 3000); 
            }
            }
            
            this.inValidMessage = true;
          }
          );
        }
        // if(this.loginForm.valid){

        // }
      }

    showpassword(){
      this.show = true;
      this.showeye = false;
    }

    hidepassword(){
      this.show = false;
      this.showeye = true;
    }

    async setSessionToken(){
      return new Promise((resolve,reject)=>{
        console.log("login-status++++++++++++++","success")
        var loginstatus = sessionStorage.setItem('login-status','success');
        resolve(loginstatus);
      })
     
    }
    async setAdminToken(token){
      return new Promise((resolve,reject)=>{
        console.log("admin Token ++++++++++++++",token)
        var admintoken = sessionStorage.setItem('admintoken',token);
        resolve(admintoken);
      })
     
    }
  }
  
