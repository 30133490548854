<div class="container bg-white p-4">
    <h2 class="text-center eir-grant">Utilized Under EIR-Grant​</h2>
    <table class="table table-striped  table-responsive text-center">
        <thead>
            <tr>
                <th scope="col">S.N</th>
                <th scope="col">EIR</th>
                <th scope="col" class="">Supp/Manag.​Cost EiR​</th>
                <th scope="col">Grant</th>
            <th scope="col">Supp/Manag.Cost Grant​</th>
            <th class="col-3">Low Eng.​Program​</th>
            <th scope="col">Work​ Shop</th>
            <th scope="col">Hackathon</th>
            <th scope="col">Challenge​ Grant</th>
            <th scope="col">Deep Eng.​Program​</th>
            <th scope="col">Amt.​Refunded​</th>
            <th scope="col">Total​ utilized​​</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Data 1</td>
                <td>Data 2</td>
                <td>Data 3</td>
                <td>Data 3</td>
                <td>This list is ​downloadable</td>
                <td>dfd​</td>
                <td>Data 3</td>
                <td> Disable For G1,G2</td>
                <td>Disable For G1,G2</td>
                <td>Disable For G1,G2</td>
                <td></td>
                <td>*B</td>
            </tr>
            <tr>
                <td>Data 1</td>
                <td>Data 2</td>
                <td>Data 3</td>
                <td>Data 3</td>
                <td>Data 3</td>
                <td>Data 3</td>
                <td>Data 3</td>
                <td>Data 3</td>
                <td>Data 3</td>
                <td>Data 3</td>
                <td></td>
                <td>Data 3</td>
            </tr>
            <!-- Add more rows as needed -->
        </tbody>
    </table>
</div>
