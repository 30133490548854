
<div class="container py-3">
  <!-- <ngx-gia-utilized-table></ngx-gia-utilized-table> -->
</div>

<div class="container">
    <div class="row  scroll-bar">
    <div class="whitebg w-100" >
    <h2 class="text-center py-4">Welcome to Finance Manager Dashboard</h2>
  
    <div class="row p-3 d-flex justify-content-around">
        <div class="row p-4 d-flex justify-content-around">
          <div class="col-12 col-md-11 aboutmeity " *ngIf="scheme_id == 1">
            <h2 class="text-center my-3">About the TIDE 2.0</h2>
              <p class="p-3">TIDE 2.0 will promote tech entrepreneurship through financial and technical support to incubators engaged in supporting ICT startups primarily engaged in using emerging technologies such as IoT, AI, Block-chain, Robotics etc. in pre-identified areas of societal relevance.</p>
              <p class="p-3">The Scheme will be implemented through empowering 51 incubators in India and handholding close to 2000 tech start-ups over a period of 5 years. The scheme envisions creating a holistic ecosystem to support technology startups and incubation centers through identifying and creating necessary linkages.</p>
              <p class="p-3">MeitY Startup Hub (MSH) would be setup under TIDE 2.0 to ensure synergistic linkages among the TIDE centres, theme based incubation centres, Centre of Excellences on emerging technologies and other collaborative platforms. The MSH will also ensure crisscrossing of technology resources across the innovation ecosystem to come out with technology solutions to various societal challenges.</p>
              <p class="p-3">Technology Incubation and Development of Entrepreneurs (TIDE 2.0) Scheme has been envisaged to promote tech entrepreneurship through financial and technical support to incubators engaged in supporting ICT startups using emerging technologies such as IoT, AI, Block-chain, Robotics etc. in seven pre-identified areas of societal relevance. The Scheme will be implemented through 51 incubators and handholding of approximately 2000 tech start-ups over a period of five years. It will support tech startups addressing societal challenges in seven selected thematic areas identified based on national priorities in the realms of.</p>
          </div>
          <div class="col-12 col-md-11 aboutmeity " *ngIf="scheme_id == 2">
            <h2 class="text-center my-3">About the SAMRIDH</h2>
              <p class="p-3">The Government of India has various programs to promote incubation services to startups. A need is urgently felt to initiate an accelerator program to assist these start-ups with Social Impact and to solve India's problems at scale.</p>
              <p class="p-3">The SAMRIDH scheme is thus conceptualized and being implemented to provide support to selected accelerators for extending acceleration services to start-ups (focused on Tier-II and III based) along with one-to-one matching funding support of up to ₹40 lakh. The scheme will support around 300 tech Start-Ups in 3-year duration with cohort size of 5-10 Start-Ups. The Startups generated from other programs will act as a feeder for the SAMRIDH program.</p>
              <p class="p-3">Accelerators spread across 14 states and 12 cities are supporting 175 startups, selected through a multilevel screening process. The cohort size of each accelerator is 5-10 start-ups each. List of accelerators compromises of Government Supported Organizations, Academic Institutions, Private sector and early stage start-up funding platforms.</p>
              <!-- <p class="p-3">Technology Incubation and Development of Entrepreneurs (TIDE 2.0) Scheme has been envisaged to promote tech entrepreneurship through financial and technical support to incubators engaged in supporting ICT startups using emerging technologies such as IoT, AI, Block-chain, Robotics etc. in seven pre-identified areas of societal relevance. The Scheme will be implemented through 51 incubators and handholding of approximately 2000 tech start-ups over a period of five years. It will support tech startups addressing societal challenges in seven selected thematic areas identified based on national priorities in the realms of.</p> -->
          </div>
          <div class="col-12 col-md-11 aboutmeity " *ngIf="scheme_id == 3">
            <h2 class="text-center my-3">About Other MeitY Schemes</h2>
              <p class="p-3">Other MeitY schemes will promote tech entrepreneurship through financial and technical support to incubators engaged in supporting ICT startups primarily engaged in using emerging technologies such as IoT, AI, Block-chain, Robotics etc. in pre-identified areas of societal relevance.</p>
              <!-- <p class="p-3">The Scheme will be implemented through empowering 51 incubators in India and handholding close to 2000 tech start-ups over a period of 5 years. The scheme envisions creating a holistic ecosystem to support technology startups and incubation centers through identifying and creating necessary linkages.</p>
              <p class="p-3">MeitY Startup Hub (MSH) would be setup under TIDE 2.0 to ensure synergistic linkages among the TIDE centres, theme based incubation centres, Centre of Excellences on emerging technologies and other collaborative platforms. The MSH will also ensure crisscrossing of technology resources across the innovation ecosystem to come out with technology solutions to various societal challenges.</p>
              <p class="p-3">Technology Incubation and Development of Entrepreneurs (TIDE 2.0) Scheme has been envisaged to promote tech entrepreneurship through financial and technical support to incubators engaged in supporting ICT startups using emerging technologies such as IoT, AI, Block-chain, Robotics etc. in seven pre-identified areas of societal relevance. The Scheme will be implemented through 51 incubators and handholding of approximately 2000 tech start-ups over a period of five years. It will support tech startups addressing societal challenges in seven selected thematic areas identified based on national priorities in the realms of.</p> -->
          </div>
          <div class="col-12 col-md-11 aboutmeity " *ngIf="scheme_id == 4">
            <h2 class="text-center my-3">About GENESIS</h2>
            <p>genesis</p>
              </div>
              <div class="col-12 col-md-11 aboutmeity " *ngIf="scheme_id == 5">
                <h2 class="text-center my-3">About NON MEITY SCHEME</h2>
                <p>non meity scheme</p>
                  </div>
                  <div class="col-12 col-md-11 aboutmeity " *ngIf="scheme_id == 6">
                    <h2 class="text-center my-3">About NGIS</h2>
                    <p>ngis</p>
                      </div>
        
      </div>
  
  </div>
  
  <div class=" container ">
    <div class="row p-3">
    <!-- <div class="col-12 col-md-8 p-4 ">
      <h2 class="text-center ">Notifications</h2>
      <ul class="list-group" >
        <li class="list-group-item" *ngFor="let item of notifications" >
<div class="d-flex justify-content-between">
  <div class="d-flex">
    <b>{{item.subject}}</b>:<a *ngIf= "item.link" href={{item.link}} target="_blank">Click here</a>
    <p *ngIf="item.text"> {{item.text}}</p>
  </div>
<div>
  <i class="bi bi-info-circle text-primary cursor-pointer" [title]="item.description" aria-hidden="true">View Description</i>

</div>
</div>
        </li>
      
 </ul>
    </div> -->
    <div class="col-12 col-md-8" *ngIf="scheme_id != 2">
      <h2 class="text-center ">Notifications</h2>
      <ul class="list-group row px-3" *ngIf="notifications?.length > 0">
        <div class="inView">
          <li *ngFor="let notif of notifications; let i = index" class="list-group-item col-12 d-flex justify-content-between" >
            <div class="col-9">
              <b class="text-break">{{notif.subject + ": " + '&nbsp;'}}</b>
              <p class="text-break" *ngIf="notif.text">{{ " " + notif.text }}</p>
              <p  class="text-break" *ngIf="notif.link"><a [href]="notif.link" target="_blank" [ngClass]="{ 'disabled': i != 0}" >{{ " " + notif.link }}</a></p>
            </div> 
            <div class="col-3 d-flex justify-content-between">
              <small>Created at: {{notif.created_at.substring(0, 10) }}</small>
              <i class="fa fa-info-circle text-primary" title="Click to view description!" data-bs-toggle="modal" data-bs-target="#notifDescModal" (click)="storeData(notif)"></i>
            </div>        
          </li>
        </div>        
      </ul>
      <ul class="list-group" *ngIf="notifications?.length <= 0">
        <li class="list-group-item" >
          <p>No notification yet!</p> 
        </li>
      </ul>
    </div>
    <!-- <div class="col-12 col-md-4 p-4 ">
      <h2 class="text-center">Uploads</h2>
       <div class="card uploadcard my-2 px-2" *ngFor="let item of uploads">
        
        <a href="{{item.file.url}}" target="_blank" >{{item.title}}</a>
       </div>
       
    </div> -->
    <div class="col-12 col-md-4" *ngIf="scheme_id !=2">
      <h2 class="text-center">Uploads</h2>
      <ul class="list-group row px-3" *ngIf="uploads?.length > 0">
        <div class="inView">
          <li class="list-group-item" *ngFor="let upload of uploads; let i = index">
            <a [href]="upload.file.url ? upload.file.url : upload.file" target="_blank">{{ upload.title }}</a>
          </li>
        </div>
      </ul>
      <ul class="list-group" *ngIf="uploads?.length <= 0">
        <li class="list-group-item">
          <p>No uploads yet!</p>
        </li>
      </ul>
    </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  
  <!-- Notification Description Modal -->
<div class="modal fade" id="notifDescModal" tabindex="-1" aria-labelledby="notifDescModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="notifDescModalLabel">Notification</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <i class="fa fa-times arrow px-2"></i>
        </button>
      </div>
      <div class="modal-body">
        <b>Subject:</b>
        <p class="text-break">{{ this.selectNotif?.subject }}</p>
        <b *ngIf="this.selectNotif?.text">Notification Text:</b>
        <p class="text-break" *ngIf="this.selectNotif?.text">{{ this.selectNotif?.text }}</p>
        <b *ngIf="this.selectNotif?.link">Notification Link:</b>
        <br *ngIf="this.selectNotif?.link">
        <a [href]="this.selectNotif?.link" target="_blank" class="text-underline text-primary">{{ this.selectNotif?.link }}</a>
        <br *ngIf="this.selectNotif?.link"><br *ngIf="this.selectNotif?.link">
        <b>Description:</b>
        <p class="text-break">{{ this.selectNotif?.description }}</p>
        <b>Created at:</b>
        <p>{{ this.selectNotif?.created_at.substring(0, 10) }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>