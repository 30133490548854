import { Component, OnInit } from '@angular/core';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx'; // Import xlsx library

@Component({
  selector: 'ngx-samridh-fund-release',
  templateUrl: './samridh-fund-release.component.html',
  styleUrls: ['./samridh-fund-release.component.scss']
})
export class SamridhFundReleaseComponent implements OnInit {
  entity: any;
  startdate: any;
  enddate: any;
  scheme_id: any;
  newdataaa:any;
  fundreleaseData:any;
  p:number=1;
pageSize:number=10;

  constructor(private grantManagementService: GrantManagementService, private _route: ActivatedRoute, private router:Router) {
    this._route.paramMap.subscribe((params: any) => {
      this.scheme_id = params.params.id;
      this.grantManagementService.scheme_id = this.scheme_id
      console.log('schemeid', this.scheme_id)
    })
  }

  ngOnInit(): void {
    
    this.grantManagementService.getEntityType().subscribe((res: any) => {
      this.entity = res;
     this.getfundRelease()
    })
    this.grantManagementService.getStartDate().subscribe((res:any)=>{
      this.startdate = res;     
  })
  
  this.grantManagementService.getEndDate().subscribe((res: any) => {
      this.enddate = res;
      this.getfundRelease()
  });
  this._route.queryParams.subscribe((params) => {
    this.p = +params['page'] || 1;
    this.loadData(this.p);
  
  })
  }
  getfundRelease() {
    this.grantManagementService.getfundreleasedData(this.entity, this.startdate, this.enddate).subscribe((res: any) => {
      console.log("fundreleaseData", res.results);
      this.fundreleaseData = res.results
    })
  }

  totalFund(adminfee:any,grant_amount:any){
    var total = (+adminfee) + (+grant_amount);
    return total
  }
  newdata(item:any){
    console.log('item',item);
    this.newdataaa =item
  }
//calling data
// samridthstartendentityDate(){
// // Combine all observables using forkJoin
// forkJoin([
//   this.grantManagementService.getEntityType(),
//   this.grantManagementService.getStartDate(),
//   this.grantManagementService.getEndDate()
// ]).subscribe((results: [any, any, any]) => {
//   const [entity, startdate, enddate] = results;
//     this.entity = entity;
//   this.startdate = startdate;
//   this.enddate = enddate;
//   this.getfundRelease();
// });

// }


//download 
// downloadTableData(): void {
//   const csvData = this.convertToCSV(this.fundreleaseData);
//   const blob = new Blob([csvData], { type: 'text/csv' });
//   const url = window.URL.createObjectURL(blob);
//   const a = document.createElement('a');
//   a.setAttribute('hidden', '');
//   a.setAttribute('href', url);
//   a.setAttribute('download', 'fundreleaseData.csv');
//   document.body.appendChild(a);
//   a.click();
//   document.body.removeChild(a);
// }

// convertToCSV(data: any[]): string {
//   const header = ['Name of the Accelerator', 'Startup Name', 'Total Fund Released', 'Admin Fee released(Cumulative)', 'Startup Fund Released(Cumulative)'];
//   const rows = data.map(item => [
//     item.acceleratorfund.accelerator.name,
//     item.startup.name,
//     this.totalFund(item.acceleratorfund.admin_fee, item.grant_amount).toString(),
//     item.acceleratorfund.admin_fee.toString(),
//     item.grant_amount.toString()
//   ]);

//   let csvContent = header.join(',') + '\n';
//   rows.forEach(row => {
//     csvContent += row.join(',') + '\n';
//   });

//   return csvContent;
// }


downloadTableData(): void {
    const wb = XLSX.utils.book_new();

    // Convert data to worksheet
    const ws = XLSX.utils.aoa_to_sheet(this.convertToExcelData(this.fundreleaseData));
    XLSX.utils.book_append_sheet(wb, ws, 'Fund Release Data');

    // Generate Excel file and trigger download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'fundreleaseData.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

convertToExcelData(data: any[]): any[][] {
    const header = ['Name of the Accelerator', 'Startup Name', 'Total Fund Released', 'Admin Fee released(Cumulative)', 'Startup Fund Released(Cumulative)'];
    const rows = data.map(item => [
        item.acceleratorfund.accelerator.name,
        item.startup.name,
        this.totalFund(item.acceleratorfund.admin_fee, item.grant_amount).toString(),
        item.acceleratorfund.admin_fee.toString(),
        item.grant_amount.toString()
    ]);

    return [header, ...rows];
}

//pagination
onPageChange(page:number):void{
  this.p =page,
  this.router.navigate([],{
    relativeTo:this._route,
    queryParams:{page:page},
    queryParamsHandling:'merge'
  })
  this.loadData(page)
}
private loadData(page:number):void{
  console.log(page)
}
}