import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" id="sidebarmenu" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column id="contentlayout">
      <!-- <ngx-financialyeardropdown></ngx-financialyeardropdown> -->
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit , AfterViewInit{
  constructor(){

  }

  ngOnInit(){
    
  }

  ngAfterViewInit(){
    let elem = document.getElementById('sidebarmenu');
    console.log('sidebar_elem',elem);
    console.log('children',document.querySelector('#sidebarmenu > .main-container > .scrollable'));
    document.querySelector('#sidebarmenu > .main-container > .scrollable').setAttribute('class','p-0');
  }
}
