<div class="col-12 col-sm-12">
 
    <nb-card>
      <nb-card-header>Roles</nb-card-header>
      <nb-card-body class="container-fluid">

        <form class="ng-pristine ng-invalid" [formGroup]="rolesForm" (ngSubmit)="submitForm()">
            
          <div class="col-md-6 m-auto">
          <div class="form-control-group ">
                <!-- <label for="input-email" class="label">Name:</label> -->
                    <!-- <input type="text" nbInput fullWidth status="basic" name="name" id="input-name" placeholder="Role Name"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }" autofocus="" formControlName="name"
                        class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition space"> -->
                        <select nbInput fullWidth status="basic" name="name" id="input-name" placeholder="Role Name"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" autofocus="" formControlName="name"
                            class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition space">
                            <option value="">Select Role</option>
                            <option value="Project Manager">Project Manager</option>
                            <option value="Social Manager">Social Manager</option>
                            <option value="Finance Manager">Finance Manager</option>
                            <option value="Meity Officials">Meity Officials</option>
                            <!-- <option value="Test Role">Test Role</option>
                            <option value="Test Role1">Test Role1</option> -->
                        </select>
                        <div *ngIf="submitted && f.name.errors" class="text-danger">
                            <div *ngIf="f.name.errors['required']" class="custom_font">Role is required</div>
                          </div> 
    
            </div>  
                <div class="form-control-group ">
                <!-- <label for="input-email" class="label">Email:</label> -->
                <input nbinput="" fullwidth="" name="roleCode" id="input-code" placeholder="Role Code"
                    fieldsize="large" [ngClass]="{ 'is-invalid': submitted && f.roleCode.errors }" autofocus="" formControlName="roleCode"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition space">
                    <div *ngIf="submitted && f.roleCode.errors" class="text-danger">
                        <div *ngIf="f.roleCode.errors['required']" class="custom_font">Code is required</div>
                      </div> 
    
            </div>  
            <div class="form-control-group ">
                <!-- <label for="input-email" class="label">Mobile Number:</label> -->
                <input nbinput="" fullwidth="" name="description" id="input-permission" placeholder="Role Description"
                    fieldsize="large" [ngClass]="{ 'is-invalid': submitted && f.description.errors }" autofocus="" formControlName="description"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition space">
                    <div *ngIf="submitted && f.description.errors" class="text-danger">
                        <div *ngIf="f.description.errors['required']" class="custom_font">Description is required</div>
                      </div> 
    
            </div>
          </div>



            <div class="mt-3">
                <h3 class="font-weight-bold ml-2">Permissions</h3>
                <table class="table">
                    <thead>
                        <th>#</th>
                        <th>Id</th>
                        <th>Module</th>
                        <th>Create</th>
                        <th>View</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </thead>
                    <tbody>
                        <tr *ngFor = "let p of permission_list">
                          <td><input type="checkbox" [checked]="p.view && p.create && p.edit && p.delete" name="" (change)="get_permissionid(p.permissionid,$event)" id=""></td>
                         <td>{{p.permissionid}}</td>
                         <td>{{p.name | titlecase}}</td>
                         <td><input type="checkbox" name="" (click)="editMode?permissioncheck_edit('create',p.permissionid,$event):permissioncheck('create',p.permissionid,$event)" [checked]="p.create" id="{{p.permissionid}}"></td>
                         <td><input type="checkbox" name="" (click)="editMode?permissioncheck_edit('view',p.permissionid,$event):permissioncheck('view',p.permissionid,$event)" [checked]="p.view" id=""></td>
                         <td><input type="checkbox" name="" (click)="editMode?permissioncheck_edit('edit',p.permissionid,$event):permissioncheck('edit',p.permissionid,$event)" [checked]="p.edit" id=""></td>
                         <td><input type="checkbox" name="" (click)="editMode?permissioncheck_edit('delete',p.permissionid,$event):permissioncheck('delete',p.permissionid,$event)" [checked]="p.delete" id=""></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <div class="col-3"> -->
            <div class="float-right">
            <button nbButton status="primary"   
                size="small"
                class="appearance-filled size-small shape-rectangle status-primary m100">{{editMode? 'Update':'Save'}}</button>
            <!-- </div> -->
                <!-- <div class="col-3"> -->
                <button nbButton (click)="cancelData()" [disabled]="!rolesForm.valid" status="primary"
                size="small"
                class="appearance-filled size-small shape-rectangle status-primary m100">Cancel</button>
            </div>           
            <!-- </div> -->
            
            </form>

        </nb-card-body>
    </nb-card>

    
    <nb-card>
        <!-- <ngx-add-roles  ></ngx-add-roles> -->
        <nb-card-header>Role Details</nb-card-header>
        <nb-card-body>  
        <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Role Code</th>
                        <th>Role Descriptions</th>
                        <!-- <th>Permissions</th> -->
                        <th>Actions</th>

                    </tr> 
                </thead>
                <tbody>
                    <tr *ngFor="let data of tableData; index as i">
                        <td>{{data.name | titlecase}}</td>
                        <td>{{data.role_code | titlecase}}</td>
                        <td>{{data.description | titlecase}}</td>
                        <!-- <td>
                            <div class="dropdown">
                                <button class="btn btn-info dropdown-toggle" (click)="getPermissionByRoleId(data.role_id)"type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Permissions
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a class="dropdown-item" *ngFor="let p of permissions">{{p}}</a>
                                </div>
                              </div>
                        </td> -->
                        <td>
                            <span class="fa fa-edit" style = "color:blue" (click)="editData(data, i)" ></span>
                            <!-- <span class="fa fa-trash" style = "color:red" (click)="removeData(data.id)" ></span> -->
                            <!-- <span class="fa fa-plus text-info" data-toggle="modal" data-target="#staticBackdrop"></span> -->
                        </td>                     
                    </tr>
                    
                </tbody>
            </table>
      </nb-card-body>
    </nb-card>
</div>

<!-- <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Understood</button>
        </div>
      </div>
    </div>
  </div> -->
  