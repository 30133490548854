import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import * as XLSX from 'xlsx';

@Component({
  selector: 'ngx-newstartuptable',
  templateUrl: './newstartuptable.component.html',
  styleUrls: ['./newstartuptable.component.scss']
})
export class NewstartuptableComponent implements OnInit {
  newincubatorData: any;
  approveMsg='';
  // page: number = 1;
  entity: any;
  p: number = 1;
  scheme_id: any;
  missingDocs:any;
  startdate: any;
  enddate: any;
  pageSize: number = 5;
  modalData: any;
  ciDocument: any;
  dpiitDocument: any;
  aaDocument: any;
  aiaDocument: any;
  pdDocument: any;
  iaDocument: any;
  fpDocument: any;
  ddrDocument: any;
  rejectMsg='';
  ctDocument: any;
  vrDocument: any;
  baseUrl = environment.apiurl;
  constructor(private http: HttpClient, private grantManagementService: GrantManagementService, protected Toaster: NbToastrService,
    private _route: ActivatedRoute,
    private router: Router,) {
    this._route.paramMap.subscribe((params: any) => {
      this.scheme_id = params.params.id;
      this.grantManagementService.scheme_id = this.scheme_id
    })
  }

  ngOnInit(): void {
    // this.getModalData();
    this.grantManagementService.getEntityType().subscribe((res: any) => {
      this.entity = res;
      console.log('entity------', this.entity);
      this.getteamIncubator();
      // if(this.scheme_id ==2){
      //   this.getModalData()
      // }
    })

    this.grantManagementService.getStartDate().subscribe((res: any) => {
      this.startdate = res;
      console.log('startdate------', this.startdate)
      console.log('here1');
      //this.getFunds();

    })

    this.grantManagementService.getEndDate().subscribe((res: any) => {
      this.enddate = res;
      console.log('end------', this.enddate);
      console.log('here2');
      this.getteamIncubator()
      // if(this.scheme_id ==2){
      //   this.getModalData()
      // }
    });
    this._route.queryParams.subscribe((params) => {
      // Use the 'page' parameter from the URL or default to 1
      this.p = +params['page'] || 1;
      // Load data for the current page
      this.loadData(this.p);
    })
  }
  getteamIncubator() {
    this.grantManagementService.getIncubator(this.entity, this.startdate, this.enddate).subscribe((res: any) => {
      console.log("res ==========> ", res);
      this.newincubatorData = res.results;
      console.log('incu', this.newincubatorData)
      this.newincubatorData.sort((a, b) => {
        if (a.agency_name < b.agency_name) {
          return -1;
        }
        if (a.agency_name > b.agency_name) {
          return 1;
        }
        return 0;
      });
      this.onPageChange(1)

    })
  }
  // downloadCSV() {
  //   // Flatten the data to include nested fields
  //   const flatData = [];
  //   this.newincubatorData.forEach((item, index) => {
  //     // Assuming each item has three team members
  //     const teamMember1 = item.team_info.teamInfo[0] || {}; // First team member
  //     const teamMember2 = item.team_info.teamInfo[1] || {}; // Second team member
  //     const teamMember3 = item.team_info.teamInfo[2] || {}; // Third team member


  //     flatData.push({
  //       'Incubator Name': item?.incubator_name,
  //       'State': item.contact_info?.state,
  //       'Incubator Type': item.group ? item.group : '',
  //       'Startup-Name': item.name,
  //       // Call function to combine domains into one string

  //       'Contact Person 1': `${teamMember1.team_meb_name || "-"} (${teamMember1.team_meb_designation || "-"}) - ${teamMember1.team_meb_email || "-"} - ${teamMember1.team_meb_mob || "-"}`,
  //       'Contact Person 2': `${teamMember2.team_meb_name || "-"} (${teamMember2.team_meb_designation || "-"}) - ${teamMember2.team_meb_email || "-"} - ${teamMember2.team_meb_mob || "-"}`,
  //       'Contact Person 3': `${teamMember3.team_meb_name || "-"} (${teamMember3.team_meb_designation || "-"}) - ${teamMember3.team_meb_email || "-"} - ${teamMember3.team_meb_mob || "-"}`,
  //       'Startup Detailed Doc': item?.document?.url || 'not provided',
  //       // 'Contact person': `${teamMember1.team_meb_name || "-"} (${teamMember1.team_meb_designation || "-"}) - ${teamMember1.team_meb_email || "-"} - ${teamMember1.team_meb_mob || "-"}`,

  //       'Field/Domain': this.combineDomains(item.domain),
  //       // You can add similar fields for Contact Person-4, 5, etc. if needed
  //     });
  //   });


  //   // Convert flat data to CSV format
  //   const csv = this.convertToCSV(flatData);

  //   // Create a Blob and trigger download
  //   const blob = new Blob([csv], { type: 'text/csv' });
  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = 'table_data.csv';
  //   link.click();
  // }
  // private convertToCSV(data: any[]): string {
  //   // Convert data to CSV format
  //   const header = Object.keys(data[0]).join(',');
  //   const rows = data.map(obj => Object.values(obj).join(',')).join('\n');
  //   return header + '\n' + rows;
  // }
  // combineDomains(domains: string[] | string): string {
  //   if (Array.isArray(domains)) {
  //     return domains.join(', ');
  //   } else {
  //     return domains || '-';
  //   }
  // }

  // downloadCSV() {
  //   // Flatten the data to include nested fields
  //   const flatData = [];
  //   this.newincubatorData.forEach((item, index) => {
  //     // Assuming each item has three team members
  //     const teamMember1 = item.team_info.teamInfo[0] || {}; // First team member
  //     const teamMember2 = item.team_info.teamInfo[1] || {}; // Second team member
  //     const teamMember3 = item.team_info.teamInfo[2] || {}; // Third team member
  
  //     flatData.push({
  //       'Agency Name': item?.agency_name,
  //       'State': item.contact_info?.state,
  //       // 'Incubator Type': item.group ? item.group : '',
  //       'Startup-Name': item.name,
  //       'Contact Person 1': `${teamMember1.team_meb_name || "-"} (${teamMember1.team_meb_designation || "-"}) - ${teamMember1.team_meb_email || "-"} - ${teamMember1.team_meb_mob || "-"}`,
  //       'Contact Person 2': `${teamMember2.team_meb_name || "-"} (${teamMember2.team_meb_designation || "-"}) - ${teamMember2.team_meb_email || "-"} - ${teamMember2.team_meb_mob || "-"}`,
  //       'Contact Person 3': `${teamMember3.team_meb_name || "-"} (${teamMember3.team_meb_designation || "-"}) - ${teamMember3.team_meb_email || "-"} - ${teamMember3.team_meb_mob || "-"}`,
  //       'Startup Detailed Doc': item?.document?.url || 'not provided',
  //       'Field/Domain': this.combineDomains(item.domain),
  //     }
      
  //   );
  //   });
  
  //   // Add an extra row with "details status" if scheme_id is 2
  //   if (this.scheme_id === 2) {
  //     flatData.push({
  //       'Agency Name': '',
  //       'State': '',
  //       'Startup-Name': '',
  //       'Contact Person 1': '',
  //       'Contact Person 2': '',
  //       'Contact Person 3': '',
  //       'Field/Domain': '',
  //       'Status':''
  //     });
  //   }
  
  //   // Convert flat data to CSV format
  //   const csv = this.convertToCSV(flatData);
  
  //   // Create a Blob and trigger download
  //   const blob = new Blob([csv], { type: 'text/csv' });
  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = 'table_data.csv';
  //   link.click();
  // }
  
  // private convertToCSV(data: any[]): string {
  //   if (data.length === 0) {
  //     return '';
  //   }
  //   // Convert data to CSV format
  //   const header = Object.keys(data[0]).join(',');
  //   const rows = data.map(obj => Object.values(obj).join(',')).join('\n');
  //   return header + '\n' + rows;
  // }
  
  // combineDomains(domains: string[] | string): string {
  //   if (Array.isArray(domains)) {
  //     return domains.join(', ');
  //   } else {
  //     return domains || '-';
  //   }
  // }
  // downloadCSV() {
  //   // Flatten the data to include nested fields
  //   const flatData = [];
  //   this.newincubatorData.forEach((item, index) => {
  //     // Assuming each item has three team members
  //     const teamMember1 = item.team_info.teamInfo[0] || {}; // First team member
  //     const teamMember2 = item.team_info.teamInfo[1] || {}; // Second team member
  //     const teamMember3 = item.team_info.teamInfo[2] || {}; // Third team member
  
  //     const flatItem = {
  //       'Agency Name': item?.agency_name,
  //       'State': item.contact_info?.state,
  //       // 'Incubator Type': item.group ? item.group : '',
  //       'Startup-Name': item.name,
  //       'Contact Person 1': `${teamMember1.team_meb_name || "-"} (${teamMember1.team_meb_designation || "-"}) - ${teamMember1.team_meb_email || "-"} - ${teamMember1.team_meb_mob || "-"}`,
  //       'Contact Person 2': `${teamMember2.team_meb_name || "-"} (${teamMember2.team_meb_designation || "-"}) - ${teamMember2.team_meb_email || "-"} - ${teamMember2.team_meb_mob || "-"}`,
  //       'Contact Person 3': `${teamMember3.team_meb_name || "-"} (${teamMember3.team_meb_designation || "-"}) - ${teamMember3.team_meb_email || "-"} - ${teamMember3.team_meb_mob || "-"}`,
  //       'Field/Domain': this.combineDomains(item.domain),
  //     };
  
  //     // Add Startup Detailed Doc if scheme_id is not 2
  //     if (this.scheme_id != 2) {
  //       flatItem['Startup Detailed Doc'] = item?.document?.url || 'not provided';
  //     }
  
  //     // Add grant_status if scheme_id is 2
  //     if (this.scheme_id == 2) {
  //       flatItem['Status'] = item.grant_status || '-';
  //     }
  
  //     flatData.push(flatItem);
  //   });
  
  //   // Convert flat data to CSV format
  //   const csv = this.convertToCSV(flatData);
  
  //   // Create a Blob and trigger download
  //   const blob = new Blob([csv], { type: 'text/csv' });
  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = 'table_data.csv';
  //   link.click();
  // }
  
  
  // private convertToCSV(data: any[]): string {
  //   if (data.length === 0) {
  //     return '';
  //   }
  //   // Convert data to CSV format
  //   const header = Object.keys(data[0]).join(',');
  //   const rows = data.map(obj => Object.values(obj).join(',')).join('\n');
  //   return header + '\n' + rows;
  // }
  
  combineDomains(domains: string[] | string): string {
    if (Array.isArray(domains)) {
      return domains.join(', ');
    } else {
      return domains || '-';
    }
  }
  downloadCSV() {
    // Flatten the data to include nested fields
    const flatData = [];
    this.newincubatorData.forEach((item, index) => {
        // Assuming each item has three team members
        const teamMember1 = item.team_info.teamInfo[0] || {}; // First team member
        const teamMember2 = item.team_info.teamInfo[1] || {}; // Second team member
        const teamMember3 = item.team_info.teamInfo[2] || {}; // Third team member

        const flatItem = {
            'Agency Name': item?.agency_name,
            'State': item.contact_info?.state,
            // 'Incubator Type': item.group ? item.group : '',
            'Startup-Name': item.name,
            'Contact Person 1': `${teamMember1.team_meb_name || "-"} (${teamMember1.team_meb_designation || "-"}) - ${teamMember1.team_meb_email || "-"} - ${teamMember1.team_meb_mob || "-"}`,
            'Contact Person 2': `${teamMember2.team_meb_name || "-"} (${teamMember2.team_meb_designation || "-"}) - ${teamMember2.team_meb_email || "-"} - ${teamMember2.team_meb_mob || "-"}`,
            'Contact Person 3': `${teamMember3.team_meb_name || "-"} (${teamMember3.team_meb_designation || "-"}) - ${teamMember3.team_meb_email || "-"} - ${teamMember3.team_meb_mob || "-"}`,
            'Field/Domain': this.combineDomains(item.domain),
        };

        // Add Startup Detailed Doc if scheme_id is not 2
        if (this.scheme_id != 2) {
            flatItem['Startup Detailed Doc'] = item?.document?.url || 'not provided';
        }

        // Add grant_status if scheme_id is 2
        if (this.scheme_id == 2) {
            flatItem['Status'] = item.grant_status || '-';
        }

        flatData.push(flatItem);
    });

    // Use xlsx library to convert flatData to an Excel file
    const ws = XLSX.utils.json_to_sheet(flatData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Create a Blob and trigger download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'table_data.xlsx';
    link.click();
}

  
  onPageChange(page: number): void {
    this.p = page;
    this.router.navigate([], {
      relativeTo: this._route,
      queryParams: { page: page },
      queryParamsHandling: 'merge',
    })
    this.loadData(page);

  }
  private loadData(page: number): void {
    // Implement your logic to load data based on the page
    console.log('Loading data for page', page);
  }

  newData(item: any) {
    console.log('item', item)
    this.modalData = item;
    console.log('modalData', this.modalData);
    this.ciDocument = this.modalData.grant_documents?.find(doc => doc.doc_type === 'CI');
    this.dpiitDocument = this.modalData.grant_documents.find(doc => doc.doc_type === 'DPIIT');
    this.aaDocument = this.modalData.grant_documents.find(doc => doc.doc_type === 'AA');
    this.aiaDocument = this.modalData.grant_documents.find(doc => doc.doc_type === 'AIA');
    this.iaDocument = this.modalData.grant_documents.find(doc => doc.doc_type === 'IA');
    this.pdDocument = this.modalData.grant_documents.find(doc => doc.doc_type === 'P');
    this.ctDocument = this.modalData.grant_documents.find(doc => doc.doc_type === 'C');
    this.fpDocument = this.modalData.grant_documents.find(doc => doc.doc_type === 'FP');
    this.ddrDocument = this.modalData.grant_documents.find(doc => doc.doc_type === 'DDR');
    this.vrDocument = this.modalData.grant_documents.find(doc => doc.doc_type === 'VR');

  }
  //For samridh
  approveTermFund(item:any) {
    const id = item.id;
    console.log('hhhhh',item.id)
    const agency_id = item.agency_id


    if (item.grant_data !== null && item.grant_documents?.length == 10) {
      this.grantManagementService.approvesamridhFund(id, agency_id).subscribe((res: any) => {
        console.log('data', res);
        this.getteamIncubator();
        this.approveMsg ="Succesfully Approved";
        setTimeout(()=>{
          this.approveMsg =""
        },2000)
      })
    }
    else if(item.grant_data == null){
      this.rejectMsg = 'Approval failed: Grant data is missing';
      setTimeout(()=>{
        this.rejectMsg =""
      },2000)
      console.log('approve')
    
    }
    else if(item.grant_documents.length !=10){

      this.rejectMsg ='Grant documents are missing ,please check';
      setTimeout(()=>{
        this.rejectMsg=''
      },2000)
      console.log('docs')
  }
    else{
      this.rejectMsg = 'Approval failed: Required data is missing'
      setTimeout(()=>{
        this.rejectMsg =""
      },2000)
      console.log('all')

    }
    }

   
    
  

rejectTermFund(item:any) {
  const id =item.id;
  const agency_id =item.agency_id;
  this.grantManagementService.rejectsamridhTermFund(id,agency_id).subscribe((res:any) => {
      console.log("res ========> ", res); 
      this.rejectMsg= "You have rejected this request";
      setTimeout(()=>{
        this.rejectMsg =''
      })
     this.getteamIncubator()

})
}
}
