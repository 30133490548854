import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { resolve } from 'dns';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'ngx-samridh-release-order',
  templateUrl: './samridh-release-order.component.html',
  styleUrls: ['./samridh-release-order.component.scss']
})
export class SamridhReleaseOrderComponent implements OnInit {
  fileInputLabel1:any;
  releasedForm: FormGroup;
  isreleasedorderSubmited:boolean=false;
  filetyperrr: boolean =false;
  filesizerrr: boolean =false;
  uploadingDoc = false;
  editMode:boolean=false;
  new_id:any;
  p:number=1;
pageSize:number=10;

  editdocument:any;
  errorMsg='';
  approveMsg='';
  rejectMsg='';
  releseData:any;
  accelerator: any[] = [];


  

  constructor(private fb:FormBuilder,private  grantManagement :GrantManagementService, private router:Router, private _route:ActivatedRoute) { }

  ngOnInit(): void {
  this.releasedForm = this.fb.group({
    name:['',[Validators.required]],
    uploadDoc:['',[Validators.required]]
  })
  this.acceleratorList();
  this.getreleasefundData();

  this._route.queryParams.subscribe((params) => {
    this.p = +params['page'] || 1;
    this.loadData(this.p);
  
  })
  }
  //get controls for validations
  get _name(){
    return this.releasedForm?.get('name')
  }
  get _uploadDoc(){
    return this.releasedForm?.get('uploadDoc')
  }
  //for file upload
  onFileSelectdoc(event:any){
    this.uploadingDoc = true;
      let allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      const file = event.target.files[0];
      const fileSize = file.size;
      const fileSizeMB = fileSize / (1024 ** 2);
      console.log('filesize in mb', fileSizeMB);
      console.log('file', file);
  
      if (!allowedTypes.includes(file.type)) {
          this.filetyperrr = true; // File type not allowed
      } else {
          this.filetyperrr = false; // File type allowed
      }
  
      if (fileSizeMB > 2) {
          this.filesizerrr = true; // File size exceeds 2MB
      } else {
          this.filesizerrr = false; // File size within limit
      }
  
      this.fileInputLabel1 = file ? file.name : 'Choose File';
      this.releasedForm.get("uploadDoc").setValue(file);
  }

  //on submit one
  async onSubmit(){
  
   this.isreleasedorderSubmited =true;
   var profile_doc = await this.UploadDoc()
   console.log('profile',profile_doc)
   this.new_id = this.releasedForm.get('name').value
   if(this.releasedForm.valid && !this.filetyperrr && !this.filesizerrr){
    console.log('form submitted');
    var postData = {
      release_order:profile_doc
    }

      this.grantManagement.putreleaseOrder(postData,this.new_id).subscribe(()=>{

        const targetDiv = document.getElementById('eventForm');
    if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start'});
        setTimeout(() => {
          window.scrollBy(0, -150);        
        }, 750);
      }
        console.log('data',postData);
        if(this.editMode){
          this.approveMsg = 'Form Updated Successfully'
          setTimeout(()=>{
            this.approveMsg =''
          },2000)
        }
        else{
          this.approveMsg = 'Successfully Submited the form';
          setTimeout(()=>{
            this.approveMsg =''
          },2000)
        }
       
        this.getreleasefundData();
      
        this.cancelEdit()
      },
      (error)=>{
        this.errorMsg ='Something Went Wrong'
        setTimeout(()=>{
          this.errorMsg ='';
        },2000)
    })
    this.releasedForm.reset();
    this,this.isreleasedorderSubmited=false
   }
   else{
    console.log('form not submitted')
    this.rejectMsg = 'Error :The form is not submitted',
    setTimeout(()=>{
      this.rejectMsg =''
    },2000)
   }
  }

  //post data
 
  //acceelarot list
  acceleratorList(){
    this.grantManagement.acclistData().subscribe((res:any)=>{
      console.log('res22',res.results);
      this.accelerator = res.results
    })
  }

  //upload doc
  // async UploadDoc(){
  //   console.log('uploading Doc',this.uploadingDoc)
  //   if(this.uploadingDoc){
  //     // this.uploadingDoc = false;
  //     return new Promise((resolve,reject)=>{
  //       const formData = new FormData();
  //       formData.append("file", this.releasedForm.get("uploadDoc").value);
  //       this.grantManagement.uploadFile(formData).subscribe((response:any) => {
  //           console.log("image response", response.results[0]);
  //           resolve(response.results[0]);
         
  
  //         })
  //     })
  //   }
  //   else{
  //     console.log('inside else of upload', this.editdocument.release_order.url)
  //     return new Promise((resolve,reject)=>{
  //   this.releasedForm.get("uploadDoc").setValue(this.editdocument.release_order.url);
  //   resolve( this.releasedForm.get("uploadDoc").setValue(this.editdocument.release_order.url));
   
       
  //   })
  // }
  //   }
  async UploadDoc() {
    console.log('uploading Doc', this.uploadingDoc);
    if (this.uploadingDoc) {
      // this.uploadingDoc = false;
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", this.releasedForm.get("uploadDoc").value);
        this.grantManagement.uploadFile(formData).subscribe((response: any) => {
          console.log("image response", response.results[0]);
          resolve(response.results[0]);
        }, (error) => {
          console.error('upload error', error);
          reject(error);
        });
      });
    } else {
      console.log('inside else of upload', this.editdocument.release_order.url);
      return new Promise((resolve, reject) => {
        const existingUrl = this.editdocument.release_order.url ? this.editdocument.release_order?.url : this.editdocument.release_order;
        this.releasedForm.get("uploadDoc").setValue(existingUrl);
        resolve(existingUrl); // Ensure the existing URL is resolved correctly
      });
    }
  }
  
  //get call for table data
  getreleasefundData(){
    this.grantManagement.getacclistData().subscribe((res:any)=>{
      console.log('samridh',res.results);
      this.releseData = res.results;
    })
  }
  async updatesuccessData(postData: any){
    await this.UploadDoc()
     this.grantManagement.putreleaseOrder(postData,this.new_id).subscribe(()=>{
        console.log('data',postData);
              this.editMode = false;
        // this.approveMsg = 'Form Updated Successfully'
        // setTimeout(()=>{
        //   this.approveMsg =''
        // },2000);
        this.getreleasefundData();

        this.releasedForm.reset();
        this.isreleasedorderSubmited=false;
        this.cancelEdit()
  
    },
  (error)=>{
      this.errorMsg ='Something Went Wrong'
      setTimeout(()=>{
        this.errorMsg ='';
      },2000)
  });
  }
  cancelEdit(){
    this.editMode =false;
    this.releasedForm.reset();
    this.fileInputLabel1 = '';
    this.uploadingDoc = false;
  }
  editData(item:any){
this.editMode=true;
this.editdocument = item;
console.log('ddd',item);
this.releasedForm.get('name').setValue([item.id]);

  this.fileInputLabel1 = item.release_order?.url ? item.release_order?.url.split('/').pop() : item.release_order.split('/').pop() // Set the file input label to the file name

}
  

  approveDocs(status:any,item:any){
    const id =item.id;
  
      const postData ={
        "release_order_status" : status,
    }
    this.grantManagement.putreleaseOrder(postData,id).subscribe((res:any)=>{
      const targetDiv = document.getElementById('eventForm');
      if (targetDiv) {
          targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start'});
          setTimeout(() => {
            window.scrollBy(0, -150);        
          }, 750);
        }
      if(status === 'APPROVED'){
  
        this.approveMsg = 'Release Order doc has been approved succesfully'
        setTimeout(() => {
          this.approveMsg = '';
        }, 2000);
      this.getreleasefundData();
    }
    else if(status ==='REJECTED'){
      
        this.rejectMsg = 'Release Order document has been rejected succesfully'
    
        setTimeout(() => {
          this.rejectMsg = '';
        }, 2000);
        this.getreleasefundData();

    }
    })
      

  }

  //pagination
  onPageChange(page:number):void{
    this.p =page,
    this.router.navigate([],{
      relativeTo:this._route,
      queryParams:{page:page},
      queryParamsHandling:'merge'
    })
    this.loadData(page)
  }
  private loadData(page:number):void{
    console.log(page)
  }
}
