<div class="container">
    <ngx-financialyeardropdown></ngx-financialyeardropdown>
  </div>
 <div class="container whitebg">
     <nav class="navbar navbar-expand-lg navbar-light justify-content-between">
            <div class="container-fluid">
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown ">
                            <a class="nav-link" href="#" [routerLink]="['/admin/grant-management',this.schemeId]">
                                Home
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" [routerLink]="['/admin/grant-management',this.schemeId,'startuptable']">
                                Startups
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" [routerLink]="['/admin/grant-management',this.schemeId,'team']">
                                Team {{schemeName}}
                            
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" [routerLink]="['/admin/grant-management',this.schemeId,'released']">
                                GIA Released
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" [routerLink]="['/admin/grant-management',this.schemeId,'Eir-grant']">
                                EIR Grant Release
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" [routerLink]="['/admin/grant-management',this.schemeId,'release-scaleup']">
                                Scale-Up Release
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" [routerLink]="['/admin/grant-management',this.schemeId,'notifications']">
                                Notifications
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" [routerLink]="['/admin/grant-management',this.schemeId,'uploads']">
                                Uploads
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown7" role="button" aria-expanded="false">
                                Financial data
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown7">
                                <li><a class="dropdown-item" [routerLink]="['/admin/grant-management', this.schemeId, 'state-fund']" >State wise Funds</a></li>
                                <li><a class="dropdown-item" [routerLink]="['/admin/grant-management', this.schemeId, 'statewise-agency']">State wise Agency</a></li>
                                <li><a class="dropdown-item" [routerLink]="['/admin/grant-management', this.schemeId, 'statewise-startup']">State wise Startup-list</a></li>
                                <li><a class="dropdown-item" [routerLink]="['/admin/grant-management', this.schemeId, 'quarter-fund']">Quarter wise Fund release</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

</div>

