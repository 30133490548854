<div class="container-fluid bg-white p-3">
  <div class="d-flex justify-content-end">
    <button type="button"  class="btn btn-primary" (click)="handleCancel()">
      Go to Mentor Form
    </button>
  </div>
    <div class="row my-3">
    <div class="col-12 d-flex justify-content-center align-items-center p-3">
      <h2 class="mb-0">
        Mentor Form Preview
      </h2>
    </div>
    </div>
    <div class="row">
  
      <div class="col-12">

        <div id="placehere1">
          
        </div>
     
        <div id="placehere2">
          
        </div>
  
        <div id="placehere3">
          
        </div>
  
        <div id="placehere4">
          
        </div>
      </div>
    </div>
  </div>