import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

  
@Injectable({
  providedIn: 'root'
})
export class StartupService {
  //  baseUrl = "http://10.0.53.134:8080";

  baseUrl = environment.apiurl 

  constructor(
    private http: HttpClient,
  ) {}

  // getallStartUp() {
  //   const url = this.baseUrl + "startups/list";
  //   let result: any = this.http
  //     .get(url, { headers: headers })
  //     .pipe(catchError(this.handleError));
  //   return result;
  // }
  getStartupsList(data) {
    // let data={
    //   search_entity: "STARTUP"
    // }
    const url = this.baseUrl + "search";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  getStartUpByID(id) {
    const url = this.baseUrl + `startups/${id}`;
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  actionStartup(currentData, id) {
    let url = this.baseUrl + "startups/" + id;

    let result: any = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));

      console.log("data:results:", currentData);
    return result;
  }
  editStartup(currentData, id) {
    let url = this.baseUrl + "startups/" +id;
    // console.log("update::", currentData);
      let result = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  filterStartup(data) {
    const url = this.baseUrl + "search";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getSchemesList(schemeId:any) {
    const url = this.baseUrl + "info/schemes";
    return this.http.get<any>(url, 
        { params: { scheme_id: schemeId }, headers: headers }).pipe(catchError(this.handleError))
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
