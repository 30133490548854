<div class="container">
    <h2 class="text-center">List of Agencies</h2>
    <div class="whitebg">
      <table class="table table-striped table-bordered ">
        <thead>
          <tr>
            <th>#</th>
            <th>Incubator Name</th>
            <th>State/ UT Name​</th>
            <th>Type of Incubator​​</th>
             <th>CP-1​</th>
            <th>CP-2</th>
            <th>CP-3</th>
            <th colspan="3" class="col-3 text-center">Startups​</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>fgfdg</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>@mdo</td>
            <td>@mdo</td>
            <td>@mdo</td>
            <td>sfsd</td>
           
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>fgfdg</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>@mdo</td>
            <td>@mdo</td>
            <td>@mdo</td>
          
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  