<section class="challengeform">
    <div class="my-2" style="text-align: end">
        <button type="button" class="btn btn-primary" (click)="handleRoute()" >Go back</button>
    </div>
    <div class="container bg-white p-3 customcontainer">
      <div class="row">
        <div class="col-12">
            <table class="table">
                <thead>
                    <th>
                        Field
                    </th>
                    <th>
                        Value
                    </th>
                </thead>
                <tbody>
                    <tr *ngFor = "let key of Object.keys(mentordata)">
                        <td>
                            <ng-container *ngIf = "key !== 'undefined'">
                                {{key}}
                            </ng-container>

                            <ng-container *ngIf = "key === 'undefined'">
                                Please choose your preferred mentoring format
                            </ng-container>
                            </td>
                        <!-- <td>
                            <ng-container *ngIf = "key != 'profile_img'&&key != 'linked_in'
                            &&key != 'company_website'">
                            <ng-container *ngIf="mentordata[key] && (typeof mentordata[key] !== 'object' || mentordata[key].length > 0); else emptyValue">
                                {{mentordata[key]}}
                              </ng-container>
                              <ng-template #emptyValue>--</ng-template>
                            </ng-container>

                            <ng-container *ngIf="key === 'profile_img'">
                                <ng-container *ngIf="mentordata[key] && mentordata[key][0]?.url; else emptyValue">
                                  <img [src]="mentordata[key][0].url" class="h-auto" alt="" width="100">
                                </ng-container>
                              </ng-container>
                            
                              <ng-container *ngIf="key === 'linked_in'">
                                <ng-container *ngIf="mentordata[key]; else emptyValue">
                                  <a [href]="mentordata[key]" target="_blank">Click Here</a>
                                </ng-container>
                              </ng-container>
                            
                              <ng-container *ngIf="key === 'company_website'">
                                <ng-container *ngIf="mentordata[key]; else emptyValue">
                                  <a [href]="formatUrl(mentordata[key])" target="_blank">Click Here</a>
                                </ng-container>
                                <ng-container *ngIf="mentordata[key] && !formatUrl(mentordata[key])">
                                  --
                                </ng-container>
                              </ng-container>
                            
                              <ng-template #emptyValue>--</ng-template>
                        </td> -->
                        <td>
                            <ng-container *ngIf="key !== 'profile_img' && key !== 'linked_in' && key !== 'company_website'">
                              <ng-container *ngIf="!isEmptyValue(mentordata[key]); else emptyValue">
                                {{mentordata[key]}}
                              </ng-container>
                              <ng-template #emptyValue>--</ng-template>
                            </ng-container>
                          
                            <ng-container *ngIf="key === 'profile_img'">
                              <ng-container *ngIf="mentordata[key] && mentordata[key][0]?.url; else emptyValue">
                                <img [src]="mentordata[key][0].url" class="h-auto" alt="" width="100">
                              </ng-container>
                            </ng-container>
                          
                            <ng-container *ngIf="key === 'linked_in'">
                              <ng-container *ngIf="!isEmptyValue(mentordata[key]); else emptyValue">
                                <a [href]="mentorlink(mentordata[key])" target="_blank">Click Here</a>
                              </ng-container>
                            </ng-container>
                          
                            <ng-container *ngIf="key === 'company_website'">
                              <ng-container *ngIf="mentordata[key] && formatUrl(mentordata[key]); else emptyValue">
                                <a [href]="formatUrl(mentordata[key])" target="_blank">Click Here</a>
                              </ng-container>
                              <ng-container *ngIf="mentordata[key] && !formatUrl(mentordata[key])">
                                --
                              </ng-container>
                            </ng-container>
                          
                            <ng-template #emptyValue>--</ng-template>
                          </td>
                          
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
    </div>
  </section>
