<div class="container ">
  <div class="row justify-content-end">
    <div class="col-12 col-md-6">
<div *ngIf="approveMsg" class="alert alert-success alert-dismissible fade show" role="alert">
  {{approveMsg}}
   </div>
   <div *ngIf="rejectMsg" class="alert alert-danger alert-dismissible fade show" role="alert">
    {{rejectMsg}}
    <!-- <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"><i class= "fa fa-check"></i></button> -->
     </div>
     <div *ngIf="errorMessage" class="alert alert-danger alert-dismissible fade show" role="alert">
      {{errorMessage}}
      <!-- <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"><i class= "fa fa-check"></i></button> -->
       </div>
       </div>
       </div>
       <div class="bg-white">
        <div class="d-flex justify-content-end p-3">
          <button type="button" class="btn btn-primary" (click)="downloadTableData()">Download</button>
        </div>
    <table class="table text-center">
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col" class="col-3">Accelerator Name</th>
            <th scope="col" class="col-3">Utilization Status</th>

            <th scope="col" class="col-3">Utilization Certificate</th>
            <th scope="col" class="col-3">Statement Status</th>

            <th scope="col" class="col-3">Statement of Expenditure</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of document; let i=index">
            <th scope="row">{{i+1}}</th>
            <td>{{item.accelerator.name}}</td>
            <td>{{item.uc_status}}</td>
            <td><a [href]="item.utilisation_certificate.url" target="_blank"><i class="fa fa-eye px-1" style="color: blue;"   ></i></a>
            <ng-container *ngIf="item.uc_status!=='APPROVED'">
              <i class=" fa fa-check px-1" style="color:green" (click)="docStatus('uc','APPROVED',item)"></i>

            </ng-container>
            <ng-container *ngIf="item.uc_status!=='REJECTED'">
              <i class="dropbtn fa fa-close px-1" style="color:red"  (click)="docStatus('uc','REJECTED',item)"></i>

            </ng-container>

            </td>
            <td>{{item.se_status}}</td>
            <td><a [href]="item.statement_of_expenditure.url" target="_blank">
            <i class="fa fa-eye px-1" style="color: blue;"></i></a>

            <ng-container *ngIf="item.se_status!== 'APPROVED'">
              <i  class=" fa fa-check px-1" style="color:green"  (click)="docStatus('se','APPROVED',item)"></i>
             </ng-container>
             <ng-container *ngIf="item.se_status!== 'REJECTED'">
              <i class="dropbtn fa fa-close px-1" style="color:red"  (click)="docStatus('se','REJECTED',item)"></i>

             </ng-container>
            </td>   
             </tr>
        </tbody>
      </table>
</div>
</div>
<!-- samridh modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLabel"><b>Utilization Certificate</b></h4>
        </div>
        <div class="modal-body scroll-bar" >
          <p>{{docData.accelerator.name}}</p>
          <p>{{docData.accelerator.name}}</p>

         </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          
        </div>
      </div>
    </div>
  </div> -->
  <!-- Statement of Expenditure -->
  <div class="modal fade" id="newModal" tabindex="-1" aria-labelledby="newModal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLabel"><b>Statement of Expenditure</b></h4>
        </div>
        <div class="modal-body scroll-bar" >
         </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>