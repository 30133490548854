<div class="container">
    <div class="row">
        <div class="container pt-4">        
          <div class="shadow-lg py-4 mb-5 bg-white border-radius rounded" id="certificates">
            <!-- Add your content for the Certificates tab here -->
            <form class="p-5" id ="certificateDetail" [formGroup]="certificateDetail" (ngSubmit)="onCertificatesSubmit()">
              <!-- <div class="form-group">
                <label class="form-label font-weight-bold">No Of Utilization Certificates</label>
                <input type="number" placeholder="No Of Utilization Certificates" class="form-control" formControlName="noOfUcs" readonly />
                <div *ngIf="_noOfUcs?.touched && _noOfUcs?.invalid" class="text-danger">
                  <div *ngIf="_noOfUcs?.errors?.required">
                    <p class="text-danger">This Field is mandatory</p>
                  </div>
                </div> 
              </div> -->
              <!-- <div *ngFor="let quarter of updateDocumentDetails" class="mb-4 shadow p-4"> -->
                <!-- <h3 class="mb-3 text-center">
                   <strong>Quarter {{ getQuarterNumber(quarter) }}</strong>
                </h3> -->
                <div class="form-group d-flex justify-content-end">
                  <div class="col-4 d-flex">
                    <label class="form-label font-weight-bold mx-2 my-1">Year:</label>
                    <select class="form-select form-control" id="yearDropdown" (change)="viewYear()">
                      <!-- <option value="" selected>Select Year</option> -->
                      <option [value]="year.id" *ngFor="let year of chooseYear">{{ year.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table" style="vertical-align: middle;">
                    <thead class="thead-light">
                      <tr>
                        <th class="text-center">Quarter</th>
                        <th class="text-center">Documents</th>
                        <th class="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let quarter of updateDocumentDetails">
                        <td class="text-center py-2"><strong>Quarter {{ getQuarterNumber(quarter) }}</strong></td>
                        <!-- <td class="text-center">{{ quarter.file?.duration }}</td> -->
                        
                        <!-- <td class="text-center">
                          <div class="btn-group" role="group" aria-label="Actions">
                            <button class="btn btn-primary mr-4" (click)="downloadDocument(quarter.file?.file_url)">
                              View Document
                            </button>
                            <button class="btn btn-success mr-4" style="width: 135px;" (click)="acceptDocument(quarter.term_id)">Accept</button>
                            <button class="btn btn-danger" style="width: 135px;" (click)="rejectDocument(quarter.term_id)">Reject</button>
                          </div>
                        </td> -->
                        <td class="text-center d-flex justify-content-around">
                          <ng-container *ngIf="quarter">
                            <div *ngIf="quarter?.file || !quarter?.file" class="py-2">
                              <button class="btn btn-primary mr-4" (click)="downloadDocument(quarter.file?.file_url)" *ngIf="quarter.file?.file_url">
                                View Document
                              </button>
                              <span *ngIf="!quarter.file?.file_url">Document not uploaded</span>
                            </div>
                            <div *ngIf="quarter?.statementOfExpenditure || !quarter?.statementOfExpenditure" class="py-2">
                              <button class="btn btn-primary mr-4" (click)="downloadStatement(quarter.statementOfExpenditure?.url)" *ngIf="quarter.statementOfExpenditure?.url">
                                View Statement
                              </button>
                              <span class="my-5" *ngIf="!quarter.statementOfExpenditure?.url">Statement not uploaded</span>
                            </div>
                          </ng-container>                        
                        </td>
                        <td class="text-center">
                          <ng-container *ngIf="quarter.file && quarter.statementOfExpenditure">
                            <div class="btn-group py-2" role="group">
                              <!-- {{quarter.file?.file_url}} -->                            
                              <!-- <a class="fa fa-check ml-2" style="color: blue" [disabled]="isLastCommentAccepted(quarter.comments)"  (click)="acceptDocument(quarter?.term_id)"></a>
                              <a class="fa fa fa-close ml-2" style="color: red" [disabled]="isLastCommentAccepted(quarter.comments)" (click)="showRejectCommentPrompt(quarter?.term_id)"></a> -->
                              <button class="btn btn-success mx-2" [disabled]="isLastCommentAccepted(quarter?.comments)" [innerText]="getAcceptButtonText(quarter?.comments)" style="width: 135px; border-radius: 5px;" (click)="acceptDocument(quarter?.term_id)">Accept</button>
                              <button class="btn btn-danger mx-2" [disabled]="isLastCommentRejected(quarter?.comments)" [innerText]="getRejectButtonText(quarter?.comments)" style="width: 135px; border-radius: 5px;" (click)="showRejectCommentPrompt(quarter?.term_id)">Reject</button>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="!quarter.file && !quarter.statementOfExpenditure">
                            No action to perform
                          </ng-container>
                        </td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <!-- <hr> -->
              <!-- </div> -->
                     
            </form>
          </div>
        </div>
    </div>
</div>
