import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { AxiosServicesService } from "./axios/axios-services.service";

const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

@Injectable({
  providedIn: "root",
})
export class GuestHouseService {
  baseUrl = environment.apiurl;

  constructor(
    private http: HttpClient,
    private axiosServices: AxiosServicesService
  ) {}

  createGuestData(data: any) {
    const url = this.baseUrl + "resources/guesthouse";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  getAllGuestHouseData(data) {
    const url = this.baseUrl + "resources/search";
    let result: any = this.http
      .post(url,data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getHouseByID(id) {
    let url = this.baseUrl + "resources/guesthouse/" + id;
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  deleteGuestHouseData(id: any) {
    const url = this.baseUrl + "resources/guesthouse/delete/" + id;
    let result: any = this.http
      .post(url, id, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  editGuestHouseData(data: any, id: any) {
    const url = this.baseUrl + "resources/guesthouse/" + id;
    let result: any = this.http
      .put(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }


  actionFacility(data: any, id: any, index: any) {
    const url = this.baseUrl + `resources/${index}/${id}`;
    let result: any = this.http
      .put(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  filterLab(data:any) {
    const url = this.baseUrl + `resources/profile/list`;
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
    console.log("result is ==> ",result)
  }


}
