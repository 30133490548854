export class form {
    getFormData(formid,label){
        console.log('form id from ts',formid);
        const form2 = document.getElementById(formid);
        console.log('form2 from js',form2);
        const formdata0 = new FormData(form2);
        console.log('formdata',formdata0);
        let labelobj = {};
        labelobj[label] = Object.fromEntries(formdata0);
        return labelobj;
    }

    removeUndefinedKeys(item){
        return Object.fromEntries(item);
    }
}