
<div class="container">
<div class="row justify-content-end">
    <div class="col-12 col-md-6">
<div *ngIf="approveMsg" class="alert alert-success alert-dismissible fade show" role="alert">
  {{approveMsg}}
   </div>
   <div *ngIf="rejectMsg" class="alert alert-danger alert-dismissible fade show" role="alert">
    {{rejectMsg}}
     </div>
     <div *ngIf="errorMsg" class="alert alert-danger alert-dismissible fade show" role="alert">
      {{errorMsg}}
       </div>
</div>

<div class=" container whitebg my-3 p-4 ">
    <h2 class="text-center">Release Order Form</h2>
    <form [formGroup]="releasedForm">        
      <div class="row  p-3 my-4">
        <div class="col-12 col-md-6">
          <label class="form-label label-required">Accelerator Name</label>
            <ng-select 
            [items]="accelerator"
            bindLabel="name" 
            bindValue="id"
            theme="default"
            placeholder="Select Accelerator Name"
            name="name"
            [ngModel]="null"
            formControlName="name"
            [dropdownPosition]="'bottom'"
            [multiple]="true"

            >
         <ng-option *ngFor="let stage of accelerator" [value]="stage.name">{{stage.name}}</ng-option>
        </ng-select>
        <!-- dropdown validation -->
        <div
        *ngIf="
        (isreleasedorderSubmited && _name.invalid) ||
        (_name?.touched && _name?.invalid)" class="text-danger">
         <div *ngIf="releasedForm?.get('name').errors?.required">
            <p class="text-danger">Name is mandatory</p>
          </div>
      </div>
    </div>

         <div class="col-12 col-md-6">
          <label class="form-label label-required">Upload Release Order</label>
        <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="customDocFile"
                  name="uploadDoc"
                  (change)="onFileSelectdoc($event)"
                />
                <label class="custom-file-label" for="customFile">{{
                  fileInputLabel1 || "Choose File"
                }}</label>
              </div>
              <span style="color: black"
                >(File Size Should Not Exceed 2MB. Allowed Only Documents)</span> 
                <div>
                <p class="text-danger" *ngIf="filetyperrr">
                  File format is incorrect
                </p>
                <p class="text-danger" *ngIf="filesizerrr">
                  File size should not exceed 2 MB
                </p>
              </div>
              <!-- upload doc validation   -->
                <div
                *ngIf="
                  (isreleasedorderSubmited && _uploadDoc.invalid)" class="text-danger py-1">
                <div *ngIf="_uploadDoc.errors.required || (_uploadDoc?.touched && _uploadDoc.invalid) || _uploadDoc.invalid">
                  <p class="text-danger">Upload Document is mandatory</p>
                </div>
                 </div>
            </div>
          <div class="col-12 d-flex justify-content-center py-4">
            <button type="button" class="btn btn-primary text-center" (click)="onSubmit()">{{ editMode ? 'Update' : 'Submit' }}</button>
            <button type="canel" class="mx-2 btn btn-secondary cancelEditBtn " *ngIf="editMode" (click)="cancelEdit()">Cancel</button>
    </div>
         </div>
        </form>

    <div class="py-3">
        <table class="table text-center">
            <thead>
              <tr>
                <th scope="col">S.N0</th>
                <th scope="col">Accelerator Name</th>
                <th scope="col">View Release Order Doc</th>
                <th>Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of releseData | paginate: { itemsPerPage: pageSize, currentPage: p }, let i =index">
                <th scope="row">{{i+1 +(p-1)*pageSize}}</th>
                <td>{{item.name}}</td>
               <!-- <td><a *ngIf="item?.release_order && item.release_order[0]?.url" [href]="item.release_order[0].url" target="_blank">{{ item.release_order[0]?.url }}</a>
                <span *ngIf="!item?.release_order || !item.release_order[0]?.url">-</span></td> -->

                <td><a *ngIf="item?.release_order" [href]="item.release_order?.url ? item.release_order?.url : item.release_order" target="_blank">View document here</a></td>
             <td>{{item.release_order_status}}</td>
                <td>
                    <i class="fa fa-edit px-1"(click)="editData(item)"></i>
                    <ng-container *ngIf="item.release_order_status!== 'APPROVED'">
                    <i class="dropbtn fa fa-check px-1" style="color:green"  (click)="approveDocs('APPROVED',item)"></i>
                  </ng-container>
                  <ng-container *ngIf="item.release_order_status!== 'REJECTED'">

                    <i class="dropbtn fa fa-close" style="color:red"   (click)="approveDocs('REJECTED',item)"></i>
                 </ng-container>
                  </td>
              </tr>
            </tbody>
          </table>
          <pagination-controls *ngIf="releseData?.length>0" (pageChange)="onPageChange($event)" directionLinks="true"  class="float-right"></pagination-controls>

    </div>
        </div>
    