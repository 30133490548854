<div class="contailer-fluid">
  <form [formGroup]="startupFundUtilisedDetail" (ngSubmit)="postFundUtisedDetail()">
    <div formArrayName="startup_Fund_utilised">
      <div
        *ngFor="
          let fundUtilisedDetail of startupFundUtilisedDetail?.get('startup_Fund_utilised')
            ?.controls;
          let i = index
        "
      >
        <div [formGroupName]="i" class="shadow-lg p-3 mb-5 bg-white border-radius mt-4">
  
  <div class="row">
    <div class="col font-weight-bold">
      <label for="">START UP {{i+1}}</label>
    </div>
    <div class="col eir">
      <label for="">EiR</label>
    </div>
    <div class="col mceir">
      <label for="">Management Cost For EiR</label>
    </div>
    <div class="col grant">
      <label for="">Grant</label>
    </div>
    <div class="col mcgrant">
      <label for="">Management Cost For Grant</label>
    </div>
    <div class="col hack">
      <label for="">Hackathon</label>
    </div>
    <div class="col challengeg">
      <label for="">Challenge Grant</label>
    </div>
    <div class="col dep">
      <label for="">Deep Engagement Program</label>
    </div>
    <div class="col workshop">
      <label for="">Workshop</label>
    </div>
    <div class="col workshop">
        <label for="">Total</label>
    </div>
    <!-- <div class="col-md-1">
      <label for="">Management Cost For Grant</label>
    </div> -->
  </div>
  <div class="row">
    <div class="col">
      <input type="hidden" formControlName="fundterm_id" />
      <input type="hidden" formControlName="startupfund_id" />
      <label for="">Approved Amount<span class="text-danger">*</span></label> 
    </div>
    <div class="col form-group stage">
      <input type="number" min="1" placeholder="EiR" formControlName="Eir_approvedAmount" class="form-control" [ngClass]="{'is-invalid':startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('Eir_approvedAmount').touched &&
    startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('Eir_approvedAmount').invalid}">
    </div>
    <div class="col form-group stage">
      <input type="number" min="1" placeholder="Management Cost For EiR" formControlName="mcEir_approvedAmount" class="form-control" [ngClass]="{'is-invalid':startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('mcEir_approvedAmount').touched &&
    startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('mcEir_approvedAmount').invalid}">
    </div>
    <div class="col form-group stage">
      <input type="number" min="1" placeholder="Grant" formControlName="Grant_approvedAmount" class="form-control"  [ngClass]="{'is-invalid':startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('Grant_approvedAmount').touched &&
    startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('Grant_approvedAmount').invalid}">
    </div>
    <div class="col form-group stage">
      <input type="number" min="1" placeholder="Management Cost For Grant" formControlName="mcGrant_approvedAmount" class="form-control"  [ngClass]="{'is-invalid':startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('mcGrant_approvedAmount').touched &&
    startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('mcGrant_approvedAmount').invalid}">
    </div>
    <div class="col form-group stage">
      <input type="number" min="1" placeholder="Hackathon" formControlName="hackathon_approvedAmount" class="form-control" [ngClass]="{'is-invalid':startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('hackathon_approvedAmount').touched &&
    startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('hackathon_approvedAmount').invalid}">
    </div>
    <div class="col form-group stage">
      <input type="number" min="1" placeholder="Challenge Grant" formControlName="challengeGrant_approvedAmount" class="form-control" [ngClass]="{'is-invalid':startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('challengeGrant_approvedAmount').touched &&
    startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('challengeGrant_approvedAmount').invalid}">
    </div>
    <div class="col form-group stage">
      <input type="number" min="1" placeholder="Deep Engagement Program" formControlName="dep_approvedAmount" class="form-control" [ngClass]="{'is-invalid':startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('dep_approvedAmount').touched &&
    startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('dep_approvedAmount').invalid}">
    </div>
    <div class="col form-group stage">
      <input type="number" min="1" placeholder="Workshop" formControlName="workshop_approvedAmount" class="form-control" [ngClass]="{'is-invalid':startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('workshop_approvedAmount').touched &&
    startupFundUtilisedDetail.controls.startup_Fund_utilised.controls[
      i
    ].get('workshop_approvedAmount').invalid}">
    </div>
    <div class="col form-group stage">
        <input type="number" formControlName="total_approvedAmount" class="form-control" readonly disabled>
    </div>
  </div>
  
  <div formArrayName="amountutilisedinQuarters">
    <!-- {{fundUtilisedDetail.get('amountutilisedinQuarters').controls}} -->

    <div
      *ngFor="
        let quarter of fundUtilisedDetail.get('amountutilisedinQuarters')?.controls;
        let j = index
      "
    >
      <div [formGroupName]="j" class="shadow-lg p-3 mb-2 bg-white border-radius mt-2">
  <div class="row">
    <div class="col form-group stage">
      <label for="">Amount Utilized in Q{{j+1}}</label>
    </div>
    <div class="col form-group stage">
      <!-- {{quarter.get('mcEir_Utilizedq1').valid}} -->
      <input type="text" formControlName="Eir_Utilizedq1" class="form-control" readonly />
    </div>
    <div class="col form-group stage">
      <!-- {{quarter.get('mcEir_Utilizedq1').valid}} -->
      <input type="text" formControlName="mcEir_Utilizedq1" class="form-control" readonly />
    </div>
    <div class="col form-group stage">
      <!-- {{quarter.get('mcEir_Utilizedq1').valid}} -->
      <input type="text" formControlName="Grant_Utilizedq1" class="form-control" readonly />
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="mcGrant_Utilizedq1" class="form-control" readonly />
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="hackathon_Utilizedq1" class="form-control" readonly />
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="challengeGrant_Utilizedq1" class="form-control" readonly />
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="dep_Utilizedq1" class="form-control" readonly />
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="workshop_Utilizedq1" class="form-control" readonly />
    </div>
    <div class="col form-group stage">
        <input type="text" formControlName="total_Utilizedq1" class="form-control" readonly />
    </div>
  </div>
    </div>
  </div>
   
  </div>
  <div class="row">
    <div class="col form-group stage">
      <label for="">Total Amount Utilized</label>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="Eir_UtilizedTotal" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="mcEir_UtilizedTotal" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="Grant_UtilizedTotal" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="mcGrant_UtilizedTotal" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="hackathon_UtilizedTotal" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="challengeGrant_UtilizedTotal" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="dep_UtilizedTotal" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="workshop_UtilizedTotal" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
        <input type="text" formControlName="total_UtilizedTotal" class="form-control" readonly>
    </div>
  </div>
  <div class="row">
    <div class="col form-group stage">
      <label for="">Total Amount Unutilized</label>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="Eir_unUtilized" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="mcEir_unUtilized" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="Grant_unUtilized" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="mcGrant_unUtilized" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="hackathon_unUtilized" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="challengeGrant_unUtilized" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="dep_unUtilized" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
      <input type="text" formControlName="workshop_unUtilized" class="form-control" readonly>
    </div>
    <div class="col form-group stage">
        <input type="text" formControlName="total_unUtilized" class="form-control" readonly>
    </div>
  </div>  
  </div>
  </div>
  </div>
  <button type="submit" class="btn btn-primary">Submit</button>
  </form>
</div>


