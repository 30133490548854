import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  
  private getall = new Subject<any>();
  get_all = this.getall.asObservable();

  showToast(arg0: string, arg1: string) {
    throw new Error('Method not implemented.');
  }
  openRandomToast(arg0: string, arg1: string) {
    throw new Error('Method not implemented.');
  }

  setalldata(data:any){
    this.getall.next(data);
  }

  getalldata():Observable<any>{
    return this.get_all;
  }
  constructor() { }
   
}
