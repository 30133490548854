<div class="container bg-white mt-5 col-md-6 m-auto">
    <div class="row">
      <div class="w-100">
        <h2 class="text-center">Assign Startup to Incubator</h2>
      </div>
      <div class="float-right">
        <!-- <button class="btn btn-primary" style = "color:white" (click)="gotostartup()" > Go to Startup</button>   -->
        </div>
      <div class="col-12">
                <form [formGroup]="startupForm">
                  <div class="form-group">
                    <div class="form-group col-md-10">
                      <label for="exampleFormControlSelect1">Startup</label>
                      <!-- <select class="form-control" formControlName="startupName" (change)="assignIncStartup($event)" >
                        <option value="">Select </option>
                        <option *ngFor="let option of startupList" [value]="option.id">
                          {{ option.name }}
                        </option>
                      </select> -->
                      <ng-multiselect-dropdown 
                      formControlName="startupName"
                      [(ngModel)]="selectStartup"
                      [placeholder]="''"
                      [settings]="dropdownSettingsS"
                      [data]="dropdownStartup"
                      [(ngModel)]="selectedStartup"
                    >
                    </ng-multiselect-dropdown>
                    <div *ngIf="startupForm.controls.startupName.invalid && startupForm.controls.startupName.touched" class="error-message">
                      This field is required.
                    </div>
                    </div>
                  </div>
  
                  <div class="form-group">
                    <div class="form-group col-md-10">
                      <label for="exampleFormControlSelect1">Incubator</label>
                      <!-- <select class="form-control" formControlName="incubatorAssign" (change)="incubatorToassign($event)" >
                        <option value="">Select </option>
                        <option *ngFor="let option of originalData" [value]="option.id">
                          {{ option.name }}
                        </option>
                      </select> -->

                      <ng-multiselect-dropdown 
                      formControlName="incubatorAssign"
                      [(ngModel)]="selectIncubator"
                      [placeholder]="''"
                      [settings]="dropdownSettings"
                      [data]="dropdownIncubator"
                      [(ngModel)]="selectedIncubator"
                    >
                    </ng-multiselect-dropdown>
                    <div *ngIf="startupForm.controls.incubatorAssign.invalid && startupForm.controls.incubatorAssign.touched" class="error-message">
                      This field is required.
                    </div>

                    </div>
                  </div>
                 

                </form>
                <div class="modal-footer" style="display: flex; justify-content: center;">
                  <button
                    class="btn btn-primary"
                    (click)="submitStartup()"
                  >
                    Save
                  </button>
                  <button type="button" class="btn btn-secondary" (click)="cancel()" >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          
  </div>