import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "../../environments/environment";
import { AxiosServicesService } from "./axios/axios-services.service";

const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

@Injectable({
  providedIn: "root",
})
export class ResourceBookingService {
  baseUrl = environment.apiurl;

  constructor(
    private http: HttpClient,
    private axiosServices: AxiosServicesService
  ) {}

  getAllBookingData(facility) {
    const url = this.baseUrl + `bookings/list?resource_type=` + facility;
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  approveBooking(data) {
    const url = this.baseUrl + "bookings/status";
    let result: any = this.http
      .post(url, { headers: headers }, data)
      .pipe(catchError(this.handleError));
    return result;
  }

  actionBooking(currentData) {
    let url = this.baseUrl + "bookings/status";
    let result: any = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));

    console.log("data:results:", currentData);
    return result;
  }
  filterUser(data) {
    const url = this.baseUrl + `resources/booking/list`;
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
    console.log("result is ==> ",result)
  }
  SearchAllBookingData(data:any) {
    const url = this.baseUrl + "resources/bookings/search";
    let result: any = this.http
      .post(url,data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  filterResources(data:any){
    let url = this.baseUrl + "resources/bookings/search";
    let result: any = this.http
      .post(url,data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

}
