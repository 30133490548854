import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  AbstractControl,
  Validators,
} from "@angular/forms";
import { find, get, pull } from "lodash";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ChallengeService } from "../../../services/challenge.service";
import { EditionService } from "../../../services/edition.service";
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { Router } from "@angular/router";
import { toJSON, toDOM } from "dom-to-json";
import { fromEvent, Subscription, VirtualAction } from "rxjs";
import * as _ from 'lodash';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { environment } from "../../../../environments/environment";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { states } from "../../../../assets/js/states";


export interface Fruit {
  name: string;
}

// declare var ClassicEditor: any;
declare var $: any;

var items = [];

var index;
const headers= new HttpHeaders()
.set('Authorization', `Bearer ${sessionStorage.getItem('admintoken')}`);
@Component({
  selector: "ngx-forms",
  templateUrl: "./forms.component.html",
  styleUrls: ["./forms.component.scss"],
})
export class FormsComponent implements OnInit, AfterViewInit {
  //@ViewChild('tagInput') tagInputRef: ElementRef;
  @ViewChild("tagInput_sectors") tagInputRef_sectors: ElementRef;
  @ViewChild("tagInput_subsectors") tagInputRef_subsectors: ElementRef;
  @ViewChild("tagInput_keywords") tagInputRef_keywords: ElementRef;
  @ViewChild("button_next", { static: false }) button_next: ElementRef;
  @ViewChild("UploadFileInput", { static: false }) uploadFileInput: ElementRef;

  clickedElement: Subscription = new Subscription();
  filetypeerr = false;
  filesizeerr = false;

  tags: string[] = [];
  tags_sectors: string[] = [];
  tags_subsectors: string[] = [];
  tags_keywords: string[] = [];
  tag_s: boolean;
  tag_sb: boolean;
  tag_k: boolean;
  challengename:any
  edition_name:any
  edition_desc:any
  start_date:any
  end_date:any
  submitted = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings = {};
  dropdownSettings_t:IDropdownSettings = {};

  dropdownList1 = [];
  selectedItems1 = [];
  // dropdownSettings:IDropdownSettings = {};

  dropdownList2 = [];
  selectedItems2 = [];

  dropdownList_t = [];
  selectedItems_t = [];
  nokeyword = false;
  disable_publish = true;
  // dropdownSettings2:IDropdownSettings = {};
  // public Editor = ClassicEditor;
  public industry = [];
  public sector = [];
  public keyword = [];
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "alignment", // <--- ADDED
        "bold",
        "italic",
        "link",
        "bulletedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    link: {
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
        openInNewTab: {
          mode: "manual",
          label: "Open in a new tab",
          defaultValue: true, // This option will be selected by default.
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
    placeholder: 'Enter Application Description'
  };

  public editorConfig_tc = {
    toolbar: {
      items: [
        "heading",
        "|",
        "alignment", // <--- ADDED
        "bold",
        "italic",
        "link",
        "bulletedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    link: {
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
        openInNewTab: {
          mode: "manual",
          label: "Open in a new tab",
          defaultValue: true, // This option will be selected by default.
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
    placeholder: 'Enter Terms and Conditions'
  };
  // public editorConfig = {
  //   toolbar: {
  //     items: [
  //       'heading',
  //       '|',
  //       'alignment', // <--- ADDED
  //       'bold',
  //       'italic',
  //       'link',
  //       'bulletedList',
  //       'blockQuote',
  //       'undo',
  //       'redo'
  //     ]
  //   },
  //   link: {
  //     decorators: {
  //       toggleDownloadable: {
  //         mode: 'manual',
  //         label: 'Downloadable',
  //         attributes: {
  //           download: 'file'
  //         }
  //       },
  //       openInNewTab: {
  //         mode: 'manual',
  //         label: 'Open in a new tab',
  //         defaultValue: true, // This option will be selected by default.
  //         attributes: {
  //           target: '_blank',
  //           rel: 'noopener noreferrer'
  //         }
  //       }
  //     }
  //   }
  // };

  testdata = ['test','test','test']


  // editorConfig: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: "15rem",
  //   minHeight: "5rem",
  //   maxHeight: "auto",
  //   width: "auto",
  //   minWidth: "0",
  //   translate: "no",
  //   enableToolbar: true,
  //   showToolbar: true,
  //   placeholder: "Enter text here...",
  //   defaultParagraphSeparator: "p",
  //   defaultFontName: "Arial",
  // };

  applicationForm!: FormGroup;
  companyDetailsForm!: FormGroup;
  applicationSectionForm!: FormGroup;
  additionalQuestionForm!: FormGroup;
  reviewPublishForm!: FormGroup;
  showform: boolean = false;
  showStepper: boolean;
  //Editor: boolean;
  editorsForm: FormGroup;
  leftFields = [
    { id: 1, name: "OPPORTUNITY" },
    { id: 2, name: "FUNDING REQUIREMENT" },
    { id: 3, name: "INTELLECTUAL PROPERTY" },
    { id: 4, name: "FINANCIAL PROJECTIONS" },
    { id: 5, name: "KEY CUSTOMERS" },
    { id: 6, name: "EVENTS & MILESTONES" },
    { id: 6, name: "GO TO MARKET" },
    { id: 6, name: "FUNDING ROUNDS" },
    { id: 6, name: "STRATEGIC PARTNERS" },
  ];
  rightFields = [];
  jsonOutput: any;
  value: any;
  msg: string;
  arrayDataPush = {};
  inputValue: string;
  challengeid: any;
  inValidMessage: boolean;
  data: any;
  editionid: string;
  noform: boolean;
  label: any;
  index: number;
  parent: any;
  checked: any;
  formid: any;
  fileInputLabel: any;
  alldomains: any;
  final: any = [];
  sectors: any = [];
  industryevent: any;
  exportaccess: any;
  accessemailnotification: any;
  groupbydomains: any;
  tempdata = [];
  urlpattern: string;
  selectedinput: string;
  longindex: any;
  states: string[];
  timezone: any;
  invalidapplicationdetail: boolean = false;
  invalid_tc: boolean = false;
  sectors_dropdown: any;
  

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private challengeService: ChallengeService,
    private editionService: EditionService,
    private toastrService: NbToastrService,
    private router: Router,
    private dialogService: NbDialogService,
  ) {
    this.getDomains();
  }

  ngOnInit(): void {
    console.log('states__',states)
    this.states = states;
    this.urlpattern = "((http|https)://)(www.)?" 
    + "[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]" 
    + "{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)"
    // this.challengeService.getSectorData().subscribe((res)=>{
    //   console.log('res_sector_data',res);
    //   for(var i = 0 ; i<res.length ; i++) {
    //     this.tempdata.push({
    //       item_id:i+1 , item_text:res[i].name
    //     })
    //   }
    //   this.dropdownList1 = this.tempdata;
    //   console.log('res_sectors',this.dropdownList1);
    // })
    
    // setTimeout(()=>{
    //   this.dropdownList1 = this.tempdata
    // },5000)

    this.dropdownList = [
      // { item_id: 1, item_text: 'Agriculture' },
      // { item_id: 2, item_text: 'Education' },
      // { item_id: 3, item_text: 'Healthcare' },
      // { item_id: 4, item_text: 'Finance' },
      // { item_id: 5, item_text: 'IT' }
    ];
    this.selectedItems = [
      
    ];
    
  //   this.dropdownList1 = [
  //     {
  //         "item_id": 1,
  //         "item_text": "Others"
  //     },
  //     {
  //         "item_id": 1,
  //         "item_text": "Others"
  //     },
  //     {
  //         "item_id": 1,
  //         "item_text": "Others"
  //     },
  //     {
  //         "item_id": 2,
  //         "item_text": "Auto & Truck Manufacturers"
  //     },
  //     {
  //         "item_id": 3,
  //         "item_text": "Auto, Truck & Motorcycle Parts"
  //     },
  //     {
  //         "item_id": 4,
  //         "item_text": "Electric Vehicles"
  //     },
  //     {
  //         "item_id": 5,
  //         "item_text": "Others"
  //     },
  //     {
  //         "item_id": 6,
  //         "item_text": "Tires & Rubber Products"
  //     }
  // ];
    this.selectedItems1 = [
      
    ];
    
    this.dropdownList2 = [
      { item_id: 1, item_text: 'crop' },
      { item_id: 2, item_text: 'books' },
      { item_id: 3, item_text: 'medicine' },
      { item_id: 4, item_text: 'money' },
      { item_id: 5, item_text: 'os' }
    ];
    this.selectedItems2 = [
      
    ];

    this.dropdownList_t = [
      { item_id: 1, item_text: 'IST' },
      { item_id: 2, item_text: 'GMT' },
    ];
    this.selectedItems_t = [
      
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };

    this.dropdownSettings_t = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };
    //this.formatdate();
    this.challengename = sessionStorage.getItem('challengename');
    this.edition_name = sessionStorage.getItem('edition_name');
    this.edition_desc = sessionStorage.getItem('edition_desc');
    this.start_date = sessionStorage.getItem('start_range');
    this.end_date = sessionStorage.getItem('end_range');

    this.applicationForm = this.fb.group({
      geographical: ['',Validators.required],
      startRange: ['',Validators.required],
      endRange: ['',Validators.required],
      tag: ['',Validators.required],
      businessSubSector: ['',Validators.required],
      timeZone: ['',Validators.required],
      applicationDetail: ['',Validators.required],
      eligibility: ['',Validators.required],
      keywordsTag: [''],
      chooseFile: [''],
      dataExport: [''],
      emailExport: [''],
      uploadedImage: ['',Validators.required]
    });
    this.applicationForm.controls.startRange.setValue(sessionStorage.getItem('start_range'));
    this.applicationForm.controls.endRange.setValue(sessionStorage.getItem('end_range'));
    this.companyDetailsForm = this.fb.group({
      profileLogo: [],
      companyLogo: [],
      uploadPic: [],
      companyName: [],
      registered: [],
      established: [],
      website: [],
      countryName: [],
      profilePicture: [],
      stateName: [],
      cityName: [],
      companyStage: [],
      industry: [],
      sectorName: [],
      descriptionDetail: [],

      managementName: [],
      managementTitle: [],
      managementEstabl: [],
      gender: [],
      nationality: [],
      managementEmail: [],
      contactNumber: [],
      managementDescription: [],
    });
    this.applicationSectionForm = this.fb.group({});
    this.editorsForm = this.fb.group({
      editors: this.fb.array([]),
      menus: this.fb.array([]),
      addFields: this.fb.array([]),
    });
    this.reviewPublishForm = this.fb.group({});
  }

  challenges(){
    this.router.navigate(['/admin/addchallenge']).then(()=>{
      sessionStorage.setItem('edition_changed','false');
      location.reload();
    })
  }
  add_application_detail(event:any){
    console.log('eventdata',event.target.innerText);
    let value = event.target.innerText.trimStart();
    console.log('value_length',value.length);
    let keyCode = event.keyCode;
    console.log('backspace',keyCode);
    if(value.length != 0){
      console.log('ok');
      this.invalidapplicationdetail = false
    }
    else{
      console.log('not ok');
      this.invalidapplicationdetail = true
    }
  }

  add_tc(event:any){
    console.log('eventdata',event.target.innerText);
    let value = event.target.innerText.trimStart();
    if(value.length != 0){
      console.log('ok');
      this.invalid_tc = false
    }
    else{
      console.log('not ok');
      this.invalid_tc = true
    }
  }
  
  ngAfterViewInit() {

    // document.querySelectorAll('.multiselect-item-checkbox').forEach((element)=>{
    //   console.log('first child',element)
    // })
    
    setTimeout(()=>{
      var txt = document.getElementById('txt');
      var list = document.getElementById('list');
      console.log('dom_____',txt,list);
      items = [];
      
      if(txt!=null){
        txt.addEventListener('keypress', (e) => {
          if (e.key === 'Enter') {
          let val = (<HTMLInputElement>txt).value;
          if (val !== '') {
            if (items.indexOf(val) >= 0) {
              alert('Tag name is a duplicate');
            } else {
              items.push(val);
              this.nokeyword = false;
              render();
              console.log('it is here');
              (<HTMLInputElement>txt).value = '';
              txt.focus();
            }
          } else {
            alert('Please type a tag Name');
          }
        }
      });
      }

  
  function render() {
    list.innerHTML = '';
    items.map((item, index) => {
      list.innerHTML += `<li><span>${item}</span><a id="close_${index}" class="closekeyword text-white">X</a></li>`;
    });
 
    console.log('doc_query___', document.querySelectorAll('.closekeyword'));
    document.querySelectorAll('.closekeyword').forEach((element,i)=>{
      var index = i;
      console.log('elemcross____',element,document.getElementById(`close_${i}`));
      // this.nokeyword = false;
      (<HTMLInputElement>document.getElementById('txt')).value = '';
      document.getElementById(`close_${i}`).onclick = () => {
        console.log('clicked on cross',index);
        console.log('items___',items);
        remove(index);
      }
    })
  }

  
  function remove(i) {
    items = items.filter(item => items.indexOf(item) != i);
    render();
  }
  
  window.onload = function() {
    render();
    txt.focus();
  }
    
    },5000)
  // this.observeElement();
    // let applicationdesc = document.getElementById(
    //   "applicationdesc"
    // ) as HTMLElement;

    // console.log("applicationdesc", applicationdesc);
    // ClassicEditor.create(document.querySelector("#applicationdesc")).catch(
    //   (error: any) => {
    //     console.error(error);
    //   }
    // );
    // ClassicEditor.create(document.querySelector("#termsandconditions")).catch(
    //   (error: any) => {
    //     console.error(error);
    //   }
    // );
    // if(!this.noform){
      
      this.converttohtml().then(() => {
        if(!this.noform){
          if(sessionStorage.getItem('formpreview')=='true'){
            // $('.mandatory').attr('class','d-none');
            // $('.mandatorytext').attr('class','d-none');
            // $('#addquestion').attr('class','d-none');
            // $('#removequestion').attr('class','d-none');
            // $('.addmoreradio').attr('class','d-none');
            // $('.selectfield').attr('class','d-none');
            // $('#prev_step_4').text('Submit');
            // $('#next_step_4').attr('class','d-none');
            // $('.mandatoryplus').attr('class','d-none');
            // $('.mandatoryminus').attr('class','d-none');
            $('.mandatory').attr('class','d-none');
            $('.mandatorytext').attr('class','d-none');
            $('#addquestion').attr('class','d-none');
            $('#removequestion').attr('class','d-none');
            $('.addmoreradio').attr('class','d-none');
            $('.selectfield').attr('class','d-none');
            $('#next_step_4').text('Submit');
            //$('#next_step_4').attr('class','d-none');
            $('.mandatoryplus').attr('class','d-none');
            $('.mandatoryminus').attr('class','d-none');
            $('.hide').attr('class','d-none');
            $('.hideonpreview').attr('style','display:none');

            document.querySelectorAll('.slider').forEach((element,i)=>{
              element.setAttribute('class','d-none');
            })

            document.querySelectorAll('.switch_disabled').forEach((element,i)=>{
              element.setAttribute('class','d-none');
            })

            document.getElementById('addquestion').setAttribute('class','d-none');
            document.getElementById('removequestion').setAttribute('class','d-none');

            document.querySelectorAll('.step3val').forEach((element,i)=>{
              element.classList.remove('d-none');
              element.className+=' d-block'
              //element.setAttribute('class','d-block');
            })

            document.querySelectorAll('.removeinui').forEach((element,i)=>{
              element.classList.remove('border');
              element.classList.remove('border-5');
              element.classList.remove('border-secondary');
              //element.setAttribute('class','d-block');
            })

          }
   

          console.log("after conversion");
          console.log("add question", document.getElementById("addquestion"));
          document.getElementById("addquestion").onclick = (e) => {
            e.preventDefault();
            this.addQuestions();
          };
          // console.log('btnid',document.getElementById('next_step_1') as HTMLElement);
          // let nextbtn = document.getElementById("next_step_1") as HTMLElement;
          let nextbtn2 = document.getElementById("next_step_2") as HTMLElement;
          let nextbtn3 = document.getElementById("next_step_3") as HTMLElement;
          let nextbtn4 = document.getElementById("next_step_4") as HTMLElement;
    
          let prevbtn2 = document.getElementById("prev_step_2") as HTMLElement;
          let prevbtn3 = document.getElementById("prev_step_3") as HTMLElement;
          let prevbtn4 = document.getElementById("prev_step_4") as HTMLElement;
    
          document.getElementById("prev_step_2").setAttribute("class", "d-none");
          document.getElementById("step3").setAttribute("class", "d-none");
          document.getElementById("step4").setAttribute("class", "d-none");
    
          // nextbtn.onclick = () => {
          //   document.getElementById("step1").setAttribute("class", "d-none");
          //   document.getElementById("step2").setAttribute("class", "d-block");
          // };
    
          nextbtn2.onclick = () => {
            document.getElementById("step2").setAttribute("class", "d-none");
            document.getElementById("step3").setAttribute("class", "d-block");
          };
    
          nextbtn3.onclick = () => {
            document.getElementById("step3").setAttribute("class", "d-none");
            document.getElementById("step4").setAttribute("class", "d-block");
          };
    
          prevbtn2.onclick = () => {
            //document.getElementById("step1").setAttribute("class", "d-block");
            document.getElementById("step2").setAttribute("class", "d-none");
          };
    
          prevbtn3.onclick = () => {
            document.getElementById("step3").setAttribute("class", "d-none");
            document.getElementById("step2").setAttribute("class", "d-block");
          };
    
          prevbtn4.onclick = () => {
            document.getElementById("step4").setAttribute("class", "d-none");
            document.getElementById("step3").setAttribute("class", "d-block");
          };
    
          if (
            sessionStorage.getItem("role_name") == "Super Admin" ||
            sessionStorage.getItem("role_name") == "Project Manager"
          ) {
            document.querySelectorAll(".readonly").forEach(function (element) {
              element.setAttribute("readonly", "true"); // Add the class that you want to add
            });
          }
    
          document.querySelectorAll(".hideonpreview").forEach(function (element) {
            element.setAttribute("class", "d-none"); // Add the class that you want to add
          });   
        }
        // console.log("after conversion");
        // console.log("add question", document.getElementById("addquestion"));
        // document.getElementById("addquestion").onclick = (e) => {
        //   e.preventDefault();
        //   this.addQuestions();
        // };
        // // console.log('btnid',document.getElementById('next_step_1') as HTMLElement);
        // // let nextbtn = document.getElementById("next_step_1") as HTMLElement;
        // let nextbtn2 = document.getElementById("next_step_2") as HTMLElement;
        // let nextbtn3 = document.getElementById("next_step_3") as HTMLElement;
        // let nextbtn4 = document.getElementById("next_step_4") as HTMLElement;
  
        // let prevbtn2 = document.getElementById("prev_step_2") as HTMLElement;
        // let prevbtn3 = document.getElementById("prev_step_3") as HTMLElement;
        // let prevbtn4 = document.getElementById("prev_step_4") as HTMLElement;
  
        // document.getElementById("prev_step_2").setAttribute("class", "d-none");
        // document.getElementById("step3").setAttribute("class", "d-none");
        // document.getElementById("step4").setAttribute("class", "d-none");
  
        // // nextbtn.onclick = () => {
        // //   document.getElementById("step1").setAttribute("class", "d-none");
        // //   document.getElementById("step2").setAttribute("class", "d-block");
        // // };
  
        // nextbtn2.onclick = () => {
        //   document.getElementById("step2").setAttribute("class", "d-none");
        //   document.getElementById("step3").setAttribute("class", "d-block");
        // };
  
        // nextbtn3.onclick = () => {
        //   document.getElementById("step3").setAttribute("class", "d-none");
        //   document.getElementById("step4").setAttribute("class", "d-block");
        // };
  
        // prevbtn2.onclick = () => {
        //   //document.getElementById("step1").setAttribute("class", "d-block");
        //   document.getElementById("step2").setAttribute("class", "d-none");
        // };
  
        // prevbtn3.onclick = () => {
        //   document.getElementById("step3").setAttribute("class", "d-none");
        //   document.getElementById("step2").setAttribute("class", "d-block");
        // };
  
        // prevbtn4.onclick = () => {
        //   document.getElementById("step4").setAttribute("class", "d-none");
        //   document.getElementById("step3").setAttribute("class", "d-block");
        // };
  
        // if (
        //   sessionStorage.getItem("role_name") == "Super Admin" ||
        //   sessionStorage.getItem("role_name") == "Project Manager"
        // ) {
        //   document.querySelectorAll(".readonly").forEach(function (element) {
        //     element.setAttribute("readonly", "true"); // Add the class that you want to add
        //   });
        // }
  
        // document.querySelectorAll(".hideonpreview").forEach(function (element) {
        //   element.setAttribute("class", "d-none"); // Add the class that you want to add
        // });
      });
    // }
 

    // console.log('allmandatory',document.querySelectorAll('mandatory'))
    // document.querySelectorAll(".mandatory").forEach((element,i)=>{
    //   console.log('element_mandatory',element);
    //   document.getElementById(`mandatory_${i}`).onclick = () =>{
    //     console.log('checked');
    //     document.getElementById(`input_${i}`).setAttribute('required','true')
    //   }
    // });

    // console.log('button element',this.button_next.nativeElement);
    // setTimeout(()=>{

    // nextbtn4.onclick = ()=>{
    //   document.getElementById('step4').setAttribute('class','d-none');
    //   document.getElementById('step5').setAttribute('class','d-block');
    // }
    // },1000)

    //this.clickedElement = fromEvent(this.button_next.nativeElement, 'click').subscribe(() => alert('element clicked'));
  }

  focusTagInput(value: any): void {
    if (value == "sectors") {
      this.tagInputRef_sectors.nativeElement.focus();
    } else if (value == "subsectors") {
      this.tagInputRef_subsectors.nativeElement.focus();
    } else if (value == "keywords") {
      this.tagInputRef_keywords.nativeElement.focus();
    } else {
    }
  }

  onKeyUp(event: KeyboardEvent, value: any): void {
    if (value == "sectors") {
      this.tag_s = true;
      this.tag_sb = false;
      this.tag_k = false;
      this.inputValue = this.applicationForm.controls.tag.value;
      document
        .getElementById("sector")
        .setAttribute("class", "removeautofocus");
      // this.tagInputRef_sectors.nativeElement.style.outline = "none !important";
    } else if (value == "subsectors") {
      this.tag_s = false;
      this.tag_sb = true;
      this.tag_k = false;
      this.inputValue = this.applicationForm.controls.businessSubSector.value;
      document
        .getElementById("subsector")
        .setAttribute("class", "removeautofocus");
    } else if (value == "keywords") {
      this.tag_s = false;
      this.tag_sb = false;
      this.tag_k = true;
      this.inputValue = this.applicationForm.controls.keywordsTag.value;
      document
        .getElementById("keyword")
        .setAttribute("class", "removeautofocus");
    }

    if (event.code === "Backspace" && !this.inputValue) {
      this.removeTag();
      return;
    } else {
      if (
        event.code === "Comma" ||
        event.code === "Space" ||
        event.code === "Enter"
      ) {
        console.log("input value", this.inputValue);
        this.addTag(this.inputValue);
        if (this.tag_s) {
          this.applicationForm.controls.tag.setValue("");
        } else if (this.tag_sb) {
          this.applicationForm.controls.businessSubSector.setValue("");
        } else if (this.tag_k) {
          this.applicationForm.controls.keywordsTag.setValue("");
        }
      }
    }
  }

  addTag(tag: string): void {
    if (tag[tag.length - 1] === "," || tag[tag.length - 1] === " ") {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0 && !find(this.tags, tag)) {
      if (this.tag_s) {
        console.log("tags", this.tags_sectors);
        this.tags_sectors.push(tag);
      } else if (this.tag_sb) {
        this.tags_subsectors.push(tag);
        console.log("tags_sub", this.tags_subsectors);
      } else if (this.tag_k) {
        this.tags_keywords.push(tag);
      } else {
      }
      // this.tags.push(tag);
    }
  }

  removeTag(tag?: string, value?: any): void {
    if (!!tag) {
      console.log("1");
      if (value == "sectors") {
        pull(this.tags_sectors, tag);
      } else if (value == "subsectors") {
        pull(this.tags_subsectors, tag);
      } else if (value == "keywords") {
        pull(this.tags_keywords, tag);
      }
    } else {
      if (value == "sectors") {
        this.tags_sectors.splice(-1);
      } else if (value == "subsectors") {
        this.tags_subsectors.splice(-1);
      } else if (value == "keywords") {
        this.tags_keywords.splice(-1);
      }
    }
  }

  changeEditorConfig() {
    this.Editor = true;
  }
  get editors(): FormArray {
    return this.editorsForm.get("editors") as FormArray;
  }
  get menus(): FormArray {
    return this.editorsForm.get("menus") as FormArray;
  }
  get addFields(): FormArray {
    return this.editorsForm.get("addFields") as FormArray;
  }

  addEditor() {
    document.getElementById('next_step_4').setAttribute('disabled','true');
    console.log('hello from array');
    const editor = this.fb.control("");
    this.editors.push(editor);
    const menu = this.fb.control("");
    this.menus.push(menu);
    
    // var data = document.querySelectorAll(".mandatory");
    // console.log("data", data);
    // document.querySelectorAll(".mandatory").forEach(function (element, i) {
    //   console.log("dom", element);
    //   element.setAttribute("id", `mandatory_${i + 1}`); // Add the class that you want to add
    // });

    setTimeout(()=>{
      console.log('selectfieldclass',document.querySelectorAll('.selectfield'));
      document.querySelectorAll('.selectfield').forEach((element,i)=>{
        console.log('selectelement',element);
        element.setAttribute('id',`selectfield_${i}`);
      })

    // var selectfield = document.getElementsByClassName('selectfield');
    // console.log('selectfield_class',selectfield);

    // console.log('form_____',document.querySelectorAll('.form'));
    
    },500)

  }

  observeElement(){
    var elementToObserve = document.querySelector("mandatory_0");
    if(elementToObserve ! = null){
      const observer = new MutationObserver(() => {
        console.log("callback that runs when observer is triggered");
      });
      
      // call `observe()` on that MutationObserver instance,
      // passing it the element to observe, and the options object
      observer.observe(elementToObserve, { subtree: true, childList: true });
    }

  }
  addQuestions() {
    let parent = document.getElementById("addhere") as HTMLElement;
    parent.setAttribute('class','w-100');
    console.log("parent", parent);
    //parent.className+=" columnflex";
    let innerhtml = `
    <input type="text" name="" id="" class="form-control my-2 label_question" placeholder="enter question">
    <label class="addlabel"></label>
    <div class="w-100">
    <select class="float-right selectfield">
      <option value="">Select</option>
      <option value="textarea">Long Answer</option>
      <option value="text">Sort Answer</option>
      <option value="radio">Radio</option>
      <option value="checkbox">Checkbox</option>
      <option value="file">File Upload</option>
      <option value="select">Drop Down</option>
      <option value="number">Enter Number</option>
      <option value="date">Select Date</option>
    </select>
    </div>
    <div class="placeanswer"></div>
    `;
    $("#addhere").append(innerhtml);

    // document.querySelectorAll('.label_question').forEach((element,i)=>{
    //   element.setAttribute('id',`label_question_${i}`);
    //   console.log('getelementbyid',document.getElementById(`label_question_${i}`));
    //   document.getElementById(`label_question_${i}`).onkeyup = async($event) => {
    //     if((<HTMLInputElement>document.getElementById(`label_question_${i}`)).value == ""){
    //       console.log('no value');
    //     }
    //   }
    // })

    document.querySelectorAll(".selectfield").forEach((element, i) => {
      console.log("selectindex", i);
      console.log("element", element);
     

      element.addEventListener("change", ($event: any) => {
        console.log("hello");
        document.getElementsByClassName("placeanswer")[i].setAttribute("id", `placeanswer_${i}`);
          this.test($event, i);
      }); 
    });
    
    async function addIdtoLabel(){
      return new Promise((resolve,reject)=>{
        document.querySelectorAll('.addlabel').forEach((element,i)=>{
          element.setAttribute('id',`addlabel_${i}`);
          resolve(element);
        })
      })
    }

    document.querySelectorAll('.label_question').forEach((element,i)=>{
      element.setAttribute('id',`label_question_${i}`);
      console.log('getelementbyid',document.getElementById(`label_question_${i}`));
      document.getElementById(`label_question_${i}`).onkeyup = async($event) => {
        await addIdtoLabel();
        await this.getlabelforpreview($event);
        console.log('add label');
        console.log('addlabel123454565',`addlabel_${i}`,document.getElementById(`addlabel_${i}`));
        document.getElementById(`addlabel_${i}`).innerHTML = this.label;
        // let innerHTML = `<label>${this.label}</label>`;
        // console.log('label_____',innerHTML);
        // console.log('parentrefer_______',document.getElementById(`label_question_${i}`));
        // document.getElementById(`label_question_${i}`).innerHTML = innerHTML;
      }
    })


  }


  test(event: any, i: number) {
    console.log("from test");
    console.log("label", this.label);
    this.parent = document.getElementById(
      `placeanswer_${i}`
    ) as HTMLElement;
    console.log("parent123", this.parent);

    // if (event.target.value != "") {
    
    //   console.log("event_target", event.target.value);

    //   if (event.target.value != "select") {
    //     if (event.target.value != "textarea") {
    //       let innerHTML = `
    //       <input type="${event.target.value}" name="" id="" class="form-control readonly">
          
    //       `;

    //       this.parent.innerHTML = innerHTML;
    //     } else {
    //       let innerHTML = `<textarea name="" id="" cols="30" rows="10" class="form-control addck" placeholder="enter answer"></textarea>`;
    //       this.parent.innerHTML = innerHTML;
      
    //     }
    //   } else {
    //     var dropDown = document.createElement("select");
    //     dropDown.setAttribute("class", "form-control");
    //     var option = document.createElement("option");
    //     option.value = "test";
    //     option.text = "test";

    //     dropDown.add(option);
    //     console.log("parent3", this.parent);
    //     this.parent.appendChild(dropDown);
    //   }
    // }

    if (event.target.value != "") {
      let childnode_label = document.createElement("label");
      childnode_label.innerHTML = this.label;
      console.log('parent',this.parent);
      this.parent.appendChild(childnode_label);
      if (event.target.value != "select") {
        if (event.target.value != "textarea") {
          if(event.target.value == 'radio'|| event.target.value == 'checkbox'){
            var index = i;
            let innerHTML = `
            <div id="divradio_test_${i}" class="d-flex">
            
            </div>
            <div id="placeinputhere_test_${i}"></div>
            <input type="checkbox" id="mandatory_test_${i}" class="mandatory_test" value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
            <button class="btn btn-primary addmoreradio_test" id="addmoreradio_test_${i}">+</button>
            `;
      this.parent.innerHTML = innerHTML;
          }
          else {
            let innerHTML = `

            <input type="${event.target.value}" id="input_test_${i}" name="" clasthis.parent-control">
            <input type="checkbox" id="mandatory_test_${i}" class="mandatory_test"  value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
            <button class="btn btn-primary addmoreradio_test d-none" id="addmoreradio_test_${i}">+</button>
            `;
            this.parent.innerHTML = innerHTML;
          }

          document.querySelectorAll(".mandatory_test").forEach((element,i) => {
            console.log('mandatory',document.getElementById(`mandatory_test_${i}`));
            if(document.getElementById(`mandatory_test_${i}`)!=null){
              document.getElementById(`mandatory_test_${i}`).onclick = async($event) => {
                console.log('clicked on mandatory');
                await this.getchecked($event);
                console.log('mandatory_id',`mandatory_test_${i}`);
                if(this.checked == true){
                  document.getElementById(`input_test_${i}`).setAttribute('required','true');
                }
                else if(this.checked == false){
                  document.getElementById(`input_test_${i}`).setAttribute('required','false');
                }
                console.log('input_id',document.getElementById(`input_test_${i}`));
              }
            }
   
          });

          document.querySelectorAll(".addmoreradio_test").forEach((element,i)=>{
            let count = 2
            console.log('radioelement',element);
            
            document.getElementById(`addmoreradio_test_${i}`).onclick = (e) => {
              e.preventDefault();
              console.log('addmoreradio',`addmoreradio_test_${i}`);
              let innerHTML = `
              <input type="text" placeholder="add option" class="my-2" id="inputoption_test_${i}">
              <button class="btn btn-primary submitoption_test d-none" id="submitoption_test_${i}">Submit</button>
              `;
              
              document.getElementById(`divradio_test_${i}`).innerHTML= innerHTML;
              console.log('inputid',`inputoption_test_${i}`);
                      
              console.log('doc_id',document.getElementById(`inputoption_test_${i}`))
        
              if(document.getElementById(`inputoption_test_${i}`)!=null){
                document.getElementById(`inputoption_test_${i}`).onkeyup= (event)=>{
                  let value = (<HTMLInputElement>document.getElementById(`inputoption_test_${i}`)).value;
                  let value_trim = value.replace(/ /g,'');
                  console.log('value1234',value);
                  if(value_trim != ""){
                    document.getElementById(`submitoption_test_${i}`).classList.remove('d-none');
                    document.getElementById(`submitoption_test_${i}`).className+=' d-block';
                  }
                  else{
                    document.getElementById(`submitoption_test_${i}`).classList.remove('d-block');
                    document.getElementById(`submitoption_test_${i}`).className+=' d-none';
                  }
                }
              }
              console.log('test_________1234',document.getElementById(`divradio_test_${i}`));
              document.getElementById(`submitoption_test_${i}`).onclick = (e) => {
                e.preventDefault();
                console.log('submitoption',`submitoption_test_${i}`);
                let span = document.createElement("span");
                let childnode_radio = document.createElement("input");
                let input = (<HTMLInputElement>document.getElementById(`inputoption_test_${i}`));
                if(input.value!=''){
                  span.innerText = input.value;
                  childnode_radio.setAttribute("type", event.target.value);
                  document.getElementById(`placeinputhere_test_${i}`).appendChild(span);
                  document.getElementById(`placeinputhere_test_${i}`).appendChild(childnode_radio);
                  input.value = '';
                  document.getElementById(`inputoption_test_${i}`).setAttribute('class','d-none');
                  document.getElementById(`submitoption_test_${i}`).setAttribute('class','d-none');
                }
              }

              
            }
          })


  
        } else {
          let innerHTML = `
          <textarea class="form-control" id="input_${i}"></textarea>
          <input type="checkbox" id="mandatory_test_${i}" class="mandatory_test" value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
          <button class="btn btn-primary addmoreradio_test d-none" id="addmoreradio_test_${i}">+</button>
          `
          this.parent.innerHTML = innerHTML;

          document.querySelectorAll(".mandatorytest").forEach((element,i) => {
            document.getElementById(`mandatory_test_${i}`).onclick = async($event) => {
              await this.getchecked($event);
              console.log('mandatory_id',`mandatory_test_${i}`);
              if(this.checked == true){
                document.getElementById(`input_test_${i}`).setAttribute('required','true');
              }
              else if(this.checked == false){
                document.getElementById(`input_test_${i}`).setAttribute('required','false');
              }
              console.log('input_id',document.getElementById(`input_test_${i}`));
            }
          });

        }
      } else {
        let innerHTML = `
          <select class="form-control" id="input_test_${i}">
            
          </select>
          <input type="checkbox" id="mandatory_test_${i}" class="mandatory+_test" value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
          <button class="btn btn-primary addmoreoption_test" id="addmoreoption_test_${i}">+Add</button>
        `
          this.parent.innerHTML = innerHTML;

          document.querySelectorAll(".mandatory_test").forEach((element,i) => {
            if(document.getElementById(`mandatory_test_${i}`)!=null){
              document.getElementById(`mandatory_test_${i}`).onclick = async($event) => {
                await this.getchecked($event);
                console.log('mandatory_id',`mandatory_test_${i}`);
                if(this.checked == true){
                  document.getElementById(`input_test_${i}`).setAttribute('required','true');
                }
                else if(this.checked == false){
                  document.getElementById(`input_test_${i}`).setAttribute('required','false');
                }
                console.log('input_id',document.getElementById(`input_test_${i}`));
              }
            }
  
          });

          document.querySelectorAll(".addmoreoption_test").forEach((element,i)=>{
            let count = 2
            console.log('radioelement',element);
            if(document.getElementById(`addmoreoption_test`)!=null){
            document.getElementById(`addmoreoption_test_${i}`).onclick = () => {
              
              console.log('addmoreoption',`addmoreoption_test_${i}`);
              let option = document.createElement("option");
              option.text = `option ${count++}`;
              option.value = `option ${count++}`
   
              document.getElementById(`input_test_${i}`).appendChild(option);
              
            }
          }
          })

      }
    }
  }

  addInput_m(i:any,event:any){
    this.addInput(i,event);
    // console.log('index_____',i);
    // console.log('event_target',event.target.value);
    // if(event.target.value == "checkbox" || event.target.value == "radio"){
    //   this.addInput_options(i,event);
    // }
    // else{
    //   this.addInput(i,event);
    // }
  }


  
  addInput(i: any, event: any) {
    
    console.log("label", this.label);
    var form = document.getElementById(`answer${i}`) as HTMLElement;
    console.log('form_id_________',form);
    if (event.target.value != "") {
      // let child_label = `<label id="label_${i}" class="questionslabel">${this.label}</label>`
      let childnode_label = document.createElement("label");
      childnode_label.innerHTML = this.label;
      form.appendChild(childnode_label);
      console.log('questionlabel',document.getElementById(`answer_${i}`))
      if (event.target.value != "select") {
        if (event.target.value != "textarea") {
          if(event.target.value!='checkbox' && event.target.value!='radio'){
            this.selectedinput = 'shortanswer';
          }
          if(event.target.value == 'checkbox' || event.target.value == 'radio'){
            this.selectedinput = "radio"
            let innerHTML1 = `
           
            <div id="placeinputhere_${i}" class="placeinputhere">
            <span class="text-danger ml-1 font-weight-bold float-right showlabel d-none" id="showlabel_${i}">*</span>
            </div>
            <input type="checkbox" id="mandatory_${i}" class="mandatory" value="mandatory"><span class="mandatorytext" id="mandatorytext_${i}">This Field is Mandatory</span>
            <div class="mt-3">
            <button class="btn btn-info addmoreradio" id="addmoreradio_${i}">+ Add Options</button>
            <div id="divradio_${i}" class="d-flex mt-3">
    
            </div>
            </div>
            `;

            form.innerHTML = innerHTML1;

            document.querySelectorAll(".addmoreradio").forEach((element,i)=>{
              let count = 2
              console.log('radioelement__________',element);
              console.log('radioindex',this.longindex);
              console.log('selectedinput',this.selectedinput);
              if(this.selectedinput == 'longanswer'){
                console.log('null check',document.getElementById(`addmoreradio_${this.longindex}`));
                if(document.getElementById(`addmoreradio_${this.longindex}`)!=null){
                  document.getElementById(`addmoreradio_${this.longindex}`).onclick = () => {
                    console.log('addmoreradio123456',`addmoreradio_${this.longindex}`);
                    let innerHTML = `
                    <div class="d-flex justify-content-between align-items-center w-100 form-control border-input optionsdiv border p-1" id="optionsdiv_${this.longindex}">
                    <input type="text" placeholder="add option" class="optionvalue bg-transparent border-0 w-100 my-2" id="input_${this.longindex}">
                    <i class="fa fa-check submitoption text-success d-none" id="submitoption_${this.longindex}" aria-hidden="true"></i>
                    </div>
                    `;
                    document.getElementById(`divradio_${this.longindex}`).innerHTML= innerHTML;
                    console.log('here long');
                    if(document.getElementById(`input_${this.longindex}`)!=null){
                      document.getElementById(`input_${this.longindex}`).onkeyup= (event)=>{
                        let value = (<HTMLInputElement>document.getElementById(`input_${this.longindex}`)).value;
                        let value_trim = value.replace(/ /g,'');
                        console.log('value1234',value);
                        if(value_trim != ""){
                          document.getElementById(`submitoption_${this.longindex}`).classList.remove('d-none');
                          document.getElementById(`submitoption_${this.longindex}`).className+=' d-block';
                        }
                        else{
                          document.getElementById(`submitoption_${this.longindex}`).classList.remove('d-block');
                          document.getElementById(`submitoption_${this.longindex}`).className+=' d-none';
                        }
                      }
                    }
                    console.log('test_________1234',document.getElementById(`divradio_${this.longindex}`));
                    document.getElementById(`submitoption_${this.longindex}`).onclick = () => {
                      console.log('submitoption',`submitoption_${this.longindex}`);
                      let span = document.createElement("span");
                      span.setAttribute('class','customspan');
                      let childnode_radio = document.createElement("input");
                      let breaktag = document.createElement('br');
                      childnode_radio.setAttribute('class',`customradio_${this.longindex}`);
                      childnode_radio.setAttribute('id',`customradio_${this.longindex}`);
                      childnode_radio.setAttribute('name',this.label);
                      childnode_radio.setAttribute('data-disable',`disable_${this.longindex}`);
                      let input = (<HTMLInputElement>document.getElementById(`input_${this.longindex}`));
                      if(input.value!=''){
                        childnode_radio.setAttribute('value',input.value);
                        span.innerText = input.value;
                        childnode_radio.setAttribute("type", event.target.value);
                        document.getElementById(`placeinputhere_${this.longindex}`).appendChild(childnode_radio);
                        document.getElementById(`placeinputhere_${this.longindex}`).appendChild(span);
                        document.getElementById(`placeinputhere_${this.longindex}`).appendChild(breaktag);
                        document.getElementById(`placeinputhere_${this.longindex}`).appendChild(breaktag);
                        let disabled = document.querySelectorAll('.customradio');
                        for(var k = 0 ; k < disabled.length ; k++){
                          disabled[k].setAttribute('disabled','true')
                        }
                        console.log('disabled',disabled);
                        // document.querySelector(`[data-disable=disable_${this.longindex}]`).setAttribute('disabled','true');
                        input.value = '';
                        document.getElementById(`optionsdiv_${this.longindex}`).setAttribute('class','d-none');
                        document.getElementById(`inputoption_${this.longindex}`).setAttribute('class','d-none');
                        document.getElementById(`submitoption_${this.longindex}`).setAttribute('class','d-none');
                      }
                    }     
                  }
                }
              }
              else if(this.selectedinput == 'shortanswer'){
                console.log('index____short',i+1);
                console.log('addmoreradio_short',`addmoreradio_${i+1}`);
                console.log('null check short',document.getElementById(`addmoreradio_${i+1}`));
                if(document.getElementById(`addmoreradio_${i+1}`)!=null){
                  document.getElementById(`addmoreradio_${i+1}`).onclick = () => {
                    console.log('addmoreradio12345678',`addmoreradio_${i+1}`);
                    let innerHTML = `
                    <div class="d-flex justify-content-between align-items-center w-100 form-control border-input optionsdiv border p-1" id="optionsdiv_${i+1}">
                    <input type="text" placeholder="add option" class="optionvalue bg-transparent border-0 w-100 my-2" id="input_${i+1}">
                    <i class="fa fa-check submitoption text-success" id="submitoption_${i+1}" aria-hidden="true"></i>
                    </div>
                    `;
                    document.getElementById(`divradio_${i+1}`).innerHTML= innerHTML;
                    
                    console.log('test_________1234',document.getElementById(`divradio_${i+1}`));
                    document.getElementById(`submitoption_${i+1}`).onclick = () => {
                      console.log('submitoption',`submitoption_${i+1}`);
                      let span = document.createElement("span");
                      span.setAttribute('class','customspan');
                      let childnode_radio = document.createElement("input");
                      let breaktag = document.createElement('br');
                      childnode_radio.setAttribute('class',`customradio_${i+1}`);
                      childnode_radio.setAttribute('id',`customradio_${i+1}`);
                      childnode_radio.setAttribute('data-disable',`disable_${i+1}`);
                      childnode_radio.setAttribute('name',this.label);
                      let input = (<HTMLInputElement>document.getElementById(`input_${i+1}`));
                      if(input.value!=''){
                        childnode_radio.setAttribute('value',input.value);
                        span.innerText = input.value;
                        childnode_radio.setAttribute("type", event.target.value);
                        document.getElementById(`placeinputhere_${i+1}`).appendChild(childnode_radio);
                        document.getElementById(`placeinputhere_${i+1}`).appendChild(span);
                        document.getElementById(`placeinputhere_${i+1}`).appendChild(breaktag);
                        document.getElementById(`placeinputhere_${i+1}`).appendChild(breaktag);
                        let disabled = document.querySelectorAll('.customradio');
                        console.log('disabled___',disabled);
                        for(var k = 0 ; k < disabled.length ; k++){
                          disabled[k].setAttribute('disabled','true')
                        }
                        // document.querySelector(`[data-disable=disable_${i+1}]`).setAttribute('disabled','true');
                        input.value = '';
                        document.getElementById(`optionsdiv_${i+1}`).setAttribute('class','d-none');
                        document.getElementById(`inputoption_${i+1}`).setAttribute('class','d-none');
                        document.getElementById(`submitoption_${i+1}`).setAttribute('class','d-none');
                      }
                    }     
                  }
                }
              }
              else if(this.selectedinput == 'radio'){
                console.log('index____short',i+1);
                console.log('addmoreradio_short',`addmoreradio_${i+1}`);
                console.log('null check short___radio',document.getElementById(`addmoreradio_${i+1}`));
                if(i==0 && document.getElementById(`addmoreradio_${i+1}`)==null){
                  if(document.getElementById(`addmoreradio_${i}`)!=null){
                    // async function addinnerhtml_options(){
                    //   return new Promise((resolve,reject)=>{
                    //     document.getElementById(`addmoreradio_${i}`).onclick = ()=>{
                          
                    //     }
                    //   })
                    // }
                    document.getElementById(`addmoreradio_${i}`).onclick = () => {
                      console.log('addmoreradio123456789',`addmoreradio_${i}`);
                      let innerHTML = `
                      
                      <div class="d-flex justify-content-between align-items-center w-100 form-control border-input optionsdiv border p-1" id="optionsdiv_${i}">
                      
                      <input type="text" placeholder="add option" class="optionvalue bg-transparent border-0 w-100 my-2" id="input_${i}">
                      
                      <i class="fa fa-check submitoption text-success d-none" id="submitoption_${i}" aria-hidden="true"></i>
                      </div>
                      <span class="text-danger ml-1 font-weight-bold float-right showlabel d-none" id="showlabel_${i}">*</span>
                      `;
           
                 
                      document.getElementById(`divradio_${i}`).innerHTML= innerHTML;
                      console.log('inputid',`input_${i}`);
                      
                      console.log('doc_id',document.getElementById(`input_${i}`))
                
                      if(document.getElementById(`input_${i}`)!=null){
                        document.getElementById(`input_${i}`).onkeyup= (event)=>{
                          let value = (<HTMLInputElement>document.getElementById(`input_${i}`)).value;
                          let value_trim = value.replace(/ /g,'');
                          console.log('value1234',value);
                          if(value_trim != ""){
                            document.getElementById(`submitoption_${i}`).classList.remove('d-none');
                            document.getElementById(`submitoption_${i}`).className+=' d-block';
                          }
                          else{
                            document.getElementById(`submitoption_${i}`).classList.remove('d-block');
                            document.getElementById(`submitoption_${i}`).className+=' d-none';
                          }
                        }
                      }
                      console.log('test_________1234',document.getElementById(`divradio_${i}`));
                      document.getElementById(`submitoption_${i}`).onclick = () => {
                        console.log('submitoption',`submitoption_${i}`);
                        let span = document.createElement("span");
                        span.setAttribute('class','customspan');
                        let childnode_radio = document.createElement("input");
                        let breaktag = document.createElement('br');
                        childnode_radio.setAttribute('class',`customradio_${i}`);
                        childnode_radio.setAttribute('id',`customradio_${i}`);
                        childnode_radio.setAttribute('data-disable',`disable_${i}`);
                        childnode_radio.setAttribute('name',this.label);
                        let input = (<HTMLInputElement>document.getElementById(`input_${i}`));
                        if(input.value!=''){
                          childnode_radio.setAttribute('value',input.value);
                          span.innerText = input.value;
                          childnode_radio.setAttribute("type", event.target.value);
                          document.getElementById(`placeinputhere_${i}`).appendChild(childnode_radio);
                          document.getElementById(`placeinputhere_${i}`).appendChild(span);
                          document.getElementById(`placeinputhere_${i}`).appendChild(breaktag);
                          document.getElementById(`placeinputhere_${i}`).appendChild(breaktag);
                          let disabled = document.querySelectorAll('.customradio');
                          for(var k = 0 ; k < disabled.length ; k++){
                            disabled[k].setAttribute('disabled','true')
                          }
                          console.log('disabled',disabled);
                          // document.querySelector(`[data-disable=disable_${i}]`).setAttribute('disabled','true');
                          input.value = '';
                          document.getElementById(`optionsdiv_${i}`).setAttribute('class','d-none');
                          document.getElementById(`inputoption_${i}`).setAttribute('class','d-none');
                          document.getElementById(`submitoption_${i}`).setAttribute('class','d-none');
                        }
                      }     
                    }
                  }
                  document.querySelectorAll(".mandatory").forEach((element,i) => {
                    if(document.getElementById(`mandatory_${i}`)!=null){
                      document.getElementById(`mandatory_${i}`).onclick = async($event) => {
                        await this.getchecked($event);
                        console.log('mandatory_id',`mandatory_${i}`);
                        if(this.checked == true){
                          document.getElementById(`customradio_${i}`).setAttribute('required','true');
                          document.querySelectorAll(`.customradio_${i}`).forEach((element,i)=>{
                            element.className+=' requiredqa';
                        })
                          // document.getElementById(`customradio_${i}`).className+=' requiredqa';
                          document.getElementById(`showlabel_${i}`).classList.remove('d-none');
                        }
                        else if(this.checked == false){
                          document.getElementById(`customradio_${i}`).setAttribute('required','false');
                          document.querySelectorAll(`.customradio_${i}`).forEach((element,i)=>{
                            element.classList.remove('requiredqa');
                          })
                          // document.getElementById(`customradio_${i}`).classList.remove('requiredqa');
                          document.getElementById(`showlabel_${i}`).className+=' d-none'
                        }
                        console.log('customradio_id',document.getElementById(`customradio_${i}`));
                      }
                    }
           
                  });
                }
                else{
                  if(document.getElementById(`addmoreradio_${i+1}`)!=null){
                    document.getElementById(`addmoreradio_${i+1}`).onclick = () => {
                      console.log('addmoreradio0123456789',`addmoreradio_${i+1}`);
                      let innerHTML = `
                      
                      <div class="d-flex justify-content-between align-items-center w-100 form-control border-input optionsdiv border p-1" id="optionsdiv_${i+1}">
                      
                      <input type="text" placeholder="add option" class="optionvalue bg-transparent border-0 w-100 my-2" id="input_${i+1}">
                      <i class="fa fa-check submitoption text-success d-none" id="submitoption_${i+1}" aria-hidden="true"></i>
                      </div>
                      <span class="text-danger ml-1 font-weight-bold float-right showlabel d-none" id="showlabel_${i+1}">*</span>
                      `;
                      document.getElementById(`divradio_${i+1}`).innerHTML= innerHTML;
                      if(document.getElementById(`input_${i+1}`)!=null){
                        document.getElementById(`input_${i+1}`).onkeyup= (event)=>{
                          let value = (<HTMLInputElement>document.getElementById(`input_${i+1}`)).value;
                          let value_trim = value.replace(/ /g,'');
                          console.log('value1234',value);
                          if(value_trim != ""){
                            document.getElementById(`submitoption_${i+1}`).classList.remove('d-none');
                            document.getElementById(`submitoption_${i+1}`).className+=' d-block';
                          }
                          else{
                            document.getElementById(`submitoption_${i+1}`).classList.remove('d-block');
                            document.getElementById(`submitoption_${i+1}`).className+=' d-none';
                          }
                        }
                      }
                      console.log('test_________1234',document.getElementById(`divradio_${i+1}`));
                      document.getElementById(`submitoption_${i+1}`).onclick = () => {
                        console.log('submitoption',`submitoption_${i+1}`);
                        let span = document.createElement("span");
                        span.setAttribute('class','customspan');
                        let childnode_radio = document.createElement("input");
                        let breaktag = document.createElement('br');
                        childnode_radio.setAttribute('class',`customradio_${i+1}`);
                        childnode_radio.setAttribute('id',`customradio_${i+1}`);
                        childnode_radio.setAttribute('data-disable',`disable_${i+1}`);
                        childnode_radio.setAttribute('name',this.label);
                        let input = (<HTMLInputElement>document.getElementById(`input_${i+1}`));
                        if(input.value!=''){
                          childnode_radio.setAttribute('value',input.value);
                          span.innerText = input.value;
                          childnode_radio.setAttribute("type", event.target.value);
                          document.getElementById(`placeinputhere_${i+1}`).appendChild(childnode_radio);
                          document.getElementById(`placeinputhere_${i+1}`).appendChild(span);
                          document.getElementById(`placeinputhere_${i+1}`).appendChild(breaktag);
                          document.getElementById(`placeinputhere_${i+1}`).appendChild(breaktag);
                          let disabled = document.querySelectorAll('.customradio');
                          for(var k = 0 ; k < disabled.length ; k++){
                            disabled[k].setAttribute('disabled','true')
                          }
                          console.log('disabled',disabled);
                          // document.querySelector(`[data-disable=disable_${i+1}]`).setAttribute('disabled','true');
                          input.value = '';
                          document.getElementById(`optionsdiv_${i+1}`).setAttribute('class','d-none');
                          document.getElementById(`inputoption_${i+1}`).setAttribute('class','d-none');
                          document.getElementById(`submitoption_${i+1}`).setAttribute('class','d-none');
                        }
                      }     
                    }
                  }
                  document.querySelectorAll(".mandatory").forEach((element,i) => {
                    if(document.getElementById(`mandatory_${i+1}`)!=null){
                      document.getElementById(`mandatory_${i+1}`).onclick = async($event) => {
                        await this.getchecked($event);
                        console.log('mandatory_id',`mandatory_${i+1}`);
                        if(this.checked == true){
                          document.getElementById(`customradio_${i+1}`).setAttribute('required','true');
                          document.querySelectorAll(`.customradio_${i+1}`).forEach((element,i)=>{
                            element.className+=' requiredqa';
                          })
                          // document.getElementById(`customradio_${i+1}`).className+=' requiredqa';
                          document.getElementById(`showlabel_${i+1}`).classList.remove('d-none');
                        }
                        else if(this.checked == false){
                          document.getElementById(`customradio_${i+1}`).setAttribute('required','false');
                          document.querySelectorAll(`.customradio_${i+1}`).forEach((element,i)=>{
                            element.classList.remove('requiredqa');
                          })
                          // document.getElementById(`customradio_${i+1}`).classList.remove('requiredqa');
                          document.getElementById(`showlabel_${i+1}`).className+=' d-none'
                        }
                        console.log('customradio_id',document.getElementById(`customradio_${i+1}`));
                      }
                    }
           
                  });
                }
      
              }
              console.log('addmoreoptionid',`addmoreradio_${i}`,document.getElementById(`addmoreradio_${i}`));

        
            })

            //place here
            console.log('radio___2017')
          }

          else{
            let innerHTML = `
            <span class="text-danger ml-1 font-weight-bold float-right showlabel d-none" id="showlabel_${i}">*</span>
            <input type="${event.target.value}" id="input_${i}" readonly name="${this.label}" class="form-control input_${i}">
            <input type="checkbox" id="mandatory_${i}" class="mandatory show"  value="mandatory"><span id="mandatorytext_${i}" class="mandatorytext show">This Field is Mandatory</span>
            <button class="btn btn-primary addmoreradio d-none" id="addmoreradio_${i}">+</button>
            
            `;
            form.innerHTML = innerHTML;
            console.log('short answer_2017');
            document.querySelectorAll(".mandatory").forEach((element,i) => {
              console.log('checkmandatorynull',document.getElementById(`mandatory_${i}`))
              if(document.getElementById(`mandatory_${i}`)!=null){
                document.getElementById(`mandatory_${i}`).onclick = async($event) => {
                  await this.getchecked($event);
                  console.log('mandatory_id',`mandatory_${i}`);
                  if(this.checked == true){
                    document.getElementById(`input_${i}`).setAttribute('required','true');
                    document.querySelectorAll(`.input_${i}`).forEach((element,i)=>{
                      element.className+=' requiredqa';
                    })
                    // document.getElementById(`input_${i}`).className+=' requiredqa';
                    document.getElementById(`showlabel_${i}`).classList.remove('d-none');
                  }
                  else if(this.checked == false){
                    document.getElementById(`input_${i}`).setAttribute('required','false');
                    document.querySelectorAll(`.input_${i}`).forEach((element,i)=>{
                      element.classList.remove('requiredqa');
                    })
                    // document.getElementById(`input_${i}`).classList.remove('requiredqa');
                    document.getElementById(`showlabel_${i}`).className += ' d-none';
                  }
                  console.log('input_id',document.getElementById(`input_${i}`));
                }
              }
     
            });
          }

 
            console.log('enable next');
            console.log('next_step_4',document.getElementById('next_step_4'));
            document.getElementById('next_step_4').removeAttribute('disabled');

          // }

          document.querySelectorAll('.hideonpreview').forEach((element,i)=>{
            console.log('hideonpreview',element);
          })

          // document.querySelectorAll(".mandatory").forEach((element,i) => {
          //   if(document.getElementById(`mandatory_${i}`)!=null){
          //     document.getElementById(`mandatory_${i}`).onclick = async($event) => {
          //       await this.getchecked($event);
          //       console.log('mandatory_id',`mandatory_${i}`);
          //       if(this.checked == true){
          //         document.getElementById(`input_${i}`).setAttribute('required','true');
          //         document.getElementById(`input_${i}`).className+=' requiredqa';
          //       }
          //       else if(this.checked == false){
          //         document.getElementById(`input_${i}`).setAttribute('required','false');
          //         document.getElementById(`input_${i}`).classList.remove('requiredqa');
          //       }
          //       console.log('input_id',document.getElementById(`input_${i}`));
          //     }
          //   }
   
          // });
  
        } 
        else {
          this.longindex = 0;
          this.selectedinput = "longanswer";
          let innerHTML = `
          <span class="text-danger ml-1 font-weight-bold float-right showlabel d-none" id="showlabel_${i}">*</span>
          <textarea class="form-control" id="input_${i}" readonly name="${this.label}"></textarea>
          <input type="checkbox" id="mandatory_${i}" class="mandatory" value="mandatory"><span id="mandatorytext_${i}" class="mandatorytext show">This Field is Mandatory</span>
          `
          form.innerHTML = innerHTML;

          this.longindex = i + 1;

          document.getElementById('next_step_4').removeAttribute('disabled');

          document.querySelectorAll(".mandatory").forEach((element,i) => {
            document.getElementById(`mandatory_${i}`).onclick = async($event) => {
              await this.getchecked($event);
              console.log('mandatory_id',`mandatory_${i}`);
              if(this.checked == true){
                document.getElementById(`input_${i}`).setAttribute('required','true');
                document.getElementById(`input_${i}`).className+=' requiredqa';
                document.getElementById(`showlabel_${i}`).classList.remove('d-none');
              }
              else if(this.checked == false){
                document.getElementById(`input_${i}`).setAttribute('required','false');
                document.getElementById(`input_${i}`).classList.remove('requiredqa');
                document.getElementById(`showlabel_${i}`).className+=' d-none';
              }
              console.log('input_id',document.getElementById(`input_${i}`));
            }
          });

        }
      } else {
        let innerHTML = `
          <select class="form-control" id="input_${i}">
            
          </select>
          <input type="checkbox" id="mandatory_${i}" class="mandatory" value="mandatory"><span class="mandatorytext show" id="mandatorytext_${i}">This Field is Mandatory</span>
          <button class="btn btn-primary addmoreoption" id="addmoreoption_${i}">+Add</button>
        `
          form.innerHTML = innerHTML;

          document.querySelectorAll(".mandatory").forEach((element,i) => {
            if(document.getElementById(`mandatory_${i}`)!=null){
              document.getElementById(`mandatory_${i}`).onclick = async($event) => {
                await this.getchecked($event);
                console.log('mandatory_id',`mandatory_${i}`);
                if(this.checked == true){
                  document.getElementById(`input_${i}`).setAttribute('required','true');
                }
                else if(this.checked == false){
                  document.getElementById(`input_${i}`).setAttribute('required','false');
                }
                console.log('input_id',document.getElementById(`input_${i}`));
              }
            }
  
          });

          document.querySelectorAll(".addmoreoption").forEach((element,i)=>{
            let count = 2
            console.log('radioelement',element);
            if(document.getElementById(`addmoreoption`)!=null){
            document.getElementById(`addmoreoption_${i}`).onclick = () => {
              
              console.log('addmoreoption',`addmoreoption_${i}`);
              let option = document.createElement("option");
              option.text = `option ${count++}`;
              option.value = `option ${count++}`
   
              document.getElementById(`input_${i}`).appendChild(option);
            
            }
          }
          })

      }
    }
  }

  removeEditor(index: number) {
    this.editors.removeAt(index);
    this.menus.removeAt(index);
    this.addFields.removeAt(index);
  }

  async getchecked(event){
    console.log('event____',event.target.checked);
    return new Promise((resolve,reject)=>{
      this.checked = event.target.checked;
      resolve(this.checked);
    })

  }
  addmandatory(){
    console.log('add mandatory');
  }
  
  getlabel(event: any, i:any) {
    this.label = event.target.value.trimStart();
    console.log('label',this.label);
    if(this.label == ""){
      console.log('labelif');
      document.getElementById(`selectfield_${i}`).setAttribute('disabled','true');
    }
    else{
      console.log('labelelse');
      document.getElementById(`selectfield_${i}`).removeAttribute('disabled');
    }
    
    document.getElementById(`label${i}`).innerHTML = this.label;
    document.getElementById(`label${i}`).setAttribute('class','mt-10');
    document.getElementById(`label${i}`).innerHTML += `<span class="text-danger ml-1 font-weight-bold float-right showlabel d-none" id="showlabel_${i}">*</span>`;
    //document.getElementById(`label_${i}`).className = "d-none questionlabel"
  }

  async getlabelforpreview(event:any){
    return new Promise ((resolve,reject)=>{
      this.label = event.target.value;
      console.log('label',typeof(this.label));
      resolve(this.label);
    })
  }

  selectFile(event: any) {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.msg = "You must select an image";
      return;
    }
  }
  moveToRight(field: any) {
    const index = this.leftFields.indexOf(field);
    if (index !== -1) {
      this.leftFields.splice(index, 1);
      this.rightFields.push(field);
    }
  }

  moveToLeft(field: any) {
    const index = this.rightFields.indexOf(field);
    if (index !== -1) {
      this.rightFields.splice(index, 1);
      this.leftFields.push(field);
    }
  }
  get f(){
    return this.applicationForm.controls;
  }
  applicationJson() {
    console.log('items_key',items.length);
    this.submitted = true;
    console.log('industry',this.selectedItems);
    console.log('industry',this.selectedItems1);
    console.log('industry',this.selectedItems2);
    console.log('timezone',this.selectedItems_t);

    console.log('itemsval',(<HTMLInputElement>document.getElementById('txt')).value)
    if(!this.applicationForm.valid){
      console.log('item_length',items.length);
      if(items.length == 0){
        this.nokeyword = true
      }
      else{
        this.nokeyword = false;
      }
     // this.nokeyword = true;
      // if(this.filetypeerr){
      //   this.toastrService.danger('Please upload correct file type')
      // }
      // if(this.filesizeerr){
      //   this.toastrService.danger('File size should not exceed 2 MB')
      // }
    }
    else{
      this.submitted = false;
      for(var i = 0 ; i < this.selectedItems.length; i++){
        this.industry.push(this.selectedItems[i].item_text);
      }

      for(var i = 0 ; i < this.selectedItems1.length; i++){
        this.sector.push(this.selectedItems1[i].item_text);
    }

      for(var i = 0 ; i < this.selectedItems.length; i++){
        this.keyword.push(this.selectedItems[i].item_text);
    }

    console.log(this.industry,this.sector,this.keyword);
    }
    // let someDOMElement = document.getElementById("step1");
    // this.jsonOutput = toJSON(someDOMElement);
    // console.log("json output", this.jsonOutput);
    // this.arrayDataPush["step1"] = this.jsonOutput;
    // console.log("arraydatapush_first", this.arrayDataPush);
  }
  companyJson() {
    let someDOMElement = document.getElementById("step2");
    this.jsonOutput = toJSON(someDOMElement);
    this.arrayDataPush["step2"] = this.jsonOutput;
    console.log("arraydatapush_second", this.arrayDataPush);
    //this.arrayDataPush.push({step2:this.jsonOutput});
  }
  sectionJson() {
    let someDOMElement = document.getElementById("step3");
    this.jsonOutput = toJSON(someDOMElement);
    this.arrayDataPush["step3"] = this.jsonOutput;
    console.log("arraydatapush_third", this.arrayDataPush);
    //this.arrayDataPush.push({step3:this.jsonOutput});
  }
  additionalJson() {
    let someDOMElement = document.getElementById("step4");
    this.jsonOutput = toJSON(someDOMElement);
    //this.arrayDataPush.push({step4:this.jsonOutput});
    this.arrayDataPush["step4"] = this.jsonOutput;
    console.log("arraydatapush_final", this.arrayDataPush);
  }

  converttohtml() {
    return new Promise((resolve, reject) => {
      console.log("before convert to html");
      //var step1: any;
      var step2: any;
      var step3: any;
      var step4: unknown;
      this.challengeService
        .getFormbyEditionid(sessionStorage.getItem("editionid"))
        .subscribe((res: { results: string | any[] }) => {
          console.log("resform1234", res);
          if (res.results.length != 0) {
            this.noform = false;
            //step1 = res.results[0].sections.step1;
            step2 = res.results[0].sections.step2;
            step3 = res.results[0].sections.step3;
            step4 = res.results[0].sections.step4;
            
            console.log('step2',step2);
            console.log('step3',step3);
            console.log('step4',step4);

            // let parent = document.getElementById("placehere");
            // let DOMDocumentFragment = toDOM(step1);
            // console.log("DOMDoc", DOMDocumentFragment);
            // parent.parentNode.appendChild(DOMDocumentFragment);

            let parent2 = document.getElementById("placehere2");
            let DOMDocumentFragment2 = toDOM(step2);
            console.log("DOMDoc", DOMDocumentFragment2);
            parent2.parentNode.appendChild(DOMDocumentFragment2);

            let parent3 = document.getElementById("placehere3");
            let DOMDocumentFragment3 = toDOM(step3);
            console.log("DOMDoc", DOMDocumentFragment3);
            parent3.parentNode.appendChild(DOMDocumentFragment3);

            let parent4 = document.getElementById("placehere4");
            let DOMDocumentFragment4 = toDOM(step4);
            console.log("DOMDoc", DOMDocumentFragment4);
            parent4.parentNode.appendChild(DOMDocumentFragment4);
          }
          //   ,500)

          // }
          else {
            this.noform = true;
          }

          resolve(step4);
        });
    });
  }

  onFileSelect(event) {
    console.log('file is here');
    let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel','application/pdf','text/csv','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/msword','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-excel']
    const file = event.target.files[0];
    const filesize = file.size;
    const filesizemb = filesize/1024 ** 2;
    console.log('filesize in mb',filesizemb);
    console.log('file',file);
    if(!af.includes(file.type)){
      this.filetypeerr = true
    }
    else{
      this.filetypeerr = false
    }

    if(filesizemb > 2){
      this.filesizeerr = true
    }
    else{
      this.filesizeerr = false
    }
    this.fileInputLabel = file.name;
    this.applicationForm.get("uploadedImage").setValue(file);
  }
  
  createApplicationForm() {

    const formData = new FormData();
    formData.append("file",this.applicationForm.get("uploadedImage").value);
    console.log('formdata_image',formData);
    this.editionid = sessionStorage.getItem("editionid");
    console.log("challengeid", this.challengeid);
    this.additionalJson();

    this.challengeService.uploadFile(formData,'create-edition').subscribe((response:any)=>{
      console.log('image response',response.results[0].url);
      // if (response.statusCode === 200) {
        this.uploadFileInput.nativeElement.value = "";
        this.fileInputLabel = undefined;
      // }
      let data = {
        geography: "REGIONAL",
        timezone: [this.timezone],
        domain: [...this.industry],
        sector: [...this.sector],
        description: this.applicationForm.controls.applicationDetail.value,
        sections: this.arrayDataPush,
        keywords: [...items],
        documents:  [{
          "file_id":response.results[0].file_id,
          "url":response.results[0].url
        }],
        terms_and_conditions:this.applicationForm.controls.eligibility.value,
        export_access:this.exportaccess,
        email_notification:this.accessemailnotification,
        status: "DRAFT",
      };
      console.log('data________',data);
      console.log("form Data", data);
      console.log("editionid",this.editionid);
      this.challengeService
        .createAppForm(this.editionid, data)
        .pipe()
        .subscribe(
          (data: any) => {
            console.log('data________123',data);
            this.formid = data.results[0].id;
            this.data = data;
            this.toastrService.success("Data Added Successfully!");
            this.disable_publish = false;
          },
          (error: any) => {
            this.inValidMessage = true;
            this.toastrService.warning("Unable to Add records!");
          }
        );
    })

  }

  allowlogo(event:any){
    console.log('event',event.target.checked);
    var element1 = document.getElementById('companyprofilepic')
    if(event.target.checked == true){
      let element = document.getElementById('requiredcompanyprofile');
      element.classList.remove('requiredcompanyprofile');
      console.log('element',element);
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('requiredcompanyprofile');
      element.className+=' requiredcompanyprofile';
      console.log('element',element);
      element1.classList.remove('d-none');
    }
  }



  managementyear(event:any){
    console.log('event',event.target.checked);
    if(event.target.checked == true){
      let element = document.getElementById('managementyear');
      console.log('element',element);
      element.className+=' required';
    }
    else {
      let element = document.getElementById('managementyear');
      element.classList.remove('hide');
    }
  }

  allowname(event:any){
    console.log('event',event.target.checked);
    if(event.target.checked == true){
      let element = document.getElementById('name');
      element.classList.remove('required');
      console.log('element',element);
    }
    else {
      let element = document.getElementById('name');
      element.className+=' required';
    }
  }

  allowyoutube(event:any){
    console.log('event',event.target.checked);
    var element1 = document.getElementById('companyyt');
    if(event.target.checked == true){
      let element = document.getElementById('youtube');
      element.classList.remove('required');
      console.log('element',element);
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('youtube');
      element.className+=' required';
      console.log('element',element);
      element1.classList.remove('d-none');
    }
  }

  allowlinkedin_company(event:any){
    console.log('event',event.target.checked);
    var element1 = document.getElementById('companylinkedin');
    if(event.target.checked == true){
      let element = document.getElementById('linkedin_company');
      element.classList.remove('required');
      console.log('element',element);
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('linkedin_company');
      element.className+=' required';
      console.log('element',element);
      element1.classList.remove('d-none');
    }
  }

  allowkooapp(event:any){
    console.log('event',event.target.checked);
    var element1 = document.getElementById('companykoo');
    if(event.target.checked == true){
      let element = document.getElementById('kooapp');
      element.classList.remove('required');
      console.log('element',element);
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('kooapp');
      element.className+=' required';
      console.log('element',element);
      element1.classList.remove('d-none');
    }
  }

  allowfacebook(event:any){
    console.log('event',event.target.checked);
    var element1 = document.getElementById('companyfacebook');
    if(event.target.checked == true){
      let element = document.getElementById('facebook');
      element.classList.remove('required');
      console.log('element',element);
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('facebook');
      element.className+=' required';
      console.log('element',element);
      element1.classList.remove('d-none');
    }
  }

  allowinsta(event:any){
    console.log('event',event.target.checked);
    var element1 = document.getElementById('companyinsta');
    if(event.target.checked == true){
      let element = document.getElementById('insta');
      element.classList.remove('required');
      console.log('element',element);
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('insta');
      element.className+=' required';
      console.log('element',element);
      element1.classList.remove('d-none');
    }
  }

  allowtwitter_company(event:any){
    console.log('event',event.target.checked);
    var element1 = document.getElementById('companytwitter');
    if(event.target.checked == true){
      let element = document.getElementById('twitter_company');
      element.classList.remove('required');
      console.log('element',element);
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('twitter_company');
      element.className+=' required';
      console.log('element',element);
      element1.classList.remove('d-none');
    }
  }

  allowregistered(event:any){
    console.log('event_name',event.target.checked);
    var element1 = document.getElementById('companyreglabel')
    if(event.target.checked == true){
      let element = document.getElementById('registered');
      console.log('element_registered',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('registered');
      console.log('element',element);
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowyear(event:any){
    console.log('event',event.target.checked);
    var element1 = document.getElementById('yearestd');
    if(event.target.checked == true){
      let element = document.getElementById('year');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('year');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowwebsite(event:any){
    var element1 = document.getElementById('companyweb');
    console.log('event',event.target.checked);
    if(event.target.checked == true){
      let element = document.getElementById('website');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('website');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowcountry(event:any){
    console.log('event',event.target.checked);
    var element1 = document.getElementById('companycountry');
    if(event.target.checked == true){
      let element = document.getElementById('country');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('country');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowstate(event:any){
    console.log('event',event.target.checked);
    var element1 = document.getElementById('companystate');
    if(event.target.checked == true){
      let element = document.getElementById('state');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('state');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowcity(event:any){
    var element1 = document.getElementById('companycity');
    console.log('event',event.target.checked);
    if(event.target.checked == true){
      let element = document.getElementById('city');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
      
    }
    else {
      let element = document.getElementById('city');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowstage(event:any){
    var element1 = document.getElementById('companystage');
    console.log('event',event.target.checked);
    if(event.target.checked == true){
      let element = document.getElementById('stage');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('stage');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowindustry(event:any){
    console.log('event',event.target.checked);
    var element1 = document.getElementById('companyindustry');
    if(event.target.checked == true){
      let element = document.getElementById('industry');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('industry');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowsector(event:any){
    var element1 = document.getElementById('companysector');
    console.log('event',event.target.checked);
    if(event.target.checked == true){
      let element = document.getElementById('sector');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('sector');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowemail(event:any){
    var element1 = document.getElementById('email_management');
    if(event.target.checked == true){
      let element = document.getElementById('email');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('email');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allownationality(event:any){
    var element1 = document.getElementById('nat_mem');
    if(event.target.checked == true){
      let element = document.getElementById('nationality');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
      
    }
    else {
      let element = document.getElementById('nationality');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowgender(event:any){
    var element1 = document.getElementById('gender_member');
    if(event.target.checked == true){
      let element = document.getElementById('gender');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('gender');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowmanagementyear(event:any){
    if(event.target.checked == true){
      let element = document.getElementById('managementyear');
      console.log('element',element);
      element.classList.remove('required');
    }
    else {
      let element = document.getElementById('managementyear');
      element.className+=' required';
    }
  }

  allowtitle(event:any){
    var element1 = document.getElementById('title_management');
    if(event.target.checked == true){
      let element = document.getElementById('title');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('title');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowmanagementname(event:any){
    var element1 = document.getElementById('name_management');
    if(event.target.checked == true){
      let element = document.getElementById('managementname');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('managementname');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowprofilepic(event:any){
    console.log('event',event.target.checked);
    var element1 = document.getElementById('managementprofile')
    if(event.target.checked == true){
      let element = document.getElementById('m_profilepic');
      element.classList.remove('m_profilepic');
      console.log('element',element);
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('m_profilepic');
      element.className+=' m_profilepic';
      console.log('element',element);
      element1.classList.remove('d-none');
    }
    // var element1 = document.getElementById('managementprofile');
    // if(event.target.checked == true){
    //   let element = document.getElementById('m_profilepic');
    //   console.log('element',element);
    //   element.classList.remove('profilepicture');
    //   element1.className+=' d-none';
    // }
    // else {
    //   let element = document.getElementById('m_profilepic');
    //   element.className+=' profilepicture';
    //   element1.classList.remove('d-none');
    // }
  }

  allowtwitter(event){
    var element1 = document.getElementById('m_twitter');
    if(event.target.checked == true){
      let element = document.getElementById('twitter');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
      
    }
    else {
      let element = document.getElementById('twitter');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowlinkedin(event){
    var element1 = document.getElementById('m_linkedin');
    if(event.target.checked == true){
      let element = document.getElementById('linkedin');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('linkedin');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }
  
  allowentity(event){
    var element1 = document.getElementById('mem_ent');
    if(event.target.checked == true){
      let element = document.getElementById('entitytype');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('entitytype');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  allowteamsize(event){
    var element1 = document.getElementById('mem_ts');
    if(event.target.checked == true){
      let element = document.getElementById('teamsize');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('teamsize');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }


  moreaboutthismember(event){
    var element1 = document.getElementById('moreabt');
    if(event.target.checked == true){
      let element = document.getElementById('moreaboutthismember');
      console.log('element',element);
      element.classList.remove('required');
      element1.className+=' d-none';
    }
    else {
      let element = document.getElementById('moreaboutthismember');
      element.className+=' required';
      element1.classList.remove('d-none');
    }
  }

  publish(){
    let data = {
      "status":"PUBLISHED"
    }
    this.challengeService.publishform(data,this.formid).subscribe((res)=>{
      sessionStorage.setItem('formpreview','true');
      this.toastrService.success('application form has been created successfully');
      sessionStorage.setItem('edition_changed','false');
      setTimeout(()=> {
        location.reload();
      },2000)
      
    })
  }

  getDomains(){
    this.challengeService.getalldomains().subscribe((res:any)=>{
      this.alldomains = res.results;
      console.log('all domains',this.alldomains);
      for(var i = 0 ; i < this.alldomains.length ; i++){
        this.dropdownList.push({
          item_id:i+1 , item_text:this.alldomains[i].name
        })
      }

      this.groupbydomains = _.groupBy(this.alldomains,'name');
      console.log('group domains',this.groupbydomains);
    })
  }

  getSectors(event:any){
    let sectors = this.alldomains.find((item:any)=>{
      return item.id == event.target.value;
    })
    console.log('sectorslist_dropdown',this.sectors_dropdown);
    this.sectors_dropdown = sectors.sectors;
    console.log('sectors123',this.sectors_dropdown);
  }
  getTimeZone(event:any){
    this.timezone = event.target.value;
  }
  async onItemSelectIndustry(event){

    console.log('onItemSelect',this.groupbydomains,event);
    console.log('getsector',this.groupbydomains[`${event.item_text}`][0]['sectors'])
    // this.challengeService.setSectorData(this.groupbydomains[`${event.item_text}`][0]['sectors'])
    // this.sectors.push(...this.groupbydomains[`${event.item_text}`][0]['sectors']);
    await this.groupbysectors(event);
    console.log('sectors',this.sectors);
    //this.challengeService.setSectorData(this.sectors);
    for(var i = 0 ; i<this.sectors.length ; i++) {
      this.tempdata.push({
        item_id:i+1 , item_text:this.sectors[i].name
      })
    }
    this.dropdownList1 = [...this.tempdata];
    console.log('sectors_dropdown',this.dropdownList1);

  }

  async onItemDeSelectIndustry(event){

    console.log('event',event);

  }

  async groupbysectors(event){
    return new Promise((resolve,reject)=>{
      this.challengeService.setSectorData(this.groupbydomains[`${event.item_text}`][0]['sectors'])
      this.sectors.push(...this.groupbydomains[`${event.item_text}`][0]['sectors']);
      resolve(this.sectors);
    })
  }

  async assigntosectorlist(){
    await this.onItemSelectIndustry(this.industryevent);
    this.dropdownList1 = this.sectors;
    console.log()
  }

  getaccesstoexport(event:any){
    console.log('export access',event.target.checked)
    this.exportaccess = event.target.checked;
  }

  getaccesstoemailnotification(event:any){
    console.log('email notification',event.target.checked);
    this.accessemailnotification = event.target.checked;
  }
}
