import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-evaluatorlogin',
  templateUrl: './evaluatorlogin.component.html',
  styleUrls: ['./evaluatorlogin.component.scss']
})
export class EvaluatorloginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
