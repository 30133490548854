import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

  
@Injectable({
  providedIn: 'root'
})
export class IncubatorService {
  // baseUrl = "http://10.0.53.134:8080";
  baseUrl = environment.apiurl 

  constructor(
    private http: HttpClient,
  ) {}

  getIncubatorsList(data:any) {
   
    const url = this.baseUrl + "search";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  filterIncubator(data) {
    const url = this.baseUrl + "search";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  getIncubatorsByID(id) {
    const url = this.baseUrl + `incubators/${id}`;
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  getschemeList(){
    const url=this.baseUrl +"schemes/list";
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  actionIncubator(currentData, id) {
    let url = this.baseUrl + "incubators/" + id;

    let result: any = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));

      console.log("data:results:", currentData);
    return result;
  }
  editIncubatorModal(currentData, id) {
    let url = this.baseUrl + "incubators/" +id;
    // console.log("update::", currentData);
      let result = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  updateScheme(data, id) {
    let url = this.baseUrl + `incubators/${id}/schemes`;
      let result = this.http
      .put(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  assignStarup(data, id){
    let url = this.baseUrl + `incubators/${id}/startups`;
      let result = this.http
      .put(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
