<div class="container bg-white m-1 col-md-6 m-auto">
  <div class="row">
    <div class="w-100">
      <h2 class="text-center">Assign Scheme's and Group to Incubator</h2>
    </div>
    <div class="float-right">
      <!-- <button class="btn btn-primary" style = "color:white" (click)="gotoincubator()" > Go to Incubator</button>   -->
    </div>
    <div class="col-12">
      <!-- <div class="row my-3">
                  <div class="col-md-12">
                    <label for="">Incubator Name</label>
                    <input class="form-control" type="text" placeholder=""  formControlName="incubatorName" readonly>
                  </div>
                  <div class="custom-control custom-checkbox col-md-5 my-3 ml-4">
                    <div *ngFor="let option of options" [value]="option.id">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" formControlName="schemeName" >
                    <label class="custom-control-label" for="customCheck1">{{option.name}}</label>
                    </div>
                  </div>
                  <div class="col-md-6 form-group my-3">
                    <label for="exampleFormControlSelect1">Group</label>
                    <select class="form-control" formControlName="selectedGroup">
                      <option *ngFor="let option of types" [value]="option.value">
                        {{ option.label }}
                      </option>
                    </select>
                  </div>
                  <div class="d-flex w-100 justify-content-center">
                    <button class="btn custombtn mr-3 mb-3 mt-3" (click)="submitDetail()">Submit</button>
                  </div>
          </div> -->
      <div>
        <form [formGroup]="incubatorForm">
          <div class="form-group">
            <div class="form-group col-md-12">
              <div class="col-md-8">
                <label for="exampleFormControlSelect1">Incubator</label>
                <!-- <select class="form-control" formControlName="incubatorName" (change)="incubatobyId($event)" >
                      <option value="">Select</option>
                      <option *ngFor="let option of originalData" [value]="option.id">
                        {{ option.name }}
                      </option>
                    </select> -->
                <!-- <ng-multiselect-dropdown 
                      formControlName="incubatorName"
                      [placeholder]="''"
                      [settings]="dropdownSettings"
                      [data]="dropdownIncubator"
                      (onSelect)="incubatobyId($event)"

                     >
                    </ng-multiselect-dropdown> -->

                <!--Using ng-option and for loop-->
                <!-- <p>{{dropdownIncubator | json}}</p> -->
                <ng-select
                  formControlName="incubatorName"
                  [(ngModel)]="selectIncubator"
                  (change)="incubatobyId($event)"
                  placeholder="Select Incubator"
                  class="incubator_input"
                >
                  <ng-option
                    *ngFor="let incubator of dropdownIncubator"
                    [value]="incubator.item_id"
                    >{{ incubator.item_text }}</ng-option
                  >
                </ng-select>
                <div *ngIf="incubatorForm.controls.incubatorName.invalid && incubatorForm.controls.incubatorName.touched" class="error-message">
                  This field is required.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="custom-control custom-checkbox col-md-8 ml-4">
              <div class="my-4" *ngFor="let option of options">
                <input
                  type="checkbox"
                  (change)="schemeval($event)"
                  [value]="option.id"
                  formControlName="schemes"
                />
                {{ option.name | uppercase }}
              </div>
              <div *ngIf="incubatorForm.controls.schemes.invalid && incubatorForm.controls.schemes.touched" class="error-message">
                This field is required.
              </div>
            </div>
            <!-- <div class="custom-control custom-checkbox col-md-6 ml-4">
                      <div>
                        <input type="checkbox" (change)="schemeval($event)" formControlName="schemesS"  >
                        SAMRIDH
                      </div>
                 </div> -->
            <div class="form-group col-md-8 ml-4">
              <label for="exampleFormControlSelect1">Group</label>
              <select class="form-control" formControlName="group">
                <option value="">Select</option>
                <option *ngFor="let option of types" [value]="option.value">
                  {{ option.label }}
                </option>
              </select>
              <div *ngIf="incubatorForm.controls.group.invalid && incubatorForm.controls.group.touched" class="error-message">
                This field is required.
              </div>
            </div>
          </div>
          <!-- <div class="row mt-5">
                    <div class="custom-control custom-checkbox col-md-6 ml-4">
                      <div>
                        <input type="checkbox" (change)="schemeval($event)" formControlName="schemesT">
                        TIDE 2.0
                      </div>
                 </div> 
                   <div class="form-group col-md-5">
                    <label for="exampleFormControlSelect1">Group</label>
                    <select class="form-control" formControlName="group">
                      <option *ngFor="let option of types" [value]="option.value">
                        {{ option.label }}
                      </option>
                    </select>
                  </div> 
                </div>-->
        </form>
        <div class="modal-footer" style="display: flex; justify-content: center;">
          <button class="btn btn-primary" (click)="submitDetail()">Save</button>
          <button type="button" class="btn btn-secondary" (click)="cancel()">
            Cancel
          </button>
        </div>
      </div>
    </div>

    <!-- <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Incubator Name</th>
        <th scope="col">Scheme</th>
        <th scope="col">Group</th>
        <th scope="col">Status</th>
      </tr>
    </thead>
    <tbody >
      <tr *ngFor="let data of dataList">
        <td>{{data.name}}</td>
        <td>{{data.scheme_names}}</td>
        <td>{{data.group}}</td>
        <td>{{data.status}}</td>
      </tr>
    
    </tbody>
    <ng-template #noRecords>
      <tbody>
        <tr>
          <td colspan="4" class="no-records-found">No Data found.</td>
        </tr>
      </tbody>
    </ng-template>
  </table> -->
  </div>
</div>
