import { HttpClient, HttpErrorResponse , HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from "../../../environments/environment";

const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Authorization', `Bearer ${sessionStorage.getItem('admintoken')}`);

@Injectable({
  providedIn: "root",
})
export class SchemeService {
  baseUrl = environment.apiurl;

  constructor(
    private http: HttpClient
  ) {}

  getAllSchemes() {
    let url = this.baseUrl + "schemes/list?stats=true";
    let result: any = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
    return result;
  }

  createScheme(data: any) {
   
  }
 
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
