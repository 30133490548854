import { Component, OnInit } from '@angular/core';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'ngx-statewise-fundreleased',
  templateUrl: './statewise-fundreleased.component.html',
  styleUrls: ['./statewise-fundreleased.component.scss']
})
export class StatewiseFundreleasedComponent implements OnInit {

  data:any;
  start_date = "2019-01-01";
  end_date = "2024-12-31";
  grant_type = "EIR";
  years: string[] = [];
  states: string[] = [];
  entity: any;
  scheme_id:any;

  constructor(private grantservice:GrantManagementService,private _route :ActivatedRoute) {
    this._route.paramMap.subscribe((params:any)=>{
      this.scheme_id = params.params.id;
      this.grantservice.scheme_id = this.scheme_id
    })
   }

  ngOnInit(): void {
    this.grantservice.getEntityType().subscribe((res: any) => {
      this.entity = res;
      console.log('entity------', this.entity);
     
  });
    this.getStateYearWiseData();
  }

  getStateYearWiseData(){
    this.grantservice.getStateandYearWiseData(this.start_date,this.end_date,this.grant_type,this.entity,this.scheme_id).subscribe((res)=>{
      this.data = res;
      this.data.forEach(item => {
        const years = item.year_range.split('-');
        this.years.push(...years.filter(year => !this.years.includes(year)));
        this.states.push(item.state);
      });
      this.years = Array.from(new Set(this.years));
      this.states = Array.from(new Set(this.states));
    })
  }

  getData(year: string, state: string): string {
    const item = this.data.find(d => d.year_range.includes(year) && d.state === state);
    return item ? item.total_value : '';
  }
  // downloadCSV() {
  //   const flatData = [];
  
  //   // Add headers (years) to flatData
  //   const headers = ['State', ...this.years];
  //   flatData.push(headers);
  
  //   // Add data rows to flatData
  //   this.states.forEach(state => {
  //     if (state) { // Check if state is not empty
  //       const rowData = [state];
  //       this.years.forEach(year => {
  //         const data = this.getData(year, state);
  //         rowData.push(data !== undefined && data !== null ? data : '-');
  //       });
  //       flatData.push(rowData);
  //     }
  //   });
  
  //   // Convert flat data to CSV format
  //   const csv = this.convertToCSV(flatData);
  
  //   // Create a Blob and trigger download
  //   const blob = new Blob([csv], { type: 'text/csv' });
  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = 'table_data.csv';
  //   link.click();
  // }
  
  // convertToCSV(data) {
  //   // Convert data to CSV format
  //   return data.map(row => row.join(',')).join('\n');
  // }
  
  downloadCSV() {
    const flatData = [];

    // Add headers (years) to flatData
    const headers = ['State', ...this.years];
    flatData.push(headers);

    // Add data rows to flatData
    this.states.forEach(state => {
        if (state) { // Check if state is not empty
            const rowData = [state];
            this.years.forEach(year => {
                const data = this.getData(year, state);
                rowData.push(data !== undefined && data !== null ? data : '-');
            });
            flatData.push(rowData);
        }
    });

    // Convert flat data to XLSX format
    const ws = XLSX.utils.aoa_to_sheet(flatData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Create a Blob and trigger download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'table_data.xlsx';
    link.click();
}

}
