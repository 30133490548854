<!-- <div class="container py-3">
  <ngx-gia-utilized-table></ngx-gia-utilized-table>
</div> -->
<div class="container bg-white p-4">
  <h2 class="text-center eir-grant">Quarter Wise Fund Release
  </h2>
  <div class="table-responsive py-3">
    <div class="d-flex justify-content-end p-3">
      <button type="button" class="btn btn-primary text-end" (click)="downloadCSV()">Download</button>
    </div>
<div class="table-container">
    <table class="table text-center table-bordered ">

      <thead>
        <!-- <colgroup span="2"></colgroup> -->
       <tr>
          <th scope="col" >Q1</th>
          <th >Q2</th>
          <th scope="col">Q3​</th>
          <th scope="col" >Q4</th>

        </tr>
      </thead>
    <tbody>
        <tr >
          <td *ngFor="let quarter of QuarterData; let i=index">
          <table class="table table-borderless">
            <thead>
              <th>Agency Name</th>
              <th>Value</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of quarter">
                <td>{{item.agency_name}}</td>
                <td>{{item.total_value}}</td>
              </tr>
              <!-- <tr *ngFor="let item of Q2Data">
                <td>{{item.agency_name}}</td>
                <td>{{item.total_value}}</td>
              </tr> -->
            </tbody>
          </table>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</div>