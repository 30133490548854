<div class="login-form row custom-card col-md-10 offset-md-1">
    <div class="col-12 col-md-6 col-sm-12 transform-center">
        <div class="text-center">
            <img src="{{'assets/images/MeitYStartupHub-Logo-FINAL_5 1.png'}}" height="137px">
        </div>
        <hr class="hr-text">
        <div class="desc-text">
            Meity startup India Portal is a one of its kind online platform for all stakeholders of the startup
            ecosystem in India.
        </div>
        <hr class="hr-text">
        <div class="text-center">
            <!-- <img class="govt-login" src="{{'assets/images/gov-login-img.png'}}"> -->
        </div>
    </div>
    <div class="col-12 col-md-6 col-sm-12">
        <div class="text-center pt-30">
            <!-- <img class="welcome-meity" src="{{'assets/images/login-logo-welcom.png'}}" width="50%"> -->
        </div>
        <hr class="hr-text" data-content="OR">
        <form class="ng-pristine ng-invalid" [formGroup]="forgotForm">
            <div class="form-control-group">
                <label for="input-email" class="font-weight-bold">Email :</label>
                <input nbinput="" fullwidth="" name="email" id="input-email" placeholder="Email"
                    fieldsize="large" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" autofocus="" formControlName="email"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                <div *ngIf="submitted && f.email.errors" class="text-danger">
                        <div *ngIf="f.email.errors['required']">Email is required</div>
                        <div *ngIf="f.email.errors['pattern']">Email is invalid</div>
                </div>
                
            </div>
            <button  type="button" (click)="forgotpasswordcode()" fullwidth="" status="primary" size="large"
                class="appearance-filled full-width size-large shape-rectangle status-primary nb-transition login-button">
                Request Code </button>
        </form>
        <section class="sign-in-or-up" style="float:right">
            <span class="label-with-link">
            <p>
                <a routerLink="/auth" class="font-weight-bold text-decoration-none">Back to Log In?</a>
            </p>
        </span>
        </section>
    </div>
</div>