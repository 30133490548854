<div class="d-flex mb-3"> 
  <div class="col-sm-2">
    <label class="mx-3">State</label>
  <!-- <select class="form-control mx-3" [(ngModel)]="selectstate"> -->
    <!-- <option value="All">All</option> -->
    <!-- <option value="Delhi">Delhi</option>
    <option value="Mumbai">Mumbai</option>
    <option value="hyderabad">hyderabad</option>
    <option value="Roorkee">Roorkee</option>
    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
    <option value="Uttarakhand">Uttarakhand</option> 	
    <option value="Andhra Pradesh">Andhra Pradesh</option>

  </select> -->
  <ng-multiselect-dropdown [(ngModel)]="selectstate" 
              [placeholder]="''"
              [settings]="dropdownSettings_s"
              [data]="dropdownstates"
              [(ngModel)]="selectedStates"
              [ngModelOptions]="{standalone: true}"
            >
    </ng-multiselect-dropdown>
   

</div>
<div class="col-sm-2">
  <label class="mx-3">City</label>
<input type="text" class="form-control mx-3" [(ngModel)]="selectcity" placeholder="Enter City">
</div>
    <div class="col-sm-2 ">
    <label class="mx-3 ">Domain</label>
    <ng-multiselect-dropdown [(ngModel)]="selectdomain" 
              [placeholder]="''"
              [settings]="dropdownSettings"
              [data]="dropdownList"
              [(ngModel)]="selectedItems"
              [ngModelOptions]="{standalone: true}"
              (onSelect)="onItemSelectDomain($event)"
              (onDeSelect)="onItemDeSelectDomain($event)"
            >
    </ng-multiselect-dropdown>
    </div>
    
    <!-- <div class="col-sm-2">
      <label class="mx-3">Sector</label>
    <ng-multiselect-dropdown [(ngModel)]="selectsector"
              [placeholder]="''"
              [settings]="dropdownSettings"
              [data]="dropdownList1"
              [(ngModel)]="selectedItems1"
              [ngModelOptions]="{standalone: true}"
              
            >
            </ng-multiselect-dropdown>
    </div> -->
    <div class="col-sm-2">
      <label class="mx-1">Status</label>
    <!-- <select class="form-control" [(ngModel)]="selectstatus">
        <option value="">Select</option>
        <option value="APPROVED">APPROVED</option>
        <option value="REJECTED">REJECTED</option>
        <option value="PENDING">PENDING</option>
     </select> -->
    <ng-multiselect-dropdown [(ngModel)]="selectstatus" 
              [placeholder]="''"
              [settings]="dropdownSettings"
              [data]="dropdownstatus"
              [(ngModel)]="selectedStatus"
              [ngModelOptions]="{standalone: true}"
            >
    </ng-multiselect-dropdown>
    </div>
    <div class="col-sm-4">
      <label class="mx-3">.</label><br>
        <button [disabled]="selectstate =='' &&  selectcity == '' && selectdomain ==''  && selectstatus == ''"  class="btn btn-primary mr-1" (click)="filterItem()" *ngIf="filterMode" id="filter"> Filter </button>
        <button class="btn btn-secondary mr-1" (click)="resetFilters()" >Reset</button>
        <button  class="btn btn-success" (click)="downloadStartupList()"><i class="fas fa-download"></i> Download</button>
        <!-- <button class="btn btn-primary " [matMenuTriggerFor]="menu"> Results </button>        
        <mat-menu #menu="matMenu">
          <button mat-menu-item>APPROVED <span >({{approvedCount}})</span></button>
          <button mat-menu-item>REJECTED <span >({{rejectedCount}})</span></button>
          <button mat-menu-item>PENDING <span >({{pendingCount}})</span></button>
        </mat-menu> -->
    </div>
   
    </div>

      <!-- filter count row -->
      <div class="filter-container">
     
        <div class="filter-row">
          <p (click)="countFilter('All')" [class.active]="activeFilter == null">View All 
            <!-- <span>{{approvedCount + rejectedCount + pendingCount}}</span></p> -->
          <p (click)="countFilter('APPROVED')" [class.active]="activeFilter == 'APPROVED'">APPROVED <span>{{approvedCount}}</span>
          </p>
          <p (click)="countFilter('REJECTED')" [class.active]="activeFilter == 'REJECTED'">REJECTED <span>{{rejectedCount}}</span></p>
          <p (click)="countFilter('PENDING')" [class.active]="activeFilter == 'PENDING'">PENDING <span>{{pendingCount}}</span></p>
        </div>

     
    </div>
    <!-- filter count row -->

  <div class="container-grid col-12 col-sm-12">
    <div class="p-2">
      <strong class="d-flex justify-content-center" style="font-size: 27px;">Accelerators Details</strong>
    </div>
       <div class="container-lg">
          <!-- <div class="table-responsive" > -->
            <div class="loader-container" *ngIf="loading">
              <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="table-wrapper table-responsive">
              <table class="table table-striped" *ngIf="!loading">
                <thead>
                        <tr>
                          <th scope="col" class="text-center">Sl No</th>
                          <th>Updated Time</th>
                            <th>Accelerators Name</th>
                            <th>State</th>
                            <th>City</th>
                            <th>Domain</th>
                            <th>Status</th>
                            <th *ngIf="activeFilter !== 'PENDING'" style="text-align: center;">{{ activeFilter || 'Approved/Rejected' }} By</th>
                            <th>Last Logged In</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="data?.length > 0; else noRecords">
                      <tr *ngFor="let d of data | paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index">
                        <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th>
                        <td>{{d.updated_at |  date}}</td>
                        <td>{{d.name | titlecase}}</td>
                        <td>{{d.state | titlecase}}</td>
                        <td>{{d.city | titlecase}}</td>
                          <td>
                     <ul class="list-unstyled d-flex" style="margin: 0;">
                       <li *ngFor = "let item of d.domain | slice:0:5">
                          {{item}} 
                       </li>
                     </ul>
                   </td>
                   <td>{{d.status | titlecase}}</td>
                   <td *ngIf="activeFilter !== 'PENDING'">{{d.approved_by}}</td>
                   <td class="text-center">{{ d.login_info != null ? (d.login_info.last_login_time | date) : '-' }}</td>
                        <!-- <td>{{d.status | titlecase}}</td> -->
                        <td class="">
                         
                          <div class="d-flex" style="justify-content: center;">
                            <div *ngIf="d.status === 'PENDING'">
                              <a class="dropbtn fa fa-check mr-2" style="color:blue"  (click)="changestatus(d.id, 'APPROVED')"></a>
                              <a class="dropbtn fa fa-close mr-2 " style="color:red" (click)="changestatus(d.id, 'REJECTED')"></a>
                            </div> 
                            <div *ngIf="d.status === 'APPROVED'">
                              <a class="dropbtn fa fa-close mr-2 ml-4" style="color:red" (click)="changestatus(d.id, 'REJECTED')"></a>
                            </div >
                            <div *ngIf="d.status === 'REJECTED'">
                              <a class="dropbtn fa fa-check mr-4" style="color:blue" (click)="changestatus(d.id, 'APPROVED')"></a>
                            </div >
                            <div>
                              <a class="fa fa-eye arrow" (click)="preview(d.id)" style = "color:green" ></a>  
                            </div>
                        </div>

                          <!-- <div class="dropdown mr-3">
                            <a class="dropbtn fa fa-check" style="color: blue"></a>
                            <div class="dropdown-content">
                              <a *ngIf="d.status === 'APPROVED'" (click)="changestatus(d.id, 'REJECTED')">Reject</a>
                              <a *ngIf="d.status === 'REJECTED'" (click)="changestatus(d.id, 'APPROVED')">Approve</a>
                            </div>
                            <div class="dropdown-content" *ngIf="d.status === 'PENDING'">
                              <a (click)="changestatus(d.id, 'REJECTED')">Reject</a>
                              <a (click)="changestatus(d.id, 'APPROVED')">Approve</a> -->
                              <!-- <a *ngIf="d.status === 'REJECTED'" disabled>Rejected</a> -->
                            <!-- </div>
                          </div> -->
                          <!-- <span class="fa fa-edit arrow mr-3" (click)="editData(d)" style = "color:blue" data-toggle="modal" data-target="#updateModal"></span>   -->
                          <!-- <span class="fa fa-eye arrow" (click)="preview(d.id)" style = "color:green" ></span>   -->
                     </td>
                      </tr>
                    </tbody>
                    <ng-template #noRecords>
                      <tbody>
                        <tr>
                          <td colspan="8" class="no-records-found">No Data found.</td>
                        </tr>
                      </tbody>
                    </ng-template>
               </table>

            </div>
            <pagination-controls *ngIf="paginationEnabled && data?.length > 0;" (pageChange)="onPageChange($event)" directionLinks="true" class="float-right"></pagination-controls>
        <!-- </div> -->
      </div>   
  </div>



<!-- Update Modal -->
<div class="modal custom-modal" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Request for Document</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container col-sm-12 mt-5" #section>
          <form [formGroup]="modalUpdateForm">
            <div class="form-group">
              <label for="">StartUp Name</label>
              <input class="form-control" type="text" placeholder="" formControlName="modalStartUpName" readonly>
            </div>
            <div class="form-group">
              <label for="">Document Name</label>
              <input class="form-control" type="text" placeholder="" formControlName="modalDocumentName">
            </div>
            <button type="button" class="btn btn-primary" (click)="updatestartupModel(data)">Submit</button>
          </form>
        
          <table class="table" >
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">StartUp Name</th>
                <th scope="col">Documents</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>PAN</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Adhar</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>Voter Card</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>



