<div class="container-grid col-sm-12" #section>
  <nb-card class="col-sm-12">
      <nb-card-header class="float-right">Press Release</nb-card-header>
  </nb-card>
 
      <form
        [formGroup]="pressReleaseForm"
        class="form-control-group col-12"
       
      >
      <div class="row">
      <div class="form-control-group col-sm-4">
          <label for="">Title</label>
          <input
            type="text"
            formControlName="pressTitle"
            class="form-control"
            placeholder="Enter Title"
          />
          <div *ngIf="pressReleaseForm.get('pressTitle').invalid && pressReleaseForm.get('pressTitle').touched">
            <div *ngIf="pressReleaseForm.get('pressTitle').errors.required" class="text-danger">Title is required.</div>
          </div>
        </div>
        <input type="hidden" formControlName="status">
        <div class="form-control-group col-sm-4">
          <label for="">Link</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">@</span>
            </div>
            <input type="text" class="form-control" formControlName="link"
            placeholder="Enter Link" aria-label="" aria-describedby="basic-addon1">
          </div>
          <div *ngIf="pressReleaseForm.get('link').invalid && pressReleaseForm.get('link').touched">
            <div *ngIf="pressReleaseForm.get('link').errors.required" class="text-danger">URL is required.</div>
            <div *ngIf="pressReleaseForm.get('link').errors.pattern" class="text-danger">Valid URL is required.</div>
        </div>  
        </div>
        <div class="form-control-group col-sm-4" >
          <label for="">Date</label>
          <div style="display: flex;">
            <!-- <nb-card> -->
            <input  #dateInput nbInput placeholder="Enter Date" [nbDatepicker]="picker" class="input"  formControlName="date" >
            <!-- <nb-datepicker #picker [min]="min" [max]="max" ></nb-datepicker> -->
            <button nbSuffix nbButton ghost class="press-datepicker" (click)="dateInput.click()">
              <nb-icon icon="calendar"> </nb-icon>
            </button>
            <nb-datepicker #picker [max]="max" ></nb-datepicker>
          </div>
            <div *ngIf="pressReleaseForm.get('date').invalid && pressReleaseForm.get('date').touched">
              <div *ngIf="pressReleaseForm.get('date').errors.required" class="text-danger">Date is required.</div>
            </div>
            
          <!-- </nb-card> -->
          
        </div>
        </div>
  <div class="row">
      <div class="form-control-group col-sm-12 mt-2">
        <label>Description</label>
        <ckeditor  placeholder="Enter Description" [editor]="Editor" formControlName="pressDescription"
      [config]="editorConfig" ></ckeditor>
        <div *ngIf="pressReleaseForm.get('pressDescription').invalid && pressReleaseForm.get('pressDescription').touched">
          <div *ngIf="pressReleaseForm.get('pressDescription').errors.required" class="text-danger">Description is required.</div>
        </div>  
      </div>
           
  </div>
  <div class="d-flex w-100 justify-content-center">
    <button class="btn custombtn mr-3 mb-3 mt-3" (click)="createPressRelease()" *ngIf="createMode">Create</button>
    <button class="btn custombtn mr-3 mb-3 mt-3" (click)="updateFormsData()" *ngIf="editMode">Update</button>
    <button class="btn custombtn mb-3 mt-3" (click)="cancel()">Cancel</button>

  </div>
 </form>
       
 <div class="col-md-6 my-2 float-right"> 
  <div class="Custom_row">
    <input class="form-control" type="text" [(ngModel)]="searchText" placeholder="Search by Title...">
    <!-- download button -->
<div class="download_button">
<button class="btn btn-success" (click)="downloadlabList()"><i class="fas fa-download"></i> Download</button>
</div>

<!-- download button -->
</div>
</div>
<div class="table-wrapper table-responsive">
<table class="table table-striped">
<thead >
  <tr>
    <th scope="col" class="text-center">Sl No</th>
    <th scope="col">Updated Time</th>
    <th scope="col">Title</th>
    <th scope="col">Description</th>
    <th scope="col">Link</th>
    <th scope="col" width="120px">Date</th>
    <th scope="col" >Status</th>
    <th scope="col" width="220px">Actions</th>
  </tr>
</thead>
<tbody *ngIf="filteredData?.length > 0; else noRecords">
  <tr  *ngFor="let d of filteredData | paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index">
    <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th>
    <td>{{d.updated_at |  date}}</td>
    <td>{{d.pressrelease_title | titlecase}}</td>
    <td [innerHtml]="d.pressrelease_description  | slice:0:50"></td>
    <td> <a href="{{d.pressrelease_link}}" target="_blank"> {{d.pressrelease_link}}</a></td>
    <td>{{d.pressrelease_date}}</td>
    <td>{{d.pressrelease_status}}</td>

    <td>
        <!-- <div class="dropdown mr-3">
        <a class="dropbtn fa fa-check" style = "color:blue"></a>
        <div class="dropdown-content" >
          <a *ngIf="d.pressrelease_status === 'active'" (click)="changestatus(d,'inactive')">inactive</a>
          <a *ngIf="d.pressrelease_status === 'inactive'" (click)="changestatus(d,'active')">active</a>
        </div> 
      </div> -->
      <div class="d-flex align-items-center ">
        <div *ngIf="d.pressrelease_status === 'active'">
          <a class="fa fa-close mr-3 arrow" style="color:red"  (click)="changestatus(d,'inactive')"></a>
        </div>
        <div *ngIf="d.pressrelease_status === 'inactive'">
            <a class="fa fa-check mr-3 arrow" style="color:blue"  (click)="changestatus(d,'active')"></a>
        </div>
        <a class="fa fa-edit mr-3 arrow" style = "color:blue" (click)="editData(d, section)" ></a>
        <a class="fa fa-trash mr-3 arrow" style = "color:red" (click)="removeData(d.pressrelease_id)" ></a>
        <a class="fa fa-eye arrow" style = "color:green" data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" ></a>
        </div>
    </td>
  </tr>
  
</tbody>
<ng-template #noRecords>
  <tbody>
    <tr>
      <td colspan="6" class="no-records-found">No Data found.</td>
    </tr>
  </tbody>
</ng-template>
</table>
</div>
<pagination-controls *ngIf="filteredData?.length > 0;" (pageChange)="p = $event" class="float-right"></pagination-controls>
</div>


<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
Launch demo modal
</button> -->

<!-- Modal -->
<div class="modal custom-modal " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">

<div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Preview</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
          <div class="input-group mt-2">
            <div class="input-group-prepend mr-2">
              <label>Title</label>
              <!-- <span class="input-group-text">With textarea</span> -->
            </div>
            <input type="text" class="form-control mb-2" placeholder="" aria-label="Username" value="{{modaldata.presstitle}}" aria-describedby="basic-addon1" readonly>
          </div>
          <div class="input-group mt-2">
            <div class="input-group-prepend mr-2">
              <label >Date</label>
              <!-- <span class="input-group-text">With textarea</span> -->
            </div>
            <input type="text" class="form-control mb-2" placeholder="" aria-label="Username" value="{{modaldata.date}}" aria-describedby="basic-addon1" readonly>
          </div>
          <div class="input-group mt-2">
            <label class="mr-4 mb-3">Link</label>

              <a href="{{modaldata.link}}" target="_blank" readonly>{{modaldata.link}}</a>
          </div>
          <label>Description</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <!-- <span class="input-group-text">With textarea</span> -->
            </div>
            <!-- <textarea class="form-control mb-2" rows="8" cols="50" aria-label="With textarea" value="{{modaldata.description}}" readonly></textarea> -->
            <div class="text_contianer form-control" [innerHtml]="modaldata.description">
             
            </div>
          </div>        
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
    </div>
  </div>
</div>

</div>
