import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  public errorMsg: string; 
  constructor(private toastrService: NbToastrService) {}
  handleError(error: any) {
    if (error) {
      switch (error) {        
        case 400:
          this.errorMsg = 'Bad Request: Invalid input data';
          break;
          case "E0001":
          this.errorMsg = 'You are already exceeded the limit. Please wait for one hour.';
          break;
        case "E0002":
          this.errorMsg = 'Please Provide Valid Credentials';         
          break;
        case "E0003":
          this.errorMsg = 'Your Session is expired,Please login again';         
          break;
        case 404:
          this.errorMsg = 'Not Found: Resource not found';
          break;
        case 500:
          this.errorMsg = 'Something went wrong';
          break;
        case 409:
          this.errorMsg = 'Details already exist';
          break;
        case "E0010":
            this.errorMsg = 'Your not authorized to access Admin portal.';         
            break;
        default:
          this.errorMsg = 'An error occurred';
          break;
      }
    } else {
      this.errorMsg = 'An error occurred';
    }

    // Show the toast message
    this.showToast();
  }

  private showToast() {
    this.toastrService.danger(this.errorMsg, 'Error', {
      duration: 3000, 
    });
  }
    
  }
 

