import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AxiosServicesService } from "./axios/axios-services.service";
import { environment } from "../../environments/environment";

const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

const headers_file = new HttpHeaders().set(
  "Authorization",
  `Bearer ${sessionStorage.getItem("admintoken")}`
);

@Injectable({
  providedIn: "root",
})
export class ChallengeService {
  //baseUrl = "http://43.205.193.210:8090";
  //baseUrl = "http://43.205.193.210:8090";
  baseUrl = environment.apiurl;
  private sectordata = new Subject<any>();
  public sector_data = this.sectordata.asObservable();

  constructor(
    private http: HttpClient,
    private axiosServices: AxiosServicesService
  ) {}

  getAllChallenges() {
    let url = this.baseUrl + "challenges";
    let result: any = this.http.get(url).pipe(catchError(this.handleError));
    return result;
  }

  setSectorData(data: any) {
    this.sectordata.next(data);
  }

  getSectorData(): Observable<any> {
    return this.sector_data;
  }

  createChallenge(data: any) {
    console.log("data :: ", data);
    const url = this.baseUrl + "challenges";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  createAppForm(editionid: any, data: any) {
    console.log("data :: ", data);
    const url = this.baseUrl + `editions/${editionid}/forms`;
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getFormbyEditionid(editionid: any) {
    const url = this.baseUrl + `editions/${editionid}/forms`;
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  createRound(data: any, editionid: any) {
    const url = this.baseUrl + `editions/${editionid}/rounds`;
    let result = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getEditionRounds(editionid: any) {
    const url = this.baseUrl + `editions/${editionid}/rounds`;
    let result = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  createCriteria(data, roundid: any) {
    const url = this.baseUrl + `rounds/${roundid}/criteria`;
    let result = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getCriteriaForRound(stageid: any) {
    const url = this.baseUrl + `rounds/${stageid}/criteria`;
    let result = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  addExternalEvaluators(data) {
    const url = this.baseUrl + `evaluators/external`;
    let result = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  listevaluators(type: any) {
    const url = this.baseUrl + `evaluators?type=${type}`;
    let result = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  addEvaluatorstoRound(round_id: any, data: any) {
    const url = this.baseUrl + `rounds/${round_id}/evaluators`;
    let result = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  publishform(data: any, formid: any) {
    const url = this.baseUrl + `editions/forms/${formid}`;
    let result = this.http
      .put(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  updateform(data: any, formid: any) {
    const url = this.baseUrl + `editions/forms/${formid}`;
    let result = this.http
      .put(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getallapplications(editionid: any,state:any) {
    const url = this.baseUrl + `applications/state/list?edition_id=${editionid}&state=${state}`;
    let result = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getalldomains() {
    const url = this.baseUrl + `domains`;
    let result = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  moveapplicationtoaround(data: any) {
    const url = this.baseUrl + `evaluation/rounds`;
    let result = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  listapplicationsbyroundid(editionid: any, roundid: any) {
    const url =
      this.baseUrl +
      `applications/rounds/list?edition_id=${editionid}&round_id=${roundid}`;
    let result = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getevaluatoreditions() {
    const url = this.baseUrl + `evaluator/editions`;
    let result = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getEvaluatorRoundsByEditionId(editionid: any) {
    const url = this.baseUrl + `evaluator/rounds?edition_id=${editionid}`;
    let result = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getEvaluatorApplicationsByRoundId(roundid: any) {
    const url = this.baseUrl + `evaluator/applications?round_id=${roundid}`;
    let result = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getIndividualApplicationdata(id: any) {
    const url = this.baseUrl + `applications/${id}`;
    let result = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  assignScoreToCriteria(data: any) {
    const url = this.baseUrl + `evaluation/scores`;
    let result = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getScoresforRound(applicationid: any, roundid: any) {
    const url =
      this.baseUrl +
      `evaluation/scores?application_id=${applicationid}&round_id=${roundid}&group_by=evaluator`;
    let result = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  uploadFile(data: any, intent: any) {
    const url = this.baseUrl + `files?intent=${intent}`;
    let result = this.http
      .post(url, data, { headers: headers_file })
      .pipe(catchError(this.handleError));
    return result;
  }

  uploadManyFiles(data: any, intent: any) {
    const url = this.baseUrl + `files/many?intent=${intent}`;
    let result = this.http
      .post(url, data, { headers: headers_file })
      .pipe(catchError(this.handleError));
    return result;
  }

  updatecriteria(criteriaid:any,data:any){
    const url = this.baseUrl + `rounds/criteria/${criteriaid}`;
    let result = this.http
    .put(url, data, { headers: headers })
    .pipe(catchError(this.handleError));
  return result;
  }

  deletecriteria(criteriaid:any){
    console.log('deleteid',criteriaid);
    const url = this.baseUrl + `rounds/criteria/${criteriaid}`;
    let result = this.http.delete(url,{headers:headers}).pipe(catchError(this.handleError));
    return result;
  }

  exportSubmittedApplications(editionid){
    const url = this.baseUrl + `applications/export?edition_id=${editionid}`;
    let result = this.http.get(url,{headers:headers}).pipe(catchError(this.handleError));
    return result;
  }

  scoresByAllEvaluators(applicationid:any,roundid:any){
    const url = this.baseUrl + `evaluation/scores/roundScoresByEvaluators?application_id=${applicationid}&round_id=${roundid}`;
    let result = this.http.get(url,{headers:headers}).pipe(catchError(this.handleError));
    return result;
  }

  criteriaWiseScoreForEvaluator(applicationid:any,roundid:any){
    const url = this.baseUrl + `evaluation/scores/roundCriteriaScoresOfEvaluator?application_id=${applicationid}&round_id=${roundid}`;
    let result = this.http.get(url,{headers:headers}).pipe(catchError(this.handleError));
    return result;
  }
}

