import { Component, Input } from '@angular/core';
import { NbDialogRef, NB_DIALOG_CONFIG } from '@nebular/theme';
import { Subject } from 'rxjs';

@Component({
  selector: 'ngx-showcase-dialog',
  templateUrl: 'showcase-dialog.component.html',
  styleUrls: ['showcase-dialog.component.scss'],
})
export class ShowcaseDialogComponent {

  message:any;
  config:any;
  title:any;
  constructor(public ref: NbDialogRef<ShowcaseDialogComponent>) {}

}
