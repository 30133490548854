import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "../../environments/environment";

const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

@Injectable({
  providedIn: "root",
})
export class CoWorkingSpaceService {
  baseUrl = environment.apiurl;

  constructor(private http: HttpClient) {}

  createCoworkingSpaceData(data: any) {
    const url = this.baseUrl + "resources/coworking";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  getAllCoworkingSpaceData(data:any) {
    const url = this.baseUrl + "resources/search";
    let result: any = this.http
      .post(url,data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  getCoworkingSpaceByID(id: any) {
    let url = this.baseUrl + "resources/coworking/" + id;
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  deleteCoworkingSpaceData(id: any) {
    const url = this.baseUrl + "resources/coworking/delete/" + id;
    let result: any = this.http
      .post(url, id, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  editCoworkingSpaceData(data: any, id: any) {
    const url = this.baseUrl + "resources/coworking/" + id;
    let result: any = this.http
      .put(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }


  actionFacility(data: any, id: any, index: any) {
    const url = this.baseUrl + `resources/${index}/${id}`;
    let result: any = this.http
      .put(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  filterLab(data:any) {
    const url = this.baseUrl + `resources/profile/list`;
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
    console.log("result is ==> ",result)
  }
}
