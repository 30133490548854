import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ngx-lab-facilities',
  templateUrl: './lab-facilities.component.html',
  styleUrls: ['./lab-facilities.component.scss']
})
export class LabFacilitiesComponent implements OnInit {
  labForm:FormGroup;
  lab:boolean;
  guest:boolean;
  cowork:boolean;
  equipment:boolean;

  newid: any;
  entity_type: any;
  tab: any;

  constructor(
    private fb:FormBuilder,
    private router:Router,private route :ActivatedRoute
  ) { }

  ngOnInit(): void {    
    this.extraction();
    if(this.tab != null){
      console.log(this.tab)
      this.navigateMenu(this.tab)
    }
    else{
      this.navigateMenu('lab')
    }
       // add style for ui break on scroll
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = '0'; 
      htmlElement.style.position = 'sticky';
    }
  }

  ngOnDestroy(): void{
     // remove style for ui break on scroll
     var htmlElement = document.documentElement;
     if (htmlElement) {
       htmlElement.style.top = ''; 
       htmlElement.style.position = '';
     }
    
       // remove style for ui break on scroll
  }

 
  // cancel(){
  //   this.labForm.reset();
  // }
  navigateMenu(value:string){
    if(value == 'lab'){
      this.lab = true;
      this.cowork = false;
      this.equipment = false;
      this.guest = false;
    }

    else if(value == 'guest'){
      this.lab = false;
      this.cowork = false;
      this.equipment = false;
      this.guest = true;
    }

    if(value == 'equipment'){
      this.lab = false;
      this.cowork = false;
      this.equipment = true;
      this.guest = false;
    }

    if(value == 'cowork'){
      this.lab = false;
      this.cowork = true;
      this.equipment = false;
      this.guest = false;
    }
  }
  
  extraction(){
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state) {
      const state = navigation.extras.state as { name: any, entity_type: any, tab: any };
      this.newid = state.name;
      this.entity_type = state.entity_type;
      this.tab = state.tab;
    } else {
      // Fallback: Access the state directly from the history (useful for reload scenarios)
      const state = window.history.state as { name: any, entity_type: any, tab: any };
      this.newid = state.name;
      this.entity_type = state.entity_type;
      this.tab = state.tab;
    }

    console.log('hh',this.newid, this.entity_type, this.tab);
  }
}
