import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { GrantManagementService } from "../../../services/grant-management/grant-management.service";
import { NbToastrService } from "@nebular/theme";
const headers = new HttpHeaders().set(
  "Authorization",
  `Bearer ${sessionStorage.getItem("admintoken")}`
);
@Component({
  selector: "ngx-teamtide-table",
  templateUrl: "./teamtide-table.component.html",
  styleUrls: ["./teamtide-table.component.scss"],
})
export class TeamtideTableComponent implements OnInit {
  teamtideForm: FormGroup;
  entity_type:string="INCUBATOR"
  baseUrl = environment.apiurl;
  istideFormSubmited:boolean=false;
startdate:any
enddate:any;
p:number=1;
pageSize:number=10;

  newtideData:any;
  editmode:boolean=false;
  team_id:any;
  teamsidData:any;
  scheme_id:any
  entity: any;
  constructor(private fb: FormBuilder, private http:HttpClient,
    private router: Router,private _route:ActivatedRoute,
    private toasterService:NbToastrService,private grantManagementService:GrantManagementService) {
  //   this._route.paramMap.subscribe((params:any)=>{
  //     this.schemesnew_id = params.params.id;
      
  // })
  this._route.paramMap.subscribe((params:any)=>{
    this.scheme_id = params.params.id;
    this.grantManagementService.scheme_id = this.scheme_id
  })
}

  ngOnInit(): void {
    this.teamtideForm = this.fb.group({
      name: ["",[Validators.required]], 
      designation: ["",[Validators.required]],
      email: ["",[Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]], 
      cnumber: ["",[Validators.required,Validators.pattern(/^[0-9]*$/),Validators.maxLength(10),Validators.minLength(10)]],
    });

    this.grantManagementService.getEntityType().subscribe((res: any) => {
      this.entity = res;
      console.log('entity------', this.entity);
      this.getteamTide();
  });
  

  this.grantManagementService.getStartDate().subscribe((res:any)=>{
    this.startdate = res;
    console.log('startdate------',this.startdate)
    console.log('here1');
    //this.getFunds();
    
})

this.grantManagementService.getEndDate().subscribe((res: any) => {
    this.enddate = res;
    console.log('end------', this.enddate);
    console.log('here2');
    this.getteamTide();
});
this._route.queryParams.subscribe((params) => {
  this.p = +params['page'] || 1;
  this.loadData(this.p);

})
    // this.getteamTide();
  }
  onSubmit() {
    this.istideFormSubmited = true;

    if (this.teamtideForm.valid) {

      console.table(this.teamtideForm.value);
        var tidedata = {
        name: this.teamtideForm.value.name,
        designation: this.teamtideForm.value.designation,
        email: this.teamtideForm.value.email,
        contact: this.teamtideForm.value.cnumber,
        scheme_ids:[this.scheme_id]
      };
    
      if(this.editmode){
        this.updatesuccessData(this.team_id,tidedata);
        this.toasterService.success('Updated Successfully')

      }
      else{
      
        this.grantManagementService.createTide(tidedata,this.entity).subscribe(() => {

          this.toasterService.success('Form Submitted Successfully')

         console.log('data',tidedata)
         this.getteamTide();
         this.teamtideForm.reset();
         this.istideFormSubmited=false;
        //  this.teamtideForm.markAsUntouched();
    },
      (error: any) =>{
        this.toasterService.danger('Something went wrong')
  
      } )
      // this.teamtideForm.reset();


      }}
    else{
      console.log('ffsdfsdgfdgfd');
    }
  }

  //Get team tide data
  getteamTide(){
    this.grantManagementService.getTeamTides(this.entity,this.startdate, this.enddate).subscribe((data:any) => {
      console.log("res ==========> ",data);
      // this.newtideData = data.results;
      this.newtideData = data.results.filter((item: any) => item.scheme_ids.includes(this.scheme_id));
      this.onPageChange(1)
    }
   
   
    );
  }

  getbyidtideData(id:any){
    this.editmode = true; 
    this.team_id = id;
   
      this.grantManagementService.getbyidtideData(id,this.entity).subscribe((data:any)=>{
        
    
        console.log('kabir',data.results)
        this.teamsidData = data.results[0]
        this.teamtideForm.patchValue({
          // id:investorData.id,
          name:this.teamsidData.name,
          designation:this.teamsidData.designation,
          email:this.teamsidData.email,
          cnumber:this.teamsidData.contact,
          scheme_ids:[this.scheme_id]
          // scheme_ids:['1']
          //  status:this.successData.status

          
        });
  })
}

//delete
teamDelete(id: any){
  this.grantManagementService.deleteTide(id,this.entity).subscribe(() => {
    this.getteamTide();
    this.toasterService.danger('Record deleted successfully')
  });

}
//Update Data
updatesuccessData(id: any,data:any){
  this.grantManagementService.updatesuccessData(id, data,this.entity).subscribe(
    (res) => {
  console.log('res___1234',res);
    // this.investorForm.reset();
    this.editmode = false;
    // this.issuccessFormSubmited = false;
    this.getteamTide();
    this.teamtideForm.reset();
    this.istideFormSubmited=false

  });
}
cancelEdit(){
  this.editmode =false;
  this.teamtideForm.reset();

}

get _name() {
  return this.teamtideForm?.get("name");
}
get _designation() {
  return this.teamtideForm?.get("designation");
}
get _email(){
  return this.teamtideForm?.get("email")
}
get _cnumber(){
  return this.teamtideForm?.get("cnumber")
}

//pagination
onPageChange(page:number):void{
  this.p =page,
  this.router.navigate([],{
    relativeTo:this._route,
    queryParams:{page:page},
    queryParamsHandling:'merge'
  })
  this.loadData(page)
}
private loadData(page:number):void{
  console.log(page)
}
}
