import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AxiosServicesService } from '../../services/axios/axios-services.service';
import { NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  forgotForm: FormGroup;
  submitted = false;
  show: boolean = false;
  showeye:boolean = true;
  constructor(private formBuilder: FormBuilder,
    private axiosService: AxiosServicesService,private toastrService: NbToastrService,private router:Router) { }

  ngOnInit(): void {
 
    this.forgotForm = this.formBuilder.group({
      email: [
        null,
        [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      ],
      code: [
        null,
        [Validators.required, Validators.minLength(6)],
      ],
      password: [
        null,
        [Validators.required, Validators.minLength(6)],
      ]
    });

    if(sessionStorage.getItem('email_forgotpassword')!=null){
      this.forgotForm.controls.email.setValue(sessionStorage.getItem('email_forgotpassword'))
    }
  }

  get f(){
    return this.forgotForm.controls;
  }

  resetpassword(){
    this.submitted = true;
    if(!this.forgotForm.valid){

    }
    else{
      let data = {
        "email":this.forgotForm.controls.email.value,
        "code":this.forgotForm.controls.code.value,
        "password":this.forgotForm.controls.password.value
      }
      this.axiosService.resetpasswordwithcode(data).subscribe((res)=>{
        console.log('res_pass',res);
        this.toastrService.success('Your password has been reset successfully')
        this.forgotForm.reset;
        this.submitted = false;
        this.router.navigate(['/auth']);
      })
    }

  }

  showpassword(){
    this.show = true;
    this.showeye = false;
  }

  hidepassword(){
    this.show = false;
    this.showeye = true;
  }
}
