
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      
      <h5 class="modal-title" id="confirmationDialogLabel">Status Confirmation</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="ref.close(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Do you want to confirm this as {{message}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ref.close(true)" >YES</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="ref.close(false)">NO</button>
    </div>
  </div>
</div>