import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ConfirmedValidator } from "../../confirmed.validator";
import { AxiosServicesService } from "../../services/axios/axios-services.service";
@Component({
  selector: "ngx-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loginResponse: any;
  mobileNumForm: FormGroup;
  inValidMessage: boolean = false;
  roleList: any = [
    { value: "user1", title: "User 1" },
    { value: "user2", title: "User 2" },
    { value: "user3", title: "User 3" },
    { value: "user4", title: "User 4" },
  ];

  // loginservice: any;
  constructor(
    private router: Router,
    private http: HttpClient,
    // private loginservice:LoginService
    private formBuilder: FormBuilder,
    private axiosService: AxiosServicesService
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group(
      {
        name: [
          null,
          Validators.compose([Validators.required, Validators.minLength(6)]),
        ],
        email: [
          null,
          [
            Validators.required,
            Validators.email,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          ],
        ],
        dob: [null, Validators.compose([Validators.required])],
        role: [null, Validators.compose([Validators.required])],
        mobile: [
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern("^[0-9]*$"),
            Validators.minLength(10),
            Validators.maxLength(10),
          ]),
        ],
        password: [
          "",
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(25),
          ],
        ],
        confirmPassword: ["", [Validators.required]],
      },
      {
        validator: this.ConfirmedValidator("password", "confirmPassword"),
      }
    );

    this.registerForm.controls.role.setValue("");
  }
  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  passwordLogin() {
    if (this.registerForm.valid) {
      this.router.navigate(["/auth"]);
    }
  }
}
