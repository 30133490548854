<div class="login-form row custom-card col-md-10 offset-md-1">
    <div class="col-12 col-md-6 col-sm-12 transform-center">
        <div class="text-center">
            <img src="{{'assets/images/MeitYStartupHub-Logo-FINAL_5 1.png'}}" height="137px">
        </div>
        <hr class="hr-text">
        <div class="desc-text">
            Meity startup India Portal is a one of its kind online platform for all stakeholders of the startup
            ecosystem in India.
        </div>
        <hr class="hr-text">
        <div class="text-center">
            <!-- <img class="govt-login" src="{{'assets/images/gov-login-img.png'}}"> -->
        </div>
    </div>
    <div class="col-12 col-md-6 col-sm-12">
        <div class="text-center pt-30">
            <!-- <img class="welcome-meity" src="{{'assets/images/login-logo-welcom.png'}}" width="50%"> -->
        </div>
        <!-- Login with password -->
        <form class="ng-pristine ng-invalid" [formGroup]="registerForm">

            <div class="form-control-group">
                <label for="input-email" class="label">Name :</label>
                <input nbinput="" fullwidth="" name="email" id="input-email" placeholder="Name" fieldsize="large"
                    [ngClass]="{'status-danger':!registerForm.get('email')?.valid &&
                registerForm.get('name')?.dirty}" autofocus="" formControlName="name"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                <p class="caption status-danger ng-star-inserted" *ngIf="!registerForm.get('name')?.valid &&
                registerForm.get('name')?.dirty"> {{
                    registerForm.get('name')?.hasError('minlength')
                    ? 'Minimum 6 characters required'
                    : registerForm.get('name')?.getError('invalid')
                    }}
                </p>

            </div>
            <div class="form-control-group">
                <label for="input-email" class="label">Email:</label>
                <input nbinput="" fullwidth="" name="email" id="input-email" placeholder="Email / Mobile number"
                    fieldsize="large" [ngClass]="{'status-danger':!registerForm.get('email')?.valid &&
                registerForm.get('email')?.dirty}" autofocus="" formControlName="email"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                <p class="caption status-danger ng-star-inserted" *ngIf="!registerForm.get('email')?.valid &&
                registerForm.get('email')?.dirty"> {{
                    registerForm.get('email')?.hasError('')
                    ? 'Invalid email address'
                    : 'Invalid email address'
                    }}
                </p>

            </div>
            <div class="form-control-group">
                <label for="input-email" class="label">Mobile Number:</label>
                <input nbinput="" fullwidth="" name="email" id="input-email" placeholder="Mobile Number"
                    fieldsize="large" [ngClass]="{'status-danger':!registerForm.get('mobile')?.valid &&
                registerForm.get('mobile')?.dirty}" autofocus="" formControlName="mobile"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition">
                <p class="caption status-danger ng-star-inserted" *ngIf="!registerForm.get('mobile')?.valid &&
                registerForm.get('mobile')?.dirty"> {{
                    registerForm.get('mobile')?.hasError('minlength')
                    ? 'Minimum 10 characters required'
                    : 'Invalid mobile number'
                    }}
                </p>

            </div>
            <div class="form-control-group">
                <label for="input-email" class="label">Role:</label>
                <nb-select class="select-input input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition" status="primary" formControlName="role">
                    <nb-option value="" selected> -- select role -- </nb-option>
                    <nb-option *ngFor="let role of roleList" [value]="role.value">{{role.title}}</nb-option>
                  </nb-select>
                <nb-datepicker #formpicker></nb-datepicker>
                <p class="caption status-danger ng-star-inserted" *ngIf="!registerForm.get('role')?.valid &&
                (registerForm.get('role')?.dirty || registerForm.get('role')?.touched)"> {{
                    registerForm.get('role')?.hasError('required')
                    ? 'Role is required'
                    : 'Invalid Date of birth'
                    }}
                </p>

            </div>
            <div class="form-control-group">
                <label for="input-email" class="label">Date of birth:</label>
                <input nbInput
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition"
                    placeholder="Date of birth" formControlName="dob" [nbDatepicker]="formpicker">
                <nb-datepicker #formpicker></nb-datepicker>
                <p class="caption status-danger ng-star-inserted" *ngIf="!registerForm.get('dob')?.valid &&
                (registerForm.get('dob')?.dirty || registerForm.get('dob')?.touched)"> {{
                    registerForm.get('dob')?.hasError('required')
                    ? 'Date of birth is required'
                    : 'Invalid Date of birth'
                    }}
                </p>

            </div>
            <div class="form-control-group">
                <label for="input-password" class="label">Password:</label>

                <input formControlName="password" nbinput="" fullwidth="" name="password" type="password"
                    id="input-password" placeholder="Password" fieldsize="large" [ngClass]="{'status-danger':!registerForm.get('password')?.valid &&
                    registerForm.get('password')?.dirty}"
                    class="input-full-width size-large status-basic shape-rectangle ng-untouched ng-pristine ng-invalid nb-transition"
                    required="" minlength="4" maxlength="50">
                <span class="caption status-danger ng-star-inserted" *ngIf="!registerForm.get('password')?.valid &&
                    (registerForm.get('password')?.touched || registerForm.get('password')?.dirty)">
                    {{
                    registerForm.get('password')?.hasError('minlength')
                    ? 'Minimum 6 characters required'
                    : registerForm.get('password')?.getError('invalid')
                    }}
                </span>
                <!---->
            </div>
            <div class="form-control-group">
                <label for="input-password" class="label">Confirm Password:</label>

                <input formControlName="confirmPassword" nbinput="" fullwidth="" name="password" type="password"
                    id="input-password" placeholder="Confirm Password" fieldsize="large" [ngClass]="{'status-danger':!registerForm.get('password')?.valid &&
                    registerForm.get('confirmPassword')?.dirty}"
                    class="input-full-width size-large status-basic shape-rectangle ng-untouched ng-pristine ng-invalid nb-transition"
                    required="" minlength="4" maxlength="50">
                <div class="caption status-danger ng-star-inserted"
                    *ngIf="registerForm.get('confirmPassword').errors?.['confirmedValidator']">
                    Passwords did not match
                </div>
                <!---->
            </div>
            <button [disabled]="registerForm.invalid" (click)="passwordLogin()" fullwidth="" status="primary"
                size="large"
                class="appearance-filled full-width size-large shape-rectangle status-primary nb-transition login-button">
                Register </button>
        </form>
        <section aria-label="Social sign in" class="links"> or enter with:
            <div class="socials">
                <a href="https://gmail.com/" target="_blank">
                    <!-- <nb-icon class="google-icon" icon="google-outline" pack="eva">
                    </nb-icon> -->
                </a>
                <a href="https://www.facebook.com/meitystartuphub.in/" target="_blank">
                    <!-- <nb-icon class="facebook-icon" icon="facebook" pack="eva">
                    </nb-icon> -->
                </a>
                <a href="https://twitter.com/msh_meity" target="_blank">
                    <!-- <nb-icon class="twitter-icon" icon="twitter" pack="eva">
                    </nb-icon> -->
                </a>
                <a href="https://in.linkedin.com/company/meity-startup-hub" target="_blank">
                    <!-- <nb-icon class="linkedin-icon" icon="linkedin" pack="eva">
                    </nb-icon> -->
                </a>
            </div>
        </section>
        <section class="another-action">
            Already have an account?
            <a routerlink="../register" class="text-link" href="auth/login">
                Log in
            </a>
        </section>
    </div>
</div>