import { HttpClient, HttpErrorResponse , HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, forkJoin, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
import { ActivatedRoute } from "@angular/router";

const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Authorization', `Bearer ${sessionStorage.getItem('admintoken')}`);

@Injectable({
  providedIn: "root",
})
export class GrantManagementService {
  baseUrl = environment.apiurl;
  entity_type:any='INCUBATOR'
  // start_date = sessionStorage.getItem('start_date');
  // end_date = sessionStorage.getItem('end_date');
  scheme_id:any;
  showscaleup=true;
  showscale:true;
  showEir:true;
  private startdateval = new BehaviorSubject(sessionStorage.getItem('start_date'));
  startdate = this.startdateval.asObservable();
  
  private enddateval = new BehaviorSubject(sessionStorage.getItem('end_date'));

  enddate = this.enddateval.asObservable();

  private entitytypeval = new BehaviorSubject<any>(sessionStorage.getItem('entity_types')||'INCUBATOR');
  entitytype = this.entitytypeval.asObservable();

  constructor(
    private http: HttpClient,private _route:ActivatedRoute
  ) {
    // this.setStartDate(sessionStorage.getItem('start_date'));
    // this.setEndDate(sessionStorage.getItem('end_date'));
   this.setEntityType(sessionStorage.getItem('entity_types'));

    this._route.paramMap.subscribe((params:any)=>{
      this.scheme_id = params.id;
      // console.log('aaaaaaaaaa',params.param.id);
      // console.log('scheme_id',this.scheme_id);

    })

    // this.setStartDate(sessionStorage.getItem('start_date'));
    // this.setEndDate(sessionStorage.getItem('end_date'));
  }
  setEntityType(data:any) {
     this.entitytypeval.next(data);
  
  }


  getEntityType(): Observable<any> {
    console.log('mmm',this.entitytype)
    return this.entitytype;
  }
  setStartDate(data:any){
    this.startdateval.next(data);
  }
  setEndDate(data: any) {
    this.enddateval.next(data);
}

  getStartDate():Observable<any>{
    return this.startdate
  }
  getEndDate(): Observable<any> {
    return this.enddate
}

  createSchemeFundId(data: any){
    try {
      console.log("data:::::", data);
      let url = this.baseUrl + `grant/incubators`;
      console.log("URL::::", url);
      let result = this.http.post(url,data,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result:::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  createIncubatorSchemeFund(data: any){
    try {
      console.log("data:::::", data);
      let url = this.baseUrl + `grant/startups/batch`;
      console.log("URL::::", url);
      let result = this.http.post(url,data,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result incubator fund:::::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  getIncubatorSchemeFund(incid: any){
    try {
      console.log("data:::::", incid);
      let url = this.baseUrl + `accounts/terms/listByIncubatorFund?incubatorfund_id=${incid}`;
      console.log("URL::::", url);
      let result = this.http.get(url, {'headers':headers}).pipe(catchError(this.handleError));
      console.log("result incubator fund:::::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  getFundUtilizationDetails(incid:any) {
    try {
      console.log("data:::::", incid);
      let url = this.baseUrl + `accounts/termheads/listByIncubatorFund?incubatorfund_id=${incid}`;
      console.log("URL::::", url);
      let result = this.http.get(url, {'headers':headers}).pipe(catchError(this.handleError));
      console.log("result incubator fund utilization:::::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  createBankAccountDetails(data:any, incubatorfund_id:any) {
    try {
      console.log("data:::::", data);
      let url = this.baseUrl + `grant/incubators/${incubatorfund_id}/bankdetails`;
      console.log("URL::::", url);
      let result = this.http.put(url,data, {'headers':headers}).pipe(catchError(this.handleError));
      console.log("result incubator fund:::::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  getIncubatorBankDetails(incubatorfund_id: any) {
    try {
      let url = this.baseUrl + `grant/incubators/${incubatorfund_id}/bankdetails`;
      console.log("URL:::",url);
      let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result:::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  getIncFundUCDocuments(incubatorfund_id: any) {
    try {
      let url = this.baseUrl + `grant/incubatorfunds/${incubatorfund_id}/documents`;
      console.log("URL:::",url);
      let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result:::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  updateStatusOnDoc(data:any, incubatorfund_id:any) {
    try {
      console.log("data:::::", data);
      let url = this.baseUrl + `grant/incubatorfunds/${incubatorfund_id}/documents/status`;
      console.log("URL::::", url);
      let result = this.http.put(url,data, {'headers':headers}).pipe(catchError(this.handleError));
      console.log("result incubator fund:::::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  createIncubatorFundUtilization(data: any){
    try {
      console.log("data:::::", data);
      let url = this.baseUrl + `grant/startups/batchheads`;
      console.log("URL::::", url);
      let result = this.http.post(url,data,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result incubator fund:::::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  getIncFundSummary(incubatorfund_id: any) {
    try {
      let url = this.baseUrl + `grant/incubatorfunds/${incubatorfund_id}/summary`;
      console.log("URL:::",url);
      let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result:::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  getStateandYearWiseData(start_date:any,end_date:any,grant_type:any,entity_types:any,scheme_id:any) {
    try {
      let url = this.baseUrl + `grant/incubatorfunds/stateandyearwise?startdate=${start_date}&enddate=${end_date}&entity_type=${entity_types}&grant_type=${grant_type}&scheme_id=${scheme_id}`;
      console.log("URL:::",url);
      let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result:::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  getIncubatorProjectDetails(incubatorfund_id: any){
    try {
      let url = this.baseUrl + `grant/incubators?incubatorfund_id=${incubatorfund_id}`;
      console.log("URL:::",url);
      let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
      console.log("result:::",result);
      return result;
    } catch(error) {
      console.log("Error:::",error);
      return;
    }
  }

  listByIncubatorFund(start_date:any,end_date:any,entity_types:any) {
    console.log('dddd', start_date,end_date)
    let url = this.baseUrl + `accounts/terms/listByIncubatorFund?startdate=${start_date}&enddate=${end_date}&entity_type=${entity_types}&scheme_id=${this.scheme_id}`;
    // console.log("Start Date:", this.start_date);
    // console.log("End Date:", this.end_date);
    
    return this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
  }

  viewTermFund(fundid:any,fund:any,entity_types:any) {
    let baseUrl = this.baseUrl;
    let path = `/accounts/termheads/listByIncubatorFund`;
    let query = `?startupfund_id=${fundid}&incubatorfundterm_id=${fund}&entity_type=${entity_types}`;
    let url = baseUrl + path + query;

    return this.http.get(url).pipe(catchError(this.handleError));
  }

  viewUtilizationFund(fundid:any,fund:any,entity_types:any){
    let baseUrl = this.baseUrl;
    let path = `/accounts/termheads/utilised/listByIncubatorFund`;
    let query = `?startupfund_id=${fundid}&incubatorfundterm_id=${fund}&entity_type=${entity_types}`;
    let url = baseUrl + path + query;

    return this.http.get(url).pipe(catchError(this.handleError));

  }
  approveTermFund(termfundid:any,fund:any,entity_types:any) {
    let url = `${this.baseUrl}grant/termaccounts/release/status/${termfundid}/${fund}?entity_type=${entity_types}`
    return this.http.put(url, {status: 'APPROVED'}, { headers: headers}).pipe(catchError(this.handleError))
  }

  rejectTermFund(termfundid:any,fund:any,entity_types:any) {
    let url = `${this.baseUrl}grant/termaccounts/release/status/${termfundid}/${fund}?entity_type=${entity_types}`
    return this.http.put(url, {status: 'REJECTED'}, { headers: headers}).pipe(catchError(this.handleError))
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  StatewiseIncubatorFund(start_date:any,end_date:any,entity_types:any) {
  console.log(end_date,'sds')
    let url = this.baseUrl + `grant/statewiseagency?start_date=${start_date}&end_date=${end_date}&entity_type=${entity_types}&scheme_id=${this.scheme_id}`;
   
    console.log('ssss',entity_types)
    return this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
  }
  QuarterwiseFund(start_date:any,end_date:any,entity_types:any) {
      let url = this.baseUrl + `grant/fund/quarterwise?start_date=${start_date}&end_date=${end_date}&entity_type=${entity_types}&scheme_id=${this.scheme_id}`;
     console.log('kkk',entity_types)
      
      return this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
    }
    stateWiseStartups(start_date:any,end_date:any,entity_types: any){
     console.log('entitytype',entity_types)
      let url = this.baseUrl +`grant/fund/statewisestartuplist?start_date=${start_date}&end_date=${end_date}&entity_type=${entity_types}&scheme_id=${this.scheme_id}`
      return this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
   
    }

    scaleuputilizeddata(fund: any, start_date: any, end_date: any,entity_types:any) {
      let granttype = this.showscaleup ? 'SCALEUP' : '';
      let fundtype = fund;
  
      let url = `${this.baseUrl}grant/incubatorfunds/received/utilised/refunded?startdate=${start_date}&enddate=${end_date}&entity_type=${entity_types}&grant_type=${granttype}&fundtype=${fundtype}`;
      return this.http.get(url).pipe(
        catchError(this.handleError)
      );
    }
    eirUtilized(fund: any, start_date: any, end_date: any,entity_types:any){
      console.log(this.showscaleup,'kk')
      let granttype = this.showscaleup ? 'EIR' : '';

      let fundtype =fund;
      let url=`${this.baseUrl}grant/incubatorfunds/received/utilised/refunded?startdate=${start_date}&enddate=${end_date}&entity_type=${entity_types}&grant_type=${granttype}&fundtype=${fundtype}`
     
      return this.http.get(url).pipe(
        catchError(this.handleError)
      );
    }
  

    getNotifications(entity_types:any,start_date:any,end_date:any) {
      const url = this.baseUrl + `grantnotifications/${this.scheme_id}?entity_type=${entity_types}&startdate=${start_date}&enddate=${end_date}`;
      return this.http.get(url, { headers: headers }).pipe(catchError((err) => throwError(err)));
  }

  createNotification(payload:any,entity_types:any) {
      const url = this.baseUrl + `grantnotifications/create?entity_type=${entity_types}`;
      return this.http.post(url, payload, { headers: headers }).pipe(catchError((err) => throwError(err)));
  }

  updateNotification(id:any, payload:any,entity_types:any) {
      const url = this.baseUrl + `grantnotifications/${id}?entity_type=${entity_types}`;
      return this.http.put(url, payload, { headers: headers }).pipe(catchError((err) => throwError(err)));
  }

  deleteNotification(id:any,entity_types:any) {
      const url = this.baseUrl + `grantnotifications/${id}?entity_type=${entity_types}`;
      return this.http.delete(url, { headers: headers }).pipe(catchError((err) => throwError(err)));
  }

  getUploads(entity_types:any,start_date:any,end_date:any) {
    const url = this.baseUrl + `grantuploads/${this.scheme_id}?entity_type=${entity_types}&startdate=${start_date}&enddate=${end_date}`;
    return this.http.get(url, { headers: headers }).pipe(catchError((err) => throwError(err)));
}

uploadFile(data: any) {
    const url = this.baseUrl + `files?intent=grant-uploads`;
    let uploadHeaders = headers.delete("content-type");
    uploadHeaders.append("content-type", 'multipart/form-data');
    // console.log(headers)
    return this.http.post(url, data, { headers: uploadHeaders }).pipe(catchError((err) => throwError(err)));
}

createUpload(payload: any,entity_types:any) {
    const url = this.baseUrl + `grantuploads/create?entity_type=${entity_types}`;
    return this.http.post(url, payload, { headers: headers }).pipe(catchError((err) => throwError(err)));
}

updateUpload(id: any, payload: any,entity_types:any) {
    const url = this.baseUrl + `grantuploads/${id}?entity_type=${entity_types}`;
    return this.http.put(url, payload, { headers: headers }).pipe(catchError((err) => throwError(err)));
}

deleteUpload(id: any,entity_types:any) {
    const url = this.baseUrl + `grantuploads/${id}?entity_type=${entity_types}`;
    return this.http.delete(url, { headers: headers }).pipe(catchError((err) => throwError(err)));
}
//startuptable
getIncubator(entity_types:any,start_date:any,end_date:any){
  const url= this.baseUrl+`incubators/startups/fund/list?fund_release=true&entity_type=${entity_types}&startdate=${start_date}&enddate=${end_date}&scheme_id=${this.scheme_id}`;
  return this.http.get(url,{ headers: headers }).pipe(catchError((err) => throwError(err)));
}

//Team tide
getTeamTides(entity_types:any,start_date:any,end_date:any){
  const url= this.baseUrl+`schemeincharges/list?entity_type=${entity_types}&startdate=${start_date}&enddate=${end_date}`;
  return this.http.get(url,{ headers: headers }).pipe(catchError((err) => throwError(err)));

}
createTide(tidedata: any,entity_types:any) {
  const url = this.baseUrl + `schemeincharges?entity_type=${entity_types}`;
  return this.http.post(url, tidedata, { headers: headers }).pipe(catchError((err) => throwError(err)));
}
getbyidtideData(id:any,entity_types:any){
  const url = this.baseUrl + `schemeincharges/${id}?entity_type=${entity_types}`;
  return this.http.get(url, { headers: headers }).pipe(catchError((err) => throwError(err)));
}

deleteTide(id:any,entity_types:any){
  const url = this.baseUrl + `schemeincharges/${id}?entity_type=${entity_types}`;
  return this.http.delete(url, { headers: headers }).pipe(catchError((err) => throwError(err)))
}
// putTeamTide(id:any,data:any,entity_types:any){
//   const url = this.baseUrl + `grantuploads/${id}?entity_type=${entity_types}`;
//     return this.http.put(url, data, { headers: headers }).pipe(catchError((err) => throwError(err)));
// }
updatesuccessData(id: any, data: any,entity_types:any): Observable<any> {
  const url = `${this.baseUrl}schemeincharges/${id}?entity_type=${entity_types}`;
  return this.http.put(url, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }).pipe(
    catchError((err) => throwError(err))
  );
}


//utilised.refunded,received 3 apis for download
getParallelData( start_date: any, end_date: any, entity_types: any): Observable<any[]> {
  let granttype = this.showscaleup ? 'EIR' : '';

  const url1 = `${this.baseUrl}grant/incubatorfunds/received/utilised/refunded?startdate=${start_date}&enddate=${end_date}&entity_type=${entity_types}&grant_type=${granttype}&fundtype=amount_received`;
const request1 = this.http.get(url1);

const url2 = `${this.baseUrl}grant/incubatorfunds/received/utilised/refunded?startdate=${start_date}&enddate=${end_date}&entity_type=${entity_types}&grant_type=${granttype}&fundtype=amount_utilised`;
const request2 = this.http.get(url2);

const url3 = `${this.baseUrl}grant/incubatorfunds/received/utilised/refunded?startdate=${start_date}&enddate=${end_date}&entity_type=${entity_types}&grant_type=${granttype}&fundtype=amount_refunded`;
const request3 = this.http.get(url3);


  // Combining multiple requests into a single observable using forkJoin
  return forkJoin([request1, request2, request3]);
}
getsectorParallelData( start_date: any, end_date: any, entity_types: any): Observable<any[]> {
  let granttype = this.showscaleup ? 'SCALEUP' : '';

  const url1 = `${this.baseUrl}grant/incubatorfunds/received/utilised/refunded?startdate=${start_date}&enddate=${end_date}&entity_type=${entity_types}&grant_type=${granttype}&fundtype=amount_received`;
const request1 = this.http.get(url1);

const url2 = `${this.baseUrl}grant/incubatorfunds/received/utilised/refunded?startdate=${start_date}&enddate=${end_date}&entity_type=${entity_types}&grant_type=${granttype}&fundtype=amount_utilised`;
const request2 = this.http.get(url2);

const url3 = `${this.baseUrl}grant/incubatorfunds/received/utilised/refunded?startdate=${start_date}&enddate=${end_date}&entity_type=${entity_types}&grant_type=${granttype}&fundtype=amount_refunded`;
const request3 = this.http.get(url3);


  // Combining multiple requests into a single observable using forkJoin
  return forkJoin([request1, request2, request3]);
}
//financial dropdowngetcall

// Samridh api calls.
//fund release get call
getfundreleasedData(entity_types:any,start_date:any,end_date:any){
  const url= this.baseUrl+`accounts/terms/listByIncubatorFund?entity_type=${entity_types}&startdate=${start_date}&enddate=${end_date}&scheme_id=${this.scheme_id}`;
return this.http.get(url,{headers:headers}).pipe(catchError((err)=>throwError(err)))
}
approvesamridhFund(termfundid:any,fund:any){
  let url = `${this.baseUrl}grant/accelerator/startup/${termfundid}/${fund}`
  return this.http.put(url, {grant_status: 'APPROVED'}, { headers: headers}).pipe(catchError(this.handleError))

}
rejectsamridhTermFund(termfundid:any,fund:any){
  let url = `${this.baseUrl}grant/accelerator/startup/${termfundid}/${fund}`
  return this.http.put(url, {grant_status: 'REJECTED'}, { headers: headers}).pipe(catchError(this.handleError))

}
// for accelerator list
acclistData(){
  const url = this.baseUrl + "accelerators/list";
 return this.http.get(url,{headers:headers}).pipe(catchError((err)=>throwError(err)))
  }
  //postData for release-order
  putreleaseOrder(payload:any,id:any){
    const url = this.baseUrl + `accelerators/releaseorder/${id}`;
    return this.http.put(url, payload, { headers: headers }).pipe(catchError((err) => throwError(err)));
}
getacclistData(){
  const url = this.baseUrl + "accelerators/release/order";
 return this.http.get(url,{headers:headers}).pipe(catchError((err)=>throwError(err)))
  }
  //documents get api
  documentData(){
    const url = this.baseUrl + "accelerator/grant/docs";
   return this.http.get(url,{headers:headers}).pipe(catchError((err)=>throwError(err)))
    }
    //utilize and sanction approve
    approveUtilizeSan(termfundid:any,payload:any){
      let url = `${this.baseUrl}grant/accelerator/docs/${termfundid}`
      return this.http.put(url,payload,{ headers: headers}).pipe(catchError(this.handleError))
    
    }
    
}
