import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";

const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

@Injectable({
  providedIn: "root",
})
export class FacilitiesService {
  //  baseUrl = "http://10.0.53.134:8080";

  baseUrl = environment.apiurl;

  constructor(private http: HttpClient) {}

  filterFacility(data) {
    const url = this.baseUrl + "resources/search";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  actionFacility(data: any, id: any, index: any) {
    const url = this.baseUrl + `resources/${index}/${id}`;
    let result: any = this.http
      .put(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
