<div class="header-container">
  <div class="logo-container">
    <a
      href="#"
      class="sidebar-toggle"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      (click)="toggleSidebar()"
    >
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><img src="{{'assets/images/MeitYStartupHub-Logo-FINAL_5 1.png'}}" height="60px"></a>
  </div>
  <!-- <nb-select
    status="primary"
    matRipple
    [selected]="currentTheme"
    (selectedChange)="changeTheme($event)"
  >
    <nb-option
      *ngFor="let theme of themes"
      [value]="theme.value"
      matRipple
    >{{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <nb-actions size="small">
       <nb-action class="control-item" icon="bell-outline" (click)="goToNotifications()"></nb-action>
       <!-- <nb-action class="control-item">
    <nb-select [selected]="currentLanguage" class="language-select" (selectedChange)="selectedLang($event)" status="primary">
      <nb-option *ngFor="let lan of language" [value]="lan.key"> {{ lan.title }}</nb-option>
    </nb-select>
</nb-action> -->
    <!-- <nb-action class="control-item" icon="power" (click)="logout()"></nb-action> -->
     <nb-action class="dropdown" >
                      <a class="dropbtn fa fa-power-off "  id="dropdownMenuButton"  style = "color:red"></a>
                      <div class="dropdown-content dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click) = "gotoadmin()">Update</a>
                        <a class="dropdown-item" (click)="logout()">Logout</a>
                      </div>
     </nb-action>
  </nb-actions>
</div>