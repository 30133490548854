import { Component, OnInit } from '@angular/core';
import { GrantManagementService } from '../../../services/grant-management/grant-management.service';
import { ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx'; // Import xlsx library

@Component({
  selector: 'ngx-statewise-agencies',
  templateUrl: './statewise-agencies.component.html',
  styleUrls: ['./statewise-agencies.component.scss']
})
export class StatewiseAgenciesComponent implements OnInit {
  Incubatorfunds:any;
  newincubator:any;
  statedata:any
  startdate: any;
  scheme_id:any;
  enddate: any;
  entity: any;
  constructor(private grantManagementService:GrantManagementService,private _route:ActivatedRoute) { 
    this._route.paramMap.subscribe((params:any)=>{
      this.scheme_id = params.params.id;
      this.grantManagementService.scheme_id = this.scheme_id
    })
  }

  ngOnInit(): void {
    this.grantManagementService.getStartDate().subscribe((res:any)=>{
      this.startdate = res;
      console.log('startdate------',this.startdate)
      // this.getincubatorFunds()
  })

  this.grantManagementService.getEndDate().subscribe((res: any) => {
      this.enddate = res;
      console.log('end------', this.enddate);
      this.getincubatorFunds()
       // Call getFunds() after end date is available
  });
  this.grantManagementService.getEntityType().subscribe((res: any) => {
    this.entity = res;
    console.log('entity------', this.entity);
    this.getincubatorFunds()
});
  // this.getincubatorFunds()
   
  }
  getincubatorFunds(){
    if(!this.startdate || !this.enddate){
      console.log('Start date or end date is not available yet.');
      return;
    }
 this.grantManagementService.StatewiseIncubatorFund(this.startdate,this.enddate,this.entity).subscribe((res:any) => {
      console.log("res ==========> ",res);
      this.Incubatorfunds = res;
      this.newincubator=res?.incubator_total[0].count;
      this.statedata=res?.state_total[0].count
      console.log('aa',this.statedata)
  })
  }
  // downloadCSV() {
  //   // Flatten the data to include nested fields
  //   const flatData = [];
  
  //   // Check if scheme_id is 2
  //   const isSchemeId2 = this.scheme_id ==2;
  
  //   // Add headers to flatData conditionally
  //   const headers = isSchemeId2
  //     ? ['S.N', 'State/ UT Name', 'Agency Name']
  //     : ['S.N', 'State/ UT Name', 'Agency Name', 'Type of the Agency'];
  //   flatData.push(headers);
  
  //   // Add data rows to flatData
  //   this.Incubatorfunds.data.forEach((item, i) => {
  //     const rowData = isSchemeId2
  //       ? [
  //           i + 1,
  //           item.state || '-',
  //           item.name || '-'
  //         ]
  //       : [
  //           i + 1,
  //           item.state || '-',
  //           item.name || '-',
  //           item.group || '-'
  //         ];
  //     flatData.push(rowData);
  //   });
  
  //   // Add total row conditionally
  //   const totalRow = isSchemeId2
  //     ? ['Total', this.newincubator || '-', this.statedata || '-']
  //     : ['Total', this.newincubator || '-', this.statedata || '-', ''];
  //   flatData.push(totalRow);
  
  //   // Convert flat data to CSV format
  //   const csv = this.convertToCSV(flatData);
  
  //   // Create a Blob and trigger download
  //   const blob = new Blob([csv], { type: 'text/csv' });
  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = 'table_data.csv';
  //   link.click();
  // }
  
  // convertToCSV(data: any[][]): string {
  //   // Convert data to CSV format
  //   return data.map(row => row.join(',')).join('\n');
  // }
  downloadCSV() {
    // Flatten the data to include nested fields
    const flatData = [];

    // Check if scheme_id is 2
    const isSchemeId2 = this.scheme_id == 2;

    // Add headers to flatData conditionally
    const headers = isSchemeId2
      ? ['S.N', 'State/ UT Name', 'Agency Name']
      : ['S.N', 'State/ UT Name', 'Agency Name', 'Type of the Agency'];
    flatData.push(headers);

    // Add data rows to flatData
    this.Incubatorfunds.data.forEach((item, i) => {
        const rowData = isSchemeId2
            ? [
                i + 1,
                item.state || '-',
                item.name || '-'
            ]
            : [
                i + 1,
                item.state || '-',
                item.name || '-',
                item.group || '-'
            ];
        flatData.push(rowData);
    });

    // Add total row conditionally
    const totalRow = isSchemeId2
        ? ['Total', this.newincubator || '-', this.statedata || '-']
        : ['Total', this.newincubator || '-', this.statedata || '-', ''];
    flatData.push(totalRow);

    // Convert flat data to XLSX format
    const ws = XLSX.utils.aoa_to_sheet(flatData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Create a Blob and trigger download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'table_data.xlsx';
    link.click();
}

// Function to convert data to CSV format
convertToCSV(data: any[][]): string {
    // Convert data to CSV format
    return data.map(row => row.join(',')).join('\n');
}
}
