import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { StartupService } from '../../services/startup.service';
import { IncubatorService } from '../../services/incubator.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'ngx-assign-startup',
  templateUrl: './assign-startup.component.html',
  styleUrls: ['./assign-startup.component.scss']
})
export class AssignStartupComponent implements OnInit {

  startupForm: FormGroup;
  startupList: any;
  startId: any;
  incubId: any;
  dataList: any;
  data: any;
  inValidMessage: boolean;
  originalData:any;
  totalItems: number;
  incId: any;
  selectedIncubator =[];
  dropdownIncubator = [];
  dropdownSettings: IDropdownSettings={};
  selectIncubator =[];

  selectedStartup=[];
  startupAssign=[];
  dropdownStartup=[];
  dropdownSettingsS:IDropdownSettings={};
  selectStartup=[];

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private startupService: StartupService,
    private incubatorService: IncubatorService,

  ) { }

  ngOnInit(): void {
    this.startupForm = this.fb.group({
      startupName:['',[Validators.required]],
      incubatorAssign:['',[Validators.required]]
    })

    this.dropdownIncubator=[
      // { item_id: 1, item_text: 'Agriculture' },
      // { item_id: 2, item_text: 'IT' },
      // { item_id: 3, item_text: 'Soft' },
    ];

    this.dropdownStartup=[
      // { item_id: 1, item_text: 'Agriculture' },
      // { item_id: 2, item_text: 'IT' },
      // { item_id: 3, item_text: 'Soft' },
    ];

    this.listAllStartups();
    this.listAllIncubators();

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };
    this.dropdownSettingsS = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };
  }

  listAllIncubators(){
    let data={
      search_entity: "INCUBATOR",
      "page": 0, "page_size": 400
    }
    this.incubatorService.getIncubatorsList(data)
      .subscribe((res:any)=>{
        this.originalData =res.results;
        for(var i = 0 ; i < this.originalData.length ; i++){
          this.dropdownIncubator.push({
            item_id:this.originalData[i].id , item_text:this.originalData[i].name
          })
        }
        this.dropdownIncubator = [...this.dropdownIncubator];
        this.totalItems = +res.total_search_results_count;
      },(err)=>{
        console.log('err',err);
      })
  }

  listAllStartups() {
    let data={
      search_entity: "STARTUP",
      "page": 0, "page_size": 2000
    }
    this.startupService.getStartupsList(data).subscribe((res: any) => {
      this.data = res.results;     

      for(var i = 0 ; i < this.data.length ; i++){
        this.dropdownStartup.push({
          item_id:this.data[i].id , item_text:this.data[i].name
        })
      }
      this.dropdownStartup = [...this.dropdownStartup];

      // console.log("startups::", this.data);
      // this.startupList = this.data;
    });
  }
  incubatobyId(event:any){
    console.log('event____Incubator',event.target.value)
    this.incId= event.target.value;
  }
  assignIncStartup(event){
    console.log('startup____',event.target.value)
    this.startId= event.target.value;
  }
  incubatorToassign(event:any){
    console.log('event____Incubator', event.target.value)
    this.incubId= event.target.value;
  }
  submitStartup(){
    console.log("startuuuup",this.startupForm.value);
    this.startupForm.markAllAsTouched();  // calling mark as touch every time.
    if (this.startupForm.invalid) {
      // Display error messages or perform other actions
      
      return;
    }
    console.log("form submited successfully")
    var startupList = [];
    for (var i = 0; i < this.selectedStartup.length; i++) {
      startupList.push(this.selectedStartup[i].item_id);
    }
    this.incubId = this.selectedIncubator[0].item_id;

  
    let data= {
      "startup_ids": startupList
          }     

        this.incubatorService.assignStarup(data, this.incubId)
          .subscribe((res:any)=>{
          this.dataList= res;
          this.listAllStartups();
          console.log("startupdetail__:", res);
          this.startupForm.reset();
          this.toastrService.success("Record Updated Successfully!");
        },
        (error) => {
        this.inValidMessage = true;
        this.toastrService.warning("Unable to Update Record!");
      })
  }

  cancel(){
    this.startupForm.reset();
  }
}
