import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  AbstractControl,
  Validators,
} from "@angular/forms";
import { find, get, pull } from "lodash";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ChallengeService } from "../../../services/challenge.service";
import { MentorService } from "../../../services/mentor.service";
import { EditionService } from "../../../services/edition.service";
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { Router } from "@angular/router";
import { toJSON, toDOM } from "dom-to-json";
import { fromEvent, Subscription, VirtualAction } from "rxjs";
import * as _ from 'lodash';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { environment } from "../../../../environments/environment";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { states } from "../../../../assets/js/states";
import { form } from "../../../../assets/js/getFormData";

export interface Fruit {
  name: string;
}

// declare var ClassicEditor: any;
declare var $: any;

var items = [];

var index;
const headers= new HttpHeaders()
.set('Authorization', `Bearer ${sessionStorage.getItem('admintoken')}`);

@Component({
  selector: 'ngx-mentorforms',
  templateUrl: './mentorforms.component.html',
  styleUrls: ['./mentorforms.component.scss']
})
export class MentorformsComponent implements OnInit, AfterViewInit {

    //@ViewChild('tagInput') tagInputRef: ElementRef;
    @ViewChild("tagInput_sectors") tagInputRef_sectors: ElementRef;
    @ViewChild("tagInput_subsectors") tagInputRef_subsectors: ElementRef;
    @ViewChild("tagInput_keywords") tagInputRef_keywords: ElementRef;
    @ViewChild("button_next", { static: false }) button_next: ElementRef;
    @ViewChild("UploadFileInput", { static: false }) uploadFileInput: ElementRef;

      fieldCount:number = 0;
      criteria_label = [];
      criteria_label3 = [];
      criteria_label4 = [];
      formids = [];
      step2criteria = [];
      step3criteria = [];
      step4criteria = [];
      step2filter = [];
      step3filter = [];
      step4filter = [];
      validation = [];

    addTextField() {
      this.fieldCount++;
      
      const formFieldsDiv = document.getElementById('formFields');
      const newField = document.createElement('div');
      newField.className = 'form-group';

      const inputField = document.createElement('input');
      inputField.type = 'text';
      inputField.name = 'field' + this.fieldCount;
      inputField.placeholder = 'Text Field ' + this.fieldCount;

      newField.appendChild(inputField);
      formFieldsDiv.appendChild(newField);
    }


    addDropdownField() {
      this.fieldCount++;

      const formFieldsDiv = document.getElementById('formFields');
      const newField = document.createElement('div');
      newField.className = 'form-group';

      const selectField = document.createElement('select');
      selectField.name = 'field' + this.fieldCount;
      selectField.multiple = true;
      newField.appendChild(selectField);
      formFieldsDiv.appendChild(newField);
    }

    addOption() {
      const formFieldsDiv = document.getElementById('formFields');
      const lastField = formFieldsDiv.lastChild;

      if (lastField && lastField.firstChild && lastField.firstChild.nodeName === 'SELECT') {
        const selectField = lastField.firstChild;

        const newOption = document.createElement('option');
        newOption.value = 'option';
        newOption.text = 'Option';
        

        selectField.appendChild(newOption);
      }
    }

    

    removeFormField() {
      if (this.fieldCount > 0) {
        const formFieldsDiv = document.getElementById('formFields');
        console.log('formsfield-----',formFieldsDiv);
        formFieldsDiv.removeChild(formFieldsDiv.lastChild);
        this.fieldCount--;
      }
    }

    removeSpecificField() {
      const formFieldsDiv = document.getElementById('formFields');
      const fieldToRemove = document.getElementById('field' + this.fieldCount);

      if (fieldToRemove) {
        formFieldsDiv.removeChild(fieldToRemove);
        this.fieldCount--;
      }
    }
  
    clickedElement: Subscription = new Subscription();
    filetypeerr = false;
    filesizeerr = false;
  
    tags: string[] = [];
    tags_sectors: string[] = [];
    tags_subsectors: string[] = [];
    tags_keywords: string[] = [];
    tag_s: boolean;
    tag_sb: boolean;
    tag_k: boolean;
    challengename:any
    edition_name:any
    edition_desc:any
    start_date:any
    end_date:any
    submitted = false;
    dropdownList = [];
    selectedItems = [];
    dropdownSettings:IDropdownSettings = {};
    dropdownSettings_t:IDropdownSettings = {};
  
    dropdownList1 = [];
    selectedItems1 = [];
    // dropdownSettings:IDropdownSettings = {};
  
    dropdownList2 = [];
    selectedItems2 = [];
  
    dropdownList_t = [];
    selectedItems_t = [];
    nokeyword = false;
    disable_publish = true;
    // dropdownSettings2:IDropdownSettings = {};
    // public Editor = ClassicEditor;
    public industry = [];
    public sector = [];
    public keyword = [];
    public Editor = ClassicEditor;
    public editorConfig = {
      toolbar: {
        items: [
          "heading",
          "|",
          "alignment", // <--- ADDED
          "bold",
          "italic",
          "link",
          "bulletedList",
          "blockQuote",
          "undo",
          "redo",
        ],
      },
      link: {
        decorators: {
          toggleDownloadable: {
            mode: "manual",
            label: "Downloadable",
            attributes: {
              download: "file",
            },
          },
          openInNewTab: {
            mode: "manual",
            label: "Open in a new tab",
            defaultValue: true, // This option will be selected by default.
            attributes: {
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
        },
      },
      placeholder: 'Enter Application Description'
    };
  
    public editorConfig_tc = {
      toolbar: {
        items: [
          "heading",
          "|",
          "alignment", // <--- ADDED
          "bold",
          "italic",
          "link",
          "bulletedList",
          "blockQuote",
          "undo",
          "redo",
        ],
      },
      link: {
        decorators: {
          toggleDownloadable: {
            mode: "manual",
            label: "Downloadable",
            attributes: {
              download: "file",
            },
          },
          openInNewTab: {
            mode: "manual",
            label: "Open in a new tab",
            defaultValue: true, // This option will be selected by default.
            attributes: {
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
        },
      },
      placeholder: 'Enter Terms and Conditions'
    };
    
    testdata = ['test','test','test']

    applicationForm!: FormGroup;
    companyDetailsForm!: FormGroup;
    applicationSectionForm!: FormGroup;
    additionalQuestionForm!: FormGroup;
    reviewPublishForm!: FormGroup;
    showform: boolean = false;
    showStepper: boolean;
    editorsForm: FormGroup;
    editorsForm1: FormGroup;
    editors3Form: FormGroup;
    editors4Form: FormGroup;

    leftFields = [
      { id: 1, name: "OPPORTUNITY" },
      { id: 2, name: "FUNDING REQUIREMENT" },
      { id: 3, name: "INTELLECTUAL PROPERTY" },
      { id: 4, name: "FINANCIAL PROJECTIONS" },
      { id: 5, name: "KEY CUSTOMERS" },
      { id: 6, name: "EVENTS & MILESTONES" },
      { id: 6, name: "GO TO MARKET" },
      { id: 6, name: "FUNDING ROUNDS" },
      { id: 6, name: "STRATEGIC PARTNERS" },
    ];
    rightFields = [];
    jsonOutput: any;
    value: any;
    msg: string;
    arrayDataPush = {};
    inputValue: string;
    challengeid: any;
    inValidMessage: boolean;
    data: any;
    editionid: string;
    noform: boolean;
    label: any;
    index: number;
    parent: any;
    checked: any;
    formid: any;
    fileInputLabel: any;
    alldomains: any;
    final: any = [];
    sectors: any = [];
    industryevent: any;
    exportaccess: any;
    accessemailnotification: any;
    groupbydomains: any;
    tempdata = [];
    urlpattern: string;
    selectedinput: string;
    longindex: any;
    states: string[];
    timezone: any;
    invalidapplicationdetail: boolean = false;
    invalid_tc: boolean = false;
    sectors_dropdown: any;
    selectedDomains:any;
  
    constructor(
      private fb: FormBuilder,
      private http: HttpClient,
      private challengeService: ChallengeService,
      private mentorService: MentorService,
      private editionService: EditionService,
      private toastrService: NbToastrService,
      private router: Router,
      private dialogService: NbDialogService,
    ) {
      this.getDomains();
    }
  
    ngOnInit(): void {
      this.compareObjects();
      this.getDomains();
      console.log('states__',states)
      this.states = states;
      this.urlpattern = "((http|https)://)(www.)?" 
      + "[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]" 
      + "{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)"

  
      this.dropdownList = [
      
      ];
      this.selectedItems = [
        
      ];

      this.selectedItems1 = [
        
      ];
      
      this.dropdownList2 = [
        { item_id: 1, item_text: 'crop' },
        { item_id: 2, item_text: 'books' },
        { item_id: 3, item_text: 'medicine' },
        { item_id: 4, item_text: 'money' },
        { item_id: 5, item_text: 'os' }
      ];
      this.selectedItems2 = [
        
      ];
  
      this.dropdownList_t = [
        { item_id: 1, item_text: 'IST' },
        { item_id: 2, item_text: 'GMT' },
      ];
      this.selectedItems_t = [
        
      ];
  
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true
      };
  
      this.dropdownSettings_t = {
        singleSelection: true,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true
      };

      this.challengename = sessionStorage.getItem('challengename');
      this.edition_name = sessionStorage.getItem('edition_name');
      this.edition_desc = sessionStorage.getItem('edition_desc');
      this.start_date = sessionStorage.getItem('start_range');
      this.end_date = sessionStorage.getItem('end_range');
  
      this.applicationForm = this.fb.group({
        geographical: ['',Validators.required],
        startRange: ['',Validators.required],
        endRange: ['',Validators.required],
        tag: ['',Validators.required],
        businessSubSector: ['',Validators.required],
        timeZone: ['',Validators.required],
        applicationDetail: ['',Validators.required],
        eligibility: ['',Validators.required],
        keywordsTag: [''],
        chooseFile: [''],
        dataExport: [''],
        emailExport: [''],
        uploadedImage: ['',Validators.required]
      });
      this.applicationForm.controls.startRange.setValue(sessionStorage.getItem('start_range'));
      this.applicationForm.controls.endRange.setValue(sessionStorage.getItem('end_range'));
      this.companyDetailsForm = this.fb.group({
        profileLogo: [],
        companyLogo: [],
        uploadPic: [],
        companyName: [],
        registered: [],
        established: [],
        website: [],
        countryName: [],
        profilePicture: [],
        stateName: [],
        cityName: [],
        companyStage: [],
        industry: [],
        sectorName: [],
        descriptionDetail: [],
  
        managementName: [],
        managementTitle: [],
        managementEstabl: [],
        gender: [],
        nationality: [],
        managementEmail: [],
        contactNumber: [],
        managementDescription: [],
      });
      this.applicationSectionForm = this.fb.group({});
      this.editorsForm = this.fb.group({
        editors: this.fb.array([]),
        menus: this.fb.array([]),
        addFields: this.fb.array([]),
      });
      this.editors3Form = this.fb.group({
        editors: this.fb.array([]),
        menus: this.fb.array([]),
        addFields: this.fb.array([]),
      });
      this.editors4Form = this.fb.group({
        editors: this.fb.array([]),
        menus: this.fb.array([]),
        addFields: this.fb.array([]),
      });
      this.reviewPublishForm = this.fb.group({});
    }
    
    compareObjects(){
      const object1 = { a: 1, b: 2, c: 3 };
      const object2 = { b: 4, c: 5, d: 6 };

      const obj1Keys = Object.keys(object1);
      const obj2Keys = Object.keys(object2);

      const commonKeys = obj1Keys.filter(key => obj2Keys.includes(key));
      
      console.log('common keys',commonKeys);

      var data = this.selectKeysFromObject(object1,commonKeys);

      console.log('data_obj',data);
    }

    selectKeysFromObject(obj, keysToSelect) {
      const selectedObject = {};
      keysToSelect.forEach(key => {
        if (obj.hasOwnProperty(key)) {
          selectedObject[key] = obj[key];
        }
      });
      return selectedObject;
    }
    challenges(){
      this.router.navigate(['/admin/addchallenge']).then(()=>{
        sessionStorage.setItem('edition_changed','false');
        location.reload();
      })
    }
    add_application_detail(event:any){
      console.log('eventdata',event.target.innerText);
      let value = event.target.innerText.trimStart();
      console.log('value_length',value.length);
      let keyCode = event.keyCode;
      console.log('backspace',keyCode);
      if(value.length != 0){
        console.log('ok');
        this.invalidapplicationdetail = false
      }
      else{
        console.log('not ok');
        this.invalidapplicationdetail = true
      }
    }
  
    add_tc(event:any){
      console.log('eventdata',event.target.innerText);
      let value = event.target.innerText.trimStart();
      if(value.length != 0){
        console.log('ok');
        this.invalid_tc = false
      }
      else{
        console.log('not ok');
        this.invalid_tc = true
      }
    }
    
    ngAfterViewInit() {
      var multiSelect = document.getElementById('multiselect_domain');

// Add a change event listener
      multiSelect.addEventListener('change', ()=>{
    // Get the selected options
      var selectedOptions = this.getSelectedOptions(multiSelect);

    // Log the selected options to the console (or perform any other action)
    console.log('Selected Options:', selectedOptions);

    this.selectedDomains = selectedOptions;

    this.getSectors(this.selectedDomains);
  });
      setTimeout(()=>{
        var txt = document.getElementById('txt');
        var list = document.getElementById('list');
        console.log('dom_____',txt,list);
        items = [];
        
        if(txt!=null){
          txt.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
            let val = (<HTMLInputElement>txt).value;
            if (val !== '') {
              if (items.indexOf(val) >= 0) {
                //alert('Tag name is a duplicate');
              } else {
                items.push(val);
                this.nokeyword = false;
                render();
                console.log('it is here');
                (<HTMLInputElement>txt).value = '';
                txt.focus();
              }
            } else {
              //alert('Please type a tag Name');
            }
          }
        });
        }
  
    
    function render() {
      list.innerHTML = '';
      items.map((item, index) => {
        list.innerHTML += `<li><span>${item}</span><a id="close_${index}" class="closekeyword text-white">X</a></li>`;
      });
   
      console.log('doc_query___', document.querySelectorAll('.closekeyword'));
      document.querySelectorAll('.closekeyword').forEach((element,i)=>{
        var index = i;
        console.log('elemcross____',element,document.getElementById(`close_${i}`));
        (<HTMLInputElement>document.getElementById('txt')).value = '';
        document.getElementById(`close_${i}`).onclick = () => {
          console.log('clicked on cross',index);
          console.log('items___',items);
          remove(index);
        }
      })
    }
  
    
    function remove(i) {
      items = items.filter(item => items.indexOf(item) != i);
      render();
    }
    
    window.onload = function() {
      render();
      txt.focus();
    }
      
      },5000)

        
        this.converttohtml().then(() => {
          if(!this.noform){
            if(sessionStorage.getItem('formpreview')=='true'){

              $('.mandatory').attr('class','d-none');
              $('.mandatorytext').attr('class','d-none');
              $('#addquestion').attr('class','d-none');
              $('#removequestion').attr('class','d-none');
              $('.addmoreradio').attr('class','d-none');
              $('.selectfield').attr('class','d-none');
              $('#next_step_4')?.text('Submit');
              $('.mandatoryplus').attr('class','d-none');
              $('.mandatoryminus').attr('class','d-none');
              $('.hide').attr('class','d-none');
              $('.hideonpreview').attr('style','display:none');
  
              document.querySelectorAll('.slider').forEach((element,i)=>{
                element.setAttribute('class','d-none');
              })
  
              document.querySelectorAll('.switch_disabled').forEach((element,i)=>{
                element.setAttribute('class','d-none');
              })
  
              document.getElementById('addquestion').setAttribute('class','d-none');
              document.getElementById('removequestion').setAttribute('class','d-none');
  
              document.querySelectorAll('.step3val').forEach((element,i)=>{
                element.classList.remove('d-none');
                element.className+=' d-block'
              })
  
              document.querySelectorAll('.removeinui').forEach((element,i)=>{
                element.classList.remove('border');
                element.classList.remove('border-5');
                element.classList.remove('border-secondary');
              })
  
            }
     
  
            console.log("after conversion");
            console.log("add question", document.getElementById("addquestion"));
            document.getElementById("addquestion").onclick = (e) => {
              e.preventDefault();
              this.addQuestions();
            };
            let nextbtn2 = document.getElementById("next_step_2") as HTMLElement;
            let nextbtn3 = document.getElementById("next_step_3") as HTMLElement;
            let nextbtn4 = document.getElementById("next_step_4") as HTMLElement;
      
            let prevbtn2 = document.getElementById("prev_step_2") as HTMLElement;
            let prevbtn3 = document.getElementById("prev_step_3") as HTMLElement;
            let prevbtn4 = document.getElementById("prev_step_4") as HTMLElement;
      
            document.getElementById("prev_step_2").setAttribute("class", "d-none");
            document.getElementById("step3").setAttribute("class", "d-none");
            document.getElementById("step4").setAttribute("class", "d-none");
      
            nextbtn2.onclick = () => {
              document.getElementById("step2").setAttribute("class", "d-none");
              document.getElementById("step3").setAttribute("class", "d-block");
            };
      
            nextbtn3.onclick = () => {
              document.getElementById("step3").setAttribute("class", "d-none");
              document.getElementById("step4").setAttribute("class", "d-block");
            };
      
            prevbtn2.onclick = () => {
              document.getElementById("step2").setAttribute("class", "d-none");
            };
      
            prevbtn3.onclick = () => {
              document.getElementById("step3").setAttribute("class", "d-none");
              document.getElementById("step2").setAttribute("class", "d-block");
            };
      
            prevbtn4.onclick = () => {
              document.getElementById("step4").setAttribute("class", "d-none");
              document.getElementById("step3").setAttribute("class", "d-block");
            };
      
            if (
              sessionStorage.getItem("role_name") == "Super Admin" ||
              sessionStorage.getItem("role_name") == "Project Manager"
            ) {
              document.querySelectorAll(".readonly").forEach(function (element) {
                element.setAttribute("readonly", "true");
              });
            }
      
            document.querySelectorAll(".hideonpreview").forEach(function (element) {
              element.setAttribute("class", "d-none");
            });   

          }
        });

    }

    getSelectedOptions(select) {
      var selectedOptions = [];
      var options = select.options;
  
      for (var i = 0; i < options.length; i++) {
          if (options[i].selected) {
              selectedOptions.push(options[i].value);
          }
      }
  
      return selectedOptions;
  }
  
    focusTagInput(value: any): void {
      if (value == "sectors") {
        this.tagInputRef_sectors.nativeElement.focus();
      } else if (value == "subsectors") {
        this.tagInputRef_subsectors.nativeElement.focus();
      } else if (value == "keywords") {
        this.tagInputRef_keywords.nativeElement.focus();
      } else {
      }
    }
  
    onKeyUp(event: KeyboardEvent, value: any): void {
      if (value == "sectors") {
        this.tag_s = true;
        this.tag_sb = false;
        this.tag_k = false;
        this.inputValue = this.applicationForm.controls.tag.value;
        document
          .getElementById("sector")
          .setAttribute("class", "removeautofocus");
      } else if (value == "subsectors") {
        this.tag_s = false;
        this.tag_sb = true;
        this.tag_k = false;
        this.inputValue = this.applicationForm.controls.businessSubSector.value;
        document
          .getElementById("subsector")
          .setAttribute("class", "removeautofocus");
      } else if (value == "keywords") {
        this.tag_s = false;
        this.tag_sb = false;
        this.tag_k = true;
        this.inputValue = this.applicationForm.controls.keywordsTag.value;
        document
          .getElementById("keyword")
          .setAttribute("class", "removeautofocus");
      }
  
      if (event.code === "Backspace" && !this.inputValue) {
        this.removeTag();
        return;
      } else {
        if (
          event.code === "Comma" ||
          event.code === "Space" ||
          event.code === "Enter"
        ) {
          console.log("input value", this.inputValue);
          this.addTag(this.inputValue);
          if (this.tag_s) {
            this.applicationForm.controls.tag.setValue("");
          } else if (this.tag_sb) {
            this.applicationForm.controls.businessSubSector.setValue("");
          } else if (this.tag_k) {
            this.applicationForm.controls.keywordsTag.setValue("");
          }
        }
      }
    }
  
    addTag(tag: string): void {
      if (tag[tag.length - 1] === "," || tag[tag.length - 1] === " ") {
        tag = tag.slice(0, -1);
      }
      if (tag.length > 0 && !find(this.tags, tag)) {
        if (this.tag_s) {
          console.log("tags", this.tags_sectors);
          this.tags_sectors.push(tag);
        } else if (this.tag_sb) {
          this.tags_subsectors.push(tag);
          console.log("tags_sub", this.tags_subsectors);
        } else if (this.tag_k) {
          this.tags_keywords.push(tag);
        } else {
        }
      }
    }
  
    removeTag(tag?: string, value?: any): void {
      if (!!tag) {
        console.log("1");
        if (value == "sectors") {
          pull(this.tags_sectors, tag);
        } else if (value == "subsectors") {
          pull(this.tags_subsectors, tag);
        } else if (value == "keywords") {
          pull(this.tags_keywords, tag);
        }
      } else {
        if (value == "sectors") {
          this.tags_sectors.splice(-1);
        } else if (value == "subsectors") {
          this.tags_subsectors.splice(-1);
        } else if (value == "keywords") {
          this.tags_keywords.splice(-1);
        }
      }
    }
  
    changeEditorConfig() {
      this.Editor = true;
    }
    get editors(): FormArray {
      return this.editorsForm.get("editors") as FormArray;
    }
    get menus(): FormArray {
      return this.editorsForm.get("menus") as FormArray;
    }
    get addFields(): FormArray {
      return this.editorsForm.get("addFields") as FormArray;
    }

    get editors3(): FormArray {
      return this.editors3Form.get("editors") as FormArray;
    }
    get menus3(): FormArray {
      return this.editors3Form.get("menus") as FormArray;
    }
    get addFields3(): FormArray {
      return this.editors3Form.get("addFields") as FormArray;
    }

    get editors4(): FormArray {
      return this.editors4Form.get("editors") as FormArray;
    }
    get menus4(): FormArray {
      return this.editors4Form.get("menus") as FormArray;
    }
    get addFields4(): FormArray {
      return this.editors4Form.get("addFields") as FormArray;
    }
  
    addEditor(step:string) {
      //document.getElementById('next_step_4')?.setAttribute('disabled','true');
      console.log('hello from array');

      if(step=='step2'){
        const editor = this.fb.control("");
        this.editors.push(editor);
        const menu = this.fb.control("");
        this.menus.push(menu);
  
    
        setTimeout(()=>{
          console.log('selectfieldclass',document.querySelectorAll('.selectfield'));
          document.querySelectorAll('.selectfield').forEach((element,i)=>{
            console.log('selectelement',element);
            element.setAttribute('id',`selectfield_${i}`);
          })
  
        
        },500)
      }
      
      else if(step=='step3'){
        const editor = this.fb.control("");
        this.editors3.push(editor);
        const menu = this.fb.control("");
        this.menus.push(menu);
  
    
        setTimeout(()=>{
          console.log('selectfieldclass',document.querySelectorAll('.selectfield'));
          document.querySelectorAll('.selectfield').forEach((element,i)=>{
            console.log('selectelement',element);
            element.setAttribute('id',`selectfield_${i}`);
          })
  
        
        },500)
      }

      else if(step=='step4'){
        const editor = this.fb.control("");
        this.editors4.push(editor);
        const menu = this.fb.control("");
        this.menus.push(menu);
  
    
        setTimeout(()=>{
          console.log('selectfieldclass',document.querySelectorAll('.selectfield'));
          document.querySelectorAll('.selectfield').forEach((element,i)=>{
            console.log('selectelement',element);
            element.setAttribute('id',`selectfield_${i}`);
          })
  
        
        },500)
      }
  
    }
  
    observeElement(){
      var elementToObserve = document.querySelector("mandatory_0");
      if(elementToObserve ! = null){
        const observer = new MutationObserver(() => {
          console.log("callback that runs when observer is triggered");
        });
        
        observer.observe(elementToObserve, { subtree: true, childList: true });
      }
  
    }
    addQuestions() {
      let parent = document.getElementById("addhere") as HTMLElement;
      parent.setAttribute('class','w-100');
      console.log("parent", parent);
      let innerhtml = `
      <input type="text" name="" id="" class="form-control my-2 label_question" placeholder="enter question">
      <label class="addlabel"></label>
      <div class="w-100">
      <select class="float-right selectfield">
        <option value="">Select</option>
        <option value="textarea">Long Answer</option>
        <option value="text">Sort Answer</option>
        <option value="radio">Radio</option>
        <option value="checkbox">Checkbox</option>
        <option value="file">File Upload</option>
        <option value="select">Drop Down</option>
        <option value="number">Enter Number</option>
        <option value="date">Select Date</option>
      </select>
      </div>
      <div class="answer"></div>
      `;
      $("#addhere").append(innerhtml);

  
      document.querySelectorAll(".selectfield").forEach((element, i) => {
        console.log("selectindex", i);
        console.log("element", element);
       
  
        element.addEventListener("change", ($event: any) => {
          console.log("hello");
          document.getElementsByClassName("answer")[i].setAttribute("id", `answer_${i}`);
            this.test($event, i);
        }); 
      });
      
      async function addIdtoLabel(){
        return new Promise((resolve,reject)=>{
          document.querySelectorAll('.addlabel').forEach((element,i)=>{
            element.setAttribute('id',`addlabel_${i}`);
            resolve(element);
          })
        })
      }
  
      document.querySelectorAll('.label_question').forEach((element,i)=>{
        element.setAttribute('id',`label_question_${i}`);
        console.log('getelementbyid',document.getElementById(`label_question_${i}`));
        document.getElementById(`label_question_${i}`).onkeyup = async($event) => {
          await addIdtoLabel();
          await this.getlabelforpreview($event);
          console.log('add label');
          console.log('addlabel123454565',`addlabel_${i}`,document.getElementById(`addlabel_${i}`));
          document.getElementById(`addlabel_${i}`).innerHTML = this.label;
        }
      })
  
  
    }
  
  
    test(event: any, i: number) {
      console.log("from test");
      console.log("label", this.label);
      this.parent = document.getElementById(
        `answer_${i}`
      ) as HTMLElement;
      console.log("parent123", this.parent);

  
      if (event.target.value != "") {
        let childnode_label = document.createElement("label");
        childnode_label.innerHTML = this.label;
        console.log('parent',this.parent);
        this.parent.appendChild(childnode_label);
        if (event.target.value != "select") {
          if (event.target.value != "textarea") {
            if(event.target.value == 'radio'|| event.target.value == 'checkbox'){
              var index = i;
              let innerHTML = `
              <div id="divradio_test_${i}" class="d-flex" name="${this.label}">
              
              </div>
              <div id="placeinputhere_test_${i}"></div>
              <input type="checkbox" id="mandatory_test_${i}" class="mandatory_test" value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
              <button class="btn btn-primary addmoreradio_test" id="addmoreradio_test_${i}">+</button>
              `;
        this.parent.innerHTML = innerHTML;
            }
            else {
              let innerHTML = `
  
              <input type="${event.target.value}" id="input_test_${i}" name="${this.label}" class="form-control">
              <input type="checkbox" id="mandatory_test_${i}" class="mandatory_test"  value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
              <button class="btn btn-primary addmoreradio_test d-none" id="addmoreradio_test_${i}">+</button>
              `;
              this.parent.innerHTML = innerHTML;
            }
  
            document.querySelectorAll(".mandatory_test").forEach((element,i) => {
              console.log('mandatory',document.getElementById(`mandatory_test_${i}`));
              if(document.getElementById(`mandatory_test_${i}`)!=null){
                document.getElementById(`mandatory_test_${i}`).onclick = async($event) => {
                  console.log('clicked on mandatory');
                  await this.getchecked($event,i);
                  console.log('mandatory_id',`mandatory_test_${i}`);
                  if(this.checked == true){
                    document.getElementById(`input_test_${i}`).setAttribute('required','true');
                  }
                  else if(this.checked == false){
                    document.getElementById(`input_test_${i}`).setAttribute('required','false');
                  }
                  console.log('input_id',document.getElementById(`input_test_${i}`));
                }
              }
     
            });
  
            document.querySelectorAll(".addmoreradio_test").forEach((element,i)=>{
              let count = 2
              console.log('radioelement',element);
              
              document.getElementById(`addmoreradio_test_${i}`).onclick = (e) => {
                e.preventDefault();
                console.log('addmoreradio',`addmoreradio_test_${i}`);
                let innerHTML = `
                <input type="text" placeholder="add option" class="my-2" id="inputoption_test_${i}">
                <button class="btn btn-primary submitoption_test d-none" id="submitoption_test_${i}">Submit</button>
                `;
                
                document.getElementById(`divradio_test_${i}`).innerHTML= innerHTML;
                console.log('inputid',`inputoption_test_${i}`);
                        
                console.log('doc_id',document.getElementById(`inputoption_test_${i}`))
          
                if(document.getElementById(`inputoption_test_${i}`)!=null){
                  document.getElementById(`inputoption_test_${i}`).onkeyup= (event)=>{
                    let value = (<HTMLInputElement>document.getElementById(`inputoption_test_${i}`)).value;
                    let value_trim = value.replace(/ /g,'');
                    console.log('value1234',value);
                    if(value_trim != ""){
                      document.getElementById(`submitoption_test_${i}`).classList.remove('d-none');
                      document.getElementById(`submitoption_test_${i}`).className+=' d-block';
                    }
                    else{
                      document.getElementById(`submitoption_test_${i}`).classList.remove('d-block');
                      document.getElementById(`submitoption_test_${i}`).className+=' d-none';
                    }
                  }
                }
                console.log('test_________1234',document.getElementById(`divradio_test_${i}`));
                document.getElementById(`submitoption_test_${i}`).onclick = (e) => {
                  e.preventDefault();
                  console.log('submitoption',`submitoption_test_${i}`);
                  let span = document.createElement("span");
                  let childnode_radio = document.createElement("input");
                  let input = (<HTMLInputElement>document.getElementById(`inputoption_test_${i}`));
                  if(input.value!=''){
                    span.innerText = input.value;
                    childnode_radio.setAttribute("type", event.target.value);
                    document.getElementById(`placeinputhere_test_${i}`).appendChild(span);
                    document.getElementById(`placeinputhere_test_${i}`).appendChild(childnode_radio);
                    input.value = '';
                    document.getElementById(`inputoption_test_${i}`).setAttribute('class','d-none');
                    document.getElementById(`submitoption_test_${i}`).setAttribute('class','d-none');
                  }
                }
  
                
              }
            })
  
  
    
          } else {
            let innerHTML = `
            <textarea class="form-control" id="input_${i}" name="${this.label}"></textarea>
            <input type="checkbox" id="mandatory_test_${i}" class="mandatory_test" value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
            <button class="btn btn-primary addmoreradio_test d-none" id="addmoreradio_test_${i}">+</button>
            `
            this.parent.innerHTML = innerHTML;
  
            document.querySelectorAll(".mandatorytest").forEach((element,i) => {
              document.getElementById(`mandatory_test_${i}`).onclick = async($event) => {
                await this.getchecked($event,i);
                console.log('mandatory_id',`mandatory_test_${i}`);
                if(this.checked == true){
                  document.getElementById(`input_test_${i}`).setAttribute('required','true');
                }
                else if(this.checked == false){
                  document.getElementById(`input_test_${i}`).setAttribute('required','false');
                }
                console.log('input_id',document.getElementById(`input_test_${i}`));
              }
            });
  
          }
        } else {
          let innerHTML = `
            <select class="form-control" id="input_test_${i}" name="${this.label}">
              
            </select>
            <input type="checkbox" id="mandatory_test_${i}" class="mandatory+_test" value="mandatory"><span class="mandatorytext">This Field is Mandatory</span>
            <button class="btn btn-primary addmoreoption_test" id="addmoreoption_test_${i}">+Add</button>
          `
            this.parent.innerHTML = innerHTML;
  
            document.querySelectorAll(".mandatory_test").forEach((element,i) => {
              if(document.getElementById(`mandatory_test_${i}`)!=null){
                document.getElementById(`mandatory_test_${i}`).onclick = async($event) => {
                  await this.getchecked($event,i);
                  console.log('mandatory_id',`mandatory_test_${i}`);
                  if(this.checked == true){
                    document.getElementById(`input_test_${i}`).setAttribute('required','true');
                  }
                  else if(this.checked == false){
                    document.getElementById(`input_test_${i}`).setAttribute('required','false');
                  }
                  console.log('input_id',document.getElementById(`input_test_${i}`));
                }
              }
    
            });
  
            document.querySelectorAll(".addmoreoption_test").forEach((element,i)=>{
              let count = 2
              console.log('radioelement',element);
              if(document.getElementById(`addmoreoption_test`)!=null){
              document.getElementById(`addmoreoption_test_${i}`).onclick = () => {
                
                console.log('addmoreoption',`addmoreoption_test_${i}`);
                let option = document.createElement("option");
                option.text = `option ${count++}`;
                option.value = `option ${count++}`
     
                document.getElementById(`input_test_${i}`).appendChild(option);
                
              }
            }
            })
  
        }
      }
    }
  
    addInput_m(i:any,event:any){
      this.addInput(i,event);
    }
    
    addInput_scorable(i:any , event:any){
      if(event.target.checked){
        document.getElementById(`label${i}`).classList.add('score_label');
        // alert(event.target.value);
        document.getElementById(`answer${i}`).classList.remove('d-none');
        this.addInput(i,event);
      }
      else{
        document.getElementById(`answer${i}`).classList.add('d-none');
        document.getElementById(`label${i}`).classList.remove('score_label');
      }
    }
  
    
    addInput(i: any, event: any) {
      console.log('index123----',i);
      var index = i;
      console.log('event123----',event);
      console.log("label", this.label);
      var form = document.getElementById(`answer${i}`) as HTMLElement;
      console.log('form_id_________',form);
      if (event.target.value != "") {
        if(event.target.value != "scorable"){
          let childnode_label = document.createElement("label");
          childnode_label.innerHTML = this.label;
          form.appendChild(childnode_label);
          console.log('questionlabel',document.getElementById(`answer_${i}`))
          // if (event.target.value != "select") {
            if (event.target.value != "textarea") {
              if(event.target.value!='checkbox' && event.target.value!='radio' && event.target.value!='select'){
                this.selectedinput = 'shortanswer';
              }
              if(event.target.value == 'checkbox' || event.target.value == 'radio' || event.target.value == 'select'){
                //alert('here_add_select__');
                this.selectedinput = event.target.value;
                let innerHTML1 = `
               
                <div id="placeinputhere_${i}" class="placeinputhere">
                <span class="text-danger ml-1 font-weight-bold float-right showlabel d-none" id="showlabel_${i}">*</span>
                </div>
                <input type="checkbox" id="mandatory_${i}" class="mandatory" value="mandatory"><span class="mandatorytext" id="mandatorytext_${i}">This Field is Mandatory</span>
                <select id="pattern_${i}" class="form-control validationpattern">
                  <option value="">Select Validation Pattern</option>
                  <option value="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">email</option>
                  <option value="^[A-Z]{5}[0-9]{4}[A-Z]$">PAN</option>
                </select>
                <div class="mt-3">
                <button class="btn btn-info addmoreradio" id="addmoreradio_${i}">+ Add Options</button>
                <div id="divradio_${i}" class="d-flex mt-3">
        
                </div>
                </div>
                `;
    
                form.innerHTML = innerHTML1;
    
                document.querySelectorAll(".addmoreradio").forEach((element,i)=>{
                  console.log("addopts",element);
                  let count = 2
                  console.log('radioelement__________',element);
                  console.log('radioindex',this.longindex);
                  console.log('selectedinput',this.selectedinput);
                  if(this.selectedinput == 'longanswer'){
                    console.log('null check',document.getElementById(`addmoreradio_${this.longindex}`));
                    if(document.getElementById(`addmoreradio_${this.longindex}`)!=null){
                      document.getElementById(`addmoreradio_${this.longindex}`).onclick = () => {
                        console.log('addmoreradio123456',`addmoreradio_${this.longindex}`);
                        let innerHTML = `
                        <div class="d-flex justify-content-between align-items-center w-100 form-control border-input optionsdiv border p-1" id="optionsdiv_${this.longindex}">
                        <input type="text" placeholder="add option" class="optionvalue bg-transparent border-0 w-100 my-2" id="input_${this.longindex}">
                        <i class="fa fa-check submitoption text-success d-none" id="submitoption_${this.longindex}" aria-hidden="true"></i>
                        </div>
                        `;
                        document.getElementById(`divradio_${this.longindex}`).innerHTML= innerHTML;
                        console.log('here long');
                        if(document.getElementById(`input_${this.longindex}`)!=null){
                          document.getElementById(`input_${this.longindex}`).onkeyup= (event)=>{
                            let value = (<HTMLInputElement>document.getElementById(`input_${this.longindex}`)).value;
                            let value_trim = value.replace(/ /g,'');
                            console.log('value1234',value);
                            if(value_trim != ""){
                              document.getElementById(`submitoption_${this.longindex}`).classList.remove('d-none');
                              document.getElementById(`submitoption_${this.longindex}`).className+=' d-block';
                            }
                            else{
                              document.getElementById(`submitoption_${this.longindex}`).classList.remove('d-block');
                              document.getElementById(`submitoption_${this.longindex}`).className+=' d-none';
                            }
                          }
                        }
                        console.log('test_________1234',document.getElementById(`divradio_${this.longindex}`));
                        document.getElementById(`submitoption_${this.longindex}`).onclick = () => {
                          console.log('submitoption',`submitoption_${this.longindex}`);
                          let span = document.createElement("span");
                          span.setAttribute('class','customspan');
                          let childnode_radio = document.createElement("input");
                          let breaktag = document.createElement('br');
                          childnode_radio.setAttribute('class',`customradio_${this.longindex}`);
                          childnode_radio.setAttribute('id',`customradio_${this.longindex}`);
                          childnode_radio.setAttribute('name',this.label);
                          childnode_radio.setAttribute('data-disable',`disable_${this.longindex}`);
                          let input = (<HTMLInputElement>document.getElementById(`input_${this.longindex}`));
                          if(input.value!=''){
                            childnode_radio.setAttribute('value',input.value);
                            span.innerText = input.value;
                            childnode_radio.setAttribute("type", event.target.value);
                            document.getElementById(`placeinputhere_${this.longindex}`).appendChild(childnode_radio);
                            document.getElementById(`placeinputhere_${this.longindex}`).appendChild(span);
                            document.getElementById(`placeinputhere_${this.longindex}`).appendChild(breaktag);
                            document.getElementById(`placeinputhere_${this.longindex}`).appendChild(breaktag);
                            let disabled = document.querySelectorAll('.customradio');
                            for(var k = 0 ; k < disabled.length ; k++){
                              disabled[k].setAttribute('disabled','true')
                            }
                            console.log('disabled',disabled);
                            input.value = '';
                            document.getElementById(`optionsdiv_${this.longindex}`).setAttribute('class','d-none');
                            document.getElementById(`inputoption_${this.longindex}`).setAttribute('class','d-none');
                            document.getElementById(`submitoption_${this.longindex}`).setAttribute('class','d-none');
                          }
                        }     
                      }
                    }
                  }
                  else if(this.selectedinput == 'shortanswer'){
                    console.log('index____short',i+1);
                    console.log('addmoreradio_short',`addmoreradio_${i+1}`);
                    console.log('null check short',document.getElementById(`addmoreradio_${i+1}`));
                    if(document.getElementById(`addmoreradio_${i+1}`)!=null){
                      document.getElementById(`addmoreradio_${i+1}`).onclick = () => {
                        console.log('addmoreradio12345678',`addmoreradio_${i+1}`);
                        let innerHTML = `
                        <div class="d-flex justify-content-between align-items-center w-100 form-control border-input optionsdiv border p-1" id="optionsdiv_${i+1}">
                        <input type="text" placeholder="add option" class="optionvalue bg-transparent border-0 w-100 my-2" id="input_${i+1}">
                        <i class="fa fa-check submitoption text-success" id="submitoption_${i+1}" aria-hidden="true"></i>
                        </div>
                        `;
                        document.getElementById(`divradio_${i+1}`).innerHTML= innerHTML;
                        
                        console.log('test_________1234',document.getElementById(`divradio_${i+1}`));
                        document.getElementById(`submitoption_${i+1}`).onclick = () => {
                          console.log('submitoption',`submitoption_${i+1}`);
                          let span = document.createElement("span");
                          span.setAttribute('class','customspan');
                          let childnode_radio = document.createElement("input");
                          let breaktag = document.createElement('br');
                          childnode_radio.setAttribute('class',`customradio_${i+1}`);
                          childnode_radio.setAttribute('id',`customradio_${i+1}`);
                          childnode_radio.setAttribute('data-disable',`disable_${i+1}`);
                          childnode_radio.setAttribute('name',this.label);
                          let input = (<HTMLInputElement>document.getElementById(`input_${i+1}`));
                          if(input.value!=''){
                            childnode_radio.setAttribute('value',input.value);
                            span.innerText = input.value;
                            childnode_radio.setAttribute("type", event.target.value);
                            document.getElementById(`placeinputhere_${i+1}`).appendChild(childnode_radio);
                            document.getElementById(`placeinputhere_${i+1}`).appendChild(span);
                            document.getElementById(`placeinputhere_${i+1}`).appendChild(breaktag);
                            document.getElementById(`placeinputhere_${i+1}`).appendChild(breaktag);
                            let disabled = document.querySelectorAll('.customradio');
                            console.log('disabled___',disabled);
                            for(var k = 0 ; k < disabled.length ; k++){
                              disabled[k].setAttribute('disabled','true')
                            }
                            
                            input.value = '';
                            document.getElementById(`optionsdiv_${i+1}`).setAttribute('class','d-none');
                            document.getElementById(`inputoption_${i+1}`).setAttribute('class','d-none');
                            document.getElementById(`submitoption_${i+1}`).setAttribute('class','d-none');
                          }
                        }     
                      }
                    }
                  }
                  else if(this.selectedinput == 'radio'){
                    //alert('radio');
    
  
                    document.getElementById(`addmoreradio_${index}`).onclick = () => {
                      let innerHTML = `
                      
                      <div class="d-flex justify-content-between align-items-center w-100 form-control border-input optionsdiv border p-1" id="optionsdiv_${index}">
                      
                      <input type="text" placeholder="add option" class="optionvalue bg-transparent border-0 w-100 my-2" id="input_${index}">
                      <i class="fa fa-check submitoption text-success d-none" id="submitoption_${index}" aria-hidden="true"></i>
                      </div>
                      <span class="text-danger ml-1 font-weight-bold float-right showlabel d-none" id="showlabel_${index}">*</span>
                      `;
                      document.getElementById(`divradio_${index}`).innerHTML= innerHTML;
                      if(document.getElementById(`input_${index}`)!=null){
                        document.getElementById(`input_${index}`).onkeyup= (event)=>{
                          let value = (<HTMLInputElement>document.getElementById(`input_${index}`)).value;
                          let value_trim = value.replace(/ /g,'');
                          console.log('value1234',value);
                          if(value_trim != ""){
                            document.getElementById(`submitoption_${index}`).classList.remove('d-none');
                            document.getElementById(`submitoption_${index}`).className+=' d-block';
                          }
                          else{
                            document.getElementById(`submitoption_${index}`).classList.remove('d-block');
                            document.getElementById(`submitoption_${index}`).className+=' d-none';
                          }
                        }
                      }
                      console.log('test_________1234',document.getElementById(`divradio_${index}`));
                      document.getElementById(`submitoption_${index}`).onclick = () => {
                        //alert('submitted here');
                        console.log('submitoption',`submitoption_${index}`);
                        let span = document.createElement("span");
                        span.setAttribute('class','customspan');
                        let childnode_radio = document.createElement("input");
                        let breaktag = document.createElement('br');
                        childnode_radio.setAttribute('class',`customradio_${index}`);
                        childnode_radio.setAttribute('id',`customradio_${index}`);
                        childnode_radio.setAttribute('data-disable',`disable_${index}`);
                        // if(event.target.value == 'checkbox'){
  
                        //   console.log('customradio',document.getElementById(`customradio_${index}`),index);
                        //   childnode_radio.setAttribute('name',`${this.label}[]`);
                        // }
                        // else{
                        //   childnode_radio.setAttribute('name',this.label);
                        // }
                        childnode_radio.setAttribute('name',this.label);
                        let input = (<HTMLInputElement>document.getElementById(`input_${index}`));
                        if(input.value!=''){
                          childnode_radio.setAttribute('value',input.value);
                          span.innerText = input.value;
                          childnode_radio.setAttribute("type", event.target.value);
                          document.getElementById(`placeinputhere_${index}`).appendChild(childnode_radio);
                          document.getElementById(`placeinputhere_${index}`).appendChild(span);
                          document.getElementById(`placeinputhere_${index}`).appendChild(breaktag);
                          document.getElementById(`placeinputhere_${index}`).appendChild(breaktag);
                          let disabled = document.querySelectorAll('.customradio');
                          for(var k = 0 ; k < disabled.length ; k++){
                            disabled[k].setAttribute('disabled','true')
                          }
                          console.log('disabled',disabled);
                          input.value = '';
                          document.getElementById(`optionsdiv_${index}`).setAttribute('class','d-none');
                          document.getElementById(`inputoption_${index}`).setAttribute('class','d-none');
                          document.getElementById(`submitoption_${index}`).setAttribute('class','d-none');
                        }
                      }     
                    }
          
                  }
  
                  else if(this.selectedinput == 'select'){
                    alert('selected')
                    let innerHTML = `
              <select class="form-control" id="input_${index}" name="${this.label}">
              </select>
              <input type="checkbox" id="mandatory_${index}" class="mandatory" value="mandatory"><span class="mandatorytext show" id="mandatorytext_${index}">This Field is Mandatory</span>
              <select id="pattern_${index}" class="form-control validationpattern">
                  <option value="">Select Validation Pattern</option>
                  <option value="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">email</option>
                  <option value="^[A-Z]{5}[0-9]{4}[A-Z]$">PAN</option>
                </select>
              <input type="checkbox" id="multiple_${index}" class="mandatory"><span class="mandatorytext">Multiselect</span>
              <button class="btn btn-primary addmoreoption" id="addmoreoption_${index}">+Add</button>
            `
              form.innerHTML = innerHTML;
  
              document.getElementById(`multiple_${index}`).onclick = (e:any) => {
                //alert(index);
                e.preventDefault();
                if(e.target.checked == true){
                  document.getElementById(`input_${index}`).setAttribute('multiple','');
                  //document.getElementById(`input_${index}`).setAttribute('name',`${this.label}[]`);
                }
                else{
                  document.getElementById(`input_${index}`).removeAttribute('multiple');
                  //document.getElementById(`input_${index}`).setAttribute('name',`${this.label}`);
                }
                // document.getElementById(`input_${index}`).setAttribute('multiple','');
              }
  
              document.querySelectorAll(".mandatory").forEach((element,i) => {
                if(document.getElementById(`mandatory_${i}`)!=null){
                  document.getElementById(`mandatory_${i}`).onclick = async($event) => {
                    console.log('1');
                    await this.getchecked($event,i);
                    console.log('mandatory_id',`mandatory_${i}`);
                    if(this.checked == true){
                      document.getElementById(`input_${i}`).setAttribute('required','true');
                    }
                    else if(this.checked == false){
                      document.getElementById(`input_${i}`).setAttribute('required','false');
                    }
                    console.log('input_id',document.getElementById(`input_${i}`));
                  }
                }
      
              });

              if(document.getElementById(`pattern_${index}`)!=null){
                document.getElementById(`pattern_${index}`).onclick = async($event) => {
                  console.log('pattern');
                  //await this.getchecked($event,i);
                  await this.selectedPattern($event,index);
                  //console.log('mandatory_id',`pattern_${index}`);
                  // if(this.checked == true){
                  //   document.getElementById(`input_${i}`).setAttribute('required','true');
                  //   document.querySelectorAll(`.input_${i}`).forEach((element,i)=>{
                  //     element.className+=' requiredqa';
                  //   })
                   
                  //   document.getElementById(`showlabel_${i}`).classList.remove('d-none');
                  // }
                  // else if(this.checked == false){
                  //   document.getElementById(`input_${i}`).setAttribute('required','false');
                  //   document.querySelectorAll(`.input_${i}`).forEach((element,i)=>{
                  //     element.classList.remove('requiredqa');
                  //   })
                  //   document.getElementById(`showlabel_${i}`).className += ' d-none';
                  // }
                  //console.log('input_id',document.getElementById(`input_${i}`));
                }
              }
  
              document.getElementById(`addmoreoption_${index}`).onclick = () => {
                //alert('add more option');
                var userInput = prompt("Please enter a value:");
                let option = document.createElement("option");
                option.text = userInput;
                option.value = userInput;
     
                document.getElementById(`input_${index}`).appendChild(option);
              
              }
    
                  }
                  else if(this.selectedinput == 'checkbox'){
                    //alert('checkbox');
                    document.getElementById(`addmoreradio_${index}`).onclick = () => {
                      let innerHTML = `
                      
                      <div class="d-flex justify-content-between align-items-center w-100 form-control border-input optionsdiv border p-1" id="optionsdiv_${index}">
                      
                      <input type="text" placeholder="add option" class="optionvalue bg-transparent border-0 w-100 my-2" id="input_${index}">
                      <i class="fa fa-check submitoption text-success d-none" id="submitoption_${index}" aria-hidden="true"></i>
                      </div>
                      <span class="text-danger ml-1 font-weight-bold float-right showlabel d-none" id="showlabel_${index}">*</span>
                      `;
                      document.getElementById(`divradio_${index}`).innerHTML= innerHTML;
                      if(document.getElementById(`input_${index}`)!=null){
                        document.getElementById(`input_${index}`).onkeyup= (event)=>{
                          let value = (<HTMLInputElement>document.getElementById(`input_${index}`)).value;
                          let value_trim = value.replace(/ /g,'');
                          console.log('value1234',value);
                          if(value_trim != ""){
                            document.getElementById(`submitoption_${index}`).classList.remove('d-none');
                            document.getElementById(`submitoption_${index}`).className+=' d-block';
                          }
                          else{
                            document.getElementById(`submitoption_${index}`).classList.remove('d-block');
                            document.getElementById(`submitoption_${index}`).className+=' d-none';
                          }
                        }
                      }
                      console.log('test_________1234',document.getElementById(`divradio_${index}`));
                      document.getElementById(`submitoption_${index}`).onclick = () => {
                        console.log('submitoption',`submitoption_${index}`);
                        let span = document.createElement("span");
                        span.setAttribute('class','customspan');
                        let childnode_radio = document.createElement("input");
                        let breaktag = document.createElement('br');
                        childnode_radio.setAttribute('class',`customradio_${index}`);
                        childnode_radio.setAttribute('id',`customradio_${index}`);
                        childnode_radio.setAttribute('data-disable',`disable_${index}`);
  
                        // if(event.target.value == 'checkbox'){
  
                        //   console.log('customradio',document.getElementById(`customradio_${index}`),index);
                        //   childnode_radio.setAttribute('name',`${this.label}[]`);
                        // }
                        // else{
                        //   childnode_radio.setAttribute('name',this.label);
                        // }
                        childnode_radio.setAttribute('name',this.label);
                        let input = (<HTMLInputElement>document.getElementById(`input_${index}`));
                        if(input.value!=''){
                          childnode_radio.setAttribute('value',input.value);
                          span.innerText = input.value;
                          childnode_radio.setAttribute("type", event.target.value);
                          document.getElementById(`placeinputhere_${index}`).appendChild(childnode_radio);
                          document.getElementById(`placeinputhere_${index}`).appendChild(span);
                          document.getElementById(`placeinputhere_${index}`).appendChild(breaktag);
                          document.getElementById(`placeinputhere_${index}`).appendChild(breaktag);
                          let disabled = document.querySelectorAll('.customradio');
                          for(var k = 0 ; k < disabled.length ; k++){
                            disabled[k].setAttribute('disabled','true')
                          }
                          console.log('disabled',disabled);
                          input.value = '';
                          document.getElementById(`optionsdiv_${index}`).setAttribute('class','d-none');
                          document.getElementById(`inputoption_${index}`).setAttribute('class','d-none');
                          document.getElementById(`submitoption_${index}`).setAttribute('class','d-none');
                        }
                      }     
                    }
                  }
                })
                
                document.querySelectorAll(".mandatory").forEach((element,i) => {
                  console.log('checkmandatorynull',document.getElementById(`mandatory_${i}`))
                  if(document.getElementById(`mandatory_${i}`)!=null){
                    document.getElementById(`mandatory_${i}`).onclick = async($event) => {
                      console.log('2');
                      await this.getchecked($event,i);
                      console.log('mandatory_id',`mandatory_${i}`);
                      if(this.checked == true){
                        document.getElementById(`input_${i}`).setAttribute('required','true');
                        document.querySelectorAll(`.input_${i}`).forEach((element,i)=>{
                          element.className+=' requiredqa';
                        })
                       
                        document.getElementById(`showlabel_${i}`).classList.remove('d-none');
                      }
                      else if(this.checked == false){
                        document.getElementById(`input_${i}`).setAttribute('required','false');
                        document.querySelectorAll(`.input_${i}`).forEach((element,i)=>{
                          element.classList.remove('requiredqa');
                        })
                        document.getElementById(`showlabel_${i}`).className += ' d-none';
                      }
                      console.log('input_id',document.getElementById(`input_${i}`));
                    }
                  }

                  if(document.getElementById(`pattern_${i}`)!=null){
                    document.getElementById(`pattern_${i}`).onclick = async($event) => {
                      console.log('pattern');
                      //await this.getchecked($event,i);
                      await this.selectedPattern($event,i);
                      console.log('mandatory_id',`pattern_${i}`);
                      // if(this.checked == true){
                      //   document.getElementById(`input_${i}`).setAttribute('required','true');
                      //   document.querySelectorAll(`.input_${i}`).forEach((element,i)=>{
                      //     element.className+=' requiredqa';
                      //   })
                       
                      //   document.getElementById(`showlabel_${i}`).classList.remove('d-none');
                      // }
                      // else if(this.checked == false){
                      //   document.getElementById(`input_${i}`).setAttribute('required','false');
                      //   document.querySelectorAll(`.input_${i}`).forEach((element,i)=>{
                      //     element.classList.remove('requiredqa');
                      //   })
                      //   document.getElementById(`showlabel_${i}`).className += ' d-none';
                      // }
                      console.log('input_id',document.getElementById(`input_${i}`));
                    }
                  }
         
                });
    
                console.log('radio___2017')
              }
    
              else{
                let innerHTML = `
                <span class="text-danger ml-1 font-weight-bold float-right showlabel d-none" id="showlabel_${i}">*</span>
                <input type="${event.target.value}" id="input_${i}" readonly name="${this.label}" class="form-control input_${i}">
                <input type="checkbox" id="mandatory_${i}" class="mandatory show"  value="mandatory"><span id="mandatorytext_${i}" class="mandatorytext show">This Field is Mandatory</span>
                <select id="pattern_${i}" class="form-control validationpattern">
                  <option value="">Select Validation Pattern</option>
                  <option value="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">email</option>
                  <option value="^[A-Z]{5}[0-9]{4}[A-Z]$">PAN</option>
                </select>
                <button class="btn btn-primary addmoreradio d-none" id="addmoreradio_${i}">+</button>
                
                `;
                form.innerHTML = innerHTML;
                console.log('short answer_2017');
                document.querySelectorAll(".mandatory").forEach((element,i) => {
                  console.log('checkmandatorynull',document.getElementById(`mandatory_${i}`))
                  if(document.getElementById(`mandatory_${i}`)!=null){
                    document.getElementById(`mandatory_${i}`).onclick = async($event) => {
                      console.log('2');
                      await this.getchecked($event,i);
                      console.log('mandatory_id',`mandatory_${i}`);
                      if(this.checked == true){
                        document.getElementById(`input_${i}`).setAttribute('required','true');
                        document.querySelectorAll(`.input_${i}`).forEach((element,i)=>{
                          element.className+=' requiredqa';
                        })
                       
                        document.getElementById(`showlabel_${i}`).classList.remove('d-none');
                      }
                      else if(this.checked == false){
                        document.getElementById(`input_${i}`).setAttribute('required','false');
                        document.querySelectorAll(`.input_${i}`).forEach((element,i)=>{
                          element.classList.remove('requiredqa');
                        })
                        document.getElementById(`showlabel_${i}`).className += ' d-none';
                      }
                      console.log('input_id',document.getElementById(`input_${i}`));
                    }
                  }

                  if(document.getElementById(`pattern_${i}`)!=null){
                    document.getElementById(`pattern_${i}`).onclick = async($event) => {
                      console.log('pattern');
                      //await this.getchecked($event,i);
                      await this.selectedPattern($event,i);
                      console.log('mandatory_id',`pattern_${i}`);
                      // if(this.checked == true){
                      //   document.getElementById(`input_${i}`).setAttribute('required','true');
                      //   document.querySelectorAll(`.input_${i}`).forEach((element,i)=>{
                      //     element.className+=' requiredqa';
                      //   })
                       
                      //   document.getElementById(`showlabel_${i}`).classList.remove('d-none');
                      // }
                      // else if(this.checked == false){
                      //   document.getElementById(`input_${i}`).setAttribute('required','false');
                      //   document.querySelectorAll(`.input_${i}`).forEach((element,i)=>{
                      //     element.classList.remove('requiredqa');
                      //   })
                      //   document.getElementById(`showlabel_${i}`).className += ' d-none';
                      // }
                      console.log('input_id',document.getElementById(`input_${i}`));
                    }
                  }
         
                });
              }
    
     
                console.log('enable next');
                console.log('next_step_4',document.getElementById('next_step_4'));
                document.getElementById('next_step_4').removeAttribute('disabled');
                document.querySelectorAll('.hideonpreview').forEach((element,i)=>{
                console.log('hideonpreview',element);
              })
    
      
            } 
            else {
              alert('long');
              this.longindex = 0;
              this.selectedinput = "longanswer";
              let innerHTML = `
              <span class="text-danger ml-1 font-weight-bold float-right showlabel d-none" id="showlabel_${i}">*</span>
              <textarea class="form-control" id="input_${i}" readonly name="${this.label}"></textarea>
              <input type="checkbox" id="mandatory_${i}" class="mandatory" value="mandatory"><span id="mandatorytext_${i}" class="mandatorytext show">This Field is Mandatory</span>

              <select id="pattern_${i}" class="form-control validationpattern">
              <option value="">Select Validation Pattern</option>
              <option value="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">email</option>
              <option value="^[A-Z]{5}[0-9]{4}[A-Z]$">PAN</option>
              </select>
              `
              form.innerHTML = innerHTML;
    
              this.longindex = i + 1;
    
              //document.getElementById('next_step_4')?.removeAttribute('disabled');
    
              document.querySelectorAll(".mandatory").forEach((element,i) => {
                document.getElementById(`mandatory_${i}`).onclick = async($event) => {
                  console.log('3');
                  await this.getchecked($event,i);
                  console.log('mandatory_id',`mandatory_${i}`);
                  if(this.checked == true){
                    document.getElementById(`input_${i}`).setAttribute('required','true');
                    document.getElementById(`input_${i}`).className+=' requiredqa';
                    document.getElementById(`showlabel_${i}`).classList.remove('d-none');
                  }
                  else if(this.checked == false){
                    document.getElementById(`input_${i}`).setAttribute('required','false');
                    document.getElementById(`input_${i}`).classList.remove('requiredqa');
                    document.getElementById(`showlabel_${i}`).className+=' d-none';
                  }
                  console.log('input_id',document.getElementById(`input_${i}`));
                }
              });
              if(document.getElementById(`pattern_${i}`)!=null){
                document.getElementById(`pattern_${i}`).onclick = async($event) => {
                await this.selectedPattern($event,i);
                }
                }
            }
        }
        else{
          alert('selected1')
          let innerHTML = `
          <select class="form-control" id="input_${index}" name="${this.label}">
          
          </select>
          <input type="checkbox" id="mandatory_${index}" class="mandatory" value="mandatory"><span class="mandatorytext show" id="mandatorytext_${index}">This Field is Mandatory</span>
          <input type="checkbox" id="multiple_${index}" class="mandatory"><span class="mandatorytext">Multiselect</span>
          <button class="btn btn-primary addmoreoption" id="addmoreoption_${index}">+Add</button>
        `
          form.innerHTML = innerHTML;

          document.getElementById(`multiple_${index}`).onclick = (e:any) => {
            //alert(index);
            e.preventDefault();
            if(e.target.checked == true){
              document.getElementById(`input_${index}`).setAttribute('multiple','');
              //document.getElementById(`input_${index}`).setAttribute('name',`${this.label}[]`);
            }
            else{
              document.getElementById(`input_${index}`).removeAttribute('multiple');
              //document.getElementById(`input_${index}`).setAttribute('name',`${this.label}`);
            }
            // document.getElementById(`input_${index}`).setAttribute('multiple','');
          }

          document.querySelectorAll(".mandatory").forEach((element,i) => {
            if(document.getElementById(`mandatory_${i}`)!=null){
              document.getElementById(`mandatory_${i}`).onclick = async($event) => {
                console.log('4');
                await this.getchecked($event,i);
                console.log('mandatory_id',`mandatory_${i}`);
                if(this.checked == true){
                  document.getElementById(`input_${i}`).setAttribute('required','true');
                }
                else if(this.checked == false){
                  document.getElementById(`input_${i}`).setAttribute('required','false');
                }
                console.log('input_id',document.getElementById(`input_${i}`));
              }
            }
  
          });

          document.getElementById(`addmoreoption_${index}`).onclick = () => {

            if(document.getElementById('step2')!=null){
              if(!this.criteria_label.includes(this.label)){
                this.criteria_label.push(this.label);
              }
              console.log('criteria_label2',this.criteria_label);
            }

            if(document.getElementById('step3')!=null){
              if(!this.criteria_label3.includes(this.label)){
                this.criteria_label3.push(this.label);
              }
              console.log('criteria_label3',this.criteria_label3);
            }

            if(document.getElementById('step4')!=null){
              if(!this.criteria_label4.includes(this.label)){
                this.criteria_label4.push(this.label);
              }
              console.log('criteria_label4',this.criteria_label4);
            }
            var userInput = prompt("Please enter a value:");
            let option = document.createElement("option");
            option.text = userInput;
            option.value = userInput;
            document.getElementById(`input_${index}`).appendChild(option);
            console.log('form1234----',document.getElementById(`criteriaform_${index}`));
            if(document.getElementById(`criteriaform_${index}`)==null){
              // if(!this.formids.includes(`criteriaform_${index}`)){
                this.formids.push(`criteriaform_${index}`);
              // }
              console.log('formids----',this.formids);
              var form = document.createElement('form');
              form.id = `criteriaform_${index}`;
              form.setAttribute('data-name',this.label);
              form.setAttribute('class','label_question criteriaform');
              // Create first input field
              // var label1 = document.createElement('label');
              // label1.textContent = 'Field 1:';
              // form.appendChild(label1);
  
              var input = document.createElement('input');
              input.type = 'number';
              // input1.id =  field1;
              input.name = option.value;
              input.placeholder = option.value;
              input.setAttribute('class','criteria_input form-control');
              // input1.required = true;
              form.appendChild(input);
              // Line break
              form.appendChild(document.createElement('br'));
  
              // Create second input field
              // var label2 = document.createElement('label');
              // label2.textContent = 'Field 2:';
              // form.appendChild(label2);
  
              // var input2 = document.createElement('input');
              // input2.type = 'text';
              // input2.id = 'field2';
              // input2.name = 'field2';
              // input2.required = true;
              // form.appendChild(input2);
  
              // // Line break
              // form.appendChild(document.createElement('br'));
  
              // // Create submit button
              var submitButton = document.createElement('button');
              // submitButton.type = 'button';
              // submitButton.textContent = 'Submit';
              //submitButton.addEventListener('click', submitForm);
              //form.appendChild(submitButton);
  
              // Append form to the body
              document.getElementById(`answer${index}`).appendChild(form);
              
              // let criteria = document.createElement("input");
              // criteria.name = option.value;
              // criteria.placeholder = `Please enter score for ${option.value}`;
              // document.getElementById(`answer${index}`).appendChild(criteria);
            }

          else{
              var input = document.createElement('input');
              input.type = 'number';
              // input1.id =  field1;
              input.name = option.value;
              input.placeholder = option.value;
              input.setAttribute('class','criteria_input form-control');
              // input1.required = true;
              document.getElementById(`criteriaform_${index}`).appendChild(input);
  
              // Line break
              document.getElementById(`criteriaform_${index}`).appendChild(document.createElement('br'));
          }
          }
          
        }

       
      }
    }
  
    removeEditor(index: number,step:string) {

      if(step == 'step2'){
        this.editors.removeAt(index);
        this.menus.removeAt(index);
        this.addFields.removeAt(index);
      }

      else if(step == 'step3'){
        this.editors3.removeAt(index);
        this.menus3.removeAt(index);
        this.addFields3.removeAt(index);
      }

      else if(step == 'step4'){
        this.editors4.removeAt(index);
        this.menus4.removeAt(index);
        this.addFields4.removeAt(index);
      }
      
    }
  
    async getchecked(event,index?){
      console.log('event_value_1',event.target.checked);
      console.log('event_value_2',event.target.value);
      console.log('index for required',index);
      var checked;
      if(event.target.checked != undefined){
        checked = event.target.checked;
        console.log('checked123',checked);
      }
      //var name = document.getElementById(`label${index}`).getAttribute('name');
      //console.log('label text',document.getElementById(`label${index}`));
      var obj = {}
      obj[document.getElementById(`label${index}`).textContent.replace(/\*/g, '')] = {
        "required":false,
        "pattern": ""
      }
      if(checked){
        console.log('checked_1');
        obj[document.getElementById(`label${index}`).textContent.replace(/\*/g, '')]['required'] = true;
      }
      else{
        console.log('checked_2');
        obj[document.getElementById(`label${index}`).textContent.replace(/\*/g, '')]['required'] = false;
      }

      // if(event.target.value!=''&&event.target.value!='mandatory'){
      //   obj[document.getElementById(`label${index}`).textContent.replace(/\*/g, '')]['pattern'] = `${event.target.value}`
      // }
      if(this.validation.length!=0){
        //var oldvalue = this.validation[0][this.label];
        var oldvalue = this.findByKey(this.validation,document.getElementById(`label${index}`).textContent.replace(/\*/g, ''));
        console.log('oldvalue____123',oldvalue);
      }
      // obj[this.label] = {
      //   "required":true
      // }
    if(this.validation.length==0){
        this.validation.push(obj)
      }
      else{
        if(this.isKeyPresent(this.validation,document.getElementById(`label${index}`).textContent.replace(/\*/g, ''))){
          console.log('key_new');
          for(var i = 0 ; i < this.validation.length ; i++){
            if(this.validation[i][document.getElementById(`label${index}`).textContent.replace(/\*/g, '')]!=undefined){
              this.validation[i][document.getElementById(`label${index}`).textContent.replace(/\*/g, '')]["required"] = obj[document.getElementById(`label${index}`).textContent.replace(/\*/g, '')]["required"];
            }
          }
          //this.replaceValueByKey(this.validation,this.label,oldvalue,obj[this.label]);
        }
        else{
          console.log('key_old');
          this.validation.push(obj);
        }
      }
      console.log('validation array',this.validation);
      return new Promise((resolve,reject)=>{
        this.checked = event.target.checked;
        resolve(this.checked);
      })
  //
    }

    async selectedPattern(event,index?){
      var checked;
      console.log('value_pattern_1234',event.target.value)
      document.getElementById(`input_${index}`).setAttribute('pattern',`${event.target.value}`);
      console.log('validation__1234',this.validation);
      if(event.target.checked != undefined){
        checked = event.target.checked;
        console.log('checked123',checked);
      }
      //var name = document.getElementById(`label${index}`).getAttribute('name');
      //console.log('label text',document.getElementById(`label${index}`));
      var obj = {}
      obj[document.getElementById(`label${index}`).textContent.replace(/\*/g, '')] = {
        "pattern": ""
      }

      obj[document.getElementById(`label${index}`).textContent.replace(/\*/g, '')]['pattern'] = `${event.target.value}`
      if(this.validation.length!=0){
        //var oldvalue = this.validation[0][this.label];
        var oldvalue = this.findByKey(this.validation,document.getElementById(`label${index}`).textContent.replace(/\*/g, ''));
        console.log('oldvalue____123',oldvalue);
      }
      // obj[this.label] = {
      //   "required":true
      // }
    if(this.validation.length==0){
        this.validation.push(obj)
      }
      else{
        if(this.isKeyPresent(this.validation,document.getElementById(`label${index}`).textContent.replace(/\*/g, ''))){
          console.log('key_new');
          for(var i = 0 ; i < this.validation.length ; i++){
            if(this.validation[i][document.getElementById(`label${index}`).textContent.replace(/\*/g, '')]!=undefined){
              this.validation[i][document.getElementById(`label${index}`).textContent.replace(/\*/g, '')]["pattern"] = obj[document.getElementById(`label${index}`).textContent.replace(/\*/g, '')]["pattern"];
            }
          }
          //this.replaceValueByKey(this.validation,this.label,oldvalue,obj[this.label]);
        }
        else{
          console.log('key_old');
          this.validation.push(obj);
        }
      }
      console.log('validation array selected',this.validation);
      return new Promise((resolve,reject)=>{
        this.checked = event.target.checked;
        resolve(this.checked);
      })
  //
    }
    findByKey(array, key) {
      return array.find(obj => obj.hasOwnProperty(key));
    }

    replaceValueByKey(array, key, oldValue, newValue) {
      array.forEach(obj => {
          if (obj[key] === oldValue) {
              obj[key] = newValue;
          }
      });
    }

    isKeyPresent(array, key) {
      for (let obj of array) {
        if (obj.hasOwnProperty(key)) {
            return true; // Key is present in at least one object
        }
    }
    return false; // Key is not present in any object
}
    addmandatory(){
      console.log('add mandatory');
    }
    
    getlabel(event: any, i:any) {
      this.label = event.target.value.trimStart();
      if(this.label != ""){
        document.getElementById(`scorable${i}`).removeAttribute('disabled');
      }
      else{
        document.getElementById(`scorable${i}`).setAttribute('disabled','');
      }
      console.log('index__1234',i);
      console.log('label',this.label);
      if(this.label == ""){
        console.log('labelif');
        //document.getElementById(`selectfield_${i}`).setAttribute('disabled','true');
      }
      else{
        console.log('labelelse');
      }
      
      document.getElementById(`label${i}`).innerHTML = this.label;
      document.getElementById(`label${i}`).setAttribute('class','mt-10');
      document.getElementById(`label${i}`).innerHTML += `<span class="text-danger ml-1 font-weight-bold float-right showlabel d-none" id="showlabel_${i}">*</span>`;
    }
  
    async getlabelforpreview(event:any){
      return new Promise ((resolve,reject)=>{
        this.label = event.target.value;
        console.log('label',typeof(this.label));
        resolve(this.label);
      })
    }
  
    selectFile(event: any) {
      if (!event.target.files[0] || event.target.files[0].length == 0) {
        this.msg = "You must select an image";
        return;
      }
    }
    moveToRight(field: any) {
      const index = this.leftFields.indexOf(field);
      if (index !== -1) {
        this.leftFields.splice(index, 1);
        this.rightFields.push(field);
      }
    }
  
    moveToLeft(field: any) {
      const index = this.rightFields.indexOf(field);
      if (index !== -1) {
        this.rightFields.splice(index, 1);
        this.leftFields.push(field);
      }
    }
    get f(){
      return this.applicationForm.controls;
    }
    applicationJson() {
      console.log('items_key',items.length);
      this.submitted = true;
      console.log('industry',this.selectedItems);
      console.log('industry',this.selectedItems1);
      console.log('industry',this.selectedItems2);
      console.log('timezone',this.selectedItems_t);
  
      console.log('itemsval',(<HTMLInputElement>document.getElementById('txt')).value)
      if(!this.applicationForm.valid){
        console.log('item_length',items.length);
        if(items.length == 0){
          this.nokeyword = true
        }
        else{
          this.nokeyword = false;
        }
  
      }
      else{
        this.submitted = false;
        for(var i = 0 ; i < this.selectedItems.length; i++){
          this.industry.push(this.selectedItems[i].item_text);
        }
  
        for(var i = 0 ; i < this.selectedItems1.length; i++){
          this.sector.push(this.selectedItems1[i].item_text);
      }
  
        for(var i = 0 ; i < this.selectedItems.length; i++){
          this.keyword.push(this.selectedItems[i].item_text);
      }
  
      console.log(this.industry,this.sector,this.keyword);
      }
      
    }

    personalJson() {
      let someDOMElement = document.getElementById("step1");
      this.jsonOutput = toJSON(someDOMElement);
      this.arrayDataPush["step1"] = this.jsonOutput;
      console.log("arraydatapush_first", this.arrayDataPush);
    }

    companyJson() {
      let someDOMElement = document.getElementById("step2");
      this.jsonOutput = toJSON(someDOMElement);
      this.arrayDataPush["step2"] = this.jsonOutput;
      console.log("arraydatapush_second", this.arrayDataPush);
      // this.step2criteria = [];
      // this.step2filter = [];
      // for(var i = 0 ; i < this.formids.length ; i++){
      //   if(document.getElementById(this.formids[i])!=null){
      //     this.step2criteria.push(new form().getFormData(this.formids[i],this.criteria_label[i]));
      //   }
      // }

      var formids2 = [];

      var parentElement = document.getElementById('step2');
      var elementClasses = parentElement.getElementsByClassName('criteriaform');

      for(var i = 0 ; i < elementClasses.length ; i++){
        formids2.push(elementClasses[i].getAttribute('id'));
      }

    this.step2criteria = [];
    this.step2filter = [];
    for(var i = 0 ; i < formids2.length ; i++){
      console.log('formids_3',formids2[i]);
      if(document.getElementById(formids2[i])!=null){
        // if(!this.step2criteria.includes(new form().getFormData(formids3[i],this.criteria_label3[i]))){
          this.step2criteria.push(new form().getFormData(formids2[i],this.criteria_label[i]));
        // }
      }
    }

      for(var i = 0 ; i < this.step2criteria.length ; i++){
        console.log('keys',Object.keys(this.step2criteria[i]));
        if(Object.keys(this.step2criteria[i])[0]!='undefined'){
          this.step2filter.push(this.step2criteria[i]);
        }
      }
      //t
      // document.querySelectorAll('.answer').forEach((e,i)=>{
      //     if(document.getElementById(`criteriaform_${i}`)!=null){
      //       var element1 = e.previousElementSibling;
      //       var element2 = element1 ? element1.previousElementSibling : null;
      //       step2Criteria.push(new form().getFormData(i,element2.textContent));
      //     }
      // })

      console.log('step2criteria-----',this.step2criteria);
 
    }
    sectionJson() {
      let someDOMElement = document.getElementById("step3");
      this.jsonOutput = toJSON(someDOMElement);
      this.arrayDataPush["step3"] = this.jsonOutput;
      console.log("arraydatapush_third", this.arrayDataPush);
      // this.step3criteria = [];
      // this.step3filter = [];
      // for(var i = 0 ; i < this.formids.length ; i++){
      //   if(document.getElementById(this.formids[i])!=null){
      //     // if(!this.step2criteria.includes(new form().getFormData(this.formids[i],this.criteria_label3[i]))){
      //       this.step3criteria.push(new form().getFormData(this.formids[i],this.criteria_label3[i]));
      //     // }
      //   }
      // }

      var formids3 = [];

      var parentElement = document.getElementById('step3');
      var elementClasses = parentElement.getElementsByClassName('criteriaform');

      for(var i = 0 ; i < elementClasses.length ; i++){
        formids3.push(elementClasses[i].getAttribute('id'));
      }

    this.step3criteria = [];
    this.step3filter = [];
    for(var i = 0 ; i < formids3.length ; i++){
      console.log('formids_3',formids3[i]);
      if(document.getElementById(formids3[i])!=null){
        // if(!this.step2criteria.includes(new form().getFormData(formids3[i],this.criteria_label3[i]))){
          this.step3criteria.push(new form().getFormData(formids3[i],this.criteria_label3[i]));
        // }
      }
    }

      for(var i = 0 ; i < this.step3criteria.length ; i++){
        console.log('keys',Object.keys(this.step3criteria[i]));
        if(Object.keys(this.step3criteria[i])[0]!='undefined'){
          this.step3filter.push(this.step3criteria[i]);
        }
      }
      //this.step3filter = this.removeUndefinedKeys(this.step3criteria); 
      // document.querySelectorAll('.answer').forEach((e,i)=>{
      //     if(document.getElementById(`criteriaform_${i}`)!=null){
      //       var element1 = e.previousElementSibling;
      //       var element2 = element1 ? element1.previousElementSibling : null;
      //       step3criteria.push(new form().getFormData(i,element2.textContent));
      //     }
      // })

      console.log('step3criteria-----',this.step3filter);
   
    }
    additionalJson() {
      let someDOMElement = document.getElementById("step4");
      this.jsonOutput = toJSON(someDOMElement);
      this.arrayDataPush["step4"] = this.jsonOutput;
      console.log("arraydatapush_final", this.arrayDataPush);
      // this.step4criteria = [];
      // this.step4filter = [];
      // for(var i = 0 ; i < this.formids.length ; i++){
      //   if(document.getElementById(this.formids[i])!=null){
      //     this.step4criteria.push(new form().getFormData(this.formids[i],this.criteria_label4[i]));
      //   }
      // }

      var formids4 = [];

    var parentElement = document.getElementById('step4');
    var elementClasses = parentElement.getElementsByClassName('criteriaform');

    for(var i = 0 ; i < elementClasses.length ; i++){
      formids4.push(elementClasses[i].getAttribute('id'));
    }

    this.step4criteria = [];
    this.step4filter = [];
    for(var i = 0 ; i < formids4.length ; i++){
      console.log('formids_3',formids4[i]);
      if(document.getElementById(formids4[i])!=null){
        // if(!this.step2criteria.includes(new form().getFormData(formids3[i],this.criteria_label3[i]))){
          this.step4criteria.push(new form().getFormData(formids4[i],this.criteria_label4[i]));
        // }
      }
    }
      console.log('step4criteria1234',this.step4criteria);
      for(var i = 0 ; i < this.step4criteria.length ; i++){
        console.log('keys',Object.keys(this.step4criteria[i]));
        if(Object.keys(this.step4criteria[i])[0]!='undefined'){
          this.step4filter.push(this.step4criteria[i]);
        }
      }

      let score_schema = [];
      score_schema.push(...this.step2filter , ...this.step3filter , ...this.step4filter);
      console.log('step4criteria-----',this.step4filter);
      let payload = {
        "form_name": new Date().getTime().toString(),
        "form_schema": this.arrayDataPush,
        "score_schema": score_schema,
        "required_schema": this.validation,
        "status": "PENDING"
    };
    console.log('payload_form_123',payload);
      this.mentorService.saveRegistrationForm(payload).subscribe((res)=>{
        console.log('form created ----',res);
        this.toastrService.success('form created successfully');
        this.router.navigate(['/admin/mentorformsall']);
      })
    }
    
removeUndefinedKeys(arr){
  return arr.map(obj => {
    // Use Object.entries to get an array of [key, value] pairs
    const filteredEntries = Object.entries(obj).filter(([key, value]) => value !== undefined);

    // Use Object.fromEntries to convert the filtered entries back into an object
    var filtered_data = new form().removeUndefinedKeys(filteredEntries);

    return filtered_data;
  });
};
    converttohtml() {
      return new Promise((resolve, reject) => {
        console.log("before convert to html");
        var step2: any;
        var step3: any;
        var step4: unknown;
        this.challengeService
          .getFormbyEditionid(sessionStorage.getItem("editionid"))
          .subscribe((res: { results: string | any[] }) => {
            console.log("resform1234", res);
            if (res.results.length != 0) {
              this.noform = false;
              step2 = res.results[0].sections.step2;
              step3 = res.results[0].sections.step3;
              step4 = res.results[0].sections.step4;
              
              console.log('step2',step2);
              console.log('step3',step3);
              console.log('step4',step4);
  
              let parent2 = document.getElementById("placehere2");
              let DOMDocumentFragment2 = toDOM(step2);
              console.log("DOMDoc", DOMDocumentFragment2);
              parent2.parentNode.appendChild(DOMDocumentFragment2);
  
              let parent3 = document.getElementById("placehere3");
              let DOMDocumentFragment3 = toDOM(step3);
              console.log("DOMDoc", DOMDocumentFragment3);
              parent3.parentNode.appendChild(DOMDocumentFragment3);
  
              let parent4 = document.getElementById("placehere4");
              let DOMDocumentFragment4 = toDOM(step4);
              console.log("DOMDoc", DOMDocumentFragment4);
              parent4.parentNode.appendChild(DOMDocumentFragment4);
            }
            //   ,500)
  
            // }
            else {
              this.noform = true;
            }
  
            resolve(step4);
          });
      });
    }
  
    onFileSelect(event) {
      console.log('file is here');
      let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel','application/pdf','text/csv','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/msword','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-excel']
      const file = event.target.files[0];
      const filesize = file.size;
      const filesizemb = filesize/1024 ** 2;
      console.log('filesize in mb',filesizemb);
      console.log('file',file);
      if(!af.includes(file.type)){
        this.filetypeerr = true
      }
      else{
        this.filetypeerr = false
      }
  
      if(filesizemb > 2){
        this.filesizeerr = true
      }
      else{
        this.filesizeerr = false
      }
      this.fileInputLabel = file.name;
      this.applicationForm.get("uploadedImage").setValue(file);
    }
    
    createApplicationForm() {
  
      const formData = new FormData();
      formData.append("file",this.applicationForm.get("uploadedImage").value);
      console.log('formdata_image',formData);
      this.editionid = sessionStorage.getItem("editionid");
      console.log("challengeid", this.challengeid);
      this.additionalJson();

    }
  
    allowlogo(event:any){
      console.log('event',event.target.checked);
      var element1 = document.getElementById('companyprofilepic')
      if(event.target.checked == true){
        let element = document.getElementById('requiredcompanyprofile');
        element.classList.remove('requiredcompanyprofile');
        console.log('element',element);
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('requiredcompanyprofile');
        element.className+=' requiredcompanyprofile';
        console.log('element',element);
        element1.classList.remove('d-none');
      }
    }
  
  
  
    managementyear(event:any){
      console.log('event',event.target.checked);
      if(event.target.checked == true){
        let element = document.getElementById('managementyear');
        console.log('element',element);
        element.className+=' required';
      }
      else {
        let element = document.getElementById('managementyear');
        element.classList.remove('hide');
      }
    }
  
    allowname(event:any){
      console.log('event',event.target.checked);
      if(event.target.checked == true){
        let element = document.getElementById('name');
        element.classList.remove('required');
        console.log('element',element);
      }
      else {
        let element = document.getElementById('name');
        element.className+=' required';
      }
    }
  
    allowyoutube(event:any){
      console.log('event',event.target.checked);
      var element1 = document.getElementById('companyyt');
      if(event.target.checked == true){
        let element = document.getElementById('youtube');
        element.classList.remove('required');
        console.log('element',element);
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('youtube');
        element.className+=' required';
        console.log('element',element);
        element1.classList.remove('d-none');
      }
    }
  
    allowlinkedin_company(event:any){
      console.log('event',event.target.checked);
      var element1 = document.getElementById('companylinkedin');
      if(event.target.checked == true){
        let element = document.getElementById('linkedin_company');
        element.classList.remove('required');
        console.log('element',element);
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('linkedin_company');
        element.className+=' required';
        console.log('element',element);
        element1.classList.remove('d-none');
      }
    }
  
    allowkooapp(event:any){
      console.log('event',event.target.checked);
      var element1 = document.getElementById('companykoo');
      if(event.target.checked == true){
        let element = document.getElementById('kooapp');
        element.classList.remove('required');
        console.log('element',element);
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('kooapp');
        element.className+=' required';
        console.log('element',element);
        element1.classList.remove('d-none');
      }
    }
  
    allowfacebook(event:any){
      console.log('event',event.target.checked);
      var element1 = document.getElementById('companyfacebook');
      if(event.target.checked == true){
        let element = document.getElementById('facebook');
        element.classList.remove('required');
        console.log('element',element);
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('facebook');
        element.className+=' required';
        console.log('element',element);
        element1.classList.remove('d-none');
      }
    }
  
    allowinsta(event:any){
      console.log('event',event.target.checked);
      var element1 = document.getElementById('companyinsta');
      if(event.target.checked == true){
        let element = document.getElementById('insta');
        element.classList.remove('required');
        console.log('element',element);
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('insta');
        element.className+=' required';
        console.log('element',element);
        element1.classList.remove('d-none');
      }
    }
  
    allowtwitter_company(event:any){
      console.log('event',event.target.checked);
      var element1 = document.getElementById('companytwitter');
      if(event.target.checked == true){
        let element = document.getElementById('twitter_company');
        element.classList.remove('required');
        console.log('element',element);
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('twitter_company');
        element.className+=' required';
        console.log('element',element);
        element1.classList.remove('d-none');
      }
    }
  
    allowregistered(event:any){
      console.log('event_name',event.target.checked);
      var element1 = document.getElementById('companyreglabel')
      if(event.target.checked == true){
        let element = document.getElementById('registered');
        console.log('element_registered',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('registered');
        console.log('element',element);
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowyear(event:any){
      console.log('event',event.target.checked);
      var element1 = document.getElementById('yearestd');
      if(event.target.checked == true){
        let element = document.getElementById('year');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('year');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowwebsite(event:any){
      var element1 = document.getElementById('companyweb');
      console.log('event',event.target.checked);
      if(event.target.checked == true){
        let element = document.getElementById('website');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('website');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowcountry(event:any){
      console.log('event',event.target.checked);
      var element1 = document.getElementById('companycountry');
      if(event.target.checked == true){
        let element = document.getElementById('country');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('country');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowstate(event:any){
      console.log('event',event.target.checked);
      var element1 = document.getElementById('companystate');
      if(event.target.checked == true){
        let element = document.getElementById('state');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('state');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowcity(event:any){
      var element1 = document.getElementById('companycity');
      console.log('event',event.target.checked);
      if(event.target.checked == true){
        let element = document.getElementById('city');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
        
      }
      else {
        let element = document.getElementById('city');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowstage(event:any){
      var element1 = document.getElementById('companystage');
      console.log('event',event.target.checked);
      if(event.target.checked == true){
        let element = document.getElementById('stage');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('stage');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowindustry(event:any){
      console.log('event',event.target.checked);
      var element1 = document.getElementById('companyindustry');
      if(event.target.checked == true){
        let element = document.getElementById('industry');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('industry');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowsector(event:any){
      var element1 = document.getElementById('companysector');
      console.log('event',event.target.checked);
      if(event.target.checked == true){
        let element = document.getElementById('sector');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('sector');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowemail(event:any){
      var element1 = document.getElementById('email_management');
      if(event.target.checked == true){
        let element = document.getElementById('email');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('email');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allownationality(event:any){
      var element1 = document.getElementById('nat_mem');
      if(event.target.checked == true){
        let element = document.getElementById('nationality');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
        
      }
      else {
        let element = document.getElementById('nationality');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowgender(event:any){
      var element1 = document.getElementById('gender_member');
      if(event.target.checked == true){
        let element = document.getElementById('gender');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('gender');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowmanagementyear(event:any){
      if(event.target.checked == true){
        let element = document.getElementById('managementyear');
        console.log('element',element);
        element.classList.remove('required');
      }
      else {
        let element = document.getElementById('managementyear');
        element.className+=' required';
      }
    }
  
    allowtitle(event:any){
      var element1 = document.getElementById('title_management');
      if(event.target.checked == true){
        let element = document.getElementById('title');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('title');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowmanagementname(event:any){
      var element1 = document.getElementById('name_management');
      if(event.target.checked == true){
        let element = document.getElementById('managementname');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('managementname');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowprofilepic(event:any){
      console.log('event',event.target.checked);
      var element1 = document.getElementById('managementprofile')
      if(event.target.checked == true){
        let element = document.getElementById('m_profilepic');
        element.classList.remove('m_profilepic');
        console.log('element',element);
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('m_profilepic');
        element.className+=' m_profilepic';
        console.log('element',element);
        element1.classList.remove('d-none');
      }

    }
  
    allowtwitter(event){
      var element1 = document.getElementById('m_twitter');
      if(event.target.checked == true){
        let element = document.getElementById('twitter');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
        
      }
      else {
        let element = document.getElementById('twitter');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowlinkedin(event){
      var element1 = document.getElementById('m_linkedin');
      if(event.target.checked == true){
        let element = document.getElementById('linkedin');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('linkedin');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
    
    allowentity(event){
      var element1 = document.getElementById('mem_ent');
      if(event.target.checked == true){
        let element = document.getElementById('entitytype');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('entitytype');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    allowteamsize(event){
      var element1 = document.getElementById('mem_ts');
      if(event.target.checked == true){
        let element = document.getElementById('teamsize');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('teamsize');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
  
    moreaboutthismember(event){
      var element1 = document.getElementById('moreabt');
      if(event.target.checked == true){
        let element = document.getElementById('moreaboutthismember');
        console.log('element',element);
        element.classList.remove('required');
        element1.className+=' d-none';
      }
      else {
        let element = document.getElementById('moreaboutthismember');
        element.className+=' required';
        element1.classList.remove('d-none');
      }
    }
  
    publish(){
      let data = {
        "status":"PUBLISHED"
      }

    }
  
    getDomains(){
      this.challengeService.getalldomains().subscribe((res:any)=>{
        this.alldomains = res.results;
        // console.log('all domains',this.alldomains);
        // for(var i = 0 ; i < this.alldomains.length ; i++){
        //   this.dropdownList.push({
        //     item_id:i+1 , item_text:this.alldomains[i].name
        //   })
        // }
  
        // this.groupbydomains = _.groupBy(this.alldomains,'name');
        // console.log('group domains',this.groupbydomains);
      })
    }
  
    getSelectedDomains(event:any){
      console.log('event_domains___1234',event.target.value);
    }

    getSectors(data:any){
      this.sectors = [];
      for(var i = 0 ; i < data.length ; i++){
        console.log('data from domains___',data[i]);
        let filter = this.alldomains.find((item)=>{
          return item.name == data[i];
        })
        this.sectors.push(...filter.sectors);
      }

      console.log('sectors_data_1234',this.sectors);
      // let sectors = this.alldomains.find((item:any)=>{
      //   return item.id == event.target.value;
      // })
      // console.log('sectorslist_dropdown',this.sectors_dropdown);
      // this.sectors_dropdown = sectors.sectors;
      // console.log('sectors123',this.sectors_dropdown);
    }
    getTimeZone(event:any){
      this.timezone = event.target.value;
    }
    async onItemSelectIndustry(event){
  
      console.log('onItemSelect',this.groupbydomains,event);
      console.log('getsector',this.groupbydomains[`${event.item_text}`][0]['sectors'])
      await this.groupbysectors(event);
      console.log('sectors',this.sectors);
      for(var i = 0 ; i<this.sectors.length ; i++) {
        this.tempdata.push({
          item_id:i+1 , item_text:this.sectors[i].name
        })
      }
      this.dropdownList1 = [...this.tempdata];
      console.log('sectors_dropdown',this.dropdownList1);
  
    }
  
    async onItemDeSelectIndustry(event){
  
      console.log('event',event);
  
    }
  
    async groupbysectors(event){
      return new Promise((resolve,reject)=>{
        this.challengeService.setSectorData(this.groupbydomains[`${event.item_text}`][0]['sectors'])
        this.sectors.push(...this.groupbydomains[`${event.item_text}`][0]['sectors']);
        resolve(this.sectors);
      })
    }
  
    async assigntosectorlist(){
      await this.onItemSelectIndustry(this.industryevent);
      this.dropdownList1 = this.sectors;
      console.log()
    }
  
    getaccesstoexport(event:any){
      console.log('export access',event.target.checked)
      this.exportaccess = event.target.checked;
    }
  
    getaccesstoemailnotification(event:any){
      console.log('email notification',event.target.checked);
      this.accessemailnotification = event.target.checked;
    }

    handleCancel(){
      this.router.navigate(['/admin/mentorformsall'])
    }
}
