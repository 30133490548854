import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NewsService } from "../../services/news.service";
import * as _ from "lodash";
import {
  NbDateService,
  NbDialogService,
  NbToastrService,
} from "@nebular/theme";
import { ShowcaseDialogComponent } from "../../@core/miscellaneous/dialog/showcase-dialog/showcase-dialog.component";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MatDialog } from "@angular/material/dialog";
import { CommondialogComponent } from "../../@core/miscellaneous/commondialog/commondialog.component";
import { ExcelService } from '../../services/excel.service';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "ngx-press-release",
  templateUrl: "./press-release.component.html",
  styleUrls: ["./press-release.component.scss"],
})
export class PressReleaseComponent implements OnInit {
  editMode: boolean = false;
  createMode: boolean = true;
  isExpanded: boolean = false;
  min: Date;
  max: Date;
  pressReleaseForm: FormGroup;
  previewForm: FormGroup;
  filterStatus:any;
  data: any;
  currentData: any;
  modaldata = { presstitle: "", link: "", date: "", description: "" };
  inValidMessage: boolean;
  formSubmitted = false;
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "alignment", // <--- ADDED
        "bold",
        "italic",
        "link",
        "bulletedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    link: {
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
        openInNewTab: {
          mode: "manual",
          label: "Open in a new tab",
          defaultValue: true, // This option will be selected by default.
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
  };
  htmlValue: any;
  pressrelease_status: any;
  status: any;
  pressrelease_id: any;
  pressdata: { pressrelease_id: any; pressrelease_title: any; pressrelease_description: any; pressrelease_link: any; pressrelease_date: any; pressrelease_status: any; };
  searchText = '';
  p: number = 1;
  pageSize: number = 25;
  id = "section";
  mat_data: any;

  constructor(
    private fb: FormBuilder,
    private newsService: NewsService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    protected dateService: NbDateService<Date>,
    private dialog: MatDialog,
    private excelService:ExcelService,
    private route:ActivatedRoute

  ) {
    // this.min = this.dateService.addMonth(this.dateService.today(), -1);
    this.max = this.dateService.addMonth(this.dateService.today(), 1);
    this.route.queryParams.subscribe(params => { 
        this.filterStatus = params['status'];
    })
  }

  ngOnInit(): void {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    this.pressReleaseForm = this.fb.group({
      pressTitle: ["", Validators.required],
      link: ["", [Validators.required, Validators.pattern(urlRegex)]],
      date: ["", Validators.required],
      pressDescription: ["", Validators.required],
      status:[""]
    });
    this.getallpressRelease();
  }
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
  createPressRelease() {
    let selectedDate = this.pressReleaseForm.controls.date.value; // example date value
    let dateObjj = new Date(Date.parse(selectedDate));
    let dateObj = dateObjj.toLocaleDateString('en-IN', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).replace(/\//g, '-'); // replace slashes with dashes
    let parts = dateObj.split('-');
    let formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    // console.log("formattedDate", formattedDate);

    this.formSubmitted = true;
    if (this.pressReleaseForm.valid) {
      this.editMode = false;
      this.createMode = true;
      this.data = {
        pressrelease_title: this.pressReleaseForm.controls.pressTitle.value,
        pressrelease_description: this.pressReleaseForm.controls.pressDescription.value,
        pressrelease_link: this.pressReleaseForm.controls.link.value,
        // pressrelease_date: this.pressReleaseForm.controls.date.value,
        pressrelease_date: formattedDate
      };
      this.newsService.createPressRelease(this.data).subscribe(
        (res: any) => {
          this.data = res.results;
          console.log("ts data::", this.data);
          this.getallpressRelease();
          this.pressReleaseForm.reset();
          this.toastrService.success("Press-Release Added Successfully!");
        },
        (error) => {
          this.inValidMessage = true;
          this.toastrService.warning("Press-Release Records already Exist!");
        }
      );
    } else {
      this.pressReleaseForm.markAllAsTouched();
    }
  }

  editData(pressdata, el:any) {
    el.scrollIntoView({behaviour:'smooth'});

    this.editMode = true; //change btn to update
    this.createMode = false;
    console.log("Editdataaaa..", pressdata);
    this.pressReleaseForm.controls["pressTitle"].setValue(
      pressdata.pressrelease_title
    );
    this.pressReleaseForm.controls["link"].setValue(pressdata.pressrelease_link);
    this.pressReleaseForm.controls["pressDescription"].setValue(
      pressdata.pressrelease_description
    );
    this.pressReleaseForm.controls["date"].setValue(pressdata.pressrelease_date);
    this.pressReleaseForm.controls["status"].setValue(pressdata.pressrelease_status);
    this.status=pressdata.pressrelease_status;
    this.pressrelease_id = pressdata.pressrelease_id;
  }

  updateFormsData() {
    

    if (this.pressReleaseForm.valid) {
      if (this.editMode && _.pressReleaseForm == _.data) {
        this.editMode = true;
        this.currentData = this.pressReleaseForm.value;

        let selectedDate = this.currentData.date; // example date value
        let dateObjj = new Date(Date.parse(selectedDate));
        let dateObj = dateObjj.toLocaleDateString('en-IN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }).replace(/\//g, '-'); // replace slashes with dashes
        let parts = dateObj.split('-');
        let formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        // console.log("formattedDate", formattedDate);

        this.currentData["pressrelease_status"] = this.status;

         this.pressdata = {
          pressrelease_id: this.pressrelease_id,
          pressrelease_title: this.currentData.pressTitle,
          pressrelease_description: this.currentData.pressDescription,
          pressrelease_link: this.currentData.link,
          pressrelease_date: formattedDate,

          // pressrelease_date: this.currentData.date,
          pressrelease_status: this.currentData.status
        };
        // console.log("data:::", this.pressdata);
        // console.log('cd___',this.currentData);
        this.newsService
          .editpressRelease(this.pressdata)
          .subscribe(
            (res) => {
              console.log("res:::", res);
              this.pressReleaseForm.reset();
              this.getallpressRelease();
              this.editMode = false;
              this.createMode = true;
              this.toastrService.success("Press-Release Updated Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Press-Release already Exist!");
            }
          ); /////put api function call ends here!
      }
    }
    else{
      this.toastrService.warning("Press-Release Form Invalid!");
    }
  }
  removeData(id) {
    this.dialogService
      .open(ShowcaseDialogComponent, {
        context: {
          message: "Are you sure you want to delete this Press Release?",
        },
      })
      .onClose.subscribe((confirmed) => {
        if (confirmed) {
          console.log("deletedata:", id);
          this.newsService.deletepressRow(id).subscribe(
            (data) => {
              this.getallpressRelease();
              this.toastrService.success("Press Release Deleted Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Delete Press Release!");
            }
          );
        }
      });
      var htmlElement = document.documentElement;
      if (htmlElement) {
        htmlElement.style.top = '0'; 
        htmlElement.style.position = 'sticky';
      }
  }

  getallpressRelease() {
    this.newsService.getallRelease().subscribe((res: any) => {
      console.log("res_press", res);
      this.data = res.results;
      // console.log("check", typeof(res.results));
      console.log("press results all::", this.data);
    });
  }
  get filteredData() {
    let filteredDataByStatus;
    if(this.filterStatus) {
        filteredDataByStatus = this.data?.filter(d => d.pressrelease_status.toLowerCase() == this.filterStatus.toLowerCase())
    } else { filteredDataByStatus = this.data }

    return filteredDataByStatus?.filter(d => {
      // Check if name property exists and is not undefined
      if (d && d.pressrelease_title || d && d.pressrelease_description || d && d.pressrelease_status) {
        return d.pressrelease_title.toLowerCase().includes(this.searchText.toLowerCase())
              || d.pressrelease_description.toLowerCase().includes(this.searchText.toLowerCase())
              || d.pressrelease_status.toLowerCase().includes(this.searchText.toLowerCase());
      }
      // If name property is undefined, return false to exclude from filtered data
      return false;
    });
  }
  
  changestatus(data:any, status:any){
    // localStorage.setItem('myData', 'Press Release');
    // console.log('data', data);
    this.mat_data = status;
    const dialogRef=this.dialog.open(CommondialogComponent,{
          data: { status: this.mat_data, name: 'Press Release' }
        })
        dialogRef.afterClosed().subscribe(result => {
          var result=result;
          if(result==true){
            var value = {
              pressrelease_id: data.pressrelease_id,
              pressrelease_title: data.pressrelease_title,
              pressrelease_description: data.pressrelease_description,
              pressrelease_link: data.pressrelease_link,
              pressrelease_date: data.pressrelease_date,
              pressrelease_status: status
            }
            this.newsService.actionPressRelease(value).subscribe((res:any)=>{
                console.log('change_status',res);
                this.getallpressRelease();
                localStorage.removeItem('myData');
                this.toastrService.success("Successfully updated Status!");
              },
              (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Update Status!");
            })
          }
        })

        // back screen ui distrubed fix
        var htmlElement = document.documentElement;
        if (htmlElement) {
          htmlElement.style.top = '0'; 
          htmlElement.style.position = 'sticky';
        }
  }

  getTokenId(id: any) {
    sessionStorage.setItem("token", id);
    console.log("token::", id);
  }
  preview(item: any) {
    console.log("item", item);
    this.modaldata.presstitle = item.pressrelease_title;
    this.modaldata.link = item.pressrelease_link;
    this.modaldata.description = item.pressrelease_description;
    this.modaldata.date = item.pressrelease_date;

    console.log("modaldata", this.modaldata);
  }
  cancel() {
    this.pressReleaseForm.reset();
    this.editMode = false;
    this.createMode = true;
  }

   // xls download file
   
   exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'Press-release','Press-release');
  }
  downloadlabList(){ 
    var exportTableData
    if(this.filterStatus) {
        exportTableData = this.data?.filter(d => d.pressrelease_status == this.filterStatus.toLowerCase())
    } else { exportTableData = this.data }
    this.exportData(exportTableData);
   
  }
 
  exportData(data2: any[]) {    
    const labData = data2.map((d) => ({      
      'Title': d.pressrelease_title ? d.pressrelease_title :'-',
      Description: d.pressrelease_description ? d.pressrelease_description : '-',
      Link: d.pressrelease_link ? d.pressrelease_link : '-',
      'Create date': d?.pressrelease_date ? new Date(d?.pressrelease_date) : '-',
      Status: d.pressrelease_status ? d.pressrelease_status :'-',
      // 'Updated at':new Date(d?.updated_at),

     



      
    }));
    this.exportAsXLSX(labData)
    // this.generateExcelFile(data, "myData.xlsx");
    console.log("Export:", labData);
  }

 
  // xls download file
}
