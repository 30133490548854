<div>
  <nb-card>
    <nb-card-body>
      <nb-tabset>
        <nb-tab tabTitle="Add FAQs" >
          <!-- Tab content 1 -->
          <div class="container-grid col-sm-12" #section>
            <nb-card>
              <nb-card-body>
                <nb-card-header>FAQs</nb-card-header>
              </nb-card-body>
              <form [formGroup]="faqsForm" class="form-control-group">
                <div class="form-control-group col-sm-12 ">
                  <!-- <select class="btn btn-primary" >
                      <option value="">--Select--</option>
                      <option *ngFor="let data of categoryList" value="{{data.faqs_category_id}}" >{{data.faqs_categoryname}}</option>
                      </select> -->
                  <input
                    type="text"
                    name=""
                    id=""
                    readonly
                    class="form-control"
                    value="{{ categoryList }}"
                  />
                </div>

                <div class="form-control-group col-sm-12 mt-3">
                  <label for="">Question</label>
                  <input
                    type="text"
                    formControlName="question"
                    class="form-control" placeholder="Entere Question"
                  />
                  <div
                    *ngIf="
                      faqsForm.get('question').invalid &&
                      faqsForm.get('question').touched
                    "
                  >
                  
                    <div
                      *ngIf="faqsForm.get('question').errors.required"
                      class="text-danger"
                    >
                      Question is required.
                    </div>
                  </div>
                </div>

                <div class="form-control-group col-sm-12 mt-2">
                  <label>Answer</label>
                  <ckeditor [editor]="Editor" [config]="editorConfig" formControlName="answer" #myEditor></ckeditor>
                  <div
                    *ngIf="
                      faqsForm.get('answer').invalid &&
                      faqsForm.get('answer').touched
                    "
                  >
                    <div
                      *ngIf="faqsForm.get('answer').errors.required"
                      class="text-danger"
                    >
                      Answer is required.
                    </div>
                  </div>
                </div>
              </form>
            </nb-card>
            <div class="d-flex w-100 justify-content-center">
              <button class="btn custombtn mr-3 mb-3 mt-3" (click)="submitFaqsQuestion()" *ngIf="createMode">Create</button>
              <button class="btn custombtn mr-3 mb-3 mt-3" (click)="updateForms()" *ngIf="editMode">Update</button>
              <button class="btn custombtn mb-3 mt-3" (click)="cancel()">Cancel</button>
            </div>
          </div>

          <div class=" col-sm-12">
            <!-- <div class="col-md-4 my-2"> -->
              <div class="btn btn-success mt-2 float-right">
                <span class="fa fa-download" (click)="downloadFaqList()">
                Download</span
                >
              </div>
              <div class="align-items-center col-md-4 my-2 float-right">
              <input class="form-control " type="text" [(ngModel)]="searchText" placeholder="Search by Question...">
            </div>
              
            <!-- </div> -->
         
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" class="text-center">Sl No</th>
                  <th scope="col">Updated Time</th>
                  <th scope="col">Question</th>
                  <th scope="col">Answer</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody *ngIf="filteredData?.length > 0; else noRecords">
                <tr *ngFor="let d of filteredData | paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index">
                  <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th>
                  <td>{{d.updated_at |  date}}</td>
                  <td [innerHtml]="d.faq_question | slice:0:50"> </td>
                  <td [innerHtml]="d.faq_answer | slice:0:50"> </td>
                  <td> {{d.faq_status}}</td>
                  <td width="200px">
                    <!-- <div class="dropdown">
                      <a class="dropbtn fa fa-check" style = "color:blue"></a>
                      <div class="dropdown-content" >
                        <a *ngIf="d.faq_status === 'active'" (click)="changestatus(d,'inactive')">inactive</a>
                        <a *ngIf="d.faq_status === 'inactive'" (click)="changestatus(d,'active')">active</a>
                      </div>
                    </div> -->
                    <div class="d-flex align-items-center ">
                    <div *ngIf="d.faq_status === 'active'">
                      <a class="fa fa-close mr-3 arrow" style="color:red"  (click)="changestatus(d,'inactive')"></a>
                    </div>
                    <div *ngIf="d.faq_status === 'inactive'">
                        <a class="fa fa-check mr-3 arrow" style="color:blue"  (click)="changestatus(d,'active')"></a>
                    </div>
                    <a class="fa fa-edit mr-3 arrow" style="color: blue" (click)="editData(d, section)" ></a>
                    <a class="fa fa-trash mr-3 arrow" style="color: red" (click)="deleteFaqs(d.faqs_id)" ></a>
                    <a class="fa fa-eye arrow" style = "color:green" data-toggle="modal" data-target="#previewFaqModel" (click)="preview(d)" ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <ng-template #noRecords>
                <tbody>
                  <tr>
                    <td colspan="4" class="no-records-found">No Data found.</td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
            <pagination-controls *ngIf="filteredData?.length > 0;" (pageChange)="p = $event" class="float-right"></pagination-controls>

          </div>
        </nb-tab>
        <nb-tab tabTitle="Drag&Drop">
          <!-- Tab content 2 -->
          <nb-card>
            <nb-card-body>
              <nb-card-header>FAQs</nb-card-header>
            </nb-card-body>
            <div class="container-grid">
              <div
                cdkDropList
                class="example-list"
                (cdkDropListDropped)="drop($event)"
                id="faqs"
                [cdkDropListConnectedTo]="dropLists"
              >
                <div class="example-box" *ngFor="let data of list2" cdkDrag>
                  {{ data.faq_question }}<br />
                 
                  <!-- ({{data.faq_index}}) <br>  -->
                  <!-- {{ data.faq_answer }} -->
                </div>
              </div>
            </div>
          </nb-card>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
  </nb-card>
</div>

<!-- Modal -->
<div class="modal custom-modal " id="previewFaqModel" tabindex="-1" role="dialog" aria-labelledby="viewFaqModel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content ">
      <div class="modal-header ">
        <h3 class="modal-title " id="viewFaqModel">Preview FAQs</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="container-fluid ">
          <label for="basic-url">FAQs Categories</label>
          <div class="input-group">
            <div class="input-group-prepend">
            </div>
            <input type="text" class="form-control mb-3" placeholder="" aria-label="Username" value="{{categoryList}}" aria-describedby="basic-addon1" readonly>
          </div>
          <label for="basic-url">FAQs Question</label>
          <div class="input-group">
          <div class="input-group-prepend">
          </div>
          <input type="text" class="form-control mb-3" placeholder="" aria-label="Username" value="{{modaldata.question}}" aria-describedby="basic-addon1" readonly>
        </div>

          <label for="basic-url">FAQs Answer  </label>
          <div class="input-group">
        <div class="input-group-prepend">
        </div>
        <div class="answer_input mr-1 p-2" [innerHtml]="modaldata.answer">
                </div>
        <!--<textarea class="form-control mb-3" rows="8" cols="50" aria-label="With textarea" value="{{modaldata.answer}}" id="fileInput" readonly></textarea>-->
       </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>